
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 850,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 900,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0801', CSTD_PLAN: '2024-01-09', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-29', A_TOT: 43,    A_ACT: 0,    A_REM: 43,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'CABLE TRAYS', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-C-0401', CSTD_PLAN: '2024-10-01', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 28,    A_ACT: 0,    A_REM: 28,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Paving & Civil Works', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-I-0301', CSTD_PLAN: '2024-09-07', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 118,   A_ACT: 0,    A_REM: 118,   A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 24,   B_ACT: 0,   B_REM: 24,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SMART JUNCTION BOX & FO CABLES', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-P-0401', CSTD_PLAN: '2024-09-12', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 1505,  A_ACT: 517,  A_REM: 988,   A_PRO: 34,  A_STATUS: 'INPROGRESS', AP_TOT: 20,  AP_ACT: 0,  AP_REM: 20, AP_PRO: 0,   B_TOT: 600,  B_ACT: 158, B_REM: 442,  B_PRO: 26,  B_STATUS: 'INPROGRESS', BP_TOT: 17, BP_ACT: 0,  BP_REM: 17, BP_PRO: 0,   C_TOT: 5,   C_ACT: 0, C_REM: 5,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 3,   CP_ACT: 0,   CP_REM: 3,  CP_PRO: 0,   SUBSYS_DESC: 'Solvent Recycle System', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-T-0101', CSTD_PLAN: '2024-04-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 31,    A_ACT: 0,    A_REM: 31,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Field Telecommunication System', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-U-1001', CSTD_PLAN: '2024-02-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-23', A_TOT: 58,    A_ACT: 32,   A_REM: 26,    A_PRO: 55,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 36,   B_ACT: 20,  B_REM: 16,   B_PRO: 55,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Instrument Air', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-U-2102', CSTD_PLAN: '2024-05-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-23', A_TOT: 21,    A_ACT: 17,   A_REM: 4,     A_PRO: 81,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 15,   B_ACT: 12,  B_REM: 3,    B_PRO: 80,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MEDIUM PRESSURE STEAM UTILITY STATION', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-U-6003', CSTD_PLAN: '2024-07-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 65,    A_ACT: 18,   A_REM: 47,    A_PRO: 27,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 29,   B_ACT: 6,   B_REM: 23,   B_PRO: 20,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'HP FLUSHING OIL', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-U-6102', CSTD_PLAN: '2024-08-26', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 180,   A_ACT: 96,   A_REM: 84,    A_PRO: 53,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'HOT OIL TRACING', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-U-7401', CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 280,   A_ACT: 110,  A_REM: 170,   A_PRO: 39,  A_STATUS: 'INPROGRESS', AP_TOT: 6,   AP_ACT: 0,  AP_REM: 6,  AP_PRO: 0,   B_TOT: 91,   B_ACT: 29,  B_REM: 62,   B_PRO: 31,  B_STATUS: 'INPROGRESS', BP_TOT: 5,  BP_ACT: 0,  BP_REM: 5,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 13,  CP_ACT: 0,   CP_REM: 13, CP_PRO: 0,   SUBSYS_DESC: 'Hot Oil', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-U-1101', CSTD_PLAN: '2024-02-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 15,    A_ACT: 3,    A_REM: 12,    A_PRO: 20,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 12,   B_ACT: 4,   B_REM: 8,    B_PRO: 33,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Utility Air', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-U-4001', CSTD_PLAN: '2024-04-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 10,    A_ACT: 3,    A_REM: 7,     A_PRO: 30,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 10,   B_ACT: 4,   B_REM: 6,    B_PRO: 40,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'NEWATER', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-U-6005', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 15,    A_ACT: 2,    A_REM: 13,    A_PRO: 13,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 12,   B_ACT: 2,   B_REM: 10,   B_PRO: 16,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'UGO FLUSHING OIL', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-I-0201', CSTD_PLAN: '2024-10-08', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 98,    A_ACT: 0,    A_REM: 98,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FO & NETWORK CABINETS', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-I-0202', CSTD_PLAN: '2024-02-24', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 60,    A_ACT: 0,    A_REM: 60,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 14,   B_ACT: 0,   B_REM: 14,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'POWER SUPPLY DISTRIBUTION CABINET', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0202', CSTD_PLAN: '2024-09-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 113,   A_ACT: 7,    A_REM: 106,   A_PRO: 6,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 23,   B_ACT: 0,   B_REM: 23,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 1,  BP_ACT: 1,  BP_REM: 0,  BP_PRO: 100, C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'COMPRESSOR', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0703', CSTD_PLAN: '2024-08-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 297,   A_ACT: 115,  A_REM: 182,   A_PRO: 38,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 113,  B_ACT: 48,  B_REM: 65,   B_PRO: 42,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'LN HC8 PRODUCT', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0704', CSTD_PLAN: '2024-08-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 334,   A_ACT: 63,   A_REM: 271,   A_PRO: 18,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 125,  B_ACT: 34,  B_REM: 91,   B_PRO: 27,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'HN HC8 PRODUCT', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0802', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 872,   A_ACT: 58,   A_REM: 814,   A_PRO: 6,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 324,  B_ACT: 7,   B_REM: 317,  B_PRO: 2,   B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 1,  BP_REM: 0,  BP_PRO: 100, C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MAKE UP HYDROGEN COMPRESSOR A', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0902', CSTD_PLAN: '2024-05-28', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 37,    A_ACT: 0,    A_REM: 37,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 17,   B_ACT: 0,   B_REM: 17,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'LUBRICITY INJECTION PACKAGE', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0903', CSTD_PLAN: '2024-06-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 36,    A_ACT: 0,    A_REM: 36,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 16,   B_ACT: 0,   B_REM: 16,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'ANTIFOAM INJECTION PACKAGE', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-1001', CSTD_PLAN: '2024-02-07', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 142,   A_ACT: 29,   A_REM: 113,   A_PRO: 20,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 80,   B_ACT: 18,  B_REM: 62,   B_PRO: 22,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Instrument Air', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-2003', CSTD_PLAN: '2024-05-08', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'LOW PRESSURE STEAM TRACING', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-2301', CSTD_PLAN: '2024-05-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 150,   A_ACT: 42,   A_REM: 108,   A_PRO: 28,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 60,   B_ACT: 17,  B_REM: 43,   B_PRO: 28,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Superheated Steam', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-4601', CSTD_PLAN: '2024-04-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 28,    A_ACT: 6,    A_REM: 22,    A_PRO: 21,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 23,   B_ACT: 0,   B_REM: 23,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIRE WATER AG', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-C-0401', CSTD_PLAN: '2024-09-07', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'PAVING & CIVIL WORKS', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-E-1101', CSTD_PLAN: '2024-10-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 38,    A_ACT: 4,    A_REM: 34,    A_PRO: 10,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Lighting, Welding Outlets & Sockets', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0105', CSTD_PLAN: '2024-08-12', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 376,   A_ACT: 17,   A_REM: 359,   A_PRO: 4,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 49,   B_ACT: 0,   B_REM: 49,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'CARBON BED  ADSORBERS', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0302', CSTD_PLAN: '2024-08-12', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 344,   A_ACT: 92,   A_REM: 252,   A_PRO: 26,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 153,  B_ACT: 28,  B_REM: 125,  B_PRO: 18,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'DIESEL PRODUCT', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0303', CSTD_PLAN: '2024-08-30', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 212,   A_ACT: 81,   A_REM: 131,   A_PRO: 38,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 93,   B_ACT: 31,  B_REM: 62,   B_PRO: 33,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'HEAVY DIESEL PRODUCT', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-U-2103', CSTD_PLAN: '2024-06-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 84,    A_ACT: 20,   A_REM: 64,    A_PRO: 23,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 10,  C_ACT: 0, C_REM: 10,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MEDIUM PRESSURE STEAM TRACING', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-U-6006', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 38,    A_ACT: 18,   A_REM: 20,    A_PRO: 47,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 33,   B_ACT: 17,  B_REM: 16,   B_PRO: 51,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'DHU INTERFACE MATERIAL', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-C-0101', CSTD_PLAN: '2024-05-15', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 100,   A_ACT: 67,   A_REM: 33,    A_PRO: 67,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Piling & Foundations', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-E-1001', CSTD_PLAN: '2024-09-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 24,    A_ACT: 5,    A_REM: 19,    A_PRO: 20,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Grounding & Lightning Protection', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0206', CSTD_PLAN: '2024-07-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 105,   A_ACT: 14,   A_REM: 91,    A_PRO: 13,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 24,   B_ACT: 0,   B_REM: 24,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FEED SECTION HP', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0207', CSTD_PLAN: '2024-08-21', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 3005,  A_ACT: 348,  A_REM: 2657,  A_PRO: 11,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 641,  B_ACT: 0,   B_REM: 641,  B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'REACTORS STAGE 1', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0601', CSTD_PLAN: '2024-08-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 71,    A_ACT: 53,   A_REM: 18,    A_PRO: 74,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 30,   B_ACT: 26,  B_REM: 4,    B_PRO: 86,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Wash Water', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0702', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 481,   A_ACT: 119,  A_REM: 362,   A_PRO: 24,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 366,  B_ACT: 118, B_REM: 248,  B_PRO: 32,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'DRAIN SYSTEM', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-U-2101', CSTD_PLAN: '2024-05-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 28,    A_ACT: 13,   A_REM: 15,    A_PRO: 46,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 21,   B_ACT: 10,  B_REM: 11,   B_PRO: 47,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MEDIUM PRESSURE STEAM UTILITY STATION', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-U-4001', CSTD_PLAN: '2024-05-28', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 67,    A_ACT: 15,   A_REM: 52,    A_PRO: 22,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 36,   B_ACT: 12,  B_REM: 24,   B_PRO: 33,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Newater', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-U-4601', CSTD_PLAN: '2024-03-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 3,     A_ACT: 1,    A_REM: 2,     A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIRE WATER AG', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-C-0801', CSTD_PLAN: '2024-05-28', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 108,   A_ACT: 63,   A_REM: 45,    A_PRO: 58,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'CABLE TRAYS', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-E-0101', CSTD_PLAN: '2024-07-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SOLIDS SEPARATION PACKAGE PANEL AL-V-1', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-U-1101', CSTD_PLAN: '2024-01-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 31,    A_ACT: 22,   A_REM: 9,     A_PRO: 71,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 28,   B_ACT: 22,  B_REM: 6,    B_PRO: 78,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Utility Air Solid Separation', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-I-0202', CSTD_PLAN: '2024-03-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 9,     A_ACT: 0,    A_REM: 9,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'POWER SUPPLY DISTRIBUTION CABINET', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-P-0501', CSTD_PLAN: '2024-05-07', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 96,    A_ACT: 2,    A_REM: 94,    A_PRO: 2,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 34,   B_ACT: 4,   B_REM: 30,   B_PRO: 11,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 4,   C_ACT: 0, C_REM: 4,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Antifoam', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-I-0301', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 162,   A_ACT: 11,   A_REM: 151,   A_PRO: 6,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 36,   B_ACT: 0,   B_REM: 36,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SMART JUNCTION BOX & FO CABLES', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-P-0502', CSTD_PLAN: '2024-06-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 931,   A_ACT: 670,  A_REM: 261,   A_PRO: 72,  A_STATUS: 'INPROGRESS', AP_TOT: 15,  AP_ACT: 0,  AP_REM: 15, AP_PRO: 0,   B_TOT: 262,  B_ACT: 149, B_REM: 113,  B_PRO: 56,  B_STATUS: 'INPROGRESS', BP_TOT: 21, BP_ACT: 3,  BP_REM: 18, BP_PRO: 14,  C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0,   SUBSYS_DESC: 'REACTION SECTION TRAIN 2', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-U-2201', CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 249,   A_ACT: 192,  A_REM: 57,    A_PRO: 77,  A_STATUS: 'INPROGRESS', AP_TOT: 8,   AP_ACT: 0,  AP_REM: 8,  AP_PRO: 0,   B_TOT: 58,   B_ACT: 43,  B_REM: 15,   B_PRO: 74,  B_STATUS: 'INPROGRESS', BP_TOT: 9,  BP_ACT: 0,  BP_REM: 9,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'High Pressure Steam', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-U-4601', CSTD_PLAN: '2024-03-21', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIRE WATER AG', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-C-0101', CSTD_PLAN: '2024-04-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 88,    A_ACT: 11,   A_REM: 77,    A_PRO: 12,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Piling & Foundations', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-U-1001', CSTD_PLAN: '2024-01-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 44,    A_ACT: 11,   A_REM: 33,    A_PRO: 25,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 24,   B_ACT: 6,   B_REM: 18,   B_PRO: 25,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Instrument Air', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-C-0101', CSTD_PLAN: '2024-04-25', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 80,    A_ACT: 50,   A_REM: 30,    A_PRO: 62,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Piling & Foundations', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-C-0201', CSTD_PLAN: '2024-03-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 11,    A_ACT: 6,    A_REM: 5,     A_PRO: 54,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIREPROOFING', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-E-1001', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 25,    A_ACT: 1,    A_REM: 24,    A_PRO: 4,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'GROUNDING & LIGHTNING PROTECTION', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0107', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 10,    A_ACT: 9,    A_REM: 1,     A_PRO: 90,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Wild Naphtha', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0110', CSTD_PLAN: '2024-06-01', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 10,    A_ACT: 8,    A_REM: 2,     A_PRO: 80,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Light Diesel From DHU', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0211', CSTD_PLAN: '2024-06-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 16,    A_ACT: 14,   A_REM: 2,     A_PRO: 87,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 16,   B_ACT: 14,  B_REM: 2,    B_PRO: 87,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: '900 DHU PURGE GAS', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-3001', CSTD_PLAN: '2024-06-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 342,   A_ACT: 262,  A_REM: 80,    A_PRO: 76,  A_STATUS: 'INPROGRESS', AP_TOT: 7,   AP_ACT: 0,  AP_REM: 7,  AP_PRO: 0,   B_TOT: 199,  B_ACT: 161, B_REM: 38,   B_PRO: 80,  B_STATUS: 'INPROGRESS', BP_TOT: 8,  BP_ACT: 0,  BP_REM: 8,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 10,  CP_ACT: 0,   CP_REM: 10, CP_PRO: 0,   SUBSYS_DESC: 'Low Pressure Condensate', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-4602', CSTD_PLAN: '2024-05-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 3,     A_ACT: 0,    A_REM: 3,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIRE WATER AG INTERCONNERCTING', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-7001', CSTD_PLAN: '2024-06-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 219,   A_ACT: 47,   A_REM: 172,   A_PRO: 21,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 91,   B_ACT: 43,  B_REM: 48,   B_PRO: 47,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FUEL GAS DISTRIBUTION', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-7102', CSTD_PLAN: '2024-06-01', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 19,    A_ACT: 10,   A_REM: 9,     A_PRO: 52,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Natural Gas North BL', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-C-5401', CSTD_PLAN: '2024-05-08', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 30,    A_ACT: 10,   A_REM: 20,    A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potentially Contaminated Storm Water', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-I-0301', CSTD_PLAN: '2024-05-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SMART JUNCTION BOX & FO CABLES', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-U-3101', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Medium Pressure Condensate', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-C-0401', CSTD_PLAN: '2024-08-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 6,     A_ACT: 0,    A_REM: 6,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Paving & Civil Works', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-P-0301', CSTD_PLAN: '2024-07-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-24', CSU_MS: 'MS-10', A_TOT: 217,   A_ACT: 56,   A_REM: 161,   A_PRO: 25,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 106,  B_ACT: 24,  B_REM: 82,   B_PRO: 22,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SEAL WATER & SUMP DRUM', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-U-4601', CSTD_PLAN: '2024-04-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIRE WATER AG', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-C-0801', CSTD_PLAN: '2024-02-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 111,   A_ACT: 48,   A_REM: 63,    A_PRO: 43,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Cable Trays', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-C-0801', CSTD_PLAN: '2024-02-14', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 295,   A_ACT: 124,  A_REM: 171,   A_PRO: 42,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 3,   CP_ACT: 0,   CP_REM: 3,  CP_PRO: 0,   SUBSYS_DESC: 'Cable Trays', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-E-1101', CSTD_PLAN: '2024-03-25', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 34,    A_ACT: 18,   A_REM: 16,    A_PRO: 52,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 9,    B_ACT: 0,   B_REM: 9,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Lighting, Welding Outlets & Sockets', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-I-0202', CSTD_PLAN: '2024-03-25', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'POWER SUPPLY DISTRIBUTION CABINET', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-P-0402', CSTD_PLAN: '2024-06-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-21', A_TOT: 83,    A_ACT: 16,   A_REM: 67,    A_PRO: 19,  A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,  AP_REM: 3,  AP_PRO: 0,   B_TOT: 44,   B_ACT: 4,   B_REM: 40,   B_PRO: 9,   B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'Sour Water', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-P-0502', CSTD_PLAN: '2024-07-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 22,    A_ACT: 0,    A_REM: 22,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 20,   B_ACT: 0,   B_REM: 20,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FILMER', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-P-0603', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 333,   A_ACT: 123,  A_REM: 210,   A_PRO: 36,  A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,  AP_REM: 3,  AP_PRO: 0,   B_TOT: 165,  B_ACT: 63,  B_REM: 102,  B_PRO: 38,  B_STATUS: 'INPROGRESS', BP_TOT: 4,  BP_ACT: 0,  BP_REM: 4,  BP_PRO: 0,   C_TOT: 5,   C_ACT: 0, C_REM: 5,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0,   SUBSYS_DESC: 'K.O. DRUM RELIEF AND DRAIN SYSTEM', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-U-2103', CSTD_PLAN: '2024-07-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-23', A_TOT: 1032,  A_ACT: 296,  A_REM: 736,   A_PRO: 28,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 122, C_ACT: 0, C_REM: 122, C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MEDIUM PRESSURE STEAM TRACING', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-U-4601', CSTD_PLAN: '2024-04-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 34,    A_ACT: 6,    A_REM: 28,    A_PRO: 17,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 29,   B_ACT: 0,   B_REM: 29,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIRE WATER AG', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-U-7401', CSTD_PLAN: '2024-05-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 58,    A_ACT: 17,   A_REM: 41,    A_PRO: 29,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 9,    B_ACT: 0,   B_REM: 9,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Hot Oil', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-0101', CSTD_PLAN: '2024-05-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 318,   A_ACT: 55,   A_REM: 263,   A_PRO: 17,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Piling & Foundations', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-I-0401', CSTD_PLAN: '2024-04-25', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 28,    A_ACT: 0,    A_REM: 28,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MMS CABINETS', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0105', CSTD_PLAN: '2024-07-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 761,   A_ACT: 309,  A_REM: 452,   A_PRO: 40,  A_STATUS: 'INPROGRESS', AP_TOT: 7,   AP_ACT: 0,  AP_REM: 7,  AP_PRO: 0,   B_TOT: 367,  B_ACT: 193, B_REM: 174,  B_PRO: 52,  B_STATUS: 'INPROGRESS', BP_TOT: 3,  BP_ACT: 0,  BP_REM: 3,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SEPARATION SECTION', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0804', CSTD_PLAN: '2024-08-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 874,   A_ACT: 51,   A_REM: 823,   A_PRO: 5,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 312,  B_ACT: 6,   B_REM: 306,  B_PRO: 1,   B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 1,  BP_REM: 0,  BP_PRO: 100, C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MAKE UP HYDROGEN COMPRESSOR C', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-2103', CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 1309,  A_ACT: 461,  A_REM: 848,   A_PRO: 35,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 153, C_ACT: 0, C_REM: 153, C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MEDIUM PRESSURE STEAM TRACING', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-C-5401', CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 36,    A_ACT: 12,   A_REM: 24,    A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 4,    B_ACT: 4,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'POTENTIALLY CONTAMINATED STORM WATER', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0202', CSTD_PLAN: '2024-08-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 213,   A_ACT: 47,   A_REM: 166,   A_PRO: 22,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 61,   B_ACT: 3,   B_REM: 58,   B_PRO: 4,   B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SEPARATION SECTION LP', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-U-2401', CSTD_PLAN: '2024-05-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 88,    A_ACT: 26,   A_REM: 62,    A_PRO: 29,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 25,   B_ACT: 9,   B_REM: 16,   B_PRO: 36,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Steam Generation', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-C-5401', CSTD_PLAN: '2024-05-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 84,    A_ACT: 22,   A_REM: 62,    A_PRO: 26,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 22,   B_ACT: 12,  B_REM: 10,   B_PRO: 54,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potentially Contaminated Storm Water', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-E-1101', CSTD_PLAN: '2024-05-24', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 99,    A_ACT: 20,   A_REM: 79,    A_PRO: 20,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Lighting, Welding Outlets & Sockets', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-E-1301', CSTD_PLAN: '2024-05-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 83,    A_ACT: 4,    A_REM: 79,    A_PRO: 4,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 26,  C_ACT: 0, C_REM: 26,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'ELECTRICAL TRACING', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0201', CSTD_PLAN: '2024-08-24', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 490,   A_ACT: 155,  A_REM: 335,   A_PRO: 31,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 256,  B_ACT: 85,  B_REM: 171,  B_PRO: 33,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FEED SECTION', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0204', CSTD_PLAN: '2024-09-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 561,   A_ACT: 112,  A_REM: 449,   A_PRO: 20,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 217,  B_ACT: 46,  B_REM: 171,  B_PRO: 21,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Recycle Gas', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0401', CSTD_PLAN: '2024-07-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-18', CSU_MS: 'MS-10', A_TOT: 283,   A_ACT: 24,   A_REM: 259,   A_PRO: 8,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 77,   B_ACT: 0,   B_REM: 77,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'STAGE 2 REACTORS', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-T-0101', CSTD_PLAN: '2024-03-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 41,    A_ACT: 0,    A_REM: 41,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Field Telecommunication System', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-I-0301', CSTD_PLAN: '2024-06-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 9,     A_ACT: 0,    A_REM: 9,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SMART JUNCTION BOX & FO CABLES', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-P-0101', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 607,   A_ACT: 64,   A_REM: 543,   A_PRO: 10,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 489,  B_ACT: 20,  B_REM: 469,  B_PRO: 4,   B_STATUS: 'INPROGRESS', BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 14,  C_ACT: 0, C_REM: 14,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Solid Separation', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-U-1001', CSTD_PLAN: '2024-01-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 40,    A_ACT: 18,   A_REM: 22,    A_PRO: 45,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 28,   B_ACT: 14,  B_REM: 14,   B_PRO: 50,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Instrument Air solid Separation Area', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-U-1201', CSTD_PLAN: '2024-04-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 61,    A_ACT: 24,   A_REM: 37,    A_PRO: 39,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 38,   B_ACT: 14,  B_REM: 24,   B_PRO: 36,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Nitrogen Solid Separation', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-U-7402', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 41,    A_ACT: 19,   A_REM: 22,    A_PRO: 46,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 16,   B_ACT: 10,  B_REM: 6,    B_PRO: 62,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Hot Oil Solid Separation Area', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-C-0501', CSTD_PLAN: '2024-01-08', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 22,    A_ACT: 9,    A_REM: 13,    A_PRO: 40,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Main Steel Structures and Piperacks', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-I-0101', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 25,    A_ACT: 1,    A_REM: 24,    A_PRO: 4,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 9,    B_ACT: 0,   B_REM: 9,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Fire & Gas detection & alarming devices', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-U-3001', CSTD_PLAN: '2024-06-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 84,    A_ACT: 52,   A_REM: 32,    A_PRO: 61,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 44,   B_ACT: 33,  B_REM: 11,   B_PRO: 75,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Low Pressure Condensate', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-C-5401', CSTD_PLAN: '2024-05-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 57,    A_ACT: 10,   A_REM: 47,    A_PRO: 17,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potentially Contaminated Storm Water', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-P-0201', CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 739,   A_ACT: 222,  A_REM: 517,   A_PRO: 30,  A_STATUS: 'INPROGRESS', AP_TOT: 10,  AP_ACT: 0,  AP_REM: 10, AP_PRO: 0,   B_TOT: 180,  B_ACT: 43,  B_REM: 137,  B_PRO: 23,  B_STATUS: 'INPROGRESS', BP_TOT: 7,  BP_ACT: 0,  BP_REM: 7,  BP_PRO: 0,   C_TOT: 18,  C_ACT: 0, C_REM: 18,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'Process Air', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-P-0501', CSTD_PLAN: '2024-06-24', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 1348,  A_ACT: 915,  A_REM: 433,   A_PRO: 67,  A_STATUS: 'INPROGRESS', AP_TOT: 19,  AP_ACT: 0,  AP_REM: 19, AP_PRO: 0,   B_TOT: 363,  B_ACT: 186, B_REM: 177,  B_PRO: 51,  B_STATUS: 'INPROGRESS', BP_TOT: 30, BP_ACT: 0,  BP_REM: 30, BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 10,  CP_ACT: 0,   CP_REM: 10, CP_PRO: 0,   SUBSYS_DESC: 'REACTION SECTION TRAIN 1', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-U-3201', CSTD_PLAN: '2024-06-12', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 17,    A_ACT: 17,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 14,   B_ACT: 14,  B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'High Pressure Condensate', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-U-3501', CSTD_PLAN: '2024-05-31', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 73,    A_ACT: 44,   A_REM: 29,    A_PRO: 60,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 34,   B_ACT: 23,  B_REM: 11,   B_PRO: 67,  B_STATUS: 'INPROGRESS', BP_TOT: 5,  BP_ACT: 0,  BP_REM: 5,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'HP BOILER BLOWDOWN', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-U-4001', CSTD_PLAN: '2024-05-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 58,    A_ACT: 58,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 58,   B_ACT: 58,  B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Newater', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-E-1301', CSTD_PLAN: '2024-04-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 126,   A_ACT: 12,   A_REM: 114,   A_PRO: 9,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 17,   B_ACT: 0,   B_REM: 17,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 24,  C_ACT: 0, C_REM: 24,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Electrical Tracing', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-I-0301', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 90,    A_ACT: 0,    A_REM: 90,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 20,   B_ACT: 0,   B_REM: 20,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SMART JUNCTION BOX & FO CABLES', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-P-0101', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 2730,  A_ACT: 694,  A_REM: 2036,  A_PRO: 25,  A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,  AP_REM: 3,  AP_PRO: 0,   B_TOT: 879,  B_ACT: 153, B_REM: 726,  B_PRO: 17,  B_STATUS: 'INPROGRESS', BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 11,  C_ACT: 0, C_REM: 11,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Hot Oil Treatment', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-U-4101', CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 14,    A_ACT: 2,    A_REM: 12,    A_PRO: 14,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 2,   B_REM: 6,    B_PRO: 25,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potable Water', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-C-0501', CSTD_PLAN: '2023-12-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 36,    A_ACT: 14,   A_REM: 22,    A_PRO: 38,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 3,  BP_ACT: 0,  BP_REM: 3,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MAIN STEEL STRUCTURES AND PIPERACKS', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0108', CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-28', CSU_MS: 'MS-10', A_TOT: 27,    A_ACT: 18,   A_REM: 9,     A_PRO: 66,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 24,   B_ACT: 18,  B_REM: 6,    B_PRO: 75,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SCGO / MCR Flush Oil to Pitch', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0203', CSTD_PLAN: '2024-06-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 12,    A_ACT: 10,   A_REM: 2,     A_PRO: 83,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'CLARIFIED TAR FROMCCFU', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0214', CSTD_PLAN: '2024-07-03', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 39,    A_ACT: 26,   A_REM: 13,    A_PRO: 66,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 21,   B_ACT: 18,  B_REM: 3,    B_PRO: 85,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: '1SS-3SS', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0215', CSTD_PLAN: '2024-07-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-28', CSU_MS: 'MS-10', A_TOT: 14,    A_ACT: 12,   A_REM: 2,     A_PRO: 85,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 14,   B_ACT: 12,  B_REM: 2,    B_PRO: 85,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'EHC 50', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0216', CSTD_PLAN: '2024-07-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-28', CSU_MS: 'MS-10', A_TOT: 8,     A_ACT: 8,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'EHC 120', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0217', CSTD_PLAN: '2024-07-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-28', CSU_MS: 'MS-10', A_TOT: 3,     A_ACT: 3,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'EHC Hi Vis', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-1101', CSTD_PLAN: '2024-02-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 136,   A_ACT: 73,   A_REM: 63,    A_PRO: 53,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 120,  B_ACT: 71,  B_REM: 49,   B_PRO: 59,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Utility Air', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-2202', CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 8,     A_ACT: 4,    A_REM: 4,     A_PRO: 50,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 4,    B_ACT: 2,   B_REM: 2,    B_PRO: 50,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'High Pressure Steam South Battery Limit', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-4102', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 10,    A_ACT: 10,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 10,   B_ACT: 10,  B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potable Water North BL', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-4601', CSTD_PLAN: '2024-06-15', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIRE WATER AG', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-P-0103', CSTD_PLAN: '2024-06-01', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 44,    A_ACT: 1,    A_REM: 43,    A_PRO: 2,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 22,   B_ACT: 0,   B_REM: 22,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SODIUM HYPOCLORITE DOSING', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-U-4302', CSTD_PLAN: '2024-04-25', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 95,    A_ACT: 65,   A_REM: 30,    A_PRO: 68,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 86,   B_ACT: 62,  B_REM: 24,   B_PRO: 72,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'CW DISTRIBUTION TO SRU-ARU-SWS', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-U-4304', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 148,   A_ACT: 70,   A_REM: 78,    A_PRO: 47,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 94,   B_ACT: 42,  B_REM: 52,   B_PRO: 44,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'CW DISTRIBUTION TO DHU-CCFU NORTH', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-C-0501', CSTD_PLAN: '2023-12-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 1,     A_ACT: 0,    A_REM: 1,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Main Steel Structures and Piperacks', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-C-5401', CSTD_PLAN: '2024-04-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 64,    A_ACT: 19,   A_REM: 45,    A_PRO: 29,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 18,   B_ACT: 14,  B_REM: 4,    B_PRO: 77,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potentially Contaminated Storm Water', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-E-1101', CSTD_PLAN: '2024-03-25', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 38,    A_ACT: 3,    A_REM: 35,    A_PRO: 7,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Lighting, Welding Outlets & Sockets', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21402-P-0401', CSTD_PLAN: '2024-05-31', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-28', CSU_MS: 'MS-10', A_TOT: 184,   A_ACT: 31,   A_REM: 153,   A_PRO: 16,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 89,   B_ACT: 24,  B_REM: 65,   B_PRO: 27,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'DHU INTERFACE', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-C-0201', CSTD_PLAN: '2024-03-07', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-17', A_TOT: 13,    A_ACT: 12,   A_REM: 1,     A_PRO: 92,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 6,   CP_ACT: 0,   CP_REM: 6,  CP_PRO: 0,   SUBSYS_DESC: 'FIREPROOFING', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0104', CSTD_PLAN: '2024-05-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-17', A_TOT: 197,   A_ACT: 169,  A_REM: 28,    A_PRO: 85,  A_STATUS: 'INPROGRESS', AP_TOT: 7,   AP_ACT: 0,  AP_REM: 7,  AP_PRO: 0,   B_TOT: 46,   B_ACT: 18,  B_REM: 28,   B_PRO: 39,  B_STATUS: 'INPROGRESS', BP_TOT: 10, BP_ACT: 0,  BP_REM: 10, BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0,   SUBSYS_DESC: 'BLENDED HSFO', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-E-1101', CSTD_PLAN: '2024-05-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 01 - INDOOR / OUTDOOR LIGHTING & SMALL POWER', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0205', CSTD_PLAN: '2024-02-23', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 1,    B_ACT: 1,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 3,  BP_ACT: 0,  BP_REM: 3,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 02 - PLC Cabinets', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0206', CSTD_PLAN: '2024-03-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 02 - Auxiliary Panels / Other Systems', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-C-0401', CSTD_PLAN: '2024-01-12', CSTD_WALKDOWN: '2023-09-25', CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 24,    A_ACT: 7,    A_REM: 17,    A_PRO: 29,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 39, BP_ACT: 32, BP_REM: 7,  BP_PRO: 82,  C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, SUBSYS_DESC: 'PAVING & CIVIL WORKS', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-C-0501', CSTD_PLAN: '2023-12-02', CSTD_WALKDOWN: '2023-09-08', CSTD_ACTUAL: '2023-10-07', WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 1,     A_ACT: 0,    A_REM: 1,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 7,   AP_ACT: 3,  AP_REM: 4,  AP_PRO: 42,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 2,  BP_ACT: 2,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 11,  CP_ACT: 7,   CP_REM: 4,  CP_PRO: 63,  SUBSYS_DESC: 'STEEL STRUCTURE FOR TRANSFORMER-SSV AREA', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0103', CSTD_PLAN: '2024-01-31', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-28', WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 60,    A_ACT: 60,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 14,  AP_ACT: 14, AP_REM: 0,  AP_PRO: 100, B_TOT: 9,    B_ACT: 9,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSV - 33KV DISTRIBUTION  SYSTEM - B', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0801', CSTD_PLAN: '2024-02-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 24,    A_ACT: 24,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 4,   AP_ACT: 2,  AP_REM: 2,  AP_PRO: 50,  B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 12, BP_ACT: 6,  BP_REM: 6,  BP_PRO: 50,  C_TOT: 8,   C_ACT: 0, C_REM: 8,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 8,   CP_ACT: 1,   CP_REM: 7,  CP_PRO: 12,  SUBSYS_DESC: 'SSV - 240V AC UPS UPS-V-01', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-C-0701', CSTD_PLAN: '2023-11-24', CSTD_WALKDOWN: '2023-10-24', CSTD_ACTUAL: '2023-11-06', WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 2,     A_ACT: 2,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 5,   AP_ACT: 4,  AP_REM: 1,  AP_PRO: 80,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 5,  BP_ACT: 5,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 10,  CP_ACT: 2,   CP_REM: 8,  CP_PRO: 20,  SUBSYS_DESC: 'SSW - Civil Structure & Archit. Finishing', },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0302', CSTD_PLAN: '2024-02-15', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-8',  A_TOT: 211,   A_ACT: 69,   A_REM: 142,   A_PRO: 32,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 14,   B_ACT: 0,   B_REM: 14,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'CLEAN STORM WATER SYSTEM - PLANT AREA', },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0602', CSTD_PLAN: '2024-02-23', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-26', A_TOT: 14,    A_ACT: 0,    A_REM: 14,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'ROADS - PLANT AREA', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-C-0501', CSTD_PLAN: '2024-01-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 26,    A_ACT: 8,    A_REM: 18,    A_PRO: 30,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 4,   CP_ACT: 0,   CP_REM: 4,  CP_PRO: 0,   SUBSYS_DESC: 'Main Steel Structures and Piperacks', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-E-1301', CSTD_PLAN: '2024-03-25', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 26,    A_ACT: 11,   A_REM: 15,    A_PRO: 42,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 5,   C_ACT: 0, C_REM: 5,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Electrical Tracing', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-I-0401', CSTD_PLAN: '2024-04-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MMS CABINETS', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-P-0101', CSTD_PLAN: '2024-09-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 699,   A_ACT: 126,  A_REM: 573,   A_PRO: 18,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 241,  B_ACT: 51,  B_REM: 190,  B_PRO: 21,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Feed Section', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-P-0501', CSTD_PLAN: '2024-08-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 204,   A_ACT: 11,   A_REM: 193,   A_PRO: 5,   A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 114,  B_ACT: 22,  B_REM: 92,   B_PRO: 19,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 12,  C_ACT: 0, C_REM: 12,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Antifoam', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-U-6002', CSTD_PLAN: '2024-08-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 127,   A_ACT: 41,   A_REM: 86,    A_PRO: 32,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 64,   B_ACT: 31,  B_REM: 33,   B_PRO: 48,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MP FLUSHING OIL', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-P-0102', CSTD_PLAN: '2024-07-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 308,   A_ACT: 46,   A_REM: 262,   A_PRO: 14,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 126,  B_ACT: 1,   B_REM: 125,  B_PRO: 0,   B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 4,   C_ACT: 0, C_REM: 4,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'OVERHEAD SCGO', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-0201', CSTD_PLAN: '2024-03-09', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 105,   A_ACT: 39,   A_REM: 66,    A_PRO: 37,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIREPROOFING', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-E-1001', CSTD_PLAN: '2024-07-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 45,    A_ACT: 5,    A_REM: 40,    A_PRO: 11,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Grounding & Lightning Protection', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-I-0101', CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 350,   A_ACT: 0,    A_REM: 350,   A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 124,  B_ACT: 0,   B_REM: 124,  B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Fire & Gas detection & alarming devices', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0103', CSTD_PLAN: '2024-08-31', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 767,   A_ACT: 80,   A_REM: 687,   A_PRO: 10,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 178,  B_ACT: 24,  B_REM: 154,  B_PRO: 13,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'REACTION SECTION TRAIN 1', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0104', CSTD_PLAN: '2024-07-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 1167,  A_ACT: 72,   A_REM: 1095,  A_PRO: 6,   A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 381,  B_ACT: 27,  B_REM: 354,  B_PRO: 7,   B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'REACTION SECTION TRAIN 2', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0403', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 642,   A_ACT: 204,  A_REM: 438,   A_PRO: 31,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 295,  B_ACT: 70,  B_REM: 225,  B_PRO: 23,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 4,   C_ACT: 0, C_REM: 4,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'DIESEL PRODUCT', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0807', CSTD_PLAN: '2024-07-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 235,   A_ACT: 22,   A_REM: 213,   A_PRO: 9,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 78,   B_ACT: 0,   B_REM: 78,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MAKE UP HYDROGEN COMPRESSOR C SPILLBACK', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-1202', CSTD_PLAN: '2024-04-08', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 68,    A_ACT: 35,   A_REM: 33,    A_PRO: 51,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 62,   B_ACT: 35,  B_REM: 27,   B_PRO: 56,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'NITROGEN UTILITY STATION', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-6005', CSTD_PLAN: '2024-07-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 109,   A_ACT: 34,   A_REM: 75,    A_PRO: 31,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 86,   B_ACT: 32,  B_REM: 54,   B_PRO: 37,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'UGO FLUSHING OIL', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-E-1001', CSTD_PLAN: '2024-09-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 1,     A_ACT: 1,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'GROUNDING & LIGHTNING PROTECTION', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0304', CSTD_PLAN: '2024-07-03', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 207,   A_ACT: 69,   A_REM: 138,   A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 93,   B_ACT: 29,  B_REM: 64,   B_PRO: 31,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'EHC 50 PRODUCT', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0601', CSTD_PLAN: '2024-06-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 116,   A_ACT: 0,    A_REM: 116,   A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 48,   B_ACT: 0,   B_REM: 48,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'BHT INJECTION PACKAGE', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0701', CSTD_PLAN: '2024-07-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 106,   A_ACT: 37,   A_REM: 69,    A_PRO: 34,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 77,   B_ACT: 30,  B_REM: 47,   B_PRO: 39,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RELIEF SYSTEM', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-C-0401', CSTD_PLAN: '2024-10-24', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'PAVING & CIVIL WORKS', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-P-0802', CSTD_PLAN: '2024-05-31', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 205,   A_ACT: 43,   A_REM: 162,   A_PRO: 21,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 103,  B_ACT: 24,  B_REM: 79,   B_PRO: 23,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Drain Solid Separation Area', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-C-5401', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 19,    A_ACT: 1,    A_REM: 18,    A_PRO: 5,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'POTENTIALLY CONTAMINATED STORM WATER', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-I-0101', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 19,    A_ACT: 3,    A_REM: 16,    A_PRO: 15,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 7,    B_ACT: 0,   B_REM: 7,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Fire & Gas detection & alarming devices', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-P-0101', CSTD_PLAN: '2024-07-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 304,   A_ACT: 200,  A_REM: 104,   A_PRO: 65,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 163,  B_ACT: 96,  B_REM: 67,   B_PRO: 58,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Sour Water Feed', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-E-1101', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 71,    A_ACT: 36,   A_REM: 35,    A_PRO: 50,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 13,   B_ACT: 0,   B_REM: 13,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 5,   C_ACT: 0, C_REM: 5,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Lighting, Welding Outlets & Sockets', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-U-1001', CSTD_PLAN: '2024-02-03', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 121,   A_ACT: 72,   A_REM: 49,    A_PRO: 59,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 77,   B_ACT: 56,  B_REM: 21,   B_PRO: 72,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Instrument Air', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-U-2001', CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 286,   A_ACT: 221,  A_REM: 65,    A_PRO: 77,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 110,  B_ACT: 90,  B_REM: 20,   B_PRO: 81,  B_STATUS: 'INPROGRESS', BP_TOT: 15, BP_ACT: 0,  BP_REM: 15, BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 4,   CP_ACT: 0,   CP_REM: 4,  CP_PRO: 0,   SUBSYS_DESC: 'Low Pressure Steam', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-U-7001', CSTD_PLAN: '2024-05-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 39,    A_ACT: 9,    A_REM: 30,    A_PRO: 23,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 18,   B_ACT: 4,   B_REM: 14,   B_PRO: 22,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Fuel Gas', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-C-5401', CSTD_PLAN: '2024-04-15', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 36,    A_ACT: 4,    A_REM: 32,    A_PRO: 11,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potentially Contaminated Storm Water', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-U-7001', CSTD_PLAN: '2024-07-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 277,   A_ACT: 69,   A_REM: 208,   A_PRO: 24,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 58,   B_ACT: 24,  B_REM: 34,   B_PRO: 41,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Fuel Gas', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0102', CSTD_PLAN: '2024-05-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 25,    A_ACT: 16,   A_REM: 9,     A_PRO: 64,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 11,   B_ACT: 8,   B_REM: 3,    B_PRO: 72,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'Pitch from DAU', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0104', CSTD_PLAN: '2024-05-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 19,    A_ACT: 9,    A_REM: 10,    A_PRO: 47,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'HSFO FROM HSFO COOLERS', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0117', CSTD_PLAN: '2024-06-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 46,    A_ACT: 26,   A_REM: 20,    A_PRO: 56,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 17,   B_ACT: 10,  B_REM: 7,    B_PRO: 58,  B_STATUS: 'INPROGRESS', BP_TOT: 4,  BP_ACT: 0,  BP_REM: 4,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'Pitch to HSFOC', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0119', CSTD_PLAN: '2024-06-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 51,    A_ACT: 35,   A_REM: 16,    A_PRO: 68,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 38,   B_ACT: 32,  B_REM: 6,    B_PRO: 84,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'EXPORT GAS', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0121', CSTD_PLAN: '2024-06-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 37,    A_ACT: 33,   A_REM: 4,     A_PRO: 89,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 36,   B_ACT: 34,  B_REM: 2,    B_PRO: 94,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'LSFO collection', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0301', CSTD_PLAN: '2024-05-24', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 36,    A_ACT: 16,   A_REM: 20,    A_PRO: 44,  A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,  AP_REM: 3,  AP_PRO: 0,   B_TOT: 51,   B_ACT: 32,  B_REM: 19,   B_PRO: 62,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 6,   C_ACT: 0, C_REM: 6,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'PHOSPHATE INJECTION PACKAGE', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-2003', CSTD_PLAN: '2024-08-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 20,    A_ACT: 8,    A_REM: 12,    A_PRO: 40,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Low Pressure Steam Tracing', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-3301', CSTD_PLAN: '2024-06-07', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 175,   A_ACT: 92,   A_REM: 83,    A_PRO: 52,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 90,   B_ACT: 56,  B_REM: 34,   B_PRO: 62,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Low Pressure Boiler Feed Water', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-3401', CSTD_PLAN: '2024-06-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 48,    A_ACT: 41,   A_REM: 7,     A_PRO: 85,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 25,   B_ACT: 22,  B_REM: 3,    B_PRO: 88,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'High Pressure Boiler Feed Water', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-E-1101', CSTD_PLAN: '2024-04-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Lighting, Welding Outlets & Sockets', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-P-0102', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 36,    A_ACT: 1,    A_REM: 35,    A_PRO: 2,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 17,   B_ACT: 0,   B_REM: 17,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SULFURIC ACID DOSING', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-U-4001', CSTD_PLAN: '2024-05-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 25,    A_ACT: 12,   A_REM: 13,    A_PRO: 48,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 13,   B_ACT: 10,  B_REM: 3,    B_PRO: 76,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Newater', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-U-4305', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 186,   A_ACT: 35,   A_REM: 151,   A_PRO: 18,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 118,  B_ACT: 22,  B_REM: 96,   B_PRO: 18,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'CW DISTRIBUTION TO DHU-CCFU SOUTH', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-C-0801', CSTD_PLAN: '2023-12-15', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 9,     A_ACT: 2,    A_REM: 7,     A_PRO: 22,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Cable Trays', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-P-0103', CSTD_PLAN: '2024-07-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-24', CSU_MS: 'MS-10', A_TOT: 388,   A_ACT: 68,   A_REM: 320,   A_PRO: 17,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 127,  B_ACT: 28,  B_REM: 99,   B_PRO: 22,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MAIN FLARE STACK', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-P-0203', CSTD_PLAN: '2024-05-07', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-24', CSU_MS: 'MS-10', A_TOT: 227,   A_ACT: 38,   A_REM: 189,   A_PRO: 16,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 63,   B_ACT: 12,  B_REM: 51,   B_PRO: 19,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'H2S STACK', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-U-4001', CSTD_PLAN: '2024-05-21', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 14,    A_ACT: 8,    A_REM: 6,     A_PRO: 57,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 12,   B_ACT: 8,   B_REM: 4,    B_PRO: 66,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'NEWATER', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-E-1401', CSTD_PLAN: '2024-01-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 55,    A_ACT: 1,    A_REM: 54,    A_PRO: 1,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 24,   B_ACT: 0,   B_REM: 24,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 16,  C_ACT: 0, C_REM: 16,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Cathodic Protection', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-I-0301', CSTD_PLAN: '2024-05-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 61,    A_ACT: 0,    A_REM: 61,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 15,   B_ACT: 0,   B_REM: 15,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SMART JUNCTION BOX & FO CABLES', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-C-0501', CSTD_PLAN: '2024-01-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-17', A_TOT: 13,    A_ACT: 6,    A_REM: 7,     A_PRO: 46,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 30, BP_ACT: 0,  BP_REM: 30, BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 6,   CP_ACT: 0,   CP_REM: 6,  CP_PRO: 0,   SUBSYS_DESC: 'Main Steel Structures and Piperacks', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-1201', CSTD_PLAN: '2024-04-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-12', A_TOT: 17,    A_ACT: 17,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 18,   B_ACT: 18,  B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Nitrogen', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-2102', CSTD_PLAN: '2024-04-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-17', A_TOT: 6,     A_ACT: 6,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 5,    B_ACT: 4,   B_REM: 1,    B_PRO: 80,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Medium Pressure Steam Utility Station', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-2201', CSTD_PLAN: '2024-05-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-17', A_TOT: 73,    A_ACT: 66,   A_REM: 7,     A_PRO: 90,  A_STATUS: 'INPROGRESS', AP_TOT: 4,   AP_ACT: 0,  AP_REM: 4,  AP_PRO: 0,   B_TOT: 22,   B_ACT: 16,  B_REM: 6,    B_PRO: 72,  B_STATUS: 'INPROGRESS', BP_TOT: 9,  BP_ACT: 0,  BP_REM: 9,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 4,   CP_ACT: 0,   CP_REM: 4,  CP_PRO: 0,   SUBSYS_DESC: 'High Pressure Steam', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-4001', CSTD_PLAN: '2024-05-21', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-17', A_TOT: 25,    A_ACT: 23,   A_REM: 2,     A_PRO: 92,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 22,   B_ACT: 20,  B_REM: 2,    B_PRO: 90,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Newater', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0201', CSTD_PLAN: '2024-02-01', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 4,   AP_ACT: 0,  AP_REM: 4,  AP_PRO: 0,   B_TOT: 14,   B_ACT: 12,  B_REM: 2,    B_PRO: 85,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 01 - DCS Cabinets', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0101', CSTD_PLAN: '2024-02-26', CSTD_WALKDOWN: '2023-09-11', CSTD_ACTUAL: '2023-10-10', WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 60,    A_ACT: 60,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 83,  AP_ACT: 83, AP_REM: 0,  AP_PRO: 100, B_TOT: 20,   B_ACT: 20,  B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 28, BP_ACT: 28, BP_REM: 0,  BP_PRO: 100, C_TOT: 4,   C_ACT: 0, C_REM: 4,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 71,  CP_ACT: 69,  CP_REM: 2,  CP_PRO: 97,  SUBSYS_DESC: 'SSV - 33KV POWER SYSTEM SG-V-1/2', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0102', CSTD_PLAN: '2024-01-31', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-28', WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 60,    A_ACT: 60,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 14,  AP_ACT: 14, AP_REM: 0,  AP_PRO: 100, B_TOT: 9,    B_ACT: 9,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'SSV - 33KV DISTRIBUTION  SYSTEM - A', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0601', CSTD_PLAN: '2024-02-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'NA',    A_TOT: 46,    A_ACT: 0,    A_REM: 46,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSV - 0,415V TURN ARROUND POWER CENTER TAPC-31/33 - PANEL', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0701', CSTD_PLAN: '2024-02-08', CSTD_WALKDOWN: '2023-09-04', CSTD_ACTUAL: '2023-10-08', WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 6,     A_ACT: 6,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 4,   AP_ACT: 2,  AP_REM: 2,  AP_PRO: 50,  B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 6,  BP_ACT: 6,  BP_REM: 0,  BP_PRO: 100, C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 5,   CP_ACT: 4,   CP_REM: 1,  CP_PRO: 80,  SUBSYS_DESC: 'SSV - 110V DC UPS BC-V-1', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0301', CSTD_PLAN: '2024-03-18', CSTD_WALKDOWN: '2023-10-23', CSTD_ACTUAL: '2023-11-06', WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 9,   AP_ACT: 7,  AP_REM: 2,  AP_PRO: 77,  B_TOT: 7,    B_ACT: 6,   B_REM: 1,    B_PRO: 85,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 1,  BP_REM: 0,  BP_PRO: 100, C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'SSW - 6,9KV POWER SYSTEM SG-W-1/2', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0601', CSTD_PLAN: '2024-01-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 35,    A_ACT: 14,   A_REM: 21,    A_PRO: 40,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 4,    B_ACT: 4,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSW - 0,415V TURN ARROUND POWER CENTER TAPC-34/35 - A', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-1101', CSTD_PLAN: '2024-03-11', CSTD_WALKDOWN: '2023-10-23', CSTD_ACTUAL: '2023-11-03', WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 4,   AP_ACT: 1,  AP_REM: 3,  AP_PRO: 25,  B_TOT: 4,    B_ACT: 4,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'SSW - INDOOR LIGHTING & SMALL POWER', },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0601', CSTD_PLAN: '2024-02-15', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-26', A_TOT: 9,     A_ACT: 0,    A_REM: 9,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'ROADS - NORTH AREA', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-P-0303', CSTD_PLAN: '2024-09-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 208,   A_ACT: 35,   A_REM: 173,   A_PRO: 16,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 100,  B_ACT: 8,   B_REM: 92,   B_PRO: 8,   B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 7,   C_ACT: 0, C_REM: 7,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'ASPHALTENE STRIPPER', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-P-0602', CSTD_PLAN: '2024-07-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 377,   A_ACT: 76,   A_REM: 301,   A_PRO: 20,  A_STATUS: 'INPROGRESS', AP_TOT: 18,  AP_ACT: 0,  AP_REM: 18, AP_PRO: 0,   B_TOT: 244,  B_ACT: 54,  B_REM: 190,  B_PRO: 22,  B_STATUS: 'INPROGRESS', BP_TOT: 5,  BP_ACT: 0,  BP_REM: 5,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 7,   CP_ACT: 0,   CP_REM: 7,  CP_PRO: 0,   SUBSYS_DESC: 'DRAIN  SYSTEM', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-U-1202', CSTD_PLAN: '2024-04-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-23', A_TOT: 19,    A_ACT: 14,   A_REM: 5,     A_PRO: 73,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 18,   B_ACT: 14,  B_REM: 4,    B_PRO: 77,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'NITROGEN UTILITY STATION', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-U-6004', CSTD_PLAN: '2024-07-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 35,    A_ACT: 18,   A_REM: 17,    A_PRO: 51,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 18,   B_ACT: 12,  B_REM: 6,    B_PRO: 66,  B_STATUS: 'INPROGRESS', BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'HHP FLUSHING OIL', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-U-2102', CSTD_PLAN: '2024-05-28', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 9,     A_ACT: 1,    A_REM: 8,     A_PRO: 11,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 7,    B_ACT: 1,   B_REM: 6,    B_PRO: 14,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MEDIUM PRESSURE STEAM UTILITY STATION', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-0801', CSTD_PLAN: '2024-01-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 1701,  A_ACT: 454,  A_REM: 1247,  A_PRO: 26,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Cable Trays', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-I-0301', CSTD_PLAN: '2024-06-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 761,   A_ACT: 0,    A_REM: 761,   A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 157,  B_ACT: 0,   B_REM: 157,  B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SMART JUNCTION BOX & FO CABLES', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0201', CSTD_PLAN: '2024-08-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 1537,  A_ACT: 219,  A_REM: 1318,  A_PRO: 14,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 574,  B_ACT: 159, B_REM: 415,  B_PRO: 27,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Recycle Gas', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0402', CSTD_PLAN: '2024-08-07', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 606,   A_ACT: 121,  A_REM: 485,   A_PRO: 20,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 247,  B_ACT: 61,  B_REM: 186,  B_PRO: 24,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 4,   C_ACT: 0, C_REM: 4,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'NAPHTHA PRODUCT', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0601', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 405,   A_ACT: 87,   A_REM: 318,   A_PRO: 21,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 168,  B_ACT: 46,  B_REM: 122,  B_PRO: 27,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'WASH WATER', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0706', CSTD_PLAN: '2024-07-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 91,    A_ACT: 30,   A_REM: 61,    A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 15,   B_ACT: 0,   B_REM: 15,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 9,   C_ACT: 0, C_REM: 9,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'VACUUM PACKAGE', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0805', CSTD_PLAN: '2024-06-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 236,   A_ACT: 17,   A_REM: 219,   A_PRO: 7,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 78,   B_ACT: 0,   B_REM: 78,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MAKE UP HYDROGEN COMPRESSOR A SPILLBACK', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0901', CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 35,    A_ACT: 0,    A_REM: 35,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 17,   B_ACT: 0,   B_REM: 17,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'CFPP ADDITIVE INJECTION PACKAGE', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-1101', CSTD_PLAN: '2024-02-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 126,   A_ACT: 51,   A_REM: 75,    A_PRO: 40,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 106,  B_ACT: 51,  B_REM: 55,   B_PRO: 48,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Utility Air', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0101', CSTD_PLAN: '2024-09-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 735,   A_ACT: 166,  A_REM: 569,   A_PRO: 22,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 343,  B_ACT: 100, B_REM: 243,  B_PRO: 29,  B_STATUS: 'INPROGRESS', BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 6,   C_ACT: 0, C_REM: 6,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'REACTION FEED', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0301', CSTD_PLAN: '2024-07-15', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 378,   A_ACT: 47,   A_REM: 331,   A_PRO: 12,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 123,  B_ACT: 0,   B_REM: 123,  B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 6,   C_ACT: 0, C_REM: 6,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'VACUUM FRACTIONATOR', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-I-0101', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 191,   A_ACT: 3,    A_REM: 188,   A_PRO: 1,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 67,   B_ACT: 0,   B_REM: 67,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Fire & Gas detection & alarming devices', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0202', CSTD_PLAN: '2024-07-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-18', CSU_MS: 'MS-10', A_TOT: 394,   A_ACT: 21,   A_REM: 373,   A_PRO: 5,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 109,  B_ACT: 4,   B_REM: 105,  B_PRO: 3,   B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'STAGE 1 REACTORS', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0304', CSTD_PLAN: '2024-09-21', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 414,   A_ACT: 10,   A_REM: 404,   A_PRO: 2,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 157,  B_ACT: 0,   B_REM: 157,  B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'OFF GAS COMPRESSOR A', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0402', CSTD_PLAN: '2024-09-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 736,   A_ACT: 215,  A_REM: 521,   A_PRO: 29,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 314,  B_ACT: 96,  B_REM: 218,  B_PRO: 30,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'STAGE 2 SEPARATION', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-U-1001', CSTD_PLAN: '2024-02-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 69,    A_ACT: 16,   A_REM: 53,    A_PRO: 23,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 50,   B_ACT: 20,  B_REM: 30,   B_PRO: 40,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'INSTRUMENT AIR', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-U-1202', CSTD_PLAN: '2024-04-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 22,    A_ACT: 8,    A_REM: 14,    A_PRO: 36,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 18,   B_ACT: 8,   B_REM: 10,   B_PRO: 44,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'NITROGEN UTILITY STATION', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-C-0101', CSTD_PLAN: '2024-05-15', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 51,    A_ACT: 50,   A_REM: 1,     A_PRO: 98,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'PILING & FOUNDATIONS', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-P-0102', CSTD_PLAN: '2024-08-28', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 140,   A_ACT: 31,   A_REM: 109,   A_PRO: 22,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 51,   B_ACT: 12,  B_REM: 39,   B_PRO: 23,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Make up Mid Cut', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-P-0103', CSTD_PLAN: '2024-08-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 457,   A_ACT: 136,  A_REM: 321,   A_PRO: 29,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 201,  B_ACT: 44,  B_REM: 157,  B_PRO: 21,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 5,   C_ACT: 0, C_REM: 5,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Sludge', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-U-4101', CSTD_PLAN: '2024-05-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 13,    A_ACT: 9,    A_REM: 4,     A_PRO: 69,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 9,    B_ACT: 6,   B_REM: 3,    B_PRO: 66,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potable Water Solid Separation', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-U-4301', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 16,    A_ACT: 10,   A_REM: 6,     A_PRO: 62,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 14,   B_ACT: 10,  B_REM: 4,    B_PRO: 71,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Cooling Water Solid Separation', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-I-0301', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 18,    A_ACT: 2,    A_REM: 16,    A_PRO: 11,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SMART JUNCTION BOX & FO CABLES', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-P-0301', CSTD_PLAN: '2024-07-23', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 187,   A_ACT: 130,  A_REM: 57,    A_PRO: 69,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 113,  B_ACT: 78,  B_REM: 35,   B_PRO: 69,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Stripped Water', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-U-1101', CSTD_PLAN: '2024-02-03', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 18,    A_ACT: 6,    A_REM: 12,    A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 14,   B_ACT: 6,   B_REM: 8,    B_PRO: 42,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Utility Air', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-U-2102', CSTD_PLAN: '2024-04-24', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 7,    B_ACT: 6,   B_REM: 1,    B_PRO: 85,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Medium Pressure Utility Station', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-C-0401', CSTD_PLAN: '2024-07-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 21,    A_ACT: 0,    A_REM: 21,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Paving & Civil Works', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-P-0401', CSTD_PLAN: '2024-07-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 248,   A_ACT: 63,   A_REM: 185,   A_PRO: 25,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 165,  B_ACT: 55,  B_REM: 110,  B_PRO: 33,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'Amine Drain', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-U-1202', CSTD_PLAN: '2024-05-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 20,    A_ACT: 20,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 20,   B_ACT: 20,  B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'NITROGEN UTILITY STATION', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-U-2102', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 36,    A_ACT: 36,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 25,   B_ACT: 24,  B_REM: 1,    B_PRO: 96,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Medium Pressure Utility Station', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-U-2103', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 172,   A_ACT: 84,   A_REM: 88,    A_PRO: 48,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 21,  C_ACT: 0, C_REM: 21,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MEDIUM PRESSURE STEAM TRACING', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-U-4001', CSTD_PLAN: '2024-03-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 39,    A_ACT: 33,   A_REM: 6,     A_PRO: 84,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 36,   B_ACT: 32,  B_REM: 4,    B_PRO: 88,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Newater', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-U-4101', CSTD_PLAN: '2024-06-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 14,    A_ACT: 6,    A_REM: 8,     A_PRO: 42,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potable Water', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-C-0401', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 26,    A_ACT: 0,    A_REM: 26,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Paving & Civil Works', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-C-0501', CSTD_PLAN: '2024-01-08', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 34,    A_ACT: 14,   A_REM: 20,    A_PRO: 41,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 3,  BP_ACT: 2,  BP_REM: 1,  BP_PRO: 66,  C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 6,   CP_ACT: 0,   CP_REM: 6,  CP_PRO: 0,   SUBSYS_DESC: 'Main Steel Structures and Piperacks', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-P-0101', CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 427,   A_ACT: 182,  A_REM: 245,   A_PRO: 42,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 128,  B_ACT: 32,  B_REM: 96,   B_PRO: 25,  B_STATUS: 'INPROGRESS', BP_TOT: 7,  BP_ACT: 0,  BP_REM: 7,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0,   SUBSYS_DESC: 'AMINE ACID GAS FEED', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-P-0401', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 561,   A_ACT: 54,   A_REM: 507,   A_PRO: 9,   A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 118,  B_ACT: 20,  B_REM: 98,   B_PRO: 16,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 4,   C_ACT: 0, C_REM: 4,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Incinerator', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-U-2003', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 180,   A_ACT: 20,   A_REM: 160,   A_PRO: 11,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 26,  C_ACT: 0, C_REM: 26,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'LOW PRESSURE STEAM TRACING', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-U-2102', CSTD_PLAN: '2024-04-23', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 69,    A_ACT: 67,   A_REM: 2,     A_PRO: 97,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 47,   B_ACT: 46,  B_REM: 1,    B_PRO: 97,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'MEDIUM PRESSURE STEAM UTILITY STATION', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-U-3401', CSTD_PLAN: '2024-05-25', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 118,   A_ACT: 77,   A_REM: 41,    A_PRO: 65,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 38,   B_ACT: 22,  B_REM: 16,   B_PRO: 57,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'High Pressure Boiler Feed Water', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-E-1001', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 6,     A_ACT: 0,    A_REM: 6,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Grounding & Lightning Protection', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-I-0101', CSTD_PLAN: '2024-07-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 29,    A_ACT: 0,    A_REM: 29,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 11,   B_ACT: 0,   B_REM: 11,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Fire & Gas detection & alarming devices', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-I-0202', CSTD_PLAN: '2024-04-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 17,    A_ACT: 0,    A_REM: 17,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'POWER SUPPLY DISTRIBUTION CABINET', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-U-4001', CSTD_PLAN: '2024-03-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 14,    A_ACT: 14,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 14,   B_ACT: 14,  B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Newater', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-C-0801', CSTD_PLAN: '2024-01-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 355,   A_ACT: 235,  A_REM: 120,   A_PRO: 66,  A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,  AP_REM: 3,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'CABLE TRAYS', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-I-0301', CSTD_PLAN: '2024-03-28', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 56,    A_ACT: 0,    A_REM: 56,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SMART JUNCTION BOX BLP1401', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0106', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 8,     A_ACT: 8,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SGCO FROM HSFOC', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0112', CSTD_PLAN: '2024-06-01', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SCGO FROM PFB', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0114', CSTD_PLAN: '2024-06-07', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MOLTEN SULPHUR', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0122', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 52,    A_ACT: 48,   A_REM: 4,     A_PRO: 92,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 52,   B_ACT: 48,  B_REM: 4,    B_PRO: 92,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'UGO to users', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0124', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 9,     A_ACT: 6,    A_REM: 3,     A_PRO: 66,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Slops North BL', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0125', CSTD_PLAN: '2024-06-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-28', CSU_MS: 'MS-10', A_TOT: 103,   A_ACT: 68,   A_REM: 35,    A_PRO: 66,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 50,   B_ACT: 36,  B_REM: 14,   B_PRO: 72,  B_STATUS: 'INPROGRESS', BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'VTB from Offsite', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-1002', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 192,   A_ACT: 5,    A_REM: 187,   A_PRO: 2,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 93,   B_ACT: 0,   B_REM: 93,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 4,   C_ACT: 0, C_REM: 4,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'INSTRUMENT AIR COMPRESSORS', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-3101', CSTD_PLAN: '2024-08-14', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 603,   A_ACT: 432,  A_REM: 171,   A_PRO: 71,  A_STATUS: 'INPROGRESS', AP_TOT: 26,  AP_ACT: 0,  AP_REM: 26, AP_PRO: 0,   B_TOT: 405,  B_ACT: 298, B_REM: 107,  B_PRO: 73,  B_STATUS: 'INPROGRESS', BP_TOT: 12, BP_ACT: 0,  BP_REM: 12, BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0,   SUBSYS_DESC: 'Medium Pressure Condensate', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-4101', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 73,    A_ACT: 51,   A_REM: 22,    A_PRO: 69,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 64,   B_ACT: 45,  B_REM: 19,   B_PRO: 70,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potable Water', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-U-1201', CSTD_PLAN: '2024-05-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 9,     A_ACT: 6,    A_REM: 3,     A_PRO: 66,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Nitrogen', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-U-4303', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 253,   A_ACT: 53,   A_REM: 200,   A_PRO: 20,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 184,  B_ACT: 50,  B_REM: 134,  B_PRO: 27,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0,   SUBSYS_DESC: 'CW DISTRIBUTION TO DAU-HOS', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-E-1001', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 6,     A_ACT: 3,    A_REM: 3,     A_PRO: 50,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Grounding & Lightning Protection', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-E-1101', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 7,     A_ACT: 0,    A_REM: 7,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Lighting, Welding Outlets & Sockets', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-C-0501', CSTD_PLAN: '2024-01-08', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 10,    A_ACT: 2,    A_REM: 8,     A_PRO: 20,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Main Steel Structures and Piperacks', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-E-1401', CSTD_PLAN: '2024-02-23', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 27,    A_ACT: 1,    A_REM: 26,    A_PRO: 3,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 6,   C_ACT: 0, C_REM: 6,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Cathodic Protection', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-I-0202', CSTD_PLAN: '2024-03-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'POWER SUPPLY DISTRIBUTION CABINET', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-I-0301', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 27,    A_ACT: 0,    A_REM: 27,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SMART JUNCTION BOX & FO CABLES', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-T-0101', CSTD_PLAN: '2024-03-30', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 28,    A_ACT: 0,    A_REM: 28,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Field Telecommunication System', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-U-1202', CSTD_PLAN: '2024-05-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 10,    A_ACT: 4,    A_REM: 6,     A_PRO: 40,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 4,   B_REM: 4,    B_PRO: 50,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'NITROGEN UTILITY STATION', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-U-2101', CSTD_PLAN: '2024-05-08', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 7,    B_ACT: 6,   B_REM: 1,    B_PRO: 85,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Medium Pressure Steam', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21402-P-0103', CSTD_PLAN: '2024-05-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-28', CSU_MS: 'MS-10', A_TOT: 444,   A_ACT: 40,   A_REM: 404,   A_PRO: 9,   A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 147,  B_ACT: 22,  B_REM: 125,  B_PRO: 15,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'BS HC8', },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0303', CSTD_PLAN: '2024-02-15', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-8',  A_TOT: 71,    A_ACT: 24,   A_REM: 47,    A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 0,   B_REM: 8,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'CLEAN STORM WATER SYSTEM - SOUTH AREA', },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     SUBSYS_NO: '20000-E-1101', CSTD_PLAN: '2024-01-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-11', A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'STREET LIGHTING - NORTH', },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     SUBSYS_NO: '20000-E-1103', CSTD_PLAN: '2024-02-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-11', A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'STREET LIGHTING - PLANT SOUTH', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-C-5401', CSTD_PLAN: '2024-03-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 42,    A_ACT: 7,    A_REM: 35,    A_PRO: 16,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potentially Contaminated Storm Water', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-P-0302', CSTD_PLAN: '2024-07-08', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 673,   A_ACT: 255,  A_REM: 418,   A_PRO: 37,  A_STATUS: 'INPROGRESS', AP_TOT: 79,  AP_ACT: 0,  AP_REM: 79, AP_PRO: 0,   B_TOT: 271,  B_ACT: 97,  B_REM: 174,  B_PRO: 35,  B_STATUS: 'INPROGRESS', BP_TOT: 8,  BP_ACT: 0,  BP_REM: 8,  BP_PRO: 0,   C_TOT: 6,   C_ACT: 0, C_REM: 6,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 18,  CP_ACT: 0,   CP_REM: 18, CP_PRO: 0,   SUBSYS_DESC: 'DAO VACUUM SECTION', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-U-4001', CSTD_PLAN: '2024-04-24', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-23', A_TOT: 26,    A_ACT: 24,   A_REM: 2,     A_PRO: 92,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 26,   B_ACT: 24,  B_REM: 2,    B_PRO: 92,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Newater', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-U-4101', CSTD_PLAN: '2024-04-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-23', A_TOT: 10,    A_ACT: 2,    A_REM: 8,     A_PRO: 20,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 2,   B_REM: 4,    B_PRO: 33,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potable Water', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-U-4101', CSTD_PLAN: '2024-05-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 8,     A_ACT: 0,    A_REM: 8,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potable Water', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-E-1301', CSTD_PLAN: '2024-03-30', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 55,    A_ACT: 0,    A_REM: 55,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Electrical Tracing', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0101', CSTD_PLAN: '2024-09-12', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 1042,  A_ACT: 210,  A_REM: 832,   A_PRO: 20,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 387,  B_ACT: 117, B_REM: 270,  B_PRO: 30,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 5,   C_ACT: 0, C_REM: 5,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'REACTION FEED', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0705', CSTD_PLAN: '2024-07-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 314,   A_ACT: 103,  A_REM: 211,   A_PRO: 32,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 157,  B_ACT: 54,  B_REM: 103,  B_PRO: 34,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'BS HC8 PRODUCT', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-T-0101', CSTD_PLAN: '2024-02-24', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 97,    A_ACT: 0,    A_REM: 97,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Field Telecommunication System', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-C-0501', CSTD_PLAN: '2023-12-14', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 15,    A_ACT: 2,    A_REM: 13,    A_PRO: 13,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Main Steel Structures and Piperacks', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-I-0301', CSTD_PLAN: '2024-10-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 110,   A_ACT: 1,    A_REM: 109,   A_PRO: 0,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 24,   B_ACT: 0,   B_REM: 24,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SMART JUNCTION BOX & FO CABLES', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0102', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 582,   A_ACT: 203,  A_REM: 379,   A_PRO: 34,  A_STATUS: 'INPROGRESS', AP_TOT: 8,   AP_ACT: 0,  AP_REM: 8,  AP_PRO: 0,   B_TOT: 143,  B_ACT: 70,  B_REM: 73,   B_PRO: 49,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Reaction Section', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-C-0401', CSTD_PLAN: '2024-10-24', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 32,    A_ACT: 0,    A_REM: 32,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Paving & Civil Works', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-I-0202', CSTD_PLAN: '2024-02-24', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 23,    A_ACT: 0,    A_REM: 23,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'POWER SUPPLY DISTRIBUTION CABINET', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-I-0401', CSTD_PLAN: '2024-03-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 7,     A_ACT: 0,    A_REM: 7,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MMS CABINETS', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0301', CSTD_PLAN: '2024-09-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 1116,  A_ACT: 181,  A_REM: 935,   A_PRO: 16,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 519,  B_ACT: 49,  B_REM: 470,  B_PRO: 9,   B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 8,   C_ACT: 0, C_REM: 8,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FRACTIONATOR AND DIESEL STRIPPER', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0602', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 139,   A_ACT: 33,   A_REM: 106,   A_PRO: 23,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 61,   B_ACT: 1,   B_REM: 60,   B_PRO: 1,   B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Sour Water', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-U-2301', CSTD_PLAN: '2024-04-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 43,    A_ACT: 9,    A_REM: 34,    A_PRO: 20,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 17,   B_ACT: 2,   B_REM: 15,   B_PRO: 11,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Superheated Steam', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-U-6006', CSTD_PLAN: '2024-07-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 37,    A_ACT: 5,    A_REM: 32,    A_PRO: 13,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 30,   B_ACT: 5,   B_REM: 25,   B_PRO: 16,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MID CUT FLUSHING', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-U-7401', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 213,   A_ACT: 34,   A_REM: 179,   A_PRO: 16,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 66,   B_ACT: 4,   B_REM: 62,   B_PRO: 6,   B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Hot Oil', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-C-0201', CSTD_PLAN: '2024-07-08', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 10,    A_ACT: 3,    A_REM: 7,     A_PRO: 30,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIREPROOFING', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-C-0501', CSTD_PLAN: '2024-04-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 23,    A_ACT: 10,   A_REM: 13,    A_PRO: 43,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MAIN STEEL STRUCTURES AND PIPERACKS', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-I-0101', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 32,    A_ACT: 0,    A_REM: 32,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 11,   B_ACT: 0,   B_REM: 11,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIRE & GAS DETECTION & ALARMING DEVICES', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-P-0104', CSTD_PLAN: '2024-07-23', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 179,   A_ACT: 48,   A_REM: 131,   A_PRO: 26,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 75,   B_ACT: 0,   B_REM: 75,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'HEAT SOAK', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-P-0801', CSTD_PLAN: '2024-06-01', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 75,    A_ACT: 28,   A_REM: 47,    A_PRO: 37,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 44,   B_ACT: 14,  B_REM: 30,   B_PRO: 31,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RELIEF  SOLID SEPARATION AREA', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-U-4001', CSTD_PLAN: '2024-04-26', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 78,    A_ACT: 42,   A_REM: 36,    A_PRO: 53,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 66,   B_ACT: 42,  B_REM: 24,   B_PRO: 63,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'NEWATER', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-C-0401', CSTD_PLAN: '2024-07-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Paving & Civil Works', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-E-1001', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Grounding & Lightning Protection', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-I-0201', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FO & NETWORK CABINETS', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-P-0201', CSTD_PLAN: '2024-07-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 411,   A_ACT: 220,  A_REM: 191,   A_PRO: 53,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 176,  B_ACT: 96,  B_REM: 80,   B_PRO: 54,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'Sour Water Stripper', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-U-4601', CSTD_PLAN: '2024-05-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIRE WATER AG', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-C-0101', CSTD_PLAN: '2024-04-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 60,    A_ACT: 8,    A_REM: 52,    A_PRO: 13,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Piling & Foundations', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-U-1101', CSTD_PLAN: '2024-02-03', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 44,    A_ACT: 26,   A_REM: 18,    A_PRO: 59,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 38,   B_ACT: 26,  B_REM: 12,   B_PRO: 68,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Utility Air', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-U-6102', CSTD_PLAN: '2024-05-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 36,    A_ACT: 20,   A_REM: 16,    A_PRO: 55,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'HOT OIL TRACING', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-C-0801', CSTD_PLAN: '2024-02-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 393,   A_ACT: 255,  A_REM: 138,   A_PRO: 64,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Cable Trays', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-P-0301', CSTD_PLAN: '2024-06-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 304,   A_ACT: 141,  A_REM: 163,   A_PRO: 46,  A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,  AP_REM: 3,  AP_PRO: 0,   B_TOT: 142,  B_ACT: 92,  B_REM: 50,   B_PRO: 64,  B_STATUS: 'INPROGRESS', BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 4,   CP_ACT: 1,   CP_REM: 3,  CP_PRO: 25,  SUBSYS_DESC: 'Liquid Sulfur', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-U-1101', CSTD_PLAN: '2024-02-07', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 46,    A_ACT: 34,   A_REM: 12,    A_PRO: 73,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 42,   B_ACT: 34,  B_REM: 8,    B_PRO: 81,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Utility Air', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-C-0401', CSTD_PLAN: '2024-07-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 24,    A_ACT: 0,    A_REM: 24,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Paving & Civil Works', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-C-0501', CSTD_PLAN: '2024-01-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 18,    A_ACT: 4,    A_REM: 14,    A_PRO: 22,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Main Steel Structures and Piperacks', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-C-0801', CSTD_PLAN: '2024-01-12', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 266,   A_ACT: 125,  A_REM: 141,   A_PRO: 47,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Cable Trays', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-I-0201', CSTD_PLAN: '2024-07-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 15,    A_ACT: 0,    A_REM: 15,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FO & NETWORK CABINETS', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-U-2102', CSTD_PLAN: '2024-04-08', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 12,    A_ACT: 8,    A_REM: 4,     A_PRO: 66,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 9,    B_ACT: 5,   B_REM: 4,    B_PRO: 55,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MEDIUM PRESSURE STEAM UTILITY STATION', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-U-7101', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 67,    A_ACT: 26,   A_REM: 41,    A_PRO: 38,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 9,    B_ACT: 2,   B_REM: 7,    B_PRO: 22,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Natural Gas', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-U-7501', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 189,   A_ACT: 44,   A_REM: 145,   A_PRO: 23,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 134,  B_ACT: 40,  B_REM: 94,   B_PRO: 29,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'HOT OIL DRAIN (COLD)', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-E-1301', CSTD_PLAN: '2024-04-15', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 882,   A_ACT: 376,  A_REM: 506,   A_PRO: 42,  A_STATUS: 'INPROGRESS', AP_TOT: 11,  AP_ACT: 0,  AP_REM: 11, AP_PRO: 0,   B_TOT: 44,   B_ACT: 0,   B_REM: 44,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 136, C_ACT: 0, C_REM: 136, C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Electrical Tracing', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0113', CSTD_PLAN: '2024-06-03', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 10,    A_ACT: 9,    A_REM: 1,     A_PRO: 90,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Naphtha from DHU', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0115', CSTD_PLAN: '2024-06-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 86,    A_ACT: 50,   A_REM: 36,    A_PRO: 58,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 36,   B_ACT: 20,  B_REM: 16,   B_PRO: 55,  B_STATUS: 'INPROGRESS', BP_TOT: 3,  BP_ACT: 0,  BP_REM: 3,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'Non-Pitch', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0204', CSTD_PLAN: '2024-07-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 27,    A_ACT: 22,   A_REM: 5,     A_PRO: 81,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 20,   B_ACT: 17,  B_REM: 3,    B_PRO: 85,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'TAR', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0205', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 14,    A_ACT: 14,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 14,   B_ACT: 14,  B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SCGO FROM TK-750', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0206', CSTD_PLAN: '2024-07-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 25,    A_ACT: 21,   A_REM: 4,     A_PRO: 84,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 16,   B_ACT: 14,  B_REM: 2,    B_PRO: 87,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'TAR/MCR', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0207', CSTD_PLAN: '2024-06-03', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 18,    A_ACT: 17,   A_REM: 1,     A_PRO: 94,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 18,   B_ACT: 17,  B_REM: 1,    B_PRO: 94,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: '300 DHU PURGE GAS', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0212', CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 39,    A_ACT: 21,   A_REM: 18,    A_PRO: 53,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 30,   B_ACT: 21,  B_REM: 9,    B_PRO: 70,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'ECA Component', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0219', CSTD_PLAN: '2024-06-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-28', CSU_MS: 'MS-10', A_TOT: 36,    A_ACT: 12,   A_REM: 24,    A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Un Ex Hi Vis', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-1001', CSTD_PLAN: '2024-02-01', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 256,   A_ACT: 101,  A_REM: 155,   A_PRO: 39,  A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,  AP_REM: 3,  AP_PRO: 0,   B_TOT: 156,  B_ACT: 87,  B_REM: 69,   B_PRO: 55,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Instrument Air', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-1003', CSTD_PLAN: '2024-01-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 13,    A_ACT: 4,    A_REM: 9,     A_PRO: 30,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 5,    B_ACT: 4,   B_REM: 1,    B_PRO: 80,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Instrument Air North BL', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-2001', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 240,   A_ACT: 150,  A_REM: 90,    A_PRO: 62,  A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,  AP_REM: 3,  AP_PRO: 0,   B_TOT: 102,  B_ACT: 72,  B_REM: 30,   B_PRO: 70,  B_STATUS: 'INPROGRESS', BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Low Pressure Steam', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-3201', CSTD_PLAN: '2024-05-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 174,   A_ACT: 135,  A_REM: 39,    A_PRO: 77,  A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,  AP_REM: 3,  AP_PRO: 0,   B_TOT: 116,  B_ACT: 92,  B_REM: 24,   B_PRO: 79,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0,   SUBSYS_DESC: 'High Pressure Condensate', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-4603', CSTD_PLAN: '2024-05-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 293,   A_ACT: 65,   A_REM: 228,   A_PRO: 22,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 137,  B_ACT: 0,   B_REM: 137,  B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIRE WATER UG', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-I-0401', CSTD_PLAN: '2024-03-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 7,     A_ACT: 0,    A_REM: 7,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MMS CABINETS', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-P-0101', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 56,    A_ACT: 0,    A_REM: 56,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 32,   B_ACT: 0,   B_REM: 32,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 4,   C_ACT: 0, C_REM: 4,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'CHEMICAL DOSING PACKAGES', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-C-0101', CSTD_PLAN: '2024-04-25', CSTD_WALKDOWN: '2023-09-29', CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 18,    A_ACT: 18,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 3,   AP_ACT: 0,  AP_REM: 3,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 6,  BP_ACT: 0,  BP_REM: 6,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Piling & Foundations', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-I-0201', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 22,    A_ACT: 0,    A_REM: 22,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FO & NETWORK CABINETS', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-P-0201', CSTD_PLAN: '2024-06-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-24', CSU_MS: 'MS-10', A_TOT: 34,    A_ACT: 26,   A_REM: 8,     A_PRO: 76,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 17,   B_ACT: 14,  B_REM: 3,    B_PRO: 82,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'H2S FLARE HEADER', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-T-0101', CSTD_PLAN: '2024-03-12', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Field Telecommunication System', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-C-0401', CSTD_PLAN: '2024-07-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 19,    A_ACT: 0,    A_REM: 19,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Paving & Civil Works', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-C-5401', CSTD_PLAN: '2024-07-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 10,    A_ACT: 3,    A_REM: 7,     A_PRO: 30,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potentially Contaminated Storm Water', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-E-1001', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 39,    A_ACT: 0,    A_REM: 39,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Grounding & Lightning Protection', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-C-0101', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 386,   A_ACT: 64,   A_REM: 322,   A_PRO: 16,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Piling & Foundations', },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0603', CSTD_PLAN: '2024-02-23', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-26', A_TOT: 3,     A_ACT: 0,    A_REM: 3,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'ROADS - SOUTH AREA', },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     SUBSYS_NO: '20000-E-1102', CSTD_PLAN: '2024-02-09', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-11', A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'STREET LIGHTING - PLANT AREA', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-C-0101', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 109,   A_ACT: 29,   A_REM: 80,    A_PRO: 26,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Piling & Foundations', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-E-1001', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 11,    A_ACT: 2,    A_REM: 9,     A_PRO: 18,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Grounding & Lightning Protection', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-I-0101', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 102,   A_ACT: 3,    A_REM: 99,    A_PRO: 2,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 35,   B_ACT: 0,   B_REM: 35,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Fire & Gas detection & alarming devices', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-P-0301', CSTD_PLAN: '2024-08-26', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 471,   A_ACT: 116,  A_REM: 355,   A_PRO: 24,  A_STATUS: 'INPROGRESS', AP_TOT: 6,   AP_ACT: 0,  AP_REM: 6,  AP_PRO: 0,   B_TOT: 210,  B_ACT: 57,  B_REM: 153,  B_PRO: 27,  B_STATUS: 'INPROGRESS', BP_TOT: 12, BP_ACT: 0,  BP_REM: 12, BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 23,  CP_ACT: 0,   CP_REM: 23, CP_PRO: 0,   SUBSYS_DESC: 'STRIPPER SYSTEM', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-C-0201', CSTD_PLAN: '2024-04-23', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 9,     A_ACT: 1,    A_REM: 8,     A_PRO: 11,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIREPROOFING', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-C-0401', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 5,     A_ACT: 0,    A_REM: 5,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Paving & Civil Works', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-I-0101', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 15,    A_ACT: 0,    A_REM: 15,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Fire & Gas detection & alarming devices', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-I-0301', CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SMART JUNCTION BOX & FO CABLES', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-P-0101', CSTD_PLAN: '2024-08-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 979,   A_ACT: 252,  A_REM: 727,   A_PRO: 25,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 349,  B_ACT: 76,  B_REM: 273,  B_PRO: 21,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 4,   C_ACT: 0, C_REM: 4,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'STRIPPER & BOTTOMS', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-P-0103', CSTD_PLAN: '2024-06-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 94,    A_ACT: 12,   A_REM: 82,    A_PRO: 12,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 44,   B_ACT: 0,   B_REM: 44,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SOUR WATER', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-U-2103', CSTD_PLAN: '2024-06-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 92,    A_ACT: 0,    A_REM: 92,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 7,   C_ACT: 0, C_REM: 7,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MEDIUM PRESSURE STEAM TRACING', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-0501', CSTD_PLAN: '2024-01-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 83,    A_ACT: 15,   A_REM: 68,    A_PRO: 18,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Main Steel Structures and Piperacks', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0106', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 147,   A_ACT: 17,   A_REM: 130,   A_PRO: 11,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 45,   B_ACT: 0,   B_REM: 45,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'REACTION FEED HP', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0107', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 140,   A_ACT: 15,   A_REM: 125,   A_PRO: 10,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 18,   B_ACT: 0,   B_REM: 18,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'HEATER FEED LINES', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0203', CSTD_PLAN: '2024-09-25', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 261,   A_ACT: 3,    A_REM: 258,   A_PRO: 1,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 131,  B_ACT: 0,   B_REM: 131,  B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'PSA', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0806', CSTD_PLAN: '2024-07-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 234,   A_ACT: 19,   A_REM: 215,   A_PRO: 8,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 81,   B_ACT: 0,   B_REM: 81,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MAKE UP HYDROGEN COMPRESSOR B SPILLBACK', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-7101', CSTD_PLAN: '2024-06-12', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 137,   A_ACT: 14,   A_REM: 123,   A_PRO: 10,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 23,   B_ACT: 2,   B_REM: 21,   B_PRO: 8,   B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Natural Gas', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-C-0201', CSTD_PLAN: '2024-03-09', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 29,    A_ACT: 19,   A_REM: 10,    A_PRO: 65,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIREPROOFING', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-C-0801', CSTD_PLAN: '2024-01-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 157,   A_ACT: 0,    A_REM: 157,   A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'CABLE TRAYS', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0201', CSTD_PLAN: '2024-07-30', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 470,   A_ACT: 197,  A_REM: 273,   A_PRO: 41,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 201,  B_ACT: 94,  B_REM: 107,  B_PRO: 46,  B_STATUS: 'INPROGRESS', BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Separation Section', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0401', CSTD_PLAN: '2024-08-12', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 234,   A_ACT: 132,  A_REM: 102,   A_PRO: 56,  A_STATUS: 'INPROGRESS', AP_TOT: 9,   AP_ACT: 0,  AP_REM: 9,  AP_PRO: 0,   B_TOT: 54,   B_ACT: 27,  B_REM: 27,   B_PRO: 50,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Make up Hydrogen', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-U-7401', CSTD_PLAN: '2024-06-25', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 58,    A_ACT: 50,   A_REM: 8,     A_PRO: 86,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 22,   B_ACT: 20,  B_REM: 2,    B_PRO: 90,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Hot Oil', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-C-0201', CSTD_PLAN: '2024-04-24', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 54,    A_ACT: 8,    A_REM: 46,    A_PRO: 14,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIREPROOFING', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0203', CSTD_PLAN: '2024-06-21', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 324,   A_ACT: 156,  A_REM: 168,   A_PRO: 48,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 144,  B_ACT: 75,  B_REM: 69,   B_PRO: 52,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'STAGE 1 SEPARATION', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0501', CSTD_PLAN: '2024-06-25', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 168,   A_ACT: 10,   A_REM: 158,   A_PRO: 6,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 61,   B_ACT: 0,   B_REM: 61,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Amine', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-U-4101', CSTD_PLAN: '2024-05-25', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potable Water', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-E-1101', CSTD_PLAN: '2024-05-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 27,    A_ACT: 16,   A_REM: 11,    A_PRO: 59,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'LIGHTING, WELDING OUTLETS & SOCKETS', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-E-1101', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 8,     A_ACT: 3,    A_REM: 5,     A_PRO: 37,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Lighting, Welding Outlets & Sockets', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-U-1202', CSTD_PLAN: '2024-04-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 4,    B_ACT: 4,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'NITROGEN UTILITY STATION', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-U-3101', CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 25,    A_ACT: 25,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 11,   B_ACT: 10,  B_REM: 1,    B_PRO: 90,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Medium Pressure Condensate', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-U-4101', CSTD_PLAN: '2024-04-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 4,    B_ACT: 2,   B_REM: 2,    B_PRO: 50,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potable Water', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-C-5401', CSTD_PLAN: '2024-04-25', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 50,    A_ACT: 6,    A_REM: 44,    A_PRO: 12,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 4,    B_ACT: 2,   B_REM: 2,    B_PRO: 50,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potentially Contaminated Storm Water', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-E-1001', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Grounding & Lightning Protection', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-E-1301', CSTD_PLAN: '2024-02-15', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 46,    A_ACT: 22,   A_REM: 24,    A_PRO: 47,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 10,  C_ACT: 0, C_REM: 10,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'ELECTRICAL TRACING', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-P-0201', CSTD_PLAN: '2024-06-28', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 79,    A_ACT: 13,   A_REM: 66,    A_PRO: 16,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 26,   B_ACT: 2,   B_REM: 24,   B_PRO: 7,   B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Rich Amine', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-P-0301', CSTD_PLAN: '2024-07-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 620,   A_ACT: 220,  A_REM: 400,   A_PRO: 35,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 305,  B_ACT: 102, B_REM: 203,  B_PRO: 33,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Lean Amine', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-U-2001', CSTD_PLAN: '2024-06-14', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 33,    A_ACT: 29,   A_REM: 4,     A_PRO: 87,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 12,   B_ACT: 10,  B_REM: 2,    B_PRO: 83,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Low Pressure Steam', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-I-0202', CSTD_PLAN: '2024-03-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 19,    A_ACT: 0,    A_REM: 19,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'POWER SUPPLY DISTRIBUTION CABINET', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-U-3001', CSTD_PLAN: '2024-05-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 21,    A_ACT: 12,   A_REM: 9,     A_PRO: 57,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 14,   B_ACT: 8,   B_REM: 6,    B_PRO: 57,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Low Pressure Condensate', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-U-3301', CSTD_PLAN: '2024-06-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 97,    A_ACT: 80,   A_REM: 17,    A_PRO: 82,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 17,   B_ACT: 8,   B_REM: 9,    B_PRO: 47,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Low Pressure Boiler Feed Water', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-C-0201', CSTD_PLAN: '2024-02-23', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 17,    A_ACT: 9,    A_REM: 8,     A_PRO: 52,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIREPROOFING', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-E-1101', CSTD_PLAN: '2024-06-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 65,    A_ACT: 23,   A_REM: 42,    A_PRO: 35,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Lighting, Welding Outlets & Sockets', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-P-0201', CSTD_PLAN: '2024-07-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 597,   A_ACT: 274,  A_REM: 323,   A_PRO: 45,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 297,  B_ACT: 149, B_REM: 148,  B_PRO: 50,  B_STATUS: 'INPROGRESS', BP_TOT: 3,  BP_ACT: 0,  BP_REM: 3,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Hot Oil Distribution', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-U-7502', CSTD_PLAN: '2024-04-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 35,    A_ACT: 6,    A_REM: 29,    A_PRO: 17,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 24,   B_ACT: 6,   B_REM: 18,   B_PRO: 25,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Hot Oil Drain (Hot)', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-U-7601', CSTD_PLAN: '2024-05-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 148,   A_ACT: 79,   A_REM: 69,    A_PRO: 53,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 63,   B_ACT: 40,  B_REM: 23,   B_PRO: 63,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Off Gas', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-E-1101', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 47,    A_ACT: 21,   A_REM: 26,    A_PRO: 44,  A_STATUS: 'INPROGRESS', AP_TOT: 17,  AP_ACT: 0,  AP_REM: 17, AP_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'LIGHTING, WELDING OUTLETS & SOCKETS', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0101', CSTD_PLAN: '2024-06-03', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 52,    A_ACT: 41,   A_REM: 11,    A_PRO: 78,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 29,   B_ACT: 24,  B_REM: 5,    B_PRO: 82,  B_STATUS: 'INPROGRESS', BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'KPB/SCGO TO PFB', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0208', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 79,    A_ACT: 51,   A_REM: 28,    A_PRO: 64,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 54,   B_ACT: 39,  B_REM: 15,   B_PRO: 72,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'PSA OFF GAS', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0213', CSTD_PLAN: '2024-06-01', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 46,    A_ACT: 29,   A_REM: 17,    A_PRO: 63,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 24,   B_ACT: 15,  B_REM: 9,    B_PRO: 62,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'ECA LOH from CCFU DHU', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-1201', CSTD_PLAN: '2024-05-14', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 313,   A_ACT: 112,  A_REM: 201,   A_PRO: 35,  A_STATUS: 'INPROGRESS', AP_TOT: 6,   AP_ACT: 0,  AP_REM: 6,  AP_PRO: 0,   B_TOT: 253,  B_ACT: 109, B_REM: 144,  B_PRO: 43,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'Nitrogen', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-3502', CSTD_PLAN: '2024-06-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 121,   A_ACT: 48,   A_REM: 73,    A_PRO: 39,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 66,   B_ACT: 39,  B_REM: 27,   B_PRO: 59,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'LP BOILER BLOWDOWN', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-4001', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 107,   A_ACT: 65,   A_REM: 42,    A_PRO: 60,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 102,  B_ACT: 67,  B_REM: 35,   B_PRO: 65,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Newater', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-6004', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'HHP FLUSH OIL', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-7002', CSTD_PLAN: '2024-09-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 805,   A_ACT: 70,   A_REM: 735,   A_PRO: 8,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 242,  B_ACT: 31,  B_REM: 211,  B_PRO: 12,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FUEL GAS COMPRESSOR', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-C-0401', CSTD_PLAN: '2024-06-21', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 14,    A_ACT: 0,    A_REM: 14,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Paving & Civil Works', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-C-0501', CSTD_PLAN: '2024-01-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Main Steel Structures and Piperacks', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-C-0801', CSTD_PLAN: '2024-02-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 48,    A_ACT: 0,    A_REM: 48,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Cable Trays', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-E-1001', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 3,     A_ACT: 0,    A_REM: 3,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Grounding & Lightning Protection', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-I-0202', CSTD_PLAN: '2024-02-24', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 9,     A_ACT: 0,    A_REM: 9,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'POWER SUPPLY DISTRIBUTION CABINET', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-U-1001', CSTD_PLAN: '2024-01-30', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 13,    A_ACT: 6,    A_REM: 7,     A_PRO: 46,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Instrument Air', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-U-2001', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 31,    A_ACT: 3,    A_REM: 28,    A_PRO: 9,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Low Pressure Steam', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-U-2201', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 17,    A_ACT: 3,    A_REM: 14,    A_PRO: 17,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'High Pressure Steam', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-U-4601', CSTD_PLAN: '2024-05-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 3,     A_ACT: 1,    A_REM: 2,     A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Fire water AG', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-I-0101', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 29,    A_ACT: 0,    A_REM: 29,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Fire & Gas detection & alarming devices', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-P-0401', CSTD_PLAN: '2024-07-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 534,   A_ACT: 119,  A_REM: 415,   A_PRO: 22,  A_STATUS: 'INPROGRESS', AP_TOT: 4,   AP_ACT: 0,  AP_REM: 4,  AP_PRO: 0,   B_TOT: 364,  B_ACT: 95,  B_REM: 269,  B_PRO: 26,  B_STATUS: 'INPROGRESS', BP_TOT: 3,  BP_ACT: 0,  BP_REM: 3,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'CLOSED DRAIN HEADER', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-U-2103', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 128,   A_ACT: 60,   A_REM: 68,    A_PRO: 46,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 15,  C_ACT: 0, C_REM: 15,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MEDIUM PRESSURE STEAM TRACING', },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-5401', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-8',  A_TOT: 162,   A_ACT: 55,   A_REM: 107,   A_PRO: 34,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 60,   B_ACT: 11,  B_REM: 49,   B_PRO: 18,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potentially Contaminated Storm Water', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-C-0201', CSTD_PLAN: '2024-03-07', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 73,    A_ACT: 26,   A_REM: 47,    A_PRO: 35,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'FIREPROOFING', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-U-2101', CSTD_PLAN: '2024-05-08', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-23', A_TOT: 157,   A_ACT: 81,   A_REM: 76,    A_PRO: 51,  A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,  AP_REM: 3,  AP_PRO: 0,   B_TOT: 59,   B_ACT: 30,  B_REM: 29,   B_PRO: 50,  B_STATUS: 'INPROGRESS', BP_TOT: 12, BP_ACT: 0,  BP_REM: 12, BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 10,  CP_ACT: 0,   CP_REM: 10, CP_PRO: 0,   SUBSYS_DESC: 'Medium Pressure Steam', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-C-0801', CSTD_PLAN: '2024-03-12', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 54,    A_ACT: 25,   A_REM: 29,    A_PRO: 46,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Cable Trays', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-E-1001', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 4,     A_ACT: 1,    A_REM: 3,     A_PRO: 25,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Grounding & Lightning Protection', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-U-1201', CSTD_PLAN: '2024-04-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 6,     A_ACT: 1,    A_REM: 5,     A_PRO: 16,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 2,   B_REM: 4,    B_PRO: 33,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'NITROGEN', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-U-4601', CSTD_PLAN: '2024-05-08', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 3,     A_ACT: 1,    A_REM: 2,     A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIRE WATER AG', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-0401', CSTD_PLAN: '2024-09-07', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 92,    A_ACT: 0,    A_REM: 92,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Paving & Civil Works', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-5401', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 157,   A_ACT: 26,   A_REM: 131,   A_PRO: 16,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 26,   B_ACT: 13,  B_REM: 13,   B_PRO: 50,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potentially Contaminated Storm Water', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-E-1101', CSTD_PLAN: '2024-10-08', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 278,   A_ACT: 101,  A_REM: 177,   A_PRO: 36,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 34,   B_ACT: 0,   B_REM: 34,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 13,  C_ACT: 0, C_REM: 13,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0,   SUBSYS_DESC: 'Lighting, Welding Outlets & Sockets', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0109', CSTD_PLAN: '2024-04-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 12624, A_ACT: 1294, A_REM: 11330, A_PRO: 10,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2394, B_ACT: 7,   B_REM: 2387, B_PRO: 0,   B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'REACTORS', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0801', CSTD_PLAN: '2024-08-14', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 102,   A_ACT: 39,   A_REM: 63,    A_PRO: 38,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 74,   B_ACT: 33,  B_REM: 41,   B_PRO: 44,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Make up Hydrogen', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0803', CSTD_PLAN: '2024-08-03', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 863,   A_ACT: 58,   A_REM: 805,   A_PRO: 6,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 302,  B_ACT: 4,   B_REM: 298,  B_PRO: 1,   B_STATUS: 'INPROGRESS', BP_TOT: 2,  BP_ACT: 2,  BP_REM: 0,  BP_PRO: 100, C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MAKE UP HYDROGEN COMPRESSOR B', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-2401', CSTD_PLAN: '2024-04-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 148,   A_ACT: 39,   A_REM: 109,   A_PRO: 26,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 51,   B_ACT: 13,  B_REM: 38,   B_PRO: 25,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Steam Generation', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-4001', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 76,    A_ACT: 47,   A_REM: 29,    A_PRO: 61,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 72,   B_ACT: 47,  B_REM: 25,   B_PRO: 65,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Newater', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-4101', CSTD_PLAN: '2024-04-23', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 31,    A_ACT: 4,    A_REM: 27,    A_PRO: 12,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 22,   B_ACT: 8,   B_REM: 14,   B_PRO: 36,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potable Water', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-7001', CSTD_PLAN: '2024-04-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 322,   A_ACT: 34,   A_REM: 288,   A_PRO: 10,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 69,   B_ACT: 8,   B_REM: 61,   B_PRO: 11,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Fuel Gas', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-7401', CSTD_PLAN: '2024-04-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 31,    A_ACT: 14,   A_REM: 17,    A_PRO: 45,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 15,   B_ACT: 10,  B_REM: 5,    B_PRO: 66,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Hot Oil', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-C-0501', CSTD_PLAN: '2024-03-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 43,    A_ACT: 5,    A_REM: 38,    A_PRO: 11,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Main Steel Structures and Piperacks', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-C-0801', CSTD_PLAN: '2024-03-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 495,   A_ACT: 90,   A_REM: 405,   A_PRO: 18,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Cable Trays', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0205', CSTD_PLAN: '2024-09-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 289,   A_ACT: 9,    A_REM: 280,   A_PRO: 3,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 181,  B_ACT: 16,  B_REM: 165,  B_PRO: 8,   B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Recycle Gas Compressor', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0208', CSTD_PLAN: '2024-07-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 30,    A_ACT: 6,    A_REM: 24,    A_PRO: 20,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 11,   B_ACT: 0,   B_REM: 11,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'STAGE 1 SEPARATION HP', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0303', CSTD_PLAN: '2024-09-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 353,   A_ACT: 92,   A_REM: 261,   A_PRO: 26,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 166,  B_ACT: 29,  B_REM: 137,  B_PRO: 17,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MID CUT PRODUCT AND START-UP CIRCULATION', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0403', CSTD_PLAN: '2024-07-30', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 1172,  A_ACT: 170,  A_REM: 1002,  A_PRO: 14,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 266,  B_ACT: 0,   B_REM: 266,  B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'REACTORS STAGE 2', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-U-1101', CSTD_PLAN: '2024-02-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 27,    A_ACT: 10,   A_REM: 17,    A_PRO: 37,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 26,   B_ACT: 10,  B_REM: 16,   B_PRO: 38,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'UTILITY AIR', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-U-2103', CSTD_PLAN: '2024-07-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 420,   A_ACT: 84,   A_REM: 336,   A_PRO: 20,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 47,  C_ACT: 0, C_REM: 47,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MEDIUM PRESSURE STEAM TRACING', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-C-5401', CSTD_PLAN: '2024-05-28', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 24,    A_ACT: 7,    A_REM: 17,    A_PRO: 29,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 4,   B_REM: 2,    B_PRO: 66,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'POTENTIALLY CONTAMINATED STORM WATER', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-E-1001', CSTD_PLAN: '2024-05-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 6,     A_ACT: 3,    A_REM: 3,     A_PRO: 50,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'GROUNDING & LIGHTNING PROTECTION', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-U-2101', CSTD_PLAN: '2024-05-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 28,    A_ACT: 28,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 21,   B_ACT: 20,  B_REM: 1,    B_PRO: 95,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Medium Pressure Steam Solid Separation', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-C-0101', CSTD_PLAN: '2024-04-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 21,    A_ACT: 2,    A_REM: 19,    A_PRO: 9,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Piling & Foundations', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-C-0801', CSTD_PLAN: '2024-02-24', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 78,    A_ACT: 36,   A_REM: 42,    A_PRO: 46,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Cable Trays', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-T-0101', CSTD_PLAN: '2024-03-28', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Field Telecommunication System', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-C-0201', CSTD_PLAN: '2024-03-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 1,     A_ACT: 1,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIREPROOFING', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-C-0801', CSTD_PLAN: '2024-01-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 260,   A_ACT: 133,  A_REM: 127,   A_PRO: 51,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Cable Trays', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-P-0101', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 533,   A_ACT: 220,  A_REM: 313,   A_PRO: 41,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 258,  B_ACT: 99,  B_REM: 159,  B_PRO: 38,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'Amine Regeneration', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-C-0101', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 104,   A_ACT: 103,  A_REM: 1,     A_PRO: 99,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Piling & Foundations', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-E-1101', CSTD_PLAN: '2024-04-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 72,    A_ACT: 31,   A_REM: 41,    A_PRO: 43,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Lighting, Welding Outlets & Sockets', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-I-0201', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 24,    A_ACT: 0,    A_REM: 24,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FO & NETWORK CABINETS', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-U-1201', CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 204,   A_ACT: 125,  A_REM: 79,    A_PRO: 61,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 62,   B_ACT: 48,  B_REM: 14,   B_PRO: 77,  B_STATUS: 'INPROGRESS', BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, SUBSYS_DESC: 'Nitrogen', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-U-1202', CSTD_PLAN: '2024-04-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 37,    A_ACT: 34,   A_REM: 3,     A_PRO: 91,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 36,   B_ACT: 34,  B_REM: 2,    B_PRO: 94,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'NITROGEN UTILITY STATION', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-U-3502', CSTD_PLAN: '2024-05-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 29,    A_ACT: 7,    A_REM: 22,    A_PRO: 24,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 20,   B_ACT: 6,   B_REM: 14,   B_PRO: 30,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'LP BOILER BLOWDOWN', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-U-7101', CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 152,   A_ACT: 101,  A_REM: 51,    A_PRO: 66,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 33,   B_ACT: 12,  B_REM: 21,   B_PRO: 36,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'Natural Gas', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-T-0101', CSTD_PLAN: '2024-04-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 13,    A_ACT: 0,    A_REM: 13,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Field Telecommunication System', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-U-4601', CSTD_PLAN: '2024-04-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIRE WATER AG', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-U-6102', CSTD_PLAN: '2024-10-01', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'HOT OIL TRACING', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0103', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'HN Extract to CCFU', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0105', CSTD_PLAN: '2024-05-24', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 8,     A_ACT: 8,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'LFSO', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0109', CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 8,     A_ACT: 8,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'DAU solvent', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0111', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 45,    A_ACT: 25,   A_REM: 20,    A_PRO: 55,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 31,   B_ACT: 20,  B_REM: 11,   B_PRO: 64,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'UGO', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0120', CSTD_PLAN: '2024-06-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-28', CSU_MS: 'MS-10', A_TOT: 18,    A_ACT: 15,   A_REM: 3,     A_PRO: 83,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 12,   B_ACT: 10,  B_REM: 2,    B_PRO: 83,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'VTB from Tank', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0202', CSTD_PLAN: '2024-07-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 21,    A_ACT: 19,   A_REM: 2,     A_PRO: 90,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 14,   B_ACT: 13,  B_REM: 1,    B_PRO: 92,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'GPI/GPII BS', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0209', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 61,    A_ACT: 32,   A_REM: 29,    A_PRO: 52,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 39,   B_ACT: 31,  B_REM: 8,    B_PRO: 79,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'EXPORT GAS', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0210', CSTD_PLAN: '2024-06-01', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 138,   A_ACT: 57,   A_REM: 81,    A_PRO: 41,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 66,   B_ACT: 42,  B_REM: 24,   B_PRO: 63,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'H2', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0218', CSTD_PLAN: '2024-07-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-28', CSU_MS: 'MS-10', A_TOT: 36,    A_ACT: 22,   A_REM: 14,    A_PRO: 61,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 23,   B_ACT: 20,  B_REM: 3,    B_PRO: 87,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MCR to SOP', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-2101', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 997,   A_ACT: 559,  A_REM: 438,   A_PRO: 56,  A_STATUS: 'INPROGRESS', AP_TOT: 10,  AP_ACT: 0,  AP_REM: 10, AP_PRO: 0,   B_TOT: 556,  B_ACT: 348, B_REM: 208,  B_PRO: 62,  B_STATUS: 'INPROGRESS', BP_TOT: 11, BP_ACT: 0,  BP_REM: 11, BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 5,   CP_ACT: 0,   CP_REM: 5,  CP_PRO: 0,   SUBSYS_DESC: 'Medium Pressure Steam', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-3002', CSTD_PLAN: '2024-06-03', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Low Pressure Condensate North BL', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-5702', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 10,    A_ACT: 8,    A_REM: 2,     A_PRO: 80,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 7,   B_REM: 1,    B_PRO: 87,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SLOPS FROM CCFU SOLIDS', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-C-0101', CSTD_PLAN: '2024-04-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 9,     A_ACT: 1,    A_REM: 8,     A_PRO: 11,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Piling & Foundations', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-I-0201', CSTD_PLAN: '2024-04-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 15,    A_ACT: 0,    A_REM: 15,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FO & NETWORK CABINETS', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-T-0101', CSTD_PLAN: '2024-03-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 16,    A_ACT: 0,    A_REM: 16,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Field Telecommunication System', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-U-3301', CSTD_PLAN: '2024-05-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 3,     A_ACT: 0,    A_REM: 3,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Low Pressure Boiler Feed Water', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-U-4301', CSTD_PLAN: '2024-06-08', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 352,   A_ACT: 7,    A_REM: 345,   A_PRO: 2,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 177,  B_ACT: 0,   B_REM: 177,  B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 8,   C_ACT: 0, C_REM: 8,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'CW TOWER & PUMPS', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-P-0102', CSTD_PLAN: '2024-07-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-24', CSU_MS: 'MS-10', A_TOT: 609,   A_ACT: 264,  A_REM: 345,   A_PRO: 43,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 228,  B_ACT: 65,  B_REM: 163,  B_PRO: 28,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'MAIN FLARE KO DRUM & SLOPS', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-P-0202', CSTD_PLAN: '2024-07-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-24', CSU_MS: 'MS-10', A_TOT: 201,   A_ACT: 28,   A_REM: 173,   A_PRO: 13,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 69,   B_ACT: 8,   B_REM: 61,   B_PRO: 11,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'H2S FLARE SEAL', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-U-1001', CSTD_PLAN: '2024-02-01', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 28,    A_ACT: 4,    A_REM: 24,    A_PRO: 14,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 14,   B_ACT: 2,   B_REM: 12,   B_PRO: 14,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Instrument Air', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-U-1101', CSTD_PLAN: '2024-02-03', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 35,    A_ACT: 20,   A_REM: 15,    A_PRO: 57,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 30,   B_ACT: 20,  B_REM: 10,   B_PRO: 66,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Utility Air', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-E-1301', CSTD_PLAN: '2024-02-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 610,   A_ACT: 94,   A_REM: 516,   A_PRO: 15,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 85,   B_ACT: 0,   B_REM: 85,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 112, C_ACT: 0, C_REM: 112, C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Electrical Tracing', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-P-0101', CSTD_PLAN: '2024-07-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-28', CSU_MS: 'MS-10', A_TOT: 430,   A_ACT: 67,   A_REM: 363,   A_PRO: 15,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 152,  B_ACT: 26,  B_REM: 126,  B_PRO: 17,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'DAU Feed Tank', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-C-5401', CSTD_PLAN: '2024-04-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 21,    A_ACT: 6,    A_REM: 15,    A_PRO: 28,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 4,   B_REM: 2,    B_PRO: 66,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'POTENTIALLY CONTAMINATED STORM WATER', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-I-0202', CSTD_PLAN: '2024-02-24', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 7,     A_ACT: 0,    A_REM: 7,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'POWER SUPPLY DISTRIBUTION CABINET', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-U-2102', CSTD_PLAN: '2024-04-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 7,    B_ACT: 6,   B_REM: 1,    B_PRO: 85,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MEDIUM PRESSURE STEAM UTILITY STATION', },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0301', CSTD_PLAN: '2024-02-15', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-8',  A_TOT: 116,   A_ACT: 28,   A_REM: 88,    A_PRO: 24,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'CLEAN STORM WATER SYSTEM - NORTH AREA', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-C-0801', CSTD_PLAN: '2024-01-25', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 408,   A_ACT: 168,  A_REM: 240,   A_PRO: 41,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Cable Trays', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-E-1101', CSTD_PLAN: '2024-04-01', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 73,    A_ACT: 17,   A_REM: 56,    A_PRO: 23,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0,   SUBSYS_DESC: 'Lighting, Welding Outlets & Sockets', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-I-0201', CSTD_PLAN: '2024-09-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 28,    A_ACT: 0,    A_REM: 28,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FO & NETWORK CABINETS', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-P-0201', CSTD_PLAN: '2024-06-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 610,   A_ACT: 224,  A_REM: 386,   A_PRO: 36,  A_STATUS: 'INPROGRESS', AP_TOT: 7,   AP_ACT: 0,  AP_REM: 7,  AP_PRO: 0,   B_TOT: 169,  B_ACT: 51,  B_REM: 118,  B_PRO: 30,  B_STATUS: 'INPROGRESS', BP_TOT: 6,  BP_ACT: 0,  BP_REM: 6,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 6,   CP_ACT: 0,   CP_REM: 6,  CP_PRO: 0,   SUBSYS_DESC: 'HP Separation System', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-P-0601', CSTD_PLAN: '2024-08-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 173,   A_ACT: 96,   A_REM: 77,    A_PRO: 55,  A_STATUS: 'INPROGRESS', AP_TOT: 9,   AP_ACT: 0,  AP_REM: 9,  AP_PRO: 0,   B_TOT: 96,   B_ACT: 54,  B_REM: 42,   B_PRO: 56,  B_STATUS: 'INPROGRESS', BP_TOT: 7,  BP_ACT: 0,  BP_REM: 7,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 14,  CP_ACT: 0,   CP_REM: 14, CP_PRO: 0,   SUBSYS_DESC: 'RELIEF  SYSTEM', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-U-1101', CSTD_PLAN: '2024-02-08', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-23', A_TOT: 54,    A_ACT: 22,   A_REM: 32,    A_PRO: 40,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 44,   B_ACT: 22,  B_REM: 22,   B_PRO: 50,  B_STATUS: 'INPROGRESS', BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'Utility Air', },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         SUBSYS_NO: '20100-U-6001', CSTD_PLAN: '2024-08-12', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-26', A_TOT: 90,    A_ACT: 34,   A_REM: 56,    A_PRO: 37,  A_STATUS: 'INPROGRESS', AP_TOT: 7,   AP_ACT: 0,  AP_REM: 7,  AP_PRO: 0,   B_TOT: 47,   B_ACT: 20,  B_REM: 27,   B_PRO: 42,  B_STATUS: 'INPROGRESS', BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'LP FLUSHING OIL', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-C-0101', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 26,    A_ACT: 0,    A_REM: 26,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Piling & Foundations', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-C-0501', CSTD_PLAN: '2024-03-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 8,     A_ACT: 2,    A_REM: 6,     A_PRO: 25,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Main Steel Structures and Piperacks', },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        SUBSYS_NO: '20101-E-1101', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 18,    A_ACT: 3,    A_REM: 15,    A_PRO: 16,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Lighting, Welding Outlets & Sockets', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0102', CSTD_PLAN: '2024-08-28', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 315,   A_ACT: 49,   A_REM: 266,   A_PRO: 15,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 99,   B_ACT: 35,  B_REM: 64,   B_PRO: 35,  B_STATUS: 'INPROGRESS', BP_TOT: 3,  BP_ACT: 0,  BP_REM: 3,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'START UP HEATER', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0108', CSTD_PLAN: '2024-07-08', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 112,   A_ACT: 24,   A_REM: 88,    A_PRO: 21,  A_STATUS: 'INPROGRESS', AP_TOT: 5,   AP_ACT: 0,  AP_REM: 5,  AP_PRO: 0,   B_TOT: 16,   B_ACT: 0,   B_REM: 16,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'START UP HEATER', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0301', CSTD_PLAN: '2024-09-12', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 737,   A_ACT: 208,  A_REM: 529,   A_PRO: 28,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 317,  B_ACT: 79,  B_REM: 238,  B_PRO: 24,  B_STATUS: 'INPROGRESS', BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 4,   C_ACT: 0, C_REM: 4,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'High Pressure Fractionator', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0401', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 289,   A_ACT: 59,   A_REM: 230,   A_PRO: 20,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 102,  B_ACT: 40,  B_REM: 62,   B_PRO: 39,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FUEL GAS PRODUCT', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0501', CSTD_PLAN: '2024-09-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 586,   A_ACT: 59,   A_REM: 527,   A_PRO: 10,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 260,  B_ACT: 44,  B_REM: 216,  B_PRO: 16,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 6,   C_ACT: 0, C_REM: 6,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Amine', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0602', CSTD_PLAN: '2024-07-24', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 78,    A_ACT: 20,   A_REM: 58,    A_PRO: 25,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 47,   B_ACT: 20,  B_REM: 27,   B_PRO: 42,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SOUR WATER', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0701', CSTD_PLAN: '2024-06-21', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 542,   A_ACT: 96,   A_REM: 446,   A_PRO: 17,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 106,  B_ACT: 5,   B_REM: 101,  B_PRO: 4,   B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'VACUUM FRACTIONATOR FEED FURNACE', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0702', CSTD_PLAN: '2024-09-14', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 969,   A_ACT: 88,   A_REM: 881,   A_PRO: 9,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 391,  B_ACT: 14,  B_REM: 377,  B_PRO: 3,   B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 4,   C_ACT: 0, C_REM: 4,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'VACUUM FRACTIONATOR', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-2102', CSTD_PLAN: '2024-04-24', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 102,   A_ACT: 76,   A_REM: 26,    A_PRO: 74,  A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,  AP_REM: 3,  AP_PRO: 0,   B_TOT: 71,   B_ACT: 52,  B_REM: 19,   B_PRO: 73,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MEDIUM PRESSURE STEAM UTILITY STATION', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-C-0101', CSTD_PLAN: '2024-05-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 48,    A_ACT: 9,    A_REM: 39,    A_PRO: 18,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Piling & Foundations', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0103', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 2693,  A_ACT: 293,  A_REM: 2400,  A_PRO: 10,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 578,  B_ACT: 8,   B_REM: 570,  B_PRO: 1,   B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'REACTORS', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0104', CSTD_PLAN: '2024-08-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 47,    A_ACT: 30,   A_REM: 17,    A_PRO: 63,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 7,    B_ACT: 2,   B_REM: 5,    B_PRO: 28,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'REACTION FEED HP', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0305', CSTD_PLAN: '2024-09-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 636,   A_ACT: 171,  A_REM: 465,   A_PRO: 26,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 263,  B_ACT: 70,  B_REM: 193,  B_PRO: 26,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'EHC 50/EHC120 / UNEX HI VIS', },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0501', CSTD_PLAN: '2024-08-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 85,    A_ACT: 17,   A_REM: 68,    A_PRO: 20,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 42,   B_ACT: 13,  B_REM: 29,   B_PRO: 31,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Sour Water', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-I-0201', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 41,    A_ACT: 0,    A_REM: 41,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FO & NETWORK CABINETS', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-I-0301', CSTD_PLAN: '2024-06-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 281,   A_ACT: 0,    A_REM: 281,   A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 58,   B_ACT: 0,   B_REM: 58,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SMART JUNCTION BOX & FO CABLES', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0302', CSTD_PLAN: '2024-06-25', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 514,   A_ACT: 105,  A_REM: 409,   A_PRO: 20,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 177,  B_ACT: 18,  B_REM: 159,  B_PRO: 10,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'OFF GAS/FUEL GAS', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0305', CSTD_PLAN: '2024-09-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 371,   A_ACT: 10,   A_REM: 361,   A_PRO: 2,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 153,  B_ACT: 0,   B_REM: 153,  B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'OFF GAS COMPRESSOR B', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20400-U-2401', CSTD_PLAN: '2024-04-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 54,    A_ACT: 20,   A_REM: 34,    A_PRO: 37,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 23,   B_ACT: 4,   B_REM: 19,   B_PRO: 17,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Steam Generation', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-U-3101', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 22,    A_ACT: 22,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 16,   B_ACT: 16,  B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Medium Pressure Condensate Solid Separation', },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        SUBSYS_NO: '20401-U-4601', CSTD_PLAN: '2024-05-25', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 3,     A_ACT: 1,    A_REM: 2,     A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Fire water AG', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-C-0501', CSTD_PLAN: '2024-01-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 7,     A_ACT: 2,    A_REM: 5,     A_PRO: 28,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Main Steel Structures and Piperacks', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-U-2103', CSTD_PLAN: '2024-06-14', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 44,    A_ACT: 16,   A_REM: 28,    A_PRO: 36,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 4,   C_ACT: 0, C_REM: 4,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MEDIUM PRESSURE STEAM TRACING', },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         SUBSYS_NO: '20500-U-4001', CSTD_PLAN: '2024-03-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 6,     A_ACT: 6,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Newater', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-I-0301', CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 36,    A_ACT: 0,    A_REM: 36,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 0,   B_REM: 8,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SMART JUNCTION BOX & FO CABLES', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-U-1001', CSTD_PLAN: '2024-01-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 42,    A_ACT: 18,   A_REM: 24,    A_PRO: 42,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 28,   B_ACT: 14,  B_REM: 14,   B_PRO: 50,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Instrument Air', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-U-3301', CSTD_PLAN: '2024-04-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 10,    A_ACT: 8,    A_REM: 2,     A_PRO: 80,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Low Pressure Boiler Feed Water', },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         SUBSYS_NO: '20600-U-4601', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIRE WATER AG', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-E-1001', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 12,    A_ACT: 6,    A_REM: 6,     A_PRO: 50,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Grounding & Lightning Protection', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-I-0101', CSTD_PLAN: '2024-05-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 104,   A_ACT: 45,   A_REM: 59,    A_PRO: 43,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 37,   B_ACT: 0,   B_REM: 37,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Fire & Gas detection & alarming devices', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-P-0102', CSTD_PLAN: '2024-06-03', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 228,   A_ACT: 55,   A_REM: 173,   A_PRO: 24,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 76,   B_ACT: 12,  B_REM: 64,   B_PRO: 15,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SWS ACID GAS FEED', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-T-0101', CSTD_PLAN: '2024-04-03', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 27,    A_ACT: 0,    A_REM: 27,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Field Telecommunication System', },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         SUBSYS_NO: '20700-U-2103', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 216,   A_ACT: 73,   A_REM: 143,   A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 29,  C_ACT: 0, C_REM: 29,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MEDIUM PRESSURE STEAM TRACING', },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         SUBSYS_NO: '20800-U-2103', CSTD_PLAN: '2024-09-14', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 140,   A_ACT: 41,   A_REM: 99,    A_PRO: 29,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 13,  C_ACT: 0, C_REM: 13,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MEDIUM PRESSURE STEAM TRACING', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0116', CSTD_PLAN: '2024-06-15', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-28', CSU_MS: 'MS-10', A_TOT: 64,    A_ACT: 46,   A_REM: 18,    A_PRO: 71,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 51,   B_ACT: 43,  B_REM: 8,    B_PRO: 84,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MCR', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0118', CSTD_PLAN: '2024-07-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 8,     A_ACT: 8,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SWS EFFLUENT', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0126', CSTD_PLAN: '2024-06-01', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 25,    A_ACT: 19,   A_REM: 6,     A_PRO: 76,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 24,   B_ACT: 20,  B_REM: 4,    B_PRO: 83,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SCGO Distribution', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-P-0201', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-28', CSU_MS: 'MS-10', A_TOT: 7,     A_ACT: 5,    A_REM: 2,     A_PRO: 71,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 5,   B_REM: 1,    B_PRO: 83,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SCGO TO PITCH', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-1202', CSTD_PLAN: '2024-05-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 61,    A_ACT: 29,   A_REM: 32,    A_PRO: 47,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 35,   B_ACT: 22,  B_REM: 13,   B_PRO: 62,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Nitrogen South Battery Limit', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-2201', CSTD_PLAN: '2024-06-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 542,   A_ACT: 184,  A_REM: 358,   A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 5,   AP_ACT: 0,  AP_REM: 5,  AP_PRO: 0,   B_TOT: 181,  B_ACT: 104, B_REM: 77,   B_PRO: 57,  B_STATUS: 'INPROGRESS', BP_TOT: 10, BP_ACT: 0,  BP_REM: 10, BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'High Pressure Steam', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-3402', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-10', A_TOT: 15,    A_ACT: 15,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 10,   B_ACT: 10,  B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'High Pressure Boiler Feed Water Norht BL', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-4002', CSTD_PLAN: '2024-05-31', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 26,    A_ACT: 14,   A_REM: 12,    A_PRO: 53,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 22,   B_ACT: 14,  B_REM: 8,    B_PRO: 63,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Newater North BL', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-5401', CSTD_PLAN: '2024-08-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-24', CSU_MS: 'MS-10', A_TOT: 74,    A_ACT: 13,   A_REM: 61,    A_PRO: 17,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 39,   B_ACT: 12,  B_REM: 27,   B_PRO: 30,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Waste Water PFB', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-6102', CSTD_PLAN: '2024-07-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Hot Oil Tracing', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-7101', CSTD_PLAN: '2024-06-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 66,    A_ACT: 38,   A_REM: 28,    A_PRO: 57,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 49,   B_ACT: 34,  B_REM: 15,   B_PRO: 69,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Natural Gas', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         SUBSYS_NO: '21100-U-7501', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-34', CSU_MS: 'MS-10', A_TOT: 24,    A_ACT: 20,   A_REM: 4,     A_PRO: 83,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 16,   B_ACT: 14,  B_REM: 2,    B_PRO: 87,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'HOT OIL TO / FROM CCFU SOLIDS', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-U-1101', CSTD_PLAN: '2024-02-01', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 9,     A_ACT: 6,    A_REM: 3,     A_PRO: 66,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Utility Air', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-U-2102', CSTD_PLAN: '2024-05-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 9,    B_ACT: 8,   B_REM: 1,    B_PRO: 88,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MEDIUM PRESSURE STEAM UTILITY STATION', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-U-4306', CSTD_PLAN: '2024-05-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 96,    A_ACT: 24,   A_REM: 72,    A_PRO: 25,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 72,   B_ACT: 24,  B_REM: 48,   B_PRO: 33,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'CW DISTRIBUTION TO TKN NORTH B.L', },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         SUBSYS_NO: '21200-U-4307', CSTD_PLAN: '2024-04-26', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 40,    A_ACT: 16,   A_REM: 24,    A_PRO: 40,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 32,   B_ACT: 16,  B_REM: 16,   B_PRO: 50,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'CW DISTRIBUTION TO TKS SOUTH B.L', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-I-0202', CSTD_PLAN: '2024-03-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 14,    A_ACT: 0,    A_REM: 14,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'POWER SUPPLY DISTRIBUTION CABINET', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-I-0301', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 32,    A_ACT: 20,   A_REM: 12,    A_PRO: 62,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Smart Junction Box & FO Cables', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-P-0101', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-24', CSU_MS: 'MS-10', A_TOT: 405,   A_ACT: 182,  A_REM: 223,   A_PRO: 44,  A_STATUS: 'INPROGRESS', AP_TOT: 11,  AP_ACT: 0,  AP_REM: 11, AP_PRO: 0,   B_TOT: 263,  B_ACT: 132, B_REM: 131,  B_PRO: 50,  B_STATUS: 'INPROGRESS', BP_TOT: 12, BP_ACT: 0,  BP_REM: 12, BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 4,   CP_ACT: 0,   CP_REM: 4,  CP_PRO: 0,   SUBSYS_DESC: 'MAIN FLARE HEADER', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-U-1202', CSTD_PLAN: '2024-04-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 18,    A_ACT: 18,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 18,   B_ACT: 18,  B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'NITROGEN UTILITY STATION', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-U-2003', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 8,     A_ACT: 4,    A_REM: 4,     A_PRO: 50,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'LOW PRESSURE STEAM TRACING', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-U-2102', CSTD_PLAN: '2024-04-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 21,    A_ACT: 21,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 15,   B_ACT: 14,  B_REM: 1,    B_PRO: 93,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MEDIUM PRESSURE STEAM UTILITY STATION', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-P-0301', CSTD_PLAN: '2024-07-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-28', CSU_MS: 'MS-10', A_TOT: 188,   A_ACT: 27,   A_REM: 161,   A_PRO: 14,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 75,   B_ACT: 17,  B_REM: 58,   B_PRO: 22,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MCR', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-C-0101', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 24,    A_ACT: 0,    A_REM: 24,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Piling & Foundations', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-E-1001', CSTD_PLAN: '2024-05-15', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-17', A_TOT: 7,     A_ACT: 1,    A_REM: 6,     A_PRO: 14,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Grounding & Lightning Protection', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0201', CSTD_PLAN: '2024-06-15', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-17', A_TOT: 464,   A_ACT: 264,  A_REM: 200,   A_PRO: 56,  A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,  AP_REM: 3,  AP_PRO: 0,   B_TOT: 204,  B_ACT: 76,  B_REM: 128,  B_PRO: 37,  B_STATUS: 'INPROGRESS', BP_TOT: 7,  BP_ACT: 0,  BP_REM: 7,  BP_PRO: 0,   C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 4,   CP_ACT: 0,   CP_REM: 4,  CP_PRO: 0,   SUBSYS_DESC: 'Tempered Water', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-1001', CSTD_PLAN: '2024-01-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-9',  A_TOT: 14,    A_ACT: 14,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 10,   B_ACT: 10,  B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'Instrument Air', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-2003', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-17', A_TOT: 8,     A_ACT: 4,    A_REM: 4,     A_PRO: 50,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'LOW PRESSURE STEAM TRACING', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-4301', CSTD_PLAN: '2024-03-30', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-8',  A_TOT: 30,    A_ACT: 12,   A_REM: 18,    A_PRO: 40,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 24,   B_ACT: 12,  B_REM: 12,   B_PRO: 50,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Cooling water', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-6004', CSTD_PLAN: '2024-04-12', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-17', A_TOT: 11,    A_ACT: 11,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 17, BP_ACT: 0,  BP_REM: 17, BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'HHP FLUSH OIL', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-E-0801', CSTD_PLAN: '2024-02-07', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 10,    A_ACT: 10,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 5,    B_ACT: 5,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 5,   C_ACT: 0, C_REM: 5,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE-01 - Electrical IPDP UPS', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-T-0101', CSTD_PLAN: '2024-03-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 9,     A_ACT: 2,    A_REM: 7,     A_PRO: 22,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 01 - Telecommunication System', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0207', CSTD_PLAN: '2024-03-09', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 02 - MMS CABINETS', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-C-0801', CSTD_PLAN: '2023-12-02', CSTD_WALKDOWN: '2023-09-25', CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 102,   A_ACT: 94,   A_REM: 8,     A_PRO: 92,  A_STATUS: 'INPROGRESS', AP_TOT: 101, AP_ACT: 97, AP_REM: 4,  AP_PRO: 96,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 71, BP_ACT: 62, BP_REM: 9,  BP_PRO: 87,  C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 200, CP_ACT: 192, CP_REM: 8,  CP_PRO: 96,  SUBSYS_DESC: 'SSV - Conduit and Cable Tray Building', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0503', CSTD_PLAN: '2024-02-22', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-27', WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 2,   AP_ACT: 2,  AP_REM: 0,  AP_PRO: 100, B_TOT: 8,    B_ACT: 5,   B_REM: 3,    B_PRO: 62,  B_STATUS: 'INPROGRESS', BP_TOT: 5,  BP_ACT: 5,  BP_REM: 0,  BP_PRO: 100, C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0,   SUBSYS_DESC: 'SSV - 0,433KV POWER  SYSTEM SG-33-1-/2', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-C-0801', CSTD_PLAN: '2024-01-06', CSTD_WALKDOWN: '2023-10-13', CSTD_ACTUAL: '2023-11-20', WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 73,    A_ACT: 73,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 33,  AP_ACT: 33, AP_REM: 0,  AP_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 56, BP_ACT: 55, BP_REM: 1,  BP_PRO: 98,  C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 53,  CP_ACT: 51,  CP_REM: 2,  CP_PRO: 96,  SUBSYS_DESC: 'SSW - Conduit and Cable Tray Building', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0502', CSTD_PLAN: '2024-02-21', CSTD_WALKDOWN: '2023-10-23', CSTD_ACTUAL: '2023-11-06', WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 5,   AP_ACT: 4,  AP_REM: 1,  AP_PRO: 80,  B_TOT: 8,    B_ACT: 5,   B_REM: 3,    B_PRO: 62,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 2,   CP_ACT: 1,   CP_REM: 1,  CP_PRO: 50,  SUBSYS_DESC: 'SSW - 0,433KV POWER  SYSTEM SG-35-1/2', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-I-0101', CSTD_PLAN: '2024-03-26', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 1,   AP_ACT: 1,  AP_REM: 0,  AP_PRO: 100, B_TOT: 25,   B_ACT: 0,   B_REM: 25,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSW - Fire&Gas Building System', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0801', CSTD_PLAN: '2024-02-28', CSTD_WALKDOWN: '2023-09-20', CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 14,  AP_ACT: 3,  AP_REM: 11, AP_PRO: 21,  B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 11, BP_ACT: 0,  BP_REM: 11, BP_PRO: 0,   C_TOT: 8,   C_ACT: 0, C_REM: 8,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 14,  CP_ACT: 0,   CP_REM: 14, CP_PRO: 0,   SUBSYS_DESC: 'SSW - 240V AC UPS UPS-W-01', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21402-P-0201', CSTD_PLAN: '2024-06-07', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-28', CSU_MS: 'MS-10', A_TOT: 538,   A_ACT: 54,   A_REM: 484,   A_PRO: 10,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 235,  B_ACT: 28,  B_REM: 207,  B_PRO: 11,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 14,  C_ACT: 0, C_REM: 14,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0,   SUBSYS_DESC: 'EHC', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-P-0302', CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-28', CSU_MS: 'MS-10', A_TOT: 17,    A_ACT: 5,    A_REM: 12,    A_PRO: 29,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 11,   B_ACT: 6,   B_REM: 5,    B_PRO: 54,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'MCR TO ECA COMPONENT', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-U-1101', CSTD_PLAN: '2024-02-09', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 15,    A_ACT: 6,    A_REM: 9,     A_PRO: 40,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 12,   B_ACT: 6,   B_REM: 6,    B_PRO: 50,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Utility Air', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-U-4001', CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 6,     A_ACT: 6,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Newater', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0401', CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-17', A_TOT: 26,    A_ACT: 14,   A_REM: 12,    A_PRO: 53,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 20,   B_ACT: 12,  B_REM: 8,    B_PRO: 60,  B_STATUS: 'INPROGRESS', BP_TOT: 3,  BP_ACT: 0,  BP_REM: 3,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Closed Drain', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-3101', CSTD_PLAN: '2024-04-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-17', A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 3,  BP_ACT: 0,  BP_REM: 3,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'Medium Pressure Condensate', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0207', CSTD_PLAN: '2024-03-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 01 - MMS CABINETS', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-C-0801', CSTD_PLAN: '2024-01-09', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  A_TOT: 49,    A_ACT: 2,    A_REM: 47,    A_PRO: 4,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 02 - Conduit and Cable Tray Building', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-E-0801', CSTD_PLAN: '2024-01-31', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  A_TOT: 10,    A_ACT: 10,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 5,    B_ACT: 5,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 5,   C_ACT: 0, C_REM: 5,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 3,   CP_ACT: 0,   CP_REM: 3,  CP_PRO: 0,   SUBSYS_DESC: 'RIE-02 - Electrical IPDP UPS', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0204', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: '2023-10-18', CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 4,  BP_ACT: 1,  BP_REM: 3,  BP_PRO: 25,  C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 5,   CP_ACT: 0,   CP_REM: 5,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 02 - Fire & Gas System Cabinets', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-C-0101', CSTD_PLAN: '2023-09-22', CSTD_WALKDOWN: '2023-09-15', CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 112,   A_ACT: 111,  A_REM: 1,     A_PRO: 99,  A_STATUS: 'INPROGRESS', AP_TOT: 4,   AP_ACT: 3,  AP_REM: 1,  AP_PRO: 75,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 29, BP_ACT: 29, BP_REM: 0,  BP_PRO: 100, C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, SUBSYS_DESC: 'Piling & Foundations', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-C-0701', CSTD_PLAN: '2023-12-05', CSTD_WALKDOWN: '2023-09-08', CSTD_ACTUAL: '2023-11-30', WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 2,     A_ACT: 2,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 6,   AP_ACT: 6,  AP_REM: 0,  AP_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 15, BP_ACT: 10, BP_REM: 5,  BP_PRO: 66,  C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 14,  CP_ACT: 2,   CP_REM: 12, CP_PRO: 14,  SUBSYS_DESC: 'SSV - Civil Structure & Archit. Finishing', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0401', CSTD_PLAN: '2023-12-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 61,    A_ACT: 0,    A_REM: 61,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSV - 0,433KV EMERGENCY DIESEL GENERATOR GEN-V-1', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-I-0201', CSTD_PLAN: '2024-02-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 4,     A_ACT: 2,    A_REM: 2,     A_PRO: 50,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 7,    B_ACT: 5,   B_REM: 2,    B_PRO: 71,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSV - DCS CABINETS', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0402', CSTD_PLAN: '2024-01-24', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSW - 0,433KV Emergency Power System', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0701', CSTD_PLAN: '2024-02-16', CSTD_WALKDOWN: '2023-09-20', CSTD_ACTUAL: '2023-11-06', WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 2,     A_ACT: 2,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 5,   AP_ACT: 2,  AP_REM: 3,  AP_PRO: 40,  B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 6,  BP_ACT: 6,  BP_REM: 0,  BP_PRO: 100, C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 6,   CP_ACT: 0,   CP_REM: 6,  CP_PRO: 0,   SUBSYS_DESC: 'SSW - 110V DC UPS BC-W-1', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-H-0101', CSTD_PLAN: '2024-02-03', CSTD_WALKDOWN: '2023-10-24', CSTD_ACTUAL: '2023-11-06', WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 4,   AP_ACT: 3,  AP_REM: 1,  AP_PRO: 75,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 6,  BP_ACT: 6,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 8,   CP_ACT: 1,   CP_REM: 7,  CP_PRO: 12,  SUBSYS_DESC: 'SSW - HVAC', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-C-0501', CSTD_PLAN: '2024-02-03', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 26,    A_ACT: 11,   A_REM: 15,    A_PRO: 42,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Main Steel Structures and Piperacks', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-E-1301', CSTD_PLAN: '2024-01-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 995,   A_ACT: 254,  A_REM: 741,   A_PRO: 25,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 122,  B_ACT: 0,   B_REM: 122,  B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 211, C_ACT: 0, C_REM: 211, C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Electrical Tracing', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21402-P-0101', CSTD_PLAN: '2024-05-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-28', CSU_MS: 'MS-10', A_TOT: 452,   A_ACT: 14,   A_REM: 438,   A_PRO: 3,   A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 127,  B_ACT: 6,   B_REM: 121,  B_PRO: 4,   B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'LN HC8', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-U-1202', CSTD_PLAN: '2024-04-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 6,     A_ACT: 6,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, SUBSYS_DESC: 'NITROGEN UTILITY STATION', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0102', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-17', A_TOT: 40,    A_ACT: 37,   A_REM: 3,     A_PRO: 92,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 14,   B_ACT: 10,  B_REM: 4,    B_PRO: 71,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 5,   CP_ACT: 0,   CP_REM: 5,  CP_PRO: 0,   SUBSYS_DESC: 'UGO', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0103', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-28', CSU_MS: 'MS-35', A_TOT: 253,   A_ACT: 111,  A_REM: 142,   A_PRO: 43,  A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,  AP_REM: 3,  AP_PRO: 0,   B_TOT: 91,   B_ACT: 19,  B_REM: 72,   B_PRO: 20,  B_STATUS: 'INPROGRESS', BP_TOT: 4,  BP_ACT: 0,  BP_REM: 4,  BP_PRO: 0,   C_TOT: 4,   C_ACT: 0, C_REM: 4,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 4,   CP_ACT: 0,   CP_REM: 4,  CP_PRO: 0,   SUBSYS_DESC: 'SCGO / MCR FLUSH OIL TO PITCH', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0105', CSTD_PLAN: '2024-06-07', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-17', A_TOT: 33,    A_ACT: 33,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0,   SUBSYS_DESC: 'NON-PITCH', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-1101', CSTD_PLAN: '2024-02-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-9',  A_TOT: 17,    A_ACT: 17,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 18,   B_ACT: 18,  B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Utility Air', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-H-0101', CSTD_PLAN: '2024-02-14', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 3,   B_REM: 3,    B_PRO: 50,  B_STATUS: 'INPROGRESS', BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 01 – HVAC', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0203', CSTD_PLAN: '2024-03-30', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 01 - FO & Network Cabinets', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0201', CSTD_PLAN: '2024-02-09', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 17,  AP_ACT: 10, AP_REM: 7,  AP_PRO: 58,  B_TOT: 16,   B_ACT: 14,  B_REM: 2,    B_PRO: 87,  B_STATUS: 'INPROGRESS', BP_TOT: 5,  BP_ACT: 5,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 02 - DCS Cabinets', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0301', CSTD_PLAN: '2024-02-27', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-28', WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 74,    A_ACT: 74,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 31,  AP_ACT: 31, AP_REM: 0,  AP_PRO: 100, B_TOT: 16,   B_ACT: 15,  B_REM: 1,    B_PRO: 93,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 7,   CP_ACT: 2,   CP_REM: 5,  CP_PRO: 28,  SUBSYS_DESC: 'SSV - 6,9KV POWER SYSTEM SG-V-3/4', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-1101', CSTD_PLAN: '2024-03-06', CSTD_WALKDOWN: '2023-09-07', CSTD_ACTUAL: '2023-10-08', WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 4,   AP_ACT: 2,  AP_REM: 2,  AP_PRO: 50,  B_TOT: 5,    B_ACT: 5,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 2,  BP_ACT: 2,  BP_REM: 0,  BP_PRO: 100, C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 6,   CP_ACT: 0,   CP_REM: 6,  CP_PRO: 0,   SUBSYS_DESC: 'SSV Indoor/ Outdoor  Lighting & Small Power', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-I-0202', CSTD_PLAN: '2024-02-14', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 4,     A_ACT: 2,    A_REM: 2,     A_PRO: 50,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 1,    B_ACT: 1,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSV - SIS CABINETS', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-T-0101', CSTD_PLAN: '2024-02-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 12,    A_ACT: 2,    A_REM: 10,    A_PRO: 16,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSV - Telecommunication System', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0501', CSTD_PLAN: '2024-02-14', CSTD_WALKDOWN: '2023-10-23', CSTD_ACTUAL: '2023-11-03', WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 4,   AP_ACT: 2,  AP_REM: 2,  AP_PRO: 50,  B_TOT: 8,    B_ACT: 5,   B_REM: 3,    B_PRO: 62,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0,   SUBSYS_DESC: 'SSW - 0,433KV POWER  SYSTEM SG-34-1/2', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-1001', CSTD_PLAN: '2024-03-08', CSTD_WALKDOWN: '2023-10-23', CSTD_ACTUAL: '2023-11-06', WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 3,     A_ACT: 3,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 5,   AP_ACT: 4,  AP_REM: 1,  AP_PRO: 80,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 5,  BP_ACT: 5,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'SSW - Grounding & Lightning Protection', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-1201', CSTD_PLAN: '2024-01-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSW - INTERPOSING RELAY PANEL IO-W-1', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-T-0101', CSTD_PLAN: '2024-02-28', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSW - Telecommunication System', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-U-4001', CSTD_PLAN: '2024-04-11', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 15,    A_ACT: 12,   A_REM: 3,     A_PRO: 80,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 14,   B_ACT: 12,  B_REM: 2,    B_PRO: 85,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Newater', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-C-0101', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 157,   A_ACT: 8,    A_REM: 149,   A_PRO: 5,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Piling & Foundations', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-P-0201', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-28', CSU_MS: 'MS-10', A_TOT: 586,   A_ACT: 84,   A_REM: 502,   A_PRO: 14,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 184,  B_ACT: 36,  B_REM: 148,  B_PRO: 19,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'DAO Tank', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-U-4601', CSTD_PLAN: '2024-04-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIRE WATER AG', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-C-0401', CSTD_PLAN: '2024-07-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 6,     A_ACT: 0,    A_REM: 6,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Paving & Civil Works', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-E-1001', CSTD_PLAN: '2024-05-15', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 51,    A_ACT: 0,    A_REM: 51,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Grounding & Lightning Protection', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-I-0201', CSTD_PLAN: '2024-04-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FO & NETWORK CABINETS', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21402-P-0102', CSTD_PLAN: '2024-05-21', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-28', CSU_MS: 'MS-10', A_TOT: 403,   A_ACT: 14,   A_REM: 389,   A_PRO: 3,   A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 115,  B_ACT: 6,   B_REM: 109,  B_PRO: 5,   B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'HN HC8', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-P-0501', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 88,    A_ACT: 14,   A_REM: 74,    A_PRO: 15,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 40,   B_ACT: 8,   B_REM: 32,   B_PRO: 20,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'UnEX TANKS', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-U-1001', CSTD_PLAN: '2023-12-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 28,    A_ACT: 18,   A_REM: 10,    A_PRO: 64,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 16,   B_ACT: 12,  B_REM: 4,    B_PRO: 75,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'INSTRUMENT AIR', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-C-0401', CSTD_PLAN: '2024-06-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-10', A_TOT: 22,    A_ACT: 0,    A_REM: 22,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Paving & Civil Works', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-C-0801', CSTD_PLAN: '2024-01-25', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-26', CSU_MS: 'MS-17', A_TOT: 95,    A_ACT: 56,   A_REM: 39,    A_PRO: 58,  A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,  AP_REM: 3,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Cable Trays', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-2101', CSTD_PLAN: '2024-04-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-17', A_TOT: 25,    A_ACT: 17,   A_REM: 8,     A_PRO: 68,  A_STATUS: 'INPROGRESS', AP_TOT: 7,   AP_ACT: 0,  AP_REM: 7,  AP_PRO: 0,   B_TOT: 15,   B_ACT: 10,  B_REM: 5,    B_PRO: 66,  B_STATUS: 'INPROGRESS', BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'Medium Pressure Steam', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-3201', CSTD_PLAN: '2024-05-07', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-17', A_TOT: 108,   A_ACT: 78,   A_REM: 30,    A_PRO: 72,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 48,   B_ACT: 31,  B_REM: 17,   B_PRO: 64,  B_STATUS: 'INPROGRESS', BP_TOT: 9,  BP_ACT: 0,  BP_REM: 9,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 6,   CP_ACT: 0,   CP_REM: 6,  CP_PRO: 0,   SUBSYS_DESC: 'High Pressure Condensate', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-3401', CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-17', A_TOT: 24,    A_ACT: 22,   A_REM: 2,     A_PRO: 91,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80,  B_STATUS: 'INPROGRESS', BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'High Pressure Boiler Feed Water', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-4101', CSTD_PLAN: '2024-04-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-17', A_TOT: 13,    A_ACT: 6,    A_REM: 7,     A_PRO: 46,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Potable Water', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-C-0801', CSTD_PLAN: '2024-01-05', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 01 - Conduit and Cable Tray Building', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0206', CSTD_PLAN: '2024-02-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 01 - Auxiliary Panels / Other Systems', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-C-0101', CSTD_PLAN: '2024-01-22', CSTD_WALKDOWN: '2023-09-15', CSTD_ACTUAL: '2023-10-07', WAYPOINT: 'WP-26', CSU_MS: 'MS-3',  A_TOT: 8,     A_ACT: 8,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Piling & Foundations', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-C-0701', CSTD_PLAN: '2024-01-22', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  A_TOT: 2,     A_ACT: 1,    A_REM: 1,     A_PRO: 50,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 02 - Civil Structure & Archit. Finishing', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-E-1101', CSTD_PLAN: '2023-11-03', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  A_TOT: 2,     A_ACT: 2,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 02 - INDOOR / OUTDOOR LIGHTING & SMALL POWER', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-H-0101', CSTD_PLAN: '2024-02-14', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 3,   B_REM: 3,    B_PRO: 50,  B_STATUS: 'INPROGRESS', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 02 - HVAC', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0101', CSTD_PLAN: '2024-04-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 15,   B_ACT: 5,   B_REM: 10,   B_PRO: 33,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 02 - Fire&Gas Building System', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0502', CSTD_PLAN: '2024-02-27', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-27', WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 3,   AP_ACT: 3,  AP_REM: 0,  AP_PRO: 100, B_TOT: 8,    B_ACT: 5,   B_REM: 3,    B_PRO: 62,  B_STATUS: 'INPROGRESS', BP_TOT: 3,  BP_ACT: 3,  BP_REM: 0,  BP_PRO: 100, C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 3,   CP_ACT: 0,   CP_REM: 3,  CP_PRO: 0,   SUBSYS_DESC: 'SSV - 0,433KV POWER  SYSTEM SG-32-1/2', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-H-0101', CSTD_PLAN: '2024-02-23', CSTD_WALKDOWN: '2023-09-06', CSTD_ACTUAL: '2023-11-30', WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 5,   AP_ACT: 4,  AP_REM: 1,  AP_PRO: 80,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 4,  BP_ACT: 4,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, SUBSYS_DESC: 'SSV - HVAC', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0101', CSTD_PLAN: '2024-01-22', CSTD_WALKDOWN: '2023-10-25', CSTD_ACTUAL: '2023-11-07', WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 71,    A_ACT: 71,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 12,  AP_ACT: 11, AP_REM: 1,  AP_PRO: 91,  B_TOT: 10,   B_ACT: 10,  B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 2,  BP_ACT: 2,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSW - 33KV DISTRIBUTION  SYSTEM - A', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0102', CSTD_PLAN: '2024-01-22', CSTD_WALKDOWN: '2023-10-25', CSTD_ACTUAL: '2023-11-01', WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 71,    A_ACT: 71,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 6,   AP_ACT: 5,  AP_REM: 1,  AP_PRO: 83,  B_TOT: 10,   B_ACT: 10,  B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 9,  BP_ACT: 9,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'SSW - 33KV DISTRIBUTION  SYSTEM - B', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0401', CSTD_PLAN: '2024-03-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 61,    A_ACT: 32,   A_REM: 29,    A_PRO: 52,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSW - 0,433KV EMERGENCY DIESEL GENERATOR GEN-W-1', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0603', CSTD_PLAN: '2024-02-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 42,    A_ACT: 0,    A_REM: 42,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSW - 0,415V TURN ARROUND POWER CENTER TAPC-34/35 - PANEL', },
    { SCOPE: 'Grassroots', UNIT: 'DS',          SUBSYS_NO: '21300-U-7101', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-24', CSU_MS: 'MS-10', A_TOT: 32,    A_ACT: 11,   A_REM: 21,    A_PRO: 34,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 14,   B_ACT: 6,   B_REM: 8,    B_PRO: 42,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Natural Gas', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-U-1101', CSTD_PLAN: '2024-02-06', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 7,     A_ACT: 4,    A_REM: 3,     A_PRO: 57,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 4,   B_REM: 2,    B_PRO: 66,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'UTILITY AIR', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-P-0104', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 127,   A_ACT: 13,   A_REM: 114,   A_PRO: 10,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 48,   B_ACT: 6,   B_REM: 42,   B_PRO: 12,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'INTRMEDIATE PUMPS', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-T-0101', CSTD_PLAN: '2024-03-04', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-10', A_TOT: 41,    A_ACT: 0,    A_REM: 41,    A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Field Telecommunication System', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0101', CSTD_PLAN: '2024-05-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-31', CSU_MS: 'MS-27', A_TOT: 45,    A_ACT: 40,   A_REM: 5,     A_PRO: 88,  A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,  AP_REM: 3,  AP_PRO: 0,   B_TOT: 15,   B_ACT: 10,  B_REM: 5,    B_PRO: 66,  B_STATUS: 'INPROGRESS', BP_TOT: 4,  BP_ACT: 0,  BP_REM: 4,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 5,   CP_ACT: 0,   CP_REM: 5,  CP_PRO: 0,   SUBSYS_DESC: 'PITCH', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-C-0101', CSTD_PLAN: '2023-12-20', CSTD_WALKDOWN: '2023-10-20', CSTD_ACTUAL: '2023-11-20', WAYPOINT: 'WP-26', CSU_MS: 'MS-2',  A_TOT: 5,     A_ACT: 5,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 3,  BP_ACT: 2,  BP_REM: 1,  BP_PRO: 66,  C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Piling & Foundations', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-C-0701', CSTD_PLAN: '2024-01-31', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 01 - Civil Structure & Archit. Finishing', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0202', CSTD_PLAN: '2024-02-26', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 12,   B_ACT: 8,   B_REM: 4,    B_PRO: 66,  B_STATUS: 'INPROGRESS', BP_TOT: 4,  BP_ACT: 0,  BP_REM: 4,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 01 - SIS Cabinets', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0202', CSTD_PLAN: '2024-03-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 18,   B_ACT: 15,  B_REM: 3,    B_PRO: 83,  B_STATUS: 'INPROGRESS', BP_TOT: 12, BP_ACT: 0,  BP_REM: 12, BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 02 - SIS Cabinets', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-T-0101', CSTD_PLAN: '2024-03-07', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  A_TOT: 19,    A_ACT: 13,   A_REM: 6,     A_PRO: 68,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 02 - Telecommunication System', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-C-5401', CSTD_PLAN: '2024-02-02', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 23,    A_ACT: 20,   A_REM: 3,     A_PRO: 87,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 2,  BP_ACT: 2,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'POTENTIALLY CONTAMINATED STORM WATER', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0402', CSTD_PLAN: '2024-02-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSV - 0,433KV Emergency Power System', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0603', CSTD_PLAN: '2024-02-20', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'NA',    A_TOT: 28,    A_ACT: 11,   A_REM: 17,    A_PRO: 39,  A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,  AP_REM: 3,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSV - 0,415V TURN ARROUND POWER CENTER TAPC-31/33 - B', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-C-0501', CSTD_PLAN: '2023-12-27', CSTD_WALKDOWN: '2023-10-20', CSTD_ACTUAL: '2023-11-02', WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 1,     A_ACT: 1,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 8,   AP_ACT: 8,  AP_REM: 0,  AP_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 6,  BP_ACT: 6,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 19,  CP_ACT: 0,   CP_REM: 19, CP_PRO: 0,   SUBSYS_DESC: 'STEEL STRUCTURE FOR TRANSFORMER-SSW AREA', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-C-5401', CSTD_PLAN: '2024-01-31', CSTD_WALKDOWN: '2023-10-26', CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 18,    A_ACT: 10,   A_REM: 8,     A_PRO: 55,  A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 2,  AP_REM: 1,  AP_PRO: 66,  B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 1,  BP_ACT: 1,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 3,   CP_ACT: 1,   CP_REM: 2,  CP_PRO: 33,  SUBSYS_DESC: 'POTENTIALLY CONTAMINATED STORM WATER', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0901', CSTD_PLAN: '2024-01-26', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSW - ELECTRICAL POWER MONITORING SYSTEM  EPMS-W-1', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-I-0202', CSTD_PLAN: '2024-02-21', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 5,     A_ACT: 0,    A_REM: 5,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSW - SIS CABINETS', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-U-4601', CSTD_PLAN: '2024-07-03', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-19', CSU_MS: 'MS-10', A_TOT: 19,    A_ACT: 4,    A_REM: 15,    A_PRO: 21,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 15,   B_ACT: 0,   B_REM: 15,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'FIRE WATER AG', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-E-1101', CSTD_PLAN: '2024-03-16', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-17', A_TOT: 8,     A_ACT: 7,    A_REM: 1,     A_PRO: 87,  A_STATUS: 'INPROGRESS', AP_TOT: 4,   AP_ACT: 0,  AP_REM: 4,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Lighting, Welding Outlets & Sockets', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-E-1301', CSTD_PLAN: '2024-01-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-17', A_TOT: 227,   A_ACT: 95,   A_REM: 132,   A_PRO: 41,  A_STATUS: 'INPROGRESS', AP_TOT: 10,  AP_ACT: 0,  AP_REM: 10, AP_PRO: 0,   B_TOT: 21,   B_ACT: 0,   B_REM: 21,   B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 43,  C_ACT: 0, C_REM: 43,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'Electrical Tracing', },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       SUBSYS_NO: '21403-I-0301', CSTD_PLAN: '2024-04-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'NA',    CSU_MS: 'MS-17', A_TOT: 9,     A_ACT: 0,    A_REM: 9,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SMART JUNCTION BOX & FO CABLES', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0101', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 22,   B_ACT: 1,   B_REM: 21,   B_PRO: 4,   B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 01 - Fire&Gas Building System', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0204', CSTD_PLAN: '2024-04-09', CSTD_WALKDOWN: '2023-10-18', CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 5,  BP_ACT: 1,  BP_REM: 4,  BP_PRO: 20,  C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 4,   CP_ACT: 0,   CP_REM: 4,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 01 - Fire & Gas System Cabinets', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0205', CSTD_PLAN: '2024-02-29', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 1,    B_ACT: 1,   B_REM: 0,    B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'RIE 01 - PLC Cabinets', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0501', CSTD_PLAN: '2024-03-02', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-27', WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 2,   AP_ACT: 2,  AP_REM: 0,  AP_PRO: 100, B_TOT: 8,    B_ACT: 5,   B_REM: 3,    B_PRO: 62,  B_STATUS: 'INPROGRESS', BP_TOT: 4,  BP_ACT: 4,  BP_REM: 0,  BP_PRO: 100, C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   SUBSYS_DESC: 'SSV - 0,433KV POWER  SYSTEM SG-31-1/2', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0901', CSTD_PLAN: '2024-02-28', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,    B_ACT: 1,   B_REM: 1,    B_PRO: 50,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSV - ELECTRICAL POWER MONITORING SYSTEM  EPMS-V-1', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-1001', CSTD_PLAN: '2024-01-29', CSTD_WALKDOWN: '2023-09-08', CSTD_ACTUAL: '2023-10-08', WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 3,     A_ACT: 3,    A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 10,  AP_ACT: 10, AP_REM: 0,  AP_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 7,  BP_ACT: 7,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 5,   CP_ACT: 2,   CP_REM: 3,  CP_PRO: 40,  SUBSYS_DESC: 'SSV - Grounding & Lightning Protection', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-1201', CSTD_PLAN: '2024-02-17', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 4,    B_ACT: 3,   B_REM: 1,    B_PRO: 75,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSV - INTERPOSING RELAY PANEL IO-V-1', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     SUBSYS_NO: '21601-I-0101', CSTD_PLAN: '2024-02-14', CSTD_WALKDOWN: '2023-09-07', CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  A_TOT: 18,    A_ACT: 12,   A_REM: 6,     A_PRO: 66,  A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 1,  AP_REM: 0,  AP_PRO: 100, B_TOT: 28,   B_ACT: 8,   B_REM: 20,   B_PRO: 28,  B_STATUS: 'INPROGRESS', BP_TOT: 14, BP_ACT: 4,  BP_REM: 10, BP_PRO: 28,  C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSV - Fire&Gas Building System', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-C-0101', CSTD_PLAN: '2023-10-04', CSTD_WALKDOWN: '2023-10-05', CSTD_ACTUAL: '2023-11-20', WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 55,    A_ACT: 55,   A_REM: 0,     A_PRO: 100, A_STATUS: 'COMPLETED',  AP_TOT: 4,   AP_ACT: 4,  AP_REM: 0,  AP_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 65, BP_ACT: 65, BP_REM: 0,  BP_PRO: 100, C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 25,  CP_ACT: 25,  CP_REM: 0,  CP_PRO: 100, SUBSYS_DESC: 'Piling & Foundations', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-C-0401', CSTD_PLAN: '2024-03-06', CSTD_WALKDOWN: '2023-10-26', CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 10,    A_ACT: 7,    A_REM: 3,     A_PRO: 70,  A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   B_STATUS: 'NOTSTARTED', BP_TOT: 13, BP_ACT: 13, BP_REM: 0,  BP_PRO: 100, C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'PAVING & CIVIL WORKS', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     SUBSYS_NO: '21605-I-0201', CSTD_PLAN: '2024-02-13', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,         WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   A_STATUS: 'NOTSTARTED', AP_TOT: 2,   AP_ACT: 0,  AP_REM: 2,  AP_PRO: 0,   B_TOT: 6,    B_ACT: 4,   B_REM: 2,    B_PRO: 66,  B_STATUS: 'INPROGRESS', BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   SUBSYS_DESC: 'SSW- DCS CABINETS', },
  ],

  Queries: {
    // Chart Data
    SQL1: [
      { CDATE: '2023-07-07', PLAN_WK: 0,  ACT_WK: 0, PLAN_CUM: 0,  ACT_CUM: 0,    ACT_REM: 669, },
      { CDATE: '2023-07-14', PLAN_WK: 0,  ACT_WK: 0, PLAN_CUM: 0,  ACT_CUM: 0,    ACT_REM: 669, },
      { CDATE: '2023-07-21', PLAN_WK: 0,  ACT_WK: 0, PLAN_CUM: 0,  ACT_CUM: 0,    ACT_REM: 669, },
      { CDATE: '2023-07-28', PLAN_WK: 0,  ACT_WK: 0, PLAN_CUM: 0,  ACT_CUM: 0,    ACT_REM: 669, },
      { CDATE: '2023-08-04', PLAN_WK: 0,  ACT_WK: 0, PLAN_CUM: 0,  ACT_CUM: 0,    ACT_REM: 669, },
      { CDATE: '2023-08-11', PLAN_WK: 0,  ACT_WK: 0, PLAN_CUM: 0,  ACT_CUM: 0,    ACT_REM: 669, },
      { CDATE: '2023-08-18', PLAN_WK: 0,  ACT_WK: 0, PLAN_CUM: 0,  ACT_CUM: 0,    ACT_REM: 669, },
      { CDATE: '2023-08-25', PLAN_WK: 0,  ACT_WK: 0, PLAN_CUM: 0,  ACT_CUM: 0,    ACT_REM: 669, },
      { CDATE: '2023-09-01', PLAN_WK: 0,  ACT_WK: 0, PLAN_CUM: 0,  ACT_CUM: 0,    ACT_REM: 669, },
      { CDATE: '2023-09-08', PLAN_WK: 0,  ACT_WK: 0, PLAN_CUM: 0,  ACT_CUM: 0,    ACT_REM: 669, },
      { CDATE: '2023-09-15', PLAN_WK: 0,  ACT_WK: 0, PLAN_CUM: 0,  ACT_CUM: 0,    ACT_REM: 669, },
      { CDATE: '2023-09-22', PLAN_WK: 1,  ACT_WK: 0, PLAN_CUM: 1,  ACT_CUM: 0,    ACT_REM: 669, },
      { CDATE: '2023-09-29', PLAN_WK: 0,  ACT_WK: 0, PLAN_CUM: 1,  ACT_CUM: 0,    ACT_REM: 669, },
      { CDATE: '2023-10-06', PLAN_WK: 1,  ACT_WK: 0, PLAN_CUM: 2,  ACT_CUM: 0,    ACT_REM: 669, },
      { CDATE: '2023-10-13', PLAN_WK: 1,  ACT_WK: 7, PLAN_CUM: 3,  ACT_CUM: 7,    ACT_REM: 662, },
      { CDATE: '2023-10-20', PLAN_WK: 0,  ACT_WK: 0, PLAN_CUM: 3,  ACT_CUM: 7,    ACT_REM: 662, },
      { CDATE: '2023-10-27', PLAN_WK: 0,  ACT_WK: 0, PLAN_CUM: 3,  ACT_CUM: 7,    ACT_REM: 662, },
      { CDATE: '2023-11-03', PLAN_WK: 2,  ACT_WK: 5, PLAN_CUM: 5,  ACT_CUM: 12,   ACT_REM: 657, },
      { CDATE: '2023-11-10', PLAN_WK: 0,  ACT_WK: 7, PLAN_CUM: 5,  ACT_CUM: 19,   ACT_REM: 650, },
      { CDATE: '2023-11-17', PLAN_WK: 0,  ACT_WK: 0, PLAN_CUM: 5,  ACT_CUM: 19,   ACT_REM: 650, },
      { CDATE: '2023-11-24', PLAN_WK: 1,  ACT_WK: 3, PLAN_CUM: 6,  ACT_CUM: 22,   ACT_REM: 647, },
      { CDATE: '2023-12-01', PLAN_WK: 0,  ACT_WK: 8, PLAN_CUM: 6,  ACT_CUM: 30,   ACT_REM: 639, },
      { CDATE: '2023-12-08', PLAN_WK: 1,  ACT_WK: 0, PLAN_CUM: 7,  ACT_CUM: 30,   ACT_REM: 639, },
      { CDATE: '2023-12-15', PLAN_WK: 4,  ACT_WK: 0, PLAN_CUM: 11, ACT_CUM: 30,   ACT_REM: 639, },
      { CDATE: '2023-12-22', PLAN_WK: 1,  ACT_WK: 0, PLAN_CUM: 12, ACT_CUM: 30,   ACT_REM: 639, },
      { CDATE: '2023-12-29', PLAN_WK: 1,  ACT_WK: 0, PLAN_CUM: 13, ACT_CUM: 30,   ACT_REM: 639, },
      { CDATE: '2024-01-05', PLAN_WK: 4,  ACT_WK: 1, PLAN_CUM: 17, ACT_CUM: 31,   ACT_REM: 638, },
      { CDATE: '2024-01-12', PLAN_WK: 2,  ACT_WK: 0, PLAN_CUM: 19, ACT_CUM: 31,   ACT_REM: 638, },
      { CDATE: '2024-01-19', PLAN_WK: 3,  ACT_WK: 0, PLAN_CUM: 22, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-01-26', PLAN_WK: 5,  ACT_WK: 0, PLAN_CUM: 27, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-02-02', PLAN_WK: 15, ACT_WK: 0, PLAN_CUM: 42, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-02-09', PLAN_WK: 13, ACT_WK: 0, PLAN_CUM: 55, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-02-16', PLAN_WK: 5,  ACT_WK: 0, PLAN_CUM: 60, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-02-23', PLAN_WK: 16, ACT_WK: 0, PLAN_CUM: 76, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-03-01', PLAN_WK: 22, ACT_WK: 0, PLAN_CUM: 98, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-03-08', PLAN_WK: 23, ACT_WK: 0, PLAN_CUM: 121, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-03-15', PLAN_WK: 16, ACT_WK: 0, PLAN_CUM: 137, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-03-22', PLAN_WK: 14, ACT_WK: 0, PLAN_CUM: 151, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-03-29', PLAN_WK: 18, ACT_WK: 0, PLAN_CUM: 169, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-04-05', PLAN_WK: 8,  ACT_WK: 0, PLAN_CUM: 177, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-04-12', PLAN_WK: 4,  ACT_WK: 0, PLAN_CUM: 181, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-04-19', PLAN_WK: 17, ACT_WK: 0, PLAN_CUM: 198, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-04-26', PLAN_WK: 26, ACT_WK: 0, PLAN_CUM: 224, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-05-03', PLAN_WK: 26, ACT_WK: 0, PLAN_CUM: 250, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-05-10', PLAN_WK: 23, ACT_WK: 0, PLAN_CUM: 273, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-05-17', PLAN_WK: 30, ACT_WK: 0, PLAN_CUM: 303, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-05-24', PLAN_WK: 23, ACT_WK: 0, PLAN_CUM: 326, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-05-31', PLAN_WK: 45, ACT_WK: 0, PLAN_CUM: 371, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-06-07', PLAN_WK: 34, ACT_WK: 0, PLAN_CUM: 405, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-06-14', PLAN_WK: 34, ACT_WK: 0, PLAN_CUM: 439, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-06-21', PLAN_WK: 38, ACT_WK: 0, PLAN_CUM: 477, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-06-28', PLAN_WK: 33, ACT_WK: 0, PLAN_CUM: 510, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-07-05', PLAN_WK: 36, ACT_WK: 0, PLAN_CUM: 546, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-07-12', PLAN_WK: 23, ACT_WK: 0, PLAN_CUM: 569, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-07-19', PLAN_WK: 15, ACT_WK: 0, PLAN_CUM: 584, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-07-26', PLAN_WK: 14, ACT_WK: 0, PLAN_CUM: 598, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-08-02', PLAN_WK: 8,  ACT_WK: 0, PLAN_CUM: 606, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-08-09', PLAN_WK: 11, ACT_WK: 0, PLAN_CUM: 617, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-08-16', PLAN_WK: 5,  ACT_WK: 0, PLAN_CUM: 622, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-08-23', PLAN_WK: 5,  ACT_WK: 0, PLAN_CUM: 627, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-08-30', PLAN_WK: 8,  ACT_WK: 0, PLAN_CUM: 635, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-09-06', PLAN_WK: 7,  ACT_WK: 0, PLAN_CUM: 642, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-09-13', PLAN_WK: 7,  ACT_WK: 0, PLAN_CUM: 649, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-09-20', PLAN_WK: 9,  ACT_WK: 0, PLAN_CUM: 658, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-09-27', PLAN_WK: 2,  ACT_WK: 0, PLAN_CUM: 660, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-10-04', PLAN_WK: 5,  ACT_WK: 0, PLAN_CUM: 665, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-10-11', PLAN_WK: 2,  ACT_WK: 0, PLAN_CUM: 667, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-10-18', PLAN_WK: 0,  ACT_WK: 0, PLAN_CUM: 667, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-10-25', PLAN_WK: 2,  ACT_WK: 0, PLAN_CUM: 669, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-11-01', PLAN_WK: 0,  ACT_WK: 0, PLAN_CUM: 669, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-11-08', PLAN_WK: 0,  ACT_WK: 0, PLAN_CUM: 669, ACT_CUM: null, ACT_REM: null, },
      { CDATE: '2024-11-15', PLAN_WK: 0,  ACT_WK: 0, PLAN_CUM: 669, ACT_CUM: null, ACT_REM: null, },


    ], 

    // Canvas & Chart forms
    SQL2: [ 
      {
        CANVAS_WIDTH:           1300,
        CANVAS_HEIGHT:          800,
        CANVAS_STROKE:          '#E0E0DF',
        CANVAS_STROKE_WIDTH:    0,

        TITLE_MAIN_Y:           30,
        TITLE_MAIN_TITLE:       'Rundown Chart for Subsystem Turnover - Grassroots',
        TITLE_MAIN_COLOR:       '#44A9DF',
        TITLE_MAIN_SIZE:        18,

        TITLE_SUB_Y:            45,
        TITLE_SUB_TITLE:        'with Subsystem Skyline Chart',
        TITLE_SUB_COLOR:        '#bcbcbc',
        TITLE_SUB_SIZE:         12,
        
        PADDING_TOP:            250,
        PADDING_RIGHT:          90,
        PADDING_BOTTOM:         70,
        PADDING_LEFT:           90,
      }
    ],

    // Axis Styles
    SQL3: [ 
      { 
        // Axis
        TYPE:                   'PRIMARY        / SECONDARY   ', // Do not Change
        VISIBLE:                'Y              / Y           ',
        MAX_VALUE:              ' 44              /             ',
        TITLE:                  'No of Subsystem (week) / No of Subsystem (Outstanding) ',
        TITLE_GAP:              '45             / 45          ',
        TITLE_SIZE:             '12             / 12          ',
        TITLE_COLOR:            '#333           / #333        ',
        TEXT_SIZE:              '9              / 9           ',
        TEXT_COLOR:             '#757575        / #757575     ',
        TEXT_UNIT:              '               /             ',
        ALIGN:                  'end            / start       ',
        ROTATE:                 '0              / 0           ',
        STROKE:                 '#bcbcbc        / #bcbcbc     ', 
        STROKE_WIDTH:           '.5             / .5          ',
        
        // Grid
        GRID_VISIBLE:           'Y', 
        GRID_STROKE:            '#bcbcbc', 
        GRID_STROKE_WIDTH:      .5,
        GRID_OPACITY:           .2,
      }
    ],

    // Styles for Chart
    SQL4: [ 
      {
        CUTOFF:                 '2023-12-08', // <---
        SKY_START:              '2023-07-05', 
        SKY_END:                '2024-11-29',
        SKY_REF_DATE:           'CSTD_PLAN', // <---
        SKY_REF_BOX:            'SUBSYS_NO', // <---
        SKY_REF_STATUS:         'A_STATUS', // <---
        SKY_BOX_HEIGHT:         7.57,
        SKY_BOX_SCALE:          2,
        SKY_NAME:               ' None  / Not-Started / In-Progress / Complete  / Turn-Over ',
        SKY_STATUS:             ' TOTAL / NOTSTARTED  / INPROGRESS  / COMPLETED / TURNOVER  ',
        SKY_COLOR:              ' #FFF  / #E0E0DF     / #FEED57     / #83D2F5   / #8BC248   ',
        SKY_OPACITY:            ' .2    / .2          / 1           / .8        / 1         ',

        LINE_NAME:              ' Turnover Plan     / Turnover Actual ',
        LINE_VISIBLE:           ' Y              / Y                ',
        LINE_CODE:              ' PLAN_REM       / ACT_REM          ',
        LINE_AXIS:              ' SECOUNDARY     / SECOUNDARY       ',
        LINE_CUTOFF:            '                / Y                ', // <--- apply Cutoff
        LINE_TYPE:              ' CIRCLE         / CIRCLE           ',
        LINE_RADIUS:            ' 2              / 2                ',
        LINE_STROKE_COLOR:      ' #bcbcbc        / #EC407A          ',
        LINE_FILL_COLOR:        ' #ffffff        / #ffffff          ',
        LINE_STROKE_WIDTH:      ' 1              / 1                ',
        LINE_DASH:              ' 2              / 0                ',
        LINE_OPACITY:           ' .9             / .9               ',
        LINE_VALUE_VISIBLE:     ' N              / N                ',
        LINE_VALUE_SIZE:        ' 8              / 8                ',
        LINE_VALUE_COLOR:       ' #757575        / #757575          ',
        LINE_ANIMATION:         ' 1000           / 1000             ',
      },
    ],

    // Detail infomation Box
    SQL5: [ 
      {
        DETAIL_VISIBLE:           'Y',
        DETAIL_ONOFF:             'OFF',
        DETAIL_X:                 120,
        DETAIL_Y:                 280,
        DETAIL_WIDTH:             230,
        DETAIL_TEXT_SIZE:         10,
        DETAIL_LINEHEIGHT:        18,
        
        DETAIL_CODE:              ' UNIT     / CSTD_PLAN    / CSTD_WALKDOWN / CSTD_ACTUAL   / SUBSYS_DESC  / A         / B         / AP        / BP       ',
        DETAIL_NAME:              ' UNIT:    / SSMCC-Plan:  / Walkdown:     / SSMCC-Actual: / Description: / A-Sheet:  / B-Sheet:  / Punch-A:  / Punch-B: ',
        DETAIL_TYPE:              ' TEXT     / TEXT         / TEXT          / TEXT          / TRIM         / BAR       / BAR       / BAR       / BAR      ',
        DETAIL_VALUE_COLOR:       ' #757575  / #757575      / #757575       / #757575       / #757575      / #83D2F5   / #83D2F5   / #F7BACF   / #F7BACF  ',
        DETAIL_BAR_COLOR:         ' #44A9DF  / gray         / gray          / gray          / gray         / #83D2F5   / #83D2F5   / #F7BACF   / #F7BACF  ',
        DETAIL_TITLE_WIDTH:       80,
        DETAIL_TITLE_COLOR:       'gray',
      }
    ],

    SQL6: [
      {WEEK:"LAST", VALUE:1}, // 'LAST' 수정하지말것
      {WEEK:"SAT",  VALUE:2},
      {WEEK:"SUN",  VALUE:0},
      {WEEK:"MON",  VALUE:4},
      {WEEK:"TUE",  VALUE:5},
      {WEEK:"WEN",  VALUE:6},
      {WEEK:"TUE",  VALUE:7},
      {WEEK:"FRI",  VALUE:8},
      {WEEK:"THIS", VALUE:9}, // 'THIS' 수정하지말것
    ],
  }
}