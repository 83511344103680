
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 900,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 900,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     CSTD_PLAN: '2024-02-22', A_TOT: 211,   A_ACT: 69,   A_REM: 142,   A_PRO: 32,  B_TOT: 14,   B_ACT: 0,   B_REM: 14,   B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-8',  SUBSYS_NO: '20000-C-0302', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     CSTD_PLAN: '2023-12-14', A_TOT: 14,    A_ACT: 0,    A_REM: 14,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-26', SUBSYS_NO: '20000-C-0602', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-01-17', A_TOT: 26,    A_ACT: 8,    A_REM: 18,    A_PRO: 30,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-C-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-03-25', A_TOT: 27,    A_ACT: 11,   A_REM: 16,    A_PRO: 40,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-E-1301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-03-26', A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-I-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-09-05', A_TOT: 714,   A_ACT: 174,  A_REM: 540,   A_PRO: 24,  B_TOT: 238,  B_ACT: 71,  B_REM: 167,  B_PRO: 29,  WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-P-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-08-03', A_TOT: 226,   A_ACT: 12,   A_REM: 214,   A_PRO: 5,   B_TOT: 110,  B_ACT: 22,  B_REM: 88,   B_PRO: 20,  WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-P-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-06-29', A_TOT: 143,   A_ACT: 52,   A_REM: 91,    A_PRO: 36,  B_TOT: 68,   B_ACT: 36,  B_REM: 32,   B_PRO: 52,  WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-U-6002', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-07-10', A_TOT: 325,   A_ACT: 114,  A_REM: 211,   A_PRO: 35,  B_TOT: 124,  B_ACT: 22,  B_REM: 102,  B_PRO: 17,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20101-P-0102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-03-19', A_TOT: 104,   A_ACT: 39,   A_REM: 65,    A_PRO: 37,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-C-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-07-18', A_TOT: 45,    A_ACT: 5,    A_REM: 40,    A_PRO: 11,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '20200-E-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-05-18', A_TOT: 350,   A_ACT: 0,    A_REM: 350,   A_PRO: 0,   B_TOT: 124,  B_ACT: 0,   B_REM: 124,  B_PRO: 0,   WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '20200-I-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-04-29', A_TOT: 769,   A_ACT: 80,   A_REM: 689,   A_PRO: 10,  B_TOT: 154,  B_ACT: 24,  B_REM: 130,  B_PRO: 15,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-04-15', A_TOT: 1175,  A_ACT: 74,   A_REM: 1101,  A_PRO: 6,   B_TOT: 365,  B_ACT: 27,  B_REM: 338,  B_PRO: 7,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0104', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-09-06', A_TOT: 666,   A_ACT: 218,  A_REM: 448,   A_PRO: 32,  B_TOT: 286,  B_ACT: 70,  B_REM: 216,  B_PRO: 24,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0403', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-07-17', A_TOT: 222,   A_ACT: 27,   A_REM: 195,   A_PRO: 12,  B_TOT: 68,   B_ACT: 0,   B_REM: 68,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0807', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-04-11', A_TOT: 66,    A_ACT: 35,   A_REM: 31,    A_PRO: 53,  B_TOT: 62,   B_ACT: 35,  B_REM: 27,   B_PRO: 56,  WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '20200-U-1202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-06-07', A_TOT: 109,   A_ACT: 34,   A_REM: 75,    A_PRO: 31,  B_TOT: 86,   B_ACT: 33,  B_REM: 53,   B_PRO: 38,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-U-6005', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-09-10', A_TOT: 1,     A_ACT: 1,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20300-E-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-07-17', A_TOT: 212,   A_ACT: 79,   A_REM: 133,   A_PRO: 37,  B_TOT: 89,   B_ACT: 31,  B_REM: 58,   B_PRO: 34,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20300-P-0304', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-06-10', A_TOT: 117,   A_ACT: 0,    A_REM: 117,   A_PRO: 0,   B_TOT: 40,   B_ACT: 0,   B_REM: 40,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20300-P-0601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-06-10', A_TOT: 102,   A_ACT: 39,   A_REM: 63,    A_PRO: 38,  B_TOT: 77,   B_ACT: 32,  B_REM: 45,   B_PRO: 41,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0701', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-10-24', A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-C-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-06-11', A_TOT: 207,   A_ACT: 49,   A_REM: 158,   A_PRO: 23,  B_TOT: 102,  B_ACT: 24,  B_REM: 78,   B_PRO: 23,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-P-0802', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-06-26', A_TOT: 19,    A_ACT: 1,    A_REM: 18,    A_PRO: 5,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20500-C-5401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-06-04', A_TOT: 19,    A_ACT: 3,    A_REM: 16,    A_PRO: 15,  B_TOT: 7,    B_ACT: 0,   B_REM: 7,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20500-I-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-07-19', A_TOT: 371,   A_ACT: 203,  A_REM: 168,   A_PRO: 54,  B_TOT: 204,  B_ACT: 96,  B_REM: 108,  B_PRO: 47,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20500-P-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-05-27', A_TOT: 71,    A_ACT: 36,   A_REM: 35,    A_PRO: 50,  B_TOT: 13,   B_ACT: 0,   B_REM: 13,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-E-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-02-02', A_TOT: 121,   A_ACT: 72,   A_REM: 49,    A_PRO: 59,  B_TOT: 77,   B_ACT: 56,  B_REM: 21,   B_PRO: 72,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20700-U-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-05-20', A_TOT: 308,   A_ACT: 221,  A_REM: 87,    A_PRO: 71,  B_TOT: 122,  B_ACT: 90,  B_REM: 32,   B_PRO: 73,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20700-U-2001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-06-01', A_TOT: 40,    A_ACT: 10,   A_REM: 30,    A_PRO: 25,  B_TOT: 18,   B_ACT: 4,   B_REM: 14,   B_PRO: 22,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20700-U-7001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-06-26', A_TOT: 36,    A_ACT: 4,    A_REM: 32,    A_PRO: 11,  B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20800-C-5401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-07-02', A_TOT: 307,   A_ACT: 72,   A_REM: 235,   A_PRO: 23,  B_TOT: 58,   B_ACT: 27,  B_REM: 31,   B_PRO: 46,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20800-U-7001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-14', A_TOT: 25,    A_ACT: 16,   A_REM: 9,     A_PRO: 64,  B_TOT: 11,   B_ACT: 8,   B_REM: 3,    B_PRO: 72,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-02', A_TOT: 19,    A_ACT: 10,   A_REM: 9,     A_PRO: 52,  B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0104', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-07-18', A_TOT: 43,    A_ACT: 27,   A_REM: 16,    A_PRO: 62,  B_TOT: 15,   B_ACT: 10,  B_REM: 5,    B_PRO: 66,  WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0117', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-03', A_TOT: 50,    A_ACT: 35,   A_REM: 15,    A_PRO: 70,  B_TOT: 38,   B_ACT: 32,  B_REM: 6,    B_PRO: 84,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0119', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-07-11', A_TOT: 37,    A_ACT: 33,   A_REM: 4,     A_PRO: 89,  B_TOT: 36,   B_ACT: 34,  B_REM: 2,    B_PRO: 94,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0121', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-11', A_TOT: 36,    A_ACT: 16,   A_REM: 20,    A_PRO: 44,  B_TOT: 45,   B_ACT: 32,  B_REM: 13,   B_PRO: 71,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-29', A_TOT: 20,    A_ACT: 8,    A_REM: 12,    A_PRO: 40,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-2003', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-22', A_TOT: 175,   A_ACT: 94,   A_REM: 81,    A_PRO: 53,  B_TOT: 90,   B_ACT: 57,  B_REM: 33,   B_PRO: 63,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-3301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-01', A_TOT: 48,    A_ACT: 41,   A_REM: 7,     A_PRO: 85,  B_TOT: 25,   B_ACT: 22,  B_REM: 3,    B_PRO: 88,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-3401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-04-29', A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21200-E-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-05-09', A_TOT: 56,    A_ACT: 1,    A_REM: 55,    A_PRO: 1,   B_TOT: 25,   B_ACT: 0,   B_REM: 25,   B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-P-0102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-05-10', A_TOT: 25,    A_ACT: 12,   A_REM: 13,    A_PRO: 48,  B_TOT: 13,   B_ACT: 10,  B_REM: 3,    B_PRO: 76,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-U-4001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-05-17', A_TOT: 170,   A_ACT: 36,   A_REM: 134,   A_PRO: 21,  B_TOT: 102,  B_ACT: 22,  B_REM: 80,   B_PRO: 21,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-U-4305', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-04-02', A_TOT: 9,     A_ACT: 2,    A_REM: 7,     A_PRO: 22,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '21300-C-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-07-10', A_TOT: 395,   A_ACT: 71,   A_REM: 324,   A_PRO: 18,  B_TOT: 127,  B_ACT: 28,  B_REM: 99,   B_PRO: 22,  WAYPOINT: 'WP-24', CSU_MS: 'MS-10', SUBSYS_NO: '21300-P-0103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-05-24', A_TOT: 235,   A_ACT: 39,   A_REM: 196,   A_PRO: 16,  B_TOT: 64,   B_ACT: 12,  B_REM: 52,   B_PRO: 18,  WAYPOINT: 'WP-24', CSU_MS: 'MS-10', SUBSYS_NO: '21300-P-0203', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-04-19', A_TOT: 14,    A_ACT: 8,    A_REM: 6,     A_PRO: 57,  B_TOT: 12,   B_ACT: 8,   B_REM: 4,    B_PRO: 66,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21401-U-4001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-02-15', A_TOT: 55,    A_ACT: 1,    A_REM: 54,    A_PRO: 1,   B_TOT: 24,   B_ACT: 0,   B_REM: 24,   B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21402-E-1401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-05-06', A_TOT: 61,    A_ACT: 0,    A_REM: 61,    A_PRO: 0,   B_TOT: 15,   B_ACT: 0,   B_REM: 15,   B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21402-I-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-02-01', A_TOT: 13,    A_ACT: 6,    A_REM: 7,     A_PRO: 46,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-17', SUBSYS_NO: '21403-C-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-04-27', A_TOT: 17,    A_ACT: 17,   A_REM: 0,     A_PRO: 100, B_TOT: 18,   B_ACT: 18,  B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-19', CSU_MS: 'MS-12', SUBSYS_NO: '21403-U-1201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-05-11', A_TOT: 6,     A_ACT: 6,    A_REM: 0,     A_PRO: 100, B_TOT: 5,    B_ACT: 4,   B_REM: 1,    B_PRO: 80,  WAYPOINT: 'WP-19', CSU_MS: 'MS-17', SUBSYS_NO: '21403-U-2102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-05-07', A_TOT: 71,    A_ACT: 66,   A_REM: 5,     A_PRO: 93,  B_TOT: 20,   B_ACT: 16,  B_REM: 4,    B_PRO: 80,  WAYPOINT: 'WP-34', CSU_MS: 'MS-17', SUBSYS_NO: '21403-U-2201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-05-23', A_TOT: 25,    A_ACT: 23,   A_REM: 2,     A_PRO: 92,  B_TOT: 22,   B_ACT: 20,  B_REM: 2,    B_PRO: 90,  WAYPOINT: 'WP-19', CSU_MS: 'MS-17', SUBSYS_NO: '21403-U-4001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', CSTD_PLAN: '2024-03-09', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 14,   B_ACT: 12,  B_REM: 2,    B_PRO: 85,  WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21501-I-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-03-02', A_TOT: 60,    A_ACT: 60,   A_REM: 0,     A_PRO: 100, B_TOT: 20,   B_ACT: 20,  B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-E-0101', CSTD_WALKDOWN: '2023-09-11', CSTD_ACTUAL: '2023-10-10', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-02-29', A_TOT: 60,    A_ACT: 60,   A_REM: 0,     A_PRO: 100, B_TOT: 9,    B_ACT: 9,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-E-0102', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-28', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-03-04', A_TOT: 46,    A_ACT: 0,    A_REM: 46,    A_PRO: 0,   B_TOT: 6,    B_ACT: 5,   B_REM: 1,    B_PRO: 83,  WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-E-0601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-02-22', A_TOT: 6,     A_ACT: 6,    A_REM: 0,     A_PRO: 100, B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-E-0701', CSTD_WALKDOWN: '2023-09-04', CSTD_ACTUAL: '2023-10-08', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-03-18', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 7,   B_REM: 1,    B_PRO: 87,  WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-E-0301', CSTD_WALKDOWN: '2023-10-23', CSTD_ACTUAL: '2023-11-06', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-02-17', A_TOT: 28,    A_ACT: 28,   A_REM: 0,     A_PRO: 100, B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-E-0601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-03-11', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 4,    B_ACT: 4,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-E-1101', CSTD_WALKDOWN: '2023-10-23', CSTD_ACTUAL: '2023-11-03', },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     CSTD_PLAN: '2024-02-22', A_TOT: 71,    A_ACT: 24,   A_REM: 47,    A_PRO: 33,  B_TOT: 8,    B_ACT: 0,   B_REM: 8,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-8',  SUBSYS_NO: '20000-C-0303', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     CSTD_PLAN: '2024-02-01', A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-11', SUBSYS_NO: '20000-E-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     CSTD_PLAN: '2024-01-02', A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-11', SUBSYS_NO: '20000-E-1103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-06-18', A_TOT: 42,    A_ACT: 9,    A_REM: 33,    A_PRO: 21,  B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-C-5401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-07-01', A_TOT: 684,   A_ACT: 269,  A_REM: 415,   A_PRO: 39,  B_TOT: 265,  B_ACT: 100, B_REM: 165,  B_PRO: 37,  WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-P-0302', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-06-06', A_TOT: 26,    A_ACT: 25,   A_REM: 1,     A_PRO: 96,  B_TOT: 26,   B_ACT: 25,  B_REM: 1,    B_PRO: 96,  WAYPOINT: 'WP-34', CSU_MS: 'MS-23', SUBSYS_NO: '20100-U-4001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-06-15', A_TOT: 12,    A_ACT: 2,    A_REM: 10,    A_PRO: 16,  B_TOT: 6,    B_ACT: 2,   B_REM: 4,    B_PRO: 33,  WAYPOINT: 'WP-34', CSU_MS: 'MS-23', SUBSYS_NO: '20100-U-4101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-06-24', A_TOT: 9,     A_ACT: 1,    A_REM: 8,     A_PRO: 11,  B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20101-U-4101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-04-09', A_TOT: 55,    A_ACT: 0,    A_REM: 55,    A_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '20200-E-1301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-09-27', A_TOT: 1162,  A_ACT: 212,  A_REM: 950,   A_PRO: 18,  B_TOT: 383,  B_ACT: 117, B_REM: 266,  B_PRO: 30,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-08-24', A_TOT: 328,   A_ACT: 109,  A_REM: 219,   A_PRO: 33,  B_TOT: 155,  B_ACT: 57,  B_REM: 98,   B_PRO: 36,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0705', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-02-28', A_TOT: 97,    A_ACT: 0,    A_REM: 97,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '20200-T-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2023-12-26', A_TOT: 15,    A_ACT: 4,    A_REM: 11,    A_PRO: 26,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20300-C-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-10-02', A_TOT: 110,   A_ACT: 4,    A_REM: 106,   A_PRO: 3,   B_TOT: 24,   B_ACT: 0,   B_REM: 24,   B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20300-I-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-06-15', A_TOT: 590,   A_ACT: 204,  A_REM: 386,   A_PRO: 34,  B_TOT: 144,  B_ACT: 70,  B_REM: 74,   B_PRO: 48,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20300-P-0102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-10-24', A_TOT: 32,    A_ACT: 0,    A_REM: 32,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-C-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-02-24', A_TOT: 23,    A_ACT: 0,    A_REM: 23,    A_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-I-0202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-03-22', A_TOT: 7,     A_ACT: 0,    A_REM: 7,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20400-I-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-10-01', A_TOT: 1146,  A_ACT: 218,  A_REM: 928,   A_PRO: 19,  B_TOT: 503,  B_ACT: 59,  B_REM: 444,  B_PRO: 11,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-07-22', A_TOT: 148,   A_ACT: 33,   A_REM: 115,   A_PRO: 22,  B_TOT: 58,   B_ACT: 3,   B_REM: 55,   B_PRO: 5,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0602', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-05-04', A_TOT: 43,    A_ACT: 10,   A_REM: 33,    A_PRO: 23,  B_TOT: 17,   B_ACT: 2,   B_REM: 15,   B_PRO: 11,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-U-2301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-07-20', A_TOT: 38,    A_ACT: 7,    A_REM: 31,    A_PRO: 18,  B_TOT: 30,   B_ACT: 7,   B_REM: 23,   B_PRO: 23,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-U-6006', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-06-15', A_TOT: 214,   A_ACT: 36,   A_REM: 178,   A_PRO: 16,  B_TOT: 64,   B_ACT: 4,   B_REM: 60,   B_PRO: 6,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-U-7401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-07-20', A_TOT: 10,    A_ACT: 3,    A_REM: 7,     A_PRO: 30,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-C-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-04-29', A_TOT: 23,    A_ACT: 10,   A_REM: 13,    A_PRO: 43,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-C-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-06-19', A_TOT: 32,    A_ACT: 0,    A_REM: 32,    A_PRO: 0,   B_TOT: 11,   B_ACT: 0,   B_REM: 11,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-I-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-04-29', A_TOT: 184,   A_ACT: 51,   A_REM: 133,   A_PRO: 27,  B_TOT: 75,   B_ACT: 0,   B_REM: 75,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-P-0104', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-06-08', A_TOT: 72,    A_ACT: 30,   A_REM: 42,    A_PRO: 41,  B_TOT: 42,   B_ACT: 14,  B_REM: 28,   B_PRO: 33,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-P-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-04-20', A_TOT: 78,    A_ACT: 42,   A_REM: 36,    A_PRO: 53,  B_TOT: 66,   B_ACT: 42,  B_REM: 24,   B_PRO: 63,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-U-4001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-07-20', A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '20500-C-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-05-27', A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20500-E-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-06-04', A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20500-I-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-07-13', A_TOT: 455,   A_ACT: 232,  A_REM: 223,   A_PRO: 51,  B_TOT: 196,  B_ACT: 96,  B_REM: 100,  B_PRO: 49,  WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20500-P-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-04-18', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20500-U-4601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-04-29', A_TOT: 60,    A_ACT: 8,    A_REM: 52,    A_PRO: 13,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-C-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-01-31', A_TOT: 44,    A_ACT: 26,   A_REM: 18,    A_PRO: 59,  B_TOT: 38,   B_ACT: 26,  B_REM: 12,   B_PRO: 68,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20600-U-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-05-25', A_TOT: 36,    A_ACT: 20,   A_REM: 16,    A_PRO: 55,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-U-6102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-03-14', A_TOT: 393,   A_ACT: 255,  A_REM: 138,   A_PRO: 64,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '20700-C-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-06-22', A_TOT: 346,   A_ACT: 141,  A_REM: 205,   A_PRO: 40,  B_TOT: 160,  B_ACT: 92,  B_REM: 68,   B_PRO: 57,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20700-P-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-02-05', A_TOT: 46,    A_ACT: 34,   A_REM: 12,    A_PRO: 73,  B_TOT: 42,   B_ACT: 34,  B_REM: 8,    B_PRO: 81,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20700-U-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-07-20', A_TOT: 24,    A_ACT: 0,    A_REM: 24,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '20800-C-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-01-11', A_TOT: 22,    A_ACT: 8,    A_REM: 14,    A_PRO: 36,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20800-C-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-01-19', A_TOT: 266,   A_ACT: 126,  A_REM: 140,   A_PRO: 47,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '20800-C-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-07-02', A_TOT: 15,    A_ACT: 0,    A_REM: 15,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20800-I-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-06-14', A_TOT: 12,    A_ACT: 11,   A_REM: 1,     A_PRO: 91,  B_TOT: 9,    B_ACT: 8,   B_REM: 1,    B_PRO: 88,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20800-U-2102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-06-22', A_TOT: 69,    A_ACT: 28,   A_REM: 41,    A_PRO: 40,  B_TOT: 9,    B_ACT: 2,   B_REM: 7,    B_PRO: 22,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20800-U-7101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-06-15', A_TOT: 188,   A_ACT: 50,   A_REM: 138,   A_PRO: 26,  B_TOT: 134,  B_ACT: 46,  B_REM: 88,   B_PRO: 34,  WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20800-U-7501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-04-27', A_TOT: 968,   A_ACT: 400,  A_REM: 568,   A_PRO: 41,  B_TOT: 44,   B_ACT: 0,   B_REM: 44,   B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21100-E-1301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-15', A_TOT: 10,    A_ACT: 9,    A_REM: 1,     A_PRO: 90,  B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0113', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-07-18', A_TOT: 84,    A_ACT: 51,   A_REM: 33,    A_PRO: 60,  B_TOT: 34,   B_ACT: 20,  B_REM: 14,   B_PRO: 58,  WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0115', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-08-08', A_TOT: 27,    A_ACT: 23,   A_REM: 4,     A_PRO: 85,  B_TOT: 20,   B_ACT: 18,  B_REM: 2,    B_PRO: 90,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0204', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-25', A_TOT: 14,    A_ACT: 14,   A_REM: 0,     A_PRO: 100, B_TOT: 14,   B_ACT: 14,  B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0205', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-08-08', A_TOT: 25,    A_ACT: 21,   A_REM: 4,     A_PRO: 84,  B_TOT: 16,   B_ACT: 14,  B_REM: 2,    B_PRO: 87,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0206', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-27', A_TOT: 18,    A_ACT: 18,   A_REM: 0,     A_PRO: 100, B_TOT: 18,   B_ACT: 17,  B_REM: 1,    B_PRO: 94,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0207', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-17', A_TOT: 39,    A_ACT: 23,   A_REM: 16,    A_PRO: 59,  B_TOT: 30,   B_ACT: 22,  B_REM: 8,    B_PRO: 73,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0212', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-07-20', A_TOT: 38,    A_ACT: 12,   A_REM: 26,    A_PRO: 31,  B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60,  WAYPOINT: 'WP-28', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0219', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-02-20', A_TOT: 256,   A_ACT: 107,  A_REM: 149,   A_PRO: 41,  B_TOT: 156,  B_ACT: 92,  B_REM: 64,   B_PRO: 59,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-04-22', A_TOT: 13,    A_ACT: 4,    A_REM: 9,     A_PRO: 30,  B_TOT: 5,    B_ACT: 4,   B_REM: 1,    B_PRO: 80,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-1003', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-26', A_TOT: 250,   A_ACT: 162,  A_REM: 88,    A_PRO: 64,  B_TOT: 104,  B_ACT: 79,  B_REM: 25,   B_PRO: 76,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-2001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-08', A_TOT: 174,   A_ACT: 136,  A_REM: 38,    A_PRO: 78,  B_TOT: 116,  B_ACT: 94,  B_REM: 22,   B_PRO: 81,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-3201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-28', A_TOT: 293,   A_ACT: 66,   A_REM: 227,   A_PRO: 22,  B_TOT: 137,  B_ACT: 0,   B_REM: 137,  B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-4603', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-03-22', A_TOT: 7,     A_ACT: 0,    A_REM: 7,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21200-I-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-05-17', A_TOT: 81,    A_ACT: 0,    A_REM: 81,    A_PRO: 0,   B_TOT: 44,   B_ACT: 0,   B_REM: 44,   B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-P-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-04-25', A_TOT: 18,    A_ACT: 18,   A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '21300-C-0101', CSTD_WALKDOWN: '2023-09-29', CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-05-27', A_TOT: 22,    A_ACT: 0,    A_REM: 22,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21300-I-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-06-10', A_TOT: 34,    A_ACT: 27,   A_REM: 7,     A_PRO: 79,  B_TOT: 17,   B_ACT: 14,  B_REM: 3,    B_PRO: 82,  WAYPOINT: 'WP-24', CSU_MS: 'MS-10', SUBSYS_NO: '21300-P-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-06-25', A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21300-T-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-07-22', A_TOT: 19,    A_ACT: 0,    A_REM: 19,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '21401-C-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-06-18', A_TOT: 10,    A_ACT: 3,    A_REM: 7,     A_PRO: 30,  B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21401-C-5401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-05-27', A_TOT: 39,    A_ACT: 0,    A_REM: 39,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21401-E-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-04-20', A_TOT: 386,   A_ACT: 64,   A_REM: 322,   A_PRO: 16,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '21402-C-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-03-26', A_TOT: 26,    A_ACT: 12,   A_REM: 14,    A_PRO: 46,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21402-C-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-02-07', A_TOT: 1093,  A_ACT: 254,  A_REM: 839,   A_PRO: 23,  B_TOT: 122,  B_ACT: 0,   B_REM: 122,  B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21402-E-1301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-07-01', A_TOT: 460,   A_ACT: 16,   A_REM: 444,   A_PRO: 3,   B_TOT: 127,  B_ACT: 7,   B_REM: 120,  B_PRO: 5,   WAYPOINT: 'WP-28', CSU_MS: 'MS-10', SUBSYS_NO: '21402-P-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-05-03', A_TOT: 6,     A_ACT: 6,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21402-U-1202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     CSTD_PLAN: '2024-06-21', A_TOT: 162,   A_ACT: 57,   A_REM: 105,   A_PRO: 35,  B_TOT: 60,   B_ACT: 11,  B_REM: 49,   B_PRO: 18,  WAYPOINT: 'WP-26', CSU_MS: 'MS-8',  SUBSYS_NO: '20000-C-5401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-03-07', A_TOT: 73,    A_ACT: 33,   A_REM: 40,    A_PRO: 45,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-C-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-05-08', A_TOT: 158,   A_ACT: 104,  A_REM: 54,    A_PRO: 65,  B_TOT: 59,   B_ACT: 34,  B_REM: 25,   B_PRO: 57,  WAYPOINT: 'WP-34', CSU_MS: 'MS-23', SUBSYS_NO: '20100-U-2101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-03-13', A_TOT: 54,    A_ACT: 41,   A_REM: 13,    A_PRO: 75,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '20101-C-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-05-27', A_TOT: 4,     A_ACT: 1,    A_REM: 3,     A_PRO: 25,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20101-E-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-04-24', A_TOT: 9,     A_ACT: 3,    A_REM: 6,     A_PRO: 33,  B_TOT: 8,    B_ACT: 3,   B_REM: 5,    B_PRO: 37,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20101-U-1201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-06-10', A_TOT: 3,     A_ACT: 1,    A_REM: 2,     A_PRO: 33,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20101-U-4601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-09-07', A_TOT: 92,    A_ACT: 0,    A_REM: 92,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '20200-C-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-07-16', A_TOT: 156,   A_ACT: 26,   A_REM: 130,   A_PRO: 16,  B_TOT: 26,   B_ACT: 14,  B_REM: 12,   B_PRO: 53,  WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '20200-C-5401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-10-08', A_TOT: 278,   A_ACT: 103,  A_REM: 175,   A_PRO: 37,  B_TOT: 34,   B_ACT: 0,   B_REM: 34,   B_PRO: 0,   WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '20200-E-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-04-29', A_TOT: 12624, A_ACT: 1294, A_REM: 11330, A_PRO: 10,  B_TOT: 2394, B_ACT: 7,   B_REM: 2387, B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0109', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-07-11', A_TOT: 99,    A_ACT: 40,   A_REM: 59,    A_PRO: 40,  B_TOT: 69,   B_ACT: 33,  B_REM: 36,   B_PRO: 47,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-08-06', A_TOT: 906,   A_ACT: 61,   A_REM: 845,   A_PRO: 6,   B_TOT: 290,  B_ACT: 4,   B_REM: 286,  B_PRO: 1,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0803', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-04-24', A_TOT: 156,   A_ACT: 45,   A_REM: 111,   A_PRO: 28,  B_TOT: 51,   B_ACT: 19,  B_REM: 32,   B_PRO: 37,  WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '20200-U-2401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-06-01', A_TOT: 76,    A_ACT: 47,   A_REM: 29,    A_PRO: 61,  B_TOT: 72,   B_ACT: 47,  B_REM: 25,   B_PRO: 65,  WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '20200-U-4001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-05-31', A_TOT: 35,    A_ACT: 4,    A_REM: 31,    A_PRO: 11,  B_TOT: 20,   B_ACT: 8,   B_REM: 12,   B_PRO: 40,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-U-4101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-04-29', A_TOT: 344,   A_ACT: 34,   A_REM: 310,   A_PRO: 9,   B_TOT: 69,   B_ACT: 8,   B_REM: 61,   B_PRO: 11,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-U-7001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-05-28', A_TOT: 31,    A_ACT: 15,   A_REM: 16,    A_PRO: 48,  B_TOT: 15,   B_ACT: 10,  B_REM: 5,    B_PRO: 66,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-U-7401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-03-18', A_TOT: 43,    A_ACT: 6,    A_REM: 37,    A_PRO: 14,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-C-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-03-26', A_TOT: 495,   A_ACT: 115,  A_REM: 380,   A_PRO: 23,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-C-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-09-16', A_TOT: 336,   A_ACT: 9,    A_REM: 327,   A_PRO: 2,   B_TOT: 177,  B_ACT: 16,  B_REM: 161,  B_PRO: 9,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0205', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-06-25', A_TOT: 30,    A_ACT: 7,    A_REM: 23,    A_PRO: 23,  B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0208', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-07-12', A_TOT: 365,   A_ACT: 100,  A_REM: 265,   A_PRO: 27,  B_TOT: 162,  B_ACT: 30,  B_REM: 132,  B_PRO: 18,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0303', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-07-29', A_TOT: 1172,  A_ACT: 170,  A_REM: 1002,  A_PRO: 14,  B_TOT: 266,  B_ACT: 0,   B_REM: 266,  B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0403', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-04-24', A_TOT: 25,    A_ACT: 10,   A_REM: 15,    A_PRO: 40,  B_TOT: 24,   B_ACT: 10,  B_REM: 14,   B_PRO: 41,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-U-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-06-24', A_TOT: 420,   A_ACT: 96,   A_REM: 324,   A_PRO: 22,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-U-2103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-05-20', A_TOT: 24,    A_ACT: 7,    A_REM: 17,    A_PRO: 29,  B_TOT: 6,    B_ACT: 4,   B_REM: 2,    B_PRO: 66,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-C-5401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-05-13', A_TOT: 6,     A_ACT: 3,    A_REM: 3,     A_PRO: 50,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-E-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-04-25', A_TOT: 28,    A_ACT: 28,   A_REM: 0,     A_PRO: 100, B_TOT: 21,   B_ACT: 20,  B_REM: 1,    B_PRO: 95,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-U-2101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-04-29', A_TOT: 21,    A_ACT: 2,    A_REM: 19,    A_PRO: 9,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '20500-C-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-04-01', A_TOT: 78,    A_ACT: 36,   A_REM: 42,    A_PRO: 46,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '20500-C-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-03-20', A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20500-T-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-03-28', A_TOT: 1,     A_ACT: 1,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-C-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-02-17', A_TOT: 260,   A_ACT: 133,  A_REM: 127,   A_PRO: 51,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-C-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-06-12', A_TOT: 559,   A_ACT: 220,  A_REM: 339,   A_PRO: 39,  B_TOT: 262,  B_ACT: 99,  B_REM: 163,  B_PRO: 37,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-P-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-04-20', A_TOT: 104,   A_ACT: 103,  A_REM: 1,     A_PRO: 99,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '20700-C-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-04-17', A_TOT: 72,    A_ACT: 31,   A_REM: 41,    A_PRO: 43,  B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20700-E-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-05-27', A_TOT: 24,    A_ACT: 0,    A_REM: 24,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20700-I-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-05-03', A_TOT: 204,   A_ACT: 125,  A_REM: 79,    A_PRO: 61,  B_TOT: 62,   B_ACT: 48,  B_REM: 14,   B_PRO: 77,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20700-U-1201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-04-24', A_TOT: 37,    A_ACT: 34,   A_REM: 3,     A_PRO: 91,  B_TOT: 36,   B_ACT: 34,  B_REM: 2,    B_PRO: 94,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20700-U-1202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-05-04', A_TOT: 29,    A_ACT: 7,    A_REM: 22,    A_PRO: 24,  B_TOT: 20,   B_ACT: 6,   B_REM: 14,   B_PRO: 30,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20700-U-3502', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-06-08', A_TOT: 152,   A_ACT: 101,  A_REM: 51,    A_PRO: 66,  B_TOT: 33,   B_ACT: 12,  B_REM: 21,   B_PRO: 36,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20700-U-7101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-04-15', A_TOT: 13,    A_ACT: 0,    A_REM: 13,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20800-T-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-05-31', A_TOT: 5,     A_ACT: 2,    A_REM: 3,     A_PRO: 40,  B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20800-U-4601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-10-01', A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20800-U-6102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-24', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-20', A_TOT: 8,     A_ACT: 8,    A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0105', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-29', A_TOT: 8,     A_ACT: 8,    A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0109', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-23', A_TOT: 45,    A_ACT: 25,   A_REM: 20,    A_PRO: 55,  B_TOT: 31,   B_ACT: 20,  B_REM: 11,   B_PRO: 64,  WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0111', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-07-20', A_TOT: 18,    A_ACT: 15,   A_REM: 3,     A_PRO: 83,  B_TOT: 12,   B_ACT: 10,  B_REM: 2,    B_PRO: 83,  WAYPOINT: 'WP-28', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0120', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-28', A_TOT: 21,    A_ACT: 20,   A_REM: 1,     A_PRO: 95,  B_TOT: 14,   B_ACT: 14,  B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-06', A_TOT: 63,    A_ACT: 35,   A_REM: 28,    A_PRO: 55,  B_TOT: 39,   B_ACT: 32,  B_REM: 7,    B_PRO: 82,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0209', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-20', A_TOT: 134,   A_ACT: 62,   A_REM: 72,    A_PRO: 46,  B_TOT: 64,   B_ACT: 43,  B_REM: 21,   B_PRO: 67,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0210', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-22', A_TOT: 36,    A_ACT: 22,   A_REM: 14,    A_PRO: 61,  B_TOT: 23,   B_ACT: 20,  B_REM: 3,    B_PRO: 87,  WAYPOINT: 'WP-28', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0218', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-25', A_TOT: 985,   A_ACT: 579,  A_REM: 406,   A_PRO: 58,  B_TOT: 548,  B_ACT: 362, B_REM: 186,  B_PRO: 66,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-2101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-15', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-3002', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-14', A_TOT: 10,    A_ACT: 9,    A_REM: 1,     A_PRO: 90,  B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-5702', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-04-29', A_TOT: 9,     A_ACT: 1,    A_REM: 8,     A_PRO: 11,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '21200-C-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-04-29', A_TOT: 15,    A_ACT: 0,    A_REM: 15,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21200-I-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-03-04', A_TOT: 16,    A_ACT: 0,    A_REM: 16,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21200-T-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-05-24', A_TOT: 3,     A_ACT: 0,    A_REM: 3,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-U-3301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-06-08', A_TOT: 369,   A_ACT: 8,    A_REM: 361,   A_PRO: 2,   B_TOT: 161,  B_ACT: 0,   B_REM: 161,  B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-U-4301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-07-17', A_TOT: 637,   A_ACT: 269,  A_REM: 368,   A_PRO: 42,  B_TOT: 232,  B_ACT: 68,  B_REM: 164,  B_PRO: 29,  WAYPOINT: 'WP-24', CSU_MS: 'MS-10', SUBSYS_NO: '21300-P-0102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-06-20', A_TOT: 206,   A_ACT: 34,   A_REM: 172,   A_PRO: 16,  B_TOT: 70,   B_ACT: 8,   B_REM: 62,   B_PRO: 11,  WAYPOINT: 'WP-24', CSU_MS: 'MS-10', SUBSYS_NO: '21300-P-0202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-02-03', A_TOT: 28,    A_ACT: 4,    A_REM: 24,    A_PRO: 14,  B_TOT: 14,   B_ACT: 2,   B_REM: 12,   B_PRO: 14,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21300-U-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-02-06', A_TOT: 35,    A_ACT: 20,   A_REM: 15,    A_PRO: 57,  B_TOT: 30,   B_ACT: 20,  B_REM: 10,   B_PRO: 66,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21300-U-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-02-21', A_TOT: 641,   A_ACT: 94,   A_REM: 547,   A_PRO: 14,  B_TOT: 85,   B_ACT: 0,   B_REM: 85,   B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21401-E-1301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-07-16', A_TOT: 447,   A_ACT: 80,   A_REM: 367,   A_PRO: 17,  B_TOT: 144,  B_ACT: 31,  B_REM: 113,  B_PRO: 21,  WAYPOINT: 'WP-28', CSU_MS: 'MS-10', SUBSYS_NO: '21401-P-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-03-12', A_TOT: 20,    A_ACT: 6,    A_REM: 14,    A_PRO: 30,  B_TOT: 6,    B_ACT: 4,   B_REM: 2,    B_PRO: 66,  WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21402-C-5401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-02-24', A_TOT: 7,     A_ACT: 0,    A_REM: 7,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21402-I-0202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-05-31', A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, B_TOT: 7,    B_ACT: 6,   B_REM: 1,    B_PRO: 85,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21402-U-2102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     CSTD_PLAN: '2023-12-14', A_TOT: 3,     A_ACT: 0,    A_REM: 3,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-26', SUBSYS_NO: '20000-C-0603', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     CSTD_PLAN: '2024-01-02', A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-11', SUBSYS_NO: '20000-E-1102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-04-20', A_TOT: 109,   A_ACT: 29,   A_REM: 80,    A_PRO: 26,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-C-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-05-27', A_TOT: 11,    A_ACT: 2,    A_REM: 9,     A_PRO: 18,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-E-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-05-27', A_TOT: 102,   A_ACT: 3,    A_REM: 99,    A_PRO: 2,   B_TOT: 35,   B_ACT: 0,   B_REM: 35,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-I-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-08-24', A_TOT: 494,   A_ACT: 118,  A_REM: 376,   A_PRO: 23,  B_TOT: 195,  B_ACT: 57,  B_REM: 138,  B_PRO: 29,  WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-P-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-04-24', A_TOT: 9,     A_ACT: 6,    A_REM: 3,     A_PRO: 66,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20101-C-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-07-12', A_TOT: 5,     A_ACT: 0,    A_REM: 5,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '20101-C-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-05-27', A_TOT: 15,    A_ACT: 0,    A_REM: 15,    A_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20101-I-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-05-30', A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20101-I-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-08-29', A_TOT: 1025,  A_ACT: 438,  A_REM: 587,   A_PRO: 42,  B_TOT: 352,  B_ACT: 114, B_REM: 238,  B_PRO: 32,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20101-P-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-07-06', A_TOT: 100,   A_ACT: 24,   A_REM: 76,    A_PRO: 24,  B_TOT: 42,   B_ACT: 5,   B_REM: 37,   B_PRO: 11,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20101-P-0103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-06-25', A_TOT: 140,   A_ACT: 24,   A_REM: 116,   A_PRO: 17,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20101-U-2103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-01-29', A_TOT: 83,    A_ACT: 16,   A_REM: 67,    A_PRO: 19,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '20200-C-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-07-10', A_TOT: 147,   A_ACT: 19,   A_REM: 128,   A_PRO: 12,  B_TOT: 47,   B_ACT: 0,   B_REM: 47,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0106', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-06-11', A_TOT: 140,   A_ACT: 15,   A_REM: 125,   A_PRO: 10,  B_TOT: 18,   B_ACT: 0,   B_REM: 18,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0107', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-08-15', A_TOT: 263,   A_ACT: 4,    A_REM: 259,   A_PRO: 1,   B_TOT: 131,  B_ACT: 0,   B_REM: 131,  B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0203', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-07-13', A_TOT: 218,   A_ACT: 21,   A_REM: 197,   A_PRO: 9,   B_TOT: 69,   B_ACT: 0,   B_REM: 69,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0806', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-04-18', A_TOT: 141,   A_ACT: 14,   A_REM: 127,   A_PRO: 9,   B_TOT: 23,   B_ACT: 2,   B_REM: 21,   B_PRO: 8,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-U-7101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-03-19', A_TOT: 29,    A_ACT: 20,   A_REM: 9,     A_PRO: 69,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20300-C-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-02-06', A_TOT: 155,   A_ACT: 0,    A_REM: 155,   A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '20300-C-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-08-21', A_TOT: 465,   A_ACT: 204,  A_REM: 261,   A_PRO: 43,  B_TOT: 190,  B_ACT: 98,  B_REM: 92,   B_PRO: 51,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20300-P-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-08-14', A_TOT: 234,   A_ACT: 138,  A_REM: 96,    A_PRO: 59,  B_TOT: 52,   B_ACT: 27,  B_REM: 25,   B_PRO: 51,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20300-P-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-06-21', A_TOT: 65,    A_ACT: 50,   A_REM: 15,    A_PRO: 76,  B_TOT: 24,   B_ACT: 20,  B_REM: 4,    B_PRO: 83,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20300-U-7401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-05-08', A_TOT: 50,    A_ACT: 11,   A_REM: 39,    A_PRO: 22,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-C-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-07-10', A_TOT: 322,   A_ACT: 164,  A_REM: 158,   A_PRO: 50,  B_TOT: 139,  B_ACT: 77,  B_REM: 62,   B_PRO: 55,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0203', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-07-04', A_TOT: 175,   A_ACT: 10,   A_REM: 165,   A_PRO: 5,   B_TOT: 65,   B_ACT: 0,   B_REM: 65,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-05-16', A_TOT: 14,    A_ACT: 0,    A_REM: 14,    A_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-U-4101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-05-20', A_TOT: 27,    A_ACT: 16,   A_REM: 11,    A_PRO: 59,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-E-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-05-27', A_TOT: 8,     A_ACT: 3,    A_REM: 5,     A_PRO: 37,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20500-E-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-04-20', A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, B_TOT: 4,    B_ACT: 4,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20500-U-1202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-05-17', A_TOT: 25,    A_ACT: 25,   A_REM: 0,     A_PRO: 100, B_TOT: 11,   B_ACT: 10,  B_REM: 1,    B_PRO: 90,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20500-U-3101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-06-05', A_TOT: 7,     A_ACT: 2,    A_REM: 5,     A_PRO: 28,  B_TOT: 4,    B_ACT: 2,   B_REM: 2,    B_PRO: 50,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20500-U-4101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-06-21', A_TOT: 50,    A_ACT: 6,    A_REM: 44,    A_PRO: 12,  B_TOT: 4,    B_ACT: 2,   B_REM: 2,    B_PRO: 50,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-C-5401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-05-27', A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-E-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-02-20', A_TOT: 47,    A_ACT: 22,   A_REM: 25,    A_PRO: 46,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-E-1301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-07-01', A_TOT: 81,    A_ACT: 16,   A_REM: 65,    A_PRO: 19,  B_TOT: 27,   B_ACT: 2,   B_REM: 25,   B_PRO: 7,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-P-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-07-15', A_TOT: 681,   A_ACT: 222,  A_REM: 459,   A_PRO: 32,  B_TOT: 316,  B_ACT: 102, B_REM: 214,  B_PRO: 32,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-P-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-06-05', A_TOT: 34,    A_ACT: 29,   A_REM: 5,     A_PRO: 85,  B_TOT: 12,   B_ACT: 10,  B_REM: 2,    B_PRO: 83,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20600-U-2001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-03-09', A_TOT: 19,    A_ACT: 0,    A_REM: 19,    A_PRO: 0,   B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20700-I-0202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-05-03', A_TOT: 30,    A_ACT: 12,   A_REM: 18,    A_PRO: 40,  B_TOT: 20,   B_ACT: 8,   B_REM: 12,   B_PRO: 40,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20700-U-3001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-06-14', A_TOT: 98,    A_ACT: 80,   A_REM: 18,    A_PRO: 81,  B_TOT: 17,   B_ACT: 8,   B_REM: 9,    B_PRO: 47,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20700-U-3301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-03-01', A_TOT: 17,    A_ACT: 10,   A_REM: 7,     A_PRO: 58,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20800-C-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-06-18', A_TOT: 65,    A_ACT: 23,   A_REM: 42,    A_PRO: 35,  B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20800-E-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-07-03', A_TOT: 614,   A_ACT: 304,  A_REM: 310,   A_PRO: 49,  B_TOT: 299,  B_ACT: 160, B_REM: 139,  B_PRO: 53,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20800-P-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-06-11', A_TOT: 34,    A_ACT: 6,    A_REM: 28,    A_PRO: 17,  B_TOT: 24,   B_ACT: 6,   B_REM: 18,   B_PRO: 25,  WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20800-U-7502', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-06-27', A_TOT: 152,   A_ACT: 84,   A_REM: 68,    A_PRO: 55,  B_TOT: 63,   B_ACT: 43,  B_REM: 20,   B_PRO: 68,  WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20800-U-7601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-19', A_TOT: 47,    A_ACT: 21,   A_REM: 26,    A_PRO: 44,  B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21100-E-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-21', A_TOT: 52,    A_ACT: 41,   A_REM: 11,    A_PRO: 78,  B_TOT: 29,   B_ACT: 24,  B_REM: 5,    B_PRO: 82,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-06', A_TOT: 79,    A_ACT: 54,   A_REM: 25,    A_PRO: 68,  B_TOT: 54,   B_ACT: 41,  B_REM: 13,   B_PRO: 75,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0208', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-11', A_TOT: 46,    A_ACT: 31,   A_REM: 15,    A_PRO: 67,  B_TOT: 24,   B_ACT: 17,  B_REM: 7,    B_PRO: 70,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0213', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-27', A_TOT: 288,   A_ACT: 122,  A_REM: 166,   A_PRO: 42,  B_TOT: 237,  B_ACT: 116, B_REM: 121,  B_PRO: 48,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-1201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-22', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-2103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-12', A_TOT: 121,   A_ACT: 49,   A_REM: 72,    A_PRO: 40,  B_TOT: 66,   B_ACT: 40,  B_REM: 26,   B_PRO: 60,  WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-3502', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-15', A_TOT: 95,    A_ACT: 68,   A_REM: 27,    A_PRO: 71,  B_TOT: 92,   B_ACT: 68,  B_REM: 24,   B_PRO: 73,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-4001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-20', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-6004', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-09-02', A_TOT: 827,   A_ACT: 85,   A_REM: 742,   A_PRO: 10,  B_TOT: 235,  B_ACT: 41,  B_REM: 194,  B_PRO: 17,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-7002', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-06-21', A_TOT: 14,    A_ACT: 0,    A_REM: 14,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '21200-C-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-01-24', A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21200-C-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-02-01', A_TOT: 48,    A_ACT: 0,    A_REM: 48,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '21200-C-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-07-01', A_TOT: 3,     A_ACT: 0,    A_REM: 3,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21200-E-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-02-24', A_TOT: 9,     A_ACT: 0,    A_REM: 9,     A_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21200-I-0202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-04-01', A_TOT: 13,    A_ACT: 6,    A_REM: 7,     A_PRO: 46,  B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-U-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-05-14', A_TOT: 35,    A_ACT: 3,    A_REM: 32,    A_PRO: 8,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-U-2001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-05-16', A_TOT: 27,    A_ACT: 3,    A_REM: 24,    A_PRO: 11,  B_TOT: 9,    B_ACT: 0,   B_REM: 9,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-U-2201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-05-21', A_TOT: 3,     A_ACT: 1,    A_REM: 2,     A_PRO: 33,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-U-4601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-07-01', A_TOT: 29,    A_ACT: 0,    A_REM: 29,    A_PRO: 0,   B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21300-I-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-08-07', A_TOT: 630,   A_ACT: 135,  A_REM: 495,   A_PRO: 21,  B_TOT: 430,  B_ACT: 105, B_REM: 325,  B_PRO: 24,  WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '21300-P-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-05-28', A_TOT: 128,   A_ACT: 60,   A_REM: 68,    A_PRO: 46,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21300-U-2103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-05-06', A_TOT: 15,    A_ACT: 12,   A_REM: 3,     A_PRO: 80,  B_TOT: 14,   B_ACT: 12,  B_REM: 2,    B_PRO: 85,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21300-U-4001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-03-09', A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-I-0202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-06-29', A_TOT: 89,    A_ACT: 18,   A_REM: 71,    A_PRO: 20,  B_TOT: 42,   B_ACT: 6,   B_REM: 36,   B_PRO: 14,  WAYPOINT: 'WP-34', CSU_MS: 'MS-21', SUBSYS_NO: '20100-P-0402', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-07-10', A_TOT: 26,    A_ACT: 0,    A_REM: 26,    A_PRO: 0,   B_TOT: 18,   B_ACT: 0,   B_REM: 18,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-P-0502', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-07-08', A_TOT: 339,   A_ACT: 125,  A_REM: 214,   A_PRO: 36,  B_TOT: 159,  B_ACT: 63,  B_REM: 96,   B_PRO: 39,  WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-P-0603', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-07-02', A_TOT: 1080,  A_ACT: 296,  A_REM: 784,   A_PRO: 27,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-23', SUBSYS_NO: '20100-U-2103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-06-08', A_TOT: 33,    A_ACT: 6,    A_REM: 27,    A_PRO: 18,  B_TOT: 29,   B_ACT: 0,   B_REM: 29,   B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20100-U-4601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-06-20', A_TOT: 58,    A_ACT: 38,   A_REM: 20,    A_PRO: 65,  B_TOT: 9,    B_ACT: 4,   B_REM: 5,    B_PRO: 44,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20101-U-7401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-05-11', A_TOT: 320,   A_ACT: 55,   A_REM: 265,   A_PRO: 17,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '20200-C-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-04-27', A_TOT: 28,    A_ACT: 0,    A_REM: 28,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-I-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-08-27', A_TOT: 767,   A_ACT: 315,  A_REM: 452,   A_PRO: 41,  B_TOT: 354,  B_ACT: 195, B_REM: 159,  B_PRO: 55,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0105', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-08-06', A_TOT: 919,   A_ACT: 53,   A_REM: 866,   A_PRO: 5,   B_TOT: 302,  B_ACT: 6,   B_REM: 296,  B_PRO: 2,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0804', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-05-25', A_TOT: 1309,  A_ACT: 461,  A_REM: 848,   A_PRO: 35,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '20200-U-2103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-06-19', A_TOT: 36,    A_ACT: 12,   A_REM: 24,    A_PRO: 33,  B_TOT: 4,    B_ACT: 4,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20300-C-5401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-08-28', A_TOT: 219,   A_ACT: 50,   A_REM: 169,   A_PRO: 22,  B_TOT: 63,   B_ACT: 3,   B_REM: 60,   B_PRO: 4,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20300-P-0202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-04-18', A_TOT: 90,    A_ACT: 27,   A_REM: 63,    A_PRO: 30,  B_TOT: 25,   B_ACT: 11,  B_REM: 14,   B_PRO: 44,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20300-U-2401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-06-07', A_TOT: 83,    A_ACT: 22,   A_REM: 61,    A_PRO: 26,  B_TOT: 22,   B_ACT: 12,  B_REM: 10,   B_PRO: 54,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-C-5401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-05-24', A_TOT: 99,    A_ACT: 20,   A_REM: 79,    A_PRO: 20,  B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-E-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-05-25', A_TOT: 88,    A_ACT: 4,    A_REM: 84,    A_PRO: 4,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-E-1301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-08-07', A_TOT: 498,   A_ACT: 174,  A_REM: 324,   A_PRO: 34,  B_TOT: 252,  B_ACT: 99,  B_REM: 153,  B_PRO: 39,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-08-20', A_TOT: 539,   A_ACT: 116,  A_REM: 423,   A_PRO: 21,  B_TOT: 207,  B_ACT: 49,  B_REM: 158,  B_PRO: 23,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0204', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-06-18', A_TOT: 283,   A_ACT: 31,   A_REM: 252,   A_PRO: 11,  B_TOT: 77,   B_ACT: 0,   B_REM: 77,   B_PRO: 0,   WAYPOINT: 'WP-18', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-03-04', A_TOT: 41,    A_ACT: 0,    A_REM: 41,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-T-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-06-27', A_TOT: 9,     A_ACT: 0,    A_REM: 9,     A_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-I-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-07-22', A_TOT: 624,   A_ACT: 67,   A_REM: 557,   A_PRO: 10,  B_TOT: 471,  B_ACT: 20,  B_REM: 451,  B_PRO: 4,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-P-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-02-24', A_TOT: 40,    A_ACT: 18,   A_REM: 22,    A_PRO: 45,  B_TOT: 28,   B_ACT: 14,  B_REM: 14,   B_PRO: 50,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-U-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-04-26', A_TOT: 61,    A_ACT: 24,   A_REM: 37,    A_PRO: 39,  B_TOT: 38,   B_ACT: 14,  B_REM: 24,   B_PRO: 36,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-U-1201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-05-28', A_TOT: 41,    A_ACT: 19,   A_REM: 22,    A_PRO: 46,  B_TOT: 16,   B_ACT: 10,  B_REM: 6,    B_PRO: 62,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-U-7402', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-02-01', A_TOT: 23,    A_ACT: 10,   A_REM: 13,    A_PRO: 43,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-C-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-05-27', A_TOT: 25,    A_ACT: 1,    A_REM: 24,    A_PRO: 4,   B_TOT: 9,    B_ACT: 0,   B_REM: 9,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-I-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-06-12', A_TOT: 86,    A_ACT: 52,   A_REM: 34,    A_PRO: 60,  B_TOT: 44,   B_ACT: 33,  B_REM: 11,   B_PRO: 75,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-U-3001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-06-07', A_TOT: 57,    A_ACT: 10,   A_REM: 47,    A_PRO: 17,  B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20700-C-5401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-06-12', A_TOT: 740,   A_ACT: 222,  A_REM: 518,   A_PRO: 30,  B_TOT: 171,  B_ACT: 43,  B_REM: 128,  B_PRO: 25,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20700-P-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-06-26', A_TOT: 1432,  A_ACT: 918,  A_REM: 514,   A_PRO: 64,  B_TOT: 393,  B_ACT: 188, B_REM: 205,  B_PRO: 47,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20700-P-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-06-15', A_TOT: 17,    A_ACT: 17,   A_REM: 0,     A_PRO: 100, B_TOT: 14,   B_ACT: 14,  B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20700-U-3201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-06-04', A_TOT: 67,    A_ACT: 39,   A_REM: 28,    A_PRO: 58,  B_TOT: 30,   B_ACT: 19,  B_REM: 11,   B_PRO: 63,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20700-U-3501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-06-05', A_TOT: 58,    A_ACT: 58,   A_REM: 0,     A_PRO: 100, B_TOT: 58,   B_ACT: 58,  B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20700-U-4001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-04-20', A_TOT: 126,   A_ACT: 12,   A_REM: 114,   A_PRO: 9,   B_TOT: 17,   B_ACT: 0,   B_REM: 17,   B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20800-E-1301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-07-05', A_TOT: 90,    A_ACT: 0,    A_REM: 90,    A_PRO: 0,   B_TOT: 20,   B_ACT: 0,   B_REM: 20,   B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20800-I-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-09-09', A_TOT: 2841,  A_ACT: 721,  A_REM: 2120,  A_PRO: 25,  B_TOT: 872,  B_ACT: 165, B_REM: 707,  B_PRO: 18,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20800-P-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-06-20', A_TOT: 17,    A_ACT: 2,    A_REM: 15,    A_PRO: 11,  B_TOT: 8,    B_ACT: 2,   B_REM: 6,    B_PRO: 25,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20800-U-4101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-03-23', A_TOT: 36,    A_ACT: 14,   A_REM: 22,    A_PRO: 38,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21100-C-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-24', A_TOT: 30,    A_ACT: 20,   A_REM: 10,    A_PRO: 66,  B_TOT: 26,   B_ACT: 18,  B_REM: 8,    B_PRO: 69,  WAYPOINT: 'WP-28', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0108', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-15', A_TOT: 12,    A_ACT: 11,   A_REM: 1,     A_PRO: 91,  B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0203', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-29', A_TOT: 39,    A_ACT: 26,   A_REM: 13,    A_PRO: 66,  B_TOT: 21,   B_ACT: 18,  B_REM: 3,    B_PRO: 85,  WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0214', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-08-08', A_TOT: 14,    A_ACT: 13,   A_REM: 1,     A_PRO: 92,  B_TOT: 14,   B_ACT: 14,  B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-28', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0215', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-07-22', A_TOT: 8,     A_ACT: 8,    A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-28', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0216', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-07-22', A_TOT: 3,     A_ACT: 3,    A_REM: 0,     A_PRO: 100, B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-28', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0217', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-03', A_TOT: 136,   A_ACT: 75,   A_REM: 61,    A_PRO: 55,  B_TOT: 120,  B_ACT: 75,  B_REM: 45,   B_PRO: 62,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-15', A_TOT: 8,     A_ACT: 4,    A_REM: 4,     A_PRO: 50,  B_TOT: 4,    B_ACT: 2,   B_REM: 2,    B_PRO: 50,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-2202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-04-17', A_TOT: 10,    A_ACT: 10,   A_REM: 0,     A_PRO: 100, B_TOT: 10,   B_ACT: 10,  B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-4102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-05', A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-4601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-05-15', A_TOT: 55,    A_ACT: 1,    A_REM: 54,    A_PRO: 1,   B_TOT: 24,   B_ACT: 0,   B_REM: 24,   B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-P-0103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-04-12', A_TOT: 95,    A_ACT: 65,   A_REM: 30,    A_PRO: 68,  B_TOT: 86,   B_ACT: 62,  B_REM: 24,   B_PRO: 72,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-U-4302', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-06-01', A_TOT: 141,   A_ACT: 74,   A_REM: 67,    A_PRO: 52,  B_TOT: 90,   B_ACT: 45,  B_REM: 45,   B_PRO: 50,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-U-4304', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-03-23', A_TOT: 1,     A_ACT: 0,    A_REM: 1,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21300-C-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-06-27', A_TOT: 64,    A_ACT: 19,   A_REM: 45,    A_PRO: 29,  B_TOT: 18,   B_ACT: 14,  B_REM: 4,    B_PRO: 77,  WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21300-C-5401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-03-25', A_TOT: 38,    A_ACT: 3,    A_REM: 35,    A_PRO: 7,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21401-E-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-07-02', A_TOT: 196,   A_ACT: 33,   A_REM: 163,   A_PRO: 16,  B_TOT: 87,   B_ACT: 26,  B_REM: 61,   B_PRO: 29,  WAYPOINT: 'WP-28', CSU_MS: 'MS-10', SUBSYS_NO: '21402-P-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-03-22', A_TOT: 13,    A_ACT: 12,   A_REM: 1,     A_PRO: 92,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-17', SUBSYS_NO: '21403-C-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-05-29', A_TOT: 197,   A_ACT: 170,  A_REM: 27,    A_PRO: 86,  B_TOT: 52,   B_ACT: 18,  B_REM: 34,   B_PRO: 34,  WAYPOINT: 'WP-31', CSU_MS: 'MS-17', SUBSYS_NO: '21403-P-0104', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', CSTD_PLAN: '2024-05-02', A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21501-E-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', CSTD_PLAN: '2024-02-15', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 1,    B_ACT: 1,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  SUBSYS_NO: '21502-I-0205', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', CSTD_PLAN: '2024-02-27', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  SUBSYS_NO: '21502-I-0206', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-01-12', A_TOT: 24,    A_ACT: 8,    A_REM: 16,    A_PRO: 33,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-C-0401', CSTD_WALKDOWN: '2023-09-25', CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-03-02', A_TOT: 1,     A_ACT: 0,    A_REM: 1,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-C-0501', CSTD_WALKDOWN: '2023-09-08', CSTD_ACTUAL: '2023-10-07', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-02-28', A_TOT: 60,    A_ACT: 60,   A_REM: 0,     A_PRO: 100, B_TOT: 9,    B_ACT: 9,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-E-0103', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-28', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-03-01', A_TOT: 24,    A_ACT: 24,   A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-E-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2023-11-24', A_TOT: 2,     A_ACT: 2,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-C-0701', CSTD_WALKDOWN: '2023-10-24', CSTD_ACTUAL: '2023-11-06', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-03-04', A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-E-0801', CSTD_WALKDOWN: '2023-09-20', CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     CSTD_PLAN: '2024-01-18', A_TOT: 43,    A_ACT: 0,    A_REM: 43,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-29', SUBSYS_NO: '20000-C-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-10-01', A_TOT: 28,    A_ACT: 0,    A_REM: 28,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-C-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-09-07', A_TOT: 118,   A_ACT: 0,    A_REM: 118,   A_PRO: 0,   B_TOT: 24,   B_ACT: 0,   B_REM: 24,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-I-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-09-05', A_TOT: 1552,  A_ACT: 615,  A_REM: 937,   A_PRO: 39,  B_TOT: 575,  B_ACT: 183, B_REM: 392,  B_PRO: 31,  WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-P-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-03-26', A_TOT: 31,    A_ACT: 0,    A_REM: 31,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-T-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-03-11', A_TOT: 58,    A_ACT: 33,   A_REM: 25,    A_PRO: 56,  B_TOT: 36,   B_ACT: 21,  B_REM: 15,   B_PRO: 58,  WAYPOINT: 'WP-34', CSU_MS: 'MS-23', SUBSYS_NO: '20100-U-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-05-10', A_TOT: 21,    A_ACT: 18,   A_REM: 3,     A_PRO: 85,  B_TOT: 15,   B_ACT: 13,  B_REM: 2,    B_PRO: 86,  WAYPOINT: 'WP-34', CSU_MS: 'MS-23', SUBSYS_NO: '20100-U-2102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-07-02', A_TOT: 69,    A_ACT: 22,   A_REM: 47,    A_PRO: 31,  B_TOT: 29,   B_ACT: 12,  B_REM: 17,   B_PRO: 41,  WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-U-6003', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-09-05', A_TOT: 180,   A_ACT: 96,   A_REM: 84,    A_PRO: 53,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-U-6102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-06-25', A_TOT: 283,   A_ACT: 131,  A_REM: 152,   A_PRO: 46,  B_TOT: 93,   B_ACT: 30,  B_REM: 63,   B_PRO: 32,  WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-U-7401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-04-22', A_TOT: 14,    A_ACT: 4,    A_REM: 10,    A_PRO: 28,  B_TOT: 12,   B_ACT: 4,   B_REM: 8,    B_PRO: 33,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20101-U-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-04-22', A_TOT: 10,    A_ACT: 6,    A_REM: 4,     A_PRO: 60,  B_TOT: 10,   B_ACT: 5,   B_REM: 5,    B_PRO: 50,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20101-U-4001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-06-03', A_TOT: 15,    A_ACT: 8,    A_REM: 7,     A_PRO: 53,  B_TOT: 12,   B_ACT: 5,   B_REM: 7,    B_PRO: 41,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20101-U-6005', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-10-08', A_TOT: 98,    A_ACT: 0,    A_REM: 98,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-I-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-02-28', A_TOT: 60,    A_ACT: 0,    A_REM: 60,    A_PRO: 0,   B_TOT: 14,   B_ACT: 0,   B_REM: 14,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-I-0202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-09-17', A_TOT: 114,   A_ACT: 7,    A_REM: 107,   A_PRO: 6,   B_TOT: 22,   B_ACT: 0,   B_REM: 22,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-08-13', A_TOT: 300,   A_ACT: 117,  A_REM: 183,   A_PRO: 39,  B_TOT: 108,  B_ACT: 49,  B_REM: 59,   B_PRO: 45,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0703', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-08-19', A_TOT: 342,   A_ACT: 69,   A_REM: 273,   A_PRO: 20,  B_TOT: 123,  B_ACT: 37,  B_REM: 86,   B_PRO: 30,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0704', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-08-06', A_TOT: 912,   A_ACT: 58,   A_REM: 854,   A_PRO: 6,   B_TOT: 310,  B_ACT: 7,   B_REM: 303,  B_PRO: 2,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0802', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-06-03', A_TOT: 41,    A_ACT: 0,    A_REM: 41,    A_PRO: 0,   B_TOT: 15,   B_ACT: 0,   B_REM: 15,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0902', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-06-18', A_TOT: 40,    A_ACT: 0,    A_REM: 40,    A_PRO: 0,   B_TOT: 14,   B_ACT: 0,   B_REM: 14,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0903', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-05-27', A_TOT: 139,   A_ACT: 31,   A_REM: 108,   A_PRO: 22,  B_TOT: 78,   B_ACT: 18,  B_REM: 60,   B_PRO: 23,  WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '20200-U-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-06-04', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '20200-U-2003', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-06-18', A_TOT: 143,   A_ACT: 42,   A_REM: 101,   A_PRO: 29,  B_TOT: 54,   B_ACT: 17,  B_REM: 37,   B_PRO: 31,  WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '20200-U-2301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-05-31', A_TOT: 28,    A_ACT: 6,    A_REM: 22,    A_PRO: 21,  B_TOT: 23,   B_ACT: 0,   B_REM: 23,   B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20200-U-4601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-09-07', A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '20300-C-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-10-02', A_TOT: 38,    A_ACT: 4,    A_REM: 34,    A_PRO: 10,  B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20300-E-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-09-14', A_TOT: 405,   A_ACT: 17,   A_REM: 388,   A_PRO: 4,   B_TOT: 65,   B_ACT: 0,   B_REM: 65,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20300-P-0105', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-08-27', A_TOT: 348,   A_ACT: 96,   A_REM: 252,   A_PRO: 27,  B_TOT: 145,  B_ACT: 28,  B_REM: 117,  B_PRO: 19,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20300-P-0302', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-08-26', A_TOT: 219,   A_ACT: 82,   A_REM: 137,   A_PRO: 37,  B_TOT: 90,   B_ACT: 31,  B_REM: 59,   B_PRO: 34,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20300-P-0303', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-05-13', A_TOT: 84,    A_ACT: 20,   A_REM: 64,    A_PRO: 23,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20300-U-2103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-07-26', A_TOT: 38,    A_ACT: 19,   A_REM: 19,    A_PRO: 50,  B_TOT: 33,   B_ACT: 19,  B_REM: 14,   B_PRO: 57,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20300-U-6006', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-05-15', A_TOT: 100,   A_ACT: 67,   A_REM: 33,    A_PRO: 67,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '20400-C-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-09-19', A_TOT: 24,    A_ACT: 6,    A_REM: 18,    A_PRO: 25,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-E-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-07-03', A_TOT: 108,   A_ACT: 19,   A_REM: 89,    A_PRO: 17,  B_TOT: 26,   B_ACT: 0,   B_REM: 26,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0206', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-08-21', A_TOT: 3005,  A_ACT: 348,  A_REM: 2657,  A_PRO: 11,  B_TOT: 641,  B_ACT: 0,   B_REM: 641,  B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0207', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-07-08', A_TOT: 71,    A_ACT: 53,   A_REM: 18,    A_PRO: 74,  B_TOT: 30,   B_ACT: 26,  B_REM: 4,    B_PRO: 86,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-05-21', A_TOT: 439,   A_ACT: 133,  A_REM: 306,   A_PRO: 30,  B_TOT: 338,  B_ACT: 133, B_REM: 205,  B_PRO: 39,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0702', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-06-06', A_TOT: 28,    A_ACT: 13,   A_REM: 15,    A_PRO: 46,  B_TOT: 21,   B_ACT: 10,  B_REM: 11,   B_PRO: 47,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-U-2101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-05-11', A_TOT: 66,    A_ACT: 15,   A_REM: 51,    A_PRO: 22,  B_TOT: 36,   B_ACT: 12,  B_REM: 24,   B_PRO: 33,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-U-4001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-05-14', A_TOT: 3,     A_ACT: 1,    A_REM: 2,     A_PRO: 33,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20400-U-4601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-06-10', A_TOT: 108,   A_ACT: 63,   A_REM: 45,    A_PRO: 58,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-C-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-07-29', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-E-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-02-15', A_TOT: 31,    A_ACT: 22,   A_REM: 9,     A_PRO: 71,  B_TOT: 28,   B_ACT: 22,  B_REM: 6,    B_PRO: 78,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-U-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-03-09', A_TOT: 9,     A_ACT: 0,    A_REM: 9,     A_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20500-I-0202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-06-03', A_TOT: 108,   A_ACT: 2,    A_REM: 106,   A_PRO: 1,   B_TOT: 38,   B_ACT: 4,   B_REM: 34,   B_PRO: 10,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-P-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-05-27', A_TOT: 162,   A_ACT: 11,   A_REM: 151,   A_PRO: 6,   B_TOT: 36,   B_ACT: 0,   B_REM: 36,   B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20700-I-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-06-11', A_TOT: 1007,  A_ACT: 673,  A_REM: 334,   A_PRO: 66,  B_TOT: 296,  B_ACT: 151, B_REM: 145,  B_PRO: 51,  WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20700-P-0502', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-06-10', A_TOT: 256,   A_ACT: 192,  A_REM: 64,    A_PRO: 75,  B_TOT: 58,   B_ACT: 43,  B_REM: 15,   B_PRO: 74,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20700-U-2201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-06-10', A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20700-U-4601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-04-29', A_TOT: 88,    A_ACT: 11,   A_REM: 77,    A_PRO: 12,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '20800-C-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-02-08', A_TOT: 46,    A_ACT: 22,   A_REM: 24,    A_PRO: 47,  B_TOT: 26,   B_ACT: 10,  B_REM: 16,   B_PRO: 38,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20800-U-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-04-25', A_TOT: 80,    A_ACT: 50,   A_REM: 30,    A_PRO: 62,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '21100-C-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-06', A_TOT: 11,    A_ACT: 6,    A_REM: 5,     A_PRO: 54,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21100-C-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-27', A_TOT: 25,    A_ACT: 1,    A_REM: 24,    A_PRO: 4,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21100-E-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-24', A_TOT: 13,    A_ACT: 9,    A_REM: 4,     A_PRO: 69,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0107', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-29', A_TOT: 10,    A_ACT: 8,    A_REM: 2,     A_PRO: 80,  B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0110', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-27', A_TOT: 16,    A_ACT: 16,   A_REM: 0,     A_PRO: 100, B_TOT: 16,   B_ACT: 15,  B_REM: 1,    B_PRO: 93,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0211', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-04', A_TOT: 353,   A_ACT: 265,  A_REM: 88,    A_PRO: 75,  B_TOT: 195,  B_ACT: 162, B_REM: 33,   B_PRO: 83,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-3001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-10', A_TOT: 3,     A_ACT: 0,    A_REM: 3,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-4602', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-20', A_TOT: 217,   A_ACT: 49,   A_REM: 168,   A_PRO: 22,  B_TOT: 91,   B_ACT: 45,  B_REM: 46,   B_PRO: 49,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-7001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-06', A_TOT: 19,    A_ACT: 10,   A_REM: 9,     A_PRO: 52,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-7102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-06-08', A_TOT: 30,    A_ACT: 10,   A_REM: 20,    A_PRO: 33,  B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-C-5401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-05-17', A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21200-I-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-05-16', A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-U-3101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-08-16', A_TOT: 6,     A_ACT: 0,    A_REM: 6,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '21300-C-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-07-02', A_TOT: 220,   A_ACT: 68,   A_REM: 152,   A_PRO: 30,  B_TOT: 103,  B_ACT: 24,  B_REM: 79,   B_PRO: 23,  WAYPOINT: 'WP-24', CSU_MS: 'MS-10', SUBSYS_NO: '21300-P-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-05-23', A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21300-U-4601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-03-06', A_TOT: 111,   A_ACT: 51,   A_REM: 60,    A_PRO: 45,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '21401-C-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-04-04', A_TOT: 295,   A_ACT: 126,  A_REM: 169,   A_PRO: 42,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '21402-C-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-03-25', A_TOT: 34,    A_ACT: 18,   A_REM: 16,    A_PRO: 52,  B_TOT: 9,    B_ACT: 0,   B_REM: 9,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21402-E-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-07-02', A_TOT: 190,   A_ACT: 28,   A_REM: 162,   A_PRO: 14,  B_TOT: 74,   B_ACT: 18,  B_REM: 56,   B_PRO: 24,  WAYPOINT: 'WP-28', CSU_MS: 'MS-10', SUBSYS_NO: '21402-P-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     CSTD_PLAN: '2023-12-14', A_TOT: 9,     A_ACT: 0,    A_REM: 9,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-26', SUBSYS_NO: '20000-C-0601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-09-05', A_TOT: 228,   A_ACT: 38,   A_REM: 190,   A_PRO: 16,  B_TOT: 112,  B_ACT: 8,   B_REM: 104,  B_PRO: 7,   WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-P-0303', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-06-26', A_TOT: 382,   A_ACT: 84,   A_REM: 298,   A_PRO: 22,  B_TOT: 248,  B_ACT: 58,  B_REM: 190,  B_PRO: 23,  WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-P-0602', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-04-25', A_TOT: 19,    A_ACT: 15,   A_REM: 4,     A_PRO: 78,  B_TOT: 18,   B_ACT: 15,  B_REM: 3,    B_PRO: 83,  WAYPOINT: 'WP-34', CSU_MS: 'MS-23', SUBSYS_NO: '20100-U-1202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-07-02', A_TOT: 39,    A_ACT: 18,   A_REM: 21,    A_PRO: 46,  B_TOT: 18,   B_ACT: 12,  B_REM: 6,    B_PRO: 66,  WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-U-6004', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-05-31', A_TOT: 9,     A_ACT: 4,    A_REM: 5,     A_PRO: 44,  B_TOT: 7,    B_ACT: 2,   B_REM: 5,    B_PRO: 28,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20101-U-2102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-02-06', A_TOT: 1700,  A_ACT: 454,  A_REM: 1246,  A_PRO: 26,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '20200-C-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-06-22', A_TOT: 761,   A_ACT: 0,    A_REM: 761,   A_PRO: 0,   B_TOT: 157,  B_ACT: 0,   B_REM: 157,  B_PRO: 0,   WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '20200-I-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-07-10', A_TOT: 1578,  A_ACT: 243,  A_REM: 1335,  A_PRO: 15,  B_TOT: 555,  B_ACT: 168, B_REM: 387,  B_PRO: 30,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-08-16', A_TOT: 617,   A_ACT: 134,  A_REM: 483,   A_PRO: 21,  B_TOT: 245,  B_ACT: 67,  B_REM: 178,  B_PRO: 27,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0402', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-07-20', A_TOT: 425,   A_ACT: 89,   A_REM: 336,   A_PRO: 20,  B_TOT: 165,  B_ACT: 46,  B_REM: 119,  B_PRO: 27,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-08-24', A_TOT: 91,    A_ACT: 30,   A_REM: 61,    A_PRO: 33,  B_TOT: 15,   B_ACT: 0,   B_REM: 15,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0706', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-07-15', A_TOT: 221,   A_ACT: 24,   A_REM: 197,   A_PRO: 10,  B_TOT: 66,   B_ACT: 0,   B_REM: 66,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0805', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-06-04', A_TOT: 39,    A_ACT: 0,    A_REM: 39,    A_PRO: 0,   B_TOT: 15,   B_ACT: 0,   B_REM: 15,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0901', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-05-25', A_TOT: 126,   A_ACT: 51,   A_REM: 75,    A_PRO: 40,  B_TOT: 106,  B_ACT: 51,  B_REM: 55,   B_PRO: 48,  WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '20200-U-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-09-18', A_TOT: 820,   A_ACT: 172,  A_REM: 648,   A_PRO: 21,  B_TOT: 335,  B_ACT: 105, B_REM: 230,  B_PRO: 31,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20300-P-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-07-31', A_TOT: 372,   A_ACT: 54,   A_REM: 318,   A_PRO: 14,  B_TOT: 119,  B_ACT: 0,   B_REM: 119,  B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20300-P-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-06-10', A_TOT: 191,   A_ACT: 3,    A_REM: 188,   A_PRO: 1,   B_TOT: 67,   B_ACT: 0,   B_REM: 67,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-I-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-07-27', A_TOT: 403,   A_ACT: 34,   A_REM: 369,   A_PRO: 8,   B_TOT: 109,  B_ACT: 4,   B_REM: 105,  B_PRO: 3,   WAYPOINT: 'WP-18', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-09-16', A_TOT: 451,   A_ACT: 21,   A_REM: 430,   A_PRO: 4,   B_TOT: 153,  B_ACT: 0,   B_REM: 153,  B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0304', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-07-29', A_TOT: 740,   A_ACT: 252,  A_REM: 488,   A_PRO: 34,  B_TOT: 297,  B_ACT: 104, B_REM: 193,  B_PRO: 35,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0402', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-04-16', A_TOT: 64,    A_ACT: 22,   A_REM: 42,    A_PRO: 34,  B_TOT: 46,   B_ACT: 22,  B_REM: 24,   B_PRO: 47,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-U-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-04-17', A_TOT: 21,    A_ACT: 8,    A_REM: 13,    A_PRO: 38,  B_TOT: 18,   B_ACT: 8,   B_REM: 10,   B_PRO: 44,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-U-1202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-05-15', A_TOT: 51,    A_ACT: 50,   A_REM: 1,     A_PRO: 98,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-C-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-07-12', A_TOT: 149,   A_ACT: 32,   A_REM: 117,   A_PRO: 21,  B_TOT: 51,   B_ACT: 12,  B_REM: 39,   B_PRO: 23,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-P-0102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-09-11', A_TOT: 461,   A_ACT: 154,  A_REM: 307,   A_PRO: 33,  B_TOT: 187,  B_ACT: 44,  B_REM: 143,  B_PRO: 23,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-P-0103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-04-18', A_TOT: 17,    A_ACT: 9,    A_REM: 8,     A_PRO: 52,  B_TOT: 11,   B_ACT: 6,   B_REM: 5,    B_PRO: 54,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-U-4101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-06-06', A_TOT: 16,    A_ACT: 10,   A_REM: 6,     A_PRO: 62,  B_TOT: 14,   B_ACT: 10,  B_REM: 4,    B_PRO: 71,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-U-4301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-06-04', A_TOT: 18,    A_ACT: 2,    A_REM: 16,    A_PRO: 11,  B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20500-I-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-07-09', A_TOT: 222,   A_ACT: 130,  A_REM: 92,    A_PRO: 58,  B_TOT: 129,  B_ACT: 78,  B_REM: 51,   B_PRO: 60,  WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20500-P-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-02-03', A_TOT: 18,    A_ACT: 6,    A_REM: 12,    A_PRO: 33,  B_TOT: 14,   B_ACT: 6,   B_REM: 8,    B_PRO: 42,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20500-U-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-05-17', A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, B_TOT: 7,    B_ACT: 6,   B_REM: 1,    B_PRO: 85,  WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20500-U-2102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-07-29', A_TOT: 21,    A_ACT: 0,    A_REM: 21,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-C-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-07-15', A_TOT: 300,   A_ACT: 70,   A_REM: 230,   A_PRO: 23,  B_TOT: 196,  B_ACT: 55,  B_REM: 141,  B_PRO: 28,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-P-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-05-06', A_TOT: 20,    A_ACT: 20,   A_REM: 0,     A_PRO: 100, B_TOT: 20,   B_ACT: 20,  B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-U-1202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-03-22', A_TOT: 36,    A_ACT: 36,   A_REM: 0,     A_PRO: 100, B_TOT: 25,   B_ACT: 24,  B_REM: 1,    B_PRO: 96,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-U-2102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-05-16', A_TOT: 172,   A_ACT: 84,   A_REM: 88,    A_PRO: 48,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-U-2103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-05-11', A_TOT: 39,    A_ACT: 33,   A_REM: 6,     A_PRO: 84,  B_TOT: 36,   B_ACT: 32,  B_REM: 4,    B_PRO: 88,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-U-4001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-06-15', A_TOT: 16,    A_ACT: 6,    A_REM: 10,    A_PRO: 37,  B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-U-4101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-07-12', A_TOT: 26,    A_ACT: 0,    A_REM: 26,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '20700-C-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-02-01', A_TOT: 34,    A_ACT: 14,   A_REM: 20,    A_PRO: 41,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20700-C-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-06-07', A_TOT: 431,   A_ACT: 182,  A_REM: 249,   A_PRO: 42,  B_TOT: 126,  B_ACT: 32,  B_REM: 94,   B_PRO: 25,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20700-P-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-06-24', A_TOT: 589,   A_ACT: 54,   A_REM: 535,   A_PRO: 9,   B_TOT: 132,  B_ACT: 20,  B_REM: 112,  B_PRO: 15,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20700-P-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-06-19', A_TOT: 180,   A_ACT: 20,   A_REM: 160,   A_PRO: 11,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20700-U-2003', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-05-13', A_TOT: 69,    A_ACT: 67,   A_REM: 2,     A_PRO: 97,  B_TOT: 47,   B_ACT: 46,  B_REM: 1,    B_PRO: 97,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20700-U-2102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-05-23', A_TOT: 119,   A_ACT: 77,   A_REM: 42,    A_PRO: 64,  B_TOT: 38,   B_ACT: 22,  B_REM: 16,   B_PRO: 57,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20700-U-3401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-05-27', A_TOT: 6,     A_ACT: 0,    A_REM: 6,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20800-E-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-07-02', A_TOT: 29,    A_ACT: 0,    A_REM: 29,    A_PRO: 0,   B_TOT: 11,   B_ACT: 0,   B_REM: 11,   B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20800-I-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-04-15', A_TOT: 17,    A_ACT: 0,    A_REM: 17,    A_PRO: 0,   B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20800-I-0202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-06-19', A_TOT: 14,    A_ACT: 14,   A_REM: 0,     A_PRO: 100, B_TOT: 14,   B_ACT: 14,  B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20800-U-4001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2023-12-14', A_TOT: 355,   A_ACT: 235,  A_REM: 120,   A_PRO: 66,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '21100-C-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-03-23', A_TOT: 56,    A_ACT: 0,    A_REM: 56,    A_PRO: 0,   B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21100-I-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-04-17', A_TOT: 8,     A_ACT: 8,    A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0106', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-29', A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0112', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-29', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0114', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-06', A_TOT: 52,    A_ACT: 49,   A_REM: 3,     A_PRO: 94,  B_TOT: 52,   B_ACT: 49,  B_REM: 3,    B_PRO: 94,  WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0122', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-21', A_TOT: 9,     A_ACT: 6,    A_REM: 3,     A_PRO: 66,  B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0124', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-25', A_TOT: 106,   A_ACT: 69,   A_REM: 37,    A_PRO: 65,  B_TOT: 52,   B_ACT: 38,  B_REM: 14,   B_PRO: 73,  WAYPOINT: 'WP-28', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0125', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-07-30', A_TOT: 146,   A_ACT: 8,    A_REM: 138,   A_PRO: 5,   B_TOT: 91,   B_ACT: 0,   B_REM: 91,   B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-1002', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-07-06', A_TOT: 603,   A_ACT: 451,  A_REM: 152,   A_PRO: 74,  B_TOT: 407,  B_ACT: 309, B_REM: 98,   B_PRO: 75,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-3101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-04-26', A_TOT: 81,    A_ACT: 53,   A_REM: 28,    A_PRO: 65,  B_TOT: 70,   B_ACT: 48,  B_REM: 22,   B_PRO: 68,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-4101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-05-07', A_TOT: 9,     A_ACT: 6,    A_REM: 3,     A_PRO: 66,  B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-U-1201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-06-20', A_TOT: 254,   A_ACT: 65,   A_REM: 189,   A_PRO: 25,  B_TOT: 186,  B_ACT: 65,  B_REM: 121,  B_PRO: 34,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-U-4303', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-05-27', A_TOT: 6,     A_ACT: 3,    A_REM: 3,     A_PRO: 50,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21300-E-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-07-01', A_TOT: 7,     A_ACT: 0,    A_REM: 7,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21300-E-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-02-27', A_TOT: 10,    A_ACT: 2,    A_REM: 8,     A_PRO: 20,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21401-C-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-03-06', A_TOT: 27,    A_ACT: 1,    A_REM: 26,    A_PRO: 3,   B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21401-E-1401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-03-14', A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21401-I-0202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-04-20', A_TOT: 27,    A_ACT: 0,    A_REM: 27,    A_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21401-I-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-04-17', A_TOT: 28,    A_ACT: 0,    A_REM: 28,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21401-T-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-05-03', A_TOT: 10,    A_ACT: 4,    A_REM: 6,     A_PRO: 40,  B_TOT: 8,    B_ACT: 4,   B_REM: 4,    B_PRO: 50,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21401-U-1202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-05-06', A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, B_TOT: 7,    B_ACT: 6,   B_REM: 1,    B_PRO: 85,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21401-U-2101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-06-13', A_TOT: 452,   A_ACT: 42,   A_REM: 410,   A_PRO: 9,   B_TOT: 147,  B_ACT: 23,  B_REM: 124,  B_PRO: 15,  WAYPOINT: 'WP-28', CSU_MS: 'MS-10', SUBSYS_NO: '21402-P-0103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     CSTD_PLAN: '2024-03-02', A_TOT: 116,   A_ACT: 28,   A_REM: 88,    A_PRO: 24,  B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-8',  SUBSYS_NO: '20000-C-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-01-25', A_TOT: 408,   A_ACT: 186,  A_REM: 222,   A_PRO: 45,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-C-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-04-01', A_TOT: 73,    A_ACT: 17,   A_REM: 56,    A_PRO: 23,  B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-E-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-09-02', A_TOT: 28,    A_ACT: 0,    A_REM: 28,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-I-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-06-05', A_TOT: 610,   A_ACT: 302,  A_REM: 308,   A_PRO: 49,  B_TOT: 185,  B_ACT: 66,  B_REM: 119,  B_PRO: 35,  WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-P-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-07-02', A_TOT: 177,   A_ACT: 110,  A_REM: 67,    A_PRO: 62,  B_TOT: 98,   B_ACT: 65,  B_REM: 33,   B_PRO: 66,  WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-P-0601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-03-16', A_TOT: 54,    A_ACT: 23,   A_REM: 31,    A_PRO: 42,  B_TOT: 44,   B_ACT: 23,  B_REM: 21,   B_PRO: 52,  WAYPOINT: 'WP-34', CSU_MS: 'MS-23', SUBSYS_NO: '20100-U-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         CSTD_PLAN: '2024-07-01', A_TOT: 96,    A_ACT: 42,   A_REM: 54,    A_PRO: 43,  B_TOT: 47,   B_ACT: 22,  B_REM: 25,   B_PRO: 46,  WAYPOINT: 'WP-34', CSU_MS: 'MS-26', SUBSYS_NO: '20100-U-6001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-04-20', A_TOT: 26,    A_ACT: 0,    A_REM: 26,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '20101-C-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-03-09', A_TOT: 9,     A_ACT: 3,    A_REM: 6,     A_PRO: 33,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20101-C-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        CSTD_PLAN: '2024-05-27', A_TOT: 18,    A_ACT: 7,    A_REM: 11,    A_PRO: 38,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20101-E-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-04-24', A_TOT: 331,   A_ACT: 49,   A_REM: 282,   A_PRO: 14,  B_TOT: 99,   B_ACT: 35,  B_REM: 64,   B_PRO: 35,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-08-17', A_TOT: 112,   A_ACT: 24,   A_REM: 88,    A_PRO: 21,  B_TOT: 16,   B_ACT: 0,   B_REM: 16,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0108', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-09-17', A_TOT: 735,   A_ACT: 215,  A_REM: 520,   A_PRO: 29,  B_TOT: 295,  B_ACT: 83,  B_REM: 212,  B_PRO: 28,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-07-02', A_TOT: 291,   A_ACT: 66,   A_REM: 225,   A_PRO: 22,  B_TOT: 104,  B_ACT: 43,  B_REM: 61,   B_PRO: 41,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-09-11', A_TOT: 647,   A_ACT: 62,   A_REM: 585,   A_PRO: 9,   B_TOT: 260,  B_ACT: 45,  B_REM: 215,  B_PRO: 17,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-07-15', A_TOT: 83,    A_ACT: 22,   A_REM: 61,    A_PRO: 26,  B_TOT: 49,   B_ACT: 23,  B_REM: 26,   B_PRO: 46,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0602', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-07-27', A_TOT: 567,   A_ACT: 96,   A_REM: 471,   A_PRO: 16,  B_TOT: 106,  B_ACT: 5,   B_REM: 101,  B_PRO: 4,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0701', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-09-27', A_TOT: 953,   A_ACT: 102,  A_REM: 851,   A_PRO: 10,  B_TOT: 355,  B_ACT: 14,  B_REM: 341,  B_PRO: 3,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20200-P-0702', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      CSTD_PLAN: '2024-05-25', A_TOT: 102,   A_ACT: 77,   A_REM: 25,    A_PRO: 75,  B_TOT: 71,   B_ACT: 53,  B_REM: 18,   B_PRO: 74,  WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '20200-U-2102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-05-11', A_TOT: 48,    A_ACT: 9,    A_REM: 39,    A_PRO: 18,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '20300-C-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-06-26', A_TOT: 2693,  A_ACT: 293,  A_REM: 2400,  A_PRO: 10,  B_TOT: 578,  B_ACT: 8,   B_REM: 570,  B_PRO: 1,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20300-P-0103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-07-08', A_TOT: 47,    A_ACT: 30,   A_REM: 17,    A_PRO: 63,  B_TOT: 7,    B_ACT: 2,   B_REM: 5,    B_PRO: 28,  WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20300-P-0104', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-09-20', A_TOT: 657,   A_ACT: 180,  A_REM: 477,   A_PRO: 27,  B_TOT: 253,  B_ACT: 73,  B_REM: 180,  B_PRO: 28,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20300-P-0305', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      CSTD_PLAN: '2024-08-05', A_TOT: 86,    A_ACT: 17,   A_REM: 69,    A_PRO: 19,  B_TOT: 41,   B_ACT: 13,  B_REM: 28,   B_PRO: 31,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20300-P-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-06-10', A_TOT: 41,    A_ACT: 0,    A_REM: 41,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-I-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-06-27', A_TOT: 281,   A_ACT: 0,    A_REM: 281,   A_PRO: 0,   B_TOT: 58,   B_ACT: 0,   B_REM: 58,   B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-I-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-06-19', A_TOT: 518,   A_ACT: 116,  A_REM: 402,   A_PRO: 22,  B_TOT: 179,  B_ACT: 35,  B_REM: 144,  B_PRO: 19,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0302', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-09-16', A_TOT: 408,   A_ACT: 19,   A_REM: 389,   A_PRO: 4,   B_TOT: 150,  B_ACT: 0,   B_REM: 150,  B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-P-0305', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        CSTD_PLAN: '2024-05-10', A_TOT: 58,    A_ACT: 20,   A_REM: 38,    A_PRO: 34,  B_TOT: 23,   B_ACT: 4,   B_REM: 19,   B_PRO: 17,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20400-U-2401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-05-24', A_TOT: 22,    A_ACT: 22,   A_REM: 0,     A_PRO: 100, B_TOT: 16,   B_ACT: 16,  B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20401-U-3101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     CSTD_PLAN: '2024-04-26', A_TOT: 3,     A_ACT: 1,    A_REM: 2,     A_PRO: 33,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20401-U-4601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-02-20', A_TOT: 7,     A_ACT: 2,    A_REM: 5,     A_PRO: 28,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20500-C-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-06-12', A_TOT: 44,    A_ACT: 16,   A_REM: 28,    A_PRO: 36,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20500-U-2103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         CSTD_PLAN: '2024-05-13', A_TOT: 6,     A_ACT: 6,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20500-U-4001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-05-30', A_TOT: 36,    A_ACT: 0,    A_REM: 36,    A_PRO: 0,   B_TOT: 8,    B_ACT: 0,   B_REM: 8,    B_PRO: 0,   WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-I-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-01-30', A_TOT: 42,    A_ACT: 18,   A_REM: 24,    A_PRO: 42,  B_TOT: 28,   B_ACT: 14,  B_REM: 14,   B_PRO: 50,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-U-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-06-07', A_TOT: 10,    A_ACT: 8,    A_REM: 2,     A_PRO: 80,  B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20600-U-3301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         CSTD_PLAN: '2024-06-08', A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20600-U-4601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-05-27', A_TOT: 12,    A_ACT: 6,    A_REM: 6,     A_PRO: 50,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20700-E-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-05-04', A_TOT: 104,   A_ACT: 45,   A_REM: 59,    A_PRO: 43,  B_TOT: 37,   B_ACT: 0,   B_REM: 37,   B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20700-I-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-06-06', A_TOT: 242,   A_ACT: 55,   A_REM: 187,   A_PRO: 22,  B_TOT: 78,   B_ACT: 12,  B_REM: 66,   B_PRO: 15,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '20700-P-0102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-03-28', A_TOT: 27,    A_ACT: 0,    A_REM: 27,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '20700-T-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         CSTD_PLAN: '2024-06-06', A_TOT: 216,   A_ACT: 73,   A_REM: 143,   A_PRO: 33,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20700-U-2103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         CSTD_PLAN: '2024-06-25', A_TOT: 140,   A_ACT: 52,   A_REM: 88,    A_PRO: 37,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '20800-U-2103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-22', A_TOT: 63,    A_ACT: 46,   A_REM: 17,    A_PRO: 73,  B_TOT: 51,   B_ACT: 44,  B_REM: 7,    B_PRO: 86,  WAYPOINT: 'WP-28', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0116', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-29', A_TOT: 8,     A_ACT: 8,    A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0118', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-20', A_TOT: 28,    A_ACT: 22,   A_REM: 6,     A_PRO: 78,  B_TOT: 26,   B_ACT: 21,  B_REM: 5,    B_PRO: 80,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0126', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-20', A_TOT: 7,     A_ACT: 6,    A_REM: 1,     A_PRO: 85,  B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-28', CSU_MS: 'MS-10', SUBSYS_NO: '21100-P-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-05-02', A_TOT: 61,    A_ACT: 30,   A_REM: 31,    A_PRO: 49,  B_TOT: 35,   B_ACT: 22,  B_REM: 13,   B_PRO: 62,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-1202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-08', A_TOT: 544,   A_ACT: 189,  A_REM: 355,   A_PRO: 34,  B_TOT: 181,  B_ACT: 110, B_REM: 71,   B_PRO: 60,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-2201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-19', A_TOT: 15,    A_ACT: 15,   A_REM: 0,     A_PRO: 100, B_TOT: 10,   B_ACT: 10,  B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-31', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-3402', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-11', A_TOT: 26,    A_ACT: 14,   A_REM: 12,    A_PRO: 53,  B_TOT: 22,   B_ACT: 14,  B_REM: 8,    B_PRO: 63,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-4002', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-08-07', A_TOT: 76,    A_ACT: 13,   A_REM: 63,    A_PRO: 17,  B_TOT: 36,   B_ACT: 12,  B_REM: 24,   B_PRO: 33,  WAYPOINT: 'WP-24', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-5401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-07-10', A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-6102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-06-10', A_TOT: 66,    A_ACT: 40,   A_REM: 26,    A_PRO: 60,  B_TOT: 49,   B_ACT: 35,  B_REM: 14,   B_PRO: 71,  WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-7101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         CSTD_PLAN: '2024-07-03', A_TOT: 24,    A_ACT: 22,   A_REM: 2,     A_PRO: 91,  B_TOT: 16,   B_ACT: 16,  B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-34', CSU_MS: 'MS-10', SUBSYS_NO: '21100-U-7501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-04-02', A_TOT: 9,     A_ACT: 6,    A_REM: 3,     A_PRO: 66,  B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-U-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-05-18', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 9,    B_ACT: 8,   B_REM: 1,    B_PRO: 88,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-U-2102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-05-10', A_TOT: 96,    A_ACT: 24,   A_REM: 72,    A_PRO: 25,  B_TOT: 72,   B_ACT: 24,  B_REM: 48,   B_PRO: 33,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-U-4306', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         CSTD_PLAN: '2024-05-04', A_TOT: 40,    A_ACT: 16,   A_REM: 24,    A_PRO: 40,  B_TOT: 32,   B_ACT: 16,  B_REM: 16,   B_PRO: 50,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21200-U-4307', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-03-09', A_TOT: 14,    A_ACT: 0,    A_REM: 14,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21300-I-0202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-05-27', A_TOT: 32,    A_ACT: 20,   A_REM: 12,    A_PRO: 62,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21300-I-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-07-10', A_TOT: 466,   A_ACT: 189,  A_REM: 277,   A_PRO: 40,  B_TOT: 305,  B_ACT: 141, B_REM: 164,  B_PRO: 46,  WAYPOINT: 'WP-24', CSU_MS: 'MS-10', SUBSYS_NO: '21300-P-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-05-06', A_TOT: 18,    A_ACT: 18,   A_REM: 0,     A_PRO: 100, B_TOT: 18,   B_ACT: 18,  B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21300-U-1202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-05-25', A_TOT: 8,     A_ACT: 4,    A_REM: 4,     A_PRO: 50,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21300-U-2003', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-05-10', A_TOT: 21,    A_ACT: 21,   A_REM: 0,     A_PRO: 100, B_TOT: 15,   B_ACT: 14,  B_REM: 1,    B_PRO: 93,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21300-U-2102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'DS',          CSTD_PLAN: '2024-06-08', A_TOT: 34,    A_ACT: 11,   A_REM: 23,    A_PRO: 32,  B_TOT: 14,   B_ACT: 6,   B_REM: 8,    B_PRO: 42,  WAYPOINT: 'WP-24', CSU_MS: 'MS-10', SUBSYS_NO: '21300-U-7101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-06-28', A_TOT: 40,    A_ACT: 37,   A_REM: 3,     A_PRO: 92,  B_TOT: 14,   B_ACT: 10,  B_REM: 4,    B_PRO: 71,  WAYPOINT: 'WP-31', CSU_MS: 'MS-17', SUBSYS_NO: '21403-P-0102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-07-03', A_TOT: 274,   A_ACT: 111,  A_REM: 163,   A_PRO: 40,  B_TOT: 85,   B_ACT: 19,  B_REM: 66,   B_PRO: 22,  WAYPOINT: 'WP-28', CSU_MS: 'MS-35', SUBSYS_NO: '21403-P-0103', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-07-10', A_TOT: 33,    A_ACT: 33,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  WAYPOINT: 'WP-31', CSU_MS: 'MS-17', SUBSYS_NO: '21403-P-0105', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-02-08', A_TOT: 17,    A_ACT: 17,   A_REM: 0,     A_PRO: 100, B_TOT: 18,   B_ACT: 18,  B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-19', CSU_MS: 'MS-9',  SUBSYS_NO: '21403-U-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', CSTD_PLAN: '2024-03-25', A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   B_TOT: 6,    B_ACT: 3,   B_REM: 3,    B_PRO: 50,  WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21501-H-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', CSTD_PLAN: '2024-03-30', A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21501-I-0203', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', CSTD_PLAN: '2024-02-01', A_TOT: 1,     A_ACT: 0,    A_REM: 1,     A_PRO: 0,   B_TOT: 20,   B_ACT: 14,  B_REM: 6,    B_PRO: 70,  WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  SUBSYS_NO: '21502-I-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-03-02', A_TOT: 74,    A_ACT: 74,   A_REM: 0,     A_PRO: 100, B_TOT: 16,   B_ACT: 15,  B_REM: 1,    B_PRO: 93,  WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-E-0301', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-28', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-03-06', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 5,    B_ACT: 5,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-E-1101', CSTD_WALKDOWN: '2023-09-07', CSTD_ACTUAL: '2023-10-08', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-02-26', A_TOT: 4,     A_ACT: 2,    A_REM: 2,     A_PRO: 50,  B_TOT: 1,    B_ACT: 1,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-I-0202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-02-27', A_TOT: 12,    A_ACT: 2,    A_REM: 10,    A_PRO: 16,  B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-T-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-03-16', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 5,   B_REM: 3,    B_PRO: 62,  WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-E-0501', CSTD_WALKDOWN: '2023-10-23', CSTD_ACTUAL: '2023-11-03', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-03-08', A_TOT: 3,     A_ACT: 3,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-E-1001', CSTD_WALKDOWN: '2023-10-23', CSTD_ACTUAL: '2023-11-06', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-02-06', A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-E-1201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-03-01', A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-T-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-07-03', A_TOT: 19,    A_ACT: 4,    A_REM: 15,    A_PRO: 21,  B_TOT: 15,   B_ACT: 0,   B_REM: 15,   B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21402-U-4601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-03-16', A_TOT: 8,     A_ACT: 7,    A_REM: 1,     A_PRO: 87,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-17', SUBSYS_NO: '21403-E-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-02-27', A_TOT: 228,   A_ACT: 95,   A_REM: 133,   A_PRO: 41,  B_TOT: 21,   B_ACT: 0,   B_REM: 21,   B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-17', SUBSYS_NO: '21403-E-1301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-04-17', A_TOT: 9,     A_ACT: 0,    A_REM: 9,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-17', SUBSYS_NO: '21403-I-0301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', CSTD_PLAN: '2024-05-09', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 22,   B_ACT: 1,   B_REM: 21,   B_PRO: 4,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21501-I-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', CSTD_PLAN: '2024-04-09', A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21501-I-0204', CSTD_WALKDOWN: '2023-10-18', CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', CSTD_PLAN: '2024-03-11', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 1,    B_ACT: 1,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21501-I-0205', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-03-05', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 5,   B_REM: 3,    B_PRO: 62,  WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-E-0501', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-27', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-03-04', A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   B_TOT: 2,    B_ACT: 1,   B_REM: 1,    B_PRO: 50,  WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-E-0901', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-01-29', A_TOT: 3,     A_ACT: 3,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-E-1001', CSTD_WALKDOWN: '2023-09-08', CSTD_ACTUAL: '2023-10-08', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-02-29', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 4,    B_ACT: 4,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-E-1201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-02-14', A_TOT: 18,    A_ACT: 12,   A_REM: 6,     A_PRO: 66,  B_TOT: 28,   B_ACT: 8,   B_REM: 20,   B_PRO: 28,  WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-I-0101', CSTD_WALKDOWN: '2023-09-07', CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2023-10-04', A_TOT: 55,    A_ACT: 55,   A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-C-0101', CSTD_WALKDOWN: '2023-10-05', CSTD_ACTUAL: '2023-11-20', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-03-06', A_TOT: 10,    A_ACT: 7,    A_REM: 3,     A_PRO: 70,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-C-0401', CSTD_WALKDOWN: '2023-10-26', CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-02-21', A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   B_TOT: 6,    B_ACT: 4,   B_REM: 2,    B_PRO: 66,  WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-I-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-04-30', A_TOT: 157,   A_ACT: 8,    A_REM: 149,   A_PRO: 5,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '21401-C-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-06-21', A_TOT: 606,   A_ACT: 100,  A_REM: 506,   A_PRO: 16,  B_TOT: 182,  B_ACT: 42,  B_REM: 140,  B_PRO: 23,  WAYPOINT: 'WP-28', CSU_MS: 'MS-10', SUBSYS_NO: '21401-P-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-05-14', A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21401-U-4601', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-07-20', A_TOT: 6,     A_ACT: 0,    A_REM: 6,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '21402-C-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-05-15', A_TOT: 51,    A_ACT: 0,    A_REM: 51,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21402-E-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-04-17', A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21402-I-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-07-02', A_TOT: 412,   A_ACT: 16,   A_REM: 396,   A_PRO: 3,   B_TOT: 115,  B_ACT: 7,   B_REM: 108,  B_PRO: 6,   WAYPOINT: 'WP-28', CSU_MS: 'MS-10', SUBSYS_NO: '21402-P-0102', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-06-29', A_TOT: 89,    A_ACT: 18,   A_REM: 71,    A_PRO: 20,  B_TOT: 39,   B_ACT: 10,  B_REM: 29,   B_PRO: 25,  WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21402-P-0501', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-02-02', A_TOT: 28,    A_ACT: 18,   A_REM: 10,    A_PRO: 64,  B_TOT: 16,   B_ACT: 12,  B_REM: 4,    B_PRO: 75,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21402-U-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-06-29', A_TOT: 22,    A_ACT: 0,    A_REM: 22,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '21403-C-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-02-09', A_TOT: 95,    A_ACT: 56,   A_REM: 39,    A_PRO: 58,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-17', SUBSYS_NO: '21403-C-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-04-29', A_TOT: 25,    A_ACT: 17,   A_REM: 8,     A_PRO: 68,  B_TOT: 15,   B_ACT: 10,  B_REM: 5,    B_PRO: 66,  WAYPOINT: 'WP-19', CSU_MS: 'MS-17', SUBSYS_NO: '21403-U-2101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-05-31', A_TOT: 108,   A_ACT: 78,   A_REM: 30,    A_PRO: 72,  B_TOT: 48,   B_ACT: 31,  B_REM: 17,   B_PRO: 64,  WAYPOINT: 'WP-31', CSU_MS: 'MS-17', SUBSYS_NO: '21403-U-3201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-06-29', A_TOT: 27,    A_ACT: 22,   A_REM: 5,     A_PRO: 81,  B_TOT: 12,   B_ACT: 8,   B_REM: 4,    B_PRO: 66,  WAYPOINT: 'WP-31', CSU_MS: 'MS-17', SUBSYS_NO: '21403-U-3401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-05-23', A_TOT: 14,    A_ACT: 6,    A_REM: 8,     A_PRO: 42,  B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60,  WAYPOINT: 'WP-31', CSU_MS: 'MS-17', SUBSYS_NO: '21403-U-4101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', CSTD_PLAN: '2024-02-29', A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21501-C-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', CSTD_PLAN: '2024-03-28', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21501-I-0206', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', CSTD_PLAN: '2024-01-22', A_TOT: 8,     A_ACT: 8,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-3',  SUBSYS_NO: '21502-C-0101', CSTD_WALKDOWN: '2023-09-15', CSTD_ACTUAL: '2023-10-07', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', CSTD_PLAN: '2024-01-22', A_TOT: 2,     A_ACT: 1,    A_REM: 1,     A_PRO: 50,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  SUBSYS_NO: '21502-C-0701', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', CSTD_PLAN: '2023-11-03', A_TOT: 2,     A_ACT: 2,    A_REM: 0,     A_PRO: 100, B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  SUBSYS_NO: '21502-E-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', CSTD_PLAN: '2024-03-27', A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   B_TOT: 6,    B_ACT: 3,   B_REM: 3,    B_PRO: 50,  WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  SUBSYS_NO: '21502-H-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', CSTD_PLAN: '2024-04-16', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 15,   B_ACT: 5,   B_REM: 10,   B_PRO: 33,  WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  SUBSYS_NO: '21502-I-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-03-01', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 5,   B_REM: 3,    B_PRO: 62,  WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-E-0502', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-27', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-03-02', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-H-0101', CSTD_WALKDOWN: '2023-09-06', CSTD_ACTUAL: '2023-11-30', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-02-22', A_TOT: 71,    A_ACT: 71,   A_REM: 0,     A_PRO: 100, B_TOT: 10,   B_ACT: 10,  B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-E-0101', CSTD_WALKDOWN: '2023-10-25', CSTD_ACTUAL: '2023-11-07', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-02-22', A_TOT: 71,    A_ACT: 71,   A_REM: 0,     A_PRO: 100, B_TOT: 10,   B_ACT: 10,  B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-E-0102', CSTD_WALKDOWN: '2023-10-25', CSTD_ACTUAL: '2023-11-01', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-03-08', A_TOT: 61,    A_ACT: 32,   A_REM: 29,    A_PRO: 52,  B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-E-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-03-15', A_TOT: 46,    A_ACT: 2,    A_REM: 44,    A_PRO: 4,   B_TOT: 6,    B_ACT: 5,   B_REM: 1,    B_PRO: 83,  WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-E-0603', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-04-30', A_TOT: 24,    A_ACT: 9,    A_REM: 15,    A_PRO: 37,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-10', SUBSYS_NO: '21403-C-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-05-15', A_TOT: 7,     A_ACT: 1,    A_REM: 6,     A_PRO: 14,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-17', SUBSYS_NO: '21403-E-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-07-10', A_TOT: 469,   A_ACT: 264,  A_REM: 205,   A_PRO: 56,  B_TOT: 193,  B_ACT: 76,  B_REM: 117,  B_PRO: 39,  WAYPOINT: 'WP-31', CSU_MS: 'MS-17', SUBSYS_NO: '21403-P-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-02-02', A_TOT: 14,    A_ACT: 14,   A_REM: 0,     A_PRO: 100, B_TOT: 10,   B_ACT: 10,  B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-19', CSU_MS: 'MS-9',  SUBSYS_NO: '21403-U-1001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-06-21', A_TOT: 8,     A_ACT: 4,    A_REM: 4,     A_PRO: 50,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-19', CSU_MS: 'MS-17', SUBSYS_NO: '21403-U-2003', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-04-24', A_TOT: 30,    A_ACT: 12,   A_REM: 18,    A_PRO: 40,  B_TOT: 24,   B_ACT: 12,  B_REM: 12,   B_PRO: 50,  WAYPOINT: 'WP-19', CSU_MS: 'MS-8',  SUBSYS_NO: '21403-U-4301', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-05-07', A_TOT: 15,    A_ACT: 11,   A_REM: 4,     A_PRO: 73,  B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-31', CSU_MS: 'MS-17', SUBSYS_NO: '21403-U-6004', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', CSTD_PLAN: '2024-03-16', A_TOT: 10,    A_ACT: 10,   A_REM: 0,     A_PRO: 100, B_TOT: 5,    B_ACT: 5,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21501-E-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', CSTD_PLAN: '2024-03-23', A_TOT: 19,    A_ACT: 12,   A_REM: 7,     A_PRO: 63,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21501-T-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', CSTD_PLAN: '2024-03-01', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  SUBSYS_NO: '21502-I-0207', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-01-20', A_TOT: 99,    A_ACT: 99,   A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-C-0801', CSTD_WALKDOWN: '2023-09-25', CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-03-04', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 5,   B_REM: 3,    B_PRO: 62,  WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-E-0503', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-27', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-01-11', A_TOT: 73,    A_ACT: 73,   A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-C-0801', CSTD_WALKDOWN: '2023-10-13', CSTD_ACTUAL: '2023-11-20', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-03-16', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 5,   B_REM: 3,    B_PRO: 62,  WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-E-0502', CSTD_WALKDOWN: '2023-10-23', CSTD_ACTUAL: '2023-11-06', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-03-26', A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   B_TOT: 25,   B_ACT: 0,   B_REM: 25,   B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-I-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-07-02', A_TOT: 578,   A_ACT: 61,   A_REM: 517,   A_PRO: 10,  B_TOT: 231,  B_ACT: 28,  B_REM: 203,  B_PRO: 12,  WAYPOINT: 'WP-28', CSU_MS: 'MS-10', SUBSYS_NO: '21402-P-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-06-27', A_TOT: 17,    A_ACT: 6,    A_REM: 11,    A_PRO: 35,  B_TOT: 11,   B_ACT: 6,   B_REM: 5,    B_PRO: 54,  WAYPOINT: 'WP-28', CSU_MS: 'MS-10', SUBSYS_NO: '21402-P-0302', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-02-08', A_TOT: 15,    A_ACT: 6,    A_REM: 9,     A_PRO: 40,  B_TOT: 12,   B_ACT: 6,   B_REM: 6,    B_PRO: 50,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21402-U-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-06-25', A_TOT: 6,     A_ACT: 6,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21402-U-4001', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-07-10', A_TOT: 26,    A_ACT: 14,   A_REM: 12,    A_PRO: 53,  B_TOT: 20,   B_ACT: 12,  B_REM: 8,    B_PRO: 60,  WAYPOINT: 'WP-26', CSU_MS: 'MS-17', SUBSYS_NO: '21403-P-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-05-02', A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-31', CSU_MS: 'MS-17', SUBSYS_NO: '21403-U-3101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', CSTD_PLAN: '2024-04-02', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21501-I-0207', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', CSTD_PLAN: '2024-01-02', A_TOT: 49,    A_ACT: 2,    A_REM: 47,    A_PRO: 4,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  SUBSYS_NO: '21502-C-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', CSTD_PLAN: '2024-03-15', A_TOT: 10,    A_ACT: 10,   A_REM: 0,     A_PRO: 100, B_TOT: 5,    B_ACT: 5,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  SUBSYS_NO: '21502-E-0801', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', CSTD_PLAN: '2024-04-20', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  SUBSYS_NO: '21502-I-0204', CSTD_WALKDOWN: '2023-10-18', CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2023-09-22', A_TOT: 112,   A_ACT: 111,  A_REM: 1,     A_PRO: 99,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-C-0101', CSTD_WALKDOWN: '2023-09-15', CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2023-12-05', A_TOT: 2,     A_ACT: 2,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-C-0701', CSTD_WALKDOWN: '2023-09-08', CSTD_ACTUAL: '2023-11-30', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-03-04', A_TOT: 61,    A_ACT: 0,    A_REM: 61,    A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-E-0401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-02-20', A_TOT: 4,     A_ACT: 2,    A_REM: 2,     A_PRO: 50,  B_TOT: 7,    B_ACT: 5,   B_REM: 2,    B_PRO: 71,  WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-I-0201', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-03-15', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 7,    B_ACT: 6,   B_REM: 1,    B_PRO: 85,  WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-E-0402', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-02-21', A_TOT: 2,     A_ACT: 2,    A_REM: 0,     A_PRO: 100, B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-E-0701', CSTD_WALKDOWN: '2023-09-20', CSTD_ACTUAL: '2023-11-06', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-02-14', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-H-0101', CSTD_WALKDOWN: '2023-10-24', CSTD_ACTUAL: '2023-11-06', },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    CSTD_PLAN: '2024-02-07', A_TOT: 7,     A_ACT: 4,    A_REM: 3,     A_PRO: 57,  B_TOT: 6,    B_ACT: 4,   B_REM: 2,    B_PRO: 66,  WAYPOINT: 'WP-19', CSU_MS: 'MS-10', SUBSYS_NO: '21401-U-1101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-06-20', A_TOT: 133,   A_ACT: 19,   A_REM: 114,   A_PRO: 14,  B_TOT: 46,   B_ACT: 7,   B_REM: 39,   B_PRO: 15,  WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21402-P-0104', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    CSTD_PLAN: '2024-03-04', A_TOT: 41,    A_ACT: 0,    A_REM: 41,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'NA',    CSU_MS: 'MS-10', SUBSYS_NO: '21402-T-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       CSTD_PLAN: '2024-07-02', A_TOT: 45,    A_ACT: 40,   A_REM: 5,     A_PRO: 88,  B_TOT: 15,   B_ACT: 10,  B_REM: 5,    B_PRO: 66,  WAYPOINT: 'WP-31', CSU_MS: 'MS-27', SUBSYS_NO: '21403-P-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', CSTD_PLAN: '2023-12-20', A_TOT: 5,     A_ACT: 5,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-26', CSU_MS: 'MS-2',  SUBSYS_NO: '21501-C-0101', CSTD_WALKDOWN: '2023-10-20', CSTD_ACTUAL: '2023-11-20', },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', CSTD_PLAN: '2024-01-31', A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21501-C-0701', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', CSTD_PLAN: '2024-03-28', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 12,   B_ACT: 8,   B_REM: 4,    B_PRO: 66,  WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21501-I-0202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', CSTD_PLAN: '2024-02-23', A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 18,   B_ACT: 15,  B_REM: 3,    B_PRO: 83,  WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  SUBSYS_NO: '21502-I-0202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', CSTD_PLAN: '2024-02-28', A_TOT: 19,    A_ACT: 13,   A_REM: 6,     A_PRO: 68,  B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-3',  SUBSYS_NO: '21502-T-0101', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-01-29', A_TOT: 23,    A_ACT: 20,   A_REM: 3,     A_PRO: 87,  B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-C-5401', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-03-05', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 7,    B_ACT: 6,   B_REM: 1,    B_PRO: 85,  WAYPOINT: 'WP-10', CSU_MS: 'MS-3',  SUBSYS_NO: '21601-E-0402', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-03-04', A_TOT: 28,    A_ACT: 11,   A_REM: 17,    A_PRO: 39,  B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'NA',    CSU_MS: 'NA',    SUBSYS_NO: '21601-E-0602', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     CSTD_PLAN: '2024-03-04', A_TOT: 28,    A_ACT: 11,   A_REM: 17,    A_PRO: 39,  B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, WAYPOINT: 'NA',    CSU_MS: 'NA',    SUBSYS_NO: '21601-E-0603', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-01-03', A_TOT: 1,     A_ACT: 1,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-C-0501', CSTD_WALKDOWN: '2023-10-20', CSTD_ACTUAL: '2023-11-02', },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-02-20', A_TOT: 18,    A_ACT: 10,   A_REM: 8,     A_PRO: 55,  B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-C-5401', CSTD_WALKDOWN: '2023-10-26', CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-02-17', A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-E-0901', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     CSTD_PLAN: '2024-02-29', A_TOT: 5,     A_ACT: 0,    A_REM: 5,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   WAYPOINT: 'WP-15', CSU_MS: 'MS-2',  SUBSYS_NO: '21605-I-0202', CSTD_WALKDOWN: undefined,         CSTD_ACTUAL: undefined,        },

  ],

  Queries: {
    SQL1: [
      { CDATE: '2023-06-02', CREATED_WK: 0,     CLOSED_WK: 1006, CREATED_CUM: 0,      CLOSED_CUM: 13011, REMAIN_CUM: 100334, CLOSED_PRO: 11.5, },
      { CDATE: '2023-06-09', CREATED_WK: 0,     CLOSED_WK: 392,  CREATED_CUM: 0,      CLOSED_CUM: 13403, REMAIN_CUM: 99942,  CLOSED_PRO: 11.8, },
      { CDATE: '2023-06-16', CREATED_WK: 72338, CLOSED_WK: 464,  CREATED_CUM: 72338,  CLOSED_CUM: 13867, REMAIN_CUM: 99478,  CLOSED_PRO: 12.2, },
      { CDATE: '2023-06-23', CREATED_WK: 23329, CLOSED_WK: 491,  CREATED_CUM: 95667,  CLOSED_CUM: 14358, REMAIN_CUM: 98987,  CLOSED_PRO: 12.7, },
      { CDATE: '2023-06-30', CREATED_WK: 97,    CLOSED_WK: 483,  CREATED_CUM: 95764,  CLOSED_CUM: 14841, REMAIN_CUM: 98504,  CLOSED_PRO: 13.1, },
      { CDATE: '2023-07-07', CREATED_WK: 627,   CLOSED_WK: 451,  CREATED_CUM: 96391,  CLOSED_CUM: 15292, REMAIN_CUM: 98053,  CLOSED_PRO: 13.5, },
      { CDATE: '2023-07-14', CREATED_WK: 480,   CLOSED_WK: 519,  CREATED_CUM: 96871,  CLOSED_CUM: 15811, REMAIN_CUM: 97534,  CLOSED_PRO: 13.9, },
      { CDATE: '2023-07-21', CREATED_WK: 304,   CLOSED_WK: 545,  CREATED_CUM: 97175,  CLOSED_CUM: 16356, REMAIN_CUM: 96989,  CLOSED_PRO: 14.4, },
      { CDATE: '2023-07-28', CREATED_WK: 304,   CLOSED_WK: 284,  CREATED_CUM: 97479,  CLOSED_CUM: 16640, REMAIN_CUM: 96705,  CLOSED_PRO: 14.7, },
      { CDATE: '2023-08-04', CREATED_WK: 203,   CLOSED_WK: 412,  CREATED_CUM: 97682,  CLOSED_CUM: 17052, REMAIN_CUM: 96293,  CLOSED_PRO: 15, },
      { CDATE: '2023-08-11', CREATED_WK: 665,   CLOSED_WK: 589,  CREATED_CUM: 98347,  CLOSED_CUM: 17641, REMAIN_CUM: 95704,  CLOSED_PRO: 15.6, },
      { CDATE: '2023-08-18', CREATED_WK: 0,     CLOSED_WK: 427,  CREATED_CUM: 98347,  CLOSED_CUM: 18068, REMAIN_CUM: 95277,  CLOSED_PRO: 15.9, },
      { CDATE: '2023-08-25', CREATED_WK: 1485,  CLOSED_WK: 618,  CREATED_CUM: 99832,  CLOSED_CUM: 18686, REMAIN_CUM: 94659,  CLOSED_PRO: 16.5, },
      { CDATE: '2023-09-01', CREATED_WK: 488,   CLOSED_WK: 582,  CREATED_CUM: 100320, CLOSED_CUM: 19268, REMAIN_CUM: 94077,  CLOSED_PRO: 17, },
      { CDATE: '2023-09-08', CREATED_WK: 786,   CLOSED_WK: 544,  CREATED_CUM: 101106, CLOSED_CUM: 19812, REMAIN_CUM: 93533,  CLOSED_PRO: 17.5, },
      { CDATE: '2023-09-15', CREATED_WK: 760,   CLOSED_WK: 613,  CREATED_CUM: 101866, CLOSED_CUM: 20425, REMAIN_CUM: 92920,  CLOSED_PRO: 18, },
      { CDATE: '2023-09-22', CREATED_WK: 736,   CLOSED_WK: 595,  CREATED_CUM: 102602, CLOSED_CUM: 21020, REMAIN_CUM: 92325,  CLOSED_PRO: 18.5, },
      { CDATE: '2023-09-29', CREATED_WK: 477,   CLOSED_WK: 963,  CREATED_CUM: 103079, CLOSED_CUM: 21983, REMAIN_CUM: 91362,  CLOSED_PRO: 19.4, },
      { CDATE: '2023-10-06', CREATED_WK: 453,   CLOSED_WK: 1201, CREATED_CUM: 103532, CLOSED_CUM: 23184, REMAIN_CUM: 90161,  CLOSED_PRO: 20.5, },
      { CDATE: '2023-10-13', CREATED_WK: 586,   CLOSED_WK: 1526, CREATED_CUM: 104118, CLOSED_CUM: 24710, REMAIN_CUM: 88635,  CLOSED_PRO: 21.8, },
      { CDATE: '2023-10-20', CREATED_WK: 514,   CLOSED_WK: 911,  CREATED_CUM: 104632, CLOSED_CUM: 25621, REMAIN_CUM: 87724,  CLOSED_PRO: 22.6, },
      { CDATE: '2023-10-27', CREATED_WK: 558,   CLOSED_WK: 547,  CREATED_CUM: 105190, CLOSED_CUM: 26168, REMAIN_CUM: 87177,  CLOSED_PRO: 23.1, },
      { CDATE: '2023-11-03', CREATED_WK: 311,   CLOSED_WK: 535,  CREATED_CUM: 105501, CLOSED_CUM: 26703, REMAIN_CUM: 86642,  CLOSED_PRO: 23.6, },
      { CDATE: '2023-11-10', CREATED_WK: 516,   CLOSED_WK: 658,  CREATED_CUM: 106017, CLOSED_CUM: 27361, REMAIN_CUM: 85984,  CLOSED_PRO: 24.1, },
      { CDATE: '2023-11-17', CREATED_WK: 373,   CLOSED_WK: 605,  CREATED_CUM: 106390, CLOSED_CUM: 27966, REMAIN_CUM: 85379,  CLOSED_PRO: 24.7, },
      { CDATE: '2023-11-24', CREATED_WK: 387,   CLOSED_WK: 493,  CREATED_CUM: 106777, CLOSED_CUM: 28459, REMAIN_CUM: 84886,  CLOSED_PRO: 25.1, },
      { CDATE: '2023-12-01', CREATED_WK: 155,   CLOSED_WK: 496,  CREATED_CUM: 106932, CLOSED_CUM: 28955, REMAIN_CUM: 84390,  CLOSED_PRO: 25.5, },
      { CDATE: '2023-12-08', CREATED_WK: 2524,  CLOSED_WK: 735,  CREATED_CUM: 109456, CLOSED_CUM: 29690, REMAIN_CUM: 83655,  CLOSED_PRO: 26.2, },
      { CDATE: '2023-12-15', CREATED_WK: 3308,  CLOSED_WK: 718,  CREATED_CUM: 112764, CLOSED_CUM: 30408, REMAIN_CUM: 82937,  CLOSED_PRO: 26.8, },
      { CDATE: '2023-12-22', CREATED_WK: 581,   CLOSED_WK: 736,  CREATED_CUM: 113345, CLOSED_CUM: 31144, REMAIN_CUM: 82201,  CLOSED_PRO: 27.5, },
      { CDATE: '2023-12-29', CREATED_WK: null,  CLOSED_WK: null, CREATED_CUM: null,   CLOSED_CUM: null,  REMAIN_CUM: null,   CLOSED_PRO: 0, },
      { CDATE: '2024-01-05', CREATED_WK: null,  CLOSED_WK: null, CREATED_CUM: null,   CLOSED_CUM: null,  REMAIN_CUM: null,   CLOSED_PRO: 0, },
      { CDATE: '2024-01-12', CREATED_WK: null,  CLOSED_WK: null, CREATED_CUM: null,   CLOSED_CUM: null,  REMAIN_CUM: null,   CLOSED_PRO: 0, },
      { CDATE: '2024-01-19', CREATED_WK: null,  CLOSED_WK: null, CREATED_CUM: null,   CLOSED_CUM: null,  REMAIN_CUM: null,   CLOSED_PRO: 0, },
      { CDATE: '2024-01-26', CREATED_WK: null,  CLOSED_WK: null, CREATED_CUM: null,   CLOSED_CUM: null,  REMAIN_CUM: null,   CLOSED_PRO: 0, },
      { CDATE: '2024-02-02', CREATED_WK: null,  CLOSED_WK: null, CREATED_CUM: null,   CLOSED_CUM: null,  REMAIN_CUM: null,   CLOSED_PRO: 0, },
      { CDATE: '2024-02-09', CREATED_WK: null,  CLOSED_WK: null, CREATED_CUM: null,   CLOSED_CUM: null,  REMAIN_CUM: null,   CLOSED_PRO: 0, },
      { CDATE: '2024-02-16', CREATED_WK: null,  CLOSED_WK: null, CREATED_CUM: null,   CLOSED_CUM: null,  REMAIN_CUM: null,   CLOSED_PRO: 0, },
      { CDATE: '2024-02-23', CREATED_WK: null,  CLOSED_WK: null, CREATED_CUM: null,   CLOSED_CUM: null,  REMAIN_CUM: null,   CLOSED_PRO: 0, },
      { CDATE: '2024-03-01', CREATED_WK: null,  CLOSED_WK: null, CREATED_CUM: null,   CLOSED_CUM: null,  REMAIN_CUM: null,   CLOSED_PRO: 0, },
      { CDATE: '2024-03-08', CREATED_WK: null,  CLOSED_WK: null, CREATED_CUM: null,   CLOSED_CUM: null,  REMAIN_CUM: null,   CLOSED_PRO: 0, },
      { CDATE: '2024-03-15', CREATED_WK: null,  CLOSED_WK: null, CREATED_CUM: null,   CLOSED_CUM: null,  REMAIN_CUM: null,   CLOSED_PRO: 0, },
      { CDATE: '2024-03-22', CREATED_WK: null,  CLOSED_WK: null, CREATED_CUM: null,   CLOSED_CUM: null,  REMAIN_CUM: null,   CLOSED_PRO: 0, },
      { CDATE: '2024-03-29', CREATED_WK: null,  CLOSED_WK: null, CREATED_CUM: null,   CLOSED_CUM: null,  REMAIN_CUM: null,   CLOSED_PRO: 0, },
      { CDATE: '2024-04-05', CREATED_WK: null,  CLOSED_WK: null, CREATED_CUM: null,   CLOSED_CUM: null,  REMAIN_CUM: null,   CLOSED_PRO: 0, },
      { CDATE: '2024-04-12', CREATED_WK: null,  CLOSED_WK: null, CREATED_CUM: null,   CLOSED_CUM: null,  REMAIN_CUM: null,   CLOSED_PRO: 0, },
      { CDATE: '2024-04-19', CREATED_WK: null,  CLOSED_WK: null, CREATED_CUM: null,   CLOSED_CUM: null,  REMAIN_CUM: null,   CLOSED_PRO: 0, },
      { CDATE: '2024-04-26', CREATED_WK: null,  CLOSED_WK: null, CREATED_CUM: null,   CLOSED_CUM: null,  REMAIN_CUM: null,   CLOSED_PRO: 0, },


    ],
    
    // 12 Weeks Trends
    SQL2: [
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV',       CDATE: '2023-09-22', CREATED_WK: 751,  CLOSED_WK: 595, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV',       CDATE: '2023-09-29', CREATED_WK: 477,  CLOSED_WK: 965, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV',       CDATE: '2023-10-06', CREATED_WK: 462,  CLOSED_WK: 1202, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV',       CDATE: '2023-10-13', CREATED_WK: 595,  CLOSED_WK: 1529, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV',       CDATE: '2023-10-20', CREATED_WK: 553,  CLOSED_WK: 924, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV',       CDATE: '2023-10-27', CREATED_WK: 558,  CLOSED_WK: 549, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV',       CDATE: '2023-11-03', CREATED_WK: 312,  CLOSED_WK: 536, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV',       CDATE: '2023-11-10', CREATED_WK: 532,  CLOSED_WK: 659, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV',       CDATE: '2023-11-17', CREATED_WK: 377,  CLOSED_WK: 605, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV',       CDATE: '2023-11-24', CREATED_WK: 387,  CLOSED_WK: 488, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV',       CDATE: '2023-12-01', CREATED_WK: 156,  CLOSED_WK: 515, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV',       CDATE: '2023-12-08', CREATED_WK: 2550, CLOSED_WK: 741, },
    ],

    // Canvas Properties
    SQL3: [ 
      {
        CANVAS_WIDTH:           1300,
        CANVAS_HEIGHT:          850,
        
        PADDING_TOP:            290,
        PADDING_RIGHT:          90,
        PADDING_BOTTOM:         30,
        PADDING_LEFT:           90,

        CUTOFF:                 '2023-12-22', // <--- [P01_Project].[dbo].[GET_CUTOFF_DATE](),

        START_DATE:             '2023-03-17', 
        END_DATE:               '2025-03-28',
        WEEKEND:                'friday',

        TIMELINE_START:         '2023-07-07',
        PEAK_DURATION:          8,
      }
    ],

  },
  
}