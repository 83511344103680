
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 800,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 900,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    { PKG: 'PKG1', CODE: 'HO-1-01', PLAN: '2024-03-28', STATUS: 'NOTSTARTED', AREA: 'SSV',           DESC: 'Substation V',                                                 REMARK: 'Contractor will energize control circuits under temporary power for pre- commissioning scope. After turnover, Company will energize permanent feed. Can be scheduled earlier for TR precom scope', },
    { PKG: 'PKG1', CODE: 'HO-1-02', PLAN: '2024-04-11', STATUS: 'NOTSTARTED', AREA: 'SSW',           DESC: 'Substation W',                                                 REMARK: 'Contractor will energize control circuits under temporary power for pre- commissioning scope. After turnover, Company will energize permanent feed. Can be scheduled earlier for TR precom scope', },
    { PKG: 'PKG1', CODE: 'HO-1-03', PLAN: '2024-04-25', STATUS: 'NOTSTARTED', AREA: 'RIE2',          DESC: 'RIE2',                                                         REMARK: 'Contractor will energize control circuits under temporary power for pre- commissioning scope. After turnover, Company will energize permanent feed. Can be scheduled earlier for TR precom scope', },
    { PKG: 'PKG1', CODE: 'HO-1-04', PLAN: '2024-05-09', STATUS: 'NOTSTARTED', AREA: 'RIE1',          DESC: 'RIE1',                                                         REMARK: 'Contractor will energize control circuits under temporary power for pre- commissioning scope. After turnover, Company will energize permanent feed. Can be scheduled earlier for TR precom scope', },
    { PKG: 'PKG1', CODE: 'HO-1-05', PLAN: '2024-02-29', STATUS: 'NOTSTARTED', AREA: 'IA Dist',       DESC: 'Instrument Air / Plant Air Distribution',                      REMARK: 'Or earlier for TR precom scope', },
    { PKG: 'PKG1', CODE: 'HO-1-06', PLAN: '2024-05-30', STATUS: 'NOTSTARTED', AREA: 'N2',            DESC: 'Nitrogen',                                                     REMARK: '', },
    { PKG: 'PKG1', CODE: 'HO-1-07', PLAN: '2024-07-04', STATUS: 'NOTSTARTED', AREA: 'FW Main',       DESC: 'Firewater main',                                               REMARK: 'May be placed in service earlier for TR use (e.g., for Hydrotest water distribution)', },
    { PKG: 'PKG1', CODE: 'HO-1-08', PLAN: '2024-07-11', STATUS: 'NOTSTARTED', AREA: 'Newater',       DESC: 'NeWater',                                                      REMARK: 'Or earlier for TR precom scope (or hydrotest water supply) at contractors own risk of supply disruption', },
    { PKG: 'PKG1', CODE: 'HO-1-09', PLAN: '2024-07-11', STATUS: 'NOTSTARTED', AREA: 'Potable',       DESC: 'Potable Water',                                                REMARK: 'Potable water / eyewash stations to be ready prior to chemical filling onsite (e.g., for cooling water passivation)', },
    { PKG: 'PKG1', CODE: 'HO-1-10', PLAN: '2024-07-11', STATUS: 'NOTSTARTED', AREA: 'CT/CWS',        DESC: 'Cooling Tower / Cooling Water System',                         REMARK: 'Cooling water distribution throughout all units to be completed to allow flushing and passivation through exchangers', },
    { PKG: 'PKG1', CODE: 'HO-1-11', PLAN: '2024-07-11', STATUS: 'NOTSTARTED', AREA: 'Chem Inj',      DESC: 'Chemical Injection Packages',                                  REMARK: '', },
    { PKG: 'PKG1', CODE: 'HO-1-12', PLAN: '2024-07-18', STATUS: 'NOTSTARTED', AREA: 'SRU',           DESC: 'SRU',                                                          REMARK: '', },
    { PKG: 'PKG1', CODE: 'HO-1-13', PLAN: '2024-08-01', STATUS: 'NOTSTARTED', AREA: 'Closed Drain',  DESC: 'HC closed drain  (HSFO / SRU / Flare area)',                   REMARK: '', },
    { PKG: 'PKG1', CODE: 'HO-1-14', PLAN: '2024-08-01', STATUS: 'NOTSTARTED', AREA: 'NG/FG',         DESC: 'Natural Gas / Fuel Gas (HSFO / SRU / Flare area)',             REMARK: '', },
    { PKG: 'PKG1', CODE: 'HO-1-15', PLAN: '2024-08-01', STATUS: 'NOTSTARTED', AREA: 'Flare',         DESC: 'Flare (stack and associated equip) (HSFO / SRU / Flare area)', REMARK: '', },
    { PKG: 'PKG1', CODE: 'HO-1-16', PLAN: '2024-08-22', STATUS: 'NOTSTARTED', AREA: 'IA Comp',       DESC: 'Instrument Air Compressor',                                    REMARK: 'Contractor to provide temporary air compressors for pre-com & commissioning until permanent air compressor is operable (requiring cooling water in service)'},
    { PKG: 'PKG1', CODE: 'HO-1-17', PLAN: '2024-08-08', STATUS: 'NOTSTARTED', AREA: 'FW Deluge',     DESC: 'Firewater Deluge',                                             REMARK: 'Deluge testing to be completed prior to each scheduled Area Handover', },
    { PKG: 'PKG1', CODE: 'HO-1-18', PLAN: '2024-08-08', STATUS: 'NOTSTARTED', AREA: 'OWS/SW',        DESC: 'Oily Water Sewer / Storm Water',                               REMARK: 'Contractor to operate and dispose of construction storm water runoff until FACILITY MECHANICAL COMPLETION', },
    { PKG: 'PKG2', CODE: 'HO-2-01', PLAN: '2024-07-25', STATUS: 'NOTSTARTED', AREA: 'South Tanks',   DESC: 'S Tank Farm',                                                  REMARK: 'MCR tank is most critical. Other tanks may be later provided N2 demand is sufficient for purge prior to SU', },
    { PKG: 'PKG2', CODE: 'HO-2-02', PLAN: '2024-07-25', STATUS: 'NOTSTARTED', AREA: 'Solvent Tank',  DESC: 'Midcut Solvent Tank',                                          REMARK: 'MCR tank is most critical. Other tanks may be later provided N2 demand is sufficient for purge prior to SU', },
    { PKG: 'PKG2', CODE: 'HO-2-03', PLAN: '2024-08-15', STATUS: 'NOTSTARTED', AREA: 'CCFU Solids',   DESC: 'CCFU Solids Handling',                                         REMARK: 'To include systems necessary for introduction of TAR (feed lines, flare, drains, utilities)', },
    { PKG: 'PKG3', CODE: 'HO-3-01', PLAN: '2024-07-25', STATUS: 'NOTSTARTED', AREA: 'HOS',           DESC: 'HO System',                                                    REMARK: 'Hot Oil distribution throughout all units to be completed to allow circulation through exchangers', },
    { PKG: 'PKG3', CODE: 'HO-3-02', PLAN: '2024-07-18', STATUS: 'NOTSTARTED', AREA: 'Steam',         DESC: 'Steam',                                                        REMARK: 'Steam distribution throughout units to be completed to allow steam blowing', },
    { PKG: 'PKG3', CODE: 'HO-3-03', PLAN: '2024-08-29', STATUS: 'NOTSTARTED', AREA: 'SWS',           DESC: 'SWS',                                                          REMARK: '', },
    { PKG: 'PKG3', CODE: 'HO-3-04', PLAN: '2024-08-29', STATUS: 'NOTSTARTED', AREA: 'PFB',           DESC: 'PFB',                                                          REMARK: '', },
    { PKG: 'PKG3', CODE: 'HO-3-05', PLAN: '2024-08-08', STATUS: 'NOTSTARTED', AREA: 'DAU Feed Tank', DESC: 'DAU Feed Tank',                                                REMARK: 'DAU Feed Tank to be prioritized over other North Tanks', },
    { PKG: 'PKG3', CODE: 'HO-3-00', PLAN: '2024-08-08', STATUS: 'NOTSTARTED', AREA: 'North Tanks',   DESC: 'N Tank Farm',                                                  REMARK: 'Tank purging duration and sequence to be defined', },
    { PKG: 'PKG4', CODE: 'HO-4-02', PLAN: '2024-08-08', STATUS: 'NOTSTARTED', AREA: 'ARU',           DESC: 'ARU',                                                          REMARK: 'Earlier completion required to allow for amine circuit degreasing after turnover', },
    { PKG: 'PKG4', CODE: 'HO-4-03', PLAN: '2024-08-22', STATUS: 'NOTSTARTED', AREA: 'Amine (DHU)',   DESC: 'Amine circuit within DHU',                                     REMARK: 'Earlier completion required to allow for amine circuit degreasing after turnover', },
    { PKG: 'PKG4', CODE: 'HO-4-04', PLAN: '2024-08-29', STATUS: 'NOTSTARTED', AREA: 'Amine (CCFU)',  DESC: 'Amine circuit within CCFU',                                    REMARK: 'Earlier completion required to allow for amine circuit degreasing after turnover', },
    { PKG: 'PKG4', CODE: 'HO-4-05', PLAN: '2024-07-18', STATUS: 'NOTSTARTED', AREA: 'DHU Cat Load',  DESC: 'DHU Reactor Catalyst Loading',                                 REMARK: 'progressive turnover of reactor sub-systems for catalyst loading, including suitable laydown areas and access paving/roads', },
    { PKG: 'PKG4', CODE: 'HO-4-06', PLAN: '2024-10-24', STATUS: 'NOTSTARTED', AREA: 'DAU, DHU',      DESC: 'DAU / DHU systems',                                            REMARK: 'progressive turnover per commissioning priority for function testing, leak testing and run-ins', },
    { PKG: 'PKG4', CODE: 'HO-4-07', PLAN: '2024-08-29', STATUS: 'NOTSTARTED', AREA: 'DHU H2 Comp',   DESC: 'DHU H2 Make Up Compressors',                                   REMARK: 'Early completion required to allow for air run-in', },
    { PKG: 'PKG4', CODE: 'HO-4-08', PLAN: '2024-09-26', STATUS: 'NOTSTARTED', AREA: 'FG Comp',       DESC: 'Fuel Gas Compressor',                                          REMARK: 'Early completion required to allow for air run-in', },
    { PKG: 'PKG5', CODE: 'HO-5-01', PLAN: '2024-09-12', STATUS: 'NOTSTARTED', AREA: 'CCFU Cat Load', DESC: 'CCFU Reactor Catalyst Loading',                                REMARK: 'progressive turnover of reactor sub- systems for catalyst loading, including suitable laydown areas and access paving/roads', },
    { PKG: 'PKG5', CODE: 'HO-5-02', PLAN: '2024-10-31', STATUS: 'NOTSTARTED', AREA: 'CCFU',          DESC: 'CCFU systems',                                                 REMARK: 'progressive turnover per commissioning priority for function testing, leak testing and run-ins', },
    { PKG: 'PKG5', CODE: 'HO-5-03', PLAN: '2024-10-10', STATUS: 'NOTSTARTED', AREA: 'CCFU RG Comp',  DESC: 'CCFU Recycle Gas Compressors',                                 REMARK: 'Priority should be given to DHU/DAUahead of CCFU. Compressors required early for air runs', },
    { PKG: 'PKG5', CODE: 'HO-5-04', PLAN: '2024-10-17', STATUS: 'NOTSTARTED', AREA: 'CCFU OG Comp',  DESC: 'CCFU Offgas Compressors',                                      REMARK: '', },

  ],

  Queries: {

    SQL1: [ // Timeline
      {
        X:                      40,
        Y:                      700,
        LENGTH:                 1200,
        CUTOFF:                 '2023-12-01',
        START:                  '2023-11-15', 
        END:                    '2024-12-15',
        REF_DATE:               'PLAN', // <---
        FONT:                   'roboto',

        MONTH_HEIGHT:           20,
        MONTH_LINE:             15,
        MONTH_STROKE_WIDTH:     0.5,
        MONTH_STROKE_COLOR:     '#BCBCBC',
        MONTH_TEXT_SIZE:        10,
        MONTH_TEXT_COLOR:       '#333',

        YEAR_HEIGHT:            16,
        YEAR_LINE:              20,
        YEAR_STROKE_WIDTH:      0.5,
        YEAR_STROKE_COLOR:      '#BCBCBC',
        YEAR_TEXT_SIZE:         11,
        YEAR_TEXT_COLOR:        '#000',
      }
    ],


    // Skyline
    SQL2: [ 
      {
        TITLE_MAIN:             'Skyline Chart ',
        TITLE_SUB:              'for Contractor Handover Milestone',
        REF_COLUMN:             'AREA', // <---

        BOX_FONT:               'roboto',
        BOX_HEIGHT:             20,
        BOX_GAP:                2, 
        BOX_RADIUS:             2,
        BOX_TEXT_SIZE:          10.5,
        BOX_HOVER_SCALE:        1.4,

        // Legends 
        LEGEND_VISIBLE:         'Y',
        LEGEND_X:               45,
        LEGEND_Y:               65,  

        // Search
        SEARCH_X:               45,
        SEARCH_Y:               150.5,
        SEARCH_VISIBLE:         'Y',
        SEARCH_NOTE:            'Enter the Milestone number',
      }
    ],


    // Checksheet
    SQL3: [ 
      {
        REF_STATUS:               'STATUS', // <---
 
        // CHeck Sheet
        SHEET_TITLE:              ' Total   / Not Completed / Handover  ',
        SHEET_STATUS:             ' TOTAL   / NOTSTARTED    / HANDOVER  ',
        SHEET_TYPE:               '         /               /           ',
        SHEET_COLOR:              ' #fff    / #fff          / #82B22E   ',
        SHEET_FONT_WEIGHT:        ' regular / regular       / regular   ',
        SHEET_FONT_COLOR:         ' #000    / #000          / #000      ',
        SHEET_STROKE_WIDTH:       ' 0.5     / 0.5           / 0.5       ',
        SHEET_STROKE_COLOR:       ' #757575 / #757575       / #757575   ',
        SHEET_OPACITY:            ' 1       / 1             / 1         ',
      }
    ],


    // Detail infomation Box
    SQL4: [ 
      {
        DETAIL_VISIBLE:           'Y',
        DETAIL_ONOFF:             'ON',
        DETAIL_X:                 45,
        DETAIL_Y:                 240,
        DETAIL_WIDTH:             300,
        DETAIL_TEXT_SIZE:         10,
        DETAIL_LINEHEIGHT:        18,
        
        DETAIL_CODE:              ' PLAN     / DESC         / REMARK  ',
        DETAIL_NAME:              ' Plan:    / Description: / Remark: ',
        DETAIL_TYPE:              ' TEXT     / TRIM         / MULTI   ',
        DETAIL_VALUE_COLOR:       ' #757575  / #757575      / #83D2F5 ',
        DETAIL_BAR_COLOR:         ' gray     / gray         / #83D2F5 ',
        DETAIL_TITLE_WIDTH:       90,
        DETAIL_TITLE_COLOR:       'gray',
      }
    ],

    SQL55: [
      { CODE: '1000', MAP_ID: 'M00GECP21000001000', ASSIGN: '',  TO_AREA: 'SSV',              DESC: 'Substation V',                                                      MS_DATE: '2024-03-28', },
      { CODE: '1010', MAP_ID: 'M00GECP21000001010', ASSIGN: '',  TO_AREA: 'SSW',              DESC: 'Substation W',                                                      MS_DATE: '2024-04-11', },
      { CODE: '1020', MAP_ID: 'M00GECP21000001020', ASSIGN: '',  TO_AREA: 'RIE2',             DESC: 'RIE2',                                                              MS_DATE: '2024-04-25', },
      { CODE: '1030', MAP_ID: 'M00GECP21000001030', ASSIGN: '',  TO_AREA: 'RIE1',             DESC: 'RIE1',                                                              MS_DATE: '2024-05-09', },
      { CODE: '1040', MAP_ID: 'M00GECP21000001040', ASSIGN: '',  TO_AREA: 'IA Dist',          DESC: 'Instrument Air / Plant Air Distribution',                           MS_DATE: '2024-02-29', },
      { CODE: '1050', MAP_ID: 'M00GECP21000001050', ASSIGN: '',  TO_AREA: 'N2',               DESC: 'Nitrogen',                                                          MS_DATE: '2024-05-30', },
      { CODE: '1060', MAP_ID: 'M00GECP21000001060', ASSIGN: '',  TO_AREA: 'FW Main',          DESC: 'Firewater main',                                                    MS_DATE: '2024-07-04', },
      { CODE: '1070', MAP_ID: 'M00GECP21000001070', ASSIGN: '',  TO_AREA: 'Newater',          DESC: 'NeWater',                                                           MS_DATE: '2024-07-11', },
      { CODE: '1080', MAP_ID: 'M00GECP21000001080', ASSIGN: '',  TO_AREA: 'Potable',          DESC: 'Potable Water',                                                     MS_DATE: '2024-07-11', },
      { CODE: '1090', MAP_ID: 'M00GECP21000001090', ASSIGN: '',  TO_AREA: 'CT',               DESC: 'Cooling Tower',                                                     MS_DATE: '2024-07-11', },
      { CODE: '1090', MAP_ID: 'M00GECP21000001400', ASSIGN: '',  TO_AREA: 'CWS',              DESC: 'Cooling Water System',                                              MS_DATE: '2024-07-11', },
      { CODE: '1100', MAP_ID: 'M00GECP21000001100', ASSIGN: '',  TO_AREA: 'Chem Inj',         DESC: 'Chemical Injection Packages',                                       MS_DATE: '2024-07-11', },
      { CODE: '1110', MAP_ID: 'M00GECP21000001110', ASSIGN: '',  TO_AREA: 'SRU',              DESC: 'SRU',                                                               MS_DATE: '2024-07-18', },
      { CODE: '1120', MAP_ID: 'M00GECP21000001120', ASSIGN: '',  TO_AREA: 'Closed Drain',     DESC: 'HC closed drain  (HSFO / SRU / Flare area)',                        MS_DATE: '2024-08-01', },
      { CODE: '1130', MAP_ID: 'M00GECP21000001130', ASSIGN: '',  TO_AREA: 'NG/FG',            DESC: 'Natural Gas / Fuel Gas (HSFO / SRU / Flare area)',                  MS_DATE: '2024-08-01', },
      { CODE: '1140', MAP_ID: 'M00GECP21000001140', ASSIGN: '',  TO_AREA: 'Flare',            DESC: 'Flare (stack and associated equip) (HSFO / SRU / Flare area)',      MS_DATE: '2024-08-01', },
      { CODE: '1150', MAP_ID: 'M00GECP21000001150', ASSIGN: '',  TO_AREA: 'IA Comp',          DESC: 'Instrument Air Compressor',                                         MS_DATE: '2024-08-22', },
      { CODE: '1160', MAP_ID: 'M00GECP21000001160', ASSIGN: '',  TO_AREA: 'FW Deluge',        DESC: 'Firewater Deluge',                                                  MS_DATE: '2024-08-08', },
      { CODE: '1170', MAP_ID: 'M00GECP21000001170', ASSIGN: '',  TO_AREA: 'OWS/SW',           DESC: 'Oily Water Sewer / Storm Water',                                    MS_DATE: '2024-08-08', },
      { CODE: '1180', MAP_ID: 'M00GECP21000001180', ASSIGN: '',  TO_AREA: 'South Tanks',      DESC: 'S Tank Farm',                                                       MS_DATE: '2024-07-25', },
      { CODE: '1190', MAP_ID: 'M00GECP21000001190', ASSIGN: '',  TO_AREA: 'Solvent Tank',     DESC: 'Midcut Solvent Tank',                                               MS_DATE: '2024-07-25', },
      { CODE: '1200', MAP_ID: 'M00GECP21000001200', ASSIGN: '',  TO_AREA: 'CCFU Solids',      DESC: 'CCFU Solids Handling',                                              MS_DATE: '2024-08-15', },
      { CODE: '1210', MAP_ID: 'M00GECP21000001210', ASSIGN: '',  TO_AREA: 'HO System',        DESC: 'HO System',                                                         MS_DATE: '2024-07-25', },
      { CODE: '1390', MAP_ID: 'M00GECP21000001390', ASSIGN: '',  TO_AREA: 'HOS(Finish)',      DESC: 'HOS(Finish)',                                                       MS_DATE: '2024-07-25', },
      { CODE: '1220', MAP_ID: 'M00GECP21000001220', ASSIGN: '',  TO_AREA: 'Steam',            DESC: 'Steam',                                                             MS_DATE: '2024-07-18', },
      { CODE: '1230', MAP_ID: 'M00GECP21000001230', ASSIGN: '',  TO_AREA: 'SWS',              DESC: 'SWS',                                                               MS_DATE: '2024-08-29', },
      { CODE: '1240', MAP_ID: 'M00GECP21000001240', ASSIGN: '',  TO_AREA: 'PFB',              DESC: 'PFB',                                                               MS_DATE: '2024-08-29', },
      { CODE: '1250', MAP_ID: 'M00GECP21000001250', ASSIGN: '',  TO_AREA: 'DAU Feed Tank',    DESC: 'DAU Feed Tank',                                                     MS_DATE: '2024-08-08', },
      { CODE: '1260', MAP_ID: 'M00GECP21000001260', ASSIGN: '',  TO_AREA: 'North Tanks',      DESC: 'N Tank Farm',                                                       MS_DATE: '2024-08-08', },
      { CODE: '1270', MAP_ID: 'M00GECP21000001270', ASSIGN: '',  TO_AREA: 'ARU',              DESC: 'ARU',                                                               MS_DATE: '2024-08-08', },
      { CODE: '1280', MAP_ID: 'M00GECP21000001280', ASSIGN: '',  TO_AREA: 'Amine (DHU)',      DESC: 'Amine circuit within DHU',                                          MS_DATE: '2024-08-22', },
      { CODE: '1290', MAP_ID: 'M00GECP21000001290', ASSIGN: '',  TO_AREA: 'Amine (CCFU)',     DESC: 'Amine circuit within CCFU',                                         MS_DATE: '2024-08-29', },
      { CODE: '1305', MAP_ID: 'M00GECP21000001305', ASSIGN: '',  TO_AREA: 'DHU Cat Load(F)',  DESC: 'DHU Reactor Catalyst Loading (First - Ready for Catayst Loading)',  MS_DATE: '2024-05-23', },
      { CODE: '1306', MAP_ID: 'Not using yet',      ASSIGN: 'N', TO_AREA: 'DHU Cat Load(L)',  DESC: 'DHU Reactor Catalyst Loading (Last)',                               MS_DATE: '2024-07-18', },
      { CODE: '1309', MAP_ID: 'Not using yet',      ASSIGN: 'N', TO_AREA: 'DAU, DHU(F)',      DESC: 'DAU / DHU systems (First)',                                         MS_DATE: '2024-08-29', },
      { CODE: '1310', MAP_ID: 'M00GECP21000001310', ASSIGN: '',  TO_AREA: 'DAU, DHU(L)',      DESC: 'DAU / DHU systems (Last)',                                          MS_DATE: '2024-10-24', },
      { CODE: '1320', MAP_ID: 'M00GECP21000001320', ASSIGN: '',  TO_AREA: 'DHU H2 Comp',      DESC: 'DHU H2 Make Up Compressors',                                        MS_DATE: '2024-08-29', },
      { CODE: '1330', MAP_ID: 'M00GECP21000001330', ASSIGN: '',  TO_AREA: 'FG Comp',          DESC: 'Fuel Gas Compressor',                                               MS_DATE: '2024-09-26', },
      { CODE: '1345', MAP_ID: 'M00GECP21000001345', ASSIGN: '',  TO_AREA: 'CCFU Cat Load(F)', DESC: 'CCFU Reactor Catalyst Loading (First - Ready for Catayst Loading)', MS_DATE: '2024-08-22', },
      { CODE: '1340', MAP_ID: 'M00GECP21000001340', ASSIGN: '',  TO_AREA: 'CCFU Cat Load(L)', DESC: 'CCFU Reactor Catalyst Loading (Last - Ready for Catayst Loading)',  MS_DATE: '2024-09-12', },
      { CODE: '1349', MAP_ID: 'Not using yet',      ASSIGN: 'N', TO_AREA: 'CCFU(F)',          DESC: 'CCFU systems (First)',                                              MS_DATE: '2024-09-12', },
      { CODE: '1350', MAP_ID: 'M00GECP21000001350', ASSIGN: '',  TO_AREA: 'CCFU(L)',          DESC: 'CCFU systems (Last)',                                               MS_DATE: '2024-10-31', },
      { CODE: '1360', MAP_ID: 'M00GECP21000001360', ASSIGN: '',  TO_AREA: 'CCFU RG Comp',     DESC: 'CCFU Recycle Gas Compressors',                                      MS_DATE: '2024-10-10', },
      { CODE: '1370', MAP_ID: 'M00GECP21000001370', ASSIGN: '',  TO_AREA: 'CCFU OG Comp',     DESC: 'CCFU Offgas Compressors',                                           MS_DATE: '2024-10-17', },

    ]
  }
}