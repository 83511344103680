
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 900,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 900,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    { ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-07', CREATED_WK: 97,   CLOSED_WK: 0,    CREATED_CUM: 1516,  CLOSED_CUM: 0,    REMAIN_CUM: 1558,  TOTAL: 1558,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 1557,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-14', CREATED_WK: 3,    CLOSED_WK: 0,    CREATED_CUM: 1519,  CLOSED_CUM: 0,    REMAIN_CUM: 1558,  TOTAL: 1558,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 1557,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-21', CREATED_WK: 1,    CLOSED_WK: 5,    CREATED_CUM: 1520,  CLOSED_CUM: 5,    REMAIN_CUM: 1553,  TOTAL: 1558,  PL_CLOSED_WK: 5,    PL_CLOSED_CUM: 5,     PL_REMAIN_CUM: 1552,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-28', CREATED_WK: 14,   CLOSED_WK: 0,    CREATED_CUM: 1534,  CLOSED_CUM: 5,    REMAIN_CUM: 1553,  TOTAL: 1558,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 5,     PL_REMAIN_CUM: 1552,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 7,    CREATED_CUM: 1534,  CLOSED_CUM: 12,   REMAIN_CUM: 1546,  TOTAL: 1558,  PL_CLOSED_WK: 7,    PL_CLOSED_CUM: 12,    PL_REMAIN_CUM: 1545,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1534,  CLOSED_CUM: 12,   REMAIN_CUM: 1546,  TOTAL: 1558,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 12,    PL_REMAIN_CUM: 1545,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1534,  CLOSED_CUM: 12,   REMAIN_CUM: 1546,  TOTAL: 1558,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 12,    PL_REMAIN_CUM: 1545,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-25', CREATED_WK: 2,    CLOSED_WK: 10,   CREATED_CUM: 1536,  CLOSED_CUM: 22,   REMAIN_CUM: 1536,  TOTAL: 1558,  PL_CLOSED_WK: 10,   PL_CLOSED_CUM: 22,    PL_REMAIN_CUM: 1535,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-01', CREATED_WK: 0,    CLOSED_WK: 3,    CREATED_CUM: 1536,  CLOSED_CUM: 25,   REMAIN_CUM: 1533,  TOTAL: 1558,  PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 25,    PL_REMAIN_CUM: 1532,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-08', CREATED_WK: 8,    CLOSED_WK: 4,    CREATED_CUM: 1544,  CLOSED_CUM: 29,   REMAIN_CUM: 1529,  TOTAL: 1558,  PL_CLOSED_WK: 4,    PL_CLOSED_CUM: 29,    PL_REMAIN_CUM: 1528,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-15', CREATED_WK: 1,    CLOSED_WK: 6,    CREATED_CUM: 1545,  CLOSED_CUM: 35,   REMAIN_CUM: 1523,  TOTAL: 1558,  PL_CLOSED_WK: 6,    PL_CLOSED_CUM: 35,    PL_REMAIN_CUM: 1522,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-22', CREATED_WK: 0,    CLOSED_WK: 15,   CREATED_CUM: 1545,  CLOSED_CUM: 50,   REMAIN_CUM: 1508,  TOTAL: 1558,  PL_CLOSED_WK: 15,   PL_CLOSED_CUM: 50,    PL_REMAIN_CUM: 1507,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-29', CREATED_WK: 1,    CLOSED_WK: 12,   CREATED_CUM: 1546,  CLOSED_CUM: 62,   REMAIN_CUM: 1496,  TOTAL: 1558,  PL_CLOSED_WK: 12,   PL_CLOSED_CUM: 62,    PL_REMAIN_CUM: 1495,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 9,    CREATED_CUM: 1546,  CLOSED_CUM: 71,   REMAIN_CUM: 1487,  TOTAL: 1558,  PL_CLOSED_WK: 9,    PL_CLOSED_CUM: 71,    PL_REMAIN_CUM: 1486,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-13', CREATED_WK: 0,    CLOSED_WK: 16,   CREATED_CUM: 1546,  CLOSED_CUM: 87,   REMAIN_CUM: 1471,  TOTAL: 1558,  PL_CLOSED_WK: 16,   PL_CLOSED_CUM: 87,    PL_REMAIN_CUM: 1470,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-20', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1546,  CLOSED_CUM: 87,   REMAIN_CUM: 1471,  TOTAL: 1558,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 87,    PL_REMAIN_CUM: 1470,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-27', CREATED_WK: 0,    CLOSED_WK: 16,   CREATED_CUM: 1546,  CLOSED_CUM: 103,  REMAIN_CUM: 1455,  TOTAL: 1558,  PL_CLOSED_WK: 16,   PL_CLOSED_CUM: 103,   PL_REMAIN_CUM: 1454,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-03', CREATED_WK: 2,    CLOSED_WK: 30,   CREATED_CUM: 1548,  CLOSED_CUM: 133,  REMAIN_CUM: 1425,  TOTAL: 1558,  PL_CLOSED_WK: 30,   PL_CLOSED_CUM: 133,   PL_REMAIN_CUM: 1424,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-10', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1548,  CLOSED_CUM: 133,  REMAIN_CUM: 1425,  TOTAL: 1558,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 133,   PL_REMAIN_CUM: 1424,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 12,   CREATED_CUM: 1548,  CLOSED_CUM: 145,  REMAIN_CUM: 1413,  TOTAL: 1558,  PL_CLOSED_WK: 12,   PL_CLOSED_CUM: 145,   PL_REMAIN_CUM: 1412,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-24', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 1548,  CLOSED_CUM: 147,  REMAIN_CUM: 1411,  TOTAL: 1558,  PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 147,   PL_REMAIN_CUM: 1410,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-01', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 1548,  CLOSED_CUM: 149,  REMAIN_CUM: 1409,  TOTAL: 1558,  PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 149,   PL_REMAIN_CUM: 1408,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1548,  CLOSED_CUM: 149,  REMAIN_CUM: 1409,  TOTAL: 1558,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 149,   PL_REMAIN_CUM: 1408,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-15', CREATED_WK: 3,    CLOSED_WK: 22,   CREATED_CUM: 1551,  CLOSED_CUM: 171,  REMAIN_CUM: 1387,  TOTAL: 1558,  PL_CLOSED_WK: 22,   PL_CLOSED_CUM: 171,   PL_REMAIN_CUM: 1386,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-22', CREATED_WK: 3,    CLOSED_WK: 3,    CREATED_CUM: 1554,  CLOSED_CUM: 174,  REMAIN_CUM: 1384,  TOTAL: 1558,  PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 174,   PL_REMAIN_CUM: 1383,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 1,    CREATED_CUM: 1554,  CLOSED_CUM: 175,  REMAIN_CUM: 1383,  TOTAL: 1558,  PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 175,   PL_REMAIN_CUM: 1382,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-05', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1554,  CLOSED_CUM: 175,  REMAIN_CUM: 1383,  TOTAL: 1558,  PL_CLOSED_WK: 23,   PL_CLOSED_CUM: 198,   PL_REMAIN_CUM: 1359,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-12', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1554,  CLOSED_CUM: 175,  REMAIN_CUM: 1383,  TOTAL: 1558,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 198,   PL_REMAIN_CUM: 1359,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-19', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1554,  CLOSED_CUM: 175,  REMAIN_CUM: 1383,  TOTAL: 1558,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 198,   PL_REMAIN_CUM: 1359,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 10,   CREATED_CUM: 1554,  CLOSED_CUM: 185,  REMAIN_CUM: 1373,  TOTAL: 1558,  PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 199,   PL_REMAIN_CUM: 1358,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-02', CREATED_WK: 4,    CLOSED_WK: 0,    CREATED_CUM: 1558,  CLOSED_CUM: 185,  REMAIN_CUM: 1373,  TOTAL: 1558,  PL_CLOSED_WK: 5,    PL_CLOSED_CUM: 204,   PL_REMAIN_CUM: 1353,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-09', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1558,  CLOSED_CUM: 185,  REMAIN_CUM: 1373,  TOTAL: 1558,  PL_CLOSED_WK: 10,   PL_CLOSED_CUM: 215,   PL_REMAIN_CUM: 1342,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-16', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1558,  CLOSED_CUM: 185,  REMAIN_CUM: 1373,  TOTAL: 1558,  PL_CLOSED_WK: 15,   PL_CLOSED_CUM: 230,   PL_REMAIN_CUM: 1327,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 29,   PL_CLOSED_CUM: 260,   PL_REMAIN_CUM: 1297,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 21,   PL_CLOSED_CUM: 282,   PL_REMAIN_CUM: 1275,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 6,    PL_CLOSED_CUM: 288,   PL_REMAIN_CUM: 1269,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 4,    PL_CLOSED_CUM: 293,   PL_REMAIN_CUM: 1264,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-22', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 5,    PL_CLOSED_CUM: 298,   PL_REMAIN_CUM: 1259,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-29', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 302,   PL_REMAIN_CUM: 1255,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 13,   PL_CLOSED_CUM: 315,   PL_REMAIN_CUM: 1242,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 21,   PL_CLOSED_CUM: 337,   PL_REMAIN_CUM: 1220,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 25,   PL_CLOSED_CUM: 363,   PL_REMAIN_CUM: 1194,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 45,   PL_CLOSED_CUM: 408,   PL_REMAIN_CUM: 1149,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-03', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 36,   PL_CLOSED_CUM: 444,   PL_REMAIN_CUM: 1112,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-10', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 60,   PL_CLOSED_CUM: 505,   PL_REMAIN_CUM: 1051,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-17', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 38,   PL_CLOSED_CUM: 544,   PL_REMAIN_CUM: 1013,  PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-24', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 27,   PL_CLOSED_CUM: 572,   PL_REMAIN_CUM: 985,   PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-31', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 119,  PL_CLOSED_CUM: 692,   PL_REMAIN_CUM: 865,   PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-07', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 128,  PL_CLOSED_CUM: 820,   PL_REMAIN_CUM: 737,   PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-14', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 93,   PL_CLOSED_CUM: 914,   PL_REMAIN_CUM: 643,   PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-21', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 68,   PL_CLOSED_CUM: 982,   PL_REMAIN_CUM: 575,   PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-28', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 75,   PL_CLOSED_CUM: 1058,  PL_REMAIN_CUM: 499,   PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 52,   PL_CLOSED_CUM: 1110,  PL_REMAIN_CUM: 447,   PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 23,   PL_CLOSED_CUM: 1133,  PL_REMAIN_CUM: 423,   PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 74,   PL_CLOSED_CUM: 1208,  PL_REMAIN_CUM: 349,   PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 96,   PL_CLOSED_CUM: 1305,  PL_REMAIN_CUM: 252,   PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-02', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 127,  PL_CLOSED_CUM: 1432,  PL_REMAIN_CUM: 125,   PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-09', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 59,   PL_CLOSED_CUM: 1492,  PL_REMAIN_CUM: 65,    PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-16', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 18,   PL_CLOSED_CUM: 1510,  PL_REMAIN_CUM: 47,    PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 1513,  PL_REMAIN_CUM: 44,    PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-30', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 34,   PL_CLOSED_CUM: 1547,  PL_REMAIN_CUM: 10,    PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-06', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 1549,  PL_REMAIN_CUM: 7,     PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-13', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 1552,  PL_REMAIN_CUM: 5,     PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-20', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 1555,  PL_REMAIN_CUM: 1,     PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-27', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 1555,  PL_REMAIN_CUM: 1,     PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-04', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 1555,  PL_REMAIN_CUM: 1,     PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-11', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 1555,  PL_REMAIN_CUM: 1,     PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-18', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 1555,  PL_REMAIN_CUM: 1,     PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-25', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 1555,  PL_REMAIN_CUM: 1,     PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-11-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 1555,  PL_REMAIN_CUM: 1,     PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-11-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 1557,  PL_REMAIN_CUM: 0,     PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-11-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1558,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 1557,  PL_REMAIN_CUM: 0,     PL_TOTAL: 1557, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-07', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-14', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-21', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-28', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-25', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-01', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-08', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-15', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-22', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-29', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-13', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-20', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-27', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-03', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-10', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-24', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-01', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-15', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-22', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 39,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-05', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 2,     PL_REMAIN_CUM: 37,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-12', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 2,     PL_REMAIN_CUM: 37,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-19', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 2,     PL_REMAIN_CUM: 37,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 2,     PL_REMAIN_CUM: 37,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-02', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 2,     PL_REMAIN_CUM: 36,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-09', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 3,     PL_REMAIN_CUM: 36,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-16', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,    CLOSED_CUM: 0,    REMAIN_CUM: 39,    TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 3,     PL_REMAIN_CUM: 36,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 3,     PL_REMAIN_CUM: 36,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 4,     PL_REMAIN_CUM: 34,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 7,     PL_REMAIN_CUM: 32,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 7,     PL_REMAIN_CUM: 32,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-22', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 14,   PL_CLOSED_CUM: 21,    PL_REMAIN_CUM: 17,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-29', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 4,    PL_CLOSED_CUM: 26,    PL_REMAIN_CUM: 12,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 28,    PL_REMAIN_CUM: 10,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 29,    PL_REMAIN_CUM: 10,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 29,    PL_REMAIN_CUM: 10,    PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 29,    PL_REMAIN_CUM: 9,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-03', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 31,    PL_REMAIN_CUM: 7,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-10', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 33,    PL_REMAIN_CUM: 5,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-17', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 34,    PL_REMAIN_CUM: 4,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-24', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 35,    PL_REMAIN_CUM: 3,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-31', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 36,    PL_REMAIN_CUM: 2,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-07', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 36,    PL_REMAIN_CUM: 2,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-14', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 37,    PL_REMAIN_CUM: 1,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-21', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 38,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-28', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-02', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-09', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-16', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-30', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-06', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-13', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-20', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-27', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-04', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-11', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-18', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-25', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-11-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-11-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-11-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 39,    PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 0,     PL_TOTAL: 39, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-07', CREATED_WK: 6,    CLOSED_WK: 0,    CREATED_CUM: 11360, CLOSED_CUM: 0,    REMAIN_CUM: 11484, TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 11484, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-14', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11360, CLOSED_CUM: 0,    REMAIN_CUM: 11484, TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 11484, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-21', CREATED_WK: 7,    CLOSED_WK: 0,    CREATED_CUM: 11367, CLOSED_CUM: 0,    REMAIN_CUM: 11484, TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 11484, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-28', CREATED_WK: 1,    CLOSED_WK: 0,    CREATED_CUM: 11368, CLOSED_CUM: 0,    REMAIN_CUM: 11484, TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 11484, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11368, CLOSED_CUM: 0,    REMAIN_CUM: 11484, TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 11484, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11368, CLOSED_CUM: 0,    REMAIN_CUM: 11484, TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 11484, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11368, CLOSED_CUM: 0,    REMAIN_CUM: 11484, TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 11484, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-25', CREATED_WK: 2,    CLOSED_WK: 0,    CREATED_CUM: 11370, CLOSED_CUM: 0,    REMAIN_CUM: 11484, TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 11484, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-01', CREATED_WK: 1,    CLOSED_WK: 0,    CREATED_CUM: 11371, CLOSED_CUM: 0,    REMAIN_CUM: 11484, TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 11484, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-08', CREATED_WK: 1,    CLOSED_WK: 11,   CREATED_CUM: 11372, CLOSED_CUM: 11,   REMAIN_CUM: 11473, TOTAL: 11484, PL_CLOSED_WK: 11,   PL_CLOSED_CUM: 11,    PL_REMAIN_CUM: 11473, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-15', CREATED_WK: 0,    CLOSED_WK: 5,    CREATED_CUM: 11372, CLOSED_CUM: 16,   REMAIN_CUM: 11468, TOTAL: 11484, PL_CLOSED_WK: 5,    PL_CLOSED_CUM: 16,    PL_REMAIN_CUM: 11468, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-22', CREATED_WK: 0,    CLOSED_WK: 14,   CREATED_CUM: 11372, CLOSED_CUM: 30,   REMAIN_CUM: 11454, TOTAL: 11484, PL_CLOSED_WK: 14,   PL_CLOSED_CUM: 30,    PL_REMAIN_CUM: 11454, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-29', CREATED_WK: 1,    CLOSED_WK: 0,    CREATED_CUM: 11373, CLOSED_CUM: 30,   REMAIN_CUM: 11454, TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 30,    PL_REMAIN_CUM: 11454, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11373, CLOSED_CUM: 30,   REMAIN_CUM: 11454, TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 30,    PL_REMAIN_CUM: 11454, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-13', CREATED_WK: 4,    CLOSED_WK: 26,   CREATED_CUM: 11377, CLOSED_CUM: 56,   REMAIN_CUM: 11428, TOTAL: 11484, PL_CLOSED_WK: 26,   PL_CLOSED_CUM: 56,    PL_REMAIN_CUM: 11428, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-20', CREATED_WK: 82,   CLOSED_WK: 19,   CREATED_CUM: 11459, CLOSED_CUM: 75,   REMAIN_CUM: 11409, TOTAL: 11484, PL_CLOSED_WK: 19,   PL_CLOSED_CUM: 75,    PL_REMAIN_CUM: 11409, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-27', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11459, CLOSED_CUM: 75,   REMAIN_CUM: 11409, TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 75,    PL_REMAIN_CUM: 11409, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-03', CREATED_WK: 7,    CLOSED_WK: 0,    CREATED_CUM: 11466, CLOSED_CUM: 75,   REMAIN_CUM: 11409, TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 75,    PL_REMAIN_CUM: 11409, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-10', CREATED_WK: 0,    CLOSED_WK: 23,   CREATED_CUM: 11466, CLOSED_CUM: 98,   REMAIN_CUM: 11386, TOTAL: 11484, PL_CLOSED_WK: 23,   PL_CLOSED_CUM: 98,    PL_REMAIN_CUM: 11386, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11466, CLOSED_CUM: 98,   REMAIN_CUM: 11386, TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 98,    PL_REMAIN_CUM: 11386, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-24', CREATED_WK: 0,    CLOSED_WK: 87,   CREATED_CUM: 11466, CLOSED_CUM: 185,  REMAIN_CUM: 11299, TOTAL: 11484, PL_CLOSED_WK: 87,   PL_CLOSED_CUM: 185,   PL_REMAIN_CUM: 11299, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-01', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11466, CLOSED_CUM: 185,  REMAIN_CUM: 11299, TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 185,   PL_REMAIN_CUM: 11299, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11466, CLOSED_CUM: 185,  REMAIN_CUM: 11299, TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 185,   PL_REMAIN_CUM: 11299, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-15', CREATED_WK: 0,    CLOSED_WK: 1,    CREATED_CUM: 11466, CLOSED_CUM: 186,  REMAIN_CUM: 11298, TOTAL: 11484, PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 186,   PL_REMAIN_CUM: 11298, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-22', CREATED_WK: 1,    CLOSED_WK: 1,    CREATED_CUM: 11467, CLOSED_CUM: 187,  REMAIN_CUM: 11297, TOTAL: 11484, PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 187,   PL_REMAIN_CUM: 11297, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 8,    CREATED_CUM: 11467, CLOSED_CUM: 195,  REMAIN_CUM: 11289, TOTAL: 11484, PL_CLOSED_WK: 8,    PL_CLOSED_CUM: 195,   PL_REMAIN_CUM: 11289, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-05', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11467, CLOSED_CUM: 195,  REMAIN_CUM: 11289, TOTAL: 11484, PL_CLOSED_WK: 38,   PL_CLOSED_CUM: 233,   PL_REMAIN_CUM: 11251, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-12', CREATED_WK: 0,    CLOSED_WK: 8,    CREATED_CUM: 11467, CLOSED_CUM: 203,  REMAIN_CUM: 11281, TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 233,   PL_REMAIN_CUM: 11250, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-19', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11467, CLOSED_CUM: 203,  REMAIN_CUM: 11281, TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 233,   PL_REMAIN_CUM: 11250, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-26', CREATED_WK: 16,   CLOSED_WK: 2,    CREATED_CUM: 11483, CLOSED_CUM: 205,  REMAIN_CUM: 11279, TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 233,   PL_REMAIN_CUM: 11250, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-02', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11483, CLOSED_CUM: 205,  REMAIN_CUM: 11279, TOTAL: 11484, PL_CLOSED_WK: 9,    PL_CLOSED_CUM: 243,   PL_REMAIN_CUM: 11240, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-09', CREATED_WK: 1,    CLOSED_WK: 7,    CREATED_CUM: 11484, CLOSED_CUM: 212,  REMAIN_CUM: 11272, TOTAL: 11484, PL_CLOSED_WK: 25,   PL_CLOSED_CUM: 269,   PL_REMAIN_CUM: 11214, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-16', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11484, CLOSED_CUM: 212,  REMAIN_CUM: 11272, TOTAL: 11484, PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 272,   PL_REMAIN_CUM: 11211, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 5,    PL_CLOSED_CUM: 277,   PL_REMAIN_CUM: 11206, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 14,   PL_CLOSED_CUM: 292,   PL_REMAIN_CUM: 11191, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 1111, PL_CLOSED_CUM: 1404,  PL_REMAIN_CUM: 10079, PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 1316, PL_CLOSED_CUM: 2720,  PL_REMAIN_CUM: 8763,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-22', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 136,  PL_CLOSED_CUM: 2857,  PL_REMAIN_CUM: 8626,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-29', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 24,   PL_CLOSED_CUM: 2881,  PL_REMAIN_CUM: 8602,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 77,   PL_CLOSED_CUM: 2958,  PL_REMAIN_CUM: 8525,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 73,   PL_CLOSED_CUM: 3032,  PL_REMAIN_CUM: 8451,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 350,  PL_CLOSED_CUM: 3382,  PL_REMAIN_CUM: 8101,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 55,   PL_CLOSED_CUM: 3437,  PL_REMAIN_CUM: 8046,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-03', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 188,  PL_CLOSED_CUM: 3626,  PL_REMAIN_CUM: 7857,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-10', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 282,  PL_CLOSED_CUM: 3909,  PL_REMAIN_CUM: 7574,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-17', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 338,  PL_CLOSED_CUM: 4248,  PL_REMAIN_CUM: 7235,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-24', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 391,  PL_CLOSED_CUM: 4639,  PL_REMAIN_CUM: 6844,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-31', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 572,  PL_CLOSED_CUM: 5212,  PL_REMAIN_CUM: 6271,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-07', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 949,  PL_CLOSED_CUM: 6161,  PL_REMAIN_CUM: 5322,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-14', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 527,  PL_CLOSED_CUM: 6689,  PL_REMAIN_CUM: 4795,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-21', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 315,  PL_CLOSED_CUM: 7004,  PL_REMAIN_CUM: 4479,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-28', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 714,  PL_CLOSED_CUM: 7719,  PL_REMAIN_CUM: 3764,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 937,  PL_CLOSED_CUM: 8657,  PL_REMAIN_CUM: 2826,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 612,  PL_CLOSED_CUM: 9270,  PL_REMAIN_CUM: 2213,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 221,  PL_CLOSED_CUM: 9491,  PL_REMAIN_CUM: 1992,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 247,  PL_CLOSED_CUM: 9739,  PL_REMAIN_CUM: 1744,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-02', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 332,  PL_CLOSED_CUM: 10071, PL_REMAIN_CUM: 1412,  PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-09', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 638,  PL_CLOSED_CUM: 10710, PL_REMAIN_CUM: 773,   PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-16', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 194,  PL_CLOSED_CUM: 10905, PL_REMAIN_CUM: 578,   PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 120,  PL_CLOSED_CUM: 11026, PL_REMAIN_CUM: 457,   PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-30', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 5,    PL_CLOSED_CUM: 11031, PL_REMAIN_CUM: 452,   PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-06', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 233,  PL_CLOSED_CUM: 11264, PL_REMAIN_CUM: 219,   PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-13', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 144,  PL_CLOSED_CUM: 11409, PL_REMAIN_CUM: 74,    PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-20', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 74,   PL_CLOSED_CUM: 11484, PL_REMAIN_CUM: 0,     PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-27', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 11484, PL_REMAIN_CUM: 0,     PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-04', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 11484, PL_REMAIN_CUM: 0,     PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-11', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 11484, PL_REMAIN_CUM: 0,     PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-18', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 11484, PL_REMAIN_CUM: 0,     PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-25', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 11484, PL_REMAIN_CUM: 0,     PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-11-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 11484, PL_REMAIN_CUM: 0,     PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-11-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 11484, PL_REMAIN_CUM: 0,     PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-11-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 11484, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 11484, PL_REMAIN_CUM: 0,     PL_TOTAL: 11484, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-07', CREATED_WK: 4,    CLOSED_WK: 1,    CREATED_CUM: 1114,  CLOSED_CUM: 68,   REMAIN_CUM: 1251,  TOTAL: 1319,  PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 68,    PL_REMAIN_CUM: 1251,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-14', CREATED_WK: 38,   CLOSED_WK: 22,   CREATED_CUM: 1152,  CLOSED_CUM: 90,   REMAIN_CUM: 1229,  TOTAL: 1319,  PL_CLOSED_WK: 22,   PL_CLOSED_CUM: 90,    PL_REMAIN_CUM: 1229,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-21', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 1152,  CLOSED_CUM: 92,   REMAIN_CUM: 1227,  TOTAL: 1319,  PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 92,    PL_REMAIN_CUM: 1227,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-28', CREATED_WK: 0,    CLOSED_WK: 5,    CREATED_CUM: 1152,  CLOSED_CUM: 97,   REMAIN_CUM: 1222,  TOTAL: 1319,  PL_CLOSED_WK: 5,    PL_CLOSED_CUM: 97,    PL_REMAIN_CUM: 1222,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1152,  CLOSED_CUM: 97,   REMAIN_CUM: 1222,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 97,    PL_REMAIN_CUM: 1222,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 1,    CREATED_CUM: 1152,  CLOSED_CUM: 98,   REMAIN_CUM: 1221,  TOTAL: 1319,  PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 98,    PL_REMAIN_CUM: 1221,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1152,  CLOSED_CUM: 98,   REMAIN_CUM: 1221,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 98,    PL_REMAIN_CUM: 1221,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-25', CREATED_WK: 2,    CLOSED_WK: 3,    CREATED_CUM: 1154,  CLOSED_CUM: 101,  REMAIN_CUM: 1218,  TOTAL: 1319,  PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 101,   PL_REMAIN_CUM: 1218,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-01', CREATED_WK: 0,    CLOSED_WK: 6,    CREATED_CUM: 1154,  CLOSED_CUM: 107,  REMAIN_CUM: 1212,  TOTAL: 1319,  PL_CLOSED_WK: 6,    PL_CLOSED_CUM: 107,   PL_REMAIN_CUM: 1212,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-08', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 1154,  CLOSED_CUM: 109,  REMAIN_CUM: 1210,  TOTAL: 1319,  PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 109,   PL_REMAIN_CUM: 1210,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-15', CREATED_WK: 3,    CLOSED_WK: 0,    CREATED_CUM: 1157,  CLOSED_CUM: 109,  REMAIN_CUM: 1210,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 109,   PL_REMAIN_CUM: 1210,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-22', CREATED_WK: 22,   CLOSED_WK: 0,    CREATED_CUM: 1179,  CLOSED_CUM: 109,  REMAIN_CUM: 1210,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 109,   PL_REMAIN_CUM: 1210,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-29', CREATED_WK: 1,    CLOSED_WK: 3,    CREATED_CUM: 1180,  CLOSED_CUM: 112,  REMAIN_CUM: 1207,  TOTAL: 1319,  PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 112,   PL_REMAIN_CUM: 1207,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 6,    CREATED_CUM: 1180,  CLOSED_CUM: 118,  REMAIN_CUM: 1201,  TOTAL: 1319,  PL_CLOSED_WK: 6,    PL_CLOSED_CUM: 118,   PL_REMAIN_CUM: 1201,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-13', CREATED_WK: 3,    CLOSED_WK: 7,    CREATED_CUM: 1183,  CLOSED_CUM: 125,  REMAIN_CUM: 1194,  TOTAL: 1319,  PL_CLOSED_WK: 7,    PL_CLOSED_CUM: 125,   PL_REMAIN_CUM: 1194,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-20', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1183,  CLOSED_CUM: 125,  REMAIN_CUM: 1194,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 125,   PL_REMAIN_CUM: 1194,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-27', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 1183,  CLOSED_CUM: 127,  REMAIN_CUM: 1192,  TOTAL: 1319,  PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 127,   PL_REMAIN_CUM: 1192,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-03', CREATED_WK: 1,    CLOSED_WK: 8,    CREATED_CUM: 1184,  CLOSED_CUM: 135,  REMAIN_CUM: 1184,  TOTAL: 1319,  PL_CLOSED_WK: 8,    PL_CLOSED_CUM: 135,   PL_REMAIN_CUM: 1184,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-10', CREATED_WK: 13,   CLOSED_WK: 11,   CREATED_CUM: 1197,  CLOSED_CUM: 146,  REMAIN_CUM: 1173,  TOTAL: 1319,  PL_CLOSED_WK: 11,   PL_CLOSED_CUM: 146,   PL_REMAIN_CUM: 1173,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 6,    CREATED_CUM: 1197,  CLOSED_CUM: 152,  REMAIN_CUM: 1167,  TOTAL: 1319,  PL_CLOSED_WK: 6,    PL_CLOSED_CUM: 152,   PL_REMAIN_CUM: 1167,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-24', CREATED_WK: 9,    CLOSED_WK: 1,    CREATED_CUM: 1206,  CLOSED_CUM: 153,  REMAIN_CUM: 1166,  TOTAL: 1319,  PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 153,   PL_REMAIN_CUM: 1166,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-01', CREATED_WK: 11,   CLOSED_WK: 3,    CREATED_CUM: 1217,  CLOSED_CUM: 156,  REMAIN_CUM: 1163,  TOTAL: 1319,  PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 156,   PL_REMAIN_CUM: 1163,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-08', CREATED_WK: 3,    CLOSED_WK: 0,    CREATED_CUM: 1220,  CLOSED_CUM: 156,  REMAIN_CUM: 1163,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 156,   PL_REMAIN_CUM: 1163,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-15', CREATED_WK: 44,   CLOSED_WK: 24,   CREATED_CUM: 1264,  CLOSED_CUM: 180,  REMAIN_CUM: 1139,  TOTAL: 1319,  PL_CLOSED_WK: 24,   PL_CLOSED_CUM: 180,   PL_REMAIN_CUM: 1139,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-22', CREATED_WK: 2,    CLOSED_WK: 9,    CREATED_CUM: 1266,  CLOSED_CUM: 189,  REMAIN_CUM: 1130,  TOTAL: 1319,  PL_CLOSED_WK: 9,    PL_CLOSED_CUM: 189,   PL_REMAIN_CUM: 1130,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-29', CREATED_WK: 17,   CLOSED_WK: 36,   CREATED_CUM: 1283,  CLOSED_CUM: 225,  REMAIN_CUM: 1094,  TOTAL: 1319,  PL_CLOSED_WK: 36,   PL_CLOSED_CUM: 225,   PL_REMAIN_CUM: 1094,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-05', CREATED_WK: 7,    CLOSED_WK: 3,    CREATED_CUM: 1290,  CLOSED_CUM: 228,  REMAIN_CUM: 1091,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 225,   PL_REMAIN_CUM: 1094,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-12', CREATED_WK: 1,    CLOSED_WK: 4,    CREATED_CUM: 1291,  CLOSED_CUM: 232,  REMAIN_CUM: 1087,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 225,   PL_REMAIN_CUM: 1094,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-19', CREATED_WK: 19,   CLOSED_WK: 0,    CREATED_CUM: 1310,  CLOSED_CUM: 232,  REMAIN_CUM: 1087,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 225,   PL_REMAIN_CUM: 1094,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1310,  CLOSED_CUM: 232,  REMAIN_CUM: 1087,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 225,   PL_REMAIN_CUM: 1094,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-02', CREATED_WK: 6,    CLOSED_WK: 6,    CREATED_CUM: 1316,  CLOSED_CUM: 238,  REMAIN_CUM: 1081,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 225,   PL_REMAIN_CUM: 1094,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-09', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1316,  CLOSED_CUM: 238,  REMAIN_CUM: 1081,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 225,   PL_REMAIN_CUM: 1094,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-16', CREATED_WK: 3,    CLOSED_WK: 0,    CREATED_CUM: 1319,  CLOSED_CUM: 238,  REMAIN_CUM: 1081,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 225,   PL_REMAIN_CUM: 1094,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 225,   PL_REMAIN_CUM: 1093,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 225,   PL_REMAIN_CUM: 1093,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 7,    PL_CLOSED_CUM: 232,   PL_REMAIN_CUM: 1086,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 9,    PL_CLOSED_CUM: 242,   PL_REMAIN_CUM: 1076,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-22', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 7,    PL_CLOSED_CUM: 249,   PL_REMAIN_CUM: 1069,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-29', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 250,   PL_REMAIN_CUM: 1068,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 250,   PL_REMAIN_CUM: 1068,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 8,    PL_CLOSED_CUM: 259,   PL_REMAIN_CUM: 1059,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 21,   PL_CLOSED_CUM: 281,   PL_REMAIN_CUM: 1038,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 8,    PL_CLOSED_CUM: 289,   PL_REMAIN_CUM: 1029,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-03', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 19,   PL_CLOSED_CUM: 309,   PL_REMAIN_CUM: 1009,  PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-10', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 20,   PL_CLOSED_CUM: 330,   PL_REMAIN_CUM: 989,   PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-17', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 53,   PL_CLOSED_CUM: 383,   PL_REMAIN_CUM: 935,   PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-24', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 27,   PL_CLOSED_CUM: 411,   PL_REMAIN_CUM: 907,   PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-31', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 82,   PL_CLOSED_CUM: 494,   PL_REMAIN_CUM: 824,   PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-07', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 81,   PL_CLOSED_CUM: 575,   PL_REMAIN_CUM: 743,   PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-14', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 78,   PL_CLOSED_CUM: 654,   PL_REMAIN_CUM: 664,   PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-21', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 54,   PL_CLOSED_CUM: 708,   PL_REMAIN_CUM: 610,   PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-28', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 67,   PL_CLOSED_CUM: 776,   PL_REMAIN_CUM: 542,   PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 84,   PL_CLOSED_CUM: 860,   PL_REMAIN_CUM: 458,   PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 49,   PL_CLOSED_CUM: 910,   PL_REMAIN_CUM: 408,   PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 55,   PL_CLOSED_CUM: 966,   PL_REMAIN_CUM: 352,   PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 52,   PL_CLOSED_CUM: 1018,  PL_REMAIN_CUM: 300,   PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-02', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 42,   PL_CLOSED_CUM: 1060,  PL_REMAIN_CUM: 258,   PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-09', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 59,   PL_CLOSED_CUM: 1120,  PL_REMAIN_CUM: 198,   PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-16', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 61,   PL_CLOSED_CUM: 1181,  PL_REMAIN_CUM: 137,   PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 62,   PL_CLOSED_CUM: 1244,  PL_REMAIN_CUM: 74,    PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-30', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 7,    PL_CLOSED_CUM: 1251,  PL_REMAIN_CUM: 67,    PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-06', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 1254,  PL_REMAIN_CUM: 64,    PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-13', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 16,   PL_CLOSED_CUM: 1270,  PL_REMAIN_CUM: 48,    PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-20', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 1271,  PL_REMAIN_CUM: 48,    PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-27', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 1271,  PL_REMAIN_CUM: 48,    PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-04', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 1271,  PL_REMAIN_CUM: 48,    PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-11', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 1271,  PL_REMAIN_CUM: 48,    PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-18', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 1271,  PL_REMAIN_CUM: 48,    PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-25', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 1271,  PL_REMAIN_CUM: 48,    PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-11-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 48,   PL_CLOSED_CUM: 1319,  PL_REMAIN_CUM: 0,     PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-11-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 1319,  PL_REMAIN_CUM: 0,     PL_TOTAL: 1319, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-11-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 1319,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 1319,  PL_REMAIN_CUM: 0,     PL_TOTAL: 1319, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-07-07', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 475,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-07-14', CREATED_WK: 1,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-07-21', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-07-28', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-08-25', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-09-01', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-09-08', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-09-15', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-09-22', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-09-29', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-10-13', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-10-20', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-10-27', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-11-03', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-11-10', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-11-24', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-12-01', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 476,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-12-15', CREATED_WK: 2,    CLOSED_WK: 0,    CREATED_CUM: 478,   CLOSED_CUM: 0,    REMAIN_CUM: 478,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 478,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-12-22', CREATED_WK: 0,    CLOSED_WK: 3,    CREATED_CUM: 478,   CLOSED_CUM: 3,    REMAIN_CUM: 475,   TOTAL: 478,   PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 3,     PL_REMAIN_CUM: 475,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 1,    CREATED_CUM: 478,   CLOSED_CUM: 4,    REMAIN_CUM: 474,   TOTAL: 478,   PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 4,     PL_REMAIN_CUM: 474,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-01-05', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 478,   CLOSED_CUM: 4,    REMAIN_CUM: 474,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4,     PL_REMAIN_CUM: 474,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-01-12', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 478,   CLOSED_CUM: 4,    REMAIN_CUM: 474,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4,     PL_REMAIN_CUM: 474,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-01-19', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 478,   CLOSED_CUM: 4,    REMAIN_CUM: 474,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4,     PL_REMAIN_CUM: 474,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 6,    CREATED_CUM: 478,   CLOSED_CUM: 10,   REMAIN_CUM: 468,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4,     PL_REMAIN_CUM: 474,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-02-02', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 478,   CLOSED_CUM: 10,   REMAIN_CUM: 468,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4,     PL_REMAIN_CUM: 474,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-02-09', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 478,   CLOSED_CUM: 10,   REMAIN_CUM: 468,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4,     PL_REMAIN_CUM: 474,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-02-16', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 478,   CLOSED_CUM: 10,   REMAIN_CUM: 468,   TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4,     PL_REMAIN_CUM: 474,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-02-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4,     PL_REMAIN_CUM: 474,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-03-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4,     PL_REMAIN_CUM: 474,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-03-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4,     PL_REMAIN_CUM: 474,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-03-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4,     PL_REMAIN_CUM: 474,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-03-22', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4,     PL_REMAIN_CUM: 474,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-03-29', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4,     PL_REMAIN_CUM: 474,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-04-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4,     PL_REMAIN_CUM: 474,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-04-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 5,     PL_REMAIN_CUM: 472,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-04-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 7,    PL_CLOSED_CUM: 12,    PL_REMAIN_CUM: 465,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-04-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 14,    PL_REMAIN_CUM: 463,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-05-03', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 10,   PL_CLOSED_CUM: 25,    PL_REMAIN_CUM: 452,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-05-10', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 28,   PL_CLOSED_CUM: 53,    PL_REMAIN_CUM: 424,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-05-17', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 24,   PL_CLOSED_CUM: 78,    PL_REMAIN_CUM: 399,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-05-24', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 10,   PL_CLOSED_CUM: 88,    PL_REMAIN_CUM: 389,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-05-31', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 35,   PL_CLOSED_CUM: 124,   PL_REMAIN_CUM: 353,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-06-07', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 38,   PL_CLOSED_CUM: 163,   PL_REMAIN_CUM: 314,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-06-14', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 40,   PL_CLOSED_CUM: 204,   PL_REMAIN_CUM: 273,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-06-21', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 31,   PL_CLOSED_CUM: 235,   PL_REMAIN_CUM: 242,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-06-28', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 29,   PL_CLOSED_CUM: 265,   PL_REMAIN_CUM: 212,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-07-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 25,   PL_CLOSED_CUM: 290,   PL_REMAIN_CUM: 187,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-07-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 4,    PL_CLOSED_CUM: 295,   PL_REMAIN_CUM: 182,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-07-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 18,   PL_CLOSED_CUM: 314,   PL_REMAIN_CUM: 163,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-07-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 19,   PL_CLOSED_CUM: 333,   PL_REMAIN_CUM: 144,   PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-08-02', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 49,   PL_CLOSED_CUM: 383,   PL_REMAIN_CUM: 94,    PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-08-09', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 40,   PL_CLOSED_CUM: 424,   PL_REMAIN_CUM: 53,    PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-08-16', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 14,   PL_CLOSED_CUM: 438,   PL_REMAIN_CUM: 39,    PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-08-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 440,   PL_REMAIN_CUM: 37,    PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-08-30', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 29,   PL_CLOSED_CUM: 470,   PL_REMAIN_CUM: 7,     PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-09-06', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 472,   PL_REMAIN_CUM: 6,     PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-09-13', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 474,   PL_REMAIN_CUM: 3,     PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-09-20', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 478,   PL_REMAIN_CUM: 0,     PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-09-27', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 478,   PL_REMAIN_CUM: 0,     PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-10-04', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 478,   PL_REMAIN_CUM: 0,     PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-10-11', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 478,   PL_REMAIN_CUM: 0,     PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-10-18', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 478,   PL_REMAIN_CUM: 0,     PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-10-25', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 478,   PL_REMAIN_CUM: 0,     PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-11-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 478,   PL_REMAIN_CUM: 0,     PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-11-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 478,   PL_REMAIN_CUM: 0,     PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', CDATE: '2024-11-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 478,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 478,   PL_REMAIN_CUM: 0,     PL_TOTAL: 478, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-07-07', CREATED_WK: 6,    CLOSED_WK: 0,    CREATED_CUM: 10683, CLOSED_CUM: 0,    REMAIN_CUM: 10791, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 10791, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-07-14', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10683, CLOSED_CUM: 0,    REMAIN_CUM: 10791, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 10791, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-07-21', CREATED_WK: 6,    CLOSED_WK: 0,    CREATED_CUM: 10689, CLOSED_CUM: 0,    REMAIN_CUM: 10791, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 10791, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-07-28', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10689, CLOSED_CUM: 0,    REMAIN_CUM: 10791, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 10791, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10689, CLOSED_CUM: 0,    REMAIN_CUM: 10791, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 10791, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10689, CLOSED_CUM: 0,    REMAIN_CUM: 10791, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 10791, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10689, CLOSED_CUM: 0,    REMAIN_CUM: 10791, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 10791, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-08-25', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10689, CLOSED_CUM: 0,    REMAIN_CUM: 10791, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 10791, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-09-01', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10689, CLOSED_CUM: 0,    REMAIN_CUM: 10791, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 10791, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-09-08', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10689, CLOSED_CUM: 0,    REMAIN_CUM: 10791, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 10791, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-09-15', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10689, CLOSED_CUM: 0,    REMAIN_CUM: 10791, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 10791, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-09-22', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10689, CLOSED_CUM: 0,    REMAIN_CUM: 10791, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 10791, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-09-29', CREATED_WK: 1,    CLOSED_WK: 0,    CREATED_CUM: 10690, CLOSED_CUM: 0,    REMAIN_CUM: 10791, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 10791, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10690, CLOSED_CUM: 0,    REMAIN_CUM: 10791, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 10791, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-10-13', CREATED_WK: 3,    CLOSED_WK: 0,    CREATED_CUM: 10693, CLOSED_CUM: 0,    REMAIN_CUM: 10791, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 10791, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-10-20', CREATED_WK: 82,   CLOSED_WK: 0,    CREATED_CUM: 10775, CLOSED_CUM: 0,    REMAIN_CUM: 10791, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 10791, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-10-27', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10775, CLOSED_CUM: 0,    REMAIN_CUM: 10791, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 10791, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-11-03', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10775, CLOSED_CUM: 0,    REMAIN_CUM: 10791, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 10791, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-11-10', CREATED_WK: 0,    CLOSED_WK: 23,   CREATED_CUM: 10775, CLOSED_CUM: 23,   REMAIN_CUM: 10768, TOTAL: 10791, PL_CLOSED_WK: 23,   PL_CLOSED_CUM: 23,    PL_REMAIN_CUM: 10768, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10775, CLOSED_CUM: 23,   REMAIN_CUM: 10768, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 23,    PL_REMAIN_CUM: 10768, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-11-24', CREATED_WK: 0,    CLOSED_WK: 87,   CREATED_CUM: 10775, CLOSED_CUM: 110,  REMAIN_CUM: 10681, TOTAL: 10791, PL_CLOSED_WK: 87,   PL_CLOSED_CUM: 110,   PL_REMAIN_CUM: 10681, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-12-01', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10775, CLOSED_CUM: 110,  REMAIN_CUM: 10681, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 110,   PL_REMAIN_CUM: 10681, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10775, CLOSED_CUM: 110,  REMAIN_CUM: 10681, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 110,   PL_REMAIN_CUM: 10681, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-12-15', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10775, CLOSED_CUM: 110,  REMAIN_CUM: 10681, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 110,   PL_REMAIN_CUM: 10681, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-12-22', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10775, CLOSED_CUM: 110,  REMAIN_CUM: 10681, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 110,   PL_REMAIN_CUM: 10681, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 8,    CREATED_CUM: 10775, CLOSED_CUM: 118,  REMAIN_CUM: 10673, TOTAL: 10791, PL_CLOSED_WK: 8,    PL_CLOSED_CUM: 118,   PL_REMAIN_CUM: 10673, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-01-05', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10775, CLOSED_CUM: 118,  REMAIN_CUM: 10673, TOTAL: 10791, PL_CLOSED_WK: 38,   PL_CLOSED_CUM: 156,   PL_REMAIN_CUM: 10635, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-01-12', CREATED_WK: 0,    CLOSED_WK: 8,    CREATED_CUM: 10775, CLOSED_CUM: 126,  REMAIN_CUM: 10665, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 156,   PL_REMAIN_CUM: 10635, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-01-19', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10775, CLOSED_CUM: 126,  REMAIN_CUM: 10665, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 156,   PL_REMAIN_CUM: 10635, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-01-26', CREATED_WK: 16,   CLOSED_WK: 0,    CREATED_CUM: 10791, CLOSED_CUM: 126,  REMAIN_CUM: 10665, TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 156,   PL_REMAIN_CUM: 10635, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-02-02', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10791, CLOSED_CUM: 126,  REMAIN_CUM: 10665, TOTAL: 10791, PL_CLOSED_WK: 7,    PL_CLOSED_CUM: 163,   PL_REMAIN_CUM: 10627, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-02-09', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10791, CLOSED_CUM: 126,  REMAIN_CUM: 10665, TOTAL: 10791, PL_CLOSED_WK: 21,   PL_CLOSED_CUM: 185,   PL_REMAIN_CUM: 10606, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-02-16', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 10791, CLOSED_CUM: 126,  REMAIN_CUM: 10665, TOTAL: 10791, PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 187,   PL_REMAIN_CUM: 10603, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-02-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 5,    PL_CLOSED_CUM: 193,   PL_REMAIN_CUM: 10598, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-03-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 193,   PL_REMAIN_CUM: 10598, PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-03-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 1069, PL_CLOSED_CUM: 1262,  PL_REMAIN_CUM: 9528,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-03-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 1308, PL_CLOSED_CUM: 2571,  PL_REMAIN_CUM: 8219,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-03-22', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 59,   PL_CLOSED_CUM: 2630,  PL_REMAIN_CUM: 8160,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-03-29', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 16,   PL_CLOSED_CUM: 2647,  PL_REMAIN_CUM: 8143,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-04-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 65,   PL_CLOSED_CUM: 2712,  PL_REMAIN_CUM: 8078,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-04-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 73,   PL_CLOSED_CUM: 2786,  PL_REMAIN_CUM: 8004,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-04-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 350,  PL_CLOSED_CUM: 3136,  PL_REMAIN_CUM: 7654,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-04-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 51,   PL_CLOSED_CUM: 3187,  PL_REMAIN_CUM: 7603,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-05-03', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 176,  PL_CLOSED_CUM: 3364,  PL_REMAIN_CUM: 7426,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-05-10', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 262,  PL_CLOSED_CUM: 3626,  PL_REMAIN_CUM: 7164,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-05-17', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 303,  PL_CLOSED_CUM: 3930,  PL_REMAIN_CUM: 6860,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-05-24', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 375,  PL_CLOSED_CUM: 4305,  PL_REMAIN_CUM: 6485,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-05-31', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 545,  PL_CLOSED_CUM: 4850,  PL_REMAIN_CUM: 5940,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-06-07', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 895,  PL_CLOSED_CUM: 5745,  PL_REMAIN_CUM: 5045,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-06-14', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 507,  PL_CLOSED_CUM: 6253,  PL_REMAIN_CUM: 4537,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-06-21', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 291,  PL_CLOSED_CUM: 6544,  PL_REMAIN_CUM: 4246,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-06-28', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 612,  PL_CLOSED_CUM: 7157,  PL_REMAIN_CUM: 3633,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-07-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 921,  PL_CLOSED_CUM: 8078,  PL_REMAIN_CUM: 2712,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-07-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 595,  PL_CLOSED_CUM: 8673,  PL_REMAIN_CUM: 2117,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-07-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 213,  PL_CLOSED_CUM: 8887,  PL_REMAIN_CUM: 1903,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-07-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 234,  PL_CLOSED_CUM: 9122,  PL_REMAIN_CUM: 1668,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-08-02', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 307,  PL_CLOSED_CUM: 9430,  PL_REMAIN_CUM: 1361,  PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-08-09', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 618,  PL_CLOSED_CUM: 10048, PL_REMAIN_CUM: 742,   PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-08-16', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 181,  PL_CLOSED_CUM: 10230, PL_REMAIN_CUM: 560,   PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-08-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 112,  PL_CLOSED_CUM: 10343, PL_REMAIN_CUM: 447,   PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-08-30', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 10346, PL_REMAIN_CUM: 444,   PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-09-06', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 226,  PL_CLOSED_CUM: 10573, PL_REMAIN_CUM: 218,   PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-09-13', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 143,  PL_CLOSED_CUM: 10716, PL_REMAIN_CUM: 74,    PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-09-20', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 74,   PL_CLOSED_CUM: 10791, PL_REMAIN_CUM: 0,     PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-09-27', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 10791, PL_REMAIN_CUM: 0,     PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-10-04', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 10791, PL_REMAIN_CUM: 0,     PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-10-11', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 10791, PL_REMAIN_CUM: 0,     PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-10-18', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 10791, PL_REMAIN_CUM: 0,     PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-10-25', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 10791, PL_REMAIN_CUM: 0,     PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-11-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 10791, PL_REMAIN_CUM: 0,     PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-11-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 10791, PL_REMAIN_CUM: 0,     PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              CDATE: '2024-11-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10791, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 10791, PL_REMAIN_CUM: 0,     PL_TOTAL: 10791, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-07-07', CREATED_WK: 39,   CLOSED_WK: 0,    CREATED_CUM: 1432,  CLOSED_CUM: 0,    REMAIN_CUM: 4450,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 4449,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-07-14', CREATED_WK: 13,   CLOSED_WK: 0,    CREATED_CUM: 1445,  CLOSED_CUM: 0,    REMAIN_CUM: 4450,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 4449,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-07-21', CREATED_WK: 63,   CLOSED_WK: 0,    CREATED_CUM: 1508,  CLOSED_CUM: 0,    REMAIN_CUM: 4450,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 4449,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-07-28', CREATED_WK: 21,   CLOSED_WK: 0,    CREATED_CUM: 1529,  CLOSED_CUM: 0,    REMAIN_CUM: 4450,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 4449,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-08-04', CREATED_WK: 17,   CLOSED_WK: 0,    CREATED_CUM: 1546,  CLOSED_CUM: 0,    REMAIN_CUM: 4450,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 4449,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-08-11', CREATED_WK: 127,  CLOSED_WK: 0,    CREATED_CUM: 1673,  CLOSED_CUM: 0,    REMAIN_CUM: 4450,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 4449,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1673,  CLOSED_CUM: 0,    REMAIN_CUM: 4450,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 4449,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-08-25', CREATED_WK: 267,  CLOSED_WK: 0,    CREATED_CUM: 1940,  CLOSED_CUM: 0,    REMAIN_CUM: 4450,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 4449,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-09-01', CREATED_WK: 124,  CLOSED_WK: 0,    CREATED_CUM: 2064,  CLOSED_CUM: 0,    REMAIN_CUM: 4450,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 4449,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-09-08', CREATED_WK: 211,  CLOSED_WK: 0,    CREATED_CUM: 2275,  CLOSED_CUM: 0,    REMAIN_CUM: 4450,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 4449,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-09-15', CREATED_WK: 104,  CLOSED_WK: 0,    CREATED_CUM: 2379,  CLOSED_CUM: 0,    REMAIN_CUM: 4450,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 4449,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-09-22', CREATED_WK: 99,   CLOSED_WK: 0,    CREATED_CUM: 2478,  CLOSED_CUM: 0,    REMAIN_CUM: 4450,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 4449,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-09-29', CREATED_WK: 102,  CLOSED_WK: 0,    CREATED_CUM: 2580,  CLOSED_CUM: 0,    REMAIN_CUM: 4450,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 4449,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-10-06', CREATED_WK: 102,  CLOSED_WK: 0,    CREATED_CUM: 2682,  CLOSED_CUM: 0,    REMAIN_CUM: 4450,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 4449,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-10-13', CREATED_WK: 149,  CLOSED_WK: 0,    CREATED_CUM: 2831,  CLOSED_CUM: 0,    REMAIN_CUM: 4450,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 4449,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-10-20', CREATED_WK: 134,  CLOSED_WK: 0,    CREATED_CUM: 2965,  CLOSED_CUM: 0,    REMAIN_CUM: 4450,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 4449,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-10-27', CREATED_WK: 142,  CLOSED_WK: 0,    CREATED_CUM: 3107,  CLOSED_CUM: 0,    REMAIN_CUM: 4450,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 4449,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-11-03', CREATED_WK: 77,   CLOSED_WK: 6,    CREATED_CUM: 3184,  CLOSED_CUM: 6,    REMAIN_CUM: 4444,  TOTAL: 4450,  PL_CLOSED_WK: 6,    PL_CLOSED_CUM: 6,     PL_REMAIN_CUM: 4443,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-11-10', CREATED_WK: 126,  CLOSED_WK: 1,    CREATED_CUM: 3310,  CLOSED_CUM: 7,    REMAIN_CUM: 4443,  TOTAL: 4450,  PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 7,     PL_REMAIN_CUM: 4442,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-11-17', CREATED_WK: 75,   CLOSED_WK: 0,    CREATED_CUM: 3385,  CLOSED_CUM: 7,    REMAIN_CUM: 4443,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 7,     PL_REMAIN_CUM: 4442,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-11-24', CREATED_WK: 101,  CLOSED_WK: 0,    CREATED_CUM: 3486,  CLOSED_CUM: 7,    REMAIN_CUM: 4443,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 7,     PL_REMAIN_CUM: 4442,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-12-01', CREATED_WK: 10,   CLOSED_WK: 0,    CREATED_CUM: 3496,  CLOSED_CUM: 7,    REMAIN_CUM: 4443,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 7,     PL_REMAIN_CUM: 4442,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-12-08', CREATED_WK: 89,   CLOSED_WK: 2,    CREATED_CUM: 3585,  CLOSED_CUM: 9,    REMAIN_CUM: 4441,  TOTAL: 4450,  PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 9,     PL_REMAIN_CUM: 4440,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-12-15', CREATED_WK: 185,  CLOSED_WK: 5,    CREATED_CUM: 3770,  CLOSED_CUM: 14,   REMAIN_CUM: 4436,  TOTAL: 4450,  PL_CLOSED_WK: 5,    PL_CLOSED_CUM: 14,    PL_REMAIN_CUM: 4435,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-12-22', CREATED_WK: 100,  CLOSED_WK: 6,    CREATED_CUM: 3870,  CLOSED_CUM: 20,   REMAIN_CUM: 4430,  TOTAL: 4450,  PL_CLOSED_WK: 6,    PL_CLOSED_CUM: 20,    PL_REMAIN_CUM: 4429,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2023-12-29', CREATED_WK: 368,  CLOSED_WK: 13,   CREATED_CUM: 4238,  CLOSED_CUM: 33,   REMAIN_CUM: 4417,  TOTAL: 4450,  PL_CLOSED_WK: 13,   PL_CLOSED_CUM: 33,    PL_REMAIN_CUM: 4416,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-01-05', CREATED_WK: 0,    CLOSED_WK: 3,    CREATED_CUM: 4238,  CLOSED_CUM: 36,   REMAIN_CUM: 4414,  TOTAL: 4450,  PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 36,    PL_REMAIN_CUM: 4413,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-01-12', CREATED_WK: 17,   CLOSED_WK: 13,   CREATED_CUM: 4255,  CLOSED_CUM: 49,   REMAIN_CUM: 4401,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 36,    PL_REMAIN_CUM: 4413,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-01-19', CREATED_WK: 44,   CLOSED_WK: 4,    CREATED_CUM: 4299,  CLOSED_CUM: 53,   REMAIN_CUM: 4397,  TOTAL: 4450,  PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 37,    PL_REMAIN_CUM: 4412,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-01-26', CREATED_WK: 27,   CLOSED_WK: 16,   CREATED_CUM: 4326,  CLOSED_CUM: 69,   REMAIN_CUM: 4381,  TOTAL: 4450,  PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 4410,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-02-02', CREATED_WK: 22,   CLOSED_WK: 100,  CREATED_CUM: 4348,  CLOSED_CUM: 169,  REMAIN_CUM: 4281,  TOTAL: 4450,  PL_CLOSED_WK: 21,   PL_CLOSED_CUM: 61,    PL_REMAIN_CUM: 4388,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-02-09', CREATED_WK: 102,  CLOSED_WK: 57,   CREATED_CUM: 4450,  CLOSED_CUM: 226,  REMAIN_CUM: 4224,  TOTAL: 4450,  PL_CLOSED_WK: 23,   PL_CLOSED_CUM: 85,    PL_REMAIN_CUM: 4364,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-02-16', CREATED_WK: 0,    CLOSED_WK: 19,   CREATED_CUM: 4450,  CLOSED_CUM: 245,  REMAIN_CUM: 4205,  TOTAL: 4450,  PL_CLOSED_WK: 28,   PL_CLOSED_CUM: 113,   PL_REMAIN_CUM: 4336,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-02-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 10,   PL_CLOSED_CUM: 123,   PL_REMAIN_CUM: 4326,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-03-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 26,   PL_CLOSED_CUM: 149,   PL_REMAIN_CUM: 4299,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-03-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 38,   PL_CLOSED_CUM: 188,   PL_REMAIN_CUM: 4261,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-03-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 63,   PL_CLOSED_CUM: 252,   PL_REMAIN_CUM: 4197,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-03-22', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 75,   PL_CLOSED_CUM: 327,   PL_REMAIN_CUM: 4122,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-03-29', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 76,   PL_CLOSED_CUM: 404,   PL_REMAIN_CUM: 4045,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-04-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 116,  PL_CLOSED_CUM: 520,   PL_REMAIN_CUM: 3928,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-04-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 255,  PL_CLOSED_CUM: 776,   PL_REMAIN_CUM: 3673,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-04-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 146,  PL_CLOSED_CUM: 923,   PL_REMAIN_CUM: 3526,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-04-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 274,  PL_CLOSED_CUM: 1197,  PL_REMAIN_CUM: 3252,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-05-03', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 476,  PL_CLOSED_CUM: 1673,  PL_REMAIN_CUM: 2776,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-05-10', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 473,  PL_CLOSED_CUM: 2147,  PL_REMAIN_CUM: 2302,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-05-17', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 362,  PL_CLOSED_CUM: 2509,  PL_REMAIN_CUM: 1940,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-05-24', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 302,  PL_CLOSED_CUM: 2812,  PL_REMAIN_CUM: 1637,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-05-31', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 381,  PL_CLOSED_CUM: 3193,  PL_REMAIN_CUM: 1256,  PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-06-07', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 358,  PL_CLOSED_CUM: 3552,  PL_REMAIN_CUM: 897,   PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-06-14', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 276,  PL_CLOSED_CUM: 3828,  PL_REMAIN_CUM: 621,   PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-06-21', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 189,  PL_CLOSED_CUM: 4017,  PL_REMAIN_CUM: 432,   PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-06-28', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 153,  PL_CLOSED_CUM: 4171,  PL_REMAIN_CUM: 278,   PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-07-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 133,  PL_CLOSED_CUM: 4304,  PL_REMAIN_CUM: 144,   PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-07-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 70,   PL_CLOSED_CUM: 4375,  PL_REMAIN_CUM: 74,    PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-07-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 29,   PL_CLOSED_CUM: 4404,  PL_REMAIN_CUM: 45,    PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-07-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 16,   PL_CLOSED_CUM: 4421,  PL_REMAIN_CUM: 28,    PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-08-02', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 28,   PL_CLOSED_CUM: 4449,  PL_REMAIN_CUM: 0,     PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-08-09', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4449,  PL_REMAIN_CUM: 0,     PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-08-16', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4449,  PL_REMAIN_CUM: 0,     PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-08-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4449,  PL_REMAIN_CUM: 0,     PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-08-30', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4449,  PL_REMAIN_CUM: 0,     PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-09-06', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4449,  PL_REMAIN_CUM: 0,     PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-09-13', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4449,  PL_REMAIN_CUM: 0,     PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-09-20', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4449,  PL_REMAIN_CUM: 0,     PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-09-27', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4449,  PL_REMAIN_CUM: 0,     PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-10-04', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4449,  PL_REMAIN_CUM: 0,     PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-10-11', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4449,  PL_REMAIN_CUM: 0,     PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-10-18', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4449,  PL_REMAIN_CUM: 0,     PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-10-25', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4449,  PL_REMAIN_CUM: 0,     PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-11-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4449,  PL_REMAIN_CUM: 0,     PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-11-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4449,  PL_REMAIN_CUM: 0,     PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             CDATE: '2024-11-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 4450,  PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 4449,  PL_REMAIN_CUM: 0,     PL_TOTAL: 4449, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-07-07', CREATED_WK: 89,   CLOSED_WK: 140,  CREATED_CUM: 7112,  CLOSED_CUM: 2794, REMAIN_CUM: 7966,  TOTAL: 10760, PL_CLOSED_WK: 140,  PL_CLOSED_CUM: 2794,  PL_REMAIN_CUM: 7965,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-07-14', CREATED_WK: 31,   CLOSED_WK: 112,  CREATED_CUM: 7143,  CLOSED_CUM: 2906, REMAIN_CUM: 7854,  TOTAL: 10760, PL_CLOSED_WK: 112,  PL_CLOSED_CUM: 2906,  PL_REMAIN_CUM: 7853,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-07-21', CREATED_WK: 81,   CLOSED_WK: 95,   CREATED_CUM: 7224,  CLOSED_CUM: 3001, REMAIN_CUM: 7759,  TOTAL: 10760, PL_CLOSED_WK: 95,   PL_CLOSED_CUM: 3001,  PL_REMAIN_CUM: 7758,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-07-28', CREATED_WK: 106,  CLOSED_WK: 86,   CREATED_CUM: 7330,  CLOSED_CUM: 3087, REMAIN_CUM: 7673,  TOTAL: 10760, PL_CLOSED_WK: 86,   PL_CLOSED_CUM: 3087,  PL_REMAIN_CUM: 7672,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-08-04', CREATED_WK: 38,   CLOSED_WK: 113,  CREATED_CUM: 7368,  CLOSED_CUM: 3200, REMAIN_CUM: 7560,  TOTAL: 10760, PL_CLOSED_WK: 113,  PL_CLOSED_CUM: 3200,  PL_REMAIN_CUM: 7559,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-08-11', CREATED_WK: 151,  CLOSED_WK: 95,   CREATED_CUM: 7519,  CLOSED_CUM: 3295, REMAIN_CUM: 7465,  TOTAL: 10760, PL_CLOSED_WK: 95,   PL_CLOSED_CUM: 3295,  PL_REMAIN_CUM: 7464,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 56,   CREATED_CUM: 7519,  CLOSED_CUM: 3351, REMAIN_CUM: 7409,  TOTAL: 10760, PL_CLOSED_WK: 56,   PL_CLOSED_CUM: 3351,  PL_REMAIN_CUM: 7408,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-08-25', CREATED_WK: 285,  CLOSED_WK: 58,   CREATED_CUM: 7804,  CLOSED_CUM: 3409, REMAIN_CUM: 7351,  TOTAL: 10760, PL_CLOSED_WK: 58,   PL_CLOSED_CUM: 3409,  PL_REMAIN_CUM: 7350,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-09-01', CREATED_WK: 139,  CLOSED_WK: 83,   CREATED_CUM: 7943,  CLOSED_CUM: 3492, REMAIN_CUM: 7268,  TOTAL: 10760, PL_CLOSED_WK: 83,   PL_CLOSED_CUM: 3492,  PL_REMAIN_CUM: 7267,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-09-08', CREATED_WK: 231,  CLOSED_WK: 129,  CREATED_CUM: 8174,  CLOSED_CUM: 3621, REMAIN_CUM: 7139,  TOTAL: 10760, PL_CLOSED_WK: 129,  PL_CLOSED_CUM: 3621,  PL_REMAIN_CUM: 7138,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-09-15', CREATED_WK: 138,  CLOSED_WK: 47,   CREATED_CUM: 8312,  CLOSED_CUM: 3668, REMAIN_CUM: 7092,  TOTAL: 10760, PL_CLOSED_WK: 47,   PL_CLOSED_CUM: 3668,  PL_REMAIN_CUM: 7091,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-09-22', CREATED_WK: 136,  CLOSED_WK: 52,   CREATED_CUM: 8448,  CLOSED_CUM: 3720, REMAIN_CUM: 7040,  TOTAL: 10760, PL_CLOSED_WK: 52,   PL_CLOSED_CUM: 3720,  PL_REMAIN_CUM: 7039,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-09-29', CREATED_WK: 142,  CLOSED_WK: 74,   CREATED_CUM: 8590,  CLOSED_CUM: 3794, REMAIN_CUM: 6966,  TOTAL: 10760, PL_CLOSED_WK: 74,   PL_CLOSED_CUM: 3794,  PL_REMAIN_CUM: 6965,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-10-06', CREATED_WK: 121,  CLOSED_WK: 65,   CREATED_CUM: 8711,  CLOSED_CUM: 3859, REMAIN_CUM: 6901,  TOTAL: 10760, PL_CLOSED_WK: 65,   PL_CLOSED_CUM: 3859,  PL_REMAIN_CUM: 6900,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-10-13', CREATED_WK: 174,  CLOSED_WK: 58,   CREATED_CUM: 8885,  CLOSED_CUM: 3917, REMAIN_CUM: 6843,  TOTAL: 10760, PL_CLOSED_WK: 58,   PL_CLOSED_CUM: 3917,  PL_REMAIN_CUM: 6842,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-10-20', CREATED_WK: 140,  CLOSED_WK: 89,   CREATED_CUM: 9025,  CLOSED_CUM: 4006, REMAIN_CUM: 6754,  TOTAL: 10760, PL_CLOSED_WK: 89,   PL_CLOSED_CUM: 4006,  PL_REMAIN_CUM: 6753,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-10-27', CREATED_WK: 166,  CLOSED_WK: 94,   CREATED_CUM: 9191,  CLOSED_CUM: 4100, REMAIN_CUM: 6660,  TOTAL: 10760, PL_CLOSED_WK: 94,   PL_CLOSED_CUM: 4100,  PL_REMAIN_CUM: 6659,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-11-03', CREATED_WK: 83,   CLOSED_WK: 129,  CREATED_CUM: 9274,  CLOSED_CUM: 4229, REMAIN_CUM: 6531,  TOTAL: 10760, PL_CLOSED_WK: 129,  PL_CLOSED_CUM: 4229,  PL_REMAIN_CUM: 6530,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-11-10', CREATED_WK: 133,  CLOSED_WK: 120,  CREATED_CUM: 9407,  CLOSED_CUM: 4349, REMAIN_CUM: 6411,  TOTAL: 10760, PL_CLOSED_WK: 120,  PL_CLOSED_CUM: 4349,  PL_REMAIN_CUM: 6410,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-11-17', CREATED_WK: 80,   CLOSED_WK: 66,   CREATED_CUM: 9487,  CLOSED_CUM: 4415, REMAIN_CUM: 6345,  TOTAL: 10760, PL_CLOSED_WK: 66,   PL_CLOSED_CUM: 4415,  PL_REMAIN_CUM: 6344,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-11-24', CREATED_WK: 119,  CLOSED_WK: 91,   CREATED_CUM: 9606,  CLOSED_CUM: 4506, REMAIN_CUM: 6254,  TOTAL: 10760, PL_CLOSED_WK: 91,   PL_CLOSED_CUM: 4506,  PL_REMAIN_CUM: 6253,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-12-01', CREATED_WK: 13,   CLOSED_WK: 115,  CREATED_CUM: 9619,  CLOSED_CUM: 4621, REMAIN_CUM: 6139,  TOTAL: 10760, PL_CLOSED_WK: 115,  PL_CLOSED_CUM: 4621,  PL_REMAIN_CUM: 6138,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-12-08', CREATED_WK: 94,   CLOSED_WK: 123,  CREATED_CUM: 9713,  CLOSED_CUM: 4744, REMAIN_CUM: 6016,  TOTAL: 10760, PL_CLOSED_WK: 123,  PL_CLOSED_CUM: 4744,  PL_REMAIN_CUM: 6015,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-12-15', CREATED_WK: 189,  CLOSED_WK: 120,  CREATED_CUM: 9902,  CLOSED_CUM: 4864, REMAIN_CUM: 5896,  TOTAL: 10760, PL_CLOSED_WK: 120,  PL_CLOSED_CUM: 4864,  PL_REMAIN_CUM: 5895,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-12-22', CREATED_WK: 113,  CLOSED_WK: 115,  CREATED_CUM: 10015, CLOSED_CUM: 4979, REMAIN_CUM: 5781,  TOTAL: 10760, PL_CLOSED_WK: 115,  PL_CLOSED_CUM: 4979,  PL_REMAIN_CUM: 5780,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2023-12-29', CREATED_WK: 382,  CLOSED_WK: 143,  CREATED_CUM: 10397, CLOSED_CUM: 5122, REMAIN_CUM: 5638,  TOTAL: 10760, PL_CLOSED_WK: 143,  PL_CLOSED_CUM: 5122,  PL_REMAIN_CUM: 5637,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-01-05', CREATED_WK: 0,    CLOSED_WK: 22,   CREATED_CUM: 10397, CLOSED_CUM: 5144, REMAIN_CUM: 5616,  TOTAL: 10760, PL_CLOSED_WK: 22,   PL_CLOSED_CUM: 5144,  PL_REMAIN_CUM: 5615,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-01-12', CREATED_WK: 18,   CLOSED_WK: 17,   CREATED_CUM: 10415, CLOSED_CUM: 5161, REMAIN_CUM: 5599,  TOTAL: 10760, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 5144,  PL_REMAIN_CUM: 5615,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-01-19', CREATED_WK: 65,   CLOSED_WK: 8,    CREATED_CUM: 10480, CLOSED_CUM: 5169, REMAIN_CUM: 5591,  TOTAL: 10760, PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 5145,  PL_REMAIN_CUM: 5614,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-01-26', CREATED_WK: 32,   CLOSED_WK: 64,   CREATED_CUM: 10512, CLOSED_CUM: 5233, REMAIN_CUM: 5527,  TOTAL: 10760, PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 5147,  PL_REMAIN_CUM: 5612,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-02-02', CREATED_WK: 90,   CLOSED_WK: 84,   CREATED_CUM: 10602, CLOSED_CUM: 5317, REMAIN_CUM: 5443,  TOTAL: 10760, PL_CLOSED_WK: 18,   PL_CLOSED_CUM: 5165,  PL_REMAIN_CUM: 5594,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-02-09', CREATED_WK: 141,  CLOSED_WK: 79,   CREATED_CUM: 10743, CLOSED_CUM: 5396, REMAIN_CUM: 5364,  TOTAL: 10760, PL_CLOSED_WK: 9,    PL_CLOSED_CUM: 5174,  PL_REMAIN_CUM: 5585,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-02-16', CREATED_WK: 17,   CLOSED_WK: 7,    CREATED_CUM: 10760, CLOSED_CUM: 5403, REMAIN_CUM: 5357,  TOTAL: 10760, PL_CLOSED_WK: 44,   PL_CLOSED_CUM: 5219,  PL_REMAIN_CUM: 5540,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-02-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 12,   PL_CLOSED_CUM: 5232,  PL_REMAIN_CUM: 5527,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-03-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 11,   PL_CLOSED_CUM: 5243,  PL_REMAIN_CUM: 5515,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-03-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 29,   PL_CLOSED_CUM: 5273,  PL_REMAIN_CUM: 5486,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-03-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 40,   PL_CLOSED_CUM: 5313,  PL_REMAIN_CUM: 5446,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-03-22', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 76,   PL_CLOSED_CUM: 5390,  PL_REMAIN_CUM: 5369,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-03-29', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 57,   PL_CLOSED_CUM: 5447,  PL_REMAIN_CUM: 5312,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-04-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 115,  PL_CLOSED_CUM: 5563,  PL_REMAIN_CUM: 5196,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-04-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 146,  PL_CLOSED_CUM: 5709,  PL_REMAIN_CUM: 5049,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-04-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 215,  PL_CLOSED_CUM: 5925,  PL_REMAIN_CUM: 4834,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-04-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 247,  PL_CLOSED_CUM: 6173,  PL_REMAIN_CUM: 4586,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-05-03', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 299,  PL_CLOSED_CUM: 6473,  PL_REMAIN_CUM: 4286,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-05-10', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 529,  PL_CLOSED_CUM: 7002,  PL_REMAIN_CUM: 3756,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-05-17', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 467,  PL_CLOSED_CUM: 7470,  PL_REMAIN_CUM: 3289,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-05-24', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 410,  PL_CLOSED_CUM: 7880,  PL_REMAIN_CUM: 2878,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-05-31', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 437,  PL_CLOSED_CUM: 8318,  PL_REMAIN_CUM: 2441,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-06-07', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 452,  PL_CLOSED_CUM: 8770,  PL_REMAIN_CUM: 1989,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-06-14', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 475,  PL_CLOSED_CUM: 9246,  PL_REMAIN_CUM: 1513,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-06-21', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 229,  PL_CLOSED_CUM: 9475,  PL_REMAIN_CUM: 1284,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-06-28', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 256,  PL_CLOSED_CUM: 9732,  PL_REMAIN_CUM: 1027,  PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-07-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 300,  PL_CLOSED_CUM: 10032, PL_REMAIN_CUM: 727,   PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-07-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 248,  PL_CLOSED_CUM: 10280, PL_REMAIN_CUM: 479,   PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-07-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 203,  PL_CLOSED_CUM: 10483, PL_REMAIN_CUM: 276,   PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-07-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 89,   PL_CLOSED_CUM: 10572, PL_REMAIN_CUM: 186,   PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-08-02', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 69,   PL_CLOSED_CUM: 10642, PL_REMAIN_CUM: 117,   PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-08-09', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 37,   PL_CLOSED_CUM: 10679, PL_REMAIN_CUM: 80,    PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-08-16', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 23,   PL_CLOSED_CUM: 10703, PL_REMAIN_CUM: 56,    PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-08-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 4,    PL_CLOSED_CUM: 10707, PL_REMAIN_CUM: 52,    PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-08-30', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 10707, PL_REMAIN_CUM: 52,    PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-09-06', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 10707, PL_REMAIN_CUM: 52,    PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-09-13', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 10707, PL_REMAIN_CUM: 52,    PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-09-20', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 10707, PL_REMAIN_CUM: 52,    PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-09-27', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 10707, PL_REMAIN_CUM: 52,    PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-10-04', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 10707, PL_REMAIN_CUM: 52,    PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-10-11', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 10707, PL_REMAIN_CUM: 52,    PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-10-18', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 10707, PL_REMAIN_CUM: 52,    PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-10-25', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 10707, PL_REMAIN_CUM: 52,    PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-11-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 10707, PL_REMAIN_CUM: 52,    PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-11-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 35,   PL_CLOSED_CUM: 10742, PL_REMAIN_CUM: 17,    PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  CDATE: '2024-11-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 10760, PL_CLOSED_WK: 17,   PL_CLOSED_CUM: 10759, PL_REMAIN_CUM: 0,     PL_TOTAL: 10759, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-07-07', CREATED_WK: 0,    CLOSED_WK: 1,    CREATED_CUM: 389,   CLOSED_CUM: 28,   REMAIN_CUM: 420,   TOTAL: 448,   PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 28,    PL_REMAIN_CUM: 420,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-07-14', CREATED_WK: 38,   CLOSED_WK: 11,   CREATED_CUM: 427,   CLOSED_CUM: 39,   REMAIN_CUM: 409,   TOTAL: 448,   PL_CLOSED_WK: 11,   PL_CLOSED_CUM: 39,    PL_REMAIN_CUM: 409,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-07-21', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 427,   CLOSED_CUM: 41,   REMAIN_CUM: 407,   TOTAL: 448,   PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 41,    PL_REMAIN_CUM: 407,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-07-28', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 427,   CLOSED_CUM: 41,   REMAIN_CUM: 407,   TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 41,    PL_REMAIN_CUM: 407,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 427,   CLOSED_CUM: 41,   REMAIN_CUM: 407,   TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 41,    PL_REMAIN_CUM: 407,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 1,    CREATED_CUM: 427,   CLOSED_CUM: 42,   REMAIN_CUM: 406,   TOTAL: 448,   PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 42,    PL_REMAIN_CUM: 406,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 427,   CLOSED_CUM: 42,   REMAIN_CUM: 406,   TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 42,    PL_REMAIN_CUM: 406,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-08-25', CREATED_WK: 0,    CLOSED_WK: 3,    CREATED_CUM: 427,   CLOSED_CUM: 45,   REMAIN_CUM: 403,   TOTAL: 448,   PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 45,    PL_REMAIN_CUM: 403,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-09-01', CREATED_WK: 0,    CLOSED_WK: 6,    CREATED_CUM: 427,   CLOSED_CUM: 51,   REMAIN_CUM: 397,   TOTAL: 448,   PL_CLOSED_WK: 6,    PL_CLOSED_CUM: 51,    PL_REMAIN_CUM: 397,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-09-08', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 427,   CLOSED_CUM: 53,   REMAIN_CUM: 395,   TOTAL: 448,   PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 53,    PL_REMAIN_CUM: 395,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-09-15', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 427,   CLOSED_CUM: 53,   REMAIN_CUM: 395,   TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 53,    PL_REMAIN_CUM: 395,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-09-22', CREATED_WK: 11,   CLOSED_WK: 0,    CREATED_CUM: 438,   CLOSED_CUM: 53,   REMAIN_CUM: 395,   TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 53,    PL_REMAIN_CUM: 395,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-09-29', CREATED_WK: 0,    CLOSED_WK: 3,    CREATED_CUM: 438,   CLOSED_CUM: 56,   REMAIN_CUM: 392,   TOTAL: 448,   PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 56,    PL_REMAIN_CUM: 392,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 438,   CLOSED_CUM: 58,   REMAIN_CUM: 390,   TOTAL: 448,   PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 58,    PL_REMAIN_CUM: 390,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-10-13', CREATED_WK: 3,    CLOSED_WK: 7,    CREATED_CUM: 441,   CLOSED_CUM: 65,   REMAIN_CUM: 383,   TOTAL: 448,   PL_CLOSED_WK: 7,    PL_CLOSED_CUM: 65,    PL_REMAIN_CUM: 383,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-10-20', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 441,   CLOSED_CUM: 65,   REMAIN_CUM: 383,   TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 65,    PL_REMAIN_CUM: 383,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-10-27', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 441,   CLOSED_CUM: 67,   REMAIN_CUM: 381,   TOTAL: 448,   PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 67,    PL_REMAIN_CUM: 381,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-11-03', CREATED_WK: 1,    CLOSED_WK: 8,    CREATED_CUM: 442,   CLOSED_CUM: 75,   REMAIN_CUM: 373,   TOTAL: 448,   PL_CLOSED_WK: 8,    PL_CLOSED_CUM: 75,    PL_REMAIN_CUM: 373,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-11-10', CREATED_WK: 1,    CLOSED_WK: 11,   CREATED_CUM: 443,   CLOSED_CUM: 86,   REMAIN_CUM: 362,   TOTAL: 448,   PL_CLOSED_WK: 11,   PL_CLOSED_CUM: 86,    PL_REMAIN_CUM: 362,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 6,    CREATED_CUM: 443,   CLOSED_CUM: 92,   REMAIN_CUM: 356,   TOTAL: 448,   PL_CLOSED_WK: 6,    PL_CLOSED_CUM: 92,    PL_REMAIN_CUM: 356,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-11-24', CREATED_WK: 1,    CLOSED_WK: 0,    CREATED_CUM: 444,   CLOSED_CUM: 92,   REMAIN_CUM: 356,   TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 92,    PL_REMAIN_CUM: 356,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-12-01', CREATED_WK: 0,    CLOSED_WK: 3,    CREATED_CUM: 444,   CLOSED_CUM: 95,   REMAIN_CUM: 353,   TOTAL: 448,   PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 95,    PL_REMAIN_CUM: 353,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 444,   CLOSED_CUM: 95,   REMAIN_CUM: 353,   TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 95,    PL_REMAIN_CUM: 353,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-12-15', CREATED_WK: 1,    CLOSED_WK: 16,   CREATED_CUM: 445,   CLOSED_CUM: 111,  REMAIN_CUM: 337,   TOTAL: 448,   PL_CLOSED_WK: 16,   PL_CLOSED_CUM: 111,   PL_REMAIN_CUM: 337,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-12-22', CREATED_WK: 0,    CLOSED_WK: 9,    CREATED_CUM: 445,   CLOSED_CUM: 120,  REMAIN_CUM: 328,   TOTAL: 448,   PL_CLOSED_WK: 9,    PL_CLOSED_CUM: 120,   PL_REMAIN_CUM: 328,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 36,   CREATED_CUM: 445,   CLOSED_CUM: 156,  REMAIN_CUM: 292,   TOTAL: 448,   PL_CLOSED_WK: 36,   PL_CLOSED_CUM: 156,   PL_REMAIN_CUM: 292,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-01-05', CREATED_WK: 0,    CLOSED_WK: 3,    CREATED_CUM: 445,   CLOSED_CUM: 159,  REMAIN_CUM: 289,   TOTAL: 448,   PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 159,   PL_REMAIN_CUM: 289,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-01-12', CREATED_WK: 0,    CLOSED_WK: 4,    CREATED_CUM: 445,   CLOSED_CUM: 163,  REMAIN_CUM: 285,   TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 159,   PL_REMAIN_CUM: 289,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-01-19', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 445,   CLOSED_CUM: 163,  REMAIN_CUM: 285,   TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 159,   PL_REMAIN_CUM: 289,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 445,   CLOSED_CUM: 163,  REMAIN_CUM: 285,   TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 159,   PL_REMAIN_CUM: 289,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-02-02', CREATED_WK: 0,    CLOSED_WK: 6,    CREATED_CUM: 445,   CLOSED_CUM: 169,  REMAIN_CUM: 279,   TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 159,   PL_REMAIN_CUM: 289,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-02-09', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 445,   CLOSED_CUM: 169,  REMAIN_CUM: 279,   TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 159,   PL_REMAIN_CUM: 289,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-02-16', CREATED_WK: 3,    CLOSED_WK: 0,    CREATED_CUM: 448,   CLOSED_CUM: 169,  REMAIN_CUM: 279,   TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 159,   PL_REMAIN_CUM: 289,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-02-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 159,   PL_REMAIN_CUM: 288,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-03-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 159,   PL_REMAIN_CUM: 288,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-03-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 163,   PL_REMAIN_CUM: 284,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-03-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 5,    PL_CLOSED_CUM: 168,   PL_REMAIN_CUM: 279,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-03-22', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 168,   PL_REMAIN_CUM: 279,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-03-29', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 169,   PL_REMAIN_CUM: 278,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-04-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 169,   PL_REMAIN_CUM: 278,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-04-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 4,    PL_CLOSED_CUM: 174,   PL_REMAIN_CUM: 273,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-04-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 177,   PL_REMAIN_CUM: 271,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-04-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 178,   PL_REMAIN_CUM: 269,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-05-03', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 4,    PL_CLOSED_CUM: 182,   PL_REMAIN_CUM: 265,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-05-10', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 6,    PL_CLOSED_CUM: 189,   PL_REMAIN_CUM: 258,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-05-17', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 16,   PL_CLOSED_CUM: 206,   PL_REMAIN_CUM: 241,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-05-24', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 209,   PL_REMAIN_CUM: 238,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-05-31', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 25,   PL_CLOSED_CUM: 235,   PL_REMAIN_CUM: 212,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-06-07', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 28,   PL_CLOSED_CUM: 264,   PL_REMAIN_CUM: 183,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-06-14', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 24,   PL_CLOSED_CUM: 288,   PL_REMAIN_CUM: 159,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-06-21', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 14,   PL_CLOSED_CUM: 302,   PL_REMAIN_CUM: 145,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-06-28', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 13,   PL_CLOSED_CUM: 315,   PL_REMAIN_CUM: 132,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-07-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 22,   PL_CLOSED_CUM: 338,   PL_REMAIN_CUM: 109,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-07-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 6,    PL_CLOSED_CUM: 345,   PL_REMAIN_CUM: 102,   PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-07-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 12,   PL_CLOSED_CUM: 358,   PL_REMAIN_CUM: 89,    PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-07-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 17,   PL_CLOSED_CUM: 375,   PL_REMAIN_CUM: 72,    PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-08-02', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 13,   PL_CLOSED_CUM: 388,   PL_REMAIN_CUM: 59,    PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-08-09', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 11,   PL_CLOSED_CUM: 399,   PL_REMAIN_CUM: 48,    PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-08-16', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 10,   PL_CLOSED_CUM: 410,   PL_REMAIN_CUM: 38,    PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-08-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 7,    PL_CLOSED_CUM: 417,   PL_REMAIN_CUM: 30,    PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-08-30', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 421,   PL_REMAIN_CUM: 27,    PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-09-06', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 421,   PL_REMAIN_CUM: 26,    PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-09-13', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 9,    PL_CLOSED_CUM: 430,   PL_REMAIN_CUM: 17,    PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-09-20', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 431,   PL_REMAIN_CUM: 17,    PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-09-27', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 431,   PL_REMAIN_CUM: 17,    PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-10-04', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 431,   PL_REMAIN_CUM: 17,    PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-10-11', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 431,   PL_REMAIN_CUM: 17,    PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-10-18', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 431,   PL_REMAIN_CUM: 17,    PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-10-25', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 431,   PL_REMAIN_CUM: 17,    PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-11-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 17,   PL_CLOSED_CUM: 448,   PL_REMAIN_CUM: 0,     PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-11-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 448,   PL_REMAIN_CUM: 0,     PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       CDATE: '2024-11-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 448,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 448,   PL_REMAIN_CUM: 0,     PL_TOTAL: 448, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-07-07', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 577,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-07-14', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 577,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-07-21', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 577,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-07-28', CREATED_WK: 14,   CLOSED_WK: 0,    CREATED_CUM: 591,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 591,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 591,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 591,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-08-25', CREATED_WK: 2,    CLOSED_WK: 0,    CREATED_CUM: 593,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-09-01', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 593,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-09-08', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 593,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-09-15', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 593,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-09-22', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 593,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-09-29', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 593,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 593,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-10-13', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 593,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-10-20', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 593,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-10-27', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 593,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-11-03', CREATED_WK: 2,    CLOSED_WK: 0,    CREATED_CUM: 595,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-11-10', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 595,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 595,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-11-24', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 595,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-12-01', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 595,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 595,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-12-15', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 595,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-12-22', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 595,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 595,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-01-05', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 595,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-01-12', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 595,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-01-19', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 595,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 595,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-02-02', CREATED_WK: 4,    CLOSED_WK: 0,    CREATED_CUM: 599,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-02-09', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 599,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-02-16', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 599,   CLOSED_CUM: 0,    REMAIN_CUM: 599,   TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-02-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-03-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-03-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-03-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-03-22', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-03-29', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 598,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-04-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 4,    PL_CLOSED_CUM: 4,     PL_REMAIN_CUM: 594,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-04-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 8,    PL_CLOSED_CUM: 12,    PL_REMAIN_CUM: 586,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-04-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 16,    PL_REMAIN_CUM: 582,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-04-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 22,   PL_CLOSED_CUM: 38,    PL_REMAIN_CUM: 560,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-05-03', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 17,   PL_CLOSED_CUM: 56,    PL_REMAIN_CUM: 542,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-05-10', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 26,   PL_CLOSED_CUM: 83,    PL_REMAIN_CUM: 515,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-05-17', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 14,   PL_CLOSED_CUM: 97,    PL_REMAIN_CUM: 501,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-05-24', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 15,   PL_CLOSED_CUM: 112,   PL_REMAIN_CUM: 486,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-05-31', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 61,   PL_CLOSED_CUM: 174,   PL_REMAIN_CUM: 424,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-06-07', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 65,   PL_CLOSED_CUM: 239,   PL_REMAIN_CUM: 359,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-06-14', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 30,   PL_CLOSED_CUM: 270,   PL_REMAIN_CUM: 328,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-06-21', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 33,   PL_CLOSED_CUM: 303,   PL_REMAIN_CUM: 295,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-06-28', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 41,   PL_CLOSED_CUM: 344,   PL_REMAIN_CUM: 254,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-07-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 20,   PL_CLOSED_CUM: 364,   PL_REMAIN_CUM: 234,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-07-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 13,   PL_CLOSED_CUM: 378,   PL_REMAIN_CUM: 220,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-07-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 51,   PL_CLOSED_CUM: 429,   PL_REMAIN_CUM: 169,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-07-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 63,   PL_CLOSED_CUM: 493,   PL_REMAIN_CUM: 105,   PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-08-02', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 77,   PL_CLOSED_CUM: 570,   PL_REMAIN_CUM: 28,    PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-08-09', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 18,   PL_CLOSED_CUM: 589,   PL_REMAIN_CUM: 9,     PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-08-16', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 593,   PL_REMAIN_CUM: 5,     PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-08-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 593,   PL_REMAIN_CUM: 5,     PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-08-30', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 596,   PL_REMAIN_CUM: 2,     PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-09-06', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 597,   PL_REMAIN_CUM: 1,     PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-09-13', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 597,   PL_REMAIN_CUM: 1,     PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-09-20', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 597,   PL_REMAIN_CUM: 1,     PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-09-27', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 597,   PL_REMAIN_CUM: 1,     PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-10-04', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 597,   PL_REMAIN_CUM: 1,     PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-10-11', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 597,   PL_REMAIN_CUM: 1,     PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-10-18', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 597,   PL_REMAIN_CUM: 1,     PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-10-25', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 597,   PL_REMAIN_CUM: 1,     PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-11-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 597,   PL_REMAIN_CUM: 1,     PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-11-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 598,   PL_REMAIN_CUM: 0,     PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   CDATE: '2024-11-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 599,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 598,   PL_REMAIN_CUM: 0,     PL_TOTAL: 598, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-07-07', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-07-14', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-07-21', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-07-28', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-08-25', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-09-01', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-09-08', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-09-15', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-09-22', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-09-29', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-10-13', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-10-20', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-10-27', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-11-03', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-11-10', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-11-24', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-12-01', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-12-15', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-12-22', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-01-05', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-01-12', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-01-19', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-02-02', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-02-09', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-02-16', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 102,   CLOSED_CUM: 0,    REMAIN_CUM: 102,   TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-02-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-03-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-03-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-03-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-03-22', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-03-29', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-04-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-04-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-04-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-04-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-05-03', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-05-10', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-05-17', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-05-24', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 0,     PL_REMAIN_CUM: 102,   PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-05-31', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 9,    PL_CLOSED_CUM: 9,     PL_REMAIN_CUM: 93,    PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-06-07', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 8,    PL_CLOSED_CUM: 17,    PL_REMAIN_CUM: 84,    PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-06-14', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 7,    PL_CLOSED_CUM: 25,    PL_REMAIN_CUM: 77,    PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-06-21', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 7,    PL_CLOSED_CUM: 32,    PL_REMAIN_CUM: 69,    PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-06-28', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 9,    PL_CLOSED_CUM: 42,    PL_REMAIN_CUM: 60,    PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-07-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 12,   PL_CLOSED_CUM: 54,    PL_REMAIN_CUM: 47,    PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-07-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 57,    PL_REMAIN_CUM: 44,    PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-07-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 8,    PL_CLOSED_CUM: 65,    PL_REMAIN_CUM: 36,    PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-07-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 4,    PL_CLOSED_CUM: 70,    PL_REMAIN_CUM: 31,    PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-08-02', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 71,    PL_REMAIN_CUM: 30,    PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-08-09', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 74,    PL_REMAIN_CUM: 27,    PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-08-16', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 10,   PL_CLOSED_CUM: 84,    PL_REMAIN_CUM: 17,    PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-08-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 16,   PL_CLOSED_CUM: 100,   PL_REMAIN_CUM: 1,     PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-08-30', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 1,    PL_CLOSED_CUM: 102,   PL_REMAIN_CUM: 0,     PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-09-06', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 102,   PL_REMAIN_CUM: 0,     PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-09-13', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 102,   PL_REMAIN_CUM: 0,     PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-09-20', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 102,   PL_REMAIN_CUM: 0,     PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-09-27', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 102,   PL_REMAIN_CUM: 0,     PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-10-04', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 102,   PL_REMAIN_CUM: 0,     PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-10-11', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 102,   PL_REMAIN_CUM: 0,     PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-10-18', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 102,   PL_REMAIN_CUM: 0,     PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-10-25', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 102,   PL_REMAIN_CUM: 0,     PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-11-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 102,   PL_REMAIN_CUM: 0,     PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-11-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 102,   PL_REMAIN_CUM: 0,     PL_TOTAL: 102, },
{ ITEM: 'ITR',  UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   CDATE: '2024-11-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 102,   PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 102,   PL_REMAIN_CUM: 0,     PL_TOTAL: 102, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-07', CREATED_WK: 285,  CLOSED_WK: 240,  CREATED_CUM: 28270, CLOSED_CUM: 5793, REMAIN_CUM: 30188, TOTAL: 35981, PL_CLOSED_WK: 240,  PL_CLOSED_CUM: 5793,  PL_REMAIN_CUM: 30187, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-14', CREATED_WK: 103,  CLOSED_WK: 219,  CREATED_CUM: 28373, CLOSED_CUM: 6012, REMAIN_CUM: 29969, TOTAL: 35981, PL_CLOSED_WK: 219,  PL_CLOSED_CUM: 6012,  PL_REMAIN_CUM: 29968, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-21', CREATED_WK: 170,  CLOSED_WK: 223,  CREATED_CUM: 28543, CLOSED_CUM: 6235, REMAIN_CUM: 29746, TOTAL: 35981, PL_CLOSED_WK: 223,  PL_CLOSED_CUM: 6235,  PL_REMAIN_CUM: 29745, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-28', CREATED_WK: 227,  CLOSED_WK: 195,  CREATED_CUM: 28770, CLOSED_CUM: 6430, REMAIN_CUM: 29551, TOTAL: 35981, PL_CLOSED_WK: 195,  PL_CLOSED_CUM: 6430,  PL_REMAIN_CUM: 29550, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-04', CREATED_WK: 76,   CLOSED_WK: 183,  CREATED_CUM: 28846, CLOSED_CUM: 6613, REMAIN_CUM: 29368, TOTAL: 35981, PL_CLOSED_WK: 183,  PL_CLOSED_CUM: 6613,  PL_REMAIN_CUM: 29367, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-11', CREATED_WK: 302,  CLOSED_WK: 188,  CREATED_CUM: 29148, CLOSED_CUM: 6801, REMAIN_CUM: 29180, TOTAL: 35981, PL_CLOSED_WK: 188,  PL_CLOSED_CUM: 6801,  PL_REMAIN_CUM: 29179, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 119,  CREATED_CUM: 29148, CLOSED_CUM: 6920, REMAIN_CUM: 29061, TOTAL: 35981, PL_CLOSED_WK: 119,  PL_CLOSED_CUM: 6920,  PL_REMAIN_CUM: 29060, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-25', CREATED_WK: 609,  CLOSED_WK: 167,  CREATED_CUM: 29757, CLOSED_CUM: 7087, REMAIN_CUM: 28894, TOTAL: 35981, PL_CLOSED_WK: 167,  PL_CLOSED_CUM: 7087,  PL_REMAIN_CUM: 28893, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-01', CREATED_WK: 278,  CLOSED_WK: 201,  CREATED_CUM: 30035, CLOSED_CUM: 7288, REMAIN_CUM: 28693, TOTAL: 35981, PL_CLOSED_WK: 201,  PL_CLOSED_CUM: 7288,  PL_REMAIN_CUM: 28692, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-08', CREATED_WK: 471,  CLOSED_WK: 245,  CREATED_CUM: 30506, CLOSED_CUM: 7533, REMAIN_CUM: 28448, TOTAL: 35981, PL_CLOSED_WK: 245,  PL_CLOSED_CUM: 7533,  PL_REMAIN_CUM: 28447, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-15', CREATED_WK: 282,  CLOSED_WK: 108,  CREATED_CUM: 30788, CLOSED_CUM: 7641, REMAIN_CUM: 28340, TOTAL: 35981, PL_CLOSED_WK: 108,  PL_CLOSED_CUM: 7641,  PL_REMAIN_CUM: 28339, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-22', CREATED_WK: 294,  CLOSED_WK: 140,  CREATED_CUM: 31082, CLOSED_CUM: 7781, REMAIN_CUM: 28200, TOTAL: 35981, PL_CLOSED_WK: 140,  PL_CLOSED_CUM: 7781,  PL_REMAIN_CUM: 28199, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-29', CREATED_WK: 285,  CLOSED_WK: 190,  CREATED_CUM: 31367, CLOSED_CUM: 7971, REMAIN_CUM: 28010, TOTAL: 35981, PL_CLOSED_WK: 190,  PL_CLOSED_CUM: 7971,  PL_REMAIN_CUM: 28009, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-06', CREATED_WK: 241,  CLOSED_WK: 142,  CREATED_CUM: 31608, CLOSED_CUM: 8113, REMAIN_CUM: 27868, TOTAL: 35981, PL_CLOSED_WK: 142,  PL_CLOSED_CUM: 8113,  PL_REMAIN_CUM: 27867, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-13', CREATED_WK: 357,  CLOSED_WK: 157,  CREATED_CUM: 31965, CLOSED_CUM: 8270, REMAIN_CUM: 27711, TOTAL: 35981, PL_CLOSED_WK: 157,  PL_CLOSED_CUM: 8270,  PL_REMAIN_CUM: 27710, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-20', CREATED_WK: 362,  CLOSED_WK: 196,  CREATED_CUM: 32327, CLOSED_CUM: 8466, REMAIN_CUM: 27515, TOTAL: 35981, PL_CLOSED_WK: 196,  PL_CLOSED_CUM: 8466,  PL_REMAIN_CUM: 27514, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-27', CREATED_WK: 332,  CLOSED_WK: 217,  CREATED_CUM: 32659, CLOSED_CUM: 8683, REMAIN_CUM: 27298, TOTAL: 35981, PL_CLOSED_WK: 217,  PL_CLOSED_CUM: 8683,  PL_REMAIN_CUM: 27297, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-03', CREATED_WK: 183,  CLOSED_WK: 307,  CREATED_CUM: 32842, CLOSED_CUM: 8990, REMAIN_CUM: 26991, TOTAL: 35981, PL_CLOSED_WK: 307,  PL_CLOSED_CUM: 8990,  PL_REMAIN_CUM: 26990, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-10', CREATED_WK: 279,  CLOSED_WK: 292,  CREATED_CUM: 33121, CLOSED_CUM: 9282, REMAIN_CUM: 26699, TOTAL: 35981, PL_CLOSED_WK: 292,  PL_CLOSED_CUM: 9282,  PL_REMAIN_CUM: 26698, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-17', CREATED_WK: 160,  CLOSED_WK: 182,  CREATED_CUM: 33281, CLOSED_CUM: 9464, REMAIN_CUM: 26517, TOTAL: 35981, PL_CLOSED_WK: 182,  PL_CLOSED_CUM: 9464,  PL_REMAIN_CUM: 26516, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-24', CREATED_WK: 247,  CLOSED_WK: 291,  CREATED_CUM: 33528, CLOSED_CUM: 9755, REMAIN_CUM: 26226, TOTAL: 35981, PL_CLOSED_WK: 291,  PL_CLOSED_CUM: 9755,  PL_REMAIN_CUM: 26225, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-01', CREATED_WK: 41,   CLOSED_WK: 203,  CREATED_CUM: 33569, CLOSED_CUM: 9958, REMAIN_CUM: 26023, TOTAL: 35981, PL_CLOSED_WK: 203,  PL_CLOSED_CUM: 9958,  PL_REMAIN_CUM: 26022, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-08', CREATED_WK: 191,  CLOSED_WK: 296,  CREATED_CUM: 33760, CLOSED_CUM: 10254, REMAIN_CUM: 25727, TOTAL: 35981, PL_CLOSED_WK: 296,  PL_CLOSED_CUM: 10254, PL_REMAIN_CUM: 25726, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-15', CREATED_WK: 427,  CLOSED_WK: 293,  CREATED_CUM: 34187, CLOSED_CUM: 10547, REMAIN_CUM: 25434, TOTAL: 35981, PL_CLOSED_WK: 293,  PL_CLOSED_CUM: 10547, PL_REMAIN_CUM: 25433, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-22', CREATED_WK: 232,  CLOSED_WK: 251,  CREATED_CUM: 34419, CLOSED_CUM: 10798, REMAIN_CUM: 25183, TOTAL: 35981, PL_CLOSED_WK: 251,  PL_CLOSED_CUM: 10798, PL_REMAIN_CUM: 25182, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-29', CREATED_WK: 779,  CLOSED_WK: 262,  CREATED_CUM: 35198, CLOSED_CUM: 11060, REMAIN_CUM: 24921, TOTAL: 35981, PL_CLOSED_WK: 262,  PL_CLOSED_CUM: 11060, PL_REMAIN_CUM: 24920, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-05', CREATED_WK: 7,    CLOSED_WK: 60,   CREATED_CUM: 35205, CLOSED_CUM: 11120, REMAIN_CUM: 24861, TOTAL: 35981, PL_CLOSED_WK: 117,  PL_CLOSED_CUM: 11177, PL_REMAIN_CUM: 24803, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-12', CREATED_WK: 37,   CLOSED_WK: 106,  CREATED_CUM: 35242, CLOSED_CUM: 11226, REMAIN_CUM: 24755, TOTAL: 35981, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 11177, PL_REMAIN_CUM: 24803, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-19', CREATED_WK: 149,  CLOSED_WK: 85,   CREATED_CUM: 35391, CLOSED_CUM: 11311, REMAIN_CUM: 24670, TOTAL: 35981, PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 11180, PL_REMAIN_CUM: 24800, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-26', CREATED_WK: 80,   CLOSED_WK: 155,  CREATED_CUM: 35471, CLOSED_CUM: 11466, REMAIN_CUM: 24515, TOTAL: 35981, PL_CLOSED_WK: 5,    PL_CLOSED_CUM: 11185, PL_REMAIN_CUM: 24795, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-02', CREATED_WK: 190,  CLOSED_WK: 290,  CREATED_CUM: 35661, CLOSED_CUM: 11756, REMAIN_CUM: 24225, TOTAL: 35981, PL_CLOSED_WK: 54,   PL_CLOSED_CUM: 11240, PL_REMAIN_CUM: 24740, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-09', CREATED_WK: 283,  CLOSED_WK: 185,  CREATED_CUM: 35944, CLOSED_CUM: 11941, REMAIN_CUM: 24040, TOTAL: 35981, PL_CLOSED_WK: 71,   PL_CLOSED_CUM: 11312, PL_REMAIN_CUM: 24668, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-16', CREATED_WK: 37,   CLOSED_WK: 40,   CREATED_CUM: 35981, CLOSED_CUM: 11981, REMAIN_CUM: 24000, TOTAL: 35981, PL_CLOSED_WK: 95,   PL_CLOSED_CUM: 11408, PL_REMAIN_CUM: 24572, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 58,   PL_CLOSED_CUM: 11467, PL_REMAIN_CUM: 24513, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 76,   PL_CLOSED_CUM: 11544, PL_REMAIN_CUM: 24436, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 1197, PL_CLOSED_CUM: 12741, PL_REMAIN_CUM: 23239, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 1439, PL_CLOSED_CUM: 14181, PL_REMAIN_CUM: 21799, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-22', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 332,  PL_CLOSED_CUM: 14513, PL_REMAIN_CUM: 21467, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-29', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 184,  PL_CLOSED_CUM: 14697, PL_REMAIN_CUM: 21283, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 343,  PL_CLOSED_CUM: 15041, PL_REMAIN_CUM: 20939, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 541,  PL_CLOSED_CUM: 15582, PL_REMAIN_CUM: 20398, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 800,  PL_CLOSED_CUM: 16383, PL_REMAIN_CUM: 19597, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 681,  PL_CLOSED_CUM: 17064, PL_REMAIN_CUM: 18916, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-03', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 1115, PL_CLOSED_CUM: 18179, PL_REMAIN_CUM: 17801, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-10', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 1462, PL_CLOSED_CUM: 19642, PL_REMAIN_CUM: 16338, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-17', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 1371, PL_CLOSED_CUM: 21013, PL_REMAIN_CUM: 14966, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-24', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 1233, PL_CLOSED_CUM: 22247, PL_REMAIN_CUM: 13733, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-31', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 1661, PL_CLOSED_CUM: 23908, PL_REMAIN_CUM: 12072, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-07', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 2054, PL_CLOSED_CUM: 25963, PL_REMAIN_CUM: 10017, PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-14', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 1536, PL_CLOSED_CUM: 27499, PL_REMAIN_CUM: 8481,  PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-21', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 910,  PL_CLOSED_CUM: 28410, PL_REMAIN_CUM: 7570,  PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-28', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 1335, PL_CLOSED_CUM: 29745, PL_REMAIN_CUM: 6235,  PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 1581, PL_CLOSED_CUM: 31327, PL_REMAIN_CUM: 4653,  PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 1034, PL_CLOSED_CUM: 32362, PL_REMAIN_CUM: 3618,  PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 592,  PL_CLOSED_CUM: 32955, PL_REMAIN_CUM: 3025,  PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 503,  PL_CLOSED_CUM: 33459, PL_REMAIN_CUM: 2521,  PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-02', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 600,  PL_CLOSED_CUM: 34060, PL_REMAIN_CUM: 1920,  PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-09', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 802,  PL_CLOSED_CUM: 34862, PL_REMAIN_CUM: 1118,  PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-16', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 298,  PL_CLOSED_CUM: 35160, PL_REMAIN_CUM: 820,   PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 189,  PL_CLOSED_CUM: 35350, PL_REMAIN_CUM: 630,   PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-30', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 47,   PL_CLOSED_CUM: 35398, PL_REMAIN_CUM: 582,   PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-06', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 238,  PL_CLOSED_CUM: 35636, PL_REMAIN_CUM: 344,   PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-13', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 163,  PL_CLOSED_CUM: 35800, PL_REMAIN_CUM: 180,   PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-20', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 78,   PL_CLOSED_CUM: 35878, PL_REMAIN_CUM: 102,   PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-27', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 35878, PL_REMAIN_CUM: 102,   PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-04', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 35878, PL_REMAIN_CUM: 102,   PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-11', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 35878, PL_REMAIN_CUM: 102,   PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-18', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 35878, PL_REMAIN_CUM: 102,   PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-25', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 35878, PL_REMAIN_CUM: 102,   PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-11-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 48,   PL_CLOSED_CUM: 35926, PL_REMAIN_CUM: 54,    PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-11-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 36,   PL_CLOSED_CUM: 35962, PL_REMAIN_CUM: 18,    PL_TOTAL: 35980, },
{ ITEM: 'OV',   UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-11-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 35981, PL_CLOSED_WK: 18,   PL_CLOSED_CUM: 35980, PL_REMAIN_CUM: 0,     PL_TOTAL: 35980, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-07', CREATED_WK: 178,  CLOSED_WK: 239,  CREATED_CUM: 14241, CLOSED_CUM: 5725, REMAIN_CUM: 15856, TOTAL: 21581, PL_CLOSED_WK: 239,  PL_CLOSED_CUM: 5725,  PL_REMAIN_CUM: 15855, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-14', CREATED_WK: 62,   CLOSED_WK: 197,  CREATED_CUM: 14303, CLOSED_CUM: 5922, REMAIN_CUM: 15659, TOTAL: 21581, PL_CLOSED_WK: 197,  PL_CLOSED_CUM: 5922,  PL_REMAIN_CUM: 15658, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-21', CREATED_WK: 162,  CLOSED_WK: 216,  CREATED_CUM: 14465, CLOSED_CUM: 6138, REMAIN_CUM: 15443, TOTAL: 21581, PL_CLOSED_WK: 216,  PL_CLOSED_CUM: 6138,  PL_REMAIN_CUM: 15442, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-07-28', CREATED_WK: 212,  CLOSED_WK: 190,  CREATED_CUM: 14677, CLOSED_CUM: 6328, REMAIN_CUM: 15253, TOTAL: 21581, PL_CLOSED_WK: 190,  PL_CLOSED_CUM: 6328,  PL_REMAIN_CUM: 15252, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-04', CREATED_WK: 76,   CLOSED_WK: 176,  CREATED_CUM: 14753, CLOSED_CUM: 6504, REMAIN_CUM: 15077, TOTAL: 21581, PL_CLOSED_WK: 176,  PL_CLOSED_CUM: 6504,  PL_REMAIN_CUM: 15076, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-11', CREATED_WK: 302,  CLOSED_WK: 187,  CREATED_CUM: 15055, CLOSED_CUM: 6691, REMAIN_CUM: 14890, TOTAL: 21581, PL_CLOSED_WK: 187,  PL_CLOSED_CUM: 6691,  PL_REMAIN_CUM: 14889, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 119,  CREATED_CUM: 15055, CLOSED_CUM: 6810, REMAIN_CUM: 14771, TOTAL: 21581, PL_CLOSED_WK: 119,  PL_CLOSED_CUM: 6810,  PL_REMAIN_CUM: 14770, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-08-25', CREATED_WK: 603,  CLOSED_WK: 154,  CREATED_CUM: 15658, CLOSED_CUM: 6964, REMAIN_CUM: 14617, TOTAL: 21581, PL_CLOSED_WK: 154,  PL_CLOSED_CUM: 6964,  PL_REMAIN_CUM: 14616, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-01', CREATED_WK: 277,  CLOSED_WK: 192,  CREATED_CUM: 15935, CLOSED_CUM: 7156, REMAIN_CUM: 14425, TOTAL: 21581, PL_CLOSED_WK: 192,  PL_CLOSED_CUM: 7156,  PL_REMAIN_CUM: 14424, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-08', CREATED_WK: 462,  CLOSED_WK: 228,  CREATED_CUM: 16397, CLOSED_CUM: 7384, REMAIN_CUM: 14197, TOTAL: 21581, PL_CLOSED_WK: 228,  PL_CLOSED_CUM: 7384,  PL_REMAIN_CUM: 14196, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-15', CREATED_WK: 278,  CLOSED_WK: 97,   CREATED_CUM: 16675, CLOSED_CUM: 7481, REMAIN_CUM: 14100, TOTAL: 21581, PL_CLOSED_WK: 97,   PL_CLOSED_CUM: 7481,  PL_REMAIN_CUM: 14099, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-22', CREATED_WK: 272,  CLOSED_WK: 111,  CREATED_CUM: 16947, CLOSED_CUM: 7592, REMAIN_CUM: 13989, TOTAL: 21581, PL_CLOSED_WK: 111,  PL_CLOSED_CUM: 7592,  PL_REMAIN_CUM: 13988, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-09-29', CREATED_WK: 282,  CLOSED_WK: 175,  CREATED_CUM: 17229, CLOSED_CUM: 7767, REMAIN_CUM: 13814, TOTAL: 21581, PL_CLOSED_WK: 175,  PL_CLOSED_CUM: 7767,  PL_REMAIN_CUM: 13813, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-06', CREATED_WK: 241,  CLOSED_WK: 127,  CREATED_CUM: 17470, CLOSED_CUM: 7894, REMAIN_CUM: 13687, TOTAL: 21581, PL_CLOSED_WK: 127,  PL_CLOSED_CUM: 7894,  PL_REMAIN_CUM: 13686, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-13', CREATED_WK: 350,  CLOSED_WK: 108,  CREATED_CUM: 17820, CLOSED_CUM: 8002, REMAIN_CUM: 13579, TOTAL: 21581, PL_CLOSED_WK: 108,  PL_CLOSED_CUM: 8002,  PL_REMAIN_CUM: 13578, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-20', CREATED_WK: 280,  CLOSED_WK: 177,  CREATED_CUM: 18100, CLOSED_CUM: 8179, REMAIN_CUM: 13402, TOTAL: 21581, PL_CLOSED_WK: 177,  PL_CLOSED_CUM: 8179,  PL_REMAIN_CUM: 13401, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-10-27', CREATED_WK: 332,  CLOSED_WK: 199,  CREATED_CUM: 18432, CLOSED_CUM: 8378, REMAIN_CUM: 13203, TOTAL: 21581, PL_CLOSED_WK: 199,  PL_CLOSED_CUM: 8378,  PL_REMAIN_CUM: 13202, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-03', CREATED_WK: 173,  CLOSED_WK: 269,  CREATED_CUM: 18605, CLOSED_CUM: 8647, REMAIN_CUM: 12934, TOTAL: 21581, PL_CLOSED_WK: 269,  PL_CLOSED_CUM: 8647,  PL_REMAIN_CUM: 12933, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-10', CREATED_WK: 266,  CLOSED_WK: 258,  CREATED_CUM: 18871, CLOSED_CUM: 8905, REMAIN_CUM: 12676, TOTAL: 21581, PL_CLOSED_WK: 258,  PL_CLOSED_CUM: 8905,  PL_REMAIN_CUM: 12675, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-17', CREATED_WK: 160,  CLOSED_WK: 164,  CREATED_CUM: 19031, CLOSED_CUM: 9069, REMAIN_CUM: 12512, TOTAL: 21581, PL_CLOSED_WK: 164,  PL_CLOSED_CUM: 9069,  PL_REMAIN_CUM: 12511, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-11-24', CREATED_WK: 238,  CLOSED_WK: 201,  CREATED_CUM: 19269, CLOSED_CUM: 9270, REMAIN_CUM: 12311, TOTAL: 21581, PL_CLOSED_WK: 201,  PL_CLOSED_CUM: 9270,  PL_REMAIN_CUM: 12310, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-01', CREATED_WK: 30,   CLOSED_WK: 198,  CREATED_CUM: 19299, CLOSED_CUM: 9468, REMAIN_CUM: 12113, TOTAL: 21581, PL_CLOSED_WK: 198,  PL_CLOSED_CUM: 9468,  PL_REMAIN_CUM: 12112, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-08', CREATED_WK: 188,  CLOSED_WK: 296,  CREATED_CUM: 19487, CLOSED_CUM: 9764, REMAIN_CUM: 11817, TOTAL: 21581, PL_CLOSED_WK: 296,  PL_CLOSED_CUM: 9764,  PL_REMAIN_CUM: 11816, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-15', CREATED_WK: 380,  CLOSED_WK: 246,  CREATED_CUM: 19867, CLOSED_CUM: 10010, REMAIN_CUM: 11571, TOTAL: 21581, PL_CLOSED_WK: 246,  PL_CLOSED_CUM: 10010, PL_REMAIN_CUM: 11570, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-22', CREATED_WK: 226,  CLOSED_WK: 238,  CREATED_CUM: 20093, CLOSED_CUM: 10248, REMAIN_CUM: 11333, TOTAL: 21581, PL_CLOSED_WK: 238,  PL_CLOSED_CUM: 10248, PL_REMAIN_CUM: 11332, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2023-12-29', CREATED_WK: 762,  CLOSED_WK: 217,  CREATED_CUM: 20855, CLOSED_CUM: 10465, REMAIN_CUM: 11116, TOTAL: 21581, PL_CLOSED_WK: 217,  PL_CLOSED_CUM: 10465, PL_REMAIN_CUM: 11115, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-05', CREATED_WK: 0,    CLOSED_WK: 57,   CREATED_CUM: 20855, CLOSED_CUM: 10522, REMAIN_CUM: 11059, TOTAL: 21581, PL_CLOSED_WK: 54,   PL_CLOSED_CUM: 10519, PL_REMAIN_CUM: 11061, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-12', CREATED_WK: 36,   CLOSED_WK: 94,   CREATED_CUM: 20891, CLOSED_CUM: 10616, REMAIN_CUM: 10965, TOTAL: 21581, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 10519, PL_REMAIN_CUM: 11061, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-19', CREATED_WK: 130,  CLOSED_WK: 85,   CREATED_CUM: 21021, CLOSED_CUM: 10701, REMAIN_CUM: 10880, TOTAL: 21581, PL_CLOSED_WK: 2,    PL_CLOSED_CUM: 10521, PL_REMAIN_CUM: 11059, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-01-26', CREATED_WK: 64,   CLOSED_WK: 143,  CREATED_CUM: 21085, CLOSED_CUM: 10844, REMAIN_CUM: 10737, TOTAL: 21581, PL_CLOSED_WK: 3,    PL_CLOSED_CUM: 10525, PL_REMAIN_CUM: 11055, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-02', CREATED_WK: 180,  CLOSED_WK: 284,  CREATED_CUM: 21265, CLOSED_CUM: 11128, REMAIN_CUM: 10453, TOTAL: 21581, PL_CLOSED_WK: 39,   PL_CLOSED_CUM: 10565, PL_REMAIN_CUM: 11015, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-09', CREATED_WK: 282,  CLOSED_WK: 178,  CREATED_CUM: 21547, CLOSED_CUM: 11306, REMAIN_CUM: 10275, TOTAL: 21581, PL_CLOSED_WK: 34,   PL_CLOSED_CUM: 10600, PL_REMAIN_CUM: 10980, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-16', CREATED_WK: 34,   CLOSED_WK: 40,   CREATED_CUM: 21581, CLOSED_CUM: 11346, REMAIN_CUM: 10235, TOTAL: 21581, PL_CLOSED_WK: 77,   PL_CLOSED_CUM: 10677, PL_REMAIN_CUM: 10903, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-02-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 23,   PL_CLOSED_CUM: 10701, PL_REMAIN_CUM: 10879, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 38,   PL_CLOSED_CUM: 10739, PL_REMAIN_CUM: 10841, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 69,   PL_CLOSED_CUM: 10809, PL_REMAIN_CUM: 10771, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 109,  PL_CLOSED_CUM: 10918, PL_REMAIN_CUM: 10662, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-22', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 168,  PL_CLOSED_CUM: 11087, PL_REMAIN_CUM: 10493, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-03-29', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 149,  PL_CLOSED_CUM: 11237, PL_REMAIN_CUM: 10343, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 250,  PL_CLOSED_CUM: 11487, PL_REMAIN_CUM: 10093, PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 437,  PL_CLOSED_CUM: 11925, PL_REMAIN_CUM: 9655,  PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 402,  PL_CLOSED_CUM: 12327, PL_REMAIN_CUM: 9253,  PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-04-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 570,  PL_CLOSED_CUM: 12898, PL_REMAIN_CUM: 8682,  PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-03', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 868,  PL_CLOSED_CUM: 13766, PL_REMAIN_CUM: 7814,  PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-10', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 1096, PL_CLOSED_CUM: 14863, PL_REMAIN_CUM: 6717,  PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-17', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 939,  PL_CLOSED_CUM: 15802, PL_REMAIN_CUM: 5778,  PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-24', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 785,  PL_CLOSED_CUM: 16587, PL_REMAIN_CUM: 4992,  PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-05-31', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 885,  PL_CLOSED_CUM: 17473, PL_REMAIN_CUM: 4107,  PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-07', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 895,  PL_CLOSED_CUM: 18368, PL_REMAIN_CUM: 3212,  PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-14', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 835,  PL_CLOSED_CUM: 19204, PL_REMAIN_CUM: 2376,  PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-21', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 471,  PL_CLOSED_CUM: 19675, PL_REMAIN_CUM: 1905,  PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-06-28', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 476,  PL_CLOSED_CUM: 20152, PL_REMAIN_CUM: 1428,  PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-05', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 507,  PL_CLOSED_CUM: 20660, PL_REMAIN_CUM: 920,   PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-12', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 348,  PL_CLOSED_CUM: 21008, PL_REMAIN_CUM: 572,   PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-19', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 240,  PL_CLOSED_CUM: 21249, PL_REMAIN_CUM: 331,   PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-07-26', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 107,  PL_CLOSED_CUM: 21356, PL_REMAIN_CUM: 223,   PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-02', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 99,   PL_CLOSED_CUM: 21455, PL_REMAIN_CUM: 124,   PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-09', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 44,   PL_CLOSED_CUM: 21500, PL_REMAIN_CUM: 80,    PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-16', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 24,   PL_CLOSED_CUM: 21524, PL_REMAIN_CUM: 56,    PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-23', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 4,    PL_CLOSED_CUM: 21528, PL_REMAIN_CUM: 52,    PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-08-30', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 21528, PL_REMAIN_CUM: 52,    PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-06', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 21528, PL_REMAIN_CUM: 52,    PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-13', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 21528, PL_REMAIN_CUM: 52,    PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-20', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 21528, PL_REMAIN_CUM: 52,    PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-09-27', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 21528, PL_REMAIN_CUM: 52,    PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-04', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 21528, PL_REMAIN_CUM: 52,    PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-11', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 21528, PL_REMAIN_CUM: 52,    PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-18', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 21528, PL_REMAIN_CUM: 52,    PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-10-25', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 21528, PL_REMAIN_CUM: 52,    PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-11-01', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 0,    PL_CLOSED_CUM: 21528, PL_REMAIN_CUM: 52,    PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-11-08', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 35,   PL_CLOSED_CUM: 21563, PL_REMAIN_CUM: 17,    PL_TOTAL: 21580, },
{ ITEM: 'DISC', UNIT: 'OV', UNIT_NO: 'OV',        UNITCODE: 'OV', SUBSYS_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'OV',                                     CDATE: '2024-11-15', CREATED_WK: null, CLOSED_WK: null, CREATED_CUM: null,  CLOSED_CUM: null, REMAIN_CUM: null,  TOTAL: 21581, PL_CLOSED_WK: 17,   PL_CLOSED_CUM: 21580, PL_REMAIN_CUM: 0,     PL_TOTAL: 21580, },
],


  Queries: {

    // Overall
    SQL1: [
      { 
        SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV',  
        UNITCODE: 'Overall', UNIT_DESC: 'OV', PLAN_MAX: '2024-10-30', 
        B_TOT: 35826, B_ACT: 11847, B_REM: 23979, B_PRO: 33.1, 
    },
    ], 


    // Discipline & Checksheet
    SQL2: [
      { ITEM: 'DISC', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_SHORT: 'E.ELEC', DISC_LONG: 'E-Electrical',      ITR_TYPE: 'OV',               ITR_TITLE: 'NULL',                                   TOT: 1558,  ACT: 185,   REM: 1373,  PRO: 11.9, },
      { ITEM: 'DISC', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_SHORT: 'F.FIRE', DISC_LONG: 'F-Fire Protection', ITR_TYPE: 'OV',               ITR_TITLE: 'NULL',                                   TOT: 39,    ACT: 0,     REM: 39,    PRO: 0, },
      { ITEM: 'DISC', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_SHORT: 'I.INST', DISC_LONG: 'I-Instrument',      ITR_TYPE: 'OV',               ITR_TITLE: 'NULL',                                   TOT: 11484, ACT: 210,   REM: 11274, PRO: 1.8, },
      { ITEM: 'DISC', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_SHORT: 'M.MECH', DISC_LONG: 'M-Mechanical',      ITR_TYPE: 'OV',               ITR_TITLE: 'NULL',                                   TOT: 1316,  ACT: 238,   REM: 1078,  PRO: 18.1, },
      { ITEM: 'DISC', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_SHORT: 'P.PIPE', DISC_LONG: 'P-Piping',          ITR_TYPE: 'OV',               ITR_TITLE: 'NULL',                                   TOT: 21429, ACT: 11214, REM: 10215, PRO: 52.3, },
      { ITEM: 'ITR',  SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_SHORT: 'OV',     DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-32', ITR_TITLE: 'Piping cleaning inspection',             TOT: 4379,  ACT: 195,   REM: 4184,  PRO: 4.5, },
      { ITEM: 'ITR',  SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_SHORT: 'OV',     DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-INS-19', ITR_TITLE: 'Loop test',                              TOT: 10791, ACT: 126,   REM: 10665, PRO: 1.2, },
      { ITEM: 'ITR',  SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_SHORT: 'OV',     DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-ELE-49', ITR_TITLE: 'Motor test (solo run test / bump test)', TOT: 478,   ACT: 10,    REM: 468,   PRO: 2.1, },
      { ITEM: 'ITR',  SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_SHORT: 'OV',     DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-STA-15', ITR_TITLE: 'Equipment box-up',                       TOT: 445,   ACT: 169,   REM: 276,   PRO: 38, },
      { ITEM: 'ITR',  SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_SHORT: 'OV',     DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-MEC-19', ITR_TITLE: 'Seal plan check list',                   TOT: 102,   ACT: 0,     REM: 102,   PRO: 0, },
      { ITEM: 'ITR',  SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_SHORT: 'OV',     DISC_LONG: 'OV',                ITR_TYPE: '10160-CON-PIP-33', ITR_TITLE: 'Piping re-instatment ',                  TOT: 10684, ACT: 5362,  REM: 5322,  PRO: 50.2, },
      { ITEM: 'ITR',  SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_SHORT: 'OV',     DISC_LONG: 'OV',                ITR_TYPE: '10160-PRE-ELE-32', ITR_TITLE: 'Precommissioning lCS',                   TOT: 599,   ACT: 0,     REM: 599,   PRO: 0, },
      { ITEM: 'OV',   SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_SHORT: 'OV',     DISC_LONG: 'OV',                ITR_TYPE: 'OV',               ITR_TITLE: 'NULL',                                   TOT: 35826, ACT: 11847, REM: 23979, PRO: 33.1, },
    ], 


    // Canvas & Chart forms
    SQL3: [ 
      {
        CANVAS_WIDTH:           1300,
        CANVAS_HEIGHT:          850,
        
        PADDING_TOP:            290,
        PADDING_RIGHT:          90,
        PADDING_BOTTOM:         30,
        PADDING_LEFT:           90,

        START_DATE:             '2023-07-07', 
        END_DATE:               '2024-12-27',
        WEEKEND:                'friday',
        CUTOFF:                 '2024-01-26'
      }
    ],

  }
}