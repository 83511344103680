
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 700, // 2100
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 850,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    { SCOPE: 'Grassroots', UNIT: 'CCFU',     UNITCODE: 'CCFU',     SUBSYS_NO: '20400-U-2301', SUBSYS_DESC: 'Superheated Steam',                           OWNER_ID: 'Steam blowing MP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 43,  A_ACT: 11,  A_REM: 32,  A_PRO: 25,  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 17,  B_ACT: 4,   B_REM: 13,  B_PRO: 23,  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-01-01', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'SWS',      UNITCODE: 'SWS',      SUBSYS_NO: '20500-P-0201', SUBSYS_DESC: 'Sour Water Stripper',                         OWNER_ID: 'SWS-DCS Function Test/SWS-MRT P20511AB, P20513AB, P20515AB/SWS-O2 Free: Sour Water Stripper/SWS-GALT', COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 464, A_ACT: 240, A_REM: 224, A_PRO: 51,  AP_TOT: 30,  AP_ACT: 29, AP_REM: 1,  AP_PRO: 96,  B_TOT: 202, B_ACT: 98,  B_REM: 104, B_PRO: 48,  BP_TOT: 32, BP_ACT: 27, BP_REM: 5,  BP_PRO: 84,  C_TOT: 7, C_ACT: 0, C_REM: 7, C_PRO: 0, CP_TOT: 3,  CP_ACT: 3,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-02-01', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'ARU',      UNITCODE: 'ARU',      SUBSYS_NO: '20600-U-1101', SUBSYS_DESC: 'Utility Air',                                 OWNER_ID: 'Utility Air',                                                                                          COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 26,  A_ACT: 26,  A_REM: 0,   A_PRO: 100, AP_TOT: 2,   AP_ACT: 2,  AP_REM: 0,  AP_PRO: 100, B_TOT: 26,  B_ACT: 26,  B_REM: 0,   B_PRO: 100, BP_TOT: 3,  BP_ACT: 3,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-01-02', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'SRU',      UNITCODE: 'SRU',      SUBSYS_NO: '20700-U-1101', SUBSYS_DESC: 'Utility Air',                                 OWNER_ID: 'Utility Air',                                                                                          COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 34,  A_ACT: 34,  A_REM: 0,   A_PRO: 100, AP_TOT: 10,  AP_ACT: 10, AP_REM: 0,  AP_PRO: 100, B_TOT: 34,  B_ACT: 34,  B_REM: 0,   B_PRO: 100, BP_TOT: 2,  BP_ACT: 2,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-03-30', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'HOS',      UNITCODE: 'HOS',      SUBSYS_NO: '20800-U-2102', SUBSYS_DESC: 'MEDIUM PRESSURE STEAM UTILITY STATION',       OWNER_ID: 'Steam blowing MP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 12,  A_ACT: 11,  A_REM: 1,   A_PRO: 91,  AP_TOT: 1,   AP_ACT: 1,  AP_REM: 0,  AP_PRO: 100, B_TOT: 9,   B_ACT: 8,   B_REM: 1,   B_PRO: 88,  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 3,  CP_ACT: 1,  CP_REM: 2, CP_PRO: 33,  CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'U&O',      UNITCODE: 'U&O',      SUBSYS_NO: '21100-U-1001', SUBSYS_DESC: 'Instrument Air',                              OWNER_ID: 'Instrument Air',                                                                                       COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 240, A_ACT: 127, A_REM: 113, A_PRO: 52,  AP_TOT: 43,  AP_ACT: 33, AP_REM: 10, AP_PRO: 76,  B_TOT: 146, B_ACT: 96,  B_REM: 50,  B_PRO: 65,  BP_TOT: 8,  BP_ACT: 8,  BP_REM: 0,  BP_PRO: 100, C_TOT: 1, C_ACT: 0, C_REM: 1, C_PRO: 0, CP_TOT: 6,  CP_ACT: 5,  CP_REM: 1, CP_PRO: 83,  CSTD_PLAN: '2024-05-04', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'U&O',      UNITCODE: 'U&O',      SUBSYS_NO: '21100-U-1003', SUBSYS_DESC: 'Instrument Air North BL',                     OWNER_ID: 'Instrument Air',                                                                                       COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 13,  A_ACT: 11,  A_REM: 2,   A_PRO: 84,  AP_TOT: 2,   AP_ACT: 2,  AP_REM: 0,  AP_PRO: 100, B_TOT: 5,   B_ACT: 4,   B_REM: 1,   B_PRO: 80,  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1, C_ACT: 0, C_REM: 1, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'U&O',      UNITCODE: 'U&O',      SUBSYS_NO: '21100-U-2001', SUBSYS_DESC: 'Low Pressure Steam',                          OWNER_ID: 'Steam blowing LP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 256, A_ACT: 176, A_REM: 80,  A_PRO: 68,  AP_TOT: 38,  AP_ACT: 30, AP_REM: 8,  AP_PRO: 78,  B_TOT: 106, B_ACT: 85,  B_REM: 21,  B_PRO: 80,  BP_TOT: 29, BP_ACT: 27, BP_REM: 2,  BP_PRO: 93,  C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 17, CP_ACT: 16, CP_REM: 1, CP_PRO: 94,  CSTD_PLAN: '2024-07-17', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'SWS',      UNITCODE: 'SWS',      SUBSYS_NO: '20500-I-0101', SUBSYS_DESC: 'Fire and Gas detection and alarming devices', OWNER_ID: 'SWS-DCS Function Test',                                                                                COMM_TYPE: 'Non-Comm', CRITICAL_PATH: '', A_TOT: 22,  A_ACT: 3,   A_REM: 19,  A_PRO: 13,  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 8,   B_ACT: 0,   B_REM: 8,   B_PRO: 0,   BP_TOT: 1,  BP_ACT: 1,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-08-30', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'SWS',      UNITCODE: 'SWS',      SUBSYS_NO: '20500-P-0101', SUBSYS_DESC: 'Sour Water Feed',                             OWNER_ID: 'SWS-DCS Function Test/SWS-MRT P20511AB, P20513AB, P20515AB/SWS-O2 Free: Sour Water Feed/SWS-GALT: So', COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 378, A_ACT: 216, A_REM: 162, A_PRO: 57,  AP_TOT: 12,  AP_ACT: 11, AP_REM: 1,  AP_PRO: 91,  B_TOT: 208, B_ACT: 99,  B_REM: 109, B_PRO: 47,  BP_TOT: 20, BP_ACT: 18, BP_REM: 2,  BP_PRO: 90,  C_TOT: 9, C_ACT: 0, C_REM: 9, C_PRO: 0, CP_TOT: 10, CP_ACT: 9,  CP_REM: 1, CP_PRO: 90,  CSTD_PLAN: '2024-08-16', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'SRU',      UNITCODE: 'SRU',      SUBSYS_NO: '20700-U-1001', SUBSYS_DESC: 'Instrument Air',                              OWNER_ID: 'Instrument Air',                                                                                       COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 90,  A_ACT: 73,  A_REM: 17,  A_PRO: 81,  AP_TOT: 6,   AP_ACT: 6,  AP_REM: 0,  AP_PRO: 100, B_TOT: 57,  B_ACT: 56,  B_REM: 1,   B_PRO: 98,  BP_TOT: 7,  BP_ACT: 7,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 2,  CP_ACT: 2,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-03-22', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'SRU',      UNITCODE: 'SRU',      SUBSYS_NO: '20700-U-2001', SUBSYS_DESC: 'Low Pressure Steam',                          OWNER_ID: 'Steam blowing LP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 314, A_ACT: 257, A_REM: 57,  A_PRO: 81,  AP_TOT: 27,  AP_ACT: 26, AP_REM: 1,  AP_PRO: 96,  B_TOT: 128, B_ACT: 90,  B_REM: 38,  B_PRO: 70,  BP_TOT: 57, BP_ACT: 42, BP_REM: 15, BP_PRO: 73,  C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 13, CP_ACT: 9,  CP_REM: 4, CP_PRO: 69,  CSTD_PLAN: '2024-06-08', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'U&O',      UNITCODE: 'U&O',      SUBSYS_NO: '21100-U-3301', SUBSYS_DESC: 'Low Pressure Boiler Feed Water',              OWNER_ID: 'Steam blowing LP BFW',                                                                                 COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 184, A_ACT: 104, A_REM: 80,  A_PRO: 56,  AP_TOT: 32,  AP_ACT: 20, AP_REM: 12, AP_PRO: 62,  B_TOT: 98,  B_ACT: 63,  B_REM: 35,  B_PRO: 64,  BP_TOT: 16, BP_ACT: 12, BP_REM: 4,  BP_PRO: 75,  C_TOT: 2, C_ACT: 0, C_REM: 2, C_PRO: 0, CP_TOT: 2,  CP_ACT: 1,  CP_REM: 1, CP_PRO: 50,  CSTD_PLAN: '2024-08-14', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'U&O',      UNITCODE: 'U&O',      SUBSYS_NO: '21100-U-3401', SUBSYS_DESC: 'High Pressure Boiler Feed Water',             OWNER_ID: 'Steam blowing HP BFW',                                                                                 COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 54,  A_ACT: 41,  A_REM: 13,  A_PRO: 75,  AP_TOT: 10,  AP_ACT: 10, AP_REM: 0,  AP_PRO: 100, B_TOT: 29,  B_ACT: 22,  B_REM: 7,   B_PRO: 75,  BP_TOT: 7,  BP_ACT: 6,  BP_REM: 1,  BP_PRO: 85,  C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 2,  CP_ACT: 0,  CP_REM: 2, CP_PRO: 0,   CSTD_PLAN: '2024-06-29', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CWS',      UNITCODE: 'CWS',      SUBSYS_NO: '21200-U-4305', SUBSYS_DESC: 'CW DISTRIBUTION TO DHU-CCFU SOUTH',           OWNER_ID: 'Cooling Water',                                                                                        COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 176, A_ACT: 84,  A_REM: 92,  A_PRO: 47,  AP_TOT: 50,  AP_ACT: 9,  AP_REM: 41, AP_PRO: 18,  B_TOT: 108, B_ACT: 65,  B_REM: 43,  B_PRO: 60,  BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 7,  CP_ACT: 0,  CP_REM: 7, CP_PRO: 0,   CSTD_PLAN: '2024-06-29', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',    UNITCODE: 'HSFOC',    SUBSYS_NO: '21403-U-2102', SUBSYS_DESC: 'Medium Pressure Steam Utility Station',       OWNER_ID: 'Steam blowing MP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 12,  A_ACT: 12,  A_REM: 0,   A_PRO: 100, AP_TOT: 1,   AP_ACT: 1,  AP_REM: 0,  AP_PRO: 100, B_TOT: 9,   B_ACT: 8,   B_REM: 1,   B_PRO: 88,  BP_TOT: 1,  BP_ACT: 1,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',    UNITCODE: 'HSFOC',    SUBSYS_NO: '21403-U-2201', SUBSYS_DESC: 'High Pressure Steam',                         OWNER_ID: 'Steam blowing HP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 74,  A_ACT: 66,  A_REM: 8,   A_PRO: 89,  AP_TOT: 12,  AP_ACT: 8,  AP_REM: 4,  AP_PRO: 66,  B_TOT: 22,  B_ACT: 16,  B_REM: 6,   B_PRO: 72,  BP_TOT: 25, BP_ACT: 16, BP_REM: 9,  BP_PRO: 64,  C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 5,  CP_ACT: 1,  CP_REM: 4, CP_PRO: 20,  CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',   UNITCODE: 'DHU S2',   SUBSYS_NO: '20300-P-0201', SUBSYS_DESC: 'Separation Section',                          OWNER_ID: 'Steam blowing MP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 421, A_ACT: 190, A_REM: 231, A_PRO: 45,  AP_TOT: 110, AP_ACT: 44, AP_REM: 66, AP_PRO: 40,  B_TOT: 159, B_ACT: 88,  B_REM: 71,  B_PRO: 55,  BP_TOT: 29, BP_ACT: 19, BP_REM: 10, BP_PRO: 65,  C_TOT: 9, C_ACT: 0, C_REM: 9, C_PRO: 0, CP_TOT: 13, CP_ACT: 9,  CP_REM: 4, CP_PRO: 69,  CSTD_PLAN: '2024-09-12', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'SWS',      UNITCODE: 'SWS',      SUBSYS_NO: '20500-U-1202', SUBSYS_DESC: 'NITROGEN UTILITY STATION',                    OWNER_ID: 'SWS-Service Test Nitrogen',                                                                            COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 4,   A_ACT: 4,   A_REM: 0,   A_PRO: 100, AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 4,   B_ACT: 4,   B_REM: 0,   B_PRO: 100, BP_TOT: 1,  BP_ACT: 1,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 2,  CP_ACT: 2,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-05-15', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'SWS',      UNITCODE: 'SWS',      SUBSYS_NO: '20500-U-3101', SUBSYS_DESC: 'Medium Pressure Condensate',                  OWNER_ID: 'SWS-Service Test MP Condensate',                                                                       COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 27,  A_ACT: 27,  A_REM: 0,   A_PRO: 100, AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 12,  B_ACT: 11,  B_REM: 1,   B_PRO: 91,  BP_TOT: 6,  BP_ACT: 6,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 2,  CP_ACT: 2,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'ARU',      UNITCODE: 'ARU',      SUBSYS_NO: '20600-U-2001', SUBSYS_DESC: 'Low Pressure Steam',                          OWNER_ID: 'Steam blowing LP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 30,  A_ACT: 24,  A_REM: 6,   A_PRO: 80,  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 11,  B_ACT: 8,   B_REM: 3,   B_PRO: 72,  BP_TOT: 3,  BP_ACT: 3,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-06-27', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'SRU',      UNITCODE: 'SRU',      SUBSYS_NO: '20700-U-3301', SUBSYS_DESC: 'Low Pressure Boiler Feed Water',              OWNER_ID: 'Steam blowing LP BFW',                                                                                 COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 103, A_ACT: 93,  A_REM: 10,  A_PRO: 90,  AP_TOT: 20,  AP_ACT: 14, AP_REM: 6,  AP_PRO: 70,  B_TOT: 19,  B_ACT: 10,  B_REM: 9,   B_PRO: 52,  BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 2, C_ACT: 0, C_REM: 2, C_PRO: 0, CP_TOT: 2,  CP_ACT: 0,  CP_REM: 2, CP_PRO: 0,   CSTD_PLAN: '2024-07-09', CSTD_WALKDOWN: '2024-01-25', CSTD_ACTUAL: undefined, CSTD_STATUS: 'WALKDOWN', },
    { SCOPE: 'Grassroots', UNIT: 'U&O',      UNITCODE: 'U&O',      SUBSYS_NO: '21100-U-1201', SUBSYS_DESC: 'Nitrogen',                                    OWNER_ID: 'SWS-Service Test Nitrogen',                                                                            COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 281, A_ACT: 142, A_REM: 139, A_PRO: 50,  AP_TOT: 85,  AP_ACT: 63, AP_REM: 22, AP_PRO: 74,  B_TOT: 235, B_ACT: 136, B_REM: 99,  B_PRO: 57,  BP_TOT: 16, BP_ACT: 6,  BP_REM: 10, BP_PRO: 37,  C_TOT: 1, C_ACT: 0, C_REM: 1, C_PRO: 0, CP_TOT: 28, CP_ACT: 24, CP_REM: 4, CP_PRO: 85,  CSTD_PLAN: '2024-06-20', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'U&O',      UNITCODE: 'U&O',      SUBSYS_NO: '21100-U-4001', SUBSYS_DESC: 'Newater',                                     OWNER_ID: 'SWS-Service Test Newater',                                                                             COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 96,  A_ACT: 70,  A_REM: 26,  A_PRO: 72,  AP_TOT: 45,  AP_ACT: 34, AP_REM: 11, AP_PRO: 75,  B_TOT: 92,  B_ACT: 68,  B_REM: 24,  B_PRO: 73,  BP_TOT: 5,  BP_ACT: 1,  BP_REM: 4,  BP_PRO: 20,  C_TOT: 1, C_ACT: 0, C_REM: 1, C_PRO: 0, CP_TOT: 17, CP_ACT: 11, CP_REM: 6, CP_PRO: 64,  CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CWS',      UNITCODE: 'CWS',      SUBSYS_NO: '21200-U-1001', SUBSYS_DESC: 'Instrument Air',                              OWNER_ID: 'Instrument Air',                                                                                       COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 13,  A_ACT: 6,   A_REM: 7,   A_PRO: 46,  AP_TOT: 2,   AP_ACT: 2,  AP_REM: 0,  AP_PRO: 100, B_TOT: 10,  B_ACT: 6,   B_REM: 4,   B_PRO: 60,  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1, C_ACT: 0, C_REM: 1, C_PRO: 0, CP_TOT: 2,  CP_ACT: 2,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-04-23', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CWS',      UNITCODE: 'CWS',      SUBSYS_NO: '21200-U-2001', SUBSYS_DESC: 'Low Pressure Steam',                          OWNER_ID: 'Steam blowing LP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 44,  A_ACT: 7,   A_REM: 37,  A_PRO: 15,  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 12,  B_ACT: 0,   B_REM: 12,  B_PRO: 0,   BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CWS',      UNITCODE: 'CWS',      SUBSYS_NO: '21200-U-2201', SUBSYS_DESC: 'High Pressure Steam',                         OWNER_ID: 'Steam blowing HP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 30,  A_ACT: 4,   A_REM: 26,  A_PRO: 13,  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 11,  B_ACT: 0,   B_REM: 11,  B_PRO: 0,   BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-06-07', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'DAU',      UNITCODE: 'DAU',      SUBSYS_NO: '20100-U-1001', SUBSYS_DESC: 'Instrument Air',                              OWNER_ID: 'Instrument Air',                                                                                       COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 57,  A_ACT: 36,  A_REM: 21,  A_PRO: 63,  AP_TOT: 5,   AP_ACT: 4,  AP_REM: 1,  AP_PRO: 80,  B_TOT: 36,  B_ACT: 24,  B_REM: 12,  B_PRO: 66,  BP_TOT: 3,  BP_ACT: 0,  BP_REM: 3,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 1,  CP_ACT: 1,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-04-29', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'DAU',      UNITCODE: 'DAU',      SUBSYS_NO: '20100-U-2102', SUBSYS_DESC: 'MEDIUM PRESSURE STEAM UTILITY STATION',       OWNER_ID: 'Steam blowing MP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 21,  A_ACT: 20,  A_REM: 1,   A_PRO: 95,  AP_TOT: 1,   AP_ACT: 1,  AP_REM: 0,  AP_PRO: 100, B_TOT: 15,  B_ACT: 14,  B_REM: 1,   B_PRO: 93,  BP_TOT: 3,  BP_ACT: 2,  BP_REM: 1,  BP_PRO: 66,  C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 4,  CP_ACT: 2,  CP_REM: 2, CP_PRO: 50,  CSTD_PLAN: '2024-07-03', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',     UNITCODE: 'PFBS',     SUBSYS_NO: '20101-U-1101', SUBSYS_DESC: 'Utility Air',                                 OWNER_ID: 'Utility Air',                                                                                          COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 14,  A_ACT: 4,   A_REM: 10,  A_PRO: 28,  AP_TOT: 3,   AP_ACT: 0,  AP_REM: 3,  AP_PRO: 0,   B_TOT: 12,  B_ACT: 4,   B_REM: 8,   B_PRO: 33,  BP_TOT: 4,  BP_ACT: 1,  BP_REM: 3,  BP_PRO: 25,  C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 6,  CP_ACT: 0,  CP_REM: 6, CP_PRO: 0,   CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',   UNITCODE: 'DHU S1',   SUBSYS_NO: '20200-U-1001', SUBSYS_DESC: 'Instrument Air',                              OWNER_ID: 'Instrument Air',                                                                                       COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 137, A_ACT: 35,  A_REM: 102, A_PRO: 25,  AP_TOT: 6,   AP_ACT: 4,  AP_REM: 2,  AP_PRO: 66,  B_TOT: 78,  B_ACT: 18,  B_REM: 60,  B_PRO: 23,  BP_TOT: 1,  BP_ACT: 1,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 1,  CP_ACT: 1,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-06-11', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',   UNITCODE: 'DHU S1',   SUBSYS_NO: '20200-U-2301', SUBSYS_DESC: 'Superheated Steam',                           OWNER_ID: 'Steam blowing MP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 146, A_ACT: 48,  A_REM: 98,  A_PRO: 32,  AP_TOT: 6,   AP_ACT: 2,  AP_REM: 4,  AP_PRO: 33,  B_TOT: 56,  B_ACT: 24,  B_REM: 32,  B_PRO: 42,  BP_TOT: 7,  BP_ACT: 6,  BP_REM: 1,  BP_PRO: 85,  C_TOT: 1, C_ACT: 0, C_REM: 1, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',     UNITCODE: 'CCFU',     SUBSYS_NO: '20400-U-2101', SUBSYS_DESC: 'MEDIUM PRESSURE STEAM UTILITY STATION',       OWNER_ID: 'Steam blowing MP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 28,  A_ACT: 13,  A_REM: 15,  A_PRO: 46,  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 21,  B_ACT: 10,  B_REM: 11,  B_PRO: 47,  BP_TOT: 5,  BP_ACT: 3,  BP_REM: 2,  BP_PRO: 60,  C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',  UNITCODE: 'CCFU SS',  SUBSYS_NO: '20401-U-1101', SUBSYS_DESC: 'Utility Air Solid Separation',                OWNER_ID: 'Utility Air',                                                                                          COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 28,  A_ACT: 22,  A_REM: 6,   A_PRO: 78,  AP_TOT: 2,   AP_ACT: 2,  AP_REM: 0,  AP_PRO: 100, B_TOT: 26,  B_ACT: 22,  B_REM: 4,   B_PRO: 84,  BP_TOT: 2,  BP_ACT: 2,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 2,  CP_ACT: 2,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-04-03', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'SRU',      UNITCODE: 'SRU',      SUBSYS_NO: '20700-U-2201', SUBSYS_DESC: 'High Pressure Steam',                         OWNER_ID: 'Steam blowing HP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 262, A_ACT: 226, A_REM: 36,  A_PRO: 86,  AP_TOT: 19,  AP_ACT: 11, AP_REM: 8,  AP_PRO: 57,  B_TOT: 64,  B_ACT: 43,  B_REM: 21,  B_PRO: 67,  BP_TOT: 13, BP_ACT: 4,  BP_REM: 9,  BP_PRO: 30,  C_TOT: 3, C_ACT: 0, C_REM: 3, C_PRO: 0, CP_TOT: 2,  CP_ACT: 1,  CP_REM: 1, CP_PRO: 50,  CSTD_PLAN: '2024-07-04', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'HOS',      UNITCODE: 'HOS',      SUBSYS_NO: '20800-U-1001', SUBSYS_DESC: 'Instrument Air',                              OWNER_ID: 'Instrument Air',                                                                                       COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 45,  A_ACT: 22,  A_REM: 23,  A_PRO: 48,  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 26,  B_ACT: 10,  B_REM: 16,  B_PRO: 38,  BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 2,  CP_ACT: 0,  CP_REM: 2, CP_PRO: 0,   CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',     UNITCODE: 'PFBS',     SUBSYS_NO: '20101-U-2102', SUBSYS_DESC: 'MEDIUM PRESSURE STEAM UTILITY STATION',       OWNER_ID: 'Steam blowing MP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: 'Critical Path', A_TOT: 9,   A_ACT: 4,   A_REM: 5,   A_PRO: 44,  AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 7,   B_ACT: 2,   B_REM: 5,   B_PRO: 28,  BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 5,  CP_ACT: 0,  CP_REM: 5, CP_PRO: 0,   CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',   UNITCODE: 'DHU S1',   SUBSYS_NO: '20200-U-1101', SUBSYS_DESC: 'Utility Air',                                 OWNER_ID: 'Utility Air',                                                                                          COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 126, A_ACT: 57,  A_REM: 69,  A_PRO: 45,  AP_TOT: 23,  AP_ACT: 20, AP_REM: 3,  AP_PRO: 87,  B_TOT: 106, B_ACT: 56,  B_REM: 50,  B_PRO: 52,  BP_TOT: 13, BP_ACT: 8,  BP_REM: 5,  BP_PRO: 61,  C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 6,  CP_ACT: 5,  CP_REM: 1, CP_PRO: 83,  CSTD_PLAN: '2024-06-12', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',     UNITCODE: 'CCFU',     SUBSYS_NO: '20400-U-1001', SUBSYS_DESC: 'INSTRUMENT AIR',                              OWNER_ID: 'Instrument Air',                                                                                       COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 61,  A_ACT: 28,  A_REM: 33,  A_PRO: 45,  AP_TOT: 4,   AP_ACT: 0,  AP_REM: 4,  AP_PRO: 0,   B_TOT: 46,  B_ACT: 24,  B_REM: 22,  B_PRO: 52,  BP_TOT: 4,  BP_ACT: 1,  BP_REM: 3,  BP_PRO: 25,  C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-05-14', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',  UNITCODE: 'CCFU SS',  SUBSYS_NO: '20401-U-4301', SUBSYS_DESC: 'Cooling Water Solid Separation',              OWNER_ID: 'Cooling Water',                                                                                        COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 19,  A_ACT: 10,  A_REM: 9,   A_PRO: 52,  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 16,  B_ACT: 10,  B_REM: 6,   B_PRO: 62,  BP_TOT: 2,  BP_ACT: 2,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 5,  CP_ACT: 5,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-07-03', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'SWS',      UNITCODE: 'SWS',      SUBSYS_NO: '20500-P-0301', SUBSYS_DESC: 'Stripped Water',                              OWNER_ID: 'SWS-DCS Function Test/SWS-MRT P20511AB, P20513AB, P20515AB/SWS-O2 Free: Stripped Water/SWS-GALT: Str', COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 226, A_ACT: 131, A_REM: 95,  A_PRO: 58,  AP_TOT: 9,   AP_ACT: 8,  AP_REM: 1,  AP_PRO: 88,  B_TOT: 132, B_ACT: 83,  B_REM: 49,  B_PRO: 62,  BP_TOT: 6,  BP_ACT: 6,  BP_REM: 0,  BP_PRO: 100, C_TOT: 9, C_ACT: 0, C_REM: 9, C_PRO: 0, CP_TOT: 8,  CP_ACT: 8,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-08-05', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'SWS',      UNITCODE: 'SWS',      SUBSYS_NO: '20500-U-1101', SUBSYS_DESC: 'Utility Air',                                 OWNER_ID: 'Utility Air/SWS-Service Test Utility Air',                                                             COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 6,   A_ACT: 6,   A_REM: 0,   A_PRO: 100, AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,   B_ACT: 6,   B_REM: 0,   B_PRO: 100, BP_TOT: 1,  BP_ACT: 1,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 2,  CP_ACT: 2,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'SWS',      UNITCODE: 'SWS',      SUBSYS_NO: '20500-U-2102', SUBSYS_DESC: 'Medium Pressure Utility Station',             OWNER_ID: 'Steam blowing MP Steam/SWS-Service Test MP Steam',                                                     COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 9,   A_ACT: 9,   A_REM: 0,   A_PRO: 100, AP_TOT: 1,   AP_ACT: 1,  AP_REM: 0,  AP_PRO: 100, B_TOT: 7,   B_ACT: 6,   B_REM: 1,   B_PRO: 85,  BP_TOT: 1,  BP_ACT: 1,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-06-14', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'ARU',      UNITCODE: 'ARU',      SUBSYS_NO: '20600-U-2102', SUBSYS_DESC: 'Medium Pressure Utility Station',             OWNER_ID: 'Steam blowing MP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 36,  A_ACT: 36,  A_REM: 0,   A_PRO: 100, AP_TOT: 2,   AP_ACT: 2,  AP_REM: 0,  AP_PRO: 100, B_TOT: 25,  B_ACT: 24,  B_REM: 1,   B_PRO: 96,  BP_TOT: 3,  BP_ACT: 3,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'SRU',      UNITCODE: 'SRU',      SUBSYS_NO: '20700-U-2102', SUBSYS_DESC: 'MEDIUM PRESSURE STEAM UTILITY STATION',       OWNER_ID: 'Steam blowing MP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 69,  A_ACT: 67,  A_REM: 2,   A_PRO: 97,  AP_TOT: 3,   AP_ACT: 3,  AP_REM: 0,  AP_PRO: 100, B_TOT: 47,  B_ACT: 46,  B_REM: 1,   B_PRO: 97,  BP_TOT: 7,  BP_ACT: 6,  BP_REM: 1,  BP_PRO: 85,  C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 1,  CP_ACT: 0,  CP_REM: 1, CP_PRO: 0,   CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'U&O',      UNITCODE: 'U&O',      SUBSYS_NO: '21100-U-1002', SUBSYS_DESC: 'INSTRUMENT AIR COMPRESSORS',                  OWNER_ID: 'Instrument Air',                                                                                       COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 96,  A_ACT: 42,  A_REM: 54,  A_PRO: 43,  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 91,  B_ACT: 4,   B_REM: 87,  B_PRO: 4,   BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 20, C_ACT: 0, C_REM: 20, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'U&O',      UNITCODE: 'U&O',      SUBSYS_NO: '21100-U-3101', SUBSYS_DESC: 'Medium Pressure Condensate',                  OWNER_ID: 'SWS-Service Test MP Steam Tracing/SWS-Service Test MP Condensate',                                     COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 614, A_ACT: 479, A_REM: 135, A_PRO: 78,  AP_TOT: 220, AP_ACT: 160, AP_REM: 60, AP_PRO: 72,  B_TOT: 415, B_ACT: 329, B_REM: 86,  B_PRO: 79,  BP_TOT: 127, BP_ACT: 79, BP_REM: 48, BP_PRO: 62,  C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 68, CP_ACT: 39, CP_REM: 29, CP_PRO: 57,  CSTD_PLAN: '2024-08-01', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'U&O',      UNITCODE: 'U&O',      SUBSYS_NO: '21100-U-4101', SUBSYS_DESC: 'Potable Water',                               OWNER_ID: 'SWS-Service Test Potable Water',                                                                       COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 211, A_ACT: 80,  A_REM: 131, A_PRO: 37,  AP_TOT: 23,  AP_ACT: 19, AP_REM: 4,  AP_PRO: 82,  B_TOT: 146, B_ACT: 80,  B_REM: 66,  B_PRO: 54,  BP_TOT: 3,  BP_ACT: 1,  BP_REM: 2,  BP_PRO: 33,  C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 8,  CP_ACT: 7,  CP_REM: 1, CP_PRO: 87,  CSTD_PLAN: '2024-05-13', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CWS',      UNITCODE: 'CWS',      SUBSYS_NO: '21200-U-4303', SUBSYS_DESC: 'CW DISTRIBUTION TO DAU-HOS',                  OWNER_ID: 'Cooling Water',                                                                                        COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 257, A_ACT: 67,  A_REM: 190, A_PRO: 26,  AP_TOT: 31,  AP_ACT: 25, AP_REM: 6,  AP_PRO: 80,  B_TOT: 190, B_ACT: 66,  B_REM: 124, B_PRO: 34,  BP_TOT: 11, BP_ACT: 6,  BP_REM: 5,  BP_PRO: 54,  C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 8,  CP_ACT: 1,  CP_REM: 7, CP_PRO: 12,  CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',  UNITCODE: 'CCFU SS',  SUBSYS_NO: '20401-U-1001', SUBSYS_DESC: 'Instrument Air solid Separation Area',        OWNER_ID: 'Instrument Air',                                                                                       COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 22,  A_ACT: 18,  A_REM: 4,   A_PRO: 81,  AP_TOT: 2,   AP_ACT: 2,  AP_REM: 0,  AP_PRO: 100, B_TOT: 16,  B_ACT: 14,  B_REM: 2,   B_PRO: 87,  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 1,  CP_ACT: 1,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-03-30', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'U&O',      UNITCODE: 'U&O',      SUBSYS_NO: '21100-U-1101', SUBSYS_DESC: 'Utility Air',                                 OWNER_ID: 'Utility Air/SWS-Service Test Utility Air/SWS-O2 Free: Sour Water Feed/SWS-O2 Free: Sour Water Stripp', COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 136, A_ACT: 78,  A_REM: 58,  A_PRO: 57,  AP_TOT: 25,  AP_ACT: 21, AP_REM: 4,  AP_PRO: 84,  B_TOT: 120, B_ACT: 78,  B_REM: 42,  B_PRO: 65,  BP_TOT: 6,  BP_ACT: 3,  BP_REM: 3,  BP_PRO: 50,  C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 13, CP_ACT: 13, CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-06-12', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'U&O',      UNITCODE: 'U&O',      SUBSYS_NO: '21100-U-2202', SUBSYS_DESC: 'High Pressure Steam South Battery Limit',     OWNER_ID: 'Steam blowing HP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 8,   A_ACT: 4,   A_REM: 4,   A_PRO: 50,  AP_TOT: 1,   AP_ACT: 1,  AP_REM: 0,  AP_PRO: 100, B_TOT: 4,   B_ACT: 2,   B_REM: 2,   B_PRO: 50,  BP_TOT: 2,  BP_ACT: 2,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 2,  CP_ACT: 2,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'U&O',      UNITCODE: 'U&O',      SUBSYS_NO: '21100-U-4102', SUBSYS_DESC: 'Potable Water North BL',                      OWNER_ID: 'SWS-Service Test Potable Water',                                                                       COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 16,  A_ACT: 10,  A_REM: 6,   A_PRO: 62,  AP_TOT: 8,   AP_ACT: 7,  AP_REM: 1,  AP_PRO: 87,  B_TOT: 14,  B_ACT: 10,  B_REM: 4,   B_PRO: 71,  BP_TOT: 1,  BP_ACT: 1,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CWS',      UNITCODE: 'CWS',      SUBSYS_NO: '21200-U-4302', SUBSYS_DESC: 'CW DISTRIBUTION TO SRU-ARU-SWS',              OWNER_ID: 'Cooling Water',                                                                                        COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 80,  A_ACT: 65,  A_REM: 15,  A_PRO: 81,  AP_TOT: 21,  AP_ACT: 19, AP_REM: 2,  AP_PRO: 90,  B_TOT: 76,  B_ACT: 62,  B_REM: 14,  B_PRO: 81,  BP_TOT: 4,  BP_ACT: 4,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 4,  CP_ACT: 4,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CWS',      UNITCODE: 'CWS',      SUBSYS_NO: '21200-U-4304', SUBSYS_DESC: 'CW DISTRIBUTION TO DHU-CCFU NORTH',           OWNER_ID: 'Cooling Water',                                                                                        COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 162, A_ACT: 80,  A_REM: 82,  A_PRO: 49,  AP_TOT: 59,  AP_ACT: 39, AP_REM: 20, AP_PRO: 66,  B_TOT: 104, B_ACT: 51,  B_REM: 53,  B_PRO: 49,  BP_TOT: 3,  BP_ACT: 3,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 8,  CP_ACT: 6,  CP_REM: 2, CP_PRO: 75,  CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'DAU',      UNITCODE: 'DAU',      SUBSYS_NO: '20100-U-2101', SUBSYS_DESC: 'Medium Pressure Steam',                       OWNER_ID: 'Steam blowing MP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 161, A_ACT: 108, A_REM: 53,  A_PRO: 67,  AP_TOT: 13,  AP_ACT: 2,  AP_REM: 11, AP_PRO: 15,  B_TOT: 61,  B_ACT: 34,  B_REM: 27,  B_PRO: 55,  BP_TOT: 25, BP_ACT: 5,  BP_REM: 20, BP_PRO: 20,  C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 15, CP_ACT: 2,  CP_REM: 13, CP_PRO: 13,  CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',   UNITCODE: 'DHU S1',   SUBSYS_NO: '20200-U-2401', SUBSYS_DESC: 'Steam Generation',                            OWNER_ID: 'Steam blowing MP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 156, A_ACT: 46,  A_REM: 110, A_PRO: 29,  AP_TOT: 74,  AP_ACT: 21, AP_REM: 53, AP_PRO: 28,  B_TOT: 53,  B_ACT: 20,  B_REM: 33,  B_PRO: 37,  BP_TOT: 17, BP_ACT: 2,  BP_REM: 15, BP_PRO: 11,  C_TOT: 2, C_ACT: 0, C_REM: 2, C_PRO: 0, CP_TOT: 6,  CP_ACT: 4,  CP_REM: 2, CP_PRO: 66,  CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',     UNITCODE: 'CCFU',     SUBSYS_NO: '20400-U-1101', SUBSYS_DESC: 'UTILITY AIR',                                 OWNER_ID: 'Utility Air',                                                                                          COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 25,  A_ACT: 14,  A_REM: 11,  A_PRO: 56,  AP_TOT: 1,   AP_ACT: 0,  AP_REM: 1,  AP_PRO: 0,   B_TOT: 24,  B_ACT: 13,  B_REM: 11,  B_PRO: 54,  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',  UNITCODE: 'CCFU SS',  SUBSYS_NO: '20401-U-2101', SUBSYS_DESC: 'Medium Pressure Steam Solid Separation',      OWNER_ID: 'Steam blowing MP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 34,  A_ACT: 28,  A_REM: 6,   A_PRO: 82,  AP_TOT: 2,   AP_ACT: 2,  AP_REM: 0,  AP_PRO: 100, B_TOT: 25,  B_ACT: 20,  B_REM: 5,   B_PRO: 80,  BP_TOT: 2,  BP_ACT: 1,  BP_REM: 1,  BP_PRO: 50,  C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 2,  CP_ACT: 2,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'U&O',      UNITCODE: 'U&O',      SUBSYS_NO: '21100-U-2101', SUBSYS_DESC: 'Medium Pressure Steam',                       OWNER_ID: 'Steam blowing MP Steam/SWS-Service Test MP Steam/SWS-Service Test MP Steam Tracing',                   COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 1015, A_ACT: 642, A_REM: 373, A_PRO: 63,  AP_TOT: 166, AP_ACT: 112, AP_REM: 54, AP_PRO: 67,  B_TOT: 568, B_ACT: 404, B_REM: 164, B_PRO: 71,  BP_TOT: 160, BP_ACT: 107, BP_REM: 53, BP_PRO: 66,  C_TOT: 2, C_ACT: 0, C_REM: 2, C_PRO: 0, CP_TOT: 80, CP_ACT: 41, CP_REM: 39, CP_PRO: 51,  CSTD_PLAN: '2024-07-18', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CWS',      UNITCODE: 'CWS',      SUBSYS_NO: '21200-U-3301', SUBSYS_DESC: 'Low Pressure Boiler Feed Water',              OWNER_ID: 'Steam blowing LP BFW',                                                                                 COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 3,   A_ACT: 0,   A_REM: 3,   A_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 2,   B_ACT: 0,   B_REM: 2,   B_PRO: 0,   BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-06-14', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CWS',      UNITCODE: 'CWS',      SUBSYS_NO: '21200-U-4301', SUBSYS_DESC: 'CW TOWER and PUMPS',                          OWNER_ID: 'Cooling Water/SWS-MRT P20511AB, P20513AB, P20515AB',                                                   COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 378, A_ACT: 12,  A_REM: 366, A_PRO: 3,   AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 167, B_ACT: 0,   B_REM: 167, B_PRO: 0,   BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 26, C_ACT: 0, C_REM: 26, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-07-08', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'DS',       UNITCODE: 'DS',       SUBSYS_NO: '21300-U-1001', SUBSYS_DESC: 'Instrument Air',                              OWNER_ID: 'Instrument Air',                                                                                       COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 13,  A_ACT: 10,  A_REM: 3,   A_PRO: 76,  AP_TOT: 1,   AP_ACT: 1,  AP_REM: 0,  AP_PRO: 100, B_TOT: 4,   B_ACT: 2,   B_REM: 2,   B_PRO: 50,  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1, C_ACT: 0, C_REM: 1, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-04-05', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'DS',       UNITCODE: 'DS',       SUBSYS_NO: '21300-U-1101', SUBSYS_DESC: 'Utility Air',                                 OWNER_ID: 'Utility Air',                                                                                          COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 23,  A_ACT: 20,  A_REM: 3,   A_PRO: 87,  AP_TOT: 10,  AP_ACT: 10, AP_REM: 0,  AP_PRO: 100, B_TOT: 22,  B_ACT: 20,  B_REM: 2,   B_PRO: 90,  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-04-04', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'DAU',      UNITCODE: 'DAU',      SUBSYS_NO: '20100-U-1101', SUBSYS_DESC: 'Utility Air',                                 OWNER_ID: 'Utility Air',                                                                                          COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 54,  A_ACT: 24,  A_REM: 30,  A_PRO: 44,  AP_TOT: 11,  AP_ACT: 11, AP_REM: 0,  AP_PRO: 100, B_TOT: 44,  B_ACT: 24,  B_REM: 20,  B_PRO: 54,  BP_TOT: 8,  BP_ACT: 6,  BP_REM: 2,  BP_PRO: 75,  C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 7,  CP_ACT: 5,  CP_REM: 2, CP_PRO: 71,  CSTD_PLAN: '2024-05-04', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',   UNITCODE: 'DHU S1',   SUBSYS_NO: '20200-U-2102', SUBSYS_DESC: 'MEDIUM PRESSURE STEAM UTILITY STATION',       OWNER_ID: 'Steam blowing MP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 102, A_ACT: 85,  A_REM: 17,  A_PRO: 83,  AP_TOT: 29,  AP_ACT: 19, AP_REM: 10, AP_PRO: 65,  B_TOT: 71,  B_ACT: 57,  B_REM: 14,  B_PRO: 80,  BP_TOT: 20, BP_ACT: 13, BP_REM: 7,  BP_PRO: 65,  C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 5,  CP_ACT: 5,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-06-21', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',     UNITCODE: 'CCFU',     SUBSYS_NO: '20400-U-2401', SUBSYS_DESC: 'Steam Generation',                            OWNER_ID: 'Steam blowing MP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 58,  A_ACT: 24,  A_REM: 34,  A_PRO: 41,  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 23,  B_ACT: 8,   B_REM: 15,  B_PRO: 34,  BP_TOT: 4,  BP_ACT: 4,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'SWS',      UNITCODE: 'SWS',      SUBSYS_NO: '20500-U-2103', SUBSYS_DESC: 'MEDIUM PRESSURE STEAM TRACING',               OWNER_ID: 'SWS-Service Test MP Steam Tracing',                                                                    COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 44,  A_ACT: 16,  A_REM: 28,  A_PRO: 36,  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 0,   B_ACT: 0,   B_REM: 0,   B_PRO: 0,   BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-07-18', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'SWS',      UNITCODE: 'SWS',      SUBSYS_NO: '20500-U-4001', SUBSYS_DESC: 'Newater',                                     OWNER_ID: 'SWS-Service Test Newater',                                                                             COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 6,   A_ACT: 6,   A_REM: 0,   A_PRO: 100, AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 6,   B_ACT: 6,   B_REM: 0,   B_PRO: 100, BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'ARU',      UNITCODE: 'ARU',      SUBSYS_NO: '20600-U-1001', SUBSYS_DESC: 'Instrument Air',                              OWNER_ID: 'Instrument Air',                                                                                       COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 21,  A_ACT: 18,  A_REM: 3,   A_PRO: 85,  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 14,  B_ACT: 14,  B_REM: 0,   B_PRO: 100, BP_TOT: 1,  BP_ACT: 1,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 3,  CP_ACT: 3,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-04-03', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'U&O',      UNITCODE: 'U&O',      SUBSYS_NO: '21100-U-1202', SUBSYS_DESC: 'Nitrogen South Battery Limit',                OWNER_ID: 'SWS-MRT P20511AB, P20513AB, P20515AB',                                                                 COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 64,  A_ACT: 31,  A_REM: 33,  A_PRO: 48,  AP_TOT: 7,   AP_ACT: 7,  AP_REM: 0,  AP_PRO: 100, B_TOT: 37,  B_ACT: 22,  B_REM: 15,  B_PRO: 59,  BP_TOT: 1,  BP_ACT: 1,  BP_REM: 0,  BP_PRO: 100, C_TOT: 1, C_ACT: 0, C_REM: 1, C_PRO: 0, CP_TOT: 14, CP_ACT: 14, CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-06-20', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'U&O',      UNITCODE: 'U&O',      SUBSYS_NO: '21100-U-2201', SUBSYS_DESC: 'High Pressure Steam',                         OWNER_ID: 'Steam blowing HP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 565, A_ACT: 222, A_REM: 343, A_PRO: 39,  AP_TOT: 47,  AP_ACT: 40, AP_REM: 7,  AP_PRO: 85,  B_TOT: 191, B_ACT: 116, B_REM: 75,  B_PRO: 60,  BP_TOT: 54, BP_ACT: 44, BP_REM: 10, BP_PRO: 81,  C_TOT: 3, C_ACT: 0, C_REM: 3, C_PRO: 0, CP_TOT: 20, CP_ACT: 18, CP_REM: 2, CP_PRO: 90,  CSTD_PLAN: '2024-07-03', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'U&O',      UNITCODE: 'U&O',      SUBSYS_NO: '21100-U-3402', SUBSYS_DESC: 'High Pressure Boiler Feed Water Norht BL',    OWNER_ID: 'Steam blowing HP BFW',                                                                                 COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 18,  A_ACT: 15,  A_REM: 3,   A_PRO: 83,  AP_TOT: 5,   AP_ACT: 5,  AP_REM: 0,  AP_PRO: 100, B_TOT: 12,  B_ACT: 10,  B_REM: 2,   B_PRO: 83,  BP_TOT: 2,  BP_ACT: 1,  BP_REM: 1,  BP_PRO: 50,  C_TOT: 2, C_ACT: 0, C_REM: 2, C_PRO: 0, CP_TOT: 1,  CP_ACT: 1,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-08-10', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'U&O',      UNITCODE: 'U&O',      SUBSYS_NO: '21100-U-4002', SUBSYS_DESC: 'Newater North BL',                            OWNER_ID: 'SWS-Service Test Newater',                                                                             COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 32,  A_ACT: 14,  A_REM: 18,  A_PRO: 43,  AP_TOT: 3,   AP_ACT: 3,  AP_REM: 0,  AP_PRO: 100, B_TOT: 26,  B_ACT: 14,  B_REM: 12,  B_PRO: 53,  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 1, C_ACT: 0, C_REM: 1, C_PRO: 0, CP_TOT: 2,  CP_ACT: 2,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CWS',      UNITCODE: 'CWS',      SUBSYS_NO: '21200-U-1101', SUBSYS_DESC: 'Utility Air',                                 OWNER_ID: 'Utility Air',                                                                                          COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 12,  A_ACT: 6,   A_REM: 6,   A_PRO: 50,  AP_TOT: 1,   AP_ACT: 1,  AP_REM: 0,  AP_PRO: 100, B_TOT: 10,  B_ACT: 6,   B_REM: 4,   B_PRO: 60,  BP_TOT: 1,  BP_ACT: 1,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-05-06', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CWS',      UNITCODE: 'CWS',      SUBSYS_NO: '21200-U-2102', SUBSYS_DESC: 'MEDIUM PRESSURE STEAM UTILITY STATION',       OWNER_ID: 'Steam blowing MP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 15,  A_ACT: 12,  A_REM: 3,   A_PRO: 80,  AP_TOT: 1,   AP_ACT: 1,  AP_REM: 0,  AP_PRO: 100, B_TOT: 11,  B_ACT: 8,   B_REM: 3,   B_PRO: 72,  BP_TOT: 1,  BP_ACT: 1,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 3,  CP_ACT: 3,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CWS',      UNITCODE: 'CWS',      SUBSYS_NO: '21200-U-4306', SUBSYS_DESC: 'CW DISTRIBUTION TO TKN NORTH B.L',            OWNER_ID: 'Cooling Water',                                                                                        COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 39,  A_ACT: 26,  A_REM: 13,  A_PRO: 66,  AP_TOT: 10,  AP_ACT: 10, AP_REM: 0,  AP_PRO: 100, B_TOT: 34,  B_ACT: 26,  B_REM: 8,   B_PRO: 76,  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 1,  CP_ACT: 1,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'CWS',      UNITCODE: 'CWS',      SUBSYS_NO: '21200-U-4307', SUBSYS_DESC: 'CW DISTRIBUTION TO TKS SOUTH B.L',            OWNER_ID: 'Cooling Water',                                                                                        COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 43,  A_ACT: 16,  A_REM: 27,  A_PRO: 37,  AP_TOT: 8,   AP_ACT: 8,  AP_REM: 0,  AP_PRO: 100, B_TOT: 34,  B_ACT: 16,  B_REM: 18,  B_PRO: 47,  BP_TOT: 1,  BP_ACT: 1,  BP_REM: 0,  BP_PRO: 100, C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 5,  CP_ACT: 5,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',    UNITCODE: 'HSFOC',    SUBSYS_NO: '21403-U-1101', SUBSYS_DESC: 'Utility Air',                                 OWNER_ID: 'Utility Air',                                                                                          COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 17,  A_ACT: 17,  A_REM: 0,   A_PRO: 100, AP_TOT: 8,   AP_ACT: 6,  AP_REM: 2,  AP_PRO: 75,  B_TOT: 18,  B_ACT: 18,  B_REM: 0,   B_PRO: 100, BP_TOT: 2,  BP_ACT: 0,  BP_REM: 2,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 6,  CP_ACT: 5,  CP_REM: 1, CP_PRO: 83,  CSTD_PLAN: '2024-05-06', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS', UNITCODE: 'OM&S-TKS', SUBSYS_NO: '21402-U-1001', SUBSYS_DESC: 'INSTRUMENT AIR',                              OWNER_ID: 'Instrument Air',                                                                                       COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 28,  A_ACT: 18,  A_REM: 10,  A_PRO: 64,  AP_TOT: 3,   AP_ACT: 3,  AP_REM: 0,  AP_PRO: 100, B_TOT: 16,  B_ACT: 12,  B_REM: 4,   B_PRO: 75,  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 2,  CP_ACT: 2,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-04-04', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',    UNITCODE: 'HSFOC',    SUBSYS_NO: '21403-U-2101', SUBSYS_DESC: 'Medium Pressure Steam',                       OWNER_ID: 'Steam blowing MP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 25,  A_ACT: 17,  A_REM: 8,   A_PRO: 68,  AP_TOT: 12,  AP_ACT: 5,  AP_REM: 7,  AP_PRO: 41,  B_TOT: 15,  B_ACT: 10,  B_REM: 5,   B_PRO: 66,  BP_TOT: 17, BP_ACT: 15, BP_REM: 2,  BP_PRO: 88,  C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 2,  CP_ACT: 1,  CP_REM: 1, CP_PRO: 50,  CSTD_PLAN: '2024-05-28', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',    UNITCODE: 'HSFOC',    SUBSYS_NO: '21403-U-1001', SUBSYS_DESC: 'Instrument Air',                              OWNER_ID: 'Instrument Air',                                                                                       COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 12,  A_ACT: 12,  A_REM: 0,   A_PRO: 100, AP_TOT: 11,  AP_ACT: 7,  AP_REM: 4,  AP_PRO: 63,  B_TOT: 8,   B_ACT: 8,   B_REM: 0,   B_PRO: 100, BP_TOT: 2,  BP_ACT: 1,  BP_REM: 1,  BP_PRO: 50,  C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 5,  CP_ACT: 3,  CP_REM: 2, CP_PRO: 60,  CSTD_PLAN: '2024-05-15', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',    UNITCODE: 'HSFOC',    SUBSYS_NO: '21403-U-4301', SUBSYS_DESC: 'Cooling water',                               OWNER_ID: 'Cooling Water',                                                                                        COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 18,  A_ACT: 12,  A_REM: 6,   A_PRO: 66,  AP_TOT: 14,  AP_ACT: 14, AP_REM: 0,  AP_PRO: 100, B_TOT: 16,  B_ACT: 12,  B_REM: 4,   B_PRO: 75,  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 2,  CP_ACT: 2,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN', UNITCODE: 'OM&S-TKN', SUBSYS_NO: '21401-U-2101', SUBSYS_DESC: 'Medium Pressure Steam',                       OWNER_ID: 'Steam blowing MP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 9,   A_ACT: 9,   A_REM: 0,   A_PRO: 100, AP_TOT: 2,   AP_ACT: 2,  AP_REM: 0,  AP_PRO: 100, B_TOT: 7,   B_ACT: 6,   B_REM: 1,   B_PRO: 85,  BP_TOT: 1,  BP_ACT: 0,  BP_REM: 1,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 2,  CP_ACT: 2,  CP_REM: 0, CP_PRO: 100, CSTD_PLAN: '2024-06-01', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS', UNITCODE: 'OM&S-TKS', SUBSYS_NO: '21402-U-1101', SUBSYS_DESC: 'Utility Air',                                 OWNER_ID: 'Utility Air',                                                                                          COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 15,  A_ACT: 6,   A_REM: 9,   A_PRO: 40,  AP_TOT: 0,   AP_ACT: 0,  AP_REM: 0,  AP_PRO: 0,   B_TOT: 12,  B_ACT: 6,   B_REM: 6,   B_PRO: 50,  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-04-04', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS', UNITCODE: 'OM&S-TKS', SUBSYS_NO: '21402-U-2102', SUBSYS_DESC: 'MEDIUM PRESSURE STEAM UTILITY STATION',       OWNER_ID: 'Steam blowing MP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 9,   A_ACT: 9,   A_REM: 0,   A_PRO: 100, AP_TOT: 3,   AP_ACT: 3,  AP_REM: 0,  AP_PRO: 100, B_TOT: 7,   B_ACT: 6,   B_REM: 1,   B_PRO: 85,  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-06-20', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'DS',       UNITCODE: 'DS',       SUBSYS_NO: '21300-U-2102', SUBSYS_DESC: 'MEDIUM PRESSURE STEAM UTILITY STATION',       OWNER_ID: 'Steam blowing MP Steam',                                                                               COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 21,  A_ACT: 21,  A_REM: 0,   A_PRO: 100, AP_TOT: 1,   AP_ACT: 1,  AP_REM: 0,  AP_PRO: 100, B_TOT: 15,  B_ACT: 14,  B_REM: 1,   B_PRO: 93,  BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-05-31', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN', UNITCODE: 'OM&S-TKN', SUBSYS_NO: '21401-U-1101', SUBSYS_DESC: 'UTILITY AIR',                                 OWNER_ID: 'Utility Air',                                                                                          COMM_TYPE: 'Comm',     CRITICAL_PATH: '', A_TOT: 4,   A_ACT: 4,   A_REM: 0,   A_PRO: 100, AP_TOT: 1,   AP_ACT: 1,  AP_REM: 0,  AP_PRO: 100, B_TOT: 4,   B_ACT: 4,   B_REM: 0,   B_PRO: 100, BP_TOT: 0,  BP_ACT: 0,  BP_REM: 0,  BP_PRO: 0,   C_TOT: 0, C_ACT: 0, C_REM: 0, C_PRO: 0, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,   CSTD_PLAN: '2024-04-09', CSTD_WALKDOWN: undefined,  CSTD_ACTUAL: undefined, CSTD_STATUS: undefined, },

  ],

  Queries: {

    SQL1: [ // Timeline
      {
        X:                      400,
        Y:                      600,
        LENGTH:                 1200,
        CUTOFF:                 '2024-01-26',
        START:                  '2024-01-19',
        END:                    '2024-11-15',
        REF_DATE:               'CSTD_PLAN', // <---

        WEEKEND:                'friday',
      }
    ],


    // Skyline
    SQL2: [ 
      {
        // Skyline
        REF_COLUMN:             'SUBSYS_NO', // <---
        BOX_WIDTH:              15,
        BOX_HEIGHT:             10,
        BOX_HOVER_SCALE:        1.4,

        // Legends 
        LEGEND_VISIBLE:         'N',
        LEGEND_X:               400,
        LEGEND_Y:               10,  

        // Search
        SEARCH_VISIBLE:         'N',
        SEARCH_X:               45,
        SEARCH_Y:               240.5,
        SEARCH_NOTE:            'Enter the Subsystem number',
      }
    ],


    // Checksheet
    SQL3: [ 
      {
        // CHeck Sheet
        SHEET_TITLE:              ' Total    / Not Started  / In Progress / A&B ITR Completed  / Turn-Over  / Provisional TO ',
        SHEET_STATUS:             ' TOTAL    / NOTSTARTED   / INPROGRESS  / COMPLETED  / SSMCC      / PTO',
        SHEET_TYPE:               '          /              / PRO         /            /            / ',
        SHEET_COLOR:              ' #fff     / #fff         / #FEED57     / #CDDC37    / #4CAE4E    / #00008b ',
        SHEET_OPACITY:            ' 1        / 1            / 1           / 1          / 1          / 1 ',

        // Punch
        PUNCH_NAME:               ' None  / Created / Not Started / Opened / Cleared 100% ',
        PUNCH_STATUS:             ' NONE  / CREATE  / NOTSTARTED  / OPENED / CLEARED      ',
      }
    ],


    // Detail infomation Box
    SQL4: [ 
      {
        DETAIL_VISIBLE:           'N',
        DETAIL_ONOFF:             'OFF',
        DETAIL_X:                 50,
        DETAIL_Y:                 50,
        DETAIL_WIDTH:             350,
        DETAIL_TEXT_SIZE:         11,
        DETAIL_LINEHEIGHT:        20,
        
        DETAIL_CODE:              ' A         / B         / C          / AP      / BP      / CP       ',
        DETAIL_NAME:              ' A         / B         / C          / A       / B       / C        ',
        DETAIL_TYPE:              ' BAR       / BAR       / BAR        / BAR     / BAR     / BAR      ',
        DETAIL_GROUP:             ' ITRs      / ITRs      / ITRs       / Punch   / Punch   / Punch    ',
        DETAIL_VALUE_COLOR:       ' #333      / #333      / #333       / #333    / #333    / #333     ',
        DETAIL_BAR_COLOR:         ' #AEDFFF   / #AEDFFF   / #AEDFFF    / #FFB2D2 / #FFB2D2 / #FFB2D2  ',
        DETAIL_TITLE_WIDTH:       200,
      }
    ],

  }
}