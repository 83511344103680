
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 900,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 900,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-07-07', CREATED_WK: 24,   CLOSED_WK: 7,    CREATED_CUM: 3536,   CLOSED_CUM: 586,   REMAIN_CUM: 2950,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-07-14', CREATED_WK: 6,    CLOSED_WK: 29,   CREATED_CUM: 3542,   CLOSED_CUM: 615,   REMAIN_CUM: 2927,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-07-21', CREATED_WK: 2,    CLOSED_WK: 14,   CREATED_CUM: 3544,   CLOSED_CUM: 629,   REMAIN_CUM: 2915,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-07-28', CREATED_WK: 7,    CLOSED_WK: 23,   CREATED_CUM: 3551,   CLOSED_CUM: 652,   REMAIN_CUM: 2899,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-08-04', CREATED_WK: 4,    CLOSED_WK: 4,    CREATED_CUM: 3555,   CLOSED_CUM: 656,   REMAIN_CUM: 2899,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 15,   CREATED_CUM: 3555,   CLOSED_CUM: 671,   REMAIN_CUM: 2884,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 26,   CREATED_CUM: 3555,   CLOSED_CUM: 697,   REMAIN_CUM: 2858,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-08-25', CREATED_WK: 35,   CLOSED_WK: 27,   CREATED_CUM: 3590,   CLOSED_CUM: 724,   REMAIN_CUM: 2866,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-09-01', CREATED_WK: 2,    CLOSED_WK: 6,    CREATED_CUM: 3592,   CLOSED_CUM: 730,   REMAIN_CUM: 2862,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-09-08', CREATED_WK: 0,    CLOSED_WK: 21,   CREATED_CUM: 3592,   CLOSED_CUM: 751,   REMAIN_CUM: 2841,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-09-15', CREATED_WK: 0,    CLOSED_WK: 34,   CREATED_CUM: 3592,   CLOSED_CUM: 785,   REMAIN_CUM: 2807,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-09-22', CREATED_WK: 20,   CLOSED_WK: 13,   CREATED_CUM: 3612,   CLOSED_CUM: 798,   REMAIN_CUM: 2814,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-09-29', CREATED_WK: 5,    CLOSED_WK: 55,   CREATED_CUM: 3617,   CLOSED_CUM: 853,   REMAIN_CUM: 2764,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 13,   CREATED_CUM: 3617,   CLOSED_CUM: 866,   REMAIN_CUM: 2751,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-10-13', CREATED_WK: 3,    CLOSED_WK: 17,   CREATED_CUM: 3620,   CLOSED_CUM: 883,   REMAIN_CUM: 2737,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-10-20', CREATED_WK: 2,    CLOSED_WK: 5,    CREATED_CUM: 3622,   CLOSED_CUM: 888,   REMAIN_CUM: 2734,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-10-27', CREATED_WK: 2,    CLOSED_WK: 55,   CREATED_CUM: 3624,   CLOSED_CUM: 943,   REMAIN_CUM: 2681,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-11-03', CREATED_WK: 3,    CLOSED_WK: 24,   CREATED_CUM: 3627,   CLOSED_CUM: 967,   REMAIN_CUM: 2660,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-11-10', CREATED_WK: 2,    CLOSED_WK: 54,   CREATED_CUM: 3629,   CLOSED_CUM: 1021,  REMAIN_CUM: 2608,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 9,    CREATED_CUM: 3629,   CLOSED_CUM: 1030,  REMAIN_CUM: 2599,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-11-24', CREATED_WK: 0,    CLOSED_WK: 4,    CREATED_CUM: 3629,   CLOSED_CUM: 1034,  REMAIN_CUM: 2595,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-12-01', CREATED_WK: 2,    CLOSED_WK: 12,   CREATED_CUM: 3631,   CLOSED_CUM: 1046,  REMAIN_CUM: 2585,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-12-08', CREATED_WK: 5,    CLOSED_WK: 6,    CREATED_CUM: 3636,   CLOSED_CUM: 1052,  REMAIN_CUM: 2584,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-12-15', CREATED_WK: 48,   CLOSED_WK: 11,   CREATED_CUM: 3684,   CLOSED_CUM: 1063,  REMAIN_CUM: 2621,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-12-22', CREATED_WK: 0,    CLOSED_WK: 46,   CREATED_CUM: 3684,   CLOSED_CUM: 1109,  REMAIN_CUM: 2575,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 6,    CREATED_CUM: 3684,   CLOSED_CUM: 1115,  REMAIN_CUM: 2569,  TOTAL: 3684, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-07-07', CREATED_WK: 281,  CLOSED_WK: 75,   CREATED_CUM: 28837,  CLOSED_CUM: 2800,  REMAIN_CUM: 26037, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-07-14', CREATED_WK: 138,  CLOSED_WK: 110,  CREATED_CUM: 28975,  CLOSED_CUM: 2910,  REMAIN_CUM: 26065, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-07-21', CREATED_WK: 20,   CLOSED_WK: 27,   CREATED_CUM: 28995,  CLOSED_CUM: 2937,  REMAIN_CUM: 26058, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-07-28', CREATED_WK: 6,    CLOSED_WK: 67,   CREATED_CUM: 29001,  CLOSED_CUM: 3004,  REMAIN_CUM: 25997, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-08-04', CREATED_WK: 21,   CLOSED_WK: 48,   CREATED_CUM: 29022,  CLOSED_CUM: 3052,  REMAIN_CUM: 25970, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-08-11', CREATED_WK: 32,   CLOSED_WK: 129,  CREATED_CUM: 29054,  CLOSED_CUM: 3181,  REMAIN_CUM: 25873, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 98,   CREATED_CUM: 29054,  CLOSED_CUM: 3279,  REMAIN_CUM: 25775, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-08-25', CREATED_WK: 352,  CLOSED_WK: 158,  CREATED_CUM: 29406,  CLOSED_CUM: 3437,  REMAIN_CUM: 25969, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-09-01', CREATED_WK: 65,   CLOSED_WK: 101,  CREATED_CUM: 29471,  CLOSED_CUM: 3538,  REMAIN_CUM: 25933, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-09-08', CREATED_WK: 25,   CLOSED_WK: 94,   CREATED_CUM: 29496,  CLOSED_CUM: 3632,  REMAIN_CUM: 25864, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-09-15', CREATED_WK: 13,   CLOSED_WK: 60,   CREATED_CUM: 29509,  CLOSED_CUM: 3692,  REMAIN_CUM: 25817, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-09-22', CREATED_WK: 61,   CLOSED_WK: 61,   CREATED_CUM: 29570,  CLOSED_CUM: 3753,  REMAIN_CUM: 25817, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-09-29', CREATED_WK: 7,    CLOSED_WK: 150,  CREATED_CUM: 29577,  CLOSED_CUM: 3903,  REMAIN_CUM: 25674, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-10-06', CREATED_WK: 4,    CLOSED_WK: 116,  CREATED_CUM: 29581,  CLOSED_CUM: 4019,  REMAIN_CUM: 25562, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-10-13', CREATED_WK: 11,   CLOSED_WK: 250,  CREATED_CUM: 29592,  CLOSED_CUM: 4269,  REMAIN_CUM: 25323, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-10-20', CREATED_WK: 77,   CLOSED_WK: 261,  CREATED_CUM: 29669,  CLOSED_CUM: 4530,  REMAIN_CUM: 25139, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-10-27', CREATED_WK: 29,   CLOSED_WK: 130,  CREATED_CUM: 29698,  CLOSED_CUM: 4660,  REMAIN_CUM: 25038, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-11-03', CREATED_WK: 20,   CLOSED_WK: 101,  CREATED_CUM: 29718,  CLOSED_CUM: 4761,  REMAIN_CUM: 24957, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-11-10', CREATED_WK: 22,   CLOSED_WK: 122,  CREATED_CUM: 29740,  CLOSED_CUM: 4883,  REMAIN_CUM: 24857, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-11-17', CREATED_WK: 60,   CLOSED_WK: 96,   CREATED_CUM: 29800,  CLOSED_CUM: 4979,  REMAIN_CUM: 24821, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-11-24', CREATED_WK: 13,   CLOSED_WK: 65,   CREATED_CUM: 29813,  CLOSED_CUM: 5044,  REMAIN_CUM: 24769, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-12-01', CREATED_WK: 100,  CLOSED_WK: 89,   CREATED_CUM: 29913,  CLOSED_CUM: 5133,  REMAIN_CUM: 24780, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-12-08', CREATED_WK: 62,   CLOSED_WK: 113,  CREATED_CUM: 29975,  CLOSED_CUM: 5246,  REMAIN_CUM: 24729, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-12-15', CREATED_WK: 674,  CLOSED_WK: 178,  CREATED_CUM: 30649,  CLOSED_CUM: 5424,  REMAIN_CUM: 25225, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-12-22', CREATED_WK: 228,  CLOSED_WK: 237,  CREATED_CUM: 30877,  CLOSED_CUM: 5661,  REMAIN_CUM: 25216, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-12-29', CREATED_WK: 73,   CLOSED_WK: 85,   CREATED_CUM: 30950,  CLOSED_CUM: 5746,  REMAIN_CUM: 25204, TOTAL: 30950, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-07-07', CREATED_WK: 1,    CLOSED_WK: 45,   CREATED_CUM: 42623,  CLOSED_CUM: 4313,  REMAIN_CUM: 38310, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-07-14', CREATED_WK: 232,  CLOSED_WK: 78,   CREATED_CUM: 42855,  CLOSED_CUM: 4391,  REMAIN_CUM: 38464, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-07-21', CREATED_WK: 12,   CLOSED_WK: 63,   CREATED_CUM: 42867,  CLOSED_CUM: 4454,  REMAIN_CUM: 38413, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-07-28', CREATED_WK: 2,    CLOSED_WK: 23,   CREATED_CUM: 42869,  CLOSED_CUM: 4477,  REMAIN_CUM: 38392, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-08-04', CREATED_WK: 95,   CLOSED_WK: 133,  CREATED_CUM: 42964,  CLOSED_CUM: 4610,  REMAIN_CUM: 38354, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-08-11', CREATED_WK: 50,   CLOSED_WK: 100,  CREATED_CUM: 43014,  CLOSED_CUM: 4710,  REMAIN_CUM: 38304, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 121,  CREATED_CUM: 43014,  CLOSED_CUM: 4831,  REMAIN_CUM: 38183, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-08-25', CREATED_WK: 213,  CLOSED_WK: 127,  CREATED_CUM: 43227,  CLOSED_CUM: 4958,  REMAIN_CUM: 38269, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-09-01', CREATED_WK: 1,    CLOSED_WK: 173,  CREATED_CUM: 43228,  CLOSED_CUM: 5131,  REMAIN_CUM: 38097, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-09-08', CREATED_WK: 14,   CLOSED_WK: 162,  CREATED_CUM: 43242,  CLOSED_CUM: 5293,  REMAIN_CUM: 37949, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-09-15', CREATED_WK: 214,  CLOSED_WK: 266,  CREATED_CUM: 43456,  CLOSED_CUM: 5559,  REMAIN_CUM: 37897, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-09-22', CREATED_WK: 31,   CLOSED_WK: 283,  CREATED_CUM: 43487,  CLOSED_CUM: 5842,  REMAIN_CUM: 37645, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-09-29', CREATED_WK: 1,    CLOSED_WK: 489,  CREATED_CUM: 43488,  CLOSED_CUM: 6331,  REMAIN_CUM: 37157, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 860,  CREATED_CUM: 43488,  CLOSED_CUM: 7191,  REMAIN_CUM: 36297, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-10-13', CREATED_WK: 7,    CLOSED_WK: 1011, CREATED_CUM: 43495,  CLOSED_CUM: 8202,  REMAIN_CUM: 35293, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-10-20', CREATED_WK: 24,   CLOSED_WK: 342,  CREATED_CUM: 43519,  CLOSED_CUM: 8544,  REMAIN_CUM: 34975, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-10-27', CREATED_WK: 2,    CLOSED_WK: 74,   CREATED_CUM: 43521,  CLOSED_CUM: 8618,  REMAIN_CUM: 34903, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-11-03', CREATED_WK: 32,   CLOSED_WK: 122,  CREATED_CUM: 43553,  CLOSED_CUM: 8740,  REMAIN_CUM: 34813, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-11-10', CREATED_WK: 92,   CLOSED_WK: 153,  CREATED_CUM: 43645,  CLOSED_CUM: 8893,  REMAIN_CUM: 34752, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-11-17', CREATED_WK: 53,   CLOSED_WK: 186,  CREATED_CUM: 43698,  CLOSED_CUM: 9079,  REMAIN_CUM: 34619, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-11-24', CREATED_WK: 0,    CLOSED_WK: 178,  CREATED_CUM: 43698,  CLOSED_CUM: 9257,  REMAIN_CUM: 34441, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-12-01', CREATED_WK: 8,    CLOSED_WK: 153,  CREATED_CUM: 43706,  CLOSED_CUM: 9410,  REMAIN_CUM: 34296, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 224,  CREATED_CUM: 43706,  CLOSED_CUM: 9634,  REMAIN_CUM: 34072, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-12-15', CREATED_WK: 1876, CLOSED_WK: 217,  CREATED_CUM: 45582,  CLOSED_CUM: 9851,  REMAIN_CUM: 35731, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-12-22', CREATED_WK: 4,    CLOSED_WK: 237,  CREATED_CUM: 45586,  CLOSED_CUM: 10088, REMAIN_CUM: 35498, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 63,   CREATED_CUM: 45586,  CLOSED_CUM: 10151, REMAIN_CUM: 35435, TOTAL: 45586, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-07-07', CREATED_WK: 55,   CLOSED_WK: 28,   CREATED_CUM: 1241,   CLOSED_CUM: 330,   REMAIN_CUM: 911,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-07-14', CREATED_WK: 4,    CLOSED_WK: 19,   CREATED_CUM: 1245,   CLOSED_CUM: 349,   REMAIN_CUM: 896,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-07-21', CREATED_WK: 0,    CLOSED_WK: 11,   CREATED_CUM: 1245,   CLOSED_CUM: 360,   REMAIN_CUM: 885,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-07-28', CREATED_WK: 0,    CLOSED_WK: 9,    CREATED_CUM: 1245,   CLOSED_CUM: 369,   REMAIN_CUM: 876,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1245,   CLOSED_CUM: 369,   REMAIN_CUM: 876,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 16,   CREATED_CUM: 1245,   CLOSED_CUM: 385,   REMAIN_CUM: 860,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 11,   CREATED_CUM: 1245,   CLOSED_CUM: 396,   REMAIN_CUM: 849,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-08-25', CREATED_WK: 0,    CLOSED_WK: 13,   CREATED_CUM: 1245,   CLOSED_CUM: 409,   REMAIN_CUM: 836,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-09-01', CREATED_WK: 0,    CLOSED_WK: 6,    CREATED_CUM: 1245,   CLOSED_CUM: 415,   REMAIN_CUM: 830,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-09-08', CREATED_WK: 0,    CLOSED_WK: 3,    CREATED_CUM: 1245,   CLOSED_CUM: 418,   REMAIN_CUM: 827,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-09-15', CREATED_WK: 85,   CLOSED_WK: 17,   CREATED_CUM: 1330,   CLOSED_CUM: 435,   REMAIN_CUM: 895,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-09-22', CREATED_WK: 26,   CLOSED_WK: 19,   CREATED_CUM: 1356,   CLOSED_CUM: 454,   REMAIN_CUM: 902,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-09-29', CREATED_WK: 5,    CLOSED_WK: 1,    CREATED_CUM: 1361,   CLOSED_CUM: 455,   REMAIN_CUM: 906,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-10-06', CREATED_WK: 2,    CLOSED_WK: 3,    CREATED_CUM: 1363,   CLOSED_CUM: 458,   REMAIN_CUM: 905,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-10-13', CREATED_WK: 3,    CLOSED_WK: 1,    CREATED_CUM: 1366,   CLOSED_CUM: 459,   REMAIN_CUM: 907,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-10-20', CREATED_WK: 27,   CLOSED_WK: 15,   CREATED_CUM: 1393,   CLOSED_CUM: 474,   REMAIN_CUM: 919,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-10-27', CREATED_WK: 0,    CLOSED_WK: 25,   CREATED_CUM: 1393,   CLOSED_CUM: 499,   REMAIN_CUM: 894,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-11-03', CREATED_WK: 1,    CLOSED_WK: 10,   CREATED_CUM: 1394,   CLOSED_CUM: 509,   REMAIN_CUM: 885,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-11-10', CREATED_WK: 7,    CLOSED_WK: 14,   CREATED_CUM: 1401,   CLOSED_CUM: 523,   REMAIN_CUM: 878,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 3,    CREATED_CUM: 1401,   CLOSED_CUM: 526,   REMAIN_CUM: 875,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-11-24', CREATED_WK: 4,    CLOSED_WK: 6,    CREATED_CUM: 1405,   CLOSED_CUM: 532,   REMAIN_CUM: 873,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-12-01', CREATED_WK: 6,    CLOSED_WK: 8,    CREATED_CUM: 1411,   CLOSED_CUM: 540,   REMAIN_CUM: 871,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1411,   CLOSED_CUM: 540,   REMAIN_CUM: 871,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-12-15', CREATED_WK: 43,   CLOSED_WK: 1,    CREATED_CUM: 1454,   CLOSED_CUM: 541,   REMAIN_CUM: 913,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-12-22', CREATED_WK: 1,    CLOSED_WK: 11,   CREATED_CUM: 1455,   CLOSED_CUM: 552,   REMAIN_CUM: 903,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 11,   CREATED_CUM: 1455,   CLOSED_CUM: 563,   REMAIN_CUM: 892,   TOTAL: 1455, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-07-07', CREATED_WK: 627,  CLOSED_WK: 451,  CREATED_CUM: 96558,  CLOSED_CUM: 15294, REMAIN_CUM: 81264, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-07-14', CREATED_WK: 617,  CLOSED_WK: 519,  CREATED_CUM: 97175,  CLOSED_CUM: 15813, REMAIN_CUM: 81362, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-07-21', CREATED_WK: 304,  CLOSED_WK: 545,  CREATED_CUM: 97479,  CLOSED_CUM: 16358, REMAIN_CUM: 81121, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-07-28', CREATED_WK: 306,  CLOSED_WK: 284,  CREATED_CUM: 97785,  CLOSED_CUM: 16642, REMAIN_CUM: 81143, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-08-04', CREATED_WK: 219,  CLOSED_WK: 412,  CREATED_CUM: 98004,  CLOSED_CUM: 17054, REMAIN_CUM: 80950, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-08-11', CREATED_WK: 666,  CLOSED_WK: 589,  CREATED_CUM: 98670,  CLOSED_CUM: 17643, REMAIN_CUM: 81027, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 427,  CREATED_CUM: 98670,  CLOSED_CUM: 18070, REMAIN_CUM: 80600, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-08-25', CREATED_WK: 1471, CLOSED_WK: 618,  CREATED_CUM: 100141, CLOSED_CUM: 18688, REMAIN_CUM: 81453, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-09-01', CREATED_WK: 487,  CLOSED_WK: 582,  CREATED_CUM: 100628, CLOSED_CUM: 19270, REMAIN_CUM: 81358, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-09-08', CREATED_WK: 787,  CLOSED_WK: 544,  CREATED_CUM: 101415, CLOSED_CUM: 19814, REMAIN_CUM: 81601, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-09-15', CREATED_WK: 761,  CLOSED_WK: 613,  CREATED_CUM: 102176, CLOSED_CUM: 20427, REMAIN_CUM: 81749, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-09-22', CREATED_WK: 733,  CLOSED_WK: 595,  CREATED_CUM: 102909, CLOSED_CUM: 21022, REMAIN_CUM: 81887, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-09-29', CREATED_WK: 477,  CLOSED_WK: 966,  CREATED_CUM: 103386, CLOSED_CUM: 21988, REMAIN_CUM: 81398, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-10-06', CREATED_WK: 455,  CLOSED_WK: 1206, CREATED_CUM: 103841, CLOSED_CUM: 23194, REMAIN_CUM: 80647, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-10-13', CREATED_WK: 583,  CLOSED_WK: 1529, CREATED_CUM: 104424, CLOSED_CUM: 24723, REMAIN_CUM: 79701, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-10-20', CREATED_WK: 553,  CLOSED_WK: 927,  CREATED_CUM: 104977, CLOSED_CUM: 25650, REMAIN_CUM: 79327, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-10-27', CREATED_WK: 556,  CLOSED_WK: 549,  CREATED_CUM: 105533, CLOSED_CUM: 26199, REMAIN_CUM: 79334, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-11-03', CREATED_WK: 311,  CLOSED_WK: 536,  CREATED_CUM: 105844, CLOSED_CUM: 26735, REMAIN_CUM: 79109, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-11-10', CREATED_WK: 524,  CLOSED_WK: 659,  CREATED_CUM: 106368, CLOSED_CUM: 27394, REMAIN_CUM: 78974, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-11-17', CREATED_WK: 373,  CLOSED_WK: 605,  CREATED_CUM: 106741, CLOSED_CUM: 27999, REMAIN_CUM: 78742, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-11-24', CREATED_WK: 387,  CLOSED_WK: 493,  CREATED_CUM: 107128, CLOSED_CUM: 28492, REMAIN_CUM: 78636, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-12-01', CREATED_WK: 158,  CLOSED_WK: 497,  CREATED_CUM: 107286, CLOSED_CUM: 28989, REMAIN_CUM: 78297, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-12-08', CREATED_WK: 2544, CLOSED_WK: 734,  CREATED_CUM: 109830, CLOSED_CUM: 29723, REMAIN_CUM: 80107, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-12-15', CREATED_WK: 3330, CLOSED_WK: 735,  CREATED_CUM: 113160, CLOSED_CUM: 30458, REMAIN_CUM: 82702, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-12-22', CREATED_WK: 581,  CLOSED_WK: 922,  CREATED_CUM: 113741, CLOSED_CUM: 31380, REMAIN_CUM: 82361, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-12-29', CREATED_WK: 1203, CLOSED_WK: 323,  CREATED_CUM: 114944, CLOSED_CUM: 31703, REMAIN_CUM: 83241, TOTAL: 114944, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-07-07', CREATED_WK: 90,   CLOSED_WK: 123,  CREATED_CUM: 8634,   CLOSED_CUM: 3616,  REMAIN_CUM: 5018,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-07-14', CREATED_WK: 180,  CLOSED_WK: 103,  CREATED_CUM: 8814,   CLOSED_CUM: 3719,  REMAIN_CUM: 5095,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-07-21', CREATED_WK: 146,  CLOSED_WK: 121,  CREATED_CUM: 8960,   CLOSED_CUM: 3840,  REMAIN_CUM: 5120,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-07-28', CREATED_WK: 106,  CLOSED_WK: 112,  CREATED_CUM: 9066,   CLOSED_CUM: 3952,  REMAIN_CUM: 5114,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-08-04', CREATED_WK: 38,   CLOSED_WK: 97,   CREATED_CUM: 9104,   CLOSED_CUM: 4049,  REMAIN_CUM: 5055,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-08-11', CREATED_WK: 262,  CLOSED_WK: 138,  CREATED_CUM: 9366,   CLOSED_CUM: 4187,  REMAIN_CUM: 5179,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 78,   CREATED_CUM: 9366,   CLOSED_CUM: 4265,  REMAIN_CUM: 5101,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-08-25', CREATED_WK: 295,  CLOSED_WK: 107,  CREATED_CUM: 9661,   CLOSED_CUM: 4372,  REMAIN_CUM: 5289,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-09-01', CREATED_WK: 143,  CLOSED_WK: 119,  CREATED_CUM: 9804,   CLOSED_CUM: 4491,  REMAIN_CUM: 5313,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-09-08', CREATED_WK: 307,  CLOSED_WK: 126,  CREATED_CUM: 10111,  CLOSED_CUM: 4617,  REMAIN_CUM: 5494,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-09-15', CREATED_WK: 187,  CLOSED_WK: 132,  CREATED_CUM: 10298,  CLOSED_CUM: 4749,  REMAIN_CUM: 5549,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-09-22', CREATED_WK: 311,  CLOSED_WK: 139,  CREATED_CUM: 10609,  CLOSED_CUM: 4888,  REMAIN_CUM: 5721,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-09-29', CREATED_WK: 157,  CLOSED_WK: 150,  CREATED_CUM: 10766,  CLOSED_CUM: 5038,  REMAIN_CUM: 5728,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-10-06', CREATED_WK: 156,  CLOSED_WK: 115,  CREATED_CUM: 10922,  CLOSED_CUM: 5153,  REMAIN_CUM: 5769,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-10-13', CREATED_WK: 189,  CLOSED_WK: 92,   CREATED_CUM: 11111,  CLOSED_CUM: 5245,  REMAIN_CUM: 5866,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-10-20', CREATED_WK: 142,  CLOSED_WK: 106,  CREATED_CUM: 11253,  CLOSED_CUM: 5351,  REMAIN_CUM: 5902,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-10-27', CREATED_WK: 172,  CLOSED_WK: 122,  CREATED_CUM: 11425,  CLOSED_CUM: 5473,  REMAIN_CUM: 5952,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-11-03', CREATED_WK: 91,   CLOSED_WK: 143,  CREATED_CUM: 11516,  CLOSED_CUM: 5616,  REMAIN_CUM: 5900,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-11-10', CREATED_WK: 133,  CLOSED_WK: 169,  CREATED_CUM: 11649,  CLOSED_CUM: 5785,  REMAIN_CUM: 5864,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-11-17', CREATED_WK: 83,   CLOSED_WK: 157,  CREATED_CUM: 11732,  CLOSED_CUM: 5942,  REMAIN_CUM: 5790,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-11-24', CREATED_WK: 128,  CLOSED_WK: 163,  CREATED_CUM: 11860,  CLOSED_CUM: 6105,  REMAIN_CUM: 5755,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-12-01', CREATED_WK: 13,   CLOSED_WK: 153,  CREATED_CUM: 11873,  CLOSED_CUM: 6258,  REMAIN_CUM: 5615,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-12-08', CREATED_WK: 2287, CLOSED_WK: 212,  CREATED_CUM: 14160,  CLOSED_CUM: 6470,  REMAIN_CUM: 7690,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-12-15', CREATED_WK: 299,  CLOSED_WK: 160,  CREATED_CUM: 14459,  CLOSED_CUM: 6630,  REMAIN_CUM: 7829,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-12-22', CREATED_WK: 113,  CLOSED_WK: 226,  CREATED_CUM: 14572,  CLOSED_CUM: 6856,  REMAIN_CUM: 7716,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-12-29', CREATED_WK: 376,  CLOSED_WK: 74,   CREATED_CUM: 14948,  CLOSED_CUM: 6930,  REMAIN_CUM: 8018,  TOTAL: 14948, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-07-07', CREATED_WK: 87,   CLOSED_WK: 70,   CREATED_CUM: 4527,   CLOSED_CUM: 1167,  REMAIN_CUM: 3360,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-07-14', CREATED_WK: 26,   CLOSED_WK: 70,   CREATED_CUM: 4553,   CLOSED_CUM: 1237,  REMAIN_CUM: 3316,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-07-21', CREATED_WK: 60,   CLOSED_WK: 92,   CREATED_CUM: 4613,   CLOSED_CUM: 1329,  REMAIN_CUM: 3284,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-07-28', CREATED_WK: 83,   CLOSED_WK: 19,   CREATED_CUM: 4696,   CLOSED_CUM: 1348,  REMAIN_CUM: 3348,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-08-04', CREATED_WK: 28,   CLOSED_WK: 35,   CREATED_CUM: 4724,   CLOSED_CUM: 1383,  REMAIN_CUM: 3341,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-08-11', CREATED_WK: 163,  CLOSED_WK: 83,   CREATED_CUM: 4887,   CLOSED_CUM: 1466,  REMAIN_CUM: 3421,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 10,   CREATED_CUM: 4887,   CLOSED_CUM: 1476,  REMAIN_CUM: 3411,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-08-25', CREATED_WK: 288,  CLOSED_WK: 42,   CREATED_CUM: 5175,   CLOSED_CUM: 1518,  REMAIN_CUM: 3657,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-09-01', CREATED_WK: 140,  CLOSED_WK: 45,   CREATED_CUM: 5315,   CLOSED_CUM: 1563,  REMAIN_CUM: 3752,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-09-08', CREATED_WK: 212,  CLOSED_WK: 74,   CREATED_CUM: 5527,   CLOSED_CUM: 1637,  REMAIN_CUM: 3890,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-09-15', CREATED_WK: 128,  CLOSED_WK: 48,   CREATED_CUM: 5655,   CLOSED_CUM: 1685,  REMAIN_CUM: 3970,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-09-22', CREATED_WK: 145,  CLOSED_WK: 24,   CREATED_CUM: 5800,   CLOSED_CUM: 1709,  REMAIN_CUM: 4091,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-09-29', CREATED_WK: 146,  CLOSED_WK: 53,   CREATED_CUM: 5946,   CLOSED_CUM: 1762,  REMAIN_CUM: 4184,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-10-06', CREATED_WK: 141,  CLOSED_WK: 37,   CREATED_CUM: 6087,   CLOSED_CUM: 1799,  REMAIN_CUM: 4288,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-10-13', CREATED_WK: 181,  CLOSED_WK: 49,   CREATED_CUM: 6268,   CLOSED_CUM: 1848,  REMAIN_CUM: 4420,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-10-20', CREATED_WK: 139,  CLOSED_WK: 74,   CREATED_CUM: 6407,   CLOSED_CUM: 1922,  REMAIN_CUM: 4485,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-10-27', CREATED_WK: 176,  CLOSED_WK: 54,   CREATED_CUM: 6583,   CLOSED_CUM: 1976,  REMAIN_CUM: 4607,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-11-03', CREATED_WK: 81,   CLOSED_WK: 23,   CREATED_CUM: 6664,   CLOSED_CUM: 1999,  REMAIN_CUM: 4665,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-11-10', CREATED_WK: 135,  CLOSED_WK: 46,   CREATED_CUM: 6799,   CLOSED_CUM: 2045,  REMAIN_CUM: 4754,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-11-17', CREATED_WK: 98,   CLOSED_WK: 98,   CREATED_CUM: 6897,   CLOSED_CUM: 2143,  REMAIN_CUM: 4754,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-11-24', CREATED_WK: 118,  CLOSED_WK: 33,   CREATED_CUM: 7015,   CLOSED_CUM: 2176,  REMAIN_CUM: 4839,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-12-01', CREATED_WK: 11,   CLOSED_WK: 26,   CREATED_CUM: 7026,   CLOSED_CUM: 2202,  REMAIN_CUM: 4824,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-12-08', CREATED_WK: 95,   CLOSED_WK: 34,   CREATED_CUM: 7121,   CLOSED_CUM: 2236,  REMAIN_CUM: 4885,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-12-15', CREATED_WK: 199,  CLOSED_WK: 63,   CREATED_CUM: 7320,   CLOSED_CUM: 2299,  REMAIN_CUM: 5021,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-12-22', CREATED_WK: 116,  CLOSED_WK: 24,   CREATED_CUM: 7436,   CLOSED_CUM: 2323,  REMAIN_CUM: 5113,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-12-29', CREATED_WK: 376,  CLOSED_WK: 35,   CREATED_CUM: 7812,   CLOSED_CUM: 2358,  REMAIN_CUM: 5454,  TOTAL: 7812, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-07-07', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 354,    CLOSED_CUM: 58,    REMAIN_CUM: 296,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-07-14', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 354,    CLOSED_CUM: 60,    REMAIN_CUM: 294,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-07-21', CREATED_WK: 1,    CLOSED_WK: 2,    CREATED_CUM: 355,    CLOSED_CUM: 62,    REMAIN_CUM: 293,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-07-28', CREATED_WK: 1,    CLOSED_WK: 2,    CREATED_CUM: 356,    CLOSED_CUM: 64,    REMAIN_CUM: 292,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 5,    CREATED_CUM: 356,    CLOSED_CUM: 69,    REMAIN_CUM: 287,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-08-11', CREATED_WK: 4,    CLOSED_WK: 7,    CREATED_CUM: 360,    CLOSED_CUM: 76,    REMAIN_CUM: 284,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 360,    CLOSED_CUM: 76,    REMAIN_CUM: 284,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-08-25', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 360,    CLOSED_CUM: 78,    REMAIN_CUM: 282,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-09-01', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 360,    CLOSED_CUM: 80,    REMAIN_CUM: 280,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-09-08', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 360,    CLOSED_CUM: 82,    REMAIN_CUM: 278,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-09-15', CREATED_WK: 0,    CLOSED_WK: 1,    CREATED_CUM: 360,    CLOSED_CUM: 83,    REMAIN_CUM: 277,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-09-22', CREATED_WK: 1,    CLOSED_WK: 1,    CREATED_CUM: 361,    CLOSED_CUM: 84,    REMAIN_CUM: 277,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-09-29', CREATED_WK: 0,    CLOSED_WK: 4,    CREATED_CUM: 361,    CLOSED_CUM: 88,    REMAIN_CUM: 273,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 361,    CLOSED_CUM: 88,    REMAIN_CUM: 273,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-10-13', CREATED_WK: 5,    CLOSED_WK: 2,    CREATED_CUM: 366,    CLOSED_CUM: 90,    REMAIN_CUM: 276,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-10-20', CREATED_WK: 0,    CLOSED_WK: 7,    CREATED_CUM: 366,    CLOSED_CUM: 97,    REMAIN_CUM: 269,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-10-27', CREATED_WK: 4,    CLOSED_WK: 5,    CREATED_CUM: 370,    CLOSED_CUM: 102,   REMAIN_CUM: 268,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-11-03', CREATED_WK: 0,    CLOSED_WK: 1,    CREATED_CUM: 370,    CLOSED_CUM: 103,   REMAIN_CUM: 267,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-11-10', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 370,    CLOSED_CUM: 105,   REMAIN_CUM: 265,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 370,    CLOSED_CUM: 107,   REMAIN_CUM: 263,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-11-24', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 370,    CLOSED_CUM: 107,   REMAIN_CUM: 263,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-12-01', CREATED_WK: 5,    CLOSED_WK: 1,    CREATED_CUM: 375,    CLOSED_CUM: 108,   REMAIN_CUM: 267,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 375,    CLOSED_CUM: 110,   REMAIN_CUM: 265,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-12-15', CREATED_WK: 0,    CLOSED_WK: 6,    CREATED_CUM: 375,    CLOSED_CUM: 116,   REMAIN_CUM: 259,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-12-22', CREATED_WK: 6,    CLOSED_WK: 4,    CREATED_CUM: 381,    CLOSED_CUM: 120,   REMAIN_CUM: 261,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-12-29', CREATED_WK: 2,    CLOSED_WK: 5,    CREATED_CUM: 383,    CLOSED_CUM: 125,   REMAIN_CUM: 258,   TOTAL: 383, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-07-07', CREATED_WK: 89,   CLOSED_WK: 103,  CREATED_CUM: 6806,   CLOSED_CUM: 2424,  REMAIN_CUM: 4382,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-07-14', CREATED_WK: 31,   CLOSED_WK: 108,  CREATED_CUM: 6837,   CLOSED_CUM: 2532,  REMAIN_CUM: 4305,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-07-21', CREATED_WK: 63,   CLOSED_WK: 215,  CREATED_CUM: 6900,   CLOSED_CUM: 2747,  REMAIN_CUM: 4153,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-07-28', CREATED_WK: 101,  CLOSED_WK: 29,   CREATED_CUM: 7001,   CLOSED_CUM: 2776,  REMAIN_CUM: 4225,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-08-04', CREATED_WK: 33,   CLOSED_WK: 90,   CREATED_CUM: 7034,   CLOSED_CUM: 2866,  REMAIN_CUM: 4168,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-08-11', CREATED_WK: 155,  CLOSED_WK: 101,  CREATED_CUM: 7189,   CLOSED_CUM: 2967,  REMAIN_CUM: 4222,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 83,   CREATED_CUM: 7189,   CLOSED_CUM: 3050,  REMAIN_CUM: 4139,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-08-25', CREATED_WK: 288,  CLOSED_WK: 142,  CREATED_CUM: 7477,   CLOSED_CUM: 3192,  REMAIN_CUM: 4285,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-09-01', CREATED_WK: 136,  CLOSED_WK: 130,  CREATED_CUM: 7613,   CLOSED_CUM: 3322,  REMAIN_CUM: 4291,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-09-08', CREATED_WK: 229,  CLOSED_WK: 62,   CREATED_CUM: 7842,   CLOSED_CUM: 3384,  REMAIN_CUM: 4458,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-09-15', CREATED_WK: 134,  CLOSED_WK: 55,   CREATED_CUM: 7976,   CLOSED_CUM: 3439,  REMAIN_CUM: 4537,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-09-22', CREATED_WK: 138,  CLOSED_WK: 55,   CREATED_CUM: 8114,   CLOSED_CUM: 3494,  REMAIN_CUM: 4620,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-09-29', CREATED_WK: 156,  CLOSED_WK: 64,   CREATED_CUM: 8270,   CLOSED_CUM: 3558,  REMAIN_CUM: 4712,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-10-06', CREATED_WK: 152,  CLOSED_WK: 62,   CREATED_CUM: 8422,   CLOSED_CUM: 3620,  REMAIN_CUM: 4802,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-10-13', CREATED_WK: 184,  CLOSED_WK: 107,  CREATED_CUM: 8606,   CLOSED_CUM: 3727,  REMAIN_CUM: 4879,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-10-20', CREATED_WK: 142,  CLOSED_WK: 117,  CREATED_CUM: 8748,   CLOSED_CUM: 3844,  REMAIN_CUM: 4904,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-10-27', CREATED_WK: 171,  CLOSED_WK: 84,   CREATED_CUM: 8919,   CLOSED_CUM: 3928,  REMAIN_CUM: 4991,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-11-03', CREATED_WK: 83,   CLOSED_WK: 112,  CREATED_CUM: 9002,   CLOSED_CUM: 4040,  REMAIN_CUM: 4962,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-11-10', CREATED_WK: 133,  CLOSED_WK: 99,   CREATED_CUM: 9135,   CLOSED_CUM: 4139,  REMAIN_CUM: 4996,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-11-17', CREATED_WK: 79,   CLOSED_WK: 54,   CREATED_CUM: 9214,   CLOSED_CUM: 4193,  REMAIN_CUM: 5021,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-11-24', CREATED_WK: 124,  CLOSED_WK: 44,   CREATED_CUM: 9338,   CLOSED_CUM: 4237,  REMAIN_CUM: 5101,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-12-01', CREATED_WK: 13,   CLOSED_WK: 55,   CREATED_CUM: 9351,   CLOSED_CUM: 4292,  REMAIN_CUM: 5059,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-12-08', CREATED_WK: 95,   CLOSED_WK: 143,  CREATED_CUM: 9446,   CLOSED_CUM: 4435,  REMAIN_CUM: 5011,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-12-15', CREATED_WK: 191,  CLOSED_WK: 99,   CREATED_CUM: 9637,   CLOSED_CUM: 4534,  REMAIN_CUM: 5103,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-12-22', CREATED_WK: 113,  CLOSED_WK: 137,  CREATED_CUM: 9750,   CLOSED_CUM: 4671,  REMAIN_CUM: 5079,  TOTAL: 10126, },
    { ITEM: 'A', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-12-29', CREATED_WK: 376,  CLOSED_WK: 44,   CREATED_CUM: 10126,  CLOSED_CUM: 4715,  REMAIN_CUM: 5411,  TOTAL: 10126, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-07-07', CREATED_WK: 325,  CLOSED_WK: 0,    CREATED_CUM: 1753,   CLOSED_CUM: 0,     REMAIN_CUM: 1753,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-07-14', CREATED_WK: 3,    CLOSED_WK: 0,    CREATED_CUM: 1756,   CLOSED_CUM: 0,     REMAIN_CUM: 1756,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-07-21', CREATED_WK: 1,    CLOSED_WK: 5,    CREATED_CUM: 1757,   CLOSED_CUM: 5,     REMAIN_CUM: 1752,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-07-28', CREATED_WK: 16,   CLOSED_WK: 0,    CREATED_CUM: 1773,   CLOSED_CUM: 5,     REMAIN_CUM: 1768,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 7,    CREATED_CUM: 1773,   CLOSED_CUM: 12,    REMAIN_CUM: 1761,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1773,   CLOSED_CUM: 12,    REMAIN_CUM: 1761,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1773,   CLOSED_CUM: 12,    REMAIN_CUM: 1761,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-08-25', CREATED_WK: 3,    CLOSED_WK: 10,   CREATED_CUM: 1776,   CLOSED_CUM: 22,    REMAIN_CUM: 1754,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-09-01', CREATED_WK: 0,    CLOSED_WK: 3,    CREATED_CUM: 1776,   CLOSED_CUM: 25,    REMAIN_CUM: 1751,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-09-08', CREATED_WK: 8,    CLOSED_WK: 4,    CREATED_CUM: 1784,   CLOSED_CUM: 29,    REMAIN_CUM: 1755,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-09-15', CREATED_WK: 1,    CLOSED_WK: 6,    CREATED_CUM: 1785,   CLOSED_CUM: 35,    REMAIN_CUM: 1750,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-09-22', CREATED_WK: 0,    CLOSED_WK: 15,   CREATED_CUM: 1785,   CLOSED_CUM: 50,    REMAIN_CUM: 1735,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-09-29', CREATED_WK: 2,    CLOSED_WK: 12,   CREATED_CUM: 1787,   CLOSED_CUM: 62,    REMAIN_CUM: 1725,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 9,    CREATED_CUM: 1787,   CLOSED_CUM: 71,    REMAIN_CUM: 1716,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-10-13', CREATED_WK: 0,    CLOSED_WK: 16,   CREATED_CUM: 1787,   CLOSED_CUM: 87,    REMAIN_CUM: 1700,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-10-20', CREATED_WK: 4,    CLOSED_WK: 0,    CREATED_CUM: 1791,   CLOSED_CUM: 87,    REMAIN_CUM: 1704,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-10-27', CREATED_WK: 0,    CLOSED_WK: 16,   CREATED_CUM: 1791,   CLOSED_CUM: 103,   REMAIN_CUM: 1688,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-11-03', CREATED_WK: 2,    CLOSED_WK: 30,   CREATED_CUM: 1793,   CLOSED_CUM: 133,   REMAIN_CUM: 1660,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-11-10', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1793,   CLOSED_CUM: 133,   REMAIN_CUM: 1660,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 12,   CREATED_CUM: 1793,   CLOSED_CUM: 145,   REMAIN_CUM: 1648,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-11-24', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 1793,   CLOSED_CUM: 147,   REMAIN_CUM: 1646,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-12-01', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 1793,   CLOSED_CUM: 149,   REMAIN_CUM: 1644,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1793,   CLOSED_CUM: 149,   REMAIN_CUM: 1644,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-12-15', CREATED_WK: 5,    CLOSED_WK: 22,   CREATED_CUM: 1798,   CLOSED_CUM: 171,   REMAIN_CUM: 1627,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-12-22', CREATED_WK: 5,    CLOSED_WK: 3,    CREATED_CUM: 1803,   CLOSED_CUM: 174,   REMAIN_CUM: 1629,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-12-29', CREATED_WK: 61,   CLOSED_WK: 1,    CREATED_CUM: 1864,   CLOSED_CUM: 175,   REMAIN_CUM: 1689,  TOTAL: 1864, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-07-07', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-07-14', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-07-21', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-07-28', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-08-25', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-09-01', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-09-08', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-09-15', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-09-22', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-09-29', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-10-13', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-10-20', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-10-27', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-11-03', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-11-10', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-11-24', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-12-01', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-12-15', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-12-22', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 39,     CLOSED_CUM: 0,     REMAIN_CUM: 39,    TOTAL: 39, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-07-07', CREATED_WK: 6,    CLOSED_WK: 0,    CREATED_CUM: 11360,  CLOSED_CUM: 0,     REMAIN_CUM: 11360, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-07-14', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11360,  CLOSED_CUM: 0,     REMAIN_CUM: 11360, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-07-21', CREATED_WK: 7,    CLOSED_WK: 0,    CREATED_CUM: 11367,  CLOSED_CUM: 0,     REMAIN_CUM: 11367, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-07-28', CREATED_WK: 1,    CLOSED_WK: 0,    CREATED_CUM: 11368,  CLOSED_CUM: 0,     REMAIN_CUM: 11368, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11368,  CLOSED_CUM: 0,     REMAIN_CUM: 11368, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11368,  CLOSED_CUM: 0,     REMAIN_CUM: 11368, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11368,  CLOSED_CUM: 0,     REMAIN_CUM: 11368, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-08-25', CREATED_WK: 2,    CLOSED_WK: 0,    CREATED_CUM: 11370,  CLOSED_CUM: 0,     REMAIN_CUM: 11370, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-09-01', CREATED_WK: 1,    CLOSED_WK: 0,    CREATED_CUM: 11371,  CLOSED_CUM: 0,     REMAIN_CUM: 11371, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-09-08', CREATED_WK: 1,    CLOSED_WK: 11,   CREATED_CUM: 11372,  CLOSED_CUM: 11,    REMAIN_CUM: 11361, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-09-15', CREATED_WK: 0,    CLOSED_WK: 5,    CREATED_CUM: 11372,  CLOSED_CUM: 16,    REMAIN_CUM: 11356, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-09-22', CREATED_WK: 0,    CLOSED_WK: 14,   CREATED_CUM: 11372,  CLOSED_CUM: 30,    REMAIN_CUM: 11342, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-09-29', CREATED_WK: 1,    CLOSED_WK: 0,    CREATED_CUM: 11373,  CLOSED_CUM: 30,    REMAIN_CUM: 11343, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11373,  CLOSED_CUM: 30,    REMAIN_CUM: 11343, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-10-13', CREATED_WK: 4,    CLOSED_WK: 26,   CREATED_CUM: 11377,  CLOSED_CUM: 56,    REMAIN_CUM: 11321, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-10-20', CREATED_WK: 82,   CLOSED_WK: 19,   CREATED_CUM: 11459,  CLOSED_CUM: 75,    REMAIN_CUM: 11384, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-10-27', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11459,  CLOSED_CUM: 75,    REMAIN_CUM: 11384, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-11-03', CREATED_WK: 7,    CLOSED_WK: 0,    CREATED_CUM: 11466,  CLOSED_CUM: 75,    REMAIN_CUM: 11391, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-11-10', CREATED_WK: 0,    CLOSED_WK: 23,   CREATED_CUM: 11466,  CLOSED_CUM: 98,    REMAIN_CUM: 11368, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11466,  CLOSED_CUM: 98,    REMAIN_CUM: 11368, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-11-24', CREATED_WK: 0,    CLOSED_WK: 87,   CREATED_CUM: 11466,  CLOSED_CUM: 185,   REMAIN_CUM: 11281, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-12-01', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11466,  CLOSED_CUM: 185,   REMAIN_CUM: 11281, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11466,  CLOSED_CUM: 185,   REMAIN_CUM: 11281, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-12-15', CREATED_WK: 0,    CLOSED_WK: 1,    CREATED_CUM: 11466,  CLOSED_CUM: 186,   REMAIN_CUM: 11280, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-12-22', CREATED_WK: 2,    CLOSED_WK: 1,    CREATED_CUM: 11468,  CLOSED_CUM: 187,   REMAIN_CUM: 11281, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 11468,  CLOSED_CUM: 187,   REMAIN_CUM: 11281, TOTAL: 11468, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-07-07', CREATED_WK: 4,    CLOSED_WK: 1,    CREATED_CUM: 1114,   CLOSED_CUM: 68,    REMAIN_CUM: 1046,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-07-14', CREATED_WK: 40,   CLOSED_WK: 22,   CREATED_CUM: 1154,   CLOSED_CUM: 90,    REMAIN_CUM: 1064,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-07-21', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 1154,   CLOSED_CUM: 92,    REMAIN_CUM: 1062,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-07-28', CREATED_WK: 0,    CLOSED_WK: 5,    CREATED_CUM: 1154,   CLOSED_CUM: 97,    REMAIN_CUM: 1057,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1154,   CLOSED_CUM: 97,    REMAIN_CUM: 1057,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 1,    CREATED_CUM: 1154,   CLOSED_CUM: 98,    REMAIN_CUM: 1056,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1154,   CLOSED_CUM: 98,    REMAIN_CUM: 1056,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-08-25', CREATED_WK: 2,    CLOSED_WK: 3,    CREATED_CUM: 1156,   CLOSED_CUM: 101,   REMAIN_CUM: 1055,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-09-01', CREATED_WK: 0,    CLOSED_WK: 6,    CREATED_CUM: 1156,   CLOSED_CUM: 107,   REMAIN_CUM: 1049,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-09-08', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 1156,   CLOSED_CUM: 109,   REMAIN_CUM: 1047,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-09-15', CREATED_WK: 3,    CLOSED_WK: 0,    CREATED_CUM: 1159,   CLOSED_CUM: 109,   REMAIN_CUM: 1050,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-09-22', CREATED_WK: 22,   CLOSED_WK: 0,    CREATED_CUM: 1181,   CLOSED_CUM: 109,   REMAIN_CUM: 1072,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-09-29', CREATED_WK: 1,    CLOSED_WK: 3,    CREATED_CUM: 1182,   CLOSED_CUM: 112,   REMAIN_CUM: 1070,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 6,    CREATED_CUM: 1182,   CLOSED_CUM: 118,   REMAIN_CUM: 1064,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-10-13', CREATED_WK: 3,    CLOSED_WK: 7,    CREATED_CUM: 1185,   CLOSED_CUM: 125,   REMAIN_CUM: 1060,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-10-20', CREATED_WK: 0,    CLOSED_WK: 0,    CREATED_CUM: 1185,   CLOSED_CUM: 125,   REMAIN_CUM: 1060,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-10-27', CREATED_WK: 0,    CLOSED_WK: 2,    CREATED_CUM: 1185,   CLOSED_CUM: 127,   REMAIN_CUM: 1058,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-11-03', CREATED_WK: 1,    CLOSED_WK: 8,    CREATED_CUM: 1186,   CLOSED_CUM: 135,   REMAIN_CUM: 1051,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-11-10', CREATED_WK: 19,   CLOSED_WK: 11,   CREATED_CUM: 1205,   CLOSED_CUM: 146,   REMAIN_CUM: 1059,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 6,    CREATED_CUM: 1205,   CLOSED_CUM: 152,   REMAIN_CUM: 1053,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-11-24', CREATED_WK: 9,    CLOSED_WK: 1,    CREATED_CUM: 1214,   CLOSED_CUM: 153,   REMAIN_CUM: 1061,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-12-01', CREATED_WK: 11,   CLOSED_WK: 3,    CREATED_CUM: 1225,   CLOSED_CUM: 156,   REMAIN_CUM: 1069,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-12-08', CREATED_WK: 3,    CLOSED_WK: 0,    CREATED_CUM: 1228,   CLOSED_CUM: 156,   REMAIN_CUM: 1072,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-12-15', CREATED_WK: 82,   CLOSED_WK: 24,   CREATED_CUM: 1310,   CLOSED_CUM: 180,   REMAIN_CUM: 1130,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-12-22', CREATED_WK: 2,    CLOSED_WK: 9,    CREATED_CUM: 1312,   CLOSED_CUM: 189,   REMAIN_CUM: 1123,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-12-29', CREATED_WK: 17,   CLOSED_WK: 29,   CREATED_CUM: 1329,   CLOSED_CUM: 218,   REMAIN_CUM: 1111,  TOTAL: 1329, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-07-07', CREATED_WK: 513,  CLOSED_WK: 240,  CREATED_CUM: 28820,  CLOSED_CUM: 5795,  REMAIN_CUM: 23025, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-07-14', CREATED_WK: 107,  CLOSED_WK: 219,  CREATED_CUM: 28927,  CLOSED_CUM: 6014,  REMAIN_CUM: 22913, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-07-21', CREATED_WK: 170,  CLOSED_WK: 223,  CREATED_CUM: 29097,  CLOSED_CUM: 6237,  REMAIN_CUM: 22860, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-07-28', CREATED_WK: 229,  CLOSED_WK: 195,  CREATED_CUM: 29326,  CLOSED_CUM: 6432,  REMAIN_CUM: 22894, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-08-04', CREATED_WK: 76,   CLOSED_WK: 183,  CREATED_CUM: 29402,  CLOSED_CUM: 6615,  REMAIN_CUM: 22787, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-08-11', CREATED_WK: 314,  CLOSED_WK: 188,  CREATED_CUM: 29716,  CLOSED_CUM: 6803,  REMAIN_CUM: 22913, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 119,  CREATED_CUM: 29716,  CLOSED_CUM: 6922,  REMAIN_CUM: 22794, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-08-25', CREATED_WK: 620,  CLOSED_WK: 167,  CREATED_CUM: 30336,  CLOSED_CUM: 7089,  REMAIN_CUM: 23247, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-09-01', CREATED_WK: 278,  CLOSED_WK: 201,  CREATED_CUM: 30614,  CLOSED_CUM: 7290,  REMAIN_CUM: 23324, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-09-08', CREATED_WK: 471,  CLOSED_WK: 245,  CREATED_CUM: 31085,  CLOSED_CUM: 7535,  REMAIN_CUM: 23550, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-09-15', CREATED_WK: 282,  CLOSED_WK: 108,  CREATED_CUM: 31367,  CLOSED_CUM: 7643,  REMAIN_CUM: 23724, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-09-22', CREATED_WK: 300,  CLOSED_WK: 140,  CREATED_CUM: 31667,  CLOSED_CUM: 7783,  REMAIN_CUM: 23884, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-09-29', CREATED_WK: 316,  CLOSED_WK: 190,  CREATED_CUM: 31983,  CLOSED_CUM: 7973,  REMAIN_CUM: 24010, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-10-06', CREATED_WK: 303,  CLOSED_WK: 142,  CREATED_CUM: 32286,  CLOSED_CUM: 8115,  REMAIN_CUM: 24171, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-10-13', CREATED_WK: 387,  CLOSED_WK: 157,  CREATED_CUM: 32673,  CLOSED_CUM: 8272,  REMAIN_CUM: 24401, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-10-20', CREATED_WK: 370,  CLOSED_WK: 196,  CREATED_CUM: 33043,  CLOSED_CUM: 8468,  REMAIN_CUM: 24575, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-10-27', CREATED_WK: 344,  CLOSED_WK: 217,  CREATED_CUM: 33387,  CLOSED_CUM: 8685,  REMAIN_CUM: 24702, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-11-03', CREATED_WK: 183,  CLOSED_WK: 307,  CREATED_CUM: 33570,  CLOSED_CUM: 8992,  REMAIN_CUM: 24578, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-11-10', CREATED_WK: 285,  CLOSED_WK: 292,  CREATED_CUM: 33855,  CLOSED_CUM: 9284,  REMAIN_CUM: 24571, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-11-17', CREATED_WK: 162,  CLOSED_WK: 182,  CREATED_CUM: 34017,  CLOSED_CUM: 9466,  REMAIN_CUM: 24551, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-11-24', CREATED_WK: 257,  CLOSED_WK: 291,  CREATED_CUM: 34274,  CLOSED_CUM: 9757,  REMAIN_CUM: 24517, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-12-01', CREATED_WK: 41,   CLOSED_WK: 203,  CREATED_CUM: 34315,  CLOSED_CUM: 9960,  REMAIN_CUM: 24355, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-12-08', CREATED_WK: 193,  CLOSED_WK: 296,  CREATED_CUM: 34508,  CLOSED_CUM: 10256, REMAIN_CUM: 24252, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-12-15', CREATED_WK: 471,  CLOSED_WK: 293,  CREATED_CUM: 34979,  CLOSED_CUM: 10549, REMAIN_CUM: 24430, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-12-22', CREATED_WK: 235,  CLOSED_WK: 251,  CREATED_CUM: 35214,  CLOSED_CUM: 10800, REMAIN_CUM: 24414, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-12-29', CREATED_WK: 830,  CLOSED_WK: 160,  CREATED_CUM: 36044,  CLOSED_CUM: 10960, REMAIN_CUM: 25084, TOTAL: 36044, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-07-07', CREATED_WK: 178,  CLOSED_WK: 239,  CREATED_CUM: 14554,  CLOSED_CUM: 5727,  REMAIN_CUM: 8827,  TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-07-14', CREATED_WK: 64,   CLOSED_WK: 197,  CREATED_CUM: 14618,  CLOSED_CUM: 5924,  REMAIN_CUM: 8694,  TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-07-21', CREATED_WK: 162,  CLOSED_WK: 216,  CREATED_CUM: 14780,  CLOSED_CUM: 6140,  REMAIN_CUM: 8640,  TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-07-28', CREATED_WK: 212,  CLOSED_WK: 190,  CREATED_CUM: 14992,  CLOSED_CUM: 6330,  REMAIN_CUM: 8662,  TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-08-04', CREATED_WK: 76,   CLOSED_WK: 176,  CREATED_CUM: 15068,  CLOSED_CUM: 6506,  REMAIN_CUM: 8562,  TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-08-11', CREATED_WK: 314,  CLOSED_WK: 187,  CREATED_CUM: 15382,  CLOSED_CUM: 6693,  REMAIN_CUM: 8689,  TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 119,  CREATED_CUM: 15382,  CLOSED_CUM: 6812,  REMAIN_CUM: 8570,  TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-08-25', CREATED_WK: 613,  CLOSED_WK: 154,  CREATED_CUM: 15995,  CLOSED_CUM: 6966,  REMAIN_CUM: 9029,  TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-09-01', CREATED_WK: 277,  CLOSED_WK: 192,  CREATED_CUM: 16272,  CLOSED_CUM: 7158,  REMAIN_CUM: 9114,  TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-09-08', CREATED_WK: 462,  CLOSED_WK: 228,  CREATED_CUM: 16734,  CLOSED_CUM: 7386,  REMAIN_CUM: 9348,  TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-09-15', CREATED_WK: 278,  CLOSED_WK: 97,   CREATED_CUM: 17012,  CLOSED_CUM: 7483,  REMAIN_CUM: 9529,  TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-09-22', CREATED_WK: 278,  CLOSED_WK: 111,  CREATED_CUM: 17290,  CLOSED_CUM: 7594,  REMAIN_CUM: 9696,  TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-09-29', CREATED_WK: 312,  CLOSED_WK: 175,  CREATED_CUM: 17602,  CLOSED_CUM: 7769,  REMAIN_CUM: 9833,  TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-10-06', CREATED_WK: 303,  CLOSED_WK: 127,  CREATED_CUM: 17905,  CLOSED_CUM: 7896,  REMAIN_CUM: 10009, TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-10-13', CREATED_WK: 380,  CLOSED_WK: 108,  CREATED_CUM: 18285,  CLOSED_CUM: 8004,  REMAIN_CUM: 10281, TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-10-20', CREATED_WK: 284,  CLOSED_WK: 177,  CREATED_CUM: 18569,  CLOSED_CUM: 8181,  REMAIN_CUM: 10388, TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-10-27', CREATED_WK: 344,  CLOSED_WK: 199,  CREATED_CUM: 18913,  CLOSED_CUM: 8380,  REMAIN_CUM: 10533, TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-11-03', CREATED_WK: 173,  CLOSED_WK: 269,  CREATED_CUM: 19086,  CLOSED_CUM: 8649,  REMAIN_CUM: 10437, TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-11-10', CREATED_WK: 266,  CLOSED_WK: 258,  CREATED_CUM: 19352,  CLOSED_CUM: 8907,  REMAIN_CUM: 10445, TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-11-17', CREATED_WK: 162,  CLOSED_WK: 164,  CREATED_CUM: 19514,  CLOSED_CUM: 9071,  REMAIN_CUM: 10443, TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-11-24', CREATED_WK: 248,  CLOSED_WK: 201,  CREATED_CUM: 19762,  CLOSED_CUM: 9272,  REMAIN_CUM: 10490, TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-12-01', CREATED_WK: 30,   CLOSED_WK: 198,  CREATED_CUM: 19792,  CLOSED_CUM: 9470,  REMAIN_CUM: 10322, TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-12-08', CREATED_WK: 190,  CLOSED_WK: 296,  CREATED_CUM: 19982,  CLOSED_CUM: 9766,  REMAIN_CUM: 10216, TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-12-15', CREATED_WK: 384,  CLOSED_WK: 246,  CREATED_CUM: 20366,  CLOSED_CUM: 10012, REMAIN_CUM: 10354, TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-12-22', CREATED_WK: 226,  CLOSED_WK: 238,  CREATED_CUM: 20592,  CLOSED_CUM: 10250, REMAIN_CUM: 10342, TOTAL: 21344, },
    { ITEM: 'B', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-12-29', CREATED_WK: 752,  CLOSED_WK: 130,  CREATED_CUM: 21344,  CLOSED_CUM: 10380, REMAIN_CUM: 10964, TOTAL: 21344, },
  ],

  Queries: {
    // Overall
    SQL1: [
      {
        UNIT: 'OV',
        UNIT_DESC: 'OV',
        SSMCC_PLN: 11,  SSMCC_WD: 40,
        SSMCC_TOT: 696, SSMCC_ACT: 30,  SSMCC_REM: 666,  SSMCC_PRO: 4.3,  
        A_TOT: 114944,  A_ACT: 31703,   A_REM: 83241,   A_PRO: 27.6, 
        AP_TOT: 955,    AP_ACT: 403,    AP_REM: 552,    AP_PRO: 42.2, 
        B_TOT: 36044,   B_ACT: 10960,   B_REM: 25084,   B_PRO: 30.4, 
        BP_TOT: 940,    BP_ACT: 406,    BP_REM: 534,    BP_PRO: 43.2,
      },
    ], 

    // A & B Checksheet
    SQL2: [
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'NULL',              TOT: 114944, ACT: 31703, REM: 83241, PRO: 27.6, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           TOT: 3684,   ACT: 1115,  REM: 2569,  PRO: 30.3, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      TOT: 30950,  ACT: 5746,  REM: 25204, PRO: 18.6, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      TOT: 45586,  ACT: 10151, REM: 35435, PRO: 22.3, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      TOT: 1455,   ACT: 563,   REM: 892,   PRO: 38.7, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          TOT: 14948,  ACT: 6930,  REM: 8018,  PRO: 46.4, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      TOT: 7812,   ACT: 2358,  REM: 5454,  PRO: 30.2, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', TOT: 383,    ACT: 125,   REM: 258,   PRO: 32.6, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        TOT: 10126,  ACT: 4715,  REM: 5411,  PRO: 46.6, },
      { ITEM: 'B', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'NULL',              TOT: 36044,  ACT: 10960, REM: 25084, PRO: 30.4, },
      { ITEM: 'B', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      TOT: 1864,   ACT: 175,   REM: 1689,  PRO: 9.4, },
      { ITEM: 'B', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'F',  DISC_LONG: 'F-Fire Protection', TOT: 39,     ACT: 0,     REM: 39,    PRO: 0, },
      { ITEM: 'B', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      TOT: 11468,  ACT: 187,   REM: 11281, PRO: 1.6, },
      { ITEM: 'B', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      TOT: 1329,   ACT: 218,   REM: 1111,  PRO: 16.4, },
      { ITEM: 'B', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          TOT: 21344,  ACT: 10380, REM: 10964, PRO: 48.6, },
    ], 

    // Last Turnover Date
    SQL3: [ 
      { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     UNIT_NO: '21601', PLAN_MAX: '2024-03-06', },
      { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     UNIT_NO: '21605', PLAN_MAX: '2024-03-26', },
      { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', UNIT_NO: '21502', PLAN_MAX: '2024-04-20', },
      { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', UNIT_NO: '21501', PLAN_MAX: '2024-05-09', },
      { SCOPE: 'Grassroots', UNIT: 'GENERAL',     UNIT_NO: '20000', PLAN_MAX: '2024-06-21', },
      { SCOPE: 'Grassroots', UNIT: 'CWS',         UNIT_NO: '21200', PLAN_MAX: '2024-07-01', },
      { SCOPE: 'Grassroots', UNIT: 'HSFOC',       UNIT_NO: '21403', PLAN_MAX: '2024-07-10', },
      { SCOPE: 'Grassroots', UNIT: 'SRU',         UNIT_NO: '20700', PLAN_MAX: '2024-07-12', },
      { SCOPE: 'Grassroots', UNIT: 'SWS',         UNIT_NO: '20500', PLAN_MAX: '2024-07-20', },
      { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    UNIT_NO: '21402', PLAN_MAX: '2024-07-20', },
      { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    UNIT_NO: '21401', PLAN_MAX: '2024-07-22', },
      { SCOPE: 'Grassroots', UNIT: 'ARU',         UNIT_NO: '20600', PLAN_MAX: '2024-07-29', },
      { SCOPE: 'Grassroots', UNIT: 'DS',          UNIT_NO: '21300', PLAN_MAX: '2024-08-16', },
      { SCOPE: 'Grassroots', UNIT: 'PFBS',        UNIT_NO: '20101', PLAN_MAX: '2024-08-29', },
      { SCOPE: 'Grassroots', UNIT: 'U&O',         UNIT_NO: '21100', PLAN_MAX: '2024-09-02', },
      { SCOPE: 'Grassroots', UNIT: 'DAU',         UNIT_NO: '20100', PLAN_MAX: '2024-10-01', },
      { SCOPE: 'Grassroots', UNIT: 'HOS',         UNIT_NO: '20800', PLAN_MAX: '2024-10-01', },
      { SCOPE: 'Grassroots', UNIT: 'DHU S2',      UNIT_NO: '20300', PLAN_MAX: '2024-10-02', },
      { SCOPE: 'Grassroots', UNIT: 'DHU S1',      UNIT_NO: '20200', PLAN_MAX: '2024-10-08', },
      { SCOPE: 'Grassroots', UNIT: 'CCFU',        UNIT_NO: '20400', PLAN_MAX: '2024-10-24', },
      { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     UNIT_NO: '20401', PLAN_MAX: '2024-10-24', },
    ],

    // Canvas & Chart forms
    SQL4: [ 
      {
        CANVAS_WIDTH:           1300,
        CANVAS_HEIGHT:          850,
        
        PADDING_TOP:            290,
        PADDING_RIGHT:          90,
        PADDING_BOTTOM:         30,
        PADDING_LEFT:           90,

        START_DATE:             '2023-07-07', 
        END_DATE:               '2024-12-27',
        WEEKEND:                'friday',

        CUTOFF:                 '2024-01-26'
      }
    ],

  }
}