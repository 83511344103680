
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 5000,
    CanvasBorderWeight: 0.2,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',//0E1B2B
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#fff',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },


  Text: {
  },
  

  DataItems: [
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     UNIT_NO: '21601', UNIT_DESC: 'Electrical System-Substation SSV',              SSMCC_TOT: 31, SSMCC_PLN: 16, SSMCC_WD: 23, SSMCC_ACT: 14, SSMCC_REM: 17, SSMCC_PRO: 45.2, MIN_PLAN: '2023-10-07', MAX_PLAN: '2024-03-28', A_TOT: 793,   A_ACT: 704,  A_REM: 89,    A_PRO: 88.8, B_TOT: 160,  B_ACT: 121,  B_REM: 39,   B_PRO: 75.6, C_TOT: 27,  C_ACT: 0, C_REM: 27,  C_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     UNIT_NO: '21605', UNIT_DESC: 'Electrical System-Substation SSW',              SSMCC_TOT: 29, SSMCC_PLN: 14, SSMCC_WD: 19, SSMCC_ACT: 14, SSMCC_REM: 15, SSMCC_PRO: 48.3, MIN_PLAN: '2023-10-31', MAX_PLAN: '2024-04-11', A_TOT: 550,   A_ACT: 468,  A_REM: 82,    A_PRO: 85.1, B_TOT: 121,  B_ACT: 72,   B_REM: 49,   B_PRO: 59.5, C_TOT: 25,  C_ACT: 0, C_REM: 25,  C_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', UNIT_NO: '21502', UNIT_DESC: 'Instrument & Control System-RIE 02 - PAB 1603', SSMCC_TOT: 15, SSMCC_PLN: 1,  SSMCC_WD: 2,  SSMCC_ACT: 1,  SSMCC_REM: 14, SSMCC_PRO: 6.7,  MIN_PLAN: '2023-10-07', MAX_PLAN: '2024-04-26', A_TOT: 104,   A_ACT: 51,   A_REM: 53,    A_PRO: 49,   B_TOT: 81,   B_ACT: 55,   B_REM: 26,   B_PRO: 67.9, C_TOT: 4,   C_ACT: 0, C_REM: 4,   C_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', UNIT_NO: '21501', UNIT_DESC: 'Instrument & Control System-RIE 01 - PAB 1603', SSMCC_TOT: 15, SSMCC_PLN: 2,  SSMCC_WD: 2,  SSMCC_ACT: 1,  SSMCC_REM: 14, SSMCC_PRO: 6.7,  MIN_PLAN: '2023-10-31', MAX_PLAN: '2024-05-10', A_TOT: 51,    A_ACT: 36,   A_REM: 15,    A_PRO: 70.6, B_TOT: 78,   B_ACT: 42,   B_REM: 36,   B_PRO: 53.8, C_TOT: 4,   C_ACT: 0, C_REM: 4,   C_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         UNIT_NO: '21200', UNIT_DESC: 'Cooling Water System',                          SSMCC_TOT: 32, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 32, SSMCC_PRO: 0,    MIN_PLAN: '2024-02-24', MAX_PLAN: '2024-07-12', A_TOT: 1659,  A_ACT: 398,  A_REM: 1261,  A_PRO: 24,   B_TOT: 900,  B_ACT: 308,  B_REM: 592,  B_PRO: 34.2, C_TOT: 36,  C_ACT: 0, C_REM: 36,  C_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     UNIT_NO: '20000', UNIT_DESC: 'GENERAL',                                       SSMCC_TOT: 12, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 12, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-11', MAX_PLAN: '2024-07-15', A_TOT: 802,   A_ACT: 185,  A_REM: 617,   A_PRO: 23.1, B_TOT: 98,   B_ACT: 13,   B_REM: 85,   B_PRO: 13.3, C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         UNIT_NO: '20700', UNIT_DESC: 'Sulfur Recovery Unit',                          SSMCC_TOT: 39, SSMCC_PLN: 0,  SSMCC_WD: 1,  SSMCC_ACT: 0,  SSMCC_REM: 39, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-02', MAX_PLAN: '2024-07-19', A_TOT: 8018,  A_ACT: 4316, A_REM: 3702,  A_PRO: 53.8, B_TOT: 2225, B_ACT: 1047, B_REM: 1178, B_PRO: 47.1, C_TOT: 158, C_ACT: 0, C_REM: 158, C_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    UNIT_NO: '21402', UNIT_DESC: 'Tank Farm South',                               SSMCC_TOT: 24, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 24, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-15', MAX_PLAN: '2024-07-26', A_TOT: 2647,  A_ACT: 664,  A_REM: 1983,  A_PRO: 25.1, B_TOT: 315,  B_ACT: 82,   B_REM: 233,  B_PRO: 26,   C_TOT: 39,  C_ACT: 0, C_REM: 39,  C_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       UNIT_NO: '21403', UNIT_DESC: 'High Sulphur Fuel Oil Cooler',                  SSMCC_TOT: 31, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 31, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-02', MAX_PLAN: '2024-08-02', A_TOT: 1954,  A_ACT: 1173, A_REM: 781,   A_PRO: 60,   B_TOT: 625,  B_ACT: 310,  B_REM: 315,  B_PRO: 49.6, C_TOT: 57,  C_ACT: 0, C_REM: 57,  C_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'DS',          UNIT_NO: '21300', UNIT_DESC: 'Disposal Systems',                              SSMCC_TOT: 29, SSMCC_PLN: 1,  SSMCC_WD: 1,  SSMCC_ACT: 1,  SSMCC_REM: 28, SSMCC_PRO: 3.4,  MIN_PLAN: '2024-01-24', MAX_PLAN: '2024-08-02', A_TOT: 3455,  A_ACT: 1235, A_REM: 2220,  A_PRO: 35.7, B_TOT: 1576, B_ACT: 512,  B_REM: 1064, B_PRO: 32.5, C_TOT: 7,   C_ACT: 0, C_REM: 7,   C_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    UNIT_NO: '21401', UNIT_DESC: 'Tank Farm North',                               SSMCC_TOT: 24, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 24, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-13', MAX_PLAN: '2024-08-08', A_TOT: 4408,  A_ACT: 594,  A_REM: 3814,  A_PRO: 13.5, B_TOT: 1100, B_ACT: 190,  B_REM: 910,  B_PRO: 17.3, C_TOT: 69,  C_ACT: 0, C_REM: 69,  C_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         UNIT_NO: '20600', UNIT_DESC: 'Amine Regeneration Unit',                       SSMCC_TOT: 29, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 29, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-02', MAX_PLAN: '2024-08-08', A_TOT: 2895,  A_ACT: 1104, A_REM: 1791,  A_PRO: 38.1, B_TOT: 1110, B_ACT: 435,  B_REM: 675,  B_PRO: 39.2, C_TOT: 36,  C_ACT: 0, C_REM: 36,  C_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         UNIT_NO: '20500', UNIT_DESC: 'Sour Water Stripper Unit',                      SSMCC_TOT: 24, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 24, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-02', MAX_PLAN: '2024-08-17', A_TOT: 1409,  A_ACT: 713,  A_REM: 696,   A_PRO: 50.6, B_TOT: 603,  B_ACT: 307,  B_REM: 296,  B_PRO: 50.9, C_TOT: 18,  C_ACT: 0, C_REM: 18,  C_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        UNIT_NO: '20101', UNIT_DESC: 'Prefractionator Bottoms Stripper',              SSMCC_TOT: 21, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 21, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-25', MAX_PLAN: '2024-08-28', A_TOT: 1862,  A_ACT: 752,  A_REM: 1110,  A_PRO: 40.4, B_TOT: 599,  B_ACT: 224,  B_REM: 375,  B_PRO: 37.4, C_TOT: 27,  C_ACT: 0, C_REM: 27,  C_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         UNIT_NO: '21100', UNIT_DESC: 'Utilities & Offsites',                          SSMCC_TOT: 95, SSMCC_PLN: 0,  SSMCC_WD: 1,  SSMCC_ACT: 0,  SSMCC_REM: 95, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-20', MAX_PLAN: '2024-09-25', A_TOT: 9377,  A_ACT: 4785, A_REM: 4592,  A_PRO: 51,   B_TOT: 4429, B_ACT: 2792, B_REM: 1637, B_PRO: 63,   C_TOT: 113, C_ACT: 0, C_REM: 113, C_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         UNIT_NO: '20100', UNIT_DESC: 'Deasphalting Unit',                             SSMCC_TOT: 42, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 42, SSMCC_PRO: 0,    MIN_PLAN: '2024-02-24', MAX_PLAN: '2024-10-11', A_TOT: 9002,  A_ACT: 3738, A_REM: 5264,  A_PRO: 41.5, B_TOT: 2860, B_ACT: 1036, B_REM: 1824, B_PRO: 36.2, C_TOT: 134, C_ACT: 0, C_REM: 134, C_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      UNIT_NO: '20300', UNIT_DESC: 'Hydroprocessing Unit',                          SSMCC_TOT: 30, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 30, SSMCC_PRO: 0,    MIN_PLAN: '2024-02-24', MAX_PLAN: '2024-10-23', A_TOT: 8353,  A_ACT: 1837, A_REM: 6516,  A_PRO: 22,   B_TOT: 2397, B_ACT: 573,  B_REM: 1824, B_PRO: 23.9, C_TOT: 61,  C_ACT: 0, C_REM: 61,  C_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      UNIT_NO: '20200', UNIT_DESC: 'Hydroprocessing Unit',                          SSMCC_TOT: 68, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 68, SSMCC_PRO: 0,    MIN_PLAN: '2024-04-15', MAX_PLAN: '2024-10-25', A_TOT: 36136, A_ACT: 5836, A_REM: 30300, A_PRO: 16.2, B_TOT: 9245, B_ACT: 1591, B_REM: 7654, B_PRO: 17.2, C_TOT: 350, C_ACT: 0, C_REM: 350, C_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         UNIT_NO: '20800', UNIT_DESC: 'Hot Oil System',                                SSMCC_TOT: 28, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 28, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-02', MAX_PLAN: '2024-10-29', A_TOT: 5313,  A_ACT: 1611, A_REM: 3702,  A_PRO: 30.3, B_TOT: 1584, B_ACT: 502,  B_REM: 1082, B_PRO: 31.7, C_TOT: 52,  C_ACT: 0, C_REM: 52,  C_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        UNIT_NO: '20400', UNIT_DESC: 'Chemical Clean Fuel Unit',                      SSMCC_TOT: 49, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 49, SSMCC_PRO: 0,    MIN_PLAN: '2024-04-05', MAX_PLAN: '2024-10-30', A_TOT: 13991, A_ACT: 2919, A_REM: 11072, A_PRO: 20.9, B_TOT: 4454, B_ACT: 906,  B_REM: 3548, B_PRO: 20.3, C_TOT: 197, C_ACT: 0, C_REM: 197, C_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     UNIT_NO: '20401', UNIT_DESC: 'Chemical Clean Fuel Unit Solid Separation',     SSMCC_TOT: 29, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 29, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-25', MAX_PLAN: '2024-10-30', A_TOT: 2406,  A_ACT: 845,  A_REM: 1561,  A_PRO: 35.1, B_TOT: 1224, B_ACT: 287,  B_REM: 937,  B_PRO: 23.4, C_TOT: 26,  C_ACT: 0, C_REM: 26,  C_PRO: 0, },
  ],


  Queries: {
    SQL1: [ 
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-2003', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-C-0101', CSTD_PLAN: '2024-03-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 60,    A_ACT: 8,    A_REM: 52,    A_PRO: 13,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-C-0501', CSTD_PLAN: '2024-03-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 23,    A_ACT: 10,   A_REM: 13,    A_PRO: 43,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-1101', CSTD_PLAN: '2024-03-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 44,    A_ACT: 26,   A_REM: 18,    A_PRO: 59,  B_TOT: 38,   B_ACT: 26,  B_REM: 12,   B_PRO: 68, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-1001', CSTD_PLAN: '2024-03-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 42,    A_ACT: 18,   A_REM: 24,    A_PRO: 42,  B_TOT: 28,   B_ACT: 14,  B_REM: 14,   B_PRO: 50, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-C-0801', CSTD_PLAN: '2024-03-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 260,   A_ACT: 133,  A_REM: 127,   A_PRO: 51,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-E-1301', CSTD_PLAN: '2024-04-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 47,    A_ACT: 22,   A_REM: 25,    A_PRO: 46,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-2102', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 36,    A_ACT: 36,   A_REM: 0,     A_PRO: 100, B_TOT: 25,   B_ACT: 24,  B_REM: 1,    B_PRO: 96, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-I-0301', CSTD_PLAN: '2024-05-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 36,    A_ACT: 0,    A_REM: 36,    A_PRO: 0,   B_TOT: 8,    B_ACT: 0,   B_REM: 8,    B_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-C-0401', CSTD_PLAN: '2024-05-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 21,    A_ACT: 0,    A_REM: 21,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-C-0201', CSTD_PLAN: '2024-05-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1,     A_ACT: 1,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-E-1001', CSTD_PLAN: '2024-05-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-E-1101', CSTD_PLAN: '2024-05-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 71,    A_ACT: 36,   A_REM: 35,    A_PRO: 50,  B_TOT: 13,   B_ACT: 0,   B_REM: 13,   B_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-1202', CSTD_PLAN: '2024-05-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 26,    A_ACT: 20,   A_REM: 6,     A_PRO: 76,  B_TOT: 24,   B_ACT: 20,  B_REM: 4,    B_PRO: 83, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-4001', CSTD_PLAN: '2024-06-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 45,    A_ACT: 33,   A_REM: 12,    A_PRO: 73,  B_TOT: 40,   B_ACT: 32,  B_REM: 8,    B_PRO: 80, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-2103', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 172,   A_ACT: 84,   A_REM: 88,    A_PRO: 48,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-6102', CSTD_PLAN: '2024-06-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 36,    A_ACT: 20,   A_REM: 16,    A_PRO: 55,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-2001', CSTD_PLAN: '2024-06-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 33,    A_ACT: 27,   A_REM: 6,     A_PRO: 81,  B_TOT: 13,   B_ACT: 10,  B_REM: 3,    B_PRO: 76, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-P-0501', CSTD_PLAN: '2024-06-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 108,   A_ACT: 2,    A_REM: 106,   A_PRO: 1,   B_TOT: 36,   B_ACT: 4,   B_REM: 32,   B_PRO: 11, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-3301', CSTD_PLAN: '2024-06-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 8,    A_REM: 2,     A_PRO: 80,  B_TOT: 3,    B_ACT: 2,   B_REM: 1,    B_PRO: 66, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-4601', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-3001', CSTD_PLAN: '2024-07-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 86,    A_ACT: 52,   A_REM: 34,    A_PRO: 60,  B_TOT: 44,   B_ACT: 33,  B_REM: 11,   B_PRO: 75, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-4101', CSTD_PLAN: '2024-07-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-C-5401', CSTD_PLAN: '2024-07-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 50,    A_ACT: 7,    A_REM: 43,    A_PRO: 14,  B_TOT: 4,    B_ACT: 2,   B_REM: 2,    B_PRO: 50, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-P-0101', CSTD_PLAN: '2024-07-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 565,   A_ACT: 229,  A_REM: 336,   A_PRO: 40,  B_TOT: 267,  B_ACT: 102, B_REM: 165,  B_PRO: 38, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-I-0101', CSTD_PLAN: '2024-07-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 27,    A_ACT: 1,    A_REM: 26,    A_PRO: 3,   B_TOT: 9,    B_ACT: 0,   B_REM: 9,    B_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-P-0201', CSTD_PLAN: '2024-07-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 81,    A_ACT: 17,   A_REM: 64,    A_PRO: 21,  B_TOT: 26,   B_ACT: 2,   B_REM: 24,   B_PRO: 7, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-P-0401', CSTD_PLAN: '2024-08-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 306,   A_ACT: 74,   A_REM: 232,   A_PRO: 24,  B_TOT: 200,  B_ACT: 56,  B_REM: 144,  B_PRO: 28, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-P-0301', CSTD_PLAN: '2024-08-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 687,   A_ACT: 234,  A_REM: 453,   A_PRO: 34,  B_TOT: 320,  B_ACT: 106, B_REM: 214,  B_PRO: 33, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0404', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 38,    A_ACT: 5,    A_REM: 33,    A_PRO: 13,  B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-C-0101', CSTD_PLAN: '2024-04-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 100,   A_ACT: 67,   A_REM: 33,    A_PRO: 67,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-I-0202', CSTD_PLAN: '2024-04-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 21,    A_ACT: 10,   A_REM: 11,    A_PRO: 47,  B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-C-0501', CSTD_PLAN: '2024-04-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 43,    A_ACT: 12,   A_REM: 31,    A_PRO: 27,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-I-0301', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 281,   A_ACT: 0,    A_REM: 281,   A_PRO: 0,   B_TOT: 58,   B_ACT: 0,   B_REM: 58,   B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-I-0401', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 7,     A_ACT: 0,    A_REM: 7,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-T-0101', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 41,    A_ACT: 0,    A_REM: 41,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-C-0801', CSTD_PLAN: '2024-04-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 495,   A_ACT: 130,  A_REM: 365,   A_PRO: 26,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-E-1001', CSTD_PLAN: '2024-05-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 23,    A_ACT: 7,    A_REM: 16,    A_PRO: 30,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-I-0201', CSTD_PLAN: '2024-05-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 41,    A_ACT: 13,   A_REM: 28,    A_PRO: 31,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-1001', CSTD_PLAN: '2024-05-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 63,    A_ACT: 24,   A_REM: 39,    A_PRO: 38,  B_TOT: 46,   B_ACT: 22,  B_REM: 24,   B_PRO: 47, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-1202', CSTD_PLAN: '2024-05-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 24,    A_ACT: 10,   A_REM: 14,    A_PRO: 41,  B_TOT: 20,   B_ACT: 9,   B_REM: 11,   B_PRO: 45, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-1101', CSTD_PLAN: '2024-05-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 25,    A_ACT: 12,   A_REM: 13,    A_PRO: 48,  B_TOT: 24,   B_ACT: 11,  B_REM: 13,   B_PRO: 45, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-2301', CSTD_PLAN: '2024-05-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 43,    A_ACT: 11,   A_REM: 32,    A_PRO: 25,  B_TOT: 17,   B_ACT: 3,   B_REM: 14,   B_PRO: 17, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-2401', CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 58,    A_ACT: 21,   A_REM: 37,    A_PRO: 36,  B_TOT: 23,   B_ACT: 5,   B_REM: 18,   B_PRO: 21, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-C-0201', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 50,    A_ACT: 17,   A_REM: 33,    A_PRO: 34,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-4101', CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 14,    A_ACT: 0,    A_REM: 14,    A_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-4001', CSTD_PLAN: '2024-06-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 69,    A_ACT: 17,   A_REM: 52,    A_PRO: 24,  B_TOT: 38,   B_ACT: 13,  B_REM: 25,   B_PRO: 34, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-4601', CSTD_PLAN: '2024-06-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 3,     A_ACT: 1,    A_REM: 2,     A_PRO: 33,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0702', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 447,   A_ACT: 148,  A_REM: 299,   A_PRO: 33,  B_TOT: 344,  B_ACT: 145, B_REM: 199,  B_PRO: 42, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-C-0401', CSTD_PLAN: '2024-06-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 32,    A_ACT: 0,    A_REM: 32,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-E-1301', CSTD_PLAN: '2024-06-21', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 94,    A_ACT: 36,   A_REM: 58,    A_PRO: 38,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-E-1101', CSTD_PLAN: '2024-06-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 99,    A_ACT: 24,   A_REM: 75,    A_PRO: 24,  B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0701', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 111,   A_ACT: 42,   A_REM: 69,    A_PRO: 37,  B_TOT: 83,   B_ACT: 33,  B_REM: 50,   B_PRO: 39, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-2101', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 13,   A_REM: 15,    A_PRO: 46,  B_TOT: 21,   B_ACT: 10,  B_REM: 11,   B_PRO: 47, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-2103', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 420,   A_ACT: 100,  A_REM: 320,   A_PRO: 23,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-C-5401', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 83,    A_ACT: 23,   A_REM: 60,    A_PRO: 27,  B_TOT: 22,   B_ACT: 14,  B_REM: 8,    B_PRO: 63, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-7401', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 214,   A_ACT: 37,   A_REM: 177,   A_PRO: 17,  B_TOT: 64,   B_ACT: 4,   B_REM: 60,   B_PRO: 6, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0401', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 282,   A_ACT: 33,   A_REM: 249,   A_PRO: 11,  B_TOT: 79,   B_ACT: 1,   B_REM: 78,   B_PRO: 1, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0208', CSTD_PLAN: '2024-07-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 36,    A_ACT: 7,    A_REM: 29,    A_PRO: 19,  B_TOT: 15,   B_ACT: 0,   B_REM: 15,   B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0302', CSTD_PLAN: '2024-07-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 522,   A_ACT: 130,  A_REM: 392,   A_PRO: 24,  B_TOT: 187,  B_ACT: 51,  B_REM: 136,  B_PRO: 27, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0501', CSTD_PLAN: '2024-07-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 174,   A_ACT: 12,   A_REM: 162,   A_PRO: 6,   B_TOT: 65,   B_ACT: 1,   B_REM: 64,   B_PRO: 1, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0601', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 74,    A_ACT: 53,   A_REM: 21,    A_PRO: 71,  B_TOT: 32,   B_ACT: 26,  B_REM: 6,    B_PRO: 81, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0203', CSTD_PLAN: '2024-08-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 330,   A_ACT: 180,  A_REM: 150,   A_PRO: 54,  B_TOT: 145,  B_ACT: 83,  B_REM: 62,   B_PRO: 57, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0206', CSTD_PLAN: '2024-08-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 113,   A_ACT: 22,   A_REM: 91,    A_PRO: 19,  B_TOT: 28,   B_ACT: 0,   B_REM: 28,   B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0303', CSTD_PLAN: '2024-08-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 368,   A_ACT: 108,  A_REM: 260,   A_PRO: 29,  B_TOT: 166,  B_ACT: 35,  B_REM: 131,  B_PRO: 21, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0602', CSTD_PLAN: '2024-08-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 148,   A_ACT: 40,   A_REM: 108,   A_PRO: 27,  B_TOT: 58,   B_ACT: 4,   B_REM: 54,   B_PRO: 6, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-6006', CSTD_PLAN: '2024-08-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 41,    A_ACT: 12,   A_REM: 29,    A_PRO: 29,  B_TOT: 32,   B_ACT: 11,  B_REM: 21,   B_PRO: 34, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0202', CSTD_PLAN: '2024-08-21', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 391,   A_ACT: 45,   A_REM: 346,   A_PRO: 11,  B_TOT: 109,  B_ACT: 11,  B_REM: 98,   B_PRO: 10, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0402', CSTD_PLAN: '2024-08-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 748,   A_ACT: 269,  A_REM: 479,   A_PRO: 36,  B_TOT: 304,  B_ACT: 113, B_REM: 191,  B_PRO: 37, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0403', CSTD_PLAN: '2024-08-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1172,  A_ACT: 170,  A_REM: 1002,  A_PRO: 14,  B_TOT: 266,  B_ACT: 0,   B_REM: 266,  B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0201', CSTD_PLAN: '2024-08-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 507,   A_ACT: 202,  A_REM: 305,   A_PRO: 39,  B_TOT: 255,  B_ACT: 118, B_REM: 137,  B_PRO: 46, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0204', CSTD_PLAN: '2024-09-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 537,   A_ACT: 152,  A_REM: 385,   A_PRO: 28,  B_TOT: 209,  B_ACT: 70,  B_REM: 139,  B_PRO: 33, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0207', CSTD_PLAN: '2024-09-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 3011,  A_ACT: 348,  A_REM: 2663,  A_PRO: 11,  B_TOT: 645,  B_ACT: 0,   B_REM: 645,  B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0305', CSTD_PLAN: '2024-10-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 408,   A_ACT: 27,   A_REM: 381,   A_PRO: 6,   B_TOT: 150,  B_ACT: 6,   B_REM: 144,  B_PRO: 4, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0304', CSTD_PLAN: '2024-10-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 451,   A_ACT: 27,   A_REM: 424,   A_PRO: 6,   B_TOT: 153,  B_ACT: 6,   B_REM: 147,  B_PRO: 3, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0205', CSTD_PLAN: '2024-10-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 339,   A_ACT: 10,   A_REM: 329,   A_PRO: 2,   B_TOT: 181,  B_ACT: 20,  B_REM: 161,  B_PRO: 11, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-I-0101', CSTD_PLAN: '2024-10-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 213,   A_ACT: 3,    A_REM: 210,   A_PRO: 1,   B_TOT: 67,   B_ACT: 0,   B_REM: 67,   B_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0301', CSTD_PLAN: '2024-10-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1149,  A_ACT: 239,  A_REM: 910,   A_PRO: 20,  B_TOT: 507,  B_ACT: 69,  B_REM: 438,  B_PRO: 13, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-2103', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-C-0901', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 3,     A_ACT: 0,    A_REM: 3,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-1001', CSTD_PLAN: '2024-03-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 40,    A_ACT: 18,   A_REM: 22,    A_PRO: 45,  B_TOT: 28,   B_ACT: 14,  B_REM: 14,   B_PRO: 50, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-1101', CSTD_PLAN: '2024-04-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 34,    A_ACT: 22,   A_REM: 12,    A_PRO: 64,  B_TOT: 30,   B_ACT: 22,  B_REM: 8,    B_PRO: 73, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-C-0101', CSTD_PLAN: '2024-04-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 51,    A_ACT: 51,   A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-4101', CSTD_PLAN: '2024-05-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 20,    A_ACT: 9,    A_REM: 11,    A_PRO: 45,  B_TOT: 13,   B_ACT: 6,   B_REM: 7,    B_PRO: 46, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-4001', CSTD_PLAN: '2024-05-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 81,    A_ACT: 42,   A_REM: 39,    A_PRO: 51,  B_TOT: 68,   B_ACT: 42,  B_REM: 26,   B_PRO: 61, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-4601', CSTD_PLAN: '2024-05-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 3,     A_ACT: 1,    A_REM: 2,     A_PRO: 33,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-2101', CSTD_PLAN: '2024-05-21', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 28,   A_REM: 0,     A_PRO: 100, B_TOT: 21,   B_ACT: 20,  B_REM: 1,    B_PRO: 95, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-1201', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 64,    A_ACT: 26,   A_REM: 38,    A_PRO: 40,  B_TOT: 40,   B_ACT: 14,  B_REM: 26,   B_PRO: 35, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-C-0501', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 23,    A_ACT: 10,   A_REM: 13,    A_PRO: 43,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-7402', CSTD_PLAN: '2024-06-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 44,    A_ACT: 21,   A_REM: 23,    A_PRO: 47,  B_TOT: 18,   B_ACT: 10,  B_REM: 8,    B_PRO: 55, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-C-5401', CSTD_PLAN: '2024-06-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 24,    A_ACT: 7,    A_REM: 17,    A_PRO: 29,  B_TOT: 6,    B_ACT: 4,   B_REM: 2,    B_PRO: 66, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-3101', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 22,    A_ACT: 22,   A_REM: 0,     A_PRO: 100, B_TOT: 16,   B_ACT: 16,  B_REM: 0,    B_PRO: 100, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-P-0801', CSTD_PLAN: '2024-06-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 75,    A_ACT: 35,   A_REM: 40,    A_PRO: 46,  B_TOT: 44,   B_ACT: 15,  B_REM: 29,   B_PRO: 34, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-P-0802', CSTD_PLAN: '2024-06-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 215,   A_ACT: 67,   A_REM: 148,   A_PRO: 31,  B_TOT: 106,  B_ACT: 27,  B_REM: 79,   B_PRO: 25, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-P-0104', CSTD_PLAN: '2024-06-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 189,   A_ACT: 62,   A_REM: 127,   A_PRO: 32,  B_TOT: 75,   B_ACT: 1,   B_REM: 74,   B_PRO: 1, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-C-0801', CSTD_PLAN: '2024-07-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 108,   A_ACT: 63,   A_REM: 45,    A_PRO: 58,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-4301', CSTD_PLAN: '2024-07-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 22,    A_ACT: 10,   A_REM: 12,    A_PRO: 45,  B_TOT: 18,   B_ACT: 10,  B_REM: 8,    B_PRO: 55, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-E-0101', CSTD_PLAN: '2024-07-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-P-0102', CSTD_PLAN: '2024-08-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 155,   A_ACT: 41,   A_REM: 114,   A_PRO: 26,  B_TOT: 55,   B_ACT: 12,  B_REM: 43,   B_PRO: 21, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-P-0101', CSTD_PLAN: '2024-08-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 630,   A_ACT: 84,   A_REM: 546,   A_PRO: 13,  B_TOT: 475,  B_ACT: 23,  B_REM: 452,  B_PRO: 4, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-C-0201', CSTD_PLAN: '2024-08-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 3,    A_REM: 7,     A_PRO: 30,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-E-1001', CSTD_PLAN: '2024-08-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 3,    A_REM: 3,     A_PRO: 50,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-E-1101', CSTD_PLAN: '2024-08-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 27,    A_ACT: 16,   A_REM: 11,    A_PRO: 59,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-P-0103', CSTD_PLAN: '2024-09-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 464,   A_ACT: 159,  A_REM: 305,   A_PRO: 34,  B_TOT: 189,  B_ACT: 45,  B_REM: 144,  B_PRO: 23, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-C-0401', CSTD_PLAN: '2024-09-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-I-0301', CSTD_PLAN: '2024-10-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 3,    A_REM: 6,     A_PRO: 33,  B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-I-0101', CSTD_PLAN: '2024-10-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 37,    A_ACT: 0,    A_REM: 37,    A_PRO: 0,   B_TOT: 11,   B_ACT: 0,   B_REM: 11,   B_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-C-0101', CSTD_PLAN: '2024-02-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 11,    A_ACT: 1,    A_REM: 10,    A_PRO: 9,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-C-0501', CSTD_PLAN: '2024-03-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-C-0801', CSTD_PLAN: '2024-03-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 48,    A_ACT: 0,    A_REM: 48,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-I-0202', CSTD_PLAN: '2024-04-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 0,    A_REM: 9,     A_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-C-0401', CSTD_PLAN: '2024-04-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 14,    A_ACT: 0,    A_REM: 14,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-I-0301', CSTD_PLAN: '2024-04-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-T-0101', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 16,    A_ACT: 0,    A_REM: 16,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-I-0401', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 7,     A_ACT: 0,    A_REM: 7,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-E-1001', CSTD_PLAN: '2024-04-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 3,     A_ACT: 0,    A_REM: 3,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-1001', CSTD_PLAN: '2024-04-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 13,    A_ACT: 6,    A_REM: 7,     A_PRO: 46,  B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-1101', CSTD_PLAN: '2024-04-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 6,    A_REM: 6,     A_PRO: 50,  B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-I-0201', CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 15,    A_ACT: 0,    A_REM: 15,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-E-1101', CSTD_PLAN: '2024-05-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-4302', CSTD_PLAN: '2024-05-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 101,   A_ACT: 65,   A_REM: 36,    A_PRO: 64,  B_TOT: 90,   B_ACT: 62,  B_REM: 28,   B_PRO: 68, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-4307', CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 43,    A_ACT: 16,   A_REM: 27,    A_PRO: 37,  B_TOT: 34,   B_ACT: 16,  B_REM: 18,   B_PRO: 47, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-1201', CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 6,    A_REM: 6,     A_PRO: 50,  B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-4001', CSTD_PLAN: '2024-06-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 12,   A_REM: 16,    A_PRO: 42,  B_TOT: 15,   B_ACT: 10,  B_REM: 5,    B_PRO: 66, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-2001', CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 44,    A_ACT: 4,    A_REM: 40,    A_PRO: 9,   B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-4306', CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 99,    A_ACT: 25,   A_REM: 74,    A_PRO: 25,  B_TOT: 74,   B_ACT: 24,  B_REM: 50,   B_PRO: 32, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-3101', CSTD_PLAN: '2024-06-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-2201', CSTD_PLAN: '2024-06-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 27,    A_ACT: 3,    A_REM: 24,    A_PRO: 11,  B_TOT: 9,    B_ACT: 0,   B_REM: 9,    B_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-2102', CSTD_PLAN: '2024-06-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 9,    B_ACT: 8,   B_REM: 1,    B_PRO: 88, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-4601', CSTD_PLAN: '2024-06-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 3,     A_ACT: 1,    A_REM: 2,     A_PRO: 33,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-3301', CSTD_PLAN: '2024-06-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 3,     A_ACT: 0,    A_REM: 3,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-P-0102', CSTD_PLAN: '2024-06-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 56,    A_ACT: 1,    A_REM: 55,    A_PRO: 1,   B_TOT: 25,   B_ACT: 0,   B_REM: 25,   B_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-P-0103', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 55,    A_ACT: 1,    A_REM: 54,    A_PRO: 1,   B_TOT: 24,   B_ACT: 0,   B_REM: 24,   B_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-P-0101', CSTD_PLAN: '2024-06-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 73,    A_ACT: 0,    A_REM: 73,    A_PRO: 0,   B_TOT: 40,   B_ACT: 0,   B_REM: 40,   B_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-4305', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 173,   A_ACT: 62,   A_REM: 111,   A_PRO: 35,  B_TOT: 106,  B_ACT: 40,  B_REM: 66,   B_PRO: 37, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-C-5401', CSTD_PLAN: '2024-07-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 30,    A_ACT: 10,   A_REM: 20,    A_PRO: 33,  B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-4301', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 369,   A_ACT: 11,   A_REM: 358,   A_PRO: 3,   B_TOT: 161,  B_ACT: 0,   B_REM: 161,  B_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-4303', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 257,   A_ACT: 67,   A_REM: 190,   A_PRO: 26,  B_TOT: 190,  B_ACT: 66,  B_REM: 124,  B_PRO: 34, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-4304', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 147,   A_ACT: 78,   A_REM: 69,    A_PRO: 53,  B_TOT: 94,   B_ACT: 48,  B_REM: 46,   B_PRO: 51, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-C-0101', CSTD_PLAN: '2024-02-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 109,   A_ACT: 29,   A_REM: 80,    A_PRO: 26,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-C-0501', CSTD_PLAN: '2024-03-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 26,    A_ACT: 10,   A_REM: 16,    A_PRO: 38,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-C-0801', CSTD_PLAN: '2024-03-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 409,   A_ACT: 219,  A_REM: 190,   A_PRO: 53,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-1001', CSTD_PLAN: '2024-03-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 57,    A_ACT: 36,   A_REM: 21,    A_PRO: 63,  B_TOT: 36,   B_ACT: 24,  B_REM: 12,   B_PRO: 66, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-1101', CSTD_PLAN: '2024-04-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 54,    A_ACT: 24,   A_REM: 30,    A_PRO: 44,  B_TOT: 44,   B_ACT: 24,  B_REM: 20,   B_PRO: 54, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-C-0201', CSTD_PLAN: '2024-04-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 72,    A_ACT: 57,   A_REM: 15,    A_PRO: 79,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-E-1001', CSTD_PLAN: '2024-04-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 11,    A_ACT: 3,    A_REM: 8,     A_PRO: 27,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-I-0201', CSTD_PLAN: '2024-04-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 0,    A_REM: 28,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-I-0202', CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-E-1301', CSTD_PLAN: '2024-05-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 27,    A_ACT: 11,   A_REM: 16,    A_PRO: 40,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-E-1101', CSTD_PLAN: '2024-05-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 73,    A_ACT: 30,   A_REM: 43,    A_PRO: 41,  B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-I-0301', CSTD_PLAN: '2024-05-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 118,   A_ACT: 3,    A_REM: 115,   A_PRO: 2,   B_TOT: 24,   B_ACT: 0,   B_REM: 24,   B_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-T-0101', CSTD_PLAN: '2024-05-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 31,    A_ACT: 0,    A_REM: 31,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-I-0401', CSTD_PLAN: '2024-05-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-1202', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 22,    A_ACT: 16,   A_REM: 6,     A_PRO: 72,  B_TOT: 20,   B_ACT: 16,  B_REM: 4,    B_PRO: 80, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-C-0401', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 0,    A_REM: 28,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-2101', CSTD_PLAN: '2024-06-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 161,   A_ACT: 108,  A_REM: 53,    A_PRO: 67,  B_TOT: 61,   B_ACT: 34,  B_REM: 27,   B_PRO: 55, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-2102', CSTD_PLAN: '2024-06-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 21,    A_ACT: 20,   A_REM: 1,     A_PRO: 95,  B_TOT: 15,   B_ACT: 14,  B_REM: 1,    B_PRO: 93, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-4001', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 32,    A_ACT: 26,   A_REM: 6,     A_PRO: 81,  B_TOT: 30,   B_ACT: 26,  B_REM: 4,    B_PRO: 86, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-4601', CSTD_PLAN: '2024-07-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 8,    A_REM: 20,    A_PRO: 28,  B_TOT: 27,   B_ACT: 0,   B_REM: 27,   B_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-4101', CSTD_PLAN: '2024-07-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-C-5401', CSTD_PLAN: '2024-07-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 40,    A_ACT: 9,    A_REM: 31,    A_PRO: 22,  B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-2103', CSTD_PLAN: '2024-07-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1092,  A_ACT: 500,  A_REM: 592,   A_PRO: 45,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-7401', CSTD_PLAN: '2024-07-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 289,   A_ACT: 152,  A_REM: 137,   A_PRO: 52,  B_TOT: 97,   B_ACT: 37,  B_REM: 60,   B_PRO: 38, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0302', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 687,   A_ACT: 277,  A_REM: 410,   A_PRO: 40,  B_TOT: 267,  B_ACT: 103, B_REM: 164,  B_PRO: 38, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0601', CSTD_PLAN: '2024-07-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 186,   A_ACT: 116,  A_REM: 70,    A_PRO: 62,  B_TOT: 104,  B_ACT: 70,  B_REM: 34,   B_PRO: 67, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0603', CSTD_PLAN: '2024-07-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 342,   A_ACT: 130,  A_REM: 212,   A_PRO: 38,  B_TOT: 161,  B_ACT: 65,  B_REM: 96,   B_PRO: 40, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0602', CSTD_PLAN: '2024-08-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 390,   A_ACT: 89,   A_REM: 301,   A_PRO: 22,  B_TOT: 254,  B_ACT: 62,  B_REM: 192,  B_PRO: 24, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-6004', CSTD_PLAN: '2024-08-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 45,    A_ACT: 27,   A_REM: 18,    A_PRO: 60,  B_TOT: 22,   B_ACT: 14,  B_REM: 8,    B_PRO: 63, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-6003', CSTD_PLAN: '2024-08-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 75,    A_ACT: 35,   A_REM: 40,    A_PRO: 46,  B_TOT: 33,   B_ACT: 14,  B_REM: 19,   B_PRO: 42, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-6002', CSTD_PLAN: '2024-08-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 149,   A_ACT: 68,   A_REM: 81,    A_PRO: 45,  B_TOT: 72,   B_ACT: 41,  B_REM: 31,   B_PRO: 56, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-6001', CSTD_PLAN: '2024-08-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 105,   A_ACT: 48,   A_REM: 57,    A_PRO: 45,  B_TOT: 53,   B_ACT: 26,  B_REM: 27,   B_PRO: 49, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0201', CSTD_PLAN: '2024-08-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 615,   A_ACT: 352,  A_REM: 263,   A_PRO: 57,  B_TOT: 174,  B_ACT: 85,  B_REM: 89,   B_PRO: 48, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0402', CSTD_PLAN: '2024-08-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 88,    A_ACT: 22,   A_REM: 66,    A_PRO: 25,  B_TOT: 42,   B_ACT: 6,   B_REM: 36,   B_PRO: 14, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0502', CSTD_PLAN: '2024-08-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 26,    A_ACT: 0,    A_REM: 26,    A_PRO: 0,   B_TOT: 18,   B_ACT: 0,   B_REM: 18,   B_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0501', CSTD_PLAN: '2024-08-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 232,   A_ACT: 12,   A_REM: 220,   A_PRO: 5,   B_TOT: 114,  B_ACT: 22,  B_REM: 92,   B_PRO: 19, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0301', CSTD_PLAN: '2024-09-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 500,   A_ACT: 121,  A_REM: 379,   A_PRO: 24,  B_TOT: 198,  B_ACT: 59,  B_REM: 139,  B_PRO: 29, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-6102', CSTD_PLAN: '2024-09-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 180,   A_ACT: 99,   A_REM: 81,    A_PRO: 55,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0303', CSTD_PLAN: '2024-09-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 234,   A_ACT: 42,   A_REM: 192,   A_PRO: 17,  B_TOT: 115,  B_ACT: 8,   B_REM: 107,  B_PRO: 7, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0401', CSTD_PLAN: '2024-09-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1550,  A_ACT: 767,  A_REM: 783,   A_PRO: 49,  B_TOT: 575,  B_ACT: 203, B_REM: 372,  B_PRO: 35, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0101', CSTD_PLAN: '2024-10-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 719,   A_ACT: 266,  A_REM: 453,   A_PRO: 37,  B_TOT: 242,  B_ACT: 77,  B_REM: 165,  B_PRO: 31, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-I-0101', CSTD_PLAN: '2024-10-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 111,   A_ACT: 3,    A_REM: 108,   A_PRO: 2,   B_TOT: 35,   B_ACT: 0,   B_REM: 35,   B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-0901', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 14,    A_ACT: 0,    A_REM: 14,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-0902', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-E-1501', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 20,    A_ACT: 12,   A_REM: 8,     A_PRO: 60,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-0101', CSTD_PLAN: '2024-04-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 320,   A_ACT: 55,   A_REM: 265,   A_PRO: 17,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-1202', CSTD_PLAN: '2024-05-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 70,    A_ACT: 36,   A_REM: 34,    A_PRO: 51,  B_TOT: 66,   B_ACT: 36,  B_REM: 30,   B_PRO: 54, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-2401', CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 156,   A_ACT: 46,   A_REM: 110,   A_PRO: 29,  B_TOT: 53,   B_ACT: 20,  B_REM: 33,   B_PRO: 37, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0102', CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 345,   A_ACT: 51,   A_REM: 294,   A_PRO: 14,  B_TOT: 109,  B_ACT: 36,  B_REM: 73,   B_PRO: 33, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0109', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12624, A_ACT: 1294, A_REM: 11330, A_PRO: 10,  B_TOT: 2394, B_ACT: 7,   B_REM: 2387, B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0103', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 775,   A_ACT: 84,   A_REM: 691,   A_PRO: 10,  B_TOT: 158,  B_ACT: 24,  B_REM: 134,  B_PRO: 15, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0104', CSTD_PLAN: '2024-05-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1178,  A_ACT: 74,   A_REM: 1104,  A_PRO: 6,   B_TOT: 367,  B_ACT: 29,  B_REM: 338,  B_PRO: 7, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-0501', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 84,    A_ACT: 18,   A_REM: 66,    A_PRO: 21,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-7101', CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 147,   A_ACT: 16,   A_REM: 131,   A_PRO: 10,  B_TOT: 27,   B_ACT: 2,   B_REM: 25,   B_PRO: 7, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-0801', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1711,  A_ACT: 514,  A_REM: 1197,  A_PRO: 30,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-2103', CSTD_PLAN: '2024-06-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1305,  A_ACT: 461,  A_REM: 844,   A_PRO: 35,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-E-1001', CSTD_PLAN: '2024-06-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 42,    A_ACT: 6,    A_REM: 36,    A_PRO: 14,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-1101', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 126,   A_ACT: 52,   A_REM: 74,    A_PRO: 41,  B_TOT: 106,  B_ACT: 52,  B_REM: 54,   B_PRO: 49, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-2102', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 102,   A_ACT: 78,   A_REM: 24,    A_PRO: 76,  B_TOT: 71,   B_ACT: 53,  B_REM: 18,   B_PRO: 74, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0902', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 41,    A_ACT: 0,    A_REM: 41,    A_PRO: 0,   B_TOT: 15,   B_ACT: 0,   B_REM: 15,   B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-1001', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 139,   A_ACT: 31,   A_REM: 108,   A_PRO: 22,  B_TOT: 78,   B_ACT: 18,  B_REM: 60,   B_PRO: 23, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0901', CSTD_PLAN: '2024-06-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 39,    A_ACT: 0,    A_REM: 39,    A_PRO: 0,   B_TOT: 15,   B_ACT: 0,   B_REM: 15,   B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-2003', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-4601', CSTD_PLAN: '2024-06-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 6,    A_REM: 22,    A_PRO: 21,  B_TOT: 23,   B_ACT: 0,   B_REM: 23,   B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-7401', CSTD_PLAN: '2024-06-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 34,    A_ACT: 15,   A_REM: 19,    A_PRO: 44,  B_TOT: 17,   B_ACT: 10,  B_REM: 7,    B_PRO: 58, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-I-0202', CSTD_PLAN: '2024-06-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 60,    A_ACT: 10,   A_REM: 50,    A_PRO: 16,  B_TOT: 14,   B_ACT: 0,   B_REM: 14,   B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-T-0101', CSTD_PLAN: '2024-06-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 97,    A_ACT: 0,    A_REM: 97,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-4101', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 30,    A_ACT: 0,    A_REM: 30,    A_PRO: 0,   B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-4001', CSTD_PLAN: '2024-06-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 82,    A_ACT: 48,   A_REM: 34,    A_PRO: 58,  B_TOT: 76,   B_ACT: 48,  B_REM: 28,   B_PRO: 63, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0903', CSTD_PLAN: '2024-06-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 40,    A_ACT: 0,    A_REM: 40,    A_PRO: 0,   B_TOT: 14,   B_ACT: 0,   B_REM: 14,   B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-2301', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 146,   A_ACT: 44,   A_REM: 102,   A_PRO: 30,  B_TOT: 56,   B_ACT: 20,  B_REM: 36,   B_PRO: 35, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-0201', CSTD_PLAN: '2024-07-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 99,    A_ACT: 40,   A_REM: 59,    A_PRO: 40,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-7001', CSTD_PLAN: '2024-07-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 353,   A_ACT: 38,   A_REM: 315,   A_PRO: 10,  B_TOT: 75,   B_ACT: 8,   B_REM: 67,   B_PRO: 10, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-0401', CSTD_PLAN: '2024-07-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 92,    A_ACT: 0,    A_REM: 92,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0401', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 294,   A_ACT: 68,   A_REM: 226,   A_PRO: 23,  B_TOT: 106,  B_ACT: 43,  B_REM: 63,   B_PRO: 40, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0601', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 437,   A_ACT: 90,   A_REM: 347,   A_PRO: 20,  B_TOT: 173,  B_ACT: 46,  B_REM: 127,  B_PRO: 26, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-E-1101', CSTD_PLAN: '2024-08-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 278,   A_ACT: 103,  A_REM: 175,   A_PRO: 37,  B_TOT: 34,   B_ACT: 0,   B_REM: 34,   B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-I-0201', CSTD_PLAN: '2024-08-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 98,    A_ACT: 0,    A_REM: 98,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-E-1301', CSTD_PLAN: '2024-08-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 75,    A_ACT: 0,    A_REM: 75,    A_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0106', CSTD_PLAN: '2024-08-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 153,   A_ACT: 23,   A_REM: 130,   A_PRO: 15,  B_TOT: 49,   B_ACT: 0,   B_REM: 49,   B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0602', CSTD_PLAN: '2024-08-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 86,    A_ACT: 22,   A_REM: 64,    A_PRO: 25,  B_TOT: 52,   B_ACT: 24,  B_REM: 28,   B_PRO: 46, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0701', CSTD_PLAN: '2024-08-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 570,   A_ACT: 100,  A_REM: 470,   A_PRO: 17,  B_TOT: 108,  B_ACT: 5,   B_REM: 103,  B_PRO: 4, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-5401', CSTD_PLAN: '2024-08-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 156,   A_ACT: 26,   A_REM: 130,   A_PRO: 16,  B_TOT: 26,   B_ACT: 14,  B_REM: 12,   B_PRO: 53, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0801', CSTD_PLAN: '2024-08-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 102,   A_ACT: 44,   A_REM: 58,    A_PRO: 43,  B_TOT: 71,   B_ACT: 35,  B_REM: 36,   B_PRO: 49, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0107', CSTD_PLAN: '2024-08-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 146,   A_ACT: 15,   A_REM: 131,   A_PRO: 10,  B_TOT: 22,   B_ACT: 0,   B_REM: 22,   B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-I-0301', CSTD_PLAN: '2024-08-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 761,   A_ACT: 0,    A_REM: 761,   A_PRO: 0,   B_TOT: 157,  B_ACT: 0,   B_REM: 157,  B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-6005', CSTD_PLAN: '2024-08-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 113,   A_ACT: 35,   A_REM: 78,    A_PRO: 31,  B_TOT: 90,   B_ACT: 34,  B_REM: 56,   B_PRO: 37, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-I-0401', CSTD_PLAN: '2024-08-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 0,    A_REM: 28,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0703', CSTD_PLAN: '2024-08-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 306,   A_ACT: 118,  A_REM: 188,   A_PRO: 38,  B_TOT: 112,  B_ACT: 50,  B_REM: 62,   B_PRO: 44, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0802', CSTD_PLAN: '2024-08-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 904,   A_ACT: 71,   A_REM: 833,   A_PRO: 7,   B_TOT: 310,  B_ACT: 15,  B_REM: 295,  B_PRO: 4, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0807', CSTD_PLAN: '2024-08-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 221,   A_ACT: 33,   A_REM: 188,   A_PRO: 14,  B_TOT: 68,   B_ACT: 2,   B_REM: 66,   B_PRO: 2, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0804', CSTD_PLAN: '2024-08-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 917,   A_ACT: 58,   A_REM: 859,   A_PRO: 6,   B_TOT: 302,  B_ACT: 8,   B_REM: 294,  B_PRO: 2, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0805', CSTD_PLAN: '2024-08-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 220,   A_ACT: 31,   A_REM: 189,   A_PRO: 14,  B_TOT: 66,   B_ACT: 4,   B_REM: 62,   B_PRO: 6, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0806', CSTD_PLAN: '2024-08-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 216,   A_ACT: 31,   A_REM: 185,   A_PRO: 14,  B_TOT: 67,   B_ACT: 3,   B_REM: 64,   B_PRO: 4, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0803', CSTD_PLAN: '2024-08-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 898,   A_ACT: 64,   A_REM: 834,   A_PRO: 7,   B_TOT: 290,  B_ACT: 6,   B_REM: 284,  B_PRO: 2, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0704', CSTD_PLAN: '2024-08-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 348,   A_ACT: 73,   A_REM: 275,   A_PRO: 21,  B_TOT: 127,  B_ACT: 40,  B_REM: 87,   B_PRO: 31, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0203', CSTD_PLAN: '2024-09-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 263,   A_ACT: 4,    A_REM: 259,   A_PRO: 1,   B_TOT: 131,  B_ACT: 0,   B_REM: 131,  B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0402', CSTD_PLAN: '2024-09-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 623,   A_ACT: 157,  A_REM: 466,   A_PRO: 25,  B_TOT: 253,  B_ACT: 85,  B_REM: 168,  B_PRO: 33, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0108', CSTD_PLAN: '2024-09-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 112,   A_ACT: 24,   A_REM: 88,    A_PRO: 21,  B_TOT: 16,   B_ACT: 0,   B_REM: 16,   B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0706', CSTD_PLAN: '2024-09-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 91,    A_ACT: 31,   A_REM: 60,    A_PRO: 34,  B_TOT: 15,   B_ACT: 0,   B_REM: 15,   B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0705', CSTD_PLAN: '2024-09-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 334,   A_ACT: 111,  A_REM: 223,   A_PRO: 33,  B_TOT: 160,  B_ACT: 59,  B_REM: 101,  B_PRO: 36, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0105', CSTD_PLAN: '2024-09-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 779,   A_ACT: 321,  A_REM: 458,   A_PRO: 41,  B_TOT: 360,  B_ACT: 196, B_REM: 164,  B_PRO: 54, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0403', CSTD_PLAN: '2024-10-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 669,   A_ACT: 222,  A_REM: 447,   A_PRO: 33,  B_TOT: 289,  B_ACT: 70,  B_REM: 219,  B_PRO: 24, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0501', CSTD_PLAN: '2024-10-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 653,   A_ACT: 65,   A_REM: 588,   A_PRO: 10,  B_TOT: 265,  B_ACT: 51,  B_REM: 214,  B_PRO: 19, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0202', CSTD_PLAN: '2024-10-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 114,   A_ACT: 7,    A_REM: 107,   A_PRO: 6,   B_TOT: 22,   B_ACT: 0,   B_REM: 22,   B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0301', CSTD_PLAN: '2024-10-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 744,   A_ACT: 221,  A_REM: 523,   A_PRO: 29,  B_TOT: 304,  B_ACT: 84,  B_REM: 220,  B_PRO: 27, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-I-0101', CSTD_PLAN: '2024-10-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 390,   A_ACT: 0,    A_REM: 390,   A_PRO: 0,   B_TOT: 124,  B_ACT: 0,   B_REM: 124,  B_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0201', CSTD_PLAN: '2024-10-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1582,  A_ACT: 265,  A_REM: 1317,  A_PRO: 16,  B_TOT: 563,  B_ACT: 180, B_REM: 383,  B_PRO: 32, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0101', CSTD_PLAN: '2024-10-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1168,  A_ACT: 226,  A_REM: 942,   A_PRO: 19,  B_TOT: 387,  B_ACT: 117, B_REM: 270,  B_PRO: 30, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0702', CSTD_PLAN: '2024-10-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 968,   A_ACT: 131,  A_REM: 837,   A_PRO: 13,  B_TOT: 365,  B_ACT: 40,  B_REM: 325,  B_PRO: 11, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-E-1501', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 8,     A_ACT: 0,    A_REM: 8,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-C-0101', CSTD_PLAN: '2024-02-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 48,    A_ACT: 9,    A_REM: 39,    A_PRO: 18,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-C-0501', CSTD_PLAN: '2024-03-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 16,    A_ACT: 4,    A_REM: 12,    A_PRO: 25,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-C-0801', CSTD_PLAN: '2024-04-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 155,   A_ACT: 0,    A_REM: 155,   A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-U-2401', CSTD_PLAN: '2024-05-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 90,    A_ACT: 27,   A_REM: 63,    A_PRO: 30,  B_TOT: 27,   B_ACT: 11,  B_REM: 16,   B_PRO: 40, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-U-2103', CSTD_PLAN: '2024-05-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 84,    A_ACT: 20,   A_REM: 64,    A_PRO: 23,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-I-0301', CSTD_PLAN: '2024-05-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 110,   A_ACT: 4,    A_REM: 106,   A_PRO: 3,   B_TOT: 24,   B_ACT: 0,   B_REM: 24,   B_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-C-0201', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 29,    A_ACT: 20,   A_REM: 9,     A_PRO: 69,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-E-1001', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 1,    A_REM: 3,     A_PRO: 25,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-C-0401', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-E-1101', CSTD_PLAN: '2024-06-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 38,    A_ACT: 4,    A_REM: 34,    A_PRO: 10,  B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0102', CSTD_PLAN: '2024-07-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 596,   A_ACT: 207,  A_REM: 389,   A_PRO: 34,  B_TOT: 147,  B_ACT: 72,  B_REM: 75,   B_PRO: 49, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0601', CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 117,   A_ACT: 2,    A_REM: 115,   A_PRO: 1,   B_TOT: 40,   B_ACT: 2,   B_REM: 38,   B_PRO: 5, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-C-5401', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 36,    A_ACT: 12,   A_REM: 24,    A_PRO: 33,  B_TOT: 4,    B_ACT: 4,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-U-7401', CSTD_PLAN: '2024-07-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 68,    A_ACT: 50,   A_REM: 18,    A_PRO: 73,  B_TOT: 26,   B_ACT: 20,  B_REM: 6,    B_PRO: 76, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0103', CSTD_PLAN: '2024-07-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2693,  A_ACT: 293,  A_REM: 2400,  A_PRO: 10,  B_TOT: 578,  B_ACT: 8,   B_REM: 570,  B_PRO: 1, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0104', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 50,    A_ACT: 30,   A_REM: 20,    A_PRO: 60,  B_TOT: 9,    B_ACT: 2,   B_REM: 7,    B_PRO: 22, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-U-6006', CSTD_PLAN: '2024-08-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 41,    A_ACT: 20,   A_REM: 21,    A_PRO: 48,  B_TOT: 35,   B_ACT: 19,  B_REM: 16,   B_PRO: 54, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0501', CSTD_PLAN: '2024-08-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 89,    A_ACT: 17,   A_REM: 72,    A_PRO: 19,  B_TOT: 43,   B_ACT: 13,  B_REM: 30,   B_PRO: 30, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0401', CSTD_PLAN: '2024-08-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 240,   A_ACT: 144,  A_REM: 96,    A_PRO: 60,  B_TOT: 56,   B_ACT: 27,  B_REM: 29,   B_PRO: 48, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0201', CSTD_PLAN: '2024-09-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 471,   A_ACT: 211,  A_REM: 260,   A_PRO: 44,  B_TOT: 195,  B_ACT: 107, B_REM: 88,   B_PRO: 54, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0303', CSTD_PLAN: '2024-09-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 225,   A_ACT: 83,   A_REM: 142,   A_PRO: 36,  B_TOT: 94,   B_ACT: 32,  B_REM: 62,   B_PRO: 34, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0302', CSTD_PLAN: '2024-09-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 354,   A_ACT: 99,   A_REM: 255,   A_PRO: 28,  B_TOT: 149,  B_ACT: 29,  B_REM: 120,  B_PRO: 19, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0202', CSTD_PLAN: '2024-09-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 228,   A_ACT: 52,   A_REM: 176,   A_PRO: 22,  B_TOT: 69,   B_ACT: 3,   B_REM: 66,   B_PRO: 4, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0304', CSTD_PLAN: '2024-09-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 218,   A_ACT: 84,   A_REM: 134,   A_PRO: 38,  B_TOT: 93,   B_ACT: 35,  B_REM: 58,   B_PRO: 37, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0301', CSTD_PLAN: '2024-09-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 372,   A_ACT: 59,   A_REM: 313,   A_PRO: 15,  B_TOT: 119,  B_ACT: 3,   B_REM: 116,  B_PRO: 2, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0305', CSTD_PLAN: '2024-10-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 664,   A_ACT: 186,  A_REM: 478,   A_PRO: 28,  B_TOT: 258,  B_ACT: 76,  B_REM: 182,  B_PRO: 29, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0105', CSTD_PLAN: '2024-10-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 411,   A_ACT: 20,   A_REM: 391,   A_PRO: 4,   B_TOT: 69,   B_ACT: 0,   B_REM: 69,   B_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0101', CSTD_PLAN: '2024-10-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 823,   A_ACT: 174,  A_REM: 649,   A_PRO: 21,  B_TOT: 337,  B_ACT: 106, B_REM: 231,  B_PRO: 31, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-I-0101', CSTD_PLAN: '2024-10-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 73,    A_ACT: 1,    A_REM: 72,    A_PRO: 1,   B_TOT: 23,   B_ACT: 0,   B_REM: 23,   B_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-C-0101', CSTD_PLAN: '2024-01-24', CSTD_WALKDOWN: '2023-09-29', CSTD_ACTUAL: '2024-01-04', A_TOT: 18,    A_ACT: 18,   A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-C-0501', CSTD_PLAN: '2024-03-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1,     A_ACT: 0,    A_REM: 1,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-U-1001', CSTD_PLAN: '2024-03-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 6,    A_REM: 22,    A_PRO: 21,  B_TOT: 14,   B_ACT: 2,   B_REM: 12,   B_PRO: 14, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-U-1101', CSTD_PLAN: '2024-03-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 35,    A_ACT: 20,   A_REM: 15,    A_PRO: 57,  B_TOT: 30,   B_ACT: 20,  B_REM: 10,   B_PRO: 66, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-C-0801', CSTD_PLAN: '2024-03-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 4,    A_REM: 5,     A_PRO: 44,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-C-0401', CSTD_PLAN: '2024-04-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 0,    A_REM: 6,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-E-1001', CSTD_PLAN: '2024-04-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 3,    A_REM: 3,     A_PRO: 50,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-I-0202', CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-I-0301', CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 32,    A_ACT: 20,   A_REM: 12,    A_PRO: 62,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-I-0101', CSTD_PLAN: '2024-05-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 33,    A_ACT: 0,    A_REM: 33,    A_PRO: 0,   B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-I-0201', CSTD_PLAN: '2024-05-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 22,    A_ACT: 10,   A_REM: 12,    A_PRO: 45,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-U-1202', CSTD_PLAN: '2024-05-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 18,   A_REM: 0,     A_PRO: 100, B_TOT: 18,   B_ACT: 18,  B_REM: 0,    B_PRO: 100, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-U-4001', CSTD_PLAN: '2024-05-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 19,    A_ACT: 16,   A_REM: 3,     A_PRO: 84,  B_TOT: 18,   B_ACT: 16,  B_REM: 2,    B_PRO: 88, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-U-2003', CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 8,     A_ACT: 4,    A_REM: 4,     A_PRO: 50,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-U-2102', CSTD_PLAN: '2024-06-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 21,    A_ACT: 21,   A_REM: 0,     A_PRO: 100, B_TOT: 15,   B_ACT: 14,  B_REM: 1,    B_PRO: 93, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-U-2103', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 128,   A_ACT: 60,   A_REM: 68,    A_PRO: 46,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-E-1101', CSTD_PLAN: '2024-06-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 7,     A_ACT: 0,    A_REM: 7,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-U-4601', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-T-0101', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-P-0203', CSTD_PLAN: '2024-06-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 235,   A_ACT: 70,   A_REM: 165,   A_PRO: 29,  B_TOT: 64,   B_ACT: 12,  B_REM: 52,   B_PRO: 18, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-P-0201', CSTD_PLAN: '2024-07-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 37,    A_ACT: 27,   A_REM: 10,    A_PRO: 73,  B_TOT: 19,   B_ACT: 14,  B_REM: 5,    B_PRO: 73, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-U-7101', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 37,    A_ACT: 17,   A_REM: 20,    A_PRO: 45,  B_TOT: 16,   B_ACT: 6,   B_REM: 10,   B_PRO: 37, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-P-0202', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 211,   A_ACT: 45,   A_REM: 166,   A_PRO: 21,  B_TOT: 72,   B_ACT: 9,   B_REM: 63,   B_PRO: 12, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-C-5401', CSTD_PLAN: '2024-07-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 64,    A_ACT: 19,   A_REM: 45,    A_PRO: 29,  B_TOT: 18,   B_ACT: 14,  B_REM: 4,    B_PRO: 77, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-P-0301', CSTD_PLAN: '2024-07-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 223,   A_ACT: 76,   A_REM: 147,   A_PRO: 34,  B_TOT: 105,  B_ACT: 26,  B_REM: 79,   B_PRO: 24, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-P-0401', CSTD_PLAN: '2024-08-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 645,   A_ACT: 141,  A_REM: 504,   A_PRO: 21,  B_TOT: 440,  B_ACT: 109, B_REM: 331,  B_PRO: 24, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-P-0103', CSTD_PLAN: '2024-08-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 395,   A_ACT: 114,  A_REM: 281,   A_PRO: 28,  B_TOT: 127,  B_ACT: 30,  B_REM: 97,   B_PRO: 23, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-P-0102', CSTD_PLAN: '2024-08-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 643,   A_ACT: 274,  A_REM: 369,   A_PRO: 42,  B_TOT: 236,  B_ACT: 68,  B_REM: 168,  B_PRO: 28, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-P-0101', CSTD_PLAN: '2024-08-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 559,   A_ACT: 207,  A_REM: 352,   A_PRO: 37,  B_TOT: 375,  B_ACT: 154, B_REM: 221,  B_PRO: 41, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-1102', CSTD_PLAN: undefined,    CSTD_WALKDOWN: '2023-11-24', CSTD_ACTUAL: undefined,    A_TOT: 8,     A_ACT: 7,    A_REM: 1,     A_PRO: 87,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-I-0101', CSTD_PLAN: '2023-10-07', CSTD_WALKDOWN: '2023-09-07', CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 18,   A_REM: 0,     A_PRO: 100, B_TOT: 28,   B_ACT: 10,  B_REM: 18,   B_PRO: 35, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-1001', CSTD_PLAN: '2023-10-07', CSTD_WALKDOWN: '2023-09-08', CSTD_ACTUAL: '2023-10-08', A_TOT: 3,     A_ACT: 3,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-C-0101', CSTD_PLAN: '2023-10-07', CSTD_WALKDOWN: '2023-09-15', CSTD_ACTUAL: undefined,    A_TOT: 112,   A_ACT: 111,  A_REM: 1,     A_PRO: 99,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-1101', CSTD_PLAN: '2023-10-07', CSTD_WALKDOWN: '2023-09-07', CSTD_ACTUAL: '2023-10-08', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 5,    B_ACT: 5,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-C-0501', CSTD_PLAN: '2023-10-07', CSTD_WALKDOWN: '2023-09-08', CSTD_ACTUAL: '2023-10-07', A_TOT: 1,     A_ACT: 0,    A_REM: 1,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-I-0102', CSTD_PLAN: '2023-10-08', CSTD_WALKDOWN: '2023-10-05', CSTD_ACTUAL: '2023-10-08', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0701', CSTD_PLAN: '2023-10-10', CSTD_WALKDOWN: '2023-09-04', CSTD_ACTUAL: '2023-10-08', A_TOT: 6,     A_ACT: 6,    A_REM: 0,     A_PRO: 100, B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0101', CSTD_PLAN: '2023-10-10', CSTD_WALKDOWN: '2023-09-11', CSTD_ACTUAL: '2023-10-10', A_TOT: 60,    A_ACT: 60,   A_REM: 0,     A_PRO: 100, B_TOT: 20,   B_ACT: 20,  B_REM: 0,    B_PRO: 100, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0502', CSTD_PLAN: '2023-11-27', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-27', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 5,   B_REM: 3,    B_PRO: 62, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0501', CSTD_PLAN: '2023-11-27', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-27', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 5,   B_REM: 3,    B_PRO: 62, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0503', CSTD_PLAN: '2023-11-27', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-27', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 5,   B_REM: 3,    B_PRO: 62, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0102', CSTD_PLAN: '2023-11-28', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-28', A_TOT: 60,    A_ACT: 60,   A_REM: 0,     A_PRO: 100, B_TOT: 9,    B_ACT: 9,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0103', CSTD_PLAN: '2023-11-28', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-28', A_TOT: 60,    A_ACT: 60,   A_REM: 0,     A_PRO: 100, B_TOT: 9,    B_ACT: 9,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0301', CSTD_PLAN: '2023-11-28', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-28', A_TOT: 74,    A_ACT: 74,   A_REM: 0,     A_PRO: 100, B_TOT: 16,   B_ACT: 15,  B_REM: 1,    B_PRO: 93, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-H-0101', CSTD_PLAN: '2023-11-30', CSTD_WALKDOWN: '2023-09-06', CSTD_ACTUAL: '2023-11-30', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-C-0701', CSTD_PLAN: '2023-11-30', CSTD_WALKDOWN: '2023-09-08', CSTD_ACTUAL: '2023-11-30', A_TOT: 2,     A_ACT: 2,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-C-0801', CSTD_PLAN: '2024-03-12', CSTD_WALKDOWN: '2023-09-25', CSTD_ACTUAL: undefined,    A_TOT: 99,    A_ACT: 99,   A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-I-0201', CSTD_PLAN: '2024-03-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 2,    A_REM: 2,     A_PRO: 50,  B_TOT: 7,    B_ACT: 5,   B_REM: 2,    B_PRO: 71, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-C-0401', CSTD_PLAN: '2024-03-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 24,    A_ACT: 8,    A_REM: 16,    A_PRO: 33,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-I-0202', CSTD_PLAN: '2024-03-21', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 2,    A_REM: 2,     A_PRO: 50,  B_TOT: 1,    B_ACT: 1,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-T-0101', CSTD_PLAN: '2024-03-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 8,    A_REM: 4,     A_PRO: 66,  B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-C-5401', CSTD_PLAN: '2024-03-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 23,    A_ACT: 20,   A_REM: 3,     A_PRO: 87,  B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-1201', CSTD_PLAN: '2024-03-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 4,    B_ACT: 4,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0801', CSTD_PLAN: '2024-03-26', CSTD_WALKDOWN: '2023-08-30', CSTD_ACTUAL: undefined,    A_TOT: 24,    A_ACT: 24,   A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0601', CSTD_PLAN: '2024-03-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 42,    A_ACT: 20,   A_REM: 22,    A_PRO: 47,  B_TOT: 6,    B_ACT: 5,   B_REM: 1,    B_PRO: 83, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0901', CSTD_PLAN: '2024-03-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   B_TOT: 2,    B_ACT: 1,   B_REM: 1,    B_PRO: 50, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0402', CSTD_PLAN: '2024-03-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 7,    B_ACT: 6,   B_REM: 1,    B_PRO: 85, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0602', CSTD_PLAN: '2024-03-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 19,   A_REM: 9,     A_PRO: 67,  B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0603', CSTD_PLAN: '2024-03-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 19,   A_REM: 9,     A_PRO: 67,  B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0401', CSTD_PLAN: '2024-03-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 61,    A_ACT: 0,    A_REM: 61,    A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-1102', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 0,    A_REM: 6,     A_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0602', CSTD_PLAN: undefined,    CSTD_WALKDOWN: '2023-12-29', CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 28,   A_REM: 0,     A_PRO: 100, B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-I-0102', CSTD_PLAN: '2023-10-31', CSTD_WALKDOWN: '2023-10-05', CSTD_ACTUAL: '2023-10-31', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0102', CSTD_PLAN: '2023-11-01', CSTD_WALKDOWN: '2023-10-25', CSTD_ACTUAL: '2023-11-01', A_TOT: 71,    A_ACT: 71,   A_REM: 0,     A_PRO: 100, B_TOT: 10,   B_ACT: 10,  B_REM: 0,    B_PRO: 100, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-C-0501', CSTD_PLAN: '2023-11-02', CSTD_WALKDOWN: '2023-10-20', CSTD_ACTUAL: '2023-11-07', A_TOT: 1,     A_ACT: 1,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0501', CSTD_PLAN: '2023-11-03', CSTD_WALKDOWN: '2023-10-23', CSTD_ACTUAL: '2023-11-03', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 5,   B_REM: 3,    B_PRO: 62, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-1101', CSTD_PLAN: '2023-11-03', CSTD_WALKDOWN: '2023-10-23', CSTD_ACTUAL: '2023-11-03', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 4,    B_ACT: 4,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0301', CSTD_PLAN: '2023-11-06', CSTD_WALKDOWN: '2023-10-23', CSTD_ACTUAL: '2023-11-06', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 7,   B_REM: 1,    B_PRO: 87, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-C-0701', CSTD_PLAN: '2023-11-06', CSTD_WALKDOWN: '2023-10-24', CSTD_ACTUAL: '2023-11-06', A_TOT: 2,     A_ACT: 2,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-1001', CSTD_PLAN: '2023-11-06', CSTD_WALKDOWN: '2023-10-23', CSTD_ACTUAL: '2023-11-06', A_TOT: 3,     A_ACT: 3,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0701', CSTD_PLAN: '2023-11-06', CSTD_WALKDOWN: '2023-09-20', CSTD_ACTUAL: '2023-11-06', A_TOT: 2,     A_ACT: 2,    A_REM: 0,     A_PRO: 100, B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-H-0101', CSTD_PLAN: '2023-11-06', CSTD_WALKDOWN: '2023-10-24', CSTD_ACTUAL: '2023-11-30', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0502', CSTD_PLAN: '2023-11-06', CSTD_WALKDOWN: '2023-10-23', CSTD_ACTUAL: '2023-11-06', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 5,   B_REM: 3,    B_PRO: 62, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0101', CSTD_PLAN: '2023-11-07', CSTD_WALKDOWN: '2023-10-25', CSTD_ACTUAL: '2023-11-07', A_TOT: 71,    A_ACT: 71,   A_REM: 0,     A_PRO: 100, B_TOT: 10,   B_ACT: 10,  B_REM: 0,    B_PRO: 100, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-C-0801', CSTD_PLAN: '2023-11-20', CSTD_WALKDOWN: '2023-10-13', CSTD_ACTUAL: '2023-11-20', A_TOT: 73,    A_ACT: 73,   A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-C-0101', CSTD_PLAN: '2023-11-20', CSTD_WALKDOWN: '2023-10-05', CSTD_ACTUAL: '2023-11-20', A_TOT: 55,    A_ACT: 55,   A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-C-0401', CSTD_PLAN: '2024-03-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 7,    A_REM: 3,     A_PRO: 70,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-C-5401', CSTD_PLAN: '2024-03-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 10,   A_REM: 8,     A_PRO: 55,  B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-1201', CSTD_PLAN: '2024-03-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-I-0201', CSTD_PLAN: '2024-03-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   B_TOT: 6,    B_ACT: 4,   B_REM: 2,    B_PRO: 66, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0901', CSTD_PLAN: '2024-03-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0801', CSTD_PLAN: '2024-03-25', CSTD_WALKDOWN: '2023-09-20', CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-I-0202', CSTD_PLAN: '2024-03-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 5,     A_ACT: 0,    A_REM: 5,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-T-0101', CSTD_PLAN: '2024-03-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0601', CSTD_PLAN: '2024-03-27', CSTD_WALKDOWN: '2023-12-29', CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 28,   A_REM: 0,     A_PRO: 100, B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0401', CSTD_PLAN: '2024-04-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 61,    A_ACT: 32,   A_REM: 29,    A_PRO: 52,  B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-I-0101', CSTD_PLAN: '2024-04-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   B_TOT: 25,   B_ACT: 0,   B_REM: 25,   B_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0402', CSTD_PLAN: '2024-04-09', CSTD_WALKDOWN: '2024-01-15', CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 7,    B_ACT: 6,   B_REM: 1,    B_PRO: 85, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0603', CSTD_PLAN: '2024-04-11', CSTD_WALKDOWN: '2024-01-15', CSTD_ACTUAL: undefined,    A_TOT: 42,    A_ACT: 42,   A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 5,   B_REM: 1,    B_PRO: 83, },
      { UNIT: 'General',     SUBSYS_NO: '20000-T-0101', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 156,   A_ACT: 0,    A_REM: 156,   A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0302', CSTD_PLAN: '2024-03-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 212,   A_ACT: 69,   A_REM: 143,   A_PRO: 32,  B_TOT: 14,   B_ACT: 0,   B_REM: 14,   B_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0303', CSTD_PLAN: '2024-03-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 71,    A_ACT: 24,   A_REM: 47,    A_PRO: 33,  B_TOT: 8,    B_ACT: 0,   B_REM: 8,    B_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0801', CSTD_PLAN: '2024-03-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 43,    A_ACT: 0,    A_REM: 43,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-E-1103', CSTD_PLAN: '2024-03-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-E-1102', CSTD_PLAN: '2024-03-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0603', CSTD_PLAN: '2024-03-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 3,     A_ACT: 0,    A_REM: 3,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0601', CSTD_PLAN: '2024-03-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 0,    A_REM: 9,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0602', CSTD_PLAN: '2024-03-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 14,    A_ACT: 0,    A_REM: 14,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0301', CSTD_PLAN: '2024-03-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 121,   A_ACT: 28,   A_REM: 93,    A_PRO: 23,  B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-E-1101', CSTD_PLAN: '2024-03-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-5401', CSTD_PLAN: '2024-07-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 159,   A_ACT: 61,   A_REM: 98,    A_PRO: 38,  B_TOT: 60,   B_ACT: 13,  B_REM: 47,   B_PRO: 21, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-C-0101', CSTD_PLAN: '2024-03-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 88,    A_ACT: 11,   A_REM: 77,    A_PRO: 12,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-C-0501', CSTD_PLAN: '2024-03-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 22,    A_ACT: 8,    A_REM: 14,    A_PRO: 36,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-C-0801', CSTD_PLAN: '2024-03-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 284,   A_ACT: 126,  A_REM: 158,   A_PRO: 44,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-1001', CSTD_PLAN: '2024-03-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 45,    A_ACT: 22,   A_REM: 23,    A_PRO: 48,  B_TOT: 26,   B_ACT: 10,  B_REM: 16,   B_PRO: 38, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-E-1001', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 0,    A_REM: 6,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-C-0201', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 17,    A_ACT: 10,   A_REM: 7,     A_PRO: 58,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-C-0401', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 24,    A_ACT: 0,    A_REM: 24,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-I-0201', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 15,    A_ACT: 0,    A_REM: 15,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-I-0301', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 90,    A_ACT: 0,    A_REM: 90,    A_PRO: 0,   B_TOT: 20,   B_ACT: 0,   B_REM: 20,   B_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-I-0202', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 17,    A_ACT: 0,    A_REM: 17,    A_PRO: 0,   B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-T-0101', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 13,    A_ACT: 0,    A_REM: 13,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-E-1301', CSTD_PLAN: '2024-05-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 132,   A_ACT: 12,   A_REM: 120,   A_PRO: 9,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-E-1101', CSTD_PLAN: '2024-05-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 65,    A_ACT: 23,   A_REM: 42,    A_PRO: 35,  B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-4601', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 5,     A_ACT: 2,    A_REM: 3,     A_PRO: 40,  B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-2103', CSTD_PLAN: '2024-07-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 140,   A_ACT: 52,   A_REM: 88,    A_PRO: 37,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-7502', CSTD_PLAN: '2024-07-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 34,    A_ACT: 6,    A_REM: 28,    A_PRO: 17,  B_TOT: 24,   B_ACT: 6,   B_REM: 18,   B_PRO: 25, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-7501', CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 188,   A_ACT: 54,   A_REM: 134,   A_PRO: 28,  B_TOT: 136,  B_ACT: 46,  B_REM: 90,   B_PRO: 33, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-4101', CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 15,    A_ACT: 0,    A_REM: 15,    A_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-2102', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 11,   A_REM: 1,     A_PRO: 91,  B_TOT: 9,    B_ACT: 8,   B_REM: 1,    B_PRO: 88, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-4001', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 14,    A_ACT: 14,   A_REM: 0,     A_PRO: 100, B_TOT: 14,   B_ACT: 14,  B_REM: 0,    B_PRO: 100, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-I-0101', CSTD_PLAN: '2024-07-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 33,    A_ACT: 0,    A_REM: 33,    A_PRO: 0,   B_TOT: 11,   B_ACT: 0,   B_REM: 11,   B_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-7101', CSTD_PLAN: '2024-07-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 69,    A_ACT: 28,   A_REM: 41,    A_PRO: 40,  B_TOT: 9,    B_ACT: 2,   B_REM: 7,    B_PRO: 22, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-C-5401', CSTD_PLAN: '2024-07-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 36,    A_ACT: 4,    A_REM: 32,    A_PRO: 11,  B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-7601', CSTD_PLAN: '2024-07-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 161,   A_ACT: 85,   A_REM: 76,    A_PRO: 52,  B_TOT: 69,   B_ACT: 45,  B_REM: 24,   B_PRO: 65, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-P-0201', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 614,   A_ACT: 333,  A_REM: 281,   A_PRO: 54,  B_TOT: 301,  B_ACT: 169, B_REM: 132,  B_PRO: 56, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-7001', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 310,   A_ACT: 72,   A_REM: 238,   A_PRO: 23,  B_TOT: 60,   B_ACT: 27,  B_REM: 33,   B_PRO: 45, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-P-0101', CSTD_PLAN: '2024-10-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2846,  A_ACT: 727,  A_REM: 2119,  A_PRO: 25,  B_TOT: 876,  B_ACT: 168, B_REM: 708,  B_PRO: 19, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-6102', CSTD_PLAN: '2024-10-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-6102', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 8,     A_ACT: 0,    A_REM: 8,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-C-0101', CSTD_PLAN: '2024-03-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 24,    A_ACT: 18,   A_REM: 6,     A_PRO: 75,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-C-0501', CSTD_PLAN: '2024-03-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 13,    A_ACT: 6,    A_REM: 7,     A_PRO: 46,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-1101', CSTD_PLAN: '2024-03-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 17,    A_ACT: 17,   A_REM: 0,     A_PRO: 100, B_TOT: 18,   B_ACT: 18,  B_REM: 0,    B_PRO: 100, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-C-0801', CSTD_PLAN: '2024-03-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 95,    A_ACT: 56,   A_REM: 39,    A_PRO: 58,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-1001', CSTD_PLAN: '2024-03-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 14,    A_ACT: 14,   A_REM: 0,     A_PRO: 100, B_TOT: 10,   B_ACT: 10,  B_REM: 0,    B_PRO: 100, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-E-1301', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 228,   A_ACT: 95,   A_REM: 133,   A_PRO: 41,  B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-E-1001', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 7,     A_ACT: 1,    A_REM: 6,     A_PRO: 14,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-C-0201', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 13,    A_ACT: 12,   A_REM: 1,     A_PRO: 92,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-I-0301', CSTD_PLAN: '2024-05-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 0,    A_REM: 9,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-E-1101', CSTD_PLAN: '2024-05-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 8,     A_ACT: 7,    A_REM: 1,     A_PRO: 87,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-C-0401', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 22,    A_ACT: 0,    A_REM: 22,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-4301', CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 33,    A_ACT: 12,   A_REM: 21,    A_PRO: 36,  B_TOT: 26,   B_ACT: 12,  B_REM: 14,   B_PRO: 46, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-1201', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 20,    A_ACT: 17,   A_REM: 3,     A_PRO: 85,  B_TOT: 20,   B_ACT: 18,  B_REM: 2,    B_PRO: 90, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-2101', CSTD_PLAN: '2024-05-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 25,    A_ACT: 17,   A_REM: 8,     A_PRO: 68,  B_TOT: 15,   B_ACT: 10,  B_REM: 5,    B_PRO: 66, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-3101', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-6004', CSTD_PLAN: '2024-05-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 11,   A_REM: 7,     A_PRO: 61,  B_TOT: 4,    B_ACT: 2,   B_REM: 2,    B_PRO: 50, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-2201', CSTD_PLAN: '2024-05-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 71,    A_ACT: 66,   A_REM: 5,     A_PRO: 93,  B_TOT: 20,   B_ACT: 16,  B_REM: 4,    B_PRO: 80, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-2102', CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 9,    B_ACT: 8,   B_REM: 1,    B_PRO: 88, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-4101', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 5,     A_ACT: 0,    A_REM: 5,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0104', CSTD_PLAN: '2024-06-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 200,   A_ACT: 170,  A_REM: 30,    A_PRO: 85,  B_TOT: 48,   B_ACT: 18,  B_REM: 30,   B_PRO: 37, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-3201', CSTD_PLAN: '2024-06-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 108,   A_ACT: 79,   A_REM: 29,    A_PRO: 73,  B_TOT: 48,   B_ACT: 31,  B_REM: 17,   B_PRO: 64, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-4001', CSTD_PLAN: '2024-06-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 23,   A_REM: 5,     A_PRO: 82,  B_TOT: 24,   B_ACT: 20,  B_REM: 4,    B_PRO: 83, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-2003', CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 8,     A_ACT: 4,    A_REM: 4,     A_PRO: 50,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-3401', CSTD_PLAN: '2024-07-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 30,    A_ACT: 22,   A_REM: 8,     A_PRO: 73,  B_TOT: 14,   B_ACT: 8,   B_REM: 6,    B_PRO: 57, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0102', CSTD_PLAN: '2024-07-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 52,    A_ACT: 47,   A_REM: 5,     A_PRO: 90,  B_TOT: 16,   B_ACT: 10,  B_REM: 6,    B_PRO: 62, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0101', CSTD_PLAN: '2024-07-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 48,    A_ACT: 40,   A_REM: 8,     A_PRO: 83,  B_TOT: 17,   B_ACT: 10,  B_REM: 7,    B_PRO: 58, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0103', CSTD_PLAN: '2024-07-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 277,   A_ACT: 113,  A_REM: 164,   A_PRO: 40,  B_TOT: 87,   B_ACT: 19,  B_REM: 68,   B_PRO: 21, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0105', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 25,    A_ACT: 22,   A_REM: 3,     A_PRO: 88,  B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0201', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 480,   A_ACT: 269,  A_REM: 211,   A_PRO: 56,  B_TOT: 199,  B_ACT: 76,  B_REM: 123,  B_PRO: 38, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0401', CSTD_PLAN: '2024-08-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 26,    A_ACT: 14,   A_REM: 12,    A_PRO: 53,  B_TOT: 20,   B_ACT: 12,  B_REM: 8,    B_PRO: 60, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0101', CSTD_PLAN: '2023-10-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 22,   B_ACT: 5,   B_REM: 17,   B_PRO: 22, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-C-0101', CSTD_PLAN: '2023-11-20', CSTD_WALKDOWN: '2023-10-20', CSTD_ACTUAL: '2023-11-20', A_TOT: 5,     A_ACT: 5,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-C-0701', CSTD_PLAN: '2024-04-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 1,    A_REM: 1,     A_PRO: 50,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-C-0801', CSTD_PLAN: '2024-04-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-E-0801', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 10,   A_REM: 0,     A_PRO: 100, B_TOT: 5,    B_ACT: 5,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0205', CSTD_PLAN: '2024-04-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 1,    B_ACT: 1,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0203', CSTD_PLAN: '2024-04-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-T-0101', CSTD_PLAN: '2024-04-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 12,   A_REM: 6,     A_PRO: 66,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-H-0101', CSTD_PLAN: '2024-05-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   B_TOT: 6,    B_ACT: 3,   B_REM: 3,    B_PRO: 50, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-E-1101', CSTD_PLAN: '2024-05-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0201', CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 14,   B_ACT: 12,  B_REM: 2,    B_PRO: 85, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0206', CSTD_PLAN: '2024-05-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0207', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0202', CSTD_PLAN: '2024-05-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 12,   B_ACT: 8,   B_REM: 4,    B_PRO: 66, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0204', CSTD_PLAN: '2024-05-10', CSTD_WALKDOWN: '2023-10-18', CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-C-0101', CSTD_PLAN: '2023-10-07', CSTD_WALKDOWN: '2023-09-15', CSTD_ACTUAL: '2023-10-07', A_TOT: 8,     A_ACT: 8,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-C-0701', CSTD_PLAN: '2024-03-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 1,    A_REM: 1,     A_PRO: 50,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-C-0801', CSTD_PLAN: '2024-03-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 49,    A_ACT: 2,    A_REM: 47,    A_PRO: 4,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0201', CSTD_PLAN: '2024-03-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 5,     A_ACT: 5,    A_REM: 0,     A_PRO: 100, B_TOT: 17,   B_ACT: 15,  B_REM: 2,    B_PRO: 88, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0203', CSTD_PLAN: '2024-04-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-E-0801', CSTD_PLAN: '2024-04-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 10,   A_REM: 0,     A_PRO: 100, B_TOT: 5,    B_ACT: 5,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-E-1101', CSTD_PLAN: '2024-04-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 2,    A_REM: 0,     A_PRO: 100, B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0205', CSTD_PLAN: '2024-04-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 1,    B_ACT: 1,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0202', CSTD_PLAN: '2024-04-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 18,   B_ACT: 15,  B_REM: 3,    B_PRO: 83, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-H-0101', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   B_TOT: 6,    B_ACT: 3,   B_REM: 3,    B_PRO: 50, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0206', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-T-0101', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 19,    A_ACT: 17,   A_REM: 2,     A_PRO: 89,  B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0207', CSTD_PLAN: '2024-04-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0101', CSTD_PLAN: '2024-04-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 15,   B_ACT: 8,   B_REM: 7,    B_PRO: 53, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0204', CSTD_PLAN: '2024-04-26', CSTD_WALKDOWN: '2023-10-18', CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-C-0101', CSTD_PLAN: '2024-03-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 157,   A_ACT: 8,    A_REM: 149,   A_PRO: 5,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-U-1101', CSTD_PLAN: '2024-03-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 7,     A_ACT: 4,    A_REM: 3,     A_PRO: 57,  B_TOT: 6,    B_ACT: 4,   B_REM: 2,    B_PRO: 66, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-C-0501', CSTD_PLAN: '2024-03-21', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 2,    A_REM: 8,     A_PRO: 20,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-C-0801', CSTD_PLAN: '2024-03-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 111,   A_ACT: 52,   A_REM: 59,    A_PRO: 46,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-E-1301', CSTD_PLAN: '2024-04-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 652,   A_ACT: 94,   A_REM: 558,   A_PRO: 14,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-E-1401', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 27,    A_ACT: 1,    A_REM: 26,    A_PRO: 3,   B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-I-0202', CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 2,    A_REM: 8,     A_PRO: 20,  B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-I-0301', CSTD_PLAN: '2024-05-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 27,    A_ACT: 0,    A_REM: 27,    A_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-T-0101', CSTD_PLAN: '2024-05-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 0,    A_REM: 28,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-E-1001', CSTD_PLAN: '2024-05-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 39,    A_ACT: 0,    A_REM: 39,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-C-0401', CSTD_PLAN: '2024-05-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 19,    A_ACT: 0,    A_REM: 19,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-U-4001', CSTD_PLAN: '2024-05-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 20,    A_ACT: 8,    A_REM: 12,    A_PRO: 40,  B_TOT: 16,   B_ACT: 8,   B_REM: 8,    B_PRO: 50, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-E-1101', CSTD_PLAN: '2024-05-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 38,    A_ACT: 3,    A_REM: 35,    A_PRO: 7,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-U-1202', CSTD_PLAN: '2024-05-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 13,    A_ACT: 4,    A_REM: 9,     A_PRO: 30,  B_TOT: 10,   B_ACT: 4,   B_REM: 6,    B_PRO: 40, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-U-2101', CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, B_TOT: 7,    B_ACT: 6,   B_REM: 1,    B_PRO: 85, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-U-4601', CSTD_PLAN: '2024-06-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21402-P-0103', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 470,   A_ACT: 44,   A_REM: 426,   A_PRO: 9,   B_TOT: 148,  B_ACT: 24,  B_REM: 124,  B_PRO: 16, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21402-P-0401', CSTD_PLAN: '2024-07-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 209,   A_ACT: 35,   A_REM: 174,   A_PRO: 16,  B_TOT: 93,   B_ACT: 26,  B_REM: 67,   B_PRO: 28, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-C-5401', CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 3,    A_REM: 7,     A_PRO: 30,  B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21402-P-0201', CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 593,   A_ACT: 65,   A_REM: 528,   A_PRO: 11,  B_TOT: 233,  B_ACT: 28,  B_REM: 205,  B_PRO: 12, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21402-P-0101', CSTD_PLAN: '2024-07-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 469,   A_ACT: 16,   A_REM: 453,   A_PRO: 3,   B_TOT: 122,  B_ACT: 8,   B_REM: 114,  B_PRO: 6, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21402-P-0102', CSTD_PLAN: '2024-07-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 418,   A_ACT: 16,   A_REM: 402,   A_PRO: 3,   B_TOT: 115,  B_ACT: 8,   B_REM: 107,  B_PRO: 7, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-P-0201', CSTD_PLAN: '2024-08-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 625,   A_ACT: 126,  A_REM: 499,   A_PRO: 20,  B_TOT: 184,  B_ACT: 42,  B_REM: 142,  B_PRO: 22, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-P-0101', CSTD_PLAN: '2024-08-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 456,   A_ACT: 91,   A_REM: 365,   A_PRO: 20,  B_TOT: 146,  B_ACT: 32,  B_REM: 114,  B_PRO: 21, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-E-1501', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-U-1001', CSTD_PLAN: '2024-03-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 18,   A_REM: 10,    A_PRO: 64,  B_TOT: 16,   B_ACT: 12,  B_REM: 4,    B_PRO: 75, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-U-1101', CSTD_PLAN: '2024-03-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 15,    A_ACT: 6,    A_REM: 9,     A_PRO: 40,  B_TOT: 12,   B_ACT: 6,   B_REM: 6,    B_PRO: 50, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-E-1301', CSTD_PLAN: '2024-04-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1098,  A_ACT: 263,  A_REM: 835,   A_PRO: 24,  B_TOT: 18,   B_ACT: 0,   B_REM: 18,   B_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-C-5401', CSTD_PLAN: '2024-04-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 20,    A_ACT: 7,    A_REM: 13,    A_PRO: 35,  B_TOT: 6,    B_ACT: 4,   B_REM: 2,    B_PRO: 66, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-E-1401', CSTD_PLAN: '2024-04-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 55,    A_ACT: 1,    A_REM: 54,    A_PRO: 1,   B_TOT: 24,   B_ACT: 0,   B_REM: 24,   B_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-C-0101', CSTD_PLAN: '2024-04-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 386,   A_ACT: 64,   A_REM: 322,   A_PRO: 16,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-I-0202', CSTD_PLAN: '2024-04-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 7,     A_ACT: 0,    A_REM: 7,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-I-0301', CSTD_PLAN: '2024-04-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 61,    A_ACT: 0,    A_REM: 61,    A_PRO: 0,   B_TOT: 15,   B_ACT: 0,   B_REM: 15,   B_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-C-0501', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 26,    A_ACT: 12,   A_REM: 14,    A_PRO: 46,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-T-0101', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 41,    A_ACT: 0,    A_REM: 41,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-I-0201', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-C-0801', CSTD_PLAN: '2024-04-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 295,   A_ACT: 127,  A_REM: 168,   A_PRO: 43,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-U-1202', CSTD_PLAN: '2024-05-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 6,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-C-0401', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 0,    A_REM: 6,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-E-1001', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 51,    A_ACT: 0,    A_REM: 51,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-U-2102', CSTD_PLAN: '2024-06-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, B_TOT: 7,    B_ACT: 6,   B_REM: 1,    B_PRO: 85, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-E-1101', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 34,    A_ACT: 18,   A_REM: 16,    A_PRO: 52,  B_TOT: 9,    B_ACT: 0,   B_REM: 9,    B_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-U-4001', CSTD_PLAN: '2024-07-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 6,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-P-0302', CSTD_PLAN: '2024-07-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 20,    A_ACT: 6,    A_REM: 14,    A_PRO: 30,  B_TOT: 13,   B_ACT: 6,   B_REM: 7,    B_PRO: 46, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-P-0501', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 106,   A_ACT: 20,   A_REM: 86,    A_PRO: 18,  B_TOT: 45,   B_ACT: 10,  B_REM: 35,   B_PRO: 22, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-P-0104', CSTD_PLAN: '2024-07-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 133,   A_ACT: 19,   A_REM: 114,   A_PRO: 14,  B_TOT: 46,   B_ACT: 8,   B_REM: 38,   B_PRO: 17, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-P-0301', CSTD_PLAN: '2024-07-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 195,   A_ACT: 29,   A_REM: 166,   A_PRO: 14,  B_TOT: 76,   B_ACT: 18,  B_REM: 58,   B_PRO: 23, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-U-4601', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 19,    A_ACT: 7,    A_REM: 12,    A_PRO: 36,  B_TOT: 15,   B_ACT: 0,   B_REM: 15,   B_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-C-0101', CSTD_PLAN: '2024-03-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 26,    A_ACT: 0,    A_REM: 26,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-C-0501', CSTD_PLAN: '2024-04-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 4,    A_REM: 5,     A_PRO: 44,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-E-1001', CSTD_PLAN: '2024-04-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 1,    A_REM: 3,     A_PRO: 25,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-C-0801', CSTD_PLAN: '2024-04-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 54,    A_ACT: 41,   A_REM: 13,    A_PRO: 75,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-I-0301', CSTD_PLAN: '2024-05-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-U-1101', CSTD_PLAN: '2024-05-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 14,    A_ACT: 4,    A_REM: 10,    A_PRO: 28,  B_TOT: 12,   B_ACT: 4,   B_REM: 8,    B_PRO: 33, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-U-1201', CSTD_PLAN: '2024-05-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 3,    A_REM: 3,     A_PRO: 50,  B_TOT: 6,    B_ACT: 4,   B_REM: 2,    B_PRO: 66, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-C-0201', CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 7,    A_REM: 2,     A_PRO: 77,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-C-0401', CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 5,     A_ACT: 0,    A_REM: 5,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-E-1101', CSTD_PLAN: '2024-05-21', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 7,    A_REM: 11,    A_PRO: 38,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-U-4001', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 6,    A_REM: 4,     A_PRO: 60,  B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-U-6005', CSTD_PLAN: '2024-06-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 15,    A_ACT: 9,    A_REM: 6,     A_PRO: 60,  B_TOT: 12,   B_ACT: 10,  B_REM: 2,    B_PRO: 83, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-U-2102', CSTD_PLAN: '2024-06-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 4,    A_REM: 5,     A_PRO: 44,  B_TOT: 7,    B_ACT: 2,   B_REM: 5,    B_PRO: 28, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-U-4601', CSTD_PLAN: '2024-07-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 3,     A_ACT: 1,    A_REM: 2,     A_PRO: 33,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-U-7401', CSTD_PLAN: '2024-07-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 57,    A_ACT: 41,   A_REM: 16,    A_PRO: 71,  B_TOT: 11,   B_ACT: 6,   B_REM: 5,    B_PRO: 54, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-U-4101', CSTD_PLAN: '2024-07-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 5,     A_ACT: 0,    A_REM: 5,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-U-2103', CSTD_PLAN: '2024-07-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 140,   A_ACT: 24,   A_REM: 116,   A_PRO: 17,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-P-0103', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 100,   A_ACT: 25,   A_REM: 75,    A_PRO: 25,  B_TOT: 42,   B_ACT: 7,   B_REM: 35,   B_PRO: 16, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-P-0102', CSTD_PLAN: '2024-08-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 325,   A_ACT: 120,  A_REM: 205,   A_PRO: 36,  B_TOT: 126,  B_ACT: 42,  B_REM: 84,   B_PRO: 33, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-I-0101', CSTD_PLAN: '2024-08-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-P-0101', CSTD_PLAN: '2024-08-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1017,  A_ACT: 455,  A_REM: 562,   A_PRO: 44,  B_TOT: 358,  B_ACT: 143, B_REM: 215,  B_PRO: 39, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-I-0401', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 14,    A_ACT: 0,    A_REM: 14,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-C-0101', CSTD_PLAN: '2024-03-02', CSTD_WALKDOWN: '2024-01-12', CSTD_ACTUAL: undefined,    A_TOT: 106,   A_ACT: 106,  A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-C-0501', CSTD_PLAN: '2024-03-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 34,    A_ACT: 14,   A_REM: 20,    A_PRO: 41,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-1001', CSTD_PLAN: '2024-03-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 121,   A_ACT: 73,   A_REM: 48,    A_PRO: 60,  B_TOT: 77,   B_ACT: 56,  B_REM: 21,   B_PRO: 72, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-1101', CSTD_PLAN: '2024-03-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 46,    A_ACT: 34,   A_REM: 12,    A_PRO: 73,  B_TOT: 42,   B_ACT: 34,  B_REM: 8,    B_PRO: 81, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-C-0801', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 393,   A_ACT: 255,  A_REM: 138,   A_PRO: 64,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-I-0202', CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 15,    A_ACT: 15,   A_REM: 0,     A_PRO: 100, B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-I-0301', CSTD_PLAN: '2024-05-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 162,   A_ACT: 144,  A_REM: 18,    A_PRO: 88,  B_TOT: 36,   B_ACT: 0,   B_REM: 36,   B_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-I-0201', CSTD_PLAN: '2024-05-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 23,    A_ACT: 23,   A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-T-0101', CSTD_PLAN: '2024-05-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 27,    A_ACT: 0,    A_REM: 27,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-1202', CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 43,    A_ACT: 34,   A_REM: 9,     A_PRO: 79,  B_TOT: 40,   B_ACT: 34,  B_REM: 6,    B_PRO: 85, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-1201', CSTD_PLAN: '2024-05-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 210,   A_ACT: 130,  A_REM: 80,    A_PRO: 61,  B_TOT: 66,   B_ACT: 48,  B_REM: 18,   B_PRO: 72, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-E-1101', CSTD_PLAN: '2024-05-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 72,    A_ACT: 31,   A_REM: 41,    A_PRO: 43,  B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-3001', CSTD_PLAN: '2024-05-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 30,    A_ACT: 12,   A_REM: 18,    A_PRO: 40,  B_TOT: 20,   B_ACT: 8,   B_REM: 12,   B_PRO: 40, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-3502', CSTD_PLAN: '2024-05-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 32,    A_ACT: 7,    A_REM: 25,    A_PRO: 21,  B_TOT: 22,   B_ACT: 6,   B_REM: 16,   B_PRO: 27, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-E-1001', CSTD_PLAN: '2024-06-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 6,    A_REM: 6,     A_PRO: 50,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-C-0401', CSTD_PLAN: '2024-06-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 26,    A_ACT: 0,    A_REM: 26,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-2103', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 216,   A_ACT: 73,   A_REM: 143,   A_PRO: 33,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-2102', CSTD_PLAN: '2024-06-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 69,    A_ACT: 67,   A_REM: 2,     A_PRO: 97,  B_TOT: 47,   B_ACT: 46,  B_REM: 1,    B_PRO: 97, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-2001', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 311,   A_ACT: 222,  A_REM: 89,    A_PRO: 71,  B_TOT: 124,  B_ACT: 90,  B_REM: 34,   B_PRO: 72, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-3401', CSTD_PLAN: '2024-06-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 125,   A_ACT: 77,   A_REM: 48,    A_PRO: 61,  B_TOT: 42,   B_ACT: 22,  B_REM: 20,   B_PRO: 52, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-7001', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 40,    A_ACT: 13,   A_REM: 27,    A_PRO: 32,  B_TOT: 18,   B_ACT: 5,   B_REM: 13,   B_PRO: 27, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-4001', CSTD_PLAN: '2024-06-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 64,    A_ACT: 58,   A_REM: 6,     A_PRO: 90,  B_TOT: 62,   B_ACT: 58,  B_REM: 4,    B_PRO: 93, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-C-5401', CSTD_PLAN: '2024-07-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 57,    A_ACT: 10,   A_REM: 47,    A_PRO: 17,  B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-7101', CSTD_PLAN: '2024-07-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 158,   A_ACT: 106,  A_REM: 52,    A_PRO: 67,  B_TOT: 37,   B_ACT: 12,  B_REM: 25,   B_PRO: 32, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-4601', CSTD_PLAN: '2024-07-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-2201', CSTD_PLAN: '2024-07-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 259,   A_ACT: 195,  A_REM: 64,    A_PRO: 75,  B_TOT: 60,   B_ACT: 43,  B_REM: 17,   B_PRO: 71, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-P-0502', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1013,  A_ACT: 685,  A_REM: 328,   A_PRO: 67,  B_TOT: 300,  B_ACT: 151, B_REM: 149,  B_PRO: 50, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-I-0101', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 117,   A_ACT: 45,   A_REM: 72,    A_PRO: 38,  B_TOT: 37,   B_ACT: 0,   B_REM: 37,   B_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-2003', CSTD_PLAN: '2024-07-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 180,   A_ACT: 20,   A_REM: 160,   A_PRO: 11,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-3501', CSTD_PLAN: '2024-07-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 70,    A_ACT: 39,   A_REM: 31,    A_PRO: 55,  B_TOT: 32,   B_ACT: 19,  B_REM: 13,   B_PRO: 59, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-3301', CSTD_PLAN: '2024-07-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 101,   A_ACT: 80,   A_REM: 21,    A_PRO: 79,  B_TOT: 19,   B_ACT: 8,   B_REM: 11,   B_PRO: 42, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-3201', CSTD_PLAN: '2024-07-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 20,    A_ACT: 17,   A_REM: 3,     A_PRO: 85,  B_TOT: 16,   B_ACT: 14,  B_REM: 2,    B_PRO: 87, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-P-0102', CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 247,   A_ACT: 77,   A_REM: 170,   A_PRO: 31,  B_TOT: 82,   B_ACT: 12,  B_REM: 70,   B_PRO: 14, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-P-0201', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 746,   A_ACT: 223,  A_REM: 523,   A_PRO: 29,  B_TOT: 175,  B_ACT: 43,  B_REM: 132,  B_PRO: 24, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-P-0101', CSTD_PLAN: '2024-07-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 442,   A_ACT: 201,  A_REM: 241,   A_PRO: 45,  B_TOT: 134,  B_ACT: 34,  B_REM: 100,  B_PRO: 25, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-P-0301', CSTD_PLAN: '2024-07-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 376,   A_ACT: 142,  A_REM: 234,   A_PRO: 37,  B_TOT: 180,  B_ACT: 92,  B_REM: 88,   B_PRO: 51, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-P-0401', CSTD_PLAN: '2024-07-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 595,   A_ACT: 55,   A_REM: 540,   A_PRO: 9,   B_TOT: 136,  B_ACT: 20,  B_REM: 116,  B_PRO: 14, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-P-0501', CSTD_PLAN: '2024-07-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1438,  A_ACT: 962,  A_REM: 476,   A_PRO: 66,  B_TOT: 397,  B_ACT: 188, B_REM: 209,  B_PRO: 47, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-E-1501', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-C-0101', CSTD_PLAN: '2024-03-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 21,    A_ACT: 19,   A_REM: 2,     A_PRO: 90,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-U-1101', CSTD_PLAN: '2024-03-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 6,    A_REM: 12,    A_PRO: 33,  B_TOT: 14,   B_ACT: 6,   B_REM: 8,    B_PRO: 42, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-C-0501', CSTD_PLAN: '2024-04-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 7,     A_ACT: 2,    A_REM: 5,     A_PRO: 28,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-I-0202', CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 0,    A_REM: 9,     A_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-U-4601', CSTD_PLAN: '2024-05-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-T-0101', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-I-0301', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 2,    A_REM: 16,    A_PRO: 11,  B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-U-1202', CSTD_PLAN: '2024-05-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, B_TOT: 4,    B_ACT: 4,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-C-0801', CSTD_PLAN: '2024-05-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 78,    A_ACT: 36,   A_REM: 42,    A_PRO: 46,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-C-0401', CSTD_PLAN: '2024-06-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-U-4001', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 6,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-U-3101', CSTD_PLAN: '2024-06-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 25,    A_ACT: 25,   A_REM: 0,     A_PRO: 100, B_TOT: 11,   B_ACT: 10,  B_REM: 1,    B_PRO: 90, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-U-2102', CSTD_PLAN: '2024-06-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, B_TOT: 7,    B_ACT: 6,   B_REM: 1,    B_PRO: 85, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-U-4101', CSTD_PLAN: '2024-06-21', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 5,     A_ACT: 0,    A_REM: 5,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-U-2103', CSTD_PLAN: '2024-06-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 44,    A_ACT: 16,   A_REM: 28,    A_PRO: 36,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-E-1101', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 8,     A_ACT: 3,    A_REM: 5,     A_PRO: 37,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-E-1001', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-C-5401', CSTD_PLAN: '2024-07-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 19,    A_ACT: 1,    A_REM: 18,    A_PRO: 5,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-P-0301', CSTD_PLAN: '2024-08-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 228,   A_ACT: 130,  A_REM: 98,    A_PRO: 57,  B_TOT: 133,  B_ACT: 80,  B_REM: 53,   B_PRO: 60, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-P-0201', CSTD_PLAN: '2024-08-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 464,   A_ACT: 235,  A_REM: 229,   A_PRO: 50,  B_TOT: 202,  B_ACT: 96,  B_REM: 106,  B_PRO: 47, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-P-0101', CSTD_PLAN: '2024-08-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 377,   A_ACT: 215,  A_REM: 162,   A_PRO: 57,  B_TOT: 207,  B_ACT: 98,  B_REM: 109,  B_PRO: 47, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-I-0101', CSTD_PLAN: '2024-08-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 21,    A_ACT: 3,    A_REM: 18,    A_PRO: 14,  B_TOT: 7,    B_ACT: 0,   B_REM: 7,    B_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-I-0201', CSTD_PLAN: '2024-08-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-H-0101', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-C-0102', CSTD_PLAN: undefined,    CSTD_WALKDOWN: '2024-01-05', CSTD_ACTUAL: undefined,    A_TOT: 3,     A_ACT: 3,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-I-0101', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 22,    A_ACT: 0,    A_REM: 22,    A_PRO: 0,   B_TOT: 10,   B_ACT: 0,   B_REM: 10,   B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-E-1102', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-T-0101', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 0,    A_REM: 9,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-C-0701', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-1001', CSTD_PLAN: '2024-03-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 259,   A_ACT: 116,  A_REM: 143,   A_PRO: 44,  B_TOT: 158,  B_ACT: 92,  B_REM: 66,   B_PRO: 58, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-C-0101', CSTD_PLAN: '2024-04-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 80,    A_ACT: 50,   A_REM: 30,    A_PRO: 62,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-C-0501', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 36,    A_ACT: 14,   A_REM: 22,    A_PRO: 38,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-C-0801', CSTD_PLAN: '2024-04-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 355,   A_ACT: 261,  A_REM: 94,    A_PRO: 73,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-4102', CSTD_PLAN: '2024-05-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 13,    A_ACT: 10,   A_REM: 3,     A_PRO: 76,  B_TOT: 12,   B_ACT: 10,  B_REM: 2,    B_PRO: 83, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-I-0301', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 56,    A_ACT: 6,    A_REM: 50,    A_PRO: 10,  B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-4101', CSTD_PLAN: '2024-05-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 115,   A_ACT: 78,   A_REM: 37,    A_PRO: 67,  B_TOT: 106,  B_ACT: 76,  B_REM: 30,   B_PRO: 71, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0106', CSTD_PLAN: '2024-05-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 11,    A_ACT: 8,    A_REM: 3,     A_PRO: 72,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-1003', CSTD_PLAN: '2024-05-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 13,    A_ACT: 5,    A_REM: 8,     A_PRO: 38,  B_TOT: 5,    B_ACT: 4,   B_REM: 1,    B_PRO: 80, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-3201', CSTD_PLAN: '2024-05-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 177,   A_ACT: 137,  A_REM: 40,    A_PRO: 77,  B_TOT: 118,  B_ACT: 94,  B_REM: 24,   B_PRO: 79, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-1202', CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 64,    A_ACT: 30,   A_REM: 34,    A_PRO: 46,  B_TOT: 37,   B_ACT: 22,  B_REM: 15,   B_PRO: 59, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-1101', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 142,   A_ACT: 78,   A_REM: 64,    A_PRO: 54,  B_TOT: 124,  B_ACT: 77,  B_REM: 47,   B_PRO: 62, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0104', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 22,    A_ACT: 10,   A_REM: 12,    A_PRO: 45,  B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-2202', CSTD_PLAN: '2024-05-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 8,     A_ACT: 4,    A_REM: 4,     A_PRO: 50,  B_TOT: 4,    B_ACT: 2,   B_REM: 2,    B_PRO: 50, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0102', CSTD_PLAN: '2024-05-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 16,   A_REM: 12,    A_PRO: 57,  B_TOT: 13,   B_ACT: 8,   B_REM: 5,    B_PRO: 61, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-E-1301', CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 983,   A_ACT: 406,  A_REM: 577,   A_PRO: 41,  B_TOT: 21,   B_ACT: 0,   B_REM: 21,   B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-E-1101', CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 47,    A_ACT: 22,   A_REM: 25,    A_PRO: 46,  B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-C-0201', CSTD_PLAN: '2024-06-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 11,    A_ACT: 6,    A_REM: 5,     A_PRO: 54,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-E-1001', CSTD_PLAN: '2024-06-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 25,    A_ACT: 1,    A_REM: 24,    A_PRO: 4,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-2201', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 556,   A_ACT: 205,  A_REM: 351,   A_PRO: 36,  B_TOT: 185,  B_ACT: 110, B_REM: 75,   B_PRO: 59, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0301', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 39,    A_ACT: 16,   A_REM: 23,    A_PRO: 41,  B_TOT: 49,   B_ACT: 32,  B_REM: 17,   B_PRO: 65, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0212', CSTD_PLAN: '2024-06-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 42,    A_ACT: 26,   A_REM: 16,    A_PRO: 61,  B_TOT: 32,   B_ACT: 22,  B_REM: 10,   B_PRO: 68, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-4603', CSTD_PLAN: '2024-06-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 293,   A_ACT: 66,   A_REM: 227,   A_PRO: 22,  B_TOT: 137,  B_ACT: 0,   B_REM: 137,  B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0112', CSTD_PLAN: '2024-06-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 9,    A_REM: 3,     A_PRO: 75,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0110', CSTD_PLAN: '2024-06-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 13,    A_ACT: 8,    A_REM: 5,     A_PRO: 61,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0105', CSTD_PLAN: '2024-06-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 11,    A_ACT: 8,    A_REM: 3,     A_PRO: 72,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0210', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 139,   A_ACT: 67,   A_REM: 72,    A_PRO: 48,  B_TOT: 68,   B_ACT: 46,  B_REM: 22,   B_PRO: 67, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0126', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 34,    A_ACT: 22,   A_REM: 12,    A_PRO: 64,  B_TOT: 30,   B_ACT: 22,  B_REM: 8,    B_PRO: 73, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0101', CSTD_PLAN: '2024-06-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 58,    A_ACT: 41,   A_REM: 17,    A_PRO: 70,  B_TOT: 33,   B_ACT: 24,  B_REM: 9,    B_PRO: 72, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-1201', CSTD_PLAN: '2024-06-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 295,   A_ACT: 134,  A_REM: 161,   A_PRO: 45,  B_TOT: 243,  B_ACT: 129, B_REM: 114,  B_PRO: 53, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0119', CSTD_PLAN: '2024-06-21', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 56,    A_ACT: 35,   A_REM: 21,    A_PRO: 62,  B_TOT: 42,   B_ACT: 32,  B_REM: 10,   B_PRO: 76, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0111', CSTD_PLAN: '2024-06-21', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 51,    A_ACT: 27,   A_REM: 24,    A_PRO: 52,  B_TOT: 35,   B_ACT: 21,  B_REM: 14,   B_PRO: 60, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0103', CSTD_PLAN: '2024-06-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 15,    A_ACT: 12,   A_REM: 3,     A_PRO: 80,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0107', CSTD_PLAN: '2024-06-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 16,    A_ACT: 9,    A_REM: 7,     A_PRO: 56,  B_TOT: 12,   B_ACT: 8,   B_REM: 4,    B_PRO: 66, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0108', CSTD_PLAN: '2024-06-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 33,    A_ACT: 20,   A_REM: 13,    A_PRO: 60,  B_TOT: 28,   B_ACT: 19,  B_REM: 9,    B_PRO: 67, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0125', CSTD_PLAN: '2024-06-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 109,   A_ACT: 71,   A_REM: 38,    A_PRO: 65,  B_TOT: 54,   B_ACT: 39,  B_REM: 15,   B_PRO: 72, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0114', CSTD_PLAN: '2024-06-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0122', CSTD_PLAN: '2024-06-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 58,    A_ACT: 50,   A_REM: 8,     A_PRO: 86,  B_TOT: 56,   B_ACT: 52,  B_REM: 4,    B_PRO: 92, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-4601', CSTD_PLAN: '2024-06-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-7102', CSTD_PLAN: '2024-06-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 22,    A_ACT: 10,   A_REM: 12,    A_PRO: 45,  B_TOT: 12,   B_ACT: 8,   B_REM: 4,    B_PRO: 66, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0109', CSTD_PLAN: '2024-06-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 11,    A_ACT: 8,    A_REM: 3,     A_PRO: 72,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-7101', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 72,    A_ACT: 40,   A_REM: 32,    A_PRO: 55,  B_TOT: 53,   B_ACT: 35,  B_REM: 18,   B_PRO: 66, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-3401', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 54,    A_ACT: 41,   A_REM: 13,    A_PRO: 75,  B_TOT: 29,   B_ACT: 22,  B_REM: 7,    B_PRO: 75, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-5702', CSTD_PLAN: '2024-07-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 13,    A_ACT: 10,   A_REM: 3,     A_PRO: 76,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-4001', CSTD_PLAN: '2024-07-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 101,   A_ACT: 71,   A_REM: 30,    A_PRO: 70,  B_TOT: 96,   B_ACT: 70,  B_REM: 26,   B_PRO: 72, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0208', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 79,    A_ACT: 56,   A_REM: 23,    A_PRO: 70,  B_TOT: 54,   B_ACT: 42,  B_REM: 12,   B_PRO: 77, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-2003', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 20,    A_ACT: 8,    A_REM: 12,    A_PRO: 40,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0209', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 69,    A_ACT: 38,   A_REM: 31,    A_PRO: 55,  B_TOT: 43,   B_ACT: 32,  B_REM: 11,   B_PRO: 74, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-4602', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 3,     A_ACT: 0,    A_REM: 3,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-3001', CSTD_PLAN: '2024-07-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 355,   A_ACT: 268,  A_REM: 87,    A_PRO: 75,  B_TOT: 197,  B_ACT: 163, B_REM: 34,   B_PRO: 82, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-4002', CSTD_PLAN: '2024-07-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 32,    A_ACT: 14,   A_REM: 18,    A_PRO: 43,  B_TOT: 26,   B_ACT: 14,  B_REM: 12,   B_PRO: 53, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0213', CSTD_PLAN: '2024-07-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 49,    A_ACT: 34,   A_REM: 15,    A_PRO: 69,  B_TOT: 26,   B_ACT: 19,  B_REM: 7,    B_PRO: 73, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-3502', CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 124,   A_ACT: 52,   A_REM: 72,    A_PRO: 41,  B_TOT: 68,   B_ACT: 42,  B_REM: 26,   B_PRO: 61, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-3002', CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0113', CSTD_PLAN: '2024-07-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 13,    A_ACT: 9,    A_REM: 4,     A_PRO: 69,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0203', CSTD_PLAN: '2024-07-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 12,   A_REM: 6,     A_PRO: 66,  B_TOT: 12,   B_ACT: 8,   B_REM: 4,    B_PRO: 66, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0201', CSTD_PLAN: '2024-07-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 7,    A_REM: 3,     A_PRO: 70,  B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0124', CSTD_PLAN: '2024-07-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 6,    A_REM: 6,     A_PRO: 50,  B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-2101', CSTD_PLAN: '2024-07-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 997,   A_ACT: 610,  A_REM: 387,   A_PRO: 61,  B_TOT: 556,  B_ACT: 383, B_REM: 173,  B_PRO: 68, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-2001', CSTD_PLAN: '2024-07-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 253,   A_ACT: 169,  A_REM: 84,    A_PRO: 66,  B_TOT: 104,  B_ACT: 81,  B_REM: 23,   B_PRO: 77, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-2103', CSTD_PLAN: '2024-07-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-6004', CSTD_PLAN: '2024-07-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 15,    A_ACT: 12,   A_REM: 3,     A_PRO: 80,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0116', CSTD_PLAN: '2024-07-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 70,    A_ACT: 50,   A_REM: 20,    A_PRO: 71,  B_TOT: 55,   B_ACT: 45,  B_REM: 10,   B_PRO: 81, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0205', CSTD_PLAN: '2024-07-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 17,    A_ACT: 14,   A_REM: 3,     A_PRO: 82,  B_TOT: 16,   B_ACT: 14,  B_REM: 2,    B_PRO: 87, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0207', CSTD_PLAN: '2024-07-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 21,    A_ACT: 18,   A_REM: 3,     A_PRO: 85,  B_TOT: 20,   B_ACT: 18,  B_REM: 2,    B_PRO: 90, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0211', CSTD_PLAN: '2024-07-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 19,    A_ACT: 16,   A_REM: 3,     A_PRO: 84,  B_TOT: 18,   B_ACT: 16,  B_REM: 2,    B_PRO: 88, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0202', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 24,    A_ACT: 21,   A_REM: 3,     A_PRO: 87,  B_TOT: 16,   B_ACT: 14,  B_REM: 2,    B_PRO: 87, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0118', CSTD_PLAN: '2024-07-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 11,    A_ACT: 8,    A_REM: 3,     A_PRO: 72,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0214', CSTD_PLAN: '2024-07-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 45,    A_ACT: 30,   A_REM: 15,    A_PRO: 66,  B_TOT: 25,   B_ACT: 19,  B_REM: 6,    B_PRO: 76, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-7501', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 27,    A_ACT: 24,   A_REM: 3,     A_PRO: 88,  B_TOT: 18,   B_ACT: 16,  B_REM: 2,    B_PRO: 88, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-3101', CSTD_PLAN: '2024-08-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 608,   A_ACT: 460,  A_REM: 148,   A_PRO: 75,  B_TOT: 411,  B_ACT: 316, B_REM: 95,   B_PRO: 76, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0121', CSTD_PLAN: '2024-08-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 43,    A_ACT: 34,   A_REM: 9,     A_PRO: 79,  B_TOT: 40,   B_ACT: 34,  B_REM: 6,    B_PRO: 85, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-3402', CSTD_PLAN: '2024-08-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 15,   A_REM: 3,     A_PRO: 83,  B_TOT: 12,   B_ACT: 10,  B_REM: 2,    B_PRO: 83, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-6102', CSTD_PLAN: '2024-08-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0117', CSTD_PLAN: '2024-08-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 46,    A_ACT: 27,   A_REM: 19,    A_PRO: 58,  B_TOT: 17,   B_ACT: 10,  B_REM: 7,    B_PRO: 58, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0115', CSTD_PLAN: '2024-08-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 90,    A_ACT: 54,   A_REM: 36,    A_PRO: 60,  B_TOT: 38,   B_ACT: 20,  B_REM: 18,   B_PRO: 52, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0219', CSTD_PLAN: '2024-08-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 44,    A_ACT: 12,   A_REM: 32,    A_PRO: 27,  B_TOT: 14,   B_ACT: 6,   B_REM: 8,    B_PRO: 42, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-3301', CSTD_PLAN: '2024-08-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 180,   A_ACT: 96,   A_REM: 84,    A_PRO: 53,  B_TOT: 94,   B_ACT: 57,  B_REM: 37,   B_PRO: 60, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0120', CSTD_PLAN: '2024-08-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 24,    A_ACT: 16,   A_REM: 8,     A_PRO: 66,  B_TOT: 16,   B_ACT: 10,  B_REM: 6,    B_PRO: 62, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0218', CSTD_PLAN: '2024-08-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 39,    A_ACT: 22,   A_REM: 17,    A_PRO: 56,  B_TOT: 25,   B_ACT: 20,  B_REM: 5,    B_PRO: 80, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-1002', CSTD_PLAN: '2024-08-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 146,   A_ACT: 20,   A_REM: 126,   A_PRO: 13,  B_TOT: 91,   B_ACT: 0,   B_REM: 91,   B_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0215', CSTD_PLAN: '2024-08-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 20,    A_ACT: 14,   A_REM: 6,     A_PRO: 70,  B_TOT: 18,   B_ACT: 14,  B_REM: 4,    B_PRO: 77, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0216', CSTD_PLAN: '2024-08-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 14,    A_ACT: 8,    A_REM: 6,     A_PRO: 57,  B_TOT: 12,   B_ACT: 8,   B_REM: 4,    B_PRO: 66, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0217', CSTD_PLAN: '2024-08-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 3,    A_REM: 3,     A_PRO: 50,  B_TOT: 4,    B_ACT: 2,   B_REM: 2,    B_PRO: 50, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-5401', CSTD_PLAN: '2024-08-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 82,    A_ACT: 15,   A_REM: 67,    A_PRO: 18,  B_TOT: 40,   B_ACT: 12,  B_REM: 28,   B_PRO: 30, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0204', CSTD_PLAN: '2024-08-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 27,    A_ACT: 24,   A_REM: 3,     A_PRO: 88,  B_TOT: 20,   B_ACT: 18,  B_REM: 2,    B_PRO: 90, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0206', CSTD_PLAN: '2024-08-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 25,    A_ACT: 21,   A_REM: 4,     A_PRO: 84,  B_TOT: 16,   B_ACT: 14,  B_REM: 2,    B_PRO: 87, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-7001', CSTD_PLAN: '2024-09-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 223,   A_ACT: 52,   A_REM: 171,   A_PRO: 23,  B_TOT: 95,   B_ACT: 46,  B_REM: 49,   B_PRO: 48, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-7002', CSTD_PLAN: '2024-09-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 830,   A_ACT: 107,  A_REM: 723,   A_PRO: 12,  B_TOT: 240,  B_ACT: 55,  B_REM: 185,  B_PRO: 22, },
    ],

    SQL2: [ 
      {
        CUTOFF:               '2024-01-12',

      }
    ],
  },
}