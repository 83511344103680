
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 4150,
    CanvasBorderWeight: 0.2,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',//0E1B2B
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#fff',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },


  Text: {
  },
  

  DataItems: [
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     UNIT_NO: '21601', UNIT_DESC: 'Electrical System-Substation SSV',              SSMCC_TOT: 31, SSMCC_PLN: 16, SSMCC_WD: 23, SSMCC_ACT: 14, SSMCC_REM: 17, SSMCC_PRO: 45.2, MIN_PLAN: '2023-10-07', MAX_PLAN: '2024-03-28', A_TOT: 793,   A_ACT: 704,  A_REM: 89,    A_PRO: 88.8, B_TOT: 160,  B_ACT: 121,  B_REM: 39,   B_PRO: 75.6, C_TOT: 27,  C_ACT: 0, C_REM: 27,  C_PRO: 0, AP_TOT: 316,  AP_ACT: 289,  AP_REM: 27,   AP_PRO: 91.5, BP_TOT: 248, BP_ACT: 209, BP_REM: 39,  BP_PRO: 84.3, CP_TOT: 351, CP_ACT: 293, CP_REM: 58,  CP_PRO: 83.5, },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     UNIT_NO: '21605', UNIT_DESC: 'Electrical System-Substation SSW',              SSMCC_TOT: 29, SSMCC_PLN: 14, SSMCC_WD: 19, SSMCC_ACT: 14, SSMCC_REM: 15, SSMCC_PRO: 48.3, MIN_PLAN: '2023-10-31', MAX_PLAN: '2024-04-11', A_TOT: 550,   A_ACT: 468,  A_REM: 82,    A_PRO: 85.1, B_TOT: 121,  B_ACT: 72,   B_REM: 49,   B_PRO: 59.5, C_TOT: 25,  C_ACT: 0, C_REM: 25,  C_PRO: 0, AP_TOT: 148,  AP_ACT: 97,   AP_REM: 51,   AP_PRO: 65.5, BP_TOT: 197, BP_ACT: 176, BP_REM: 21,  BP_PRO: 89.3, CP_TOT: 188, CP_ACT: 96,  CP_REM: 92,  CP_PRO: 51.1, },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', UNIT_NO: '21502', UNIT_DESC: 'Instrument & Control System-RIE 02 - PAB 1603', SSMCC_TOT: 15, SSMCC_PLN: 1,  SSMCC_WD: 2,  SSMCC_ACT: 1,  SSMCC_REM: 14, SSMCC_PRO: 6.7,  MIN_PLAN: '2023-10-07', MAX_PLAN: '2024-04-26', A_TOT: 104,   A_ACT: 51,   A_REM: 53,    A_PRO: 49,   B_TOT: 81,   B_ACT: 55,   B_REM: 26,   B_PRO: 67.9, C_TOT: 4,   C_ACT: 0, C_REM: 4,   C_PRO: 0, AP_TOT: 20,   AP_ACT: 10,   AP_REM: 10,   AP_PRO: 50,   BP_TOT: 34,  BP_ACT: 7,   BP_REM: 27,  BP_PRO: 20.6, CP_TOT: 10,  CP_ACT: 0,   CP_REM: 10,  CP_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', UNIT_NO: '21501', UNIT_DESC: 'Instrument & Control System-RIE 01 - PAB 1603', SSMCC_TOT: 15, SSMCC_PLN: 2,  SSMCC_WD: 2,  SSMCC_ACT: 1,  SSMCC_REM: 14, SSMCC_PRO: 6.7,  MIN_PLAN: '2023-10-31', MAX_PLAN: '2024-05-10', A_TOT: 51,    A_ACT: 36,   A_REM: 15,    A_PRO: 70.6, B_TOT: 78,   B_ACT: 42,   B_REM: 36,   B_PRO: 53.8, C_TOT: 4,   C_ACT: 0, C_REM: 4,   C_PRO: 0, AP_TOT: 4,    AP_ACT: 0,    AP_REM: 4,    AP_PRO: 0,    BP_TOT: 15,  BP_ACT: 6,   BP_REM: 9,   BP_PRO: 40,   CP_TOT: 10,  CP_ACT: 0,   CP_REM: 10,  CP_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         UNIT_NO: '21200', UNIT_DESC: 'Cooling Water System',                          SSMCC_TOT: 32, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 32, SSMCC_PRO: 0,    MIN_PLAN: '2024-02-24', MAX_PLAN: '2024-07-12', A_TOT: 1659,  A_ACT: 398,  A_REM: 1261,  A_PRO: 24,   B_TOT: 900,  B_ACT: 308,  B_REM: 592,  B_PRO: 34.2, C_TOT: 36,  C_ACT: 0, C_REM: 36,  C_PRO: 0, AP_TOT: 167,  AP_ACT: 110,  AP_REM: 57,   AP_PRO: 65.9, BP_TOT: 23,  BP_ACT: 17,  BP_REM: 6,   BP_PRO: 73.9, CP_TOT: 37,  CP_ACT: 25,  CP_REM: 12,  CP_PRO: 67.6, },
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     UNIT_NO: '20000', UNIT_DESC: 'GENERAL',                                       SSMCC_TOT: 12, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 12, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-11', MAX_PLAN: '2024-07-15', A_TOT: 802,   A_ACT: 185,  A_REM: 617,   A_PRO: 23.1, B_TOT: 98,   B_ACT: 13,   B_REM: 85,   B_PRO: 13.3, C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, AP_TOT: 2,    AP_ACT: 0,    AP_REM: 2,    AP_PRO: 0,    BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,    CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,   CP_PRO: 100, },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         UNIT_NO: '20700', UNIT_DESC: 'Sulfur Recovery Unit',                          SSMCC_TOT: 39, SSMCC_PLN: 0,  SSMCC_WD: 2,  SSMCC_ACT: 0,  SSMCC_REM: 39, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-02', MAX_PLAN: '2024-07-19', A_TOT: 8018,  A_ACT: 4316, A_REM: 3702,  A_PRO: 53.8, B_TOT: 2225, B_ACT: 1047, B_REM: 1178, B_PRO: 47.1, C_TOT: 158, C_ACT: 0, C_REM: 158, C_PRO: 0, AP_TOT: 371,  AP_ACT: 304,  AP_REM: 67,   AP_PRO: 81.9, BP_TOT: 442, BP_ACT: 301, BP_REM: 141, BP_PRO: 68.1, CP_TOT: 180, CP_ACT: 148, CP_REM: 32,  CP_PRO: 82.2, },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    UNIT_NO: '21402', UNIT_DESC: 'Tank Farm South',                               SSMCC_TOT: 24, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 24, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-15', MAX_PLAN: '2024-07-26', A_TOT: 2647,  A_ACT: 664,  A_REM: 1983,  A_PRO: 25.1, B_TOT: 315,  B_ACT: 82,   B_REM: 233,  B_PRO: 26,   C_TOT: 39,  C_ACT: 0, C_REM: 39,  C_PRO: 0, AP_TOT: 27,   AP_ACT: 25,   AP_REM: 2,    AP_PRO: 92.6, BP_TOT: 10,  BP_ACT: 7,   BP_REM: 3,   BP_PRO: 70,   CP_TOT: 25,  CP_ACT: 22,  CP_REM: 3,   CP_PRO: 88, },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       UNIT_NO: '21403', UNIT_DESC: 'High Sulphur Fuel Oil Cooler',                  SSMCC_TOT: 31, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 31, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-02', MAX_PLAN: '2024-08-02', A_TOT: 1954,  A_ACT: 1173, A_REM: 781,   A_PRO: 60,   B_TOT: 625,  B_ACT: 310,  B_REM: 315,  B_PRO: 49.6, C_TOT: 57,  C_ACT: 0, C_REM: 57,  C_PRO: 0, AP_TOT: 237,  AP_ACT: 187,  AP_REM: 50,   AP_PRO: 78.9, BP_TOT: 221, BP_ACT: 112, BP_REM: 109, BP_PRO: 50.7, CP_TOT: 147, CP_ACT: 98,  CP_REM: 49,  CP_PRO: 66.7, },
    { SCOPE: 'Grassroots', UNIT: 'DS',          UNIT_NO: '21300', UNIT_DESC: 'Disposal Systems',                              SSMCC_TOT: 29, SSMCC_PLN: 1,  SSMCC_WD: 1,  SSMCC_ACT: 1,  SSMCC_REM: 28, SSMCC_PRO: 3.4,  MIN_PLAN: '2024-01-24', MAX_PLAN: '2024-08-02', A_TOT: 3455,  A_ACT: 1235, A_REM: 2220,  A_PRO: 35.7, B_TOT: 1576, B_ACT: 512,  B_REM: 1064, B_PRO: 32.5, C_TOT: 7,   C_ACT: 0, C_REM: 7,   C_PRO: 0, AP_TOT: 336,  AP_ACT: 235,  AP_REM: 101,  AP_PRO: 69.9, BP_TOT: 140, BP_ACT: 95,  BP_REM: 45,  BP_PRO: 67.9, CP_TOT: 162, CP_ACT: 117, CP_REM: 45,  CP_PRO: 72.2, },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    UNIT_NO: '21401', UNIT_DESC: 'Tank Farm North',                               SSMCC_TOT: 24, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 24, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-13', MAX_PLAN: '2024-08-08', A_TOT: 4408,  A_ACT: 594,  A_REM: 3814,  A_PRO: 13.5, B_TOT: 1100, B_ACT: 190,  B_REM: 910,  B_PRO: 17.3, C_TOT: 69,  C_ACT: 0, C_REM: 69,  C_PRO: 0, AP_TOT: 76,   AP_ACT: 73,   AP_REM: 3,    AP_PRO: 96.1, BP_TOT: 46,  BP_ACT: 41,  BP_REM: 5,   BP_PRO: 89.1, CP_TOT: 29,  CP_ACT: 26,  CP_REM: 3,   CP_PRO: 89.7, },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         UNIT_NO: '20600', UNIT_DESC: 'Amine Regeneration Unit',                       SSMCC_TOT: 29, SSMCC_PLN: 0,  SSMCC_WD: 1,  SSMCC_ACT: 0,  SSMCC_REM: 29, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-02', MAX_PLAN: '2024-08-08', A_TOT: 2895,  A_ACT: 1104, A_REM: 1791,  A_PRO: 38.1, B_TOT: 1110, B_ACT: 435,  B_REM: 675,  B_PRO: 39.2, C_TOT: 36,  C_ACT: 0, C_REM: 36,  C_PRO: 0, AP_TOT: 104,  AP_ACT: 102,  AP_REM: 2,    AP_PRO: 98.1, BP_TOT: 100, BP_ACT: 96,  BP_REM: 4,   BP_PRO: 96,   CP_TOT: 97,  CP_ACT: 95,  CP_REM: 2,   CP_PRO: 97.9, },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         UNIT_NO: '20500', UNIT_DESC: 'Sour Water Stripper Unit',                      SSMCC_TOT: 24, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 24, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-02', MAX_PLAN: '2024-08-17', A_TOT: 1409,  A_ACT: 713,  A_REM: 696,   A_PRO: 50.6, B_TOT: 603,  B_ACT: 307,  B_REM: 296,  B_PRO: 50.9, C_TOT: 18,  C_ACT: 0, C_REM: 18,  C_PRO: 0, AP_TOT: 54,   AP_ACT: 51,   AP_REM: 3,    AP_PRO: 94.4, BP_TOT: 76,  BP_ACT: 73,  BP_REM: 3,   BP_PRO: 96.1, CP_TOT: 35,  CP_ACT: 33,  CP_REM: 2,   CP_PRO: 94.3, },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        UNIT_NO: '20101', UNIT_DESC: 'Prefractionator Bottoms Stripper',              SSMCC_TOT: 21, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 21, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-25', MAX_PLAN: '2024-08-28', A_TOT: 1862,  A_ACT: 752,  A_REM: 1110,  A_PRO: 40.4, B_TOT: 599,  B_ACT: 224,  B_REM: 375,  B_PRO: 37.4, C_TOT: 27,  C_ACT: 0, C_REM: 27,  C_PRO: 0, AP_TOT: 367,  AP_ACT: 87,   AP_REM: 280,  AP_PRO: 23.7, BP_TOT: 292, BP_ACT: 56,  BP_REM: 236, BP_PRO: 19.2, CP_TOT: 158, CP_ACT: 15,  CP_REM: 143, CP_PRO: 9.5, },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         UNIT_NO: '21100', UNIT_DESC: 'Utilities & Offsites',                          SSMCC_TOT: 95, SSMCC_PLN: 0,  SSMCC_WD: 1,  SSMCC_ACT: 0,  SSMCC_REM: 95, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-20', MAX_PLAN: '2024-09-25', A_TOT: 9377,  A_ACT: 4785, A_REM: 4592,  A_PRO: 51,   B_TOT: 4429, B_ACT: 2792, B_REM: 1637, B_PRO: 63,   C_TOT: 113, C_ACT: 0, C_REM: 113, C_PRO: 0, AP_TOT: 1363, AP_ACT: 1001, AP_REM: 362,  AP_PRO: 73.4, BP_TOT: 648, BP_ACT: 464, BP_REM: 184, BP_PRO: 71.6, CP_TOT: 473, CP_ACT: 369, CP_REM: 104, CP_PRO: 78, },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         UNIT_NO: '20100', UNIT_DESC: 'Deasphalting Unit',                             SSMCC_TOT: 42, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 42, SSMCC_PRO: 0,    MIN_PLAN: '2024-02-24', MAX_PLAN: '2024-10-11', A_TOT: 9002,  A_ACT: 3738, A_REM: 5264,  A_PRO: 41.5, B_TOT: 2860, B_ACT: 1036, B_REM: 1824, B_PRO: 36.2, C_TOT: 134, C_ACT: 0, C_REM: 134, C_PRO: 0, AP_TOT: 702,  AP_ACT: 349,  AP_REM: 353,  AP_PRO: 49.7, BP_TOT: 656, BP_ACT: 347, BP_REM: 309, BP_PRO: 52.9, CP_TOT: 579, CP_ACT: 177, CP_REM: 402, CP_PRO: 30.6, },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      UNIT_NO: '20300', UNIT_DESC: 'Hydroprocessing Unit',                          SSMCC_TOT: 30, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 30, SSMCC_PRO: 0,    MIN_PLAN: '2024-02-24', MAX_PLAN: '2024-10-23', A_TOT: 8353,  A_ACT: 1837, A_REM: 6516,  A_PRO: 22,   B_TOT: 2397, B_ACT: 573,  B_REM: 1824, B_PRO: 23.9, C_TOT: 61,  C_ACT: 0, C_REM: 61,  C_PRO: 0, AP_TOT: 687,  AP_ACT: 260,  AP_REM: 427,  AP_PRO: 37.8, BP_TOT: 179, BP_ACT: 146, BP_REM: 33,  BP_PRO: 81.6, CP_TOT: 135, CP_ACT: 81,  CP_REM: 54,  CP_PRO: 60, },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      UNIT_NO: '20200', UNIT_DESC: 'Hydroprocessing Unit',                          SSMCC_TOT: 68, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 68, SSMCC_PRO: 0,    MIN_PLAN: '2024-04-15', MAX_PLAN: '2024-10-25', A_TOT: 36136, A_ACT: 5836, A_REM: 30300, A_PRO: 16.2, B_TOT: 9245, B_ACT: 1591, B_REM: 7654, B_PRO: 17.2, C_TOT: 350, C_ACT: 0, C_REM: 350, C_PRO: 0, AP_TOT: 1486, AP_ACT: 482,  AP_REM: 1004, AP_PRO: 32.4, BP_TOT: 389, BP_ACT: 286, BP_REM: 103, BP_PRO: 73.5, CP_TOT: 358, CP_ACT: 278, CP_REM: 80,  CP_PRO: 77.7, },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         UNIT_NO: '20800', UNIT_DESC: 'Hot Oil System',                                SSMCC_TOT: 28, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 28, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-02', MAX_PLAN: '2024-10-29', A_TOT: 5313,  A_ACT: 1611, A_REM: 3702,  A_PRO: 30.3, B_TOT: 1584, B_ACT: 502,  B_REM: 1082, B_PRO: 31.7, C_TOT: 52,  C_ACT: 0, C_REM: 52,  C_PRO: 0, AP_TOT: 242,  AP_ACT: 187,  AP_REM: 55,   AP_PRO: 77.3, BP_TOT: 318, BP_ACT: 251, BP_REM: 67,  BP_PRO: 78.9, CP_TOT: 135, CP_ACT: 96,  CP_REM: 39,  CP_PRO: 71.1, },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        UNIT_NO: '20400', UNIT_DESC: 'Chemical Clean Fuel Unit',                      SSMCC_TOT: 49, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 49, SSMCC_PRO: 0,    MIN_PLAN: '2024-04-05', MAX_PLAN: '2024-10-30', A_TOT: 13991, A_ACT: 2919, A_REM: 11072, A_PRO: 20.9, B_TOT: 4454, B_ACT: 906,  B_REM: 3548, B_PRO: 20.3, C_TOT: 199, C_ACT: 0, C_REM: 199, C_PRO: 0, AP_TOT: 746,  AP_ACT: 305,  AP_REM: 441,  AP_PRO: 40.9, BP_TOT: 245, BP_ACT: 133, BP_REM: 112, BP_PRO: 54.3, CP_TOT: 80,  CP_ACT: 24,  CP_REM: 56,  CP_PRO: 30, },
    { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     UNIT_NO: '20401', UNIT_DESC: 'Chemical Clean Fuel Unit Solid Separation',     SSMCC_TOT: 29, SSMCC_PLN: 0,  SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 29, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-25', MAX_PLAN: '2024-10-30', A_TOT: 2406,  A_ACT: 845,  A_REM: 1561,  A_PRO: 35.1, B_TOT: 1224, B_ACT: 287,  B_REM: 937,  B_PRO: 23.4, C_TOT: 26,  C_ACT: 0, C_REM: 26,  C_PRO: 0, AP_TOT: 101,  AP_ACT: 96,   AP_REM: 5,    AP_PRO: 95,   BP_TOT: 51,  BP_ACT: 21,  BP_REM: 30,  BP_PRO: 41.2, CP_TOT: 37,  CP_ACT: 35,  CP_REM: 2,   CP_PRO: 94.6, },
  ],


  Queries: {
    SQL1: [ 
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-2003', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-C-0101', CSTD_PLAN: '2024-03-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 60,    A_ACT: 8,    A_REM: 52,    A_PRO: 13,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-C-0501', CSTD_PLAN: '2024-03-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 23,    A_ACT: 10,   A_REM: 13,    A_PRO: 43,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 5,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 16,  BP_ACT: 15,  BP_REM: 1,   BP_PRO: 93,  CP_TOT: 22,  CP_ACT: 22,  CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-1101', CSTD_PLAN: '2024-03-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 44,    A_ACT: 26,   A_REM: 18,    A_PRO: 59,  B_TOT: 38,   B_ACT: 26,  B_REM: 12,   B_PRO: 68,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 3,   BP_ACT: 3,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-1001', CSTD_PLAN: '2024-03-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 42,    A_ACT: 18,   A_REM: 24,    A_PRO: 42,  B_TOT: 28,   B_ACT: 14,  B_REM: 14,   B_PRO: 50,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 3,   CP_ACT: 3,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-C-0801', CSTD_PLAN: '2024-03-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 260,   A_ACT: 133,  A_REM: 127,   A_PRO: 51,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-E-1301', CSTD_PLAN: '2024-04-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 47,    A_ACT: 22,   A_REM: 25,    A_PRO: 46,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 4,   BP_ACT: 4,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-2102', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 36,    A_ACT: 36,   A_REM: 0,     A_PRO: 100, B_TOT: 25,   B_ACT: 24,  B_REM: 1,    B_PRO: 96,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 3,   BP_ACT: 3,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-I-0301', CSTD_PLAN: '2024-05-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 36,    A_ACT: 0,    A_REM: 36,    A_PRO: 0,   B_TOT: 8,    B_ACT: 0,   B_REM: 8,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-E-1001', CSTD_PLAN: '2024-05-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-C-0401', CSTD_PLAN: '2024-05-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 21,    A_ACT: 0,    A_REM: 21,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-C-0201', CSTD_PLAN: '2024-05-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1,     A_ACT: 1,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-E-1101', CSTD_PLAN: '2024-05-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 71,    A_ACT: 36,   A_REM: 35,    A_PRO: 50,  B_TOT: 13,   B_ACT: 0,   B_REM: 13,   B_PRO: 0,   C_TOT: 5,  C_ACT: 0, C_REM: 5,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 21,  BP_ACT: 21,  BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-1202', CSTD_PLAN: '2024-05-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 26,    A_ACT: 20,   A_REM: 6,     A_PRO: 76,  B_TOT: 24,   B_ACT: 20,  B_REM: 4,    B_PRO: 83,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-4001', CSTD_PLAN: '2024-06-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 45,    A_ACT: 33,   A_REM: 12,    A_PRO: 73,  B_TOT: 40,   B_ACT: 32,  B_REM: 8,    B_PRO: 80,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 5,   BP_ACT: 5,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-2103', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 172,   A_ACT: 84,   A_REM: 88,    A_PRO: 48,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-6102', CSTD_PLAN: '2024-06-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 36,    A_ACT: 20,   A_REM: 16,    A_PRO: 55,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-2001', CSTD_PLAN: '2024-06-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 30,    A_ACT: 24,   A_REM: 6,     A_PRO: 80,  B_TOT: 11,   B_ACT: 8,   B_REM: 3,    B_PRO: 72,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 3,   BP_ACT: 3,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-P-0501', CSTD_PLAN: '2024-06-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 108,   A_ACT: 2,    A_REM: 106,   A_PRO: 1,   B_TOT: 36,   B_ACT: 4,   B_REM: 32,   B_PRO: 11,  C_TOT: 10, C_ACT: 0, C_REM: 10, C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-3301', CSTD_PLAN: '2024-06-29', CSTD_WALKDOWN: '2024-01-25', CSTD_ACTUAL: undefined,    A_TOT: 13,    A_ACT: 11,   A_REM: 2,     A_PRO: 84,  B_TOT: 5,    B_ACT: 4,   B_REM: 1,    B_PRO: 80,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-4601', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-3001', CSTD_PLAN: '2024-07-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 86,    A_ACT: 52,   A_REM: 34,    A_PRO: 60,  B_TOT: 44,   B_ACT: 33,  B_REM: 11,   B_PRO: 75,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 3,   BP_ACT: 3,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-U-4101', CSTD_PLAN: '2024-07-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-C-5401', CSTD_PLAN: '2024-07-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 50,    A_ACT: 7,    A_REM: 43,    A_PRO: 14,  B_TOT: 4,    B_ACT: 2,   B_REM: 2,    B_PRO: 50,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-P-0101', CSTD_PLAN: '2024-07-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 565,   A_ACT: 229,  A_REM: 336,   A_PRO: 40,  B_TOT: 267,  B_ACT: 103, B_REM: 164,  B_PRO: 38,  C_TOT: 6,  C_ACT: 0, C_REM: 6,  C_PRO: 0, AP_TOT: 50,  AP_ACT: 48,  AP_REM: 2,   AP_PRO: 96,  BP_TOT: 19,  BP_ACT: 18,  BP_REM: 1,   BP_PRO: 94,  CP_TOT: 16,  CP_ACT: 15,  CP_REM: 1,  CP_PRO: 93, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-I-0101', CSTD_PLAN: '2024-07-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 27,    A_ACT: 1,    A_REM: 26,    A_PRO: 3,   B_TOT: 9,    B_ACT: 0,   B_REM: 9,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-P-0201', CSTD_PLAN: '2024-07-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 81,    A_ACT: 17,   A_REM: 64,    A_PRO: 21,  B_TOT: 26,   B_ACT: 2,   B_REM: 24,   B_PRO: 7,   C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-P-0401', CSTD_PLAN: '2024-08-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 306,   A_ACT: 75,   A_REM: 231,   A_PRO: 24,  B_TOT: 200,  B_ACT: 56,  B_REM: 144,  B_PRO: 28,  C_TOT: 4,  C_ACT: 0, C_REM: 4,  C_PRO: 0, AP_TOT: 24,  AP_ACT: 24,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 3,   CP_ACT: 2,   CP_REM: 1,  CP_PRO: 66, },
      { UNIT: 'ARU',         SUBSYS_NO: '20600-P-0301', CSTD_PLAN: '2024-08-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 687,   A_ACT: 235,  A_REM: 452,   A_PRO: 34,  B_TOT: 320,  B_ACT: 107, B_REM: 213,  B_PRO: 33,  C_TOT: 6,  C_ACT: 0, C_REM: 6,  C_PRO: 0, AP_TOT: 12,  AP_ACT: 12,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 12,  BP_ACT: 11,  BP_REM: 1,   BP_PRO: 91,  CP_TOT: 38,  CP_ACT: 38,  CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0404', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 38,    A_ACT: 5,    A_REM: 33,    A_PRO: 13,  B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-C-0101', CSTD_PLAN: '2024-04-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 100,   A_ACT: 67,   A_REM: 33,    A_PRO: 67,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-I-0202', CSTD_PLAN: '2024-04-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 21,    A_ACT: 10,   A_REM: 11,    A_PRO: 47,  B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-C-0501', CSTD_PLAN: '2024-04-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 43,    A_ACT: 12,   A_REM: 31,    A_PRO: 27,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 46,  AP_ACT: 16,  AP_REM: 30,  AP_PRO: 34,  BP_TOT: 8,   BP_ACT: 4,   BP_REM: 4,   BP_PRO: 50,  CP_TOT: 44,  CP_ACT: 9,   CP_REM: 35, CP_PRO: 20, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-I-0401', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 7,     A_ACT: 0,    A_REM: 7,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-T-0101', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 41,    A_ACT: 0,    A_REM: 41,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-I-0301', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 281,   A_ACT: 0,    A_REM: 281,   A_PRO: 0,   B_TOT: 58,   B_ACT: 0,   B_REM: 58,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-C-0801', CSTD_PLAN: '2024-04-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 495,   A_ACT: 158,  A_REM: 337,   A_PRO: 31,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 0,   AP_REM: 1,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-E-1001', CSTD_PLAN: '2024-05-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 23,    A_ACT: 7,    A_REM: 16,    A_PRO: 30,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-I-0201', CSTD_PLAN: '2024-05-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 41,    A_ACT: 13,   A_REM: 28,    A_PRO: 31,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-1001', CSTD_PLAN: '2024-05-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 63,    A_ACT: 24,   A_REM: 39,    A_PRO: 38,  B_TOT: 46,   B_ACT: 22,  B_REM: 24,   B_PRO: 47,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 0,   AP_REM: 1,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-1202', CSTD_PLAN: '2024-05-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 24,    A_ACT: 10,   A_REM: 14,    A_PRO: 41,  B_TOT: 20,   B_ACT: 9,   B_REM: 11,   B_PRO: 45,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-1101', CSTD_PLAN: '2024-05-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 25,    A_ACT: 12,   A_REM: 13,    A_PRO: 48,  B_TOT: 24,   B_ACT: 11,  B_REM: 13,   B_PRO: 45,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-2301', CSTD_PLAN: '2024-05-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 43,    A_ACT: 11,   A_REM: 32,    A_PRO: 25,  B_TOT: 17,   B_ACT: 4,   B_REM: 13,   B_PRO: 23,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-2401', CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 58,    A_ACT: 22,   A_REM: 36,    A_PRO: 37,  B_TOT: 23,   B_ACT: 6,   B_REM: 17,   B_PRO: 26,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 4,   BP_ACT: 4,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-C-0201', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 50,    A_ACT: 17,   A_REM: 33,    A_PRO: 34,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 4,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 5,   BP_ACT: 5,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 17,  CP_ACT: 4,   CP_REM: 13, CP_PRO: 23, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-4101', CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 14,    A_ACT: 0,    A_REM: 14,    A_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-4001', CSTD_PLAN: '2024-06-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 69,    A_ACT: 17,   A_REM: 52,    A_PRO: 24,  B_TOT: 38,   B_ACT: 13,  B_REM: 25,   B_PRO: 34,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-4601', CSTD_PLAN: '2024-06-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 3,     A_ACT: 1,    A_REM: 2,     A_PRO: 33,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0702', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 453,   A_ACT: 148,  A_REM: 305,   A_PRO: 32,  B_TOT: 348,  B_ACT: 145, B_REM: 203,  B_PRO: 41,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 8,   AP_ACT: 6,   AP_REM: 2,   AP_PRO: 75,  BP_TOT: 17,  BP_ACT: 2,   BP_REM: 15,  BP_PRO: 11,  CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-C-0401', CSTD_PLAN: '2024-06-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 32,    A_ACT: 0,    A_REM: 32,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-E-1301', CSTD_PLAN: '2024-06-21', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 94,    A_ACT: 36,   A_REM: 58,    A_PRO: 38,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-E-1101', CSTD_PLAN: '2024-06-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 99,    A_ACT: 24,   A_REM: 75,    A_PRO: 24,  B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 217, AP_ACT: 99,  AP_REM: 118, AP_PRO: 45,  BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-2101', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 13,   A_REM: 15,    A_PRO: 46,  B_TOT: 21,   B_ACT: 10,  B_REM: 11,   B_PRO: 47,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 5,   BP_ACT: 3,   BP_REM: 2,   BP_PRO: 60,  CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-2103', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 420,   A_ACT: 100,  A_REM: 320,   A_PRO: 23,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0701', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 111,   A_ACT: 42,   A_REM: 69,    A_PRO: 37,  B_TOT: 83,   B_ACT: 33,  B_REM: 50,   B_PRO: 39,  C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 1,   AP_REM: 2,   AP_PRO: 33,  BP_TOT: 3,   BP_ACT: 3,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 3,   CP_ACT: 2,   CP_REM: 1,  CP_PRO: 66, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-C-5401', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 83,    A_ACT: 23,   A_REM: 60,    A_PRO: 27,  B_TOT: 22,   B_ACT: 14,  B_REM: 8,    B_PRO: 63,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-7401', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 214,   A_ACT: 37,   A_REM: 177,   A_PRO: 17,  B_TOT: 64,   B_ACT: 4,   B_REM: 60,   B_PRO: 6,   C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 4,   BP_ACT: 4,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0401', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 282,   A_ACT: 33,   A_REM: 249,   A_PRO: 11,  B_TOT: 79,   B_ACT: 2,   B_REM: 77,   B_PRO: 2,   C_TOT: 10, C_ACT: 0, C_REM: 10, C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0208', CSTD_PLAN: '2024-07-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 36,    A_ACT: 7,    A_REM: 29,    A_PRO: 19,  B_TOT: 15,   B_ACT: 0,   B_REM: 15,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0501', CSTD_PLAN: '2024-07-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 174,   A_ACT: 12,   A_REM: 162,   A_PRO: 6,   B_TOT: 65,   B_ACT: 1,   B_REM: 64,   B_PRO: 1,   C_TOT: 4,  C_ACT: 0, C_REM: 4,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0302', CSTD_PLAN: '2024-07-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 525,   A_ACT: 130,  A_REM: 395,   A_PRO: 24,  B_TOT: 189,  B_ACT: 51,  B_REM: 138,  B_PRO: 27,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 121, AP_ACT: 30,  AP_REM: 91,  AP_PRO: 24,  BP_TOT: 23,  BP_ACT: 1,   BP_REM: 22,  BP_PRO: 4,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0601', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 74,    A_ACT: 53,   A_REM: 21,    A_PRO: 71,  B_TOT: 32,   B_ACT: 26,  B_REM: 6,    B_PRO: 81,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 12,  AP_ACT: 11,  AP_REM: 1,   AP_PRO: 91,  BP_TOT: 8,   BP_ACT: 8,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0203', CSTD_PLAN: '2024-08-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 330,   A_ACT: 180,  A_REM: 150,   A_PRO: 54,  B_TOT: 145,  B_ACT: 83,  B_REM: 62,   B_PRO: 57,  C_TOT: 6,  C_ACT: 0, C_REM: 6,  C_PRO: 0, AP_TOT: 42,  AP_ACT: 30,  AP_REM: 12,  AP_PRO: 71,  BP_TOT: 26,  BP_ACT: 20,  BP_REM: 6,   BP_PRO: 76,  CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0206', CSTD_PLAN: '2024-08-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 113,   A_ACT: 22,   A_REM: 91,    A_PRO: 19,  B_TOT: 28,   B_ACT: 0,   B_REM: 28,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 6,   AP_ACT: 6,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0303', CSTD_PLAN: '2024-08-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 368,   A_ACT: 112,  A_REM: 256,   A_PRO: 30,  B_TOT: 166,  B_ACT: 36,  B_REM: 130,  B_PRO: 21,  C_TOT: 6,  C_ACT: 0, C_REM: 6,  C_PRO: 0, AP_TOT: 18,  AP_ACT: 8,   AP_REM: 10,  AP_PRO: 44,  BP_TOT: 6,   BP_ACT: 4,   BP_REM: 2,   BP_PRO: 66,  CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0602', CSTD_PLAN: '2024-08-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 148,   A_ACT: 40,   A_REM: 108,   A_PRO: 27,  B_TOT: 58,   B_ACT: 5,   B_REM: 53,   B_PRO: 8,   C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 0,   AP_REM: 3,   AP_PRO: 0,   BP_TOT: 6,   BP_ACT: 0,   BP_REM: 6,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-U-6006', CSTD_PLAN: '2024-08-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 41,    A_ACT: 12,   A_REM: 29,    A_PRO: 29,  B_TOT: 32,   B_ACT: 11,  B_REM: 21,   B_PRO: 34,  C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0202', CSTD_PLAN: '2024-08-21', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 391,   A_ACT: 46,   A_REM: 345,   A_PRO: 11,  B_TOT: 109,  B_ACT: 14,  B_REM: 95,   B_PRO: 12,  C_TOT: 10, C_ACT: 0, C_REM: 10, C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 1,   BP_REM: 1,   BP_PRO: 50,  CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0402', CSTD_PLAN: '2024-08-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 748,   A_ACT: 271,  A_REM: 477,   A_PRO: 36,  B_TOT: 304,  B_ACT: 113, B_REM: 191,  B_PRO: 37,  C_TOT: 6,  C_ACT: 0, C_REM: 6,  C_PRO: 0, AP_TOT: 50,  AP_ACT: 22,  AP_REM: 28,  AP_PRO: 44,  BP_TOT: 18,  BP_ACT: 12,  BP_REM: 6,   BP_PRO: 66,  CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0403', CSTD_PLAN: '2024-08-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1172,  A_ACT: 170,  A_REM: 1002,  A_PRO: 14,  B_TOT: 266,  B_ACT: 0,   B_REM: 266,  B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0201', CSTD_PLAN: '2024-08-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 507,   A_ACT: 202,  A_REM: 305,   A_PRO: 39,  B_TOT: 255,  B_ACT: 118, B_REM: 137,  B_PRO: 46,  C_TOT: 14, C_ACT: 0, C_REM: 14, C_PRO: 0, AP_TOT: 105, AP_ACT: 34,  AP_REM: 71,  AP_PRO: 32,  BP_TOT: 50,  BP_ACT: 21,  BP_REM: 29,  BP_PRO: 42,  CP_TOT: 7,   CP_ACT: 3,   CP_REM: 4,  CP_PRO: 42, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0204', CSTD_PLAN: '2024-09-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 537,   A_ACT: 155,  A_REM: 382,   A_PRO: 28,  B_TOT: 209,  B_ACT: 72,  B_REM: 137,  B_PRO: 34,  C_TOT: 10, C_ACT: 0, C_REM: 10, C_PRO: 0, AP_TOT: 57,  AP_ACT: 22,  AP_REM: 35,  AP_PRO: 38,  BP_TOT: 28,  BP_ACT: 13,  BP_REM: 15,  BP_PRO: 46,  CP_TOT: 3,   CP_ACT: 1,   CP_REM: 2,  CP_PRO: 33, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0207', CSTD_PLAN: '2024-09-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 3011,  A_ACT: 348,  A_REM: 2663,  A_PRO: 11,  B_TOT: 645,  B_ACT: 0,   B_REM: 645,  B_PRO: 0,   C_TOT: 4,  C_ACT: 0, C_REM: 4,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0305', CSTD_PLAN: '2024-10-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 408,   A_ACT: 27,   A_REM: 381,   A_PRO: 6,   B_TOT: 150,  B_ACT: 6,   B_REM: 144,  B_PRO: 4,   C_TOT: 18, C_ACT: 0, C_REM: 18, C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0304', CSTD_PLAN: '2024-10-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 451,   A_ACT: 27,   A_REM: 424,   A_PRO: 6,   B_TOT: 153,  B_ACT: 6,   B_REM: 147,  B_PRO: 3,   C_TOT: 32, C_ACT: 0, C_REM: 32, C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0205', CSTD_PLAN: '2024-10-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 340,   A_ACT: 10,   A_REM: 330,   A_PRO: 2,   B_TOT: 181,  B_ACT: 20,  B_REM: 161,  B_PRO: 11,  C_TOT: 32, C_ACT: 0, C_REM: 32, C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-I-0101', CSTD_PLAN: '2024-10-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 213,   A_ACT: 3,    A_REM: 210,   A_PRO: 1,   B_TOT: 67,   B_ACT: 0,   B_REM: 67,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 10,  AP_ACT: 0,   AP_REM: 10,  AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU',        SUBSYS_NO: '20400-P-0301', CSTD_PLAN: '2024-10-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1149,  A_ACT: 250,  A_REM: 899,   A_PRO: 21,  B_TOT: 507,  B_ACT: 71,  B_REM: 436,  B_PRO: 14,  C_TOT: 29, C_ACT: 0, C_REM: 29, C_PRO: 0, AP_TOT: 38,  AP_ACT: 12,  AP_REM: 26,  AP_PRO: 31,  BP_TOT: 27,  BP_ACT: 24,  BP_REM: 3,   BP_PRO: 88,  CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-2103', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-C-0901', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 3,     A_ACT: 0,    A_REM: 3,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-1001', CSTD_PLAN: '2024-03-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 40,    A_ACT: 18,   A_REM: 22,    A_PRO: 45,  B_TOT: 28,   B_ACT: 14,  B_REM: 14,   B_PRO: 50,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-1101', CSTD_PLAN: '2024-04-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 34,    A_ACT: 22,   A_REM: 12,    A_PRO: 64,  B_TOT: 30,   B_ACT: 22,  B_REM: 8,    B_PRO: 73,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-C-0101', CSTD_PLAN: '2024-04-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 51,    A_ACT: 51,   A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 24,  BP_ACT: 0,   BP_REM: 24,  BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-4101', CSTD_PLAN: '2024-05-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 20,    A_ACT: 9,    A_REM: 11,    A_PRO: 45,  B_TOT: 13,   B_ACT: 6,   B_REM: 7,    B_PRO: 46,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-4001', CSTD_PLAN: '2024-05-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 81,    A_ACT: 42,   A_REM: 39,    A_PRO: 51,  B_TOT: 68,   B_ACT: 42,  B_REM: 26,   B_PRO: 61,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 3,   AP_REM: 2,   AP_PRO: 60,  BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-4601', CSTD_PLAN: '2024-05-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 3,     A_ACT: 1,    A_REM: 2,     A_PRO: 33,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-2101', CSTD_PLAN: '2024-05-21', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 28,   A_REM: 0,     A_PRO: 100, B_TOT: 21,   B_ACT: 20,  B_REM: 1,    B_PRO: 95,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 1,   BP_REM: 1,   BP_PRO: 50,  CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-1201', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 64,    A_ACT: 28,   A_REM: 36,    A_PRO: 43,  B_TOT: 40,   B_ACT: 14,  B_REM: 26,   B_PRO: 35,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 9,   AP_ACT: 7,   AP_REM: 2,   AP_PRO: 77,  BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 3,   CP_ACT: 3,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-C-0501', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 23,    A_ACT: 10,   A_REM: 13,    A_PRO: 43,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 9,   AP_ACT: 9,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 3,   BP_ACT: 2,   BP_REM: 1,   BP_PRO: 66,  CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-7402', CSTD_PLAN: '2024-06-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 44,    A_ACT: 23,   A_REM: 21,    A_PRO: 52,  B_TOT: 18,   B_ACT: 10,  B_REM: 8,    B_PRO: 55,  C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-C-5401', CSTD_PLAN: '2024-06-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 24,    A_ACT: 7,    A_REM: 17,    A_PRO: 29,  B_TOT: 6,    B_ACT: 4,   B_REM: 2,    B_PRO: 66,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-3101', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 22,    A_ACT: 22,   A_REM: 0,     A_PRO: 100, B_TOT: 16,   B_ACT: 16,  B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-P-0801', CSTD_PLAN: '2024-06-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 75,    A_ACT: 36,   A_REM: 39,    A_PRO: 48,  B_TOT: 44,   B_ACT: 16,  B_REM: 28,   B_PRO: 36,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 16,  AP_ACT: 16,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-P-0802', CSTD_PLAN: '2024-06-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 215,   A_ACT: 68,   A_REM: 147,   A_PRO: 31,  B_TOT: 106,  B_ACT: 29,  B_REM: 77,   B_PRO: 27,  C_TOT: 4,  C_ACT: 0, C_REM: 4,  C_PRO: 0, AP_TOT: 9,   AP_ACT: 9,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-P-0104', CSTD_PLAN: '2024-06-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 189,   A_ACT: 67,   A_REM: 122,   A_PRO: 35,  B_TOT: 75,   B_ACT: 2,   B_REM: 73,   B_PRO: 2,   C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-C-0801', CSTD_PLAN: '2024-07-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 108,   A_ACT: 78,   A_REM: 30,    A_PRO: 72,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 5,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-U-4301', CSTD_PLAN: '2024-07-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 22,    A_ACT: 10,   A_REM: 12,    A_PRO: 45,  B_TOT: 18,   B_ACT: 10,  B_REM: 8,    B_PRO: 55,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 5,   CP_ACT: 5,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-E-0101', CSTD_PLAN: '2024-07-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-P-0102', CSTD_PLAN: '2024-08-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 155,   A_ACT: 47,   A_REM: 108,   A_PRO: 30,  B_TOT: 55,   B_ACT: 12,  B_REM: 43,   B_PRO: 21,  C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 8,   AP_ACT: 8,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-P-0101', CSTD_PLAN: '2024-08-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 630,   A_ACT: 94,   A_REM: 536,   A_PRO: 14,  B_TOT: 475,  B_ACT: 25,  B_REM: 450,  B_PRO: 5,   C_TOT: 10, C_ACT: 0, C_REM: 10, C_PRO: 0, AP_TOT: 11,  AP_ACT: 11,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 4,   BP_ACT: 1,   BP_REM: 3,   BP_PRO: 25,  CP_TOT: 4,   CP_ACT: 3,   CP_REM: 1,  CP_PRO: 75, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-C-0201', CSTD_PLAN: '2024-08-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 3,    A_REM: 7,     A_PRO: 30,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-E-1001', CSTD_PLAN: '2024-08-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 3,    A_REM: 3,     A_PRO: 50,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-E-1101', CSTD_PLAN: '2024-08-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 27,    A_ACT: 16,   A_REM: 11,    A_PRO: 59,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-P-0103', CSTD_PLAN: '2024-09-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 464,   A_ACT: 159,  A_REM: 305,   A_PRO: 34,  B_TOT: 189,  B_ACT: 45,  B_REM: 144,  B_PRO: 23,  C_TOT: 8,  C_ACT: 0, C_REM: 8,  C_PRO: 0, AP_TOT: 15,  AP_ACT: 14,  AP_REM: 1,   AP_PRO: 93,  BP_TOT: 5,   BP_ACT: 5,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 3,   CP_ACT: 3,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-C-0401', CSTD_PLAN: '2024-09-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-I-0301', CSTD_PLAN: '2024-10-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 3,    A_REM: 6,     A_PRO: 33,  B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CCFU SS',     SUBSYS_NO: '20401-I-0101', CSTD_PLAN: '2024-10-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 37,    A_ACT: 0,    A_REM: 37,    A_PRO: 0,   B_TOT: 11,   B_ACT: 0,   B_REM: 11,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-C-0101', CSTD_PLAN: '2024-02-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 11,    A_ACT: 1,    A_REM: 10,    A_PRO: 9,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-C-0501', CSTD_PLAN: '2024-03-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-C-0801', CSTD_PLAN: '2024-03-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 48,    A_ACT: 0,    A_REM: 48,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-I-0202', CSTD_PLAN: '2024-04-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 0,    A_REM: 9,     A_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-C-0401', CSTD_PLAN: '2024-04-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 14,    A_ACT: 0,    A_REM: 14,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-I-0301', CSTD_PLAN: '2024-04-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-T-0101', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 16,    A_ACT: 0,    A_REM: 16,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-I-0401', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 7,     A_ACT: 0,    A_REM: 7,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-E-1001', CSTD_PLAN: '2024-04-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 3,     A_ACT: 0,    A_REM: 3,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-1001', CSTD_PLAN: '2024-04-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 13,    A_ACT: 6,    A_REM: 7,     A_PRO: 46,  B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-1101', CSTD_PLAN: '2024-04-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 6,    A_REM: 6,     A_PRO: 50,  B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-I-0201', CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 15,    A_ACT: 0,    A_REM: 15,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-E-1101', CSTD_PLAN: '2024-05-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-4302', CSTD_PLAN: '2024-05-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 101,   A_ACT: 65,   A_REM: 36,    A_PRO: 64,  B_TOT: 90,   B_ACT: 62,  B_REM: 28,   B_PRO: 68,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 21,  AP_ACT: 19,  AP_REM: 2,   AP_PRO: 90,  BP_TOT: 4,   BP_ACT: 4,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-4307', CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 43,    A_ACT: 16,   A_REM: 27,    A_PRO: 37,  B_TOT: 34,   B_ACT: 16,  B_REM: 18,   B_PRO: 47,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 8,   AP_ACT: 8,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 5,   CP_ACT: 5,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-1201', CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 6,    A_REM: 6,     A_PRO: 50,  B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-4001', CSTD_PLAN: '2024-06-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 12,   A_REM: 16,    A_PRO: 42,  B_TOT: 15,   B_ACT: 10,  B_REM: 5,    B_PRO: 66,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-2001', CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 44,    A_ACT: 4,    A_REM: 40,    A_PRO: 9,   B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-4306', CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 39,    A_ACT: 25,   A_REM: 14,    A_PRO: 64,  B_TOT: 34,   B_ACT: 24,  B_REM: 10,   B_PRO: 70,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 10,  AP_ACT: 10,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-2201', CSTD_PLAN: '2024-06-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 27,    A_ACT: 3,    A_REM: 24,    A_PRO: 11,  B_TOT: 9,    B_ACT: 0,   B_REM: 9,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-3101', CSTD_PLAN: '2024-06-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-2102', CSTD_PLAN: '2024-06-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 9,    B_ACT: 8,   B_REM: 1,    B_PRO: 88,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 3,   CP_ACT: 3,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-4601', CSTD_PLAN: '2024-06-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 3,     A_ACT: 1,    A_REM: 2,     A_PRO: 33,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-3301', CSTD_PLAN: '2024-06-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 3,     A_ACT: 0,    A_REM: 3,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-P-0102', CSTD_PLAN: '2024-06-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 56,    A_ACT: 1,    A_REM: 55,    A_PRO: 1,   B_TOT: 25,   B_ACT: 0,   B_REM: 25,   B_PRO: 0,   C_TOT: 5,  C_ACT: 0, C_REM: 5,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-P-0103', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 55,    A_ACT: 1,    A_REM: 54,    A_PRO: 1,   B_TOT: 24,   B_ACT: 0,   B_REM: 24,   B_PRO: 0,   C_TOT: 5,  C_ACT: 0, C_REM: 5,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-P-0101', CSTD_PLAN: '2024-06-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 73,    A_ACT: 0,    A_REM: 73,    A_PRO: 0,   B_TOT: 40,   B_ACT: 0,   B_REM: 40,   B_PRO: 0,   C_TOT: 5,  C_ACT: 0, C_REM: 5,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-4305', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 173,   A_ACT: 64,   A_REM: 109,   A_PRO: 37,  B_TOT: 106,  B_ACT: 44,  B_REM: 62,   B_PRO: 41,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 34,  AP_ACT: 5,   AP_REM: 29,  AP_PRO: 14,  BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 3,   CP_ACT: 0,   CP_REM: 3,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-C-5401', CSTD_PLAN: '2024-07-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 30,    A_ACT: 10,   A_REM: 20,    A_PRO: 33,  B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-4301', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 369,   A_ACT: 11,   A_REM: 358,   A_PRO: 3,   B_TOT: 161,  B_ACT: 0,   B_REM: 161,  B_PRO: 0,   C_TOT: 21, C_ACT: 0, C_REM: 21, C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-4304', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 147,   A_ACT: 78,   A_REM: 69,    A_PRO: 53,  B_TOT: 94,   B_ACT: 48,  B_REM: 46,   B_PRO: 51,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 58,  AP_ACT: 39,  AP_REM: 19,  AP_PRO: 67,  BP_TOT: 3,   BP_ACT: 3,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 8,   CP_ACT: 6,   CP_REM: 2,  CP_PRO: 75, },
      { UNIT: 'CWS',         SUBSYS_NO: '21200-U-4303', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 257,   A_ACT: 67,   A_REM: 190,   A_PRO: 26,  B_TOT: 190,  B_ACT: 66,  B_REM: 124,  B_PRO: 34,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 31,  AP_ACT: 24,  AP_REM: 7,   AP_PRO: 77,  BP_TOT: 11,  BP_ACT: 6,   BP_REM: 5,   BP_PRO: 54,  CP_TOT: 8,   CP_ACT: 1,   CP_REM: 7,  CP_PRO: 12, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-C-0101', CSTD_PLAN: '2024-02-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 109,   A_ACT: 29,   A_REM: 80,    A_PRO: 26,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-C-0501', CSTD_PLAN: '2024-03-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 26,    A_ACT: 10,   A_REM: 16,    A_PRO: 38,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 27,  AP_ACT: 3,   AP_REM: 24,  AP_PRO: 11,  BP_TOT: 8,   BP_ACT: 4,   BP_REM: 4,   BP_PRO: 50,  CP_TOT: 46,  CP_ACT: 14,  CP_REM: 32, CP_PRO: 30, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-C-0801', CSTD_PLAN: '2024-03-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 409,   A_ACT: 219,  A_REM: 190,   A_PRO: 53,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 3,   AP_REM: 2,   AP_PRO: 60,  BP_TOT: 9,   BP_ACT: 5,   BP_REM: 4,   BP_PRO: 55,  CP_TOT: 10,  CP_ACT: 1,   CP_REM: 9,  CP_PRO: 10, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-1001', CSTD_PLAN: '2024-03-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 57,    A_ACT: 36,   A_REM: 21,    A_PRO: 63,  B_TOT: 36,   B_ACT: 24,  B_REM: 12,   B_PRO: 66,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 3,   AP_REM: 2,   AP_PRO: 60,  BP_TOT: 3,   BP_ACT: 0,   BP_REM: 3,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-1101', CSTD_PLAN: '2024-04-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 54,    A_ACT: 24,   A_REM: 30,    A_PRO: 44,  B_TOT: 44,   B_ACT: 24,  B_REM: 20,   B_PRO: 54,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 11,  AP_ACT: 11,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 8,   BP_ACT: 6,   BP_REM: 2,   BP_PRO: 75,  CP_TOT: 7,   CP_ACT: 4,   CP_REM: 3,  CP_PRO: 57, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-E-1001', CSTD_PLAN: '2024-04-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 11,    A_ACT: 3,    A_REM: 8,     A_PRO: 27,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 3,   AP_REM: 1,   AP_PRO: 75,  BP_TOT: 4,   BP_ACT: 2,   BP_REM: 2,   BP_PRO: 50,  CP_TOT: 8,   CP_ACT: 0,   CP_REM: 8,  CP_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-C-0201', CSTD_PLAN: '2024-04-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 72,    A_ACT: 57,   A_REM: 15,    A_PRO: 79,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 14,  BP_ACT: 10,  BP_REM: 4,   BP_PRO: 71,  CP_TOT: 15,  CP_ACT: 1,   CP_REM: 14, CP_PRO: 6, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-I-0201', CSTD_PLAN: '2024-04-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 0,    A_REM: 28,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-I-0202', CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-E-1101', CSTD_PLAN: '2024-05-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 73,    A_ACT: 30,   A_REM: 43,    A_PRO: 41,  B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 27,  AP_ACT: 10,  AP_REM: 17,  AP_PRO: 37,  BP_TOT: 40,  BP_ACT: 19,  BP_REM: 21,  BP_PRO: 47,  CP_TOT: 15,  CP_ACT: 3,   CP_REM: 12, CP_PRO: 20, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-E-1301', CSTD_PLAN: '2024-05-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 27,    A_ACT: 11,   A_REM: 16,    A_PRO: 40,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-I-0301', CSTD_PLAN: '2024-05-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 118,   A_ACT: 3,    A_REM: 115,   A_PRO: 2,   B_TOT: 24,   B_ACT: 0,   B_REM: 24,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-T-0101', CSTD_PLAN: '2024-05-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 31,    A_ACT: 0,    A_REM: 31,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-I-0401', CSTD_PLAN: '2024-05-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-1202', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 22,    A_ACT: 16,   A_REM: 6,     A_PRO: 72,  B_TOT: 20,   B_ACT: 16,  B_REM: 4,    B_PRO: 80,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 5,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 8,   BP_ACT: 8,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 5,   CP_ACT: 4,   CP_REM: 1,  CP_PRO: 80, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-C-0401', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 0,    A_REM: 28,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-2101', CSTD_PLAN: '2024-06-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 161,   A_ACT: 108,  A_REM: 53,    A_PRO: 67,  B_TOT: 61,   B_ACT: 34,  B_REM: 27,   B_PRO: 55,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 13,  AP_ACT: 2,   AP_REM: 11,  AP_PRO: 15,  BP_TOT: 25,  BP_ACT: 5,   BP_REM: 20,  BP_PRO: 20,  CP_TOT: 15,  CP_ACT: 2,   CP_REM: 13, CP_PRO: 13, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-2102', CSTD_PLAN: '2024-06-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 21,    A_ACT: 20,   A_REM: 1,     A_PRO: 95,  B_TOT: 15,   B_ACT: 14,  B_REM: 1,    B_PRO: 93,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 3,   BP_ACT: 2,   BP_REM: 1,   BP_PRO: 66,  CP_TOT: 4,   CP_ACT: 2,   CP_REM: 2,  CP_PRO: 50, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-4001', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 32,    A_ACT: 26,   A_REM: 6,     A_PRO: 81,  B_TOT: 30,   B_ACT: 26,  B_REM: 4,    B_PRO: 86,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 12,  AP_ACT: 9,   AP_REM: 3,   AP_PRO: 75,  BP_TOT: 8,   BP_ACT: 6,   BP_REM: 2,   BP_PRO: 75,  CP_TOT: 2,   CP_ACT: 1,   CP_REM: 1,  CP_PRO: 50, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-4601', CSTD_PLAN: '2024-07-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 8,    A_REM: 20,    A_PRO: 28,  B_TOT: 27,   B_ACT: 0,   B_REM: 27,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 0,   AP_REM: 3,   AP_PRO: 0,   BP_TOT: 6,   BP_ACT: 4,   BP_REM: 2,   BP_PRO: 66,  CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-4101', CSTD_PLAN: '2024-07-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-C-5401', CSTD_PLAN: '2024-07-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 40,    A_ACT: 9,    A_REM: 31,    A_PRO: 22,  B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-2103', CSTD_PLAN: '2024-07-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1092,  A_ACT: 500,  A_REM: 592,   A_PRO: 45,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-7401', CSTD_PLAN: '2024-07-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 289,   A_ACT: 152,  A_REM: 137,   A_PRO: 52,  B_TOT: 97,   B_ACT: 37,  B_REM: 60,   B_PRO: 38,  C_TOT: 7,  C_ACT: 0, C_REM: 7,  C_PRO: 0, AP_TOT: 25,  AP_ACT: 17,  AP_REM: 8,   AP_PRO: 68,  BP_TOT: 42,  BP_ACT: 23,  BP_REM: 19,  BP_PRO: 54,  CP_TOT: 28,  CP_ACT: 6,   CP_REM: 22, CP_PRO: 21, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0302', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 687,   A_ACT: 277,  A_REM: 410,   A_PRO: 40,  B_TOT: 267,  B_ACT: 103, B_REM: 164,  B_PRO: 38,  C_TOT: 6,  C_ACT: 0, C_REM: 6,  C_PRO: 0, AP_TOT: 101, AP_ACT: 20,  AP_REM: 81,  AP_PRO: 19,  BP_TOT: 39,  BP_ACT: 29,  BP_REM: 10,  BP_PRO: 74,  CP_TOT: 37,  CP_ACT: 11,  CP_REM: 26, CP_PRO: 29, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0601', CSTD_PLAN: '2024-07-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 186,   A_ACT: 116,  A_REM: 70,    A_PRO: 62,  B_TOT: 104,  B_ACT: 70,  B_REM: 34,   B_PRO: 67,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 37,  AP_ACT: 13,  AP_REM: 24,  AP_PRO: 35,  BP_TOT: 80,  BP_ACT: 45,  BP_REM: 35,  BP_PRO: 56,  CP_TOT: 91,  CP_ACT: 47,  CP_REM: 44, CP_PRO: 51, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0603', CSTD_PLAN: '2024-07-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 342,   A_ACT: 130,  A_REM: 212,   A_PRO: 38,  B_TOT: 161,  B_ACT: 65,  B_REM: 96,   B_PRO: 40,  C_TOT: 4,  C_ACT: 0, C_REM: 4,  C_PRO: 0, AP_TOT: 20,  AP_ACT: 17,  AP_REM: 3,   AP_PRO: 85,  BP_TOT: 9,   BP_ACT: 4,   BP_REM: 5,   BP_PRO: 44,  CP_TOT: 5,   CP_ACT: 2,   CP_REM: 3,  CP_PRO: 40, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0602', CSTD_PLAN: '2024-08-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 390,   A_ACT: 89,   A_REM: 301,   A_PRO: 22,  B_TOT: 254,  B_ACT: 62,  B_REM: 192,  B_PRO: 24,  C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 57,  AP_ACT: 15,  AP_REM: 42,  AP_PRO: 26,  BP_TOT: 28,  BP_ACT: 14,  BP_REM: 14,  BP_PRO: 50,  CP_TOT: 23,  CP_ACT: 9,   CP_REM: 14, CP_PRO: 39, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-6004', CSTD_PLAN: '2024-08-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 45,    A_ACT: 27,   A_REM: 18,    A_PRO: 60,  B_TOT: 22,   B_ACT: 14,  B_REM: 8,    B_PRO: 63,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 16,  AP_ACT: 13,  AP_REM: 3,   AP_PRO: 81,  BP_TOT: 6,   BP_ACT: 2,   BP_REM: 4,   BP_PRO: 33,  CP_TOT: 7,   CP_ACT: 2,   CP_REM: 5,  CP_PRO: 28, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-6003', CSTD_PLAN: '2024-08-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 75,    A_ACT: 35,   A_REM: 40,    A_PRO: 46,  B_TOT: 33,   B_ACT: 14,  B_REM: 19,   B_PRO: 42,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 8,   AP_ACT: 5,   AP_REM: 3,   AP_PRO: 62,  BP_TOT: 8,   BP_ACT: 4,   BP_REM: 4,   BP_PRO: 50,  CP_TOT: 9,   CP_ACT: 3,   CP_REM: 6,  CP_PRO: 33, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-6002', CSTD_PLAN: '2024-08-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 149,   A_ACT: 69,   A_REM: 80,    A_PRO: 46,  B_TOT: 72,   B_ACT: 41,  B_REM: 31,   B_PRO: 56,  C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 56,  AP_ACT: 15,  AP_REM: 41,  AP_PRO: 26,  BP_TOT: 34,  BP_ACT: 4,   BP_REM: 30,  BP_PRO: 11,  CP_TOT: 11,  CP_ACT: 2,   CP_REM: 9,  CP_PRO: 18, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-6001', CSTD_PLAN: '2024-08-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 105,   A_ACT: 48,   A_REM: 57,    A_PRO: 45,  B_TOT: 53,   B_ACT: 26,  B_REM: 27,   B_PRO: 49,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 41,  AP_ACT: 28,  AP_REM: 13,  AP_PRO: 68,  BP_TOT: 11,  BP_ACT: 5,   BP_REM: 6,   BP_PRO: 45,  CP_TOT: 3,   CP_ACT: 0,   CP_REM: 3,  CP_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0201', CSTD_PLAN: '2024-08-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 615,   A_ACT: 352,  A_REM: 263,   A_PRO: 57,  B_TOT: 174,  B_ACT: 85,  B_REM: 89,   B_PRO: 48,  C_TOT: 14, C_ACT: 0, C_REM: 14, C_PRO: 0, AP_TOT: 35,  AP_ACT: 22,  AP_REM: 13,  AP_PRO: 62,  BP_TOT: 59,  BP_ACT: 29,  BP_REM: 30,  BP_PRO: 49,  CP_TOT: 49,  CP_ACT: 15,  CP_REM: 34, CP_PRO: 30, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0402', CSTD_PLAN: '2024-08-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 88,    A_ACT: 22,   A_REM: 66,    A_PRO: 25,  B_TOT: 42,   B_ACT: 6,   B_REM: 36,   B_PRO: 14,  C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 2,   AP_REM: 3,   AP_PRO: 40,  BP_TOT: 3,   BP_ACT: 2,   BP_REM: 1,   BP_PRO: 66,  CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0502', CSTD_PLAN: '2024-08-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 26,    A_ACT: 0,    A_REM: 26,    A_PRO: 0,   B_TOT: 18,   B_ACT: 0,   B_REM: 18,   B_PRO: 0,   C_TOT: 5,  C_ACT: 0, C_REM: 5,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0501', CSTD_PLAN: '2024-08-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 232,   A_ACT: 12,   A_REM: 220,   A_PRO: 5,   B_TOT: 114,  B_ACT: 22,  B_REM: 92,   B_PRO: 19,  C_TOT: 26, C_ACT: 0, C_REM: 26, C_PRO: 0, AP_TOT: 9,   AP_ACT: 5,   AP_REM: 4,   AP_PRO: 55,  BP_TOT: 5,   BP_ACT: 1,   BP_REM: 4,   BP_PRO: 20,  CP_TOT: 3,   CP_ACT: 1,   CP_REM: 2,  CP_PRO: 33, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0301', CSTD_PLAN: '2024-09-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 500,   A_ACT: 121,  A_REM: 379,   A_PRO: 24,  B_TOT: 198,  B_ACT: 59,  B_REM: 139,  B_PRO: 29,  C_TOT: 20, C_ACT: 0, C_REM: 20, C_PRO: 0, AP_TOT: 28,  AP_ACT: 22,  AP_REM: 6,   AP_PRO: 78,  BP_TOT: 30,  BP_ACT: 16,  BP_REM: 14,  BP_PRO: 53,  CP_TOT: 38,  CP_ACT: 11,  CP_REM: 27, CP_PRO: 28, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-U-6102', CSTD_PLAN: '2024-09-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 180,   A_ACT: 99,   A_REM: 81,    A_PRO: 55,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0303', CSTD_PLAN: '2024-09-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 234,   A_ACT: 42,   A_REM: 192,   A_PRO: 17,  B_TOT: 115,  B_ACT: 8,   B_REM: 107,  B_PRO: 7,   C_TOT: 6,  C_ACT: 0, C_REM: 6,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 2,   AP_REM: 1,   AP_PRO: 66,  BP_TOT: 6,   BP_ACT: 6,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0401', CSTD_PLAN: '2024-09-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1550,  A_ACT: 767,  A_REM: 783,   A_PRO: 49,  B_TOT: 575,  B_ACT: 203, B_REM: 372,  B_PRO: 35,  C_TOT: 17, C_ACT: 0, C_REM: 17, C_PRO: 0, AP_TOT: 107, AP_ACT: 75,  AP_REM: 32,  AP_PRO: 70,  BP_TOT: 123, BP_ACT: 69,  BP_REM: 54,  BP_PRO: 56,  CP_TOT: 97,  CP_ACT: 22,  CP_REM: 75, CP_PRO: 22, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-P-0101', CSTD_PLAN: '2024-10-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 719,   A_ACT: 268,  A_REM: 451,   A_PRO: 37,  B_TOT: 242,  B_ACT: 77,  B_REM: 165,  B_PRO: 31,  C_TOT: 13, C_ACT: 0, C_REM: 13, C_PRO: 0, AP_TOT: 40,  AP_ACT: 27,  AP_REM: 13,  AP_PRO: 67,  BP_TOT: 37,  BP_ACT: 23,  BP_REM: 14,  BP_PRO: 62,  CP_TOT: 41,  CP_ACT: 9,   CP_REM: 32, CP_PRO: 22, },
      { UNIT: 'DAU',         SUBSYS_NO: '20100-I-0101', CSTD_PLAN: '2024-10-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 111,   A_ACT: 3,    A_REM: 108,   A_PRO: 2,   B_TOT: 35,   B_ACT: 0,   B_REM: 35,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-0901', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 14,    A_ACT: 0,    A_REM: 14,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-0902', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-E-1501', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 20,    A_ACT: 12,   A_REM: 8,     A_PRO: 60,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-0101', CSTD_PLAN: '2024-04-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 320,   A_ACT: 55,   A_REM: 265,   A_PRO: 17,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-1202', CSTD_PLAN: '2024-05-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 70,    A_ACT: 36,   A_REM: 34,    A_PRO: 51,  B_TOT: 66,   B_ACT: 36,  B_REM: 30,   B_PRO: 54,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 19,  AP_ACT: 19,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 7,   BP_ACT: 7,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-2401', CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 156,   A_ACT: 46,   A_REM: 110,   A_PRO: 29,  B_TOT: 53,   B_ACT: 20,  B_REM: 33,   B_PRO: 37,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 74,  AP_ACT: 21,  AP_REM: 53,  AP_PRO: 28,  BP_TOT: 17,  BP_ACT: 2,   BP_REM: 15,  BP_PRO: 11,  CP_TOT: 6,   CP_ACT: 4,   CP_REM: 2,  CP_PRO: 66, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0102', CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 345,   A_ACT: 51,   A_REM: 294,   A_PRO: 14,  B_TOT: 109,  B_ACT: 37,  B_REM: 72,   B_PRO: 33,  C_TOT: 18, C_ACT: 0, C_REM: 18, C_PRO: 0, AP_TOT: 6,   AP_ACT: 6,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 20,  BP_ACT: 17,  BP_REM: 3,   BP_PRO: 85,  CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0109', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12624, A_ACT: 1294, A_REM: 11330, A_PRO: 10,  B_TOT: 2394, B_ACT: 7,   B_REM: 2387, B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0103', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 775,   A_ACT: 84,   A_REM: 691,   A_PRO: 10,  B_TOT: 158,  B_ACT: 24,  B_REM: 134,  B_PRO: 15,  C_TOT: 41, C_ACT: 0, C_REM: 41, C_PRO: 0, AP_TOT: 6,   AP_ACT: 4,   AP_REM: 2,   AP_PRO: 66,  BP_TOT: 5,   BP_ACT: 5,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0104', CSTD_PLAN: '2024-05-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1178,  A_ACT: 74,   A_REM: 1104,  A_PRO: 6,   B_TOT: 367,  B_ACT: 29,  B_REM: 338,  B_PRO: 7,   C_TOT: 11, C_ACT: 0, C_REM: 11, C_PRO: 0, AP_TOT: 3,   AP_ACT: 1,   AP_REM: 2,   AP_PRO: 33,  BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-0501', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 84,    A_ACT: 18,   A_REM: 66,    A_PRO: 21,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 61,  AP_ACT: 23,  AP_REM: 38,  AP_PRO: 37,  BP_TOT: 12,  BP_ACT: 10,  BP_REM: 2,   BP_PRO: 83,  CP_TOT: 98,  CP_ACT: 67,  CP_REM: 31, CP_PRO: 68, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-7101', CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 147,   A_ACT: 16,   A_REM: 131,   A_PRO: 10,  B_TOT: 27,   B_ACT: 2,   B_REM: 25,   B_PRO: 7,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-0801', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1711,  A_ACT: 514,  A_REM: 1197,  A_PRO: 30,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 3,   BP_ACT: 3,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-2103', CSTD_PLAN: '2024-06-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1305,  A_ACT: 461,  A_REM: 844,   A_PRO: 35,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 5,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-E-1001', CSTD_PLAN: '2024-06-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 42,    A_ACT: 6,    A_REM: 36,    A_PRO: 14,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 5,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-1101', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 126,   A_ACT: 52,   A_REM: 74,    A_PRO: 41,  B_TOT: 106,  B_ACT: 52,  B_REM: 54,   B_PRO: 49,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 22,  AP_ACT: 20,  AP_REM: 2,   AP_PRO: 90,  BP_TOT: 11,  BP_ACT: 8,   BP_REM: 3,   BP_PRO: 72,  CP_TOT: 5,   CP_ACT: 5,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0902', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 41,    A_ACT: 0,    A_REM: 41,    A_PRO: 0,   B_TOT: 15,   B_ACT: 0,   B_REM: 15,   B_PRO: 0,   C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-1001', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 139,   A_ACT: 31,   A_REM: 108,   A_PRO: 22,  B_TOT: 78,   B_ACT: 18,  B_REM: 60,   B_PRO: 23,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 6,   AP_ACT: 4,   AP_REM: 2,   AP_PRO: 66,  BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-2102', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 102,   A_ACT: 80,   A_REM: 22,    A_PRO: 78,  B_TOT: 71,   B_ACT: 54,  B_REM: 17,   B_PRO: 76,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 25,  AP_ACT: 19,  AP_REM: 6,   AP_PRO: 76,  BP_TOT: 14,  BP_ACT: 13,  BP_REM: 1,   BP_PRO: 92,  CP_TOT: 5,   CP_ACT: 5,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0901', CSTD_PLAN: '2024-06-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 39,    A_ACT: 0,    A_REM: 39,    A_PRO: 0,   B_TOT: 15,   B_ACT: 0,   B_REM: 15,   B_PRO: 0,   C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-2003', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-4601', CSTD_PLAN: '2024-06-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 6,    A_REM: 22,    A_PRO: 21,  B_TOT: 23,   B_ACT: 0,   B_REM: 23,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-7401', CSTD_PLAN: '2024-06-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 34,    A_ACT: 15,   A_REM: 19,    A_PRO: 44,  B_TOT: 17,   B_ACT: 10,  B_REM: 7,    B_PRO: 58,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 6,   AP_ACT: 3,   AP_REM: 3,   AP_PRO: 50,  BP_TOT: 3,   BP_ACT: 1,   BP_REM: 2,   BP_PRO: 33,  CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-I-0202', CSTD_PLAN: '2024-06-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 60,    A_ACT: 10,   A_REM: 50,    A_PRO: 16,  B_TOT: 14,   B_ACT: 0,   B_REM: 14,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-T-0101', CSTD_PLAN: '2024-06-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 97,    A_ACT: 0,    A_REM: 97,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-4101', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 30,    A_ACT: 0,    A_REM: 30,    A_PRO: 0,   B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-4001', CSTD_PLAN: '2024-06-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 82,    A_ACT: 48,   A_REM: 34,    A_PRO: 58,  B_TOT: 76,   B_ACT: 48,  B_REM: 28,   B_PRO: 63,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 12,  AP_ACT: 12,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 8,   BP_ACT: 8,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0903', CSTD_PLAN: '2024-06-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 40,    A_ACT: 0,    A_REM: 40,    A_PRO: 0,   B_TOT: 14,   B_ACT: 0,   B_REM: 14,   B_PRO: 0,   C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-2301', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 146,   A_ACT: 44,   A_REM: 102,   A_PRO: 30,  B_TOT: 56,   B_ACT: 20,  B_REM: 36,   B_PRO: 35,  C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 2,   AP_REM: 2,   AP_PRO: 50,  BP_TOT: 7,   BP_ACT: 6,   BP_REM: 1,   BP_PRO: 85,  CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-0201', CSTD_PLAN: '2024-07-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 100,   A_ACT: 41,   A_REM: 59,    A_PRO: 41,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 3,   BP_ACT: 3,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 58,  CP_ACT: 53,  CP_REM: 5,  CP_PRO: 91, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-7001', CSTD_PLAN: '2024-07-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 353,   A_ACT: 38,   A_REM: 315,   A_PRO: 10,  B_TOT: 75,   B_ACT: 8,   B_REM: 67,   B_PRO: 10,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-0401', CSTD_PLAN: '2024-07-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 92,    A_ACT: 0,    A_REM: 92,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0601', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 437,   A_ACT: 90,   A_REM: 347,   A_PRO: 20,  B_TOT: 173,  B_ACT: 46,  B_REM: 127,  B_PRO: 26,  C_TOT: 7,  C_ACT: 0, C_REM: 7,  C_PRO: 0, AP_TOT: 30,  AP_ACT: 21,  AP_REM: 9,   AP_PRO: 70,  BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 8,   CP_ACT: 5,   CP_REM: 3,  CP_PRO: 62, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0401', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 294,   A_ACT: 68,   A_REM: 226,   A_PRO: 23,  B_TOT: 106,  B_ACT: 43,  B_REM: 63,   B_PRO: 40,  C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 60,  AP_ACT: 13,  AP_REM: 47,  AP_PRO: 21,  BP_TOT: 9,   BP_ACT: 1,   BP_REM: 8,   BP_PRO: 11,  CP_TOT: 5,   CP_ACT: 2,   CP_REM: 3,  CP_PRO: 40, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-E-1101', CSTD_PLAN: '2024-08-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 278,   A_ACT: 103,  A_REM: 175,   A_PRO: 37,  B_TOT: 34,   B_ACT: 0,   B_REM: 34,   B_PRO: 0,   C_TOT: 13, C_ACT: 0, C_REM: 13, C_PRO: 0, AP_TOT: 313, AP_ACT: 9,   AP_REM: 304, AP_PRO: 2,   BP_TOT: 5,   BP_ACT: 5,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 4,   CP_ACT: 2,   CP_REM: 2,  CP_PRO: 50, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-I-0201', CSTD_PLAN: '2024-08-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 98,    A_ACT: 0,    A_REM: 98,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-E-1301', CSTD_PLAN: '2024-08-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 75,    A_ACT: 0,    A_REM: 75,    A_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0106', CSTD_PLAN: '2024-08-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 153,   A_ACT: 23,   A_REM: 130,   A_PRO: 15,  B_TOT: 49,   B_ACT: 0,   B_REM: 49,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0602', CSTD_PLAN: '2024-08-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 86,    A_ACT: 22,   A_REM: 64,    A_PRO: 25,  B_TOT: 52,   B_ACT: 24,  B_REM: 28,   B_PRO: 46,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 37,  AP_ACT: 8,   AP_REM: 29,  AP_PRO: 21,  BP_TOT: 3,   BP_ACT: 0,   BP_REM: 3,   BP_PRO: 0,   CP_TOT: 5,   CP_ACT: 1,   CP_REM: 4,  CP_PRO: 20, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0701', CSTD_PLAN: '2024-08-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 570,   A_ACT: 100,  A_REM: 470,   A_PRO: 17,  B_TOT: 108,  B_ACT: 5,   B_REM: 103,  B_PRO: 4,   C_TOT: 16, C_ACT: 0, C_REM: 16, C_PRO: 0, AP_TOT: 6,   AP_ACT: 5,   AP_REM: 1,   AP_PRO: 83,  BP_TOT: 5,   BP_ACT: 3,   BP_REM: 2,   BP_PRO: 60,  CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-C-5401', CSTD_PLAN: '2024-08-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 156,   A_ACT: 26,   A_REM: 130,   A_PRO: 16,  B_TOT: 26,   B_ACT: 14,  B_REM: 12,   B_PRO: 53,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0801', CSTD_PLAN: '2024-08-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 102,   A_ACT: 44,   A_REM: 58,    A_PRO: 43,  B_TOT: 71,   B_ACT: 36,  B_REM: 35,   B_PRO: 50,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 1,   AP_REM: 2,   AP_PRO: 33,  BP_TOT: 4,   BP_ACT: 3,   BP_REM: 1,   BP_PRO: 75,  CP_TOT: 3,   CP_ACT: 3,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0107', CSTD_PLAN: '2024-08-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 146,   A_ACT: 15,   A_REM: 131,   A_PRO: 10,  B_TOT: 22,   B_ACT: 0,   B_REM: 22,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-U-6005', CSTD_PLAN: '2024-08-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 113,   A_ACT: 35,   A_REM: 78,    A_PRO: 31,  B_TOT: 90,   B_ACT: 34,  B_REM: 56,   B_PRO: 37,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 20,  AP_ACT: 18,  AP_REM: 2,   AP_PRO: 90,  BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-I-0301', CSTD_PLAN: '2024-08-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 761,   A_ACT: 0,    A_REM: 761,   A_PRO: 0,   B_TOT: 157,  B_ACT: 0,   B_REM: 157,  B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 0,   AP_REM: 1,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-I-0401', CSTD_PLAN: '2024-08-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 0,    A_REM: 28,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0703', CSTD_PLAN: '2024-08-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 306,   A_ACT: 119,  A_REM: 187,   A_PRO: 38,  B_TOT: 112,  B_ACT: 50,  B_REM: 62,   B_PRO: 44,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 9,   AP_ACT: 4,   AP_REM: 5,   AP_PRO: 44,  BP_TOT: 18,  BP_ACT: 15,  BP_REM: 3,   BP_PRO: 83,  CP_TOT: 3,   CP_ACT: 3,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0802', CSTD_PLAN: '2024-08-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 904,   A_ACT: 71,   A_REM: 833,   A_PRO: 7,   B_TOT: 310,  B_ACT: 15,  B_REM: 295,  B_PRO: 4,   C_TOT: 38, C_ACT: 0, C_REM: 38, C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0807', CSTD_PLAN: '2024-08-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 221,   A_ACT: 35,   A_REM: 186,   A_PRO: 15,  B_TOT: 68,   B_ACT: 3,   B_REM: 65,   B_PRO: 4,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 9,   AP_ACT: 0,   AP_REM: 9,   AP_PRO: 0,   BP_TOT: 2,   BP_ACT: 0,   BP_REM: 2,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0803', CSTD_PLAN: '2024-08-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 898,   A_ACT: 65,   A_REM: 833,   A_PRO: 7,   B_TOT: 290,  B_ACT: 7,   B_REM: 283,  B_PRO: 2,   C_TOT: 38, C_ACT: 0, C_REM: 38, C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0805', CSTD_PLAN: '2024-08-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 220,   A_ACT: 34,   A_REM: 186,   A_PRO: 15,  B_TOT: 66,   B_ACT: 7,   B_REM: 59,   B_PRO: 10,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 0,   AP_REM: 5,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0804', CSTD_PLAN: '2024-08-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 917,   A_ACT: 60,   A_REM: 857,   A_PRO: 6,   B_TOT: 302,  B_ACT: 10,  B_REM: 292,  B_PRO: 3,   C_TOT: 38, C_ACT: 0, C_REM: 38, C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0806', CSTD_PLAN: '2024-08-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 216,   A_ACT: 31,   A_REM: 185,   A_PRO: 14,  B_TOT: 67,   B_ACT: 3,   B_REM: 64,   B_PRO: 4,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 15,  AP_ACT: 0,   AP_REM: 15,  AP_PRO: 0,   BP_TOT: 3,   BP_ACT: 0,   BP_REM: 3,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0704', CSTD_PLAN: '2024-08-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 348,   A_ACT: 73,   A_REM: 275,   A_PRO: 21,  B_TOT: 127,  B_ACT: 40,  B_REM: 87,   B_PRO: 31,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 25,  AP_ACT: 3,   AP_REM: 22,  AP_PRO: 12,  BP_TOT: 11,  BP_ACT: 4,   BP_REM: 7,   BP_PRO: 36,  CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0203', CSTD_PLAN: '2024-09-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 263,   A_ACT: 4,    A_REM: 259,   A_PRO: 1,   B_TOT: 131,  B_ACT: 0,   B_REM: 131,  B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0402', CSTD_PLAN: '2024-09-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 623,   A_ACT: 157,  A_REM: 466,   A_PRO: 25,  B_TOT: 253,  B_ACT: 85,  B_REM: 168,  B_PRO: 33,  C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 82,  AP_ACT: 11,  AP_REM: 71,  AP_PRO: 13,  BP_TOT: 10,  BP_ACT: 3,   BP_REM: 7,   BP_PRO: 30,  CP_TOT: 13,  CP_ACT: 7,   CP_REM: 6,  CP_PRO: 53, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0108', CSTD_PLAN: '2024-09-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 112,   A_ACT: 24,   A_REM: 88,    A_PRO: 21,  B_TOT: 16,   B_ACT: 0,   B_REM: 16,   B_PRO: 0,   C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 0,   AP_REM: 5,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0705', CSTD_PLAN: '2024-09-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 334,   A_ACT: 111,  A_REM: 223,   A_PRO: 33,  B_TOT: 160,  B_ACT: 60,  B_REM: 100,  B_PRO: 37,  C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 68,  AP_ACT: 28,  AP_REM: 40,  AP_PRO: 41,  BP_TOT: 28,  BP_ACT: 20,  BP_REM: 8,   BP_PRO: 71,  CP_TOT: 5,   CP_ACT: 4,   CP_REM: 1,  CP_PRO: 80, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0706', CSTD_PLAN: '2024-09-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 91,    A_ACT: 32,   A_REM: 59,    A_PRO: 35,  B_TOT: 15,   B_ACT: 0,   B_REM: 15,   B_PRO: 0,   C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0105', CSTD_PLAN: '2024-09-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 779,   A_ACT: 321,  A_REM: 458,   A_PRO: 41,  B_TOT: 360,  B_ACT: 196, B_REM: 164,  B_PRO: 54,  C_TOT: 5,  C_ACT: 0, C_REM: 5,  C_PRO: 0, AP_TOT: 114, AP_ACT: 64,  AP_REM: 50,  AP_PRO: 56,  BP_TOT: 66,  BP_ACT: 58,  BP_REM: 8,   BP_PRO: 87,  CP_TOT: 30,  CP_ACT: 28,  CP_REM: 2,  CP_PRO: 93, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0403', CSTD_PLAN: '2024-10-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 669,   A_ACT: 222,  A_REM: 447,   A_PRO: 33,  B_TOT: 289,  B_ACT: 70,  B_REM: 219,  B_PRO: 24,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 18,  AP_ACT: 12,  AP_REM: 6,   AP_PRO: 66,  BP_TOT: 9,   BP_ACT: 8,   BP_REM: 1,   BP_PRO: 88,  CP_TOT: 5,   CP_ACT: 4,   CP_REM: 1,  CP_PRO: 80, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0501', CSTD_PLAN: '2024-10-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 653,   A_ACT: 65,   A_REM: 588,   A_PRO: 10,  B_TOT: 265,  B_ACT: 52,  B_REM: 213,  B_PRO: 19,  C_TOT: 17, C_ACT: 0, C_REM: 17, C_PRO: 0, AP_TOT: 37,  AP_ACT: 13,  AP_REM: 24,  AP_PRO: 35,  BP_TOT: 3,   BP_ACT: 3,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 11,  CP_ACT: 7,   CP_REM: 4,  CP_PRO: 63, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0202', CSTD_PLAN: '2024-10-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 114,   A_ACT: 7,    A_REM: 107,   A_PRO: 6,   B_TOT: 22,   B_ACT: 0,   B_REM: 22,   B_PRO: 0,   C_TOT: 17, C_ACT: 0, C_REM: 17, C_PRO: 0, AP_TOT: 1,   AP_ACT: 0,   AP_REM: 1,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0301', CSTD_PLAN: '2024-10-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 744,   A_ACT: 221,  A_REM: 523,   A_PRO: 29,  B_TOT: 304,  B_ACT: 84,  B_REM: 220,  B_PRO: 27,  C_TOT: 9,  C_ACT: 0, C_REM: 9,  C_PRO: 0, AP_TOT: 83,  AP_ACT: 33,  AP_REM: 50,  AP_PRO: 39,  BP_TOT: 20,  BP_ACT: 11,  BP_REM: 9,   BP_PRO: 55,  CP_TOT: 16,  CP_ACT: 14,  CP_REM: 2,  CP_PRO: 87, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-I-0101', CSTD_PLAN: '2024-10-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 390,   A_ACT: 0,    A_REM: 390,   A_PRO: 0,   B_TOT: 124,  B_ACT: 0,   B_REM: 124,  B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 9,   AP_ACT: 0,   AP_REM: 9,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0201', CSTD_PLAN: '2024-10-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1583,  A_ACT: 268,  A_REM: 1315,  A_PRO: 16,  B_TOT: 563,  B_ACT: 184, B_REM: 379,  B_PRO: 32,  C_TOT: 22, C_ACT: 0, C_REM: 22, C_PRO: 0, AP_TOT: 257, AP_ACT: 74,  AP_REM: 183, AP_PRO: 28,  BP_TOT: 22,  BP_ACT: 13,  BP_REM: 9,   BP_PRO: 59,  CP_TOT: 21,  CP_ACT: 7,   CP_REM: 14, CP_PRO: 33, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0101', CSTD_PLAN: '2024-10-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1168,  A_ACT: 226,  A_REM: 942,   A_PRO: 19,  B_TOT: 387,  B_ACT: 117, B_REM: 270,  B_PRO: 30,  C_TOT: 26, C_ACT: 0, C_REM: 26, C_PRO: 0, AP_TOT: 22,  AP_ACT: 20,  AP_REM: 2,   AP_PRO: 90,  BP_TOT: 42,  BP_ACT: 39,  BP_REM: 3,   BP_PRO: 92,  CP_TOT: 39,  CP_ACT: 39,  CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S1',      SUBSYS_NO: '20200-P-0702', CSTD_PLAN: '2024-10-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 968,   A_ACT: 132,  A_REM: 836,   A_PRO: 13,  B_TOT: 365,  B_ACT: 41,  B_REM: 324,  B_PRO: 11,  C_TOT: 15, C_ACT: 0, C_REM: 15, C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 4,   BP_ACT: 3,   BP_REM: 1,   BP_PRO: 75,  CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-E-1501', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 8,     A_ACT: 0,    A_REM: 8,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-C-0101', CSTD_PLAN: '2024-02-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 48,    A_ACT: 9,    A_REM: 39,    A_PRO: 18,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-C-0501', CSTD_PLAN: '2024-03-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 16,    A_ACT: 4,    A_REM: 12,    A_PRO: 25,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 42,  AP_ACT: 29,  AP_REM: 13,  AP_PRO: 69,  BP_TOT: 2,   BP_ACT: 1,   BP_REM: 1,   BP_PRO: 50,  CP_TOT: 41,  CP_ACT: 8,   CP_REM: 33, CP_PRO: 19, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-C-0801', CSTD_PLAN: '2024-04-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 155,   A_ACT: 0,    A_REM: 155,   A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-U-2401', CSTD_PLAN: '2024-05-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 90,    A_ACT: 27,   A_REM: 63,    A_PRO: 30,  B_TOT: 27,   B_ACT: 11,  B_REM: 16,   B_PRO: 40,  C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 49,  AP_ACT: 24,  AP_REM: 25,  AP_PRO: 49,  BP_TOT: 8,   BP_ACT: 1,   BP_REM: 7,   BP_PRO: 12,  CP_TOT: 5,   CP_ACT: 4,   CP_REM: 1,  CP_PRO: 80, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-U-2103', CSTD_PLAN: '2024-05-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 84,    A_ACT: 20,   A_REM: 64,    A_PRO: 23,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-I-0301', CSTD_PLAN: '2024-05-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 110,   A_ACT: 4,    A_REM: 106,   A_PRO: 3,   B_TOT: 24,   B_ACT: 0,   B_REM: 24,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 1,   AP_REM: 3,   AP_PRO: 25,  BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-C-0201', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 29,    A_ACT: 20,   A_REM: 9,     A_PRO: 69,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 20,  CP_ACT: 10,  CP_REM: 10, CP_PRO: 50, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-E-1001', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 1,    A_REM: 3,     A_PRO: 25,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-C-0401', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-E-1101', CSTD_PLAN: '2024-06-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 38,    A_ACT: 4,    A_REM: 34,    A_PRO: 10,  B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 255, AP_ACT: 34,  AP_REM: 221, AP_PRO: 13,  BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0102', CSTD_PLAN: '2024-07-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 596,   A_ACT: 207,  A_REM: 389,   A_PRO: 34,  B_TOT: 147,  B_ACT: 72,  B_REM: 75,   B_PRO: 49,  C_TOT: 21, C_ACT: 0, C_REM: 21, C_PRO: 0, AP_TOT: 37,  AP_ACT: 26,  AP_REM: 11,  AP_PRO: 70,  BP_TOT: 27,  BP_ACT: 25,  BP_REM: 2,   BP_PRO: 92,  CP_TOT: 6,   CP_ACT: 6,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0601', CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 117,   A_ACT: 2,    A_REM: 115,   A_PRO: 1,   B_TOT: 40,   B_ACT: 2,   B_REM: 38,   B_PRO: 5,   C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-C-5401', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 36,    A_ACT: 12,   A_REM: 24,    A_PRO: 33,  B_TOT: 4,    B_ACT: 4,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-U-7401', CSTD_PLAN: '2024-07-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 68,    A_ACT: 50,   A_REM: 18,    A_PRO: 73,  B_TOT: 26,   B_ACT: 20,  B_REM: 6,    B_PRO: 76,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 6,   AP_ACT: 6,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 16,  BP_ACT: 16,  BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0103', CSTD_PLAN: '2024-07-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2693,  A_ACT: 293,  A_REM: 2400,  A_PRO: 10,  B_TOT: 578,  B_ACT: 8,   B_REM: 570,  B_PRO: 1,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0104', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 50,    A_ACT: 30,   A_REM: 20,    A_PRO: 60,  B_TOT: 9,    B_ACT: 2,   B_REM: 7,    B_PRO: 22,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-U-6006', CSTD_PLAN: '2024-08-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 41,    A_ACT: 21,   A_REM: 20,    A_PRO: 51,  B_TOT: 35,   B_ACT: 20,  B_REM: 15,   B_PRO: 57,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 3,   AP_REM: 2,   AP_PRO: 60,  BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0501', CSTD_PLAN: '2024-08-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 89,    A_ACT: 17,   A_REM: 72,    A_PRO: 19,  B_TOT: 43,   B_ACT: 13,  B_REM: 30,   B_PRO: 30,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 6,   AP_ACT: 2,   AP_REM: 4,   AP_PRO: 33,  BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0401', CSTD_PLAN: '2024-08-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 240,   A_ACT: 144,  A_REM: 96,    A_PRO: 60,  B_TOT: 56,   B_ACT: 27,  B_REM: 29,   B_PRO: 48,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 37,  AP_ACT: 28,  AP_REM: 9,   AP_PRO: 75,  BP_TOT: 27,  BP_ACT: 27,  BP_REM: 0,   BP_PRO: 100, CP_TOT: 3,   CP_ACT: 3,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0201', CSTD_PLAN: '2024-09-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 471,   A_ACT: 212,  A_REM: 259,   A_PRO: 45,  B_TOT: 195,  B_ACT: 108, B_REM: 87,   B_PRO: 55,  C_TOT: 7,  C_ACT: 0, C_REM: 7,  C_PRO: 0, AP_TOT: 100, AP_ACT: 43,  AP_REM: 57,  AP_PRO: 43,  BP_TOT: 27,  BP_ACT: 19,  BP_REM: 8,   BP_PRO: 70,  CP_TOT: 12,  CP_ACT: 9,   CP_REM: 3,  CP_PRO: 75, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0303', CSTD_PLAN: '2024-09-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 225,   A_ACT: 83,   A_REM: 142,   A_PRO: 36,  B_TOT: 94,   B_ACT: 32,  B_REM: 62,   B_PRO: 34,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 4,   BP_ACT: 4,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0302', CSTD_PLAN: '2024-09-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 354,   A_ACT: 99,   A_REM: 255,   A_PRO: 28,  B_TOT: 149,  B_ACT: 29,  B_REM: 120,  B_PRO: 19,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 4,   AP_REM: 1,   AP_PRO: 80,  BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0202', CSTD_PLAN: '2024-09-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 228,   A_ACT: 52,   A_REM: 176,   A_PRO: 22,  B_TOT: 69,   B_ACT: 3,   B_REM: 66,   B_PRO: 4,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 1,   AP_REM: 1,   AP_PRO: 50,  BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0304', CSTD_PLAN: '2024-09-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 218,   A_ACT: 84,   A_REM: 134,   A_PRO: 38,  B_TOT: 93,   B_ACT: 35,  B_REM: 58,   B_PRO: 37,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 8,   AP_ACT: 8,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 3,   BP_ACT: 2,   BP_REM: 1,   BP_PRO: 66,  CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0301', CSTD_PLAN: '2024-09-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 372,   A_ACT: 60,   A_REM: 312,   A_PRO: 16,  B_TOT: 119,  B_ACT: 4,   B_REM: 115,  B_PRO: 3,   C_TOT: 10, C_ACT: 0, C_REM: 10, C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0305', CSTD_PLAN: '2024-10-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 664,   A_ACT: 187,  A_REM: 477,   A_PRO: 28,  B_TOT: 258,  B_ACT: 77,  B_REM: 181,  B_PRO: 29,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 75,  AP_ACT: 26,  AP_REM: 49,  AP_PRO: 34,  BP_TOT: 22,  BP_ACT: 15,  BP_REM: 7,   BP_PRO: 68,  CP_TOT: 9,   CP_ACT: 6,   CP_REM: 3,  CP_PRO: 66, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0105', CSTD_PLAN: '2024-10-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 411,   A_ACT: 20,   A_REM: 391,   A_PRO: 4,   B_TOT: 69,   B_ACT: 0,   B_REM: 69,   B_PRO: 0,   C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-P-0101', CSTD_PLAN: '2024-10-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 823,   A_ACT: 174,  A_REM: 649,   A_PRO: 21,  B_TOT: 337,  B_ACT: 106, B_REM: 231,  B_PRO: 31,  C_TOT: 17, C_ACT: 0, C_REM: 17, C_PRO: 0, AP_TOT: 41,  AP_ACT: 25,  AP_REM: 16,  AP_PRO: 61,  BP_TOT: 39,  BP_ACT: 32,  BP_REM: 7,   BP_PRO: 82,  CP_TOT: 30,  CP_ACT: 26,  CP_REM: 4,  CP_PRO: 86, },
      { UNIT: 'DHU S2',      SUBSYS_NO: '20300-I-0101', CSTD_PLAN: '2024-10-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 73,    A_ACT: 1,    A_REM: 72,    A_PRO: 1,   B_TOT: 23,   B_ACT: 0,   B_REM: 23,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 15,  AP_ACT: 0,   AP_REM: 15,  AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-C-0101', CSTD_PLAN: '2024-01-24', CSTD_WALKDOWN: '2023-09-29', CSTD_ACTUAL: '2024-01-04', A_TOT: 18,    A_ACT: 18,   A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 6,   BP_ACT: 2,   BP_REM: 4,   BP_PRO: 33,  CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-C-0501', CSTD_PLAN: '2024-03-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1,     A_ACT: 0,    A_REM: 1,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-U-1001', CSTD_PLAN: '2024-03-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 19,    A_ACT: 6,    A_REM: 13,    A_PRO: 31,  B_TOT: 8,    B_ACT: 2,   B_REM: 6,    B_PRO: 25,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-U-1101', CSTD_PLAN: '2024-03-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 23,    A_ACT: 20,   A_REM: 3,     A_PRO: 87,  B_TOT: 22,   B_ACT: 20,  B_REM: 2,    B_PRO: 90,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 10,  AP_ACT: 10,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-C-0801', CSTD_PLAN: '2024-03-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 4,    A_REM: 5,     A_PRO: 44,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-C-0401', CSTD_PLAN: '2024-04-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 0,    A_REM: 6,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-E-1001', CSTD_PLAN: '2024-04-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 3,    A_REM: 3,     A_PRO: 50,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-I-0202', CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-I-0301', CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 32,    A_ACT: 20,   A_REM: 12,    A_PRO: 62,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-I-0101', CSTD_PLAN: '2024-05-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 33,    A_ACT: 0,    A_REM: 33,    A_PRO: 0,   B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-I-0201', CSTD_PLAN: '2024-05-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 22,    A_ACT: 14,   A_REM: 8,     A_PRO: 63,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-U-1202', CSTD_PLAN: '2024-05-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 21,    A_ACT: 18,   A_REM: 3,     A_PRO: 85,  B_TOT: 20,   B_ACT: 18,  B_REM: 2,    B_PRO: 90,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 16,  AP_ACT: 16,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-U-4001', CSTD_PLAN: '2024-05-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 16,    A_ACT: 16,   A_REM: 0,     A_PRO: 100, B_TOT: 16,   B_ACT: 16,  B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-U-2003', CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 8,     A_ACT: 4,    A_REM: 4,     A_PRO: 50,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-U-2102', CSTD_PLAN: '2024-06-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 21,    A_ACT: 21,   A_REM: 0,     A_PRO: 100, B_TOT: 15,   B_ACT: 14,  B_REM: 1,    B_PRO: 93,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-U-2103', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 128,   A_ACT: 60,   A_REM: 68,    A_PRO: 46,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-E-1101', CSTD_PLAN: '2024-06-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 7,     A_ACT: 0,    A_REM: 7,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-U-4601', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-T-0101', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-P-0203', CSTD_PLAN: '2024-06-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 235,   A_ACT: 76,   A_REM: 159,   A_PRO: 32,  B_TOT: 64,   B_ACT: 12,  B_REM: 52,   B_PRO: 18,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-P-0201', CSTD_PLAN: '2024-07-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 37,    A_ACT: 27,   A_REM: 10,    A_PRO: 73,  B_TOT: 19,   B_ACT: 14,  B_REM: 5,    B_PRO: 73,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 13,  AP_ACT: 13,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 8,   BP_ACT: 8,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 3,   CP_ACT: 3,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-U-7101', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 37,    A_ACT: 18,   A_REM: 19,    A_PRO: 48,  B_TOT: 16,   B_ACT: 6,   B_REM: 10,   B_PRO: 37,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 4,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-P-0202', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 211,   A_ACT: 46,   A_REM: 165,   A_PRO: 21,  B_TOT: 72,   B_ACT: 9,   B_REM: 63,   B_PRO: 12,  C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 4,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 4,   BP_ACT: 3,   BP_REM: 1,   BP_PRO: 75,  CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-C-5401', CSTD_PLAN: '2024-07-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 64,    A_ACT: 19,   A_REM: 45,    A_PRO: 29,  B_TOT: 18,   B_ACT: 14,  B_REM: 4,    B_PRO: 77,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 3,   CP_ACT: 3,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-P-0301', CSTD_PLAN: '2024-07-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 223,   A_ACT: 76,   A_REM: 147,   A_PRO: 34,  B_TOT: 105,  B_ACT: 26,  B_REM: 79,   B_PRO: 24,  C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 4,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-P-0401', CSTD_PLAN: '2024-08-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 645,   A_ACT: 142,  A_REM: 503,   A_PRO: 22,  B_TOT: 440,  B_ACT: 109, B_REM: 331,  B_PRO: 24,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 46,  AP_ACT: 34,  AP_REM: 12,  AP_PRO: 73,  BP_TOT: 21,  BP_ACT: 7,   BP_REM: 14,  BP_PRO: 33,  CP_TOT: 12,  CP_ACT: 11,  CP_REM: 1,  CP_PRO: 91, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-P-0102', CSTD_PLAN: '2024-08-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 643,   A_ACT: 274,  A_REM: 369,   A_PRO: 42,  B_TOT: 236,  B_ACT: 68,  B_REM: 168,  B_PRO: 28,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 48,  AP_ACT: 46,  AP_REM: 2,   AP_PRO: 95,  BP_TOT: 28,  BP_ACT: 27,  BP_REM: 1,   BP_PRO: 96,  CP_TOT: 36,  CP_ACT: 35,  CP_REM: 1,  CP_PRO: 97, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-P-0103', CSTD_PLAN: '2024-08-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 395,   A_ACT: 131,  A_REM: 264,   A_PRO: 33,  B_TOT: 127,  B_ACT: 30,  B_REM: 97,   B_PRO: 23,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'DS',          SUBSYS_NO: '21300-P-0101', CSTD_PLAN: '2024-08-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 559,   A_ACT: 208,  A_REM: 351,   A_PRO: 37,  B_TOT: 375,  B_ACT: 154, B_REM: 221,  B_PRO: 41,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 181, AP_ACT: 94,  AP_REM: 87,  AP_PRO: 51,  BP_TOT: 67,  BP_ACT: 42,  BP_REM: 25,  BP_PRO: 62,  CP_TOT: 99,  CP_ACT: 56,  CP_REM: 43, CP_PRO: 56, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-1102', CSTD_PLAN: undefined,    CSTD_WALKDOWN: '2023-11-24', CSTD_ACTUAL: undefined,    A_TOT: 8,     A_ACT: 7,    A_REM: 1,     A_PRO: 87,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 7,   AP_ACT: 2,   AP_REM: 5,   AP_PRO: 28,  BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 7,   CP_ACT: 6,   CP_REM: 1,  CP_PRO: 85, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-C-0101', CSTD_PLAN: '2023-10-07', CSTD_WALKDOWN: '2023-09-15', CSTD_ACTUAL: undefined,    A_TOT: 112,   A_ACT: 111,  A_REM: 1,     A_PRO: 99,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 4,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 29,  BP_ACT: 29,  BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-1001', CSTD_PLAN: '2023-10-07', CSTD_WALKDOWN: '2023-09-08', CSTD_ACTUAL: '2023-10-08', A_TOT: 3,     A_ACT: 3,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 10,  AP_ACT: 10,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 7,   BP_ACT: 7,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 5,   CP_ACT: 2,   CP_REM: 3,  CP_PRO: 40, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-I-0101', CSTD_PLAN: '2023-10-07', CSTD_WALKDOWN: '2023-09-07', CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 18,   A_REM: 0,     A_PRO: 100, B_TOT: 28,   B_ACT: 10,  B_REM: 18,   B_PRO: 35,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 14,  BP_ACT: 4,   BP_REM: 10,  BP_PRO: 28,  CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-1101', CSTD_PLAN: '2023-10-07', CSTD_WALKDOWN: '2023-09-07', CSTD_ACTUAL: '2023-10-08', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 5,    B_ACT: 5,   B_REM: 0,    B_PRO: 100, C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 2,   AP_REM: 2,   AP_PRO: 50,  BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 6,   CP_ACT: 0,   CP_REM: 6,  CP_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-C-0501', CSTD_PLAN: '2023-10-07', CSTD_WALKDOWN: '2023-09-08', CSTD_ACTUAL: '2023-10-07', A_TOT: 1,     A_ACT: 0,    A_REM: 1,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 7,   AP_ACT: 3,   AP_REM: 4,   AP_PRO: 42,  BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 11,  CP_ACT: 7,   CP_REM: 4,  CP_PRO: 63, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-I-0102', CSTD_PLAN: '2023-10-08', CSTD_WALKDOWN: '2023-10-05', CSTD_ACTUAL: '2023-10-08', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 4,   CP_ACT: 0,   CP_REM: 4,  CP_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0101', CSTD_PLAN: '2023-10-10', CSTD_WALKDOWN: '2023-09-11', CSTD_ACTUAL: '2023-10-10', A_TOT: 60,    A_ACT: 60,   A_REM: 0,     A_PRO: 100, B_TOT: 20,   B_ACT: 20,  B_REM: 0,    B_PRO: 100, C_TOT: 6,  C_ACT: 0, C_REM: 6,  C_PRO: 0, AP_TOT: 83,  AP_ACT: 83,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 28,  BP_ACT: 28,  BP_REM: 0,   BP_PRO: 100, CP_TOT: 71,  CP_ACT: 69,  CP_REM: 2,  CP_PRO: 97, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0701', CSTD_PLAN: '2023-10-10', CSTD_WALKDOWN: '2023-09-04', CSTD_ACTUAL: '2023-10-08', A_TOT: 6,     A_ACT: 6,    A_REM: 0,     A_PRO: 100, B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 2,   AP_REM: 2,   AP_PRO: 50,  BP_TOT: 6,   BP_ACT: 6,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 5,   CP_ACT: 4,   CP_REM: 1,  CP_PRO: 80, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0501', CSTD_PLAN: '2023-11-27', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-27', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 5,   B_REM: 3,    B_PRO: 62,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 4,   BP_ACT: 4,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0502', CSTD_PLAN: '2023-11-27', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-27', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 5,   B_REM: 3,    B_PRO: 62,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 3,   BP_ACT: 3,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 3,   CP_ACT: 0,   CP_REM: 3,  CP_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0503', CSTD_PLAN: '2023-11-27', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-27', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 5,   B_REM: 3,    B_PRO: 62,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 5,   BP_ACT: 5,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0102', CSTD_PLAN: '2023-11-28', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-28', A_TOT: 60,    A_ACT: 60,   A_REM: 0,     A_PRO: 100, B_TOT: 9,    B_ACT: 9,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 14,  AP_ACT: 14,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0301', CSTD_PLAN: '2023-11-28', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-28', A_TOT: 74,    A_ACT: 74,   A_REM: 0,     A_PRO: 100, B_TOT: 16,   B_ACT: 15,  B_REM: 1,    B_PRO: 93,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 31,  AP_ACT: 31,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 7,   CP_ACT: 2,   CP_REM: 5,  CP_PRO: 28, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0103', CSTD_PLAN: '2023-11-28', CSTD_WALKDOWN: '2023-11-15', CSTD_ACTUAL: '2023-11-28', A_TOT: 60,    A_ACT: 60,   A_REM: 0,     A_PRO: 100, B_TOT: 9,    B_ACT: 9,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 14,  AP_ACT: 14,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-C-0701', CSTD_PLAN: '2023-11-30', CSTD_WALKDOWN: '2023-09-08', CSTD_ACTUAL: '2023-11-30', A_TOT: 2,     A_ACT: 2,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 6,   AP_ACT: 6,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 15,  BP_ACT: 11,  BP_REM: 4,   BP_PRO: 73,  CP_TOT: 14,  CP_ACT: 3,   CP_REM: 11, CP_PRO: 21, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-H-0101', CSTD_PLAN: '2023-11-30', CSTD_WALKDOWN: '2023-09-06', CSTD_ACTUAL: '2023-11-30', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 4,   AP_REM: 1,   AP_PRO: 80,  BP_TOT: 4,   BP_ACT: 4,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-C-0801', CSTD_PLAN: '2024-03-12', CSTD_WALKDOWN: '2023-09-25', CSTD_ACTUAL: undefined,    A_TOT: 99,    A_ACT: 99,   A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 101, AP_ACT: 100, AP_REM: 1,   AP_PRO: 99,  BP_TOT: 71,  BP_ACT: 63,  BP_REM: 8,   BP_PRO: 88,  CP_TOT: 200, CP_ACT: 193, CP_REM: 7,  CP_PRO: 96, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-I-0201', CSTD_PLAN: '2024-03-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, B_TOT: 7,    B_ACT: 5,   B_REM: 2,    B_PRO: 71,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-C-0401', CSTD_PLAN: '2024-03-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 24,    A_ACT: 8,    A_REM: 16,    A_PRO: 33,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 39,  BP_ACT: 33,  BP_REM: 6,   BP_PRO: 84,  CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-I-0202', CSTD_PLAN: '2024-03-21', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, B_TOT: 1,    B_ACT: 1,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-T-0101', CSTD_PLAN: '2024-03-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 10,   A_REM: 2,     A_PRO: 83,  B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-C-5401', CSTD_PLAN: '2024-03-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 23,    A_ACT: 20,   A_REM: 3,     A_PRO: 87,  B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-1201', CSTD_PLAN: '2024-03-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 4,    B_ACT: 4,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 0,   AP_REM: 5,   AP_PRO: 0,   BP_TOT: 5,   BP_ACT: 0,   BP_REM: 5,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0801', CSTD_PLAN: '2024-03-26', CSTD_WALKDOWN: '2023-08-30', CSTD_ACTUAL: undefined,    A_TOT: 24,    A_ACT: 24,   A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 2,   AP_REM: 2,   AP_PRO: 50,  BP_TOT: 12,  BP_ACT: 6,   BP_REM: 6,   BP_PRO: 50,  CP_TOT: 8,   CP_ACT: 1,   CP_REM: 7,  CP_PRO: 12, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0402', CSTD_PLAN: '2024-03-28', CSTD_WALKDOWN: '2024-01-24', CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 7,    B_ACT: 7,   B_REM: 0,    B_PRO: 100, C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0602', CSTD_PLAN: '2024-03-28', CSTD_WALKDOWN: '2024-01-24', CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 28,   A_REM: 0,     A_PRO: 100, B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 2,   AP_REM: 1,   AP_PRO: 66,  BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0603', CSTD_PLAN: '2024-03-28', CSTD_WALKDOWN: '2024-01-24', CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 28,   A_REM: 0,     A_PRO: 100, B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 1,   AP_REM: 2,   AP_PRO: 33,  BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0601', CSTD_PLAN: '2024-03-28', CSTD_WALKDOWN: '2024-01-24', CSTD_ACTUAL: undefined,    A_TOT: 42,    A_ACT: 42,   A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0901', CSTD_PLAN: '2024-03-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   B_TOT: 2,    B_ACT: 1,   B_REM: 1,    B_PRO: 50,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSV',     SUBSYS_NO: '21601-E-0401', CSTD_PLAN: '2024-03-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 61,    A_ACT: 0,    A_REM: 61,    A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0602', CSTD_PLAN: undefined,    CSTD_WALKDOWN: '2023-12-29', CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 28,   A_REM: 0,     A_PRO: 100, B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 8,   AP_ACT: 0,   AP_REM: 8,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-1102', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 0,    A_REM: 6,     A_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-I-0102', CSTD_PLAN: '2023-10-31', CSTD_WALKDOWN: '2023-10-05', CSTD_ACTUAL: '2023-10-31', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 8,   CP_ACT: 0,   CP_REM: 8,  CP_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0102', CSTD_PLAN: '2023-11-01', CSTD_WALKDOWN: '2023-10-25', CSTD_ACTUAL: '2023-11-01', A_TOT: 71,    A_ACT: 71,   A_REM: 0,     A_PRO: 100, B_TOT: 10,   B_ACT: 10,  B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 6,   AP_ACT: 5,   AP_REM: 1,   AP_PRO: 83,  BP_TOT: 9,   BP_ACT: 9,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-C-0501', CSTD_PLAN: '2023-11-02', CSTD_WALKDOWN: '2023-10-20', CSTD_ACTUAL: '2023-11-07', A_TOT: 1,     A_ACT: 1,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 8,   AP_ACT: 8,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 6,   BP_ACT: 6,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 19,  CP_ACT: 3,   CP_REM: 16, CP_PRO: 15, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-1101', CSTD_PLAN: '2023-11-03', CSTD_WALKDOWN: '2023-10-23', CSTD_ACTUAL: '2023-11-03', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 4,    B_ACT: 4,   B_REM: 0,    B_PRO: 100, C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 1,   AP_REM: 3,   AP_PRO: 25,  BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0501', CSTD_PLAN: '2023-11-03', CSTD_WALKDOWN: '2023-10-23', CSTD_ACTUAL: '2023-11-03', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 5,   B_REM: 3,    B_PRO: 62,  C_TOT: 4,  C_ACT: 0, C_REM: 4,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 2,   AP_REM: 2,   AP_PRO: 50,  BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 4,   CP_ACT: 1,   CP_REM: 3,  CP_PRO: 25, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-1001', CSTD_PLAN: '2023-11-06', CSTD_WALKDOWN: '2023-10-23', CSTD_ACTUAL: '2023-11-06', A_TOT: 3,     A_ACT: 3,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 4,   AP_REM: 1,   AP_PRO: 80,  BP_TOT: 5,   BP_ACT: 5,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 3,   CP_ACT: 0,   CP_REM: 3,  CP_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-C-0701', CSTD_PLAN: '2023-11-06', CSTD_WALKDOWN: '2023-10-24', CSTD_ACTUAL: '2023-11-06', A_TOT: 2,     A_ACT: 2,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 4,   AP_REM: 1,   AP_PRO: 80,  BP_TOT: 5,   BP_ACT: 5,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 10,  CP_ACT: 4,   CP_REM: 6,  CP_PRO: 40, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0301', CSTD_PLAN: '2023-11-06', CSTD_WALKDOWN: '2023-10-23', CSTD_ACTUAL: '2023-11-06', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 7,   B_REM: 1,    B_PRO: 87,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 9,   AP_ACT: 7,   AP_REM: 2,   AP_PRO: 77,  BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 3,   CP_ACT: 0,   CP_REM: 3,  CP_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0701', CSTD_PLAN: '2023-11-06', CSTD_WALKDOWN: '2023-09-20', CSTD_ACTUAL: '2023-11-06', A_TOT: 2,     A_ACT: 2,    A_REM: 0,     A_PRO: 100, B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 3,   AP_REM: 2,   AP_PRO: 60,  BP_TOT: 6,   BP_ACT: 6,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 6,   CP_ACT: 0,   CP_REM: 6,  CP_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-H-0101', CSTD_PLAN: '2023-11-06', CSTD_WALKDOWN: '2023-10-24', CSTD_ACTUAL: '2023-11-30', A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 3,   AP_REM: 1,   AP_PRO: 75,  BP_TOT: 6,   BP_ACT: 6,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 8,   CP_ACT: 1,   CP_REM: 7,  CP_PRO: 12, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0502', CSTD_PLAN: '2023-11-06', CSTD_WALKDOWN: '2023-10-23', CSTD_ACTUAL: '2023-11-06', A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 5,   B_REM: 3,    B_PRO: 62,  C_TOT: 4,  C_ACT: 0, C_REM: 4,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 4,   AP_REM: 1,   AP_PRO: 80,  BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 3,   CP_ACT: 1,   CP_REM: 2,  CP_PRO: 33, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0101', CSTD_PLAN: '2023-11-07', CSTD_WALKDOWN: '2023-10-25', CSTD_ACTUAL: '2023-11-07', A_TOT: 71,    A_ACT: 71,   A_REM: 0,     A_PRO: 100, B_TOT: 10,   B_ACT: 10,  B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 12,  AP_ACT: 11,  AP_REM: 1,   AP_PRO: 91,  BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-C-0101', CSTD_PLAN: '2023-11-20', CSTD_WALKDOWN: '2023-10-05', CSTD_ACTUAL: '2023-11-20', A_TOT: 55,    A_ACT: 55,   A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 4,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 65,  BP_ACT: 65,  BP_REM: 0,   BP_PRO: 100, CP_TOT: 25,  CP_ACT: 25,  CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-C-0801', CSTD_PLAN: '2023-11-20', CSTD_WALKDOWN: '2023-10-13', CSTD_ACTUAL: '2023-11-20', A_TOT: 73,    A_ACT: 73,   A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 33,  AP_ACT: 33,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 56,  BP_ACT: 55,  BP_REM: 1,   BP_PRO: 98,  CP_TOT: 53,  CP_ACT: 51,  CP_REM: 2,  CP_PRO: 96, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-C-0401', CSTD_PLAN: '2024-03-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 7,    A_REM: 3,     A_PRO: 70,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,   BP_TOT: 13,  BP_ACT: 13,  BP_REM: 0,   BP_PRO: 100, CP_TOT: 4,   CP_ACT: 0,   CP_REM: 4,  CP_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-C-5401', CSTD_PLAN: '2024-03-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 10,   A_REM: 8,     A_PRO: 55,  B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 3,   CP_ACT: 1,   CP_REM: 2,  CP_PRO: 33, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-1201', CSTD_PLAN: '2024-03-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-I-0201', CSTD_PLAN: '2024-03-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 2,    A_REM: 2,     A_PRO: 50,  B_TOT: 6,    B_ACT: 4,   B_REM: 2,    B_PRO: 66,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0901', CSTD_PLAN: '2024-03-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0801', CSTD_PLAN: '2024-03-25', CSTD_WALKDOWN: '2023-09-20', CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 14,  AP_ACT: 3,   AP_REM: 11,  AP_PRO: 21,  BP_TOT: 11,  BP_ACT: 0,   BP_REM: 11,  BP_PRO: 0,   CP_TOT: 14,  CP_ACT: 0,   CP_REM: 14, CP_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-I-0202', CSTD_PLAN: '2024-03-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 5,     A_ACT: 0,    A_REM: 5,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0601', CSTD_PLAN: '2024-03-27', CSTD_WALKDOWN: '2023-12-29', CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 28,   A_REM: 0,     A_PRO: 100, B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 8,   AP_ACT: 0,   AP_REM: 8,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 8,   CP_ACT: 8,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-T-0101', CSTD_PLAN: '2024-03-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 1,    A_REM: 11,    A_PRO: 8,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0401', CSTD_PLAN: '2024-04-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 61,    A_ACT: 32,   A_REM: 29,    A_PRO: 52,  B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-I-0101', CSTD_PLAN: '2024-04-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   B_TOT: 25,   B_ACT: 0,   B_REM: 25,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0402', CSTD_PLAN: '2024-04-09', CSTD_WALKDOWN: '2024-01-15', CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 7,    B_ACT: 7,   B_REM: 0,    B_PRO: 100, C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 0,   AP_REM: 4,   AP_PRO: 0,   BP_TOT: 3,   BP_ACT: 1,   BP_REM: 2,   BP_PRO: 33,  CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'ELS SSW',     SUBSYS_NO: '21605-E-0603', CSTD_PLAN: '2024-04-11', CSTD_WALKDOWN: '2024-01-15', CSTD_ACTUAL: undefined,    A_TOT: 42,    A_ACT: 42,   A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 0,   AP_REM: 1,   AP_PRO: 0,   BP_TOT: 5,   BP_ACT: 0,   BP_REM: 5,   BP_PRO: 0,   CP_TOT: 12,  CP_ACT: 0,   CP_REM: 12, CP_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-T-0101', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 156,   A_ACT: 0,    A_REM: 156,   A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0302', CSTD_PLAN: '2024-03-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 212,   A_ACT: 71,   A_REM: 141,   A_PRO: 33,  B_TOT: 14,   B_ACT: 0,   B_REM: 14,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0303', CSTD_PLAN: '2024-03-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 71,    A_ACT: 24,   A_REM: 47,    A_PRO: 33,  B_TOT: 8,    B_ACT: 0,   B_REM: 8,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0801', CSTD_PLAN: '2024-03-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 43,    A_ACT: 0,    A_REM: 43,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-E-1103', CSTD_PLAN: '2024-03-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-E-1102', CSTD_PLAN: '2024-03-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0603', CSTD_PLAN: '2024-03-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 3,     A_ACT: 0,    A_REM: 3,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0601', CSTD_PLAN: '2024-03-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 0,    A_REM: 9,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0602', CSTD_PLAN: '2024-03-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 14,    A_ACT: 0,    A_REM: 14,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-0301', CSTD_PLAN: '2024-03-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 121,   A_ACT: 28,   A_REM: 93,    A_PRO: 23,  B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-E-1101', CSTD_PLAN: '2024-03-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'GENERAL',     SUBSYS_NO: '20000-C-5401', CSTD_PLAN: '2024-07-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 159,   A_ACT: 62,   A_REM: 97,    A_PRO: 39,  B_TOT: 60,   B_ACT: 13,  B_REM: 47,   B_PRO: 21,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-C-0101', CSTD_PLAN: '2024-03-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 88,    A_ACT: 11,   A_REM: 77,    A_PRO: 12,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-C-0501', CSTD_PLAN: '2024-03-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 22,    A_ACT: 8,    A_REM: 14,    A_PRO: 36,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 38,  AP_ACT: 20,  AP_REM: 18,  AP_PRO: 52,  BP_TOT: 12,  BP_ACT: 7,   BP_REM: 5,   BP_PRO: 58,  CP_TOT: 29,  CP_ACT: 18,  CP_REM: 11, CP_PRO: 62, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-C-0801', CSTD_PLAN: '2024-03-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 284,   A_ACT: 126,  A_REM: 158,   A_PRO: 44,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 6,   BP_ACT: 6,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 3,   CP_ACT: 3,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-1001', CSTD_PLAN: '2024-03-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 45,    A_ACT: 22,   A_REM: 23,    A_PRO: 48,  B_TOT: 26,   B_ACT: 10,  B_REM: 16,   B_PRO: 38,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-E-1001', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 0,    A_REM: 6,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-C-0201', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 17,    A_ACT: 10,   A_REM: 7,     A_PRO: 58,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-C-0401', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 24,    A_ACT: 0,    A_REM: 24,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-I-0201', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 15,    A_ACT: 0,    A_REM: 15,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-I-0301', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 90,    A_ACT: 0,    A_REM: 90,    A_PRO: 0,   B_TOT: 20,   B_ACT: 0,   B_REM: 20,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-T-0101', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 13,    A_ACT: 0,    A_REM: 13,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-I-0202', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 17,    A_ACT: 0,    A_REM: 17,    A_PRO: 0,   B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-E-1301', CSTD_PLAN: '2024-05-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 132,   A_ACT: 12,   A_REM: 120,   A_PRO: 9,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-E-1101', CSTD_PLAN: '2024-05-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 65,    A_ACT: 23,   A_REM: 42,    A_PRO: 35,  B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 5,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 53,  BP_ACT: 53,  BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-4601', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 5,     A_ACT: 2,    A_REM: 3,     A_PRO: 40,  B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 2,   BP_ACT: 1,   BP_REM: 1,   BP_PRO: 50,  CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-2103', CSTD_PLAN: '2024-07-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 140,   A_ACT: 52,   A_REM: 88,    A_PRO: 37,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-7502', CSTD_PLAN: '2024-07-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 34,    A_ACT: 6,    A_REM: 28,    A_PRO: 17,  B_TOT: 24,   B_ACT: 6,   B_REM: 18,   B_PRO: 25,  C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-4101', CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 15,    A_ACT: 0,    A_REM: 15,    A_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-7501', CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 188,   A_ACT: 56,   A_REM: 132,   A_PRO: 29,  B_TOT: 136,  B_ACT: 48,  B_REM: 88,   B_PRO: 35,  C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 21,  AP_ACT: 17,  AP_REM: 4,   AP_PRO: 81,  BP_TOT: 9,   BP_ACT: 5,   BP_REM: 4,   BP_PRO: 55,  CP_TOT: 3,   CP_ACT: 1,   CP_REM: 2,  CP_PRO: 33, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-2102', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 11,   A_REM: 1,     A_PRO: 91,  B_TOT: 9,    B_ACT: 8,   B_REM: 1,    B_PRO: 88,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 3,   CP_ACT: 1,   CP_REM: 2,  CP_PRO: 33, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-4001', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 14,    A_ACT: 14,   A_REM: 0,     A_PRO: 100, B_TOT: 14,   B_ACT: 14,  B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 4,   BP_ACT: 4,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 9,   CP_ACT: 8,   CP_REM: 1,  CP_PRO: 88, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-7101', CSTD_PLAN: '2024-07-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 69,    A_ACT: 28,   A_REM: 41,    A_PRO: 40,  B_TOT: 9,    B_ACT: 2,   B_REM: 7,    B_PRO: 22,  C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 3,   CP_ACT: 3,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-I-0101', CSTD_PLAN: '2024-07-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 33,    A_ACT: 0,    A_REM: 33,    A_PRO: 0,   B_TOT: 11,   B_ACT: 0,   B_REM: 11,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-C-5401', CSTD_PLAN: '2024-07-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 36,    A_ACT: 4,    A_REM: 32,    A_PRO: 11,  B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-7601', CSTD_PLAN: '2024-07-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 161,   A_ACT: 86,   A_REM: 75,    A_PRO: 53,  B_TOT: 69,   B_ACT: 46,  B_REM: 23,   B_PRO: 66,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 13,  AP_ACT: 11,  AP_REM: 2,   AP_PRO: 84,  BP_TOT: 14,  BP_ACT: 10,  BP_REM: 4,   BP_PRO: 71,  CP_TOT: 7,   CP_ACT: 5,   CP_REM: 2,  CP_PRO: 71, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-P-0201', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 614,   A_ACT: 333,  A_REM: 281,   A_PRO: 54,  B_TOT: 301,  B_ACT: 173, B_REM: 128,  B_PRO: 57,  C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 99,  AP_ACT: 81,  AP_REM: 18,  AP_PRO: 81,  BP_TOT: 73,  BP_ACT: 51,  BP_REM: 22,  BP_PRO: 69,  CP_TOT: 32,  CP_ACT: 17,  CP_REM: 15, CP_PRO: 53, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-7001', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 310,   A_ACT: 72,   A_REM: 238,   A_PRO: 23,  B_TOT: 60,   B_ACT: 27,  B_REM: 33,   B_PRO: 45,  C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 11,  AP_ACT: 8,   AP_REM: 3,   AP_PRO: 72,  BP_TOT: 12,  BP_ACT: 9,   BP_REM: 3,   BP_PRO: 75,  CP_TOT: 5,   CP_ACT: 3,   CP_REM: 2,  CP_PRO: 60, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-P-0101', CSTD_PLAN: '2024-10-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2846,  A_ACT: 735,  A_REM: 2111,  A_PRO: 25,  B_TOT: 876,  B_ACT: 168, B_REM: 708,  B_PRO: 19,  C_TOT: 42, C_ACT: 0, C_REM: 42, C_PRO: 0, AP_TOT: 48,  AP_ACT: 38,  AP_REM: 10,  AP_PRO: 79,  BP_TOT: 128, BP_ACT: 101, BP_REM: 27,  BP_PRO: 78,  CP_TOT: 37,  CP_ACT: 35,  CP_REM: 2,  CP_PRO: 94, },
      { UNIT: 'HOS',         SUBSYS_NO: '20800-U-6102', CSTD_PLAN: '2024-10-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-6102', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 8,     A_ACT: 0,    A_REM: 8,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-C-0101', CSTD_PLAN: '2024-03-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 24,    A_ACT: 18,   A_REM: 6,     A_PRO: 75,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-C-0501', CSTD_PLAN: '2024-03-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 13,    A_ACT: 6,    A_REM: 7,     A_PRO: 46,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 8,   AP_ACT: 7,   AP_REM: 1,   AP_PRO: 87,  BP_TOT: 34,  BP_ACT: 4,   BP_REM: 30,  BP_PRO: 11,  CP_TOT: 19,  CP_ACT: 13,  CP_REM: 6,  CP_PRO: 68, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-1101', CSTD_PLAN: '2024-03-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 17,    A_ACT: 17,   A_REM: 0,     A_PRO: 100, B_TOT: 18,   B_ACT: 18,  B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 6,   AP_ACT: 6,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 5,   CP_ACT: 5,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-C-0801', CSTD_PLAN: '2024-03-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 95,    A_ACT: 56,   A_REM: 39,    A_PRO: 58,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 26,  AP_ACT: 23,  AP_REM: 3,   AP_PRO: 88,  BP_TOT: 5,   BP_ACT: 5,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-1001', CSTD_PLAN: '2024-03-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 14,    A_ACT: 14,   A_REM: 0,     A_PRO: 100, B_TOT: 10,   B_ACT: 10,  B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 7,   AP_ACT: 7,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 1,   BP_REM: 1,   BP_PRO: 50,  CP_TOT: 3,   CP_ACT: 2,   CP_REM: 1,  CP_PRO: 66, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-E-1301', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 228,   A_ACT: 95,   A_REM: 133,   A_PRO: 41,  B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0,   C_TOT: 7,  C_ACT: 0, C_REM: 7,  C_PRO: 0, AP_TOT: 22,  AP_ACT: 12,  AP_REM: 10,  AP_PRO: 54,  BP_TOT: 4,   BP_ACT: 3,   BP_REM: 1,   BP_PRO: 75,  CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-E-1001', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 7,     A_ACT: 1,    A_REM: 6,     A_PRO: 14,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 4,   BP_ACT: 3,   BP_REM: 1,   BP_PRO: 75,  CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-C-0201', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 13,    A_ACT: 12,   A_REM: 1,     A_PRO: 92,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 6,   CP_ACT: 0,   CP_REM: 6,  CP_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-I-0301', CSTD_PLAN: '2024-05-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 0,    A_REM: 9,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-E-1101', CSTD_PLAN: '2024-05-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 8,     A_ACT: 7,    A_REM: 1,     A_PRO: 87,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 13,  AP_ACT: 9,   AP_REM: 4,   AP_PRO: 69,  BP_TOT: 10,  BP_ACT: 9,   BP_REM: 1,   BP_PRO: 90,  CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-C-0401', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 22,    A_ACT: 0,    A_REM: 22,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-4301', CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 12,   A_REM: 6,     A_PRO: 66,  B_TOT: 16,   B_ACT: 12,  B_REM: 4,    B_PRO: 75,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 14,  AP_ACT: 14,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-1201', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 23,    A_ACT: 17,   A_REM: 6,     A_PRO: 73,  B_TOT: 22,   B_ACT: 18,  B_REM: 4,    B_PRO: 81,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 4,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 3,   BP_ACT: 3,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 5,   CP_ACT: 5,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-2101', CSTD_PLAN: '2024-05-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 25,    A_ACT: 17,   A_REM: 8,     A_PRO: 68,  B_TOT: 15,   B_ACT: 10,  B_REM: 5,    B_PRO: 66,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 12,  AP_ACT: 5,   AP_REM: 7,   AP_PRO: 41,  BP_TOT: 17,  BP_ACT: 15,  BP_REM: 2,   BP_PRO: 88,  CP_TOT: 2,   CP_ACT: 1,   CP_REM: 1,  CP_PRO: 50, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-3101', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 9,    A_REM: 3,     A_PRO: 75,  B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 9,   BP_ACT: 6,   BP_REM: 3,   BP_PRO: 66,  CP_TOT: 4,   CP_ACT: 3,   CP_REM: 1,  CP_PRO: 75, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-6004', CSTD_PLAN: '2024-05-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 11,   A_REM: 7,     A_PRO: 61,  B_TOT: 4,    B_ACT: 2,   B_REM: 2,    B_PRO: 50,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 17,  BP_ACT: 0,   BP_REM: 17,  BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-2201', CSTD_PLAN: '2024-05-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 71,    A_ACT: 66,   A_REM: 5,     A_PRO: 93,  B_TOT: 20,   B_ACT: 16,  B_REM: 4,    B_PRO: 80,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 12,  AP_ACT: 8,   AP_REM: 4,   AP_PRO: 66,  BP_TOT: 25,  BP_ACT: 16,  BP_REM: 9,   BP_PRO: 64,  CP_TOT: 5,   CP_ACT: 1,   CP_REM: 4,  CP_PRO: 20, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-2102', CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 9,    B_ACT: 8,   B_REM: 1,    B_PRO: 88,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-4101', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 5,     A_ACT: 0,    A_REM: 5,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0104', CSTD_PLAN: '2024-06-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 200,   A_ACT: 170,  A_REM: 30,    A_PRO: 85,  B_TOT: 48,   B_ACT: 18,  B_REM: 30,   B_PRO: 37,  C_TOT: 9,  C_ACT: 0, C_REM: 9,  C_PRO: 0, AP_TOT: 25,  AP_ACT: 18,  AP_REM: 7,   AP_PRO: 72,  BP_TOT: 17,  BP_ACT: 7,   BP_REM: 10,  BP_PRO: 41,  CP_TOT: 11,  CP_ACT: 9,   CP_REM: 2,  CP_PRO: 81, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-3201', CSTD_PLAN: '2024-06-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 111,   A_ACT: 79,   A_REM: 32,    A_PRO: 71,  B_TOT: 50,   B_ACT: 31,  B_REM: 19,   B_PRO: 62,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 9,   AP_ACT: 7,   AP_REM: 2,   AP_PRO: 77,  BP_TOT: 10,  BP_ACT: 1,   BP_REM: 9,   BP_PRO: 10,  CP_TOT: 10,  CP_ACT: 4,   CP_REM: 6,  CP_PRO: 40, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-4001', CSTD_PLAN: '2024-06-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 31,    A_ACT: 23,   A_REM: 8,     A_PRO: 74,  B_TOT: 26,   B_ACT: 20,  B_REM: 6,    B_PRO: 76,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 7,   AP_ACT: 6,   AP_REM: 1,   AP_PRO: 85,  BP_TOT: 5,   BP_ACT: 3,   BP_REM: 2,   BP_PRO: 60,  CP_TOT: 5,   CP_ACT: 3,   CP_REM: 2,  CP_PRO: 60, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-2003', CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 8,     A_ACT: 4,    A_REM: 4,     A_PRO: 50,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-U-3401', CSTD_PLAN: '2024-07-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 30,    A_ACT: 22,   A_REM: 8,     A_PRO: 73,  B_TOT: 14,   B_ACT: 8,   B_REM: 6,    B_PRO: 57,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 4,   BP_ACT: 2,   BP_REM: 2,   BP_PRO: 50,  CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0102', CSTD_PLAN: '2024-07-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 52,    A_ACT: 47,   A_REM: 5,     A_PRO: 90,  B_TOT: 16,   B_ACT: 10,  B_REM: 6,    B_PRO: 62,  C_TOT: 5,  C_ACT: 0, C_REM: 5,  C_PRO: 0, AP_TOT: 6,   AP_ACT: 5,   AP_REM: 1,   AP_PRO: 83,  BP_TOT: 5,   BP_ACT: 4,   BP_REM: 1,   BP_PRO: 80,  CP_TOT: 10,  CP_ACT: 5,   CP_REM: 5,  CP_PRO: 50, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0101', CSTD_PLAN: '2024-07-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 48,    A_ACT: 40,   A_REM: 8,     A_PRO: 83,  B_TOT: 17,   B_ACT: 10,  B_REM: 7,    B_PRO: 58,  C_TOT: 5,  C_ACT: 0, C_REM: 5,  C_PRO: 0, AP_TOT: 13,  AP_ACT: 10,  AP_REM: 3,   AP_PRO: 76,  BP_TOT: 8,   BP_ACT: 4,   BP_REM: 4,   BP_PRO: 50,  CP_TOT: 10,  CP_ACT: 5,   CP_REM: 5,  CP_PRO: 50, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0103', CSTD_PLAN: '2024-07-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 277,   A_ACT: 113,  A_REM: 164,   A_PRO: 40,  B_TOT: 87,   B_ACT: 19,  B_REM: 68,   B_PRO: 21,  C_TOT: 16, C_ACT: 0, C_REM: 16, C_PRO: 0, AP_TOT: 7,   AP_ACT: 4,   AP_REM: 3,   AP_PRO: 57,  BP_TOT: 9,   BP_ACT: 5,   BP_REM: 4,   BP_PRO: 55,  CP_TOT: 15,  CP_ACT: 11,  CP_REM: 4,  CP_PRO: 73, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0105', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 25,    A_ACT: 22,   A_REM: 3,     A_PRO: 88,  B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60,  C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 1,   AP_REM: 1,   AP_PRO: 50,  BP_TOT: 4,   BP_ACT: 4,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 6,   CP_ACT: 4,   CP_REM: 2,  CP_PRO: 66, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0201', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 504,   A_ACT: 269,  A_REM: 235,   A_PRO: 53,  B_TOT: 199,  B_ACT: 76,  B_REM: 123,  B_PRO: 38,  C_TOT: 7,  C_ACT: 0, C_REM: 7,  C_PRO: 0, AP_TOT: 35,  AP_ACT: 32,  AP_REM: 3,   AP_PRO: 91,  BP_TOT: 22,  BP_ACT: 15,  BP_REM: 7,   BP_PRO: 68,  CP_TOT: 25,  CP_ACT: 21,  CP_REM: 4,  CP_PRO: 84, },
      { UNIT: 'HSFOC',       SUBSYS_NO: '21403-P-0401', CSTD_PLAN: '2024-08-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 26,    A_ACT: 14,   A_REM: 12,    A_PRO: 53,  B_TOT: 20,   B_ACT: 12,  B_REM: 8,    B_PRO: 60,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 3,   BP_ACT: 0,   BP_REM: 3,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0101', CSTD_PLAN: '2023-10-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 22,   B_ACT: 5,   B_REM: 17,   B_PRO: 22,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-C-0101', CSTD_PLAN: '2023-11-20', CSTD_WALKDOWN: '2023-10-20', CSTD_ACTUAL: '2023-11-20', A_TOT: 5,     A_ACT: 5,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 3,   BP_ACT: 3,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-C-0701', CSTD_PLAN: '2024-04-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 1,    A_REM: 1,     A_PRO: 50,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-C-0801', CSTD_PLAN: '2024-04-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-E-0801', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 10,   A_REM: 0,     A_PRO: 100, B_TOT: 5,    B_ACT: 5,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0205', CSTD_PLAN: '2024-04-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 1,    B_ACT: 1,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0203', CSTD_PLAN: '2024-04-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-T-0101', CSTD_PLAN: '2024-04-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 12,   A_REM: 6,     A_PRO: 66,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 6,   CP_ACT: 0,   CP_REM: 6,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-H-0101', CSTD_PLAN: '2024-05-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   B_TOT: 6,    B_ACT: 3,   B_REM: 3,    B_PRO: 50,  C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 2,   BP_ACT: 0,   BP_REM: 2,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-E-1101', CSTD_PLAN: '2024-05-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0201', CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 14,   B_ACT: 12,  B_REM: 2,    B_PRO: 85,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 0,   AP_REM: 4,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0206', CSTD_PLAN: '2024-05-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0207', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0204', CSTD_PLAN: '2024-05-10', CSTD_WALKDOWN: '2023-10-18', CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 5,   BP_ACT: 3,   BP_REM: 2,   BP_PRO: 60,  CP_TOT: 4,   CP_ACT: 0,   CP_REM: 4,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 01', SUBSYS_NO: '21501-I-0202', CSTD_PLAN: '2024-05-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 12,   B_ACT: 8,   B_REM: 4,    B_PRO: 66,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 4,   BP_ACT: 0,   BP_REM: 4,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-C-0101', CSTD_PLAN: '2023-10-07', CSTD_WALKDOWN: '2023-09-15', CSTD_ACTUAL: '2023-10-07', A_TOT: 8,     A_ACT: 8,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-C-0701', CSTD_PLAN: '2024-03-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 1,    A_REM: 1,     A_PRO: 50,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-C-0801', CSTD_PLAN: '2024-03-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 49,    A_ACT: 2,    A_REM: 47,    A_PRO: 4,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0201', CSTD_PLAN: '2024-03-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 5,     A_ACT: 5,    A_REM: 0,     A_PRO: 100, B_TOT: 17,   B_ACT: 15,  B_REM: 2,    B_PRO: 88,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 17,  AP_ACT: 10,  AP_REM: 7,   AP_PRO: 58,  BP_TOT: 5,   BP_ACT: 5,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0203', CSTD_PLAN: '2024-04-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 0,   AP_REM: 1,   AP_PRO: 0,   BP_TOT: 7,   BP_ACT: 0,   BP_REM: 7,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-E-1101', CSTD_PLAN: '2024-04-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 2,    A_REM: 0,     A_PRO: 100, B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-E-0801', CSTD_PLAN: '2024-04-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 10,   A_REM: 0,     A_PRO: 100, B_TOT: 5,    B_ACT: 5,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,   BP_TOT: 2,   BP_ACT: 0,   BP_REM: 2,   BP_PRO: 0,   CP_TOT: 3,   CP_ACT: 0,   CP_REM: 3,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0205', CSTD_PLAN: '2024-04-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 1,    B_ACT: 1,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 3,   BP_ACT: 0,   BP_REM: 3,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0202', CSTD_PLAN: '2024-04-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 1,    A_REM: 1,     A_PRO: 50,  B_TOT: 18,   B_ACT: 15,  B_REM: 3,    B_PRO: 83,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 12,  BP_ACT: 0,   BP_REM: 12,  BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0206', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-H-0101', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   B_TOT: 6,    B_ACT: 3,   B_REM: 3,    B_PRO: 50,  C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-T-0101', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 18,   A_REM: 0,     A_PRO: 100, B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0207', CSTD_PLAN: '2024-04-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0101', CSTD_PLAN: '2024-04-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 15,   B_ACT: 8,   B_REM: 7,    B_PRO: 53,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'I&CS RIE 02', SUBSYS_NO: '21502-I-0204', CSTD_PLAN: '2024-04-26', CSTD_WALKDOWN: '2023-10-18', CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 2,    B_ACT: 2,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 4,   BP_ACT: 2,   BP_REM: 2,   BP_PRO: 50,  CP_TOT: 5,   CP_ACT: 0,   CP_REM: 5,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-C-0101', CSTD_PLAN: '2024-03-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 157,   A_ACT: 8,    A_REM: 149,   A_PRO: 5,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-U-1101', CSTD_PLAN: '2024-03-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, B_TOT: 4,    B_ACT: 4,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-C-0501', CSTD_PLAN: '2024-03-21', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 2,    A_REM: 8,     A_PRO: 20,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-C-0801', CSTD_PLAN: '2024-03-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 111,   A_ACT: 52,   A_REM: 59,    A_PRO: 46,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-E-1301', CSTD_PLAN: '2024-04-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 652,   A_ACT: 94,   A_REM: 558,   A_PRO: 14,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 4,   BP_ACT: 4,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-E-1401', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 27,    A_ACT: 1,    A_REM: 26,    A_PRO: 3,   B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0,   C_TOT: 8,  C_ACT: 0, C_REM: 8,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-I-0202', CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 2,    A_REM: 8,     A_PRO: 20,  B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-I-0301', CSTD_PLAN: '2024-05-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 27,    A_ACT: 0,    A_REM: 27,    A_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-T-0101', CSTD_PLAN: '2024-05-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 0,    A_REM: 28,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-C-0401', CSTD_PLAN: '2024-05-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 19,    A_ACT: 0,    A_REM: 19,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-E-1001', CSTD_PLAN: '2024-05-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 39,    A_ACT: 0,    A_REM: 39,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-U-4001', CSTD_PLAN: '2024-05-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 14,    A_ACT: 8,    A_REM: 6,     A_PRO: 57,  B_TOT: 12,   B_ACT: 8,   B_REM: 4,    B_PRO: 66,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 8,   AP_ACT: 8,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 3,   CP_ACT: 3,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-E-1101', CSTD_PLAN: '2024-05-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 38,    A_ACT: 3,    A_REM: 35,    A_PRO: 7,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-U-1202', CSTD_PLAN: '2024-05-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 7,     A_ACT: 4,    A_REM: 3,     A_PRO: 57,  B_TOT: 6,    B_ACT: 4,   B_REM: 2,    B_PRO: 66,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-U-2101', CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, B_TOT: 7,    B_ACT: 6,   B_REM: 1,    B_PRO: 85,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-U-4601', CSTD_PLAN: '2024-06-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21402-P-0103', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 470,   A_ACT: 45,   A_REM: 425,   A_PRO: 9,   B_TOT: 148,  B_ACT: 24,  B_REM: 124,  B_PRO: 16,  C_TOT: 6,  C_ACT: 0, C_REM: 6,  C_PRO: 0, AP_TOT: 19,  AP_ACT: 18,  AP_REM: 1,   AP_PRO: 94,  BP_TOT: 2,   BP_ACT: 1,   BP_REM: 1,   BP_PRO: 50,  CP_TOT: 6,   CP_ACT: 6,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21402-P-0401', CSTD_PLAN: '2024-07-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 209,   A_ACT: 36,   A_REM: 173,   A_PRO: 17,  B_TOT: 93,   B_ACT: 26,  B_REM: 67,   B_PRO: 28,  C_TOT: 7,  C_ACT: 0, C_REM: 7,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 4,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-C-5401', CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 3,    A_REM: 7,     A_PRO: 30,  B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21402-P-0201', CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 593,   A_ACT: 66,   A_REM: 527,   A_PRO: 11,  B_TOT: 233,  B_ACT: 28,  B_REM: 205,  B_PRO: 12,  C_TOT: 8,  C_ACT: 0, C_REM: 8,  C_PRO: 0, AP_TOT: 13,  AP_ACT: 13,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 7,   BP_ACT: 7,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 8,   CP_ACT: 6,   CP_REM: 2,  CP_PRO: 75, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21402-P-0101', CSTD_PLAN: '2024-07-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 469,   A_ACT: 16,   A_REM: 453,   A_PRO: 3,   B_TOT: 122,  B_ACT: 8,   B_REM: 114,  B_PRO: 6,   C_TOT: 4,  C_ACT: 0, C_REM: 4,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 1,   AP_REM: 1,   AP_PRO: 50,  BP_TOT: 7,   BP_ACT: 6,   BP_REM: 1,   BP_PRO: 85,  CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21402-P-0102', CSTD_PLAN: '2024-07-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 418,   A_ACT: 18,   A_REM: 400,   A_PRO: 4,   B_TOT: 115,  B_ACT: 8,   B_REM: 107,  B_PRO: 7,   C_TOT: 8,  C_ACT: 0, C_REM: 8,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 1,   AP_REM: 1,   AP_PRO: 50,  BP_TOT: 3,   BP_ACT: 2,   BP_REM: 1,   BP_PRO: 66,  CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-P-0201', CSTD_PLAN: '2024-08-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 625,   A_ACT: 130,  A_REM: 495,   A_PRO: 20,  B_TOT: 184,  B_ACT: 42,  B_REM: 142,  B_PRO: 22,  C_TOT: 16, C_ACT: 0, C_REM: 16, C_PRO: 0, AP_TOT: 10,  AP_ACT: 10,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 13,  BP_ACT: 12,  BP_REM: 1,   BP_PRO: 92,  CP_TOT: 2,   CP_ACT: 1,   CP_REM: 1,  CP_PRO: 50, },
      { UNIT: 'OM&S-TKN',    SUBSYS_NO: '21401-P-0101', CSTD_PLAN: '2024-08-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 456,   A_ACT: 91,   A_REM: 365,   A_PRO: 20,  B_TOT: 146,  B_ACT: 32,  B_REM: 114,  B_PRO: 21,  C_TOT: 10, C_ACT: 0, C_REM: 10, C_PRO: 0, AP_TOT: 9,   AP_ACT: 9,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 8,   BP_ACT: 8,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-E-1501', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-U-1001', CSTD_PLAN: '2024-03-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 18,   A_REM: 10,    A_PRO: 64,  B_TOT: 16,   B_ACT: 12,  B_REM: 4,    B_PRO: 75,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-U-1101', CSTD_PLAN: '2024-03-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 15,    A_ACT: 6,    A_REM: 9,     A_PRO: 40,  B_TOT: 12,   B_ACT: 6,   B_REM: 6,    B_PRO: 50,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-E-1301', CSTD_PLAN: '2024-04-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1098,  A_ACT: 309,  A_REM: 789,   A_PRO: 28,  B_TOT: 18,   B_ACT: 0,   B_REM: 18,   B_PRO: 0,   C_TOT: 12, C_ACT: 0, C_REM: 12, C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-C-5401', CSTD_PLAN: '2024-04-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 20,    A_ACT: 7,    A_REM: 13,    A_PRO: 35,  B_TOT: 6,    B_ACT: 4,   B_REM: 2,    B_PRO: 66,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 0,   AP_REM: 1,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-E-1401', CSTD_PLAN: '2024-04-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 55,    A_ACT: 1,    A_REM: 54,    A_PRO: 1,   B_TOT: 24,   B_ACT: 0,   B_REM: 24,   B_PRO: 0,   C_TOT: 19, C_ACT: 0, C_REM: 19, C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-C-0101', CSTD_PLAN: '2024-04-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 386,   A_ACT: 64,   A_REM: 322,   A_PRO: 16,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-I-0202', CSTD_PLAN: '2024-04-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 7,     A_ACT: 0,    A_REM: 7,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-I-0301', CSTD_PLAN: '2024-04-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 61,    A_ACT: 0,    A_REM: 61,    A_PRO: 0,   B_TOT: 15,   B_ACT: 0,   B_REM: 15,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-C-0501', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 26,    A_ACT: 12,   A_REM: 14,    A_PRO: 46,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-T-0101', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 41,    A_ACT: 0,    A_REM: 41,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-I-0201', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-C-0801', CSTD_PLAN: '2024-04-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 295,   A_ACT: 127,  A_REM: 168,   A_PRO: 43,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 5,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 3,   CP_ACT: 0,   CP_REM: 3,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-U-1202', CSTD_PLAN: '2024-05-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 6,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-C-0401', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 0,    A_REM: 6,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-E-1001', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 51,    A_ACT: 0,    A_REM: 51,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-U-2102', CSTD_PLAN: '2024-06-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, B_TOT: 7,    B_ACT: 6,   B_REM: 1,    B_PRO: 85,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-E-1101', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 34,    A_ACT: 18,   A_REM: 16,    A_PRO: 52,  B_TOT: 9,    B_ACT: 0,   B_REM: 9,    B_PRO: 0,   C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 0,   AP_REM: 1,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-U-4001', CSTD_PLAN: '2024-07-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 6,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-P-0302', CSTD_PLAN: '2024-07-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 20,    A_ACT: 6,    A_REM: 14,    A_PRO: 30,  B_TOT: 13,   B_ACT: 6,   B_REM: 7,    B_PRO: 46,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-P-0501', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 106,   A_ACT: 20,   A_REM: 86,    A_PRO: 18,  B_TOT: 45,   B_ACT: 10,  B_REM: 35,   B_PRO: 22,  C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-P-0104', CSTD_PLAN: '2024-07-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 133,   A_ACT: 19,   A_REM: 114,   A_PRO: 14,  B_TOT: 46,   B_ACT: 8,   B_REM: 38,   B_PRO: 17,  C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 1,   BP_REM: 1,   BP_PRO: 50,  CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-P-0301', CSTD_PLAN: '2024-07-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 195,   A_ACT: 29,   A_REM: 166,   A_PRO: 14,  B_TOT: 76,   B_ACT: 18,  B_REM: 58,   B_PRO: 23,  C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 5,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 4,   BP_ACT: 3,   BP_REM: 1,   BP_PRO: 75,  CP_TOT: 3,   CP_ACT: 3,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'OM&S-TKS',    SUBSYS_NO: '21402-U-4601', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 19,    A_ACT: 7,    A_REM: 12,    A_PRO: 36,  B_TOT: 15,   B_ACT: 0,   B_REM: 15,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-C-0101', CSTD_PLAN: '2024-03-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 26,    A_ACT: 0,    A_REM: 26,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-C-0501', CSTD_PLAN: '2024-04-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 4,    A_REM: 5,     A_PRO: 44,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 22,  AP_ACT: 0,   AP_REM: 22,  AP_PRO: 0,   BP_TOT: 3,   BP_ACT: 1,   BP_REM: 2,   BP_PRO: 33,  CP_TOT: 31,  CP_ACT: 0,   CP_REM: 31, CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-E-1001', CSTD_PLAN: '2024-04-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 1,    A_REM: 3,     A_PRO: 25,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-C-0801', CSTD_PLAN: '2024-04-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 54,    A_ACT: 41,   A_REM: 13,    A_PRO: 75,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 2,   BP_ACT: 0,   BP_REM: 2,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-I-0301', CSTD_PLAN: '2024-05-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-U-1101', CSTD_PLAN: '2024-05-07', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 14,    A_ACT: 4,    A_REM: 10,    A_PRO: 28,  B_TOT: 12,   B_ACT: 4,   B_REM: 8,    B_PRO: 33,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 0,   AP_REM: 3,   AP_PRO: 0,   BP_TOT: 4,   BP_ACT: 1,   BP_REM: 3,   BP_PRO: 25,  CP_TOT: 6,   CP_ACT: 0,   CP_REM: 6,  CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-U-1201', CSTD_PLAN: '2024-05-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 3,    A_REM: 3,     A_PRO: 50,  B_TOT: 6,    B_ACT: 4,   B_REM: 2,    B_PRO: 66,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,   BP_TOT: 3,   BP_ACT: 0,   BP_REM: 3,   BP_PRO: 0,   CP_TOT: 6,   CP_ACT: 0,   CP_REM: 6,  CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-C-0201', CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 7,    A_REM: 2,     A_PRO: 77,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 9,   CP_ACT: 0,   CP_REM: 9,  CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-C-0401', CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 5,     A_ACT: 0,    A_REM: 5,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-E-1101', CSTD_PLAN: '2024-05-21', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 7,    A_REM: 11,    A_PRO: 38,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 16,  BP_ACT: 12,  BP_REM: 4,   BP_PRO: 75,  CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-U-4001', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 6,    A_REM: 4,     A_PRO: 60,  B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 2,   AP_REM: 1,   AP_PRO: 66,  BP_TOT: 5,   BP_ACT: 0,   BP_REM: 5,   BP_PRO: 0,   CP_TOT: 3,   CP_ACT: 0,   CP_REM: 3,  CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-U-6005', CSTD_PLAN: '2024-06-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 15,    A_ACT: 9,    A_REM: 6,     A_PRO: 60,  B_TOT: 12,   B_ACT: 10,  B_REM: 2,    B_PRO: 83,  C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 1,   AP_REM: 4,   AP_PRO: 20,  BP_TOT: 6,   BP_ACT: 0,   BP_REM: 6,   BP_PRO: 0,   CP_TOT: 3,   CP_ACT: 0,   CP_REM: 3,  CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-U-2102', CSTD_PLAN: '2024-06-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 4,    A_REM: 5,     A_PRO: 44,  B_TOT: 7,    B_ACT: 2,   B_REM: 5,    B_PRO: 28,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 0,   AP_REM: 1,   AP_PRO: 0,   BP_TOT: 2,   BP_ACT: 0,   BP_REM: 2,   BP_PRO: 0,   CP_TOT: 5,   CP_ACT: 0,   CP_REM: 5,  CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-U-4601', CSTD_PLAN: '2024-07-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 3,     A_ACT: 1,    A_REM: 2,     A_PRO: 33,  B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-U-7401', CSTD_PLAN: '2024-07-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 57,    A_ACT: 41,   A_REM: 16,    A_PRO: 71,  B_TOT: 11,   B_ACT: 6,   B_REM: 5,    B_PRO: 54,  C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 16,  AP_ACT: 2,   AP_REM: 14,  AP_PRO: 12,  BP_TOT: 8,   BP_ACT: 0,   BP_REM: 8,   BP_PRO: 0,   CP_TOT: 3,   CP_ACT: 0,   CP_REM: 3,  CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-U-4101', CSTD_PLAN: '2024-07-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 5,     A_ACT: 0,    A_REM: 5,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-U-2103', CSTD_PLAN: '2024-07-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 140,   A_ACT: 24,   A_REM: 116,   A_PRO: 17,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-P-0103', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 100,   A_ACT: 25,   A_REM: 75,    A_PRO: 25,  B_TOT: 42,   B_ACT: 7,   B_REM: 35,   B_PRO: 16,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 22,  AP_ACT: 5,   AP_REM: 17,  AP_PRO: 22,  BP_TOT: 6,   BP_ACT: 0,   BP_REM: 6,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-P-0102', CSTD_PLAN: '2024-08-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 325,   A_ACT: 120,  A_REM: 205,   A_PRO: 36,  B_TOT: 126,  B_ACT: 42,  B_REM: 84,   B_PRO: 33,  C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 65,  AP_ACT: 7,   AP_REM: 58,  AP_PRO: 10,  BP_TOT: 78,  BP_ACT: 11,  BP_REM: 67,  BP_PRO: 14,  CP_TOT: 32,  CP_ACT: 0,   CP_REM: 32, CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-I-0101', CSTD_PLAN: '2024-08-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'PFBS',        SUBSYS_NO: '20101-P-0101', CSTD_PLAN: '2024-08-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1017,  A_ACT: 455,  A_REM: 562,   A_PRO: 44,  B_TOT: 358,  B_ACT: 143, B_REM: 215,  B_PRO: 39,  C_TOT: 16, C_ACT: 0, C_REM: 16, C_PRO: 0, AP_TOT: 226, AP_ACT: 70,  AP_REM: 156, AP_PRO: 31,  BP_TOT: 156, BP_ACT: 31,  BP_REM: 125, BP_PRO: 19,  CP_TOT: 56,  CP_ACT: 15,  CP_REM: 41, CP_PRO: 26, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-I-0401', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 14,    A_ACT: 0,    A_REM: 14,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-C-0101', CSTD_PLAN: '2024-03-02', CSTD_WALKDOWN: '2024-01-12', CSTD_ACTUAL: undefined,    A_TOT: 106,   A_ACT: 106,  A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 0,   AP_REM: 3,   AP_PRO: 0,   BP_TOT: 40,  BP_ACT: 2,   BP_REM: 38,  BP_PRO: 5,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-C-0501', CSTD_PLAN: '2024-03-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 34,    A_ACT: 14,   A_REM: 20,    A_PRO: 41,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 12,  AP_ACT: 11,  AP_REM: 1,   AP_PRO: 91,  BP_TOT: 41,  BP_ACT: 40,  BP_REM: 1,   BP_PRO: 97,  CP_TOT: 32,  CP_ACT: 26,  CP_REM: 6,  CP_PRO: 81, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-1001', CSTD_PLAN: '2024-03-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 121,   A_ACT: 73,   A_REM: 48,    A_PRO: 60,  B_TOT: 77,   B_ACT: 56,  B_REM: 21,   B_PRO: 72,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 6,   AP_ACT: 6,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 7,   BP_ACT: 7,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-1101', CSTD_PLAN: '2024-03-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 46,    A_ACT: 34,   A_REM: 12,    A_PRO: 73,  B_TOT: 42,   B_ACT: 34,  B_REM: 8,    B_PRO: 81,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 10,  AP_ACT: 10,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-C-0801', CSTD_PLAN: '2024-04-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 393,   A_ACT: 255,  A_REM: 138,   A_PRO: 64,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-I-0202', CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 15,    A_ACT: 15,   A_REM: 0,     A_PRO: 100, B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-I-0301', CSTD_PLAN: '2024-05-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 162,   A_ACT: 144,  A_REM: 18,    A_PRO: 88,  B_TOT: 36,   B_ACT: 0,   B_REM: 36,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 7,   AP_ACT: 7,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 3,   BP_ACT: 3,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-I-0201', CSTD_PLAN: '2024-05-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 23,    A_ACT: 23,   A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-T-0101', CSTD_PLAN: '2024-05-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 27,    A_ACT: 0,    A_REM: 27,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-1202', CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 43,    A_ACT: 34,   A_REM: 9,     A_PRO: 79,  B_TOT: 40,   B_ACT: 34,  B_REM: 6,    B_PRO: 85,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 10,  AP_ACT: 10,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 3,   BP_ACT: 3,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 1,   CP_REM: 1,  CP_PRO: 50, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-1201', CSTD_PLAN: '2024-05-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 210,   A_ACT: 135,  A_REM: 75,    A_PRO: 64,  B_TOT: 66,   B_ACT: 48,  B_REM: 18,   B_PRO: 72,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 17,  AP_ACT: 17,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 7,   BP_ACT: 5,   BP_REM: 2,   BP_PRO: 71,  CP_TOT: 12,  CP_ACT: 12,  CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-E-1101', CSTD_PLAN: '2024-05-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 72,    A_ACT: 31,   A_REM: 41,    A_PRO: 43,  B_TOT: 6,    B_ACT: 0,   B_REM: 6,    B_PRO: 0,   C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 8,   AP_ACT: 8,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 21,  BP_ACT: 20,  BP_REM: 1,   BP_PRO: 95,  CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-3001', CSTD_PLAN: '2024-05-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 30,    A_ACT: 12,   A_REM: 18,    A_PRO: 40,  B_TOT: 20,   B_ACT: 8,   B_REM: 12,   B_PRO: 40,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-3502', CSTD_PLAN: '2024-05-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 32,    A_ACT: 7,    A_REM: 25,    A_PRO: 21,  B_TOT: 22,   B_ACT: 6,   B_REM: 16,   B_PRO: 27,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-C-0401', CSTD_PLAN: '2024-06-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 26,    A_ACT: 0,    A_REM: 26,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-E-1001', CSTD_PLAN: '2024-06-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 6,    A_REM: 6,     A_PRO: 50,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 5,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-2103', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 216,   A_ACT: 73,   A_REM: 143,   A_PRO: 33,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 3,   BP_ACT: 2,   BP_REM: 1,   BP_PRO: 66,  CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-2102', CSTD_PLAN: '2024-06-06', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 69,    A_ACT: 67,   A_REM: 2,     A_PRO: 97,  B_TOT: 47,   B_ACT: 46,  B_REM: 1,    B_PRO: 97,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 7,   BP_ACT: 6,   BP_REM: 1,   BP_PRO: 85,  CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-2001', CSTD_PLAN: '2024-06-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 314,   A_ACT: 225,  A_REM: 89,    A_PRO: 71,  B_TOT: 126,  B_ACT: 90,  B_REM: 36,   B_PRO: 71,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 27,  AP_ACT: 26,  AP_REM: 1,   AP_PRO: 96,  BP_TOT: 57,  BP_ACT: 42,  BP_REM: 15,  BP_PRO: 73,  CP_TOT: 13,  CP_ACT: 9,   CP_REM: 4,  CP_PRO: 69, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-3401', CSTD_PLAN: '2024-06-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 125,   A_ACT: 77,   A_REM: 48,    A_PRO: 61,  B_TOT: 42,   B_ACT: 22,  B_REM: 20,   B_PRO: 52,  C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 8,   AP_ACT: 8,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 3,   BP_ACT: 3,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 5,   CP_ACT: 5,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-7001', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 40,    A_ACT: 13,   A_REM: 27,    A_PRO: 32,  B_TOT: 18,   B_ACT: 5,   B_REM: 13,   B_PRO: 27,  C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 4,   BP_ACT: 4,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-4001', CSTD_PLAN: '2024-06-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 64,    A_ACT: 58,   A_REM: 6,     A_PRO: 90,  B_TOT: 62,   B_ACT: 58,  B_REM: 4,    B_PRO: 93,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 16,  AP_ACT: 16,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-C-5401', CSTD_PLAN: '2024-07-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 57,    A_ACT: 10,   A_REM: 47,    A_PRO: 17,  B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 3,   CP_ACT: 3,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-7101', CSTD_PLAN: '2024-07-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 158,   A_ACT: 106,  A_REM: 52,    A_PRO: 67,  B_TOT: 37,   B_ACT: 12,  B_REM: 25,   B_PRO: 32,  C_TOT: 1,  C_ACT: 0, C_REM: 1,  C_PRO: 0, AP_TOT: 6,   AP_ACT: 4,   AP_REM: 2,   AP_PRO: 66,  BP_TOT: 4,   BP_ACT: 3,   BP_REM: 1,   BP_PRO: 75,  CP_TOT: 7,   CP_ACT: 6,   CP_REM: 1,  CP_PRO: 85, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-4601', CSTD_PLAN: '2024-07-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-2201', CSTD_PLAN: '2024-07-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 259,   A_ACT: 201,  A_REM: 58,    A_PRO: 77,  B_TOT: 60,   B_ACT: 43,  B_REM: 17,   B_PRO: 71,  C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 19,  AP_ACT: 11,  AP_REM: 8,   AP_PRO: 57,  BP_TOT: 13,  BP_ACT: 4,   BP_REM: 9,   BP_PRO: 30,  CP_TOT: 2,   CP_ACT: 1,   CP_REM: 1,  CP_PRO: 50, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-P-0502', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1010,  A_ACT: 684,  A_REM: 326,   A_PRO: 67,  B_TOT: 298,  B_ACT: 149, B_REM: 149,  B_PRO: 50,  C_TOT: 33, C_ACT: 0, C_REM: 33, C_PRO: 0, AP_TOT: 45,  AP_ACT: 30,  AP_REM: 15,  AP_PRO: 66,  BP_TOT: 46,  BP_ACT: 28,  BP_REM: 18,  BP_PRO: 60,  CP_TOT: 14,  CP_ACT: 12,  CP_REM: 2,  CP_PRO: 85, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-I-0101', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 117,   A_ACT: 45,   A_REM: 72,    A_PRO: 38,  B_TOT: 37,   B_ACT: 0,   B_REM: 37,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-3301', CSTD_PLAN: '2024-07-09', CSTD_WALKDOWN: '2024-01-25', CSTD_ACTUAL: undefined,    A_TOT: 103,   A_ACT: 90,   A_REM: 13,    A_PRO: 87,  B_TOT: 19,   B_ACT: 8,   B_REM: 11,   B_PRO: 42,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 14,  AP_ACT: 14,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-3501', CSTD_PLAN: '2024-07-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 70,    A_ACT: 39,   A_REM: 31,    A_PRO: 55,  B_TOT: 32,   B_ACT: 19,  B_REM: 13,   B_PRO: 59,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 7,   AP_ACT: 6,   AP_REM: 1,   AP_PRO: 85,  BP_TOT: 16,  BP_ACT: 11,  BP_REM: 5,   BP_PRO: 68,  CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-2003', CSTD_PLAN: '2024-07-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 180,   A_ACT: 20,   A_REM: 160,   A_PRO: 11,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-U-3201', CSTD_PLAN: '2024-07-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 20,    A_ACT: 17,   A_REM: 3,     A_PRO: 85,  B_TOT: 16,   B_ACT: 14,  B_REM: 2,    B_PRO: 87,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-P-0102', CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 247,   A_ACT: 82,   A_REM: 165,   A_PRO: 33,  B_TOT: 82,   B_ACT: 12,  B_REM: 70,   B_PRO: 14,  C_TOT: 12, C_ACT: 0, C_REM: 12, C_PRO: 0, AP_TOT: 14,  AP_ACT: 13,  AP_REM: 1,   AP_PRO: 92,  BP_TOT: 7,   BP_ACT: 6,   BP_REM: 1,   BP_PRO: 85,  CP_TOT: 8,   CP_ACT: 8,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-P-0201', CSTD_PLAN: '2024-07-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 746,   A_ACT: 224,  A_REM: 522,   A_PRO: 30,  B_TOT: 175,  B_ACT: 43,  B_REM: 132,  B_PRO: 24,  C_TOT: 33, C_ACT: 0, C_REM: 33, C_PRO: 0, AP_TOT: 22,  AP_ACT: 12,  AP_REM: 10,  AP_PRO: 54,  BP_TOT: 40,  BP_ACT: 33,  BP_REM: 7,   BP_PRO: 82,  CP_TOT: 12,  CP_ACT: 11,  CP_REM: 1,  CP_PRO: 91, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-P-0101', CSTD_PLAN: '2024-07-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 442,   A_ACT: 206,  A_REM: 236,   A_PRO: 46,  B_TOT: 134,  B_ACT: 34,  B_REM: 100,  B_PRO: 25,  C_TOT: 6,  C_ACT: 0, C_REM: 6,  C_PRO: 0, AP_TOT: 32,  AP_ACT: 31,  AP_REM: 1,   AP_PRO: 96,  BP_TOT: 30,  BP_ACT: 23,  BP_REM: 7,   BP_PRO: 76,  CP_TOT: 17,  CP_ACT: 15,  CP_REM: 2,  CP_PRO: 88, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-P-0301', CSTD_PLAN: '2024-07-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 376,   A_ACT: 142,  A_REM: 234,   A_PRO: 37,  B_TOT: 180,  B_ACT: 92,  B_REM: 88,   B_PRO: 51,  C_TOT: 5,  C_ACT: 0, C_REM: 5,  C_PRO: 0, AP_TOT: 14,  AP_ACT: 11,  AP_REM: 3,   AP_PRO: 78,  BP_TOT: 8,   BP_ACT: 6,   BP_REM: 2,   BP_PRO: 75,  CP_TOT: 6,   CP_ACT: 3,   CP_REM: 3,  CP_PRO: 50, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-P-0401', CSTD_PLAN: '2024-07-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 595,   A_ACT: 55,   A_REM: 540,   A_PRO: 9,   B_TOT: 136,  B_ACT: 20,  B_REM: 116,  B_PRO: 14,  C_TOT: 24, C_ACT: 0, C_REM: 24, C_PRO: 0, AP_TOT: 4,   AP_ACT: 2,   AP_REM: 2,   AP_PRO: 50,  BP_TOT: 3,   BP_ACT: 3,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 9,   CP_ACT: 9,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'SRU',         SUBSYS_NO: '20700-P-0501', CSTD_PLAN: '2024-07-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1438,  A_ACT: 991,  A_REM: 447,   A_PRO: 68,  B_TOT: 397,  B_ACT: 188, B_REM: 209,  B_PRO: 47,  C_TOT: 36, C_ACT: 0, C_REM: 36, C_PRO: 0, AP_TOT: 56,  AP_ACT: 37,  AP_REM: 19,  AP_PRO: 66,  BP_TOT: 67,  BP_ACT: 37,  BP_REM: 30,  BP_PRO: 55,  CP_TOT: 25,  CP_ACT: 15,  CP_REM: 10, CP_PRO: 60, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-E-1501', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-C-0101', CSTD_PLAN: '2024-03-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 21,    A_ACT: 19,   A_REM: 2,     A_PRO: 90,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-U-1101', CSTD_PLAN: '2024-03-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 6,    A_REM: 12,    A_PRO: 33,  B_TOT: 14,   B_ACT: 6,   B_REM: 8,    B_PRO: 42,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-C-0501', CSTD_PLAN: '2024-04-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 7,     A_ACT: 2,    A_REM: 5,     A_PRO: 28,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 8,   CP_ACT: 8,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-I-0202', CSTD_PLAN: '2024-05-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 0,    A_REM: 9,     A_PRO: 0,   B_TOT: 3,    B_ACT: 0,   B_REM: 3,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-U-4601', CSTD_PLAN: '2024-05-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-I-0301', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 2,    A_REM: 16,    A_PRO: 11,  B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-T-0101', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 0,    A_REM: 10,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-U-1202', CSTD_PLAN: '2024-05-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 4,    A_REM: 0,     A_PRO: 100, B_TOT: 4,    B_ACT: 4,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-C-0801', CSTD_PLAN: '2024-05-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 78,    A_ACT: 36,   A_REM: 42,    A_PRO: 46,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-C-0401', CSTD_PLAN: '2024-06-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-U-4001', CSTD_PLAN: '2024-06-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 6,    A_REM: 0,     A_PRO: 100, B_TOT: 6,    B_ACT: 6,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-U-3101', CSTD_PLAN: '2024-06-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 25,    A_ACT: 25,   A_REM: 0,     A_PRO: 100, B_TOT: 11,   B_ACT: 10,  B_REM: 1,    B_PRO: 90,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 6,   BP_ACT: 6,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-U-2102', CSTD_PLAN: '2024-06-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100, B_TOT: 7,    B_ACT: 6,   B_REM: 1,    B_PRO: 85,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-U-4101', CSTD_PLAN: '2024-06-21', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 5,     A_ACT: 0,    A_REM: 5,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-U-2103', CSTD_PLAN: '2024-06-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 44,    A_ACT: 16,   A_REM: 28,    A_PRO: 36,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-E-1101', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 8,     A_ACT: 3,    A_REM: 5,     A_PRO: 37,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 9,   BP_ACT: 9,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-E-1001', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 4,     A_ACT: 0,    A_REM: 4,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-C-5401', CSTD_PLAN: '2024-07-30', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 19,    A_ACT: 1,    A_REM: 18,    A_PRO: 5,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-P-0301', CSTD_PLAN: '2024-08-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 228,   A_ACT: 131,  A_REM: 97,    A_PRO: 57,  B_TOT: 133,  B_ACT: 81,  B_REM: 52,   B_PRO: 60,  C_TOT: 5,  C_ACT: 0, C_REM: 5,  C_PRO: 0, AP_TOT: 8,   AP_ACT: 8,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 6,   BP_ACT: 6,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 8,   CP_ACT: 8,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-P-0201', CSTD_PLAN: '2024-08-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 464,   A_ACT: 235,  A_REM: 229,   A_PRO: 50,  B_TOT: 202,  B_ACT: 96,  B_REM: 106,  B_PRO: 47,  C_TOT: 5,  C_ACT: 0, C_REM: 5,  C_PRO: 0, AP_TOT: 30,  AP_ACT: 28,  AP_REM: 2,   AP_PRO: 93,  BP_TOT: 28,  BP_ACT: 27,  BP_REM: 1,   BP_PRO: 96,  CP_TOT: 3,   CP_ACT: 2,   CP_REM: 1,  CP_PRO: 66, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-P-0101', CSTD_PLAN: '2024-08-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 377,   A_ACT: 215,  A_REM: 162,   A_PRO: 57,  B_TOT: 207,  B_ACT: 98,  B_REM: 109,  B_PRO: 47,  C_TOT: 8,  C_ACT: 0, C_REM: 8,  C_PRO: 0, AP_TOT: 12,  AP_ACT: 11,  AP_REM: 1,   AP_PRO: 91,  BP_TOT: 20,  BP_ACT: 18,  BP_REM: 2,   BP_PRO: 90,  CP_TOT: 10,  CP_ACT: 9,   CP_REM: 1,  CP_PRO: 90, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-I-0101', CSTD_PLAN: '2024-08-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 21,    A_ACT: 3,    A_REM: 18,    A_PRO: 14,  B_TOT: 7,    B_ACT: 0,   B_REM: 7,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'SWS',         SUBSYS_NO: '20500-I-0201', CSTD_PLAN: '2024-08-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 0,    A_REM: 18,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-T-0101', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 9,     A_ACT: 0,    A_REM: 9,     A_PRO: 0,   B_TOT: 1,    B_ACT: 0,   B_REM: 1,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-E-1102', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-H-0101', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-C-0701', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-C-0102', CSTD_PLAN: undefined,    CSTD_WALKDOWN: '2024-01-05', CSTD_ACTUAL: undefined,    A_TOT: 3,     A_ACT: 3,    A_REM: 0,     A_PRO: 100, B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-I-0101', CSTD_PLAN: undefined,    CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 22,    A_ACT: 0,    A_REM: 22,    A_PRO: 0,   B_TOT: 10,   B_ACT: 0,   B_REM: 10,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-1001', CSTD_PLAN: '2024-03-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 241,   A_ACT: 117,  A_REM: 124,   A_PRO: 48,  B_TOT: 146,  B_ACT: 92,  B_REM: 54,   B_PRO: 63,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 42,  AP_ACT: 33,  AP_REM: 9,   AP_PRO: 78,  BP_TOT: 8,   BP_ACT: 8,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 5,   CP_ACT: 5,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-C-0101', CSTD_PLAN: '2024-04-12', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 80,    A_ACT: 50,   A_REM: 30,    A_PRO: 62,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-C-0501', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 36,    A_ACT: 14,   A_REM: 22,    A_PRO: 38,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 7,   AP_ACT: 7,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 6,   BP_ACT: 3,   BP_REM: 3,   BP_PRO: 50,  CP_TOT: 10,  CP_ACT: 10,  CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-C-0801', CSTD_PLAN: '2024-04-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 355,   A_ACT: 261,  A_REM: 94,    A_PRO: 73,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 21,  AP_ACT: 18,  AP_REM: 3,   AP_PRO: 85,  BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-4102', CSTD_PLAN: '2024-05-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 16,    A_ACT: 10,   A_REM: 6,     A_PRO: 62,  B_TOT: 14,   B_ACT: 10,  B_REM: 4,    B_PRO: 71,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 8,   AP_ACT: 7,   AP_REM: 1,   AP_PRO: 87,  BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-I-0301', CSTD_PLAN: '2024-05-09', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 56,    A_ACT: 6,    A_REM: 50,    A_PRO: 10,  B_TOT: 12,   B_ACT: 0,   B_REM: 12,   B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-4101', CSTD_PLAN: '2024-05-14', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 115,   A_ACT: 78,   A_REM: 37,    A_PRO: 67,  B_TOT: 106,  B_ACT: 76,  B_REM: 30,   B_PRO: 71,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 18,  AP_ACT: 16,  AP_REM: 2,   AP_PRO: 88,  BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 6,   CP_ACT: 6,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0106', CSTD_PLAN: '2024-05-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 11,    A_ACT: 8,    A_REM: 3,     A_PRO: 72,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 4,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-1003', CSTD_PLAN: '2024-05-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 13,    A_ACT: 5,    A_REM: 8,     A_PRO: 38,  B_TOT: 5,    B_ACT: 4,   B_REM: 1,    B_PRO: 80,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-3201', CSTD_PLAN: '2024-05-16', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 180,   A_ACT: 137,  A_REM: 43,    A_PRO: 76,  B_TOT: 120,  B_ACT: 94,  B_REM: 26,   B_PRO: 78,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 60,  AP_ACT: 57,  AP_REM: 3,   AP_PRO: 95,  BP_TOT: 29,  BP_ACT: 28,  BP_REM: 1,   BP_PRO: 96,  CP_TOT: 15,  CP_ACT: 13,  CP_REM: 2,  CP_PRO: 86, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-1202', CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 64,    A_ACT: 30,   A_REM: 34,    A_PRO: 46,  B_TOT: 37,   B_ACT: 22,  B_REM: 15,   B_PRO: 59,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 7,   AP_ACT: 7,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 14,  CP_ACT: 14,  CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-1101', CSTD_PLAN: '2024-05-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 133,   A_ACT: 78,   A_REM: 55,    A_PRO: 58,  B_TOT: 118,  B_ACT: 77,  B_REM: 41,   B_PRO: 65,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 25,  AP_ACT: 21,  AP_REM: 4,   AP_PRO: 84,  BP_TOT: 6,   BP_ACT: 3,   BP_REM: 3,   BP_PRO: 50,  CP_TOT: 13,  CP_ACT: 13,  CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0104', CSTD_PLAN: '2024-05-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 22,    A_ACT: 10,   A_REM: 12,    A_PRO: 45,  B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-2202', CSTD_PLAN: '2024-05-28', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 8,     A_ACT: 4,    A_REM: 4,     A_PRO: 50,  B_TOT: 4,    B_ACT: 2,   B_REM: 2,    B_PRO: 50,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0102', CSTD_PLAN: '2024-05-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 28,    A_ACT: 16,   A_REM: 12,    A_PRO: 57,  B_TOT: 13,   B_ACT: 8,   B_REM: 5,    B_PRO: 61,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 2,   AP_REM: 1,   AP_PRO: 66,  BP_TOT: 3,   BP_ACT: 3,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 1,   CP_REM: 1,  CP_PRO: 50, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-E-1301', CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 983,   A_ACT: 406,  A_REM: 577,   A_PRO: 41,  B_TOT: 21,   B_ACT: 0,   B_REM: 21,   B_PRO: 0,   C_TOT: 13, C_ACT: 0, C_REM: 13, C_PRO: 0, AP_TOT: 32,  AP_ACT: 21,  AP_REM: 11,  AP_PRO: 65,  BP_TOT: 16,  BP_ACT: 15,  BP_REM: 1,   BP_PRO: 93,  CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-E-1101', CSTD_PLAN: '2024-06-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 47,    A_ACT: 22,   A_REM: 25,    A_PRO: 46,  B_TOT: 4,    B_ACT: 0,   B_REM: 4,    B_PRO: 0,   C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 20,  AP_ACT: 3,   AP_REM: 17,  AP_PRO: 15,  BP_TOT: 9,   BP_ACT: 9,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 3,   CP_ACT: 2,   CP_REM: 1,  CP_PRO: 66, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-C-0201', CSTD_PLAN: '2024-06-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 11,    A_ACT: 6,    A_REM: 5,     A_PRO: 54,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-E-1001', CSTD_PLAN: '2024-06-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 25,    A_ACT: 1,    A_REM: 24,    A_PRO: 4,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0301', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 39,    A_ACT: 16,   A_REM: 23,    A_PRO: 41,  B_TOT: 49,   B_ACT: 32,  B_REM: 17,   B_PRO: 65,  C_TOT: 7,  C_ACT: 0, C_REM: 7,  C_PRO: 0, AP_TOT: 19,  AP_ACT: 16,  AP_REM: 3,   AP_PRO: 84,  BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-2201', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 565,   A_ACT: 205,  A_REM: 360,   A_PRO: 36,  B_TOT: 191,  B_ACT: 112, B_REM: 79,   B_PRO: 58,  C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 47,  AP_ACT: 40,  AP_REM: 7,   AP_PRO: 85,  BP_TOT: 54,  BP_ACT: 40,  BP_REM: 14,  BP_PRO: 74,  CP_TOT: 20,  CP_ACT: 18,  CP_REM: 2,  CP_PRO: 90, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-4603', CSTD_PLAN: '2024-06-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 293,   A_ACT: 66,   A_REM: 227,   A_PRO: 22,  B_TOT: 137,  B_ACT: 0,   B_REM: 137,  B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0212', CSTD_PLAN: '2024-06-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 42,    A_ACT: 26,   A_REM: 16,    A_PRO: 61,  B_TOT: 32,   B_ACT: 22,  B_REM: 10,   B_PRO: 68,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 0,   BP_REM: 2,   BP_PRO: 0,   CP_TOT: 3,   CP_ACT: 3,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0105', CSTD_PLAN: '2024-06-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 11,    A_ACT: 8,    A_REM: 3,     A_PRO: 72,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0110', CSTD_PLAN: '2024-06-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 13,    A_ACT: 8,    A_REM: 5,     A_PRO: 61,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0112', CSTD_PLAN: '2024-06-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 9,    A_REM: 3,     A_PRO: 75,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0210', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 139,   A_ACT: 67,   A_REM: 72,    A_PRO: 48,  B_TOT: 68,   B_ACT: 46,  B_REM: 22,   B_PRO: 67,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 15,  AP_ACT: 13,  AP_REM: 2,   AP_PRO: 86,  BP_TOT: 6,   BP_ACT: 4,   BP_REM: 2,   BP_PRO: 66,  CP_TOT: 7,   CP_ACT: 7,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0126', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 34,    A_ACT: 22,   A_REM: 12,    A_PRO: 64,  B_TOT: 30,   B_ACT: 22,  B_REM: 8,    B_PRO: 73,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 3,   AP_REM: 1,   AP_PRO: 75,  BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-1201', CSTD_PLAN: '2024-06-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 292,   A_ACT: 135,  A_REM: 157,   A_PRO: 46,  B_TOT: 241,  B_ACT: 129, B_REM: 112,  B_PRO: 53,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 81,  AP_ACT: 63,  AP_REM: 18,  AP_PRO: 77,  BP_TOT: 16,  BP_ACT: 6,   BP_REM: 10,  BP_PRO: 37,  CP_TOT: 27,  CP_ACT: 24,  CP_REM: 3,  CP_PRO: 88, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0101', CSTD_PLAN: '2024-06-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 58,    A_ACT: 41,   A_REM: 17,    A_PRO: 70,  B_TOT: 33,   B_ACT: 24,  B_REM: 9,    B_PRO: 72,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 3,   AP_REM: 2,   AP_PRO: 60,  BP_TOT: 10,  BP_ACT: 8,   BP_REM: 2,   BP_PRO: 80,  CP_TOT: 5,   CP_ACT: 5,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0111', CSTD_PLAN: '2024-06-21', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 51,    A_ACT: 27,   A_REM: 24,    A_PRO: 52,  B_TOT: 35,   B_ACT: 21,  B_REM: 14,   B_PRO: 60,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 13,  AP_ACT: 13,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0119', CSTD_PLAN: '2024-06-21', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 56,    A_ACT: 35,   A_REM: 21,    A_PRO: 62,  B_TOT: 42,   B_ACT: 32,  B_REM: 10,   B_PRO: 76,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 10,  AP_ACT: 6,   AP_REM: 4,   AP_PRO: 60,  BP_TOT: 2,   BP_ACT: 1,   BP_REM: 1,   BP_PRO: 50,  CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0103', CSTD_PLAN: '2024-06-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 15,    A_ACT: 12,   A_REM: 3,     A_PRO: 80,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0107', CSTD_PLAN: '2024-06-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 16,    A_ACT: 9,    A_REM: 7,     A_PRO: 56,  B_TOT: 12,   B_ACT: 8,   B_REM: 4,    B_PRO: 66,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0108', CSTD_PLAN: '2024-06-22', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 33,    A_ACT: 20,   A_REM: 13,    A_PRO: 60,  B_TOT: 28,   B_ACT: 19,  B_REM: 9,    B_PRO: 67,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 10,  AP_ACT: 10,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0125', CSTD_PLAN: '2024-06-24', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 109,   A_ACT: 71,   A_REM: 38,    A_PRO: 65,  B_TOT: 54,   B_ACT: 42,  B_REM: 12,   B_PRO: 77,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 7,   AP_ACT: 6,   AP_REM: 1,   AP_PRO: 85,  BP_TOT: 8,   BP_ACT: 6,   BP_REM: 2,   BP_PRO: 75,  CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0114', CSTD_PLAN: '2024-06-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 12,   A_REM: 0,     A_PRO: 100, B_TOT: 8,    B_ACT: 8,   B_REM: 0,    B_PRO: 100, C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0122', CSTD_PLAN: '2024-06-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 58,    A_ACT: 50,   A_REM: 8,     A_PRO: 86,  B_TOT: 56,   B_ACT: 52,  B_REM: 4,    B_PRO: 92,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 3,   AP_REM: 1,   AP_PRO: 75,  BP_TOT: 6,   BP_ACT: 3,   BP_REM: 3,   BP_PRO: 50,  CP_TOT: 3,   CP_ACT: 2,   CP_REM: 1,  CP_PRO: 66, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-4601', CSTD_PLAN: '2024-06-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 2,    A_REM: 4,     A_PRO: 33,  B_TOT: 5,    B_ACT: 0,   B_REM: 5,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-7102', CSTD_PLAN: '2024-06-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 22,    A_ACT: 10,   A_REM: 12,    A_PRO: 45,  B_TOT: 12,   B_ACT: 8,   B_REM: 4,    B_PRO: 66,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0109', CSTD_PLAN: '2024-06-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 11,    A_ACT: 8,    A_REM: 3,     A_PRO: 72,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-3401', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 54,    A_ACT: 41,   A_REM: 13,    A_PRO: 75,  B_TOT: 29,   B_ACT: 22,  B_REM: 7,    B_PRO: 75,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 10,  AP_ACT: 10,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 7,   BP_ACT: 6,   BP_REM: 1,   BP_PRO: 85,  CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-7101', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 72,    A_ACT: 40,   A_REM: 32,    A_PRO: 55,  B_TOT: 53,   B_ACT: 35,  B_REM: 18,   B_PRO: 66,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 7,   AP_ACT: 5,   AP_REM: 2,   AP_PRO: 71,  BP_TOT: 7,   BP_ACT: 7,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 8,   CP_ACT: 8,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-5702', CSTD_PLAN: '2024-07-03', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 13,    A_ACT: 10,   A_REM: 3,     A_PRO: 76,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-4001', CSTD_PLAN: '2024-07-04', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 98,    A_ACT: 71,   A_REM: 27,    A_PRO: 72,  B_TOT: 94,   B_ACT: 70,  B_REM: 24,   B_PRO: 74,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 45,  AP_ACT: 31,  AP_REM: 14,  AP_PRO: 68,  BP_TOT: 3,   BP_ACT: 1,   BP_REM: 2,   BP_PRO: 33,  CP_TOT: 17,  CP_ACT: 11,  CP_REM: 6,  CP_PRO: 64, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0208', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 79,    A_ACT: 56,   A_REM: 23,    A_PRO: 70,  B_TOT: 54,   B_ACT: 42,  B_REM: 12,   B_PRO: 77,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 11,  AP_ACT: 5,   AP_REM: 6,   AP_PRO: 45,  BP_TOT: 7,   BP_ACT: 3,   BP_REM: 4,   BP_PRO: 42,  CP_TOT: 6,   CP_ACT: 4,   CP_REM: 2,  CP_PRO: 66, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0209', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 69,    A_ACT: 38,   A_REM: 31,    A_PRO: 55,  B_TOT: 43,   B_ACT: 32,  B_REM: 11,   B_PRO: 74,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 10,  AP_ACT: 10,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-2003', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 20,    A_ACT: 8,    A_REM: 12,    A_PRO: 40,  B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-4602', CSTD_PLAN: '2024-07-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 3,     A_ACT: 0,    A_REM: 3,     A_PRO: 0,   B_TOT: 2,    B_ACT: 0,   B_REM: 2,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-3001', CSTD_PLAN: '2024-07-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 358,   A_ACT: 268,  A_REM: 90,    A_PRO: 74,  B_TOT: 199,  B_ACT: 163, B_REM: 36,   B_PRO: 81,  C_TOT: 4,  C_ACT: 0, C_REM: 4,  C_PRO: 0, AP_TOT: 55,  AP_ACT: 46,  AP_REM: 9,   AP_PRO: 83,  BP_TOT: 38,  BP_ACT: 29,  BP_REM: 9,   BP_PRO: 76,  CP_TOT: 21,  CP_ACT: 11,  CP_REM: 10, CP_PRO: 52, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0213', CSTD_PLAN: '2024-07-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 49,    A_ACT: 34,   A_REM: 15,    A_PRO: 69,  B_TOT: 26,   B_ACT: 19,  B_REM: 7,    B_PRO: 73,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 3,   BP_ACT: 1,   BP_REM: 2,   BP_PRO: 33,  CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-4002', CSTD_PLAN: '2024-07-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 32,    A_ACT: 14,   A_REM: 18,    A_PRO: 43,  B_TOT: 26,   B_ACT: 14,  B_REM: 12,   B_PRO: 53,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-3502', CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 124,   A_ACT: 53,   A_REM: 71,    A_PRO: 42,  B_TOT: 68,   B_ACT: 42,  B_REM: 26,   B_PRO: 61,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 16,  AP_ACT: 13,  AP_REM: 3,   AP_PRO: 81,  BP_TOT: 9,   BP_ACT: 6,   BP_REM: 3,   BP_PRO: 66,  CP_TOT: 6,   CP_ACT: 6,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-3002', CSTD_PLAN: '2024-07-11', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 15,    A_ACT: 12,   A_REM: 3,     A_PRO: 80,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0203', CSTD_PLAN: '2024-07-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 12,   A_REM: 6,     A_PRO: 66,  B_TOT: 12,   B_ACT: 8,   B_REM: 4,    B_PRO: 66,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0113', CSTD_PLAN: '2024-07-15', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 13,    A_ACT: 9,    A_REM: 4,     A_PRO: 69,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0201', CSTD_PLAN: '2024-07-18', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 10,    A_ACT: 7,    A_REM: 3,     A_PRO: 70,  B_TOT: 8,    B_ACT: 6,   B_REM: 2,    B_PRO: 75,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-2103', CSTD_PLAN: '2024-07-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-2001', CSTD_PLAN: '2024-07-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 253,   A_ACT: 169,  A_REM: 84,    A_PRO: 66,  B_TOT: 104,  B_ACT: 81,  B_REM: 23,   B_PRO: 77,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 38,  AP_ACT: 29,  AP_REM: 9,   AP_PRO: 76,  BP_TOT: 29,  BP_ACT: 24,  BP_REM: 5,   BP_PRO: 82,  CP_TOT: 17,  CP_ACT: 16,  CP_REM: 1,  CP_PRO: 94, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-2101', CSTD_PLAN: '2024-07-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 1000,  A_ACT: 615,  A_REM: 385,   A_PRO: 61,  B_TOT: 558,  B_ACT: 390, B_REM: 168,  B_PRO: 69,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 159, AP_ACT: 112, AP_REM: 47,  AP_PRO: 70,  BP_TOT: 147, BP_ACT: 107, BP_REM: 40,  BP_PRO: 72,  CP_TOT: 79,  CP_ACT: 41,  CP_REM: 38, CP_PRO: 51, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0124', CSTD_PLAN: '2024-07-19', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 6,    A_REM: 6,     A_PRO: 50,  B_TOT: 10,   B_ACT: 6,   B_REM: 4,    B_PRO: 60,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-6004', CSTD_PLAN: '2024-07-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 15,    A_ACT: 12,   A_REM: 3,     A_PRO: 80,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0116', CSTD_PLAN: '2024-07-20', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 70,    A_ACT: 50,   A_REM: 20,    A_PRO: 71,  B_TOT: 55,   B_ACT: 45,  B_REM: 10,   B_PRO: 81,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 13,  AP_ACT: 7,   AP_REM: 6,   AP_PRO: 53,  BP_TOT: 3,   BP_ACT: 3,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 3,   CP_ACT: 3,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0205', CSTD_PLAN: '2024-07-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 17,    A_ACT: 14,   A_REM: 3,     A_PRO: 82,  B_TOT: 16,   B_ACT: 14,  B_REM: 2,    B_PRO: 87,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 6,   AP_ACT: 6,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0207', CSTD_PLAN: '2024-07-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 21,    A_ACT: 18,   A_REM: 3,     A_PRO: 85,  B_TOT: 20,   B_ACT: 18,  B_REM: 2,    B_PRO: 90,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 4,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0211', CSTD_PLAN: '2024-07-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 19,    A_ACT: 16,   A_REM: 3,     A_PRO: 84,  B_TOT: 18,   B_ACT: 16,  B_REM: 2,    B_PRO: 88,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0202', CSTD_PLAN: '2024-07-26', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 24,    A_ACT: 21,   A_REM: 3,     A_PRO: 87,  B_TOT: 16,   B_ACT: 14,  B_REM: 2,    B_PRO: 87,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 10,  AP_ACT: 10,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 3,   CP_ACT: 3,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0214', CSTD_PLAN: '2024-07-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 45,    A_ACT: 30,   A_REM: 15,    A_PRO: 66,  B_TOT: 25,   B_ACT: 19,  B_REM: 6,    B_PRO: 76,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 14,  AP_ACT: 14,  AP_REM: 0,   AP_PRO: 100, BP_TOT: 5,   BP_ACT: 3,   BP_REM: 2,   BP_PRO: 60,  CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0118', CSTD_PLAN: '2024-07-27', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 11,    A_ACT: 8,    A_REM: 3,     A_PRO: 72,  B_TOT: 10,   B_ACT: 8,   B_REM: 2,    B_PRO: 80,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-7501', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 27,    A_ACT: 24,   A_REM: 3,     A_PRO: 88,  B_TOT: 18,   B_ACT: 16,  B_REM: 2,    B_PRO: 88,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 5,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 3,   BP_ACT: 0,   BP_REM: 3,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-3101', CSTD_PLAN: '2024-08-01', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 611,   A_ACT: 462,  A_REM: 149,   A_PRO: 75,  B_TOT: 413,  B_ACT: 319, B_REM: 94,   B_PRO: 77,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 217, AP_ACT: 158, AP_REM: 59,  AP_PRO: 72,  BP_TOT: 121, BP_ACT: 79,  BP_REM: 42,  BP_PRO: 65,  CP_TOT: 67,  CP_ACT: 39,  CP_REM: 28, CP_PRO: 58, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0121', CSTD_PLAN: '2024-08-02', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 43,    A_ACT: 34,   A_REM: 9,     A_PRO: 79,  B_TOT: 40,   B_ACT: 34,  B_REM: 6,    B_PRO: 85,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 1,   AP_REM: 2,   AP_PRO: 33,  BP_TOT: 3,   BP_ACT: 3,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-3402', CSTD_PLAN: '2024-08-08', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 18,    A_ACT: 15,   A_REM: 3,     A_PRO: 83,  B_TOT: 12,   B_ACT: 10,  B_REM: 2,    B_PRO: 83,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 5,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 1,   BP_REM: 1,   BP_PRO: 50,  CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-6102', CSTD_PLAN: '2024-08-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 12,    A_ACT: 0,    A_REM: 12,    A_PRO: 0,   B_TOT: 0,    B_ACT: 0,   B_REM: 0,    B_PRO: 0,   C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0115', CSTD_PLAN: '2024-08-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 90,    A_ACT: 54,   A_REM: 36,    A_PRO: 60,  B_TOT: 38,   B_ACT: 20,  B_REM: 18,   B_PRO: 52,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 29,  AP_ACT: 27,  AP_REM: 2,   AP_PRO: 93,  BP_TOT: 12,  BP_ACT: 9,   BP_REM: 3,   BP_PRO: 75,  CP_TOT: 5,   CP_ACT: 4,   CP_REM: 1,  CP_PRO: 80, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0117', CSTD_PLAN: '2024-08-10', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 46,    A_ACT: 27,   A_REM: 19,    A_PRO: 58,  B_TOT: 17,   B_ACT: 10,  B_REM: 7,    B_PRO: 58,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 10,  AP_ACT: 8,   AP_REM: 2,   AP_PRO: 80,  BP_TOT: 9,   BP_ACT: 5,   BP_REM: 4,   BP_PRO: 55,  CP_TOT: 9,   CP_ACT: 8,   CP_REM: 1,  CP_PRO: 88, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-3301', CSTD_PLAN: '2024-08-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 184,   A_ACT: 102,  A_REM: 82,    A_PRO: 55,  B_TOT: 98,   B_ACT: 61,  B_REM: 37,   B_PRO: 62,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 32,  AP_ACT: 19,  AP_REM: 13,  AP_PRO: 59,  BP_TOT: 16,  BP_ACT: 12,  BP_REM: 4,   BP_PRO: 75,  CP_TOT: 2,   CP_ACT: 1,   CP_REM: 1,  CP_PRO: 50, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0120', CSTD_PLAN: '2024-08-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 24,    A_ACT: 16,   A_REM: 8,     A_PRO: 66,  B_TOT: 16,   B_ACT: 10,  B_REM: 6,    B_PRO: 62,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0219', CSTD_PLAN: '2024-08-13', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 44,    A_ACT: 12,   A_REM: 32,    A_PRO: 27,  B_TOT: 14,   B_ACT: 6,   B_REM: 8,    B_PRO: 42,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 5,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 2,   CP_ACT: 2,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0218', CSTD_PLAN: '2024-08-17', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 39,    A_ACT: 22,   A_REM: 17,    A_PRO: 56,  B_TOT: 25,   B_ACT: 20,  B_REM: 5,    B_PRO: 80,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 5,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 6,   CP_ACT: 6,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0215', CSTD_PLAN: '2024-08-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 20,    A_ACT: 14,   A_REM: 6,     A_PRO: 70,  B_TOT: 18,   B_ACT: 14,  B_REM: 4,    B_PRO: 77,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 7,   AP_ACT: 7,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 6,   CP_ACT: 6,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0216', CSTD_PLAN: '2024-08-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 14,    A_ACT: 8,    A_REM: 6,     A_PRO: 57,  B_TOT: 12,   B_ACT: 8,   B_REM: 4,    B_PRO: 66,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 6,   AP_ACT: 6,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0217', CSTD_PLAN: '2024-08-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 6,     A_ACT: 3,    A_REM: 3,     A_PRO: 50,  B_TOT: 4,    B_ACT: 2,   B_REM: 2,    B_PRO: 50,  C_TOT: 2,  C_ACT: 0, C_REM: 2,  C_PRO: 0, AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 2,   BP_ACT: 2,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-1002', CSTD_PLAN: '2024-08-23', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 146,   A_ACT: 20,   A_REM: 126,   A_PRO: 13,  B_TOT: 91,   B_ACT: 0,   B_REM: 91,   B_PRO: 0,   C_TOT: 13, C_ACT: 0, C_REM: 13, C_PRO: 0, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-5401', CSTD_PLAN: '2024-08-29', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 82,    A_ACT: 15,   A_REM: 67,    A_PRO: 18,  B_TOT: 40,   B_ACT: 12,  B_REM: 28,   B_PRO: 30,  C_TOT: 3,  C_ACT: 0, C_REM: 3,  C_PRO: 0, AP_TOT: 4,   AP_ACT: 4,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 1,   BP_REM: 0,   BP_PRO: 100, CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0206', CSTD_PLAN: '2024-08-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 25,    A_ACT: 21,   A_REM: 4,     A_PRO: 84,  B_TOT: 16,   B_ACT: 14,  B_REM: 2,    B_PRO: 87,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 5,   AP_ACT: 5,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-P-0204', CSTD_PLAN: '2024-08-31', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 27,    A_ACT: 24,   A_REM: 3,     A_PRO: 88,  B_TOT: 20,   B_ACT: 18,  B_REM: 2,    B_PRO: 90,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,   CP_TOT: 4,   CP_ACT: 4,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-7001', CSTD_PLAN: '2024-09-05', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 226,   A_ACT: 52,   A_REM: 174,   A_PRO: 23,  B_TOT: 97,   B_ACT: 46,  B_REM: 51,   B_PRO: 47,  C_TOT: 0,  C_ACT: 0, C_REM: 0,  C_PRO: 0, AP_TOT: 31,  AP_ACT: 8,   AP_REM: 23,  AP_PRO: 25,  BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,   CP_TOT: 5,   CP_ACT: 5,   CP_REM: 0,  CP_PRO: 100, },
      { UNIT: 'U&O',         SUBSYS_NO: '21100-U-7002', CSTD_PLAN: '2024-09-25', CSTD_WALKDOWN: undefined,    CSTD_ACTUAL: undefined,    A_TOT: 830,   A_ACT: 107,  A_REM: 723,   A_PRO: 12,  B_TOT: 240,  B_ACT: 60,  B_REM: 180,  B_PRO: 25,  C_TOT: 30, C_ACT: 0, C_REM: 30, C_PRO: 0, AP_TOT: 82,  AP_ACT: 7,   AP_REM: 75,  AP_PRO: 8,   BP_TOT: 9,   BP_ACT: 2,   BP_REM: 7,   BP_PRO: 22,  CP_TOT: 5,   CP_ACT: 1,   CP_REM: 4,  CP_PRO: 20, },
  ],

  SQL2: [
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 44,   REMAIN_CUM: 2171,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 54,   REMAIN_CUM: 2117,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 60,   REMAIN_CUM: 2057,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 88,   REMAIN_CUM: 1969,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 56,   REMAIN_CUM: 1913,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 16,   REMAIN_CUM: 1897,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 7,    REMAIN_CUM: 1890,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 7,    REMAIN_CUM: 1883,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 15,   REMAIN_CUM: 1868,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 1,    REMAIN_CUM: 1867,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 6,    REMAIN_CUM: 1861,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 4,    REMAIN_CUM: 1857,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 2,    REMAIN_CUM: 1855,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 5,    REMAIN_CUM: 1850,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 3,    REMAIN_CUM: 1847,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 12,   REMAIN_CUM: 1835,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 1,    REMAIN_CUM: 1834,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 2,    REMAIN_CUM: 1832,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 4,    REMAIN_CUM: 1828,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 8,    REMAIN_CUM: 1820,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 4,    REMAIN_CUM: 1816,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 2,    REMAIN_CUM: 1814,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 12,   REMAIN_CUM: 1802,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 9,    REMAIN_CUM: 1793,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 8,    REMAIN_CUM: 12548, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 1,    REMAIN_CUM: 12547, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 17,   REMAIN_CUM: 12530, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 47,   REMAIN_CUM: 12483, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 17,   REMAIN_CUM: 12466, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 37,   REMAIN_CUM: 12429, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 13,   REMAIN_CUM: 12416, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 48,   REMAIN_CUM: 12368, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 22,   REMAIN_CUM: 12346, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 327,  REMAIN_CUM: 12019, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 222,  REMAIN_CUM: 11797, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 9,    REMAIN_CUM: 11788, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 5,    REMAIN_CUM: 11783, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 25,   REMAIN_CUM: 11758, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 17,   REMAIN_CUM: 11741, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 10,   REMAIN_CUM: 11731, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 32,   REMAIN_CUM: 11699, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 43,   REMAIN_CUM: 11656, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 72,   REMAIN_CUM: 11584, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 184,  REMAIN_CUM: 11400, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 173,  REMAIN_CUM: 11227, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 27,   REMAIN_CUM: 11200, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 34,   REMAIN_CUM: 11166, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 34,   REMAIN_CUM: 11132, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 1807,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 2,    REMAIN_CUM: 1805,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 3,    REMAIN_CUM: 1802,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 4,    REMAIN_CUM: 1798,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 1,    REMAIN_CUM: 1797,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 3,    REMAIN_CUM: 1794,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 4,    REMAIN_CUM: 1790,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 5,    REMAIN_CUM: 1785,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 0,    REMAIN_CUM: 1785,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 0,    REMAIN_CUM: 1785,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 19,   REMAIN_CUM: 1766,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 3,    REMAIN_CUM: 1763,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 24,   REMAIN_CUM: 1739,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 2,    REMAIN_CUM: 1737,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 5,    REMAIN_CUM: 1732,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 9,    REMAIN_CUM: 1723,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 8,    REMAIN_CUM: 1715,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 9,    REMAIN_CUM: 1706,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 5,    REMAIN_CUM: 1701,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 28,   REMAIN_CUM: 1673,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 16,   REMAIN_CUM: 1657,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 13,   REMAIN_CUM: 1644,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 27,   REMAIN_CUM: 1617,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 14,   REMAIN_CUM: 1603,  TOTAL: 2406, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 1,    REMAIN_CUM: 1433,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 10,   REMAIN_CUM: 1423,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 5,    REMAIN_CUM: 1418,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 13,   REMAIN_CUM: 1405,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 7,    REMAIN_CUM: 1398,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 11,   REMAIN_CUM: 1387,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 6,    REMAIN_CUM: 1381,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 13,   REMAIN_CUM: 1368,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 2,    REMAIN_CUM: 1366,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 5,    REMAIN_CUM: 1361,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 3,    REMAIN_CUM: 1358,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 2,    REMAIN_CUM: 1356,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 11,   REMAIN_CUM: 1345,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 10,   REMAIN_CUM: 1335,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 9,    REMAIN_CUM: 1326,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 1326,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 1,    REMAIN_CUM: 1325,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 8,    REMAIN_CUM: 1317,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 13,   REMAIN_CUM: 1304,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 5,    REMAIN_CUM: 1299,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 2,    REMAIN_CUM: 1297,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 11,   REMAIN_CUM: 1286,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 16,   REMAIN_CUM: 1270,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 7,    REMAIN_CUM: 1263,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 19,   REMAIN_CUM: 7636,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 45,   REMAIN_CUM: 7591,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 37,   REMAIN_CUM: 7554,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 59,   REMAIN_CUM: 7495,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 75,   REMAIN_CUM: 7420,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 75,   REMAIN_CUM: 7345,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 112,  REMAIN_CUM: 7233,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 31,   REMAIN_CUM: 7202,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 14,   REMAIN_CUM: 7188,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 57,   REMAIN_CUM: 7131,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 66,   REMAIN_CUM: 7065,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 68,   REMAIN_CUM: 6997,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 123,  REMAIN_CUM: 6874,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 154,  REMAIN_CUM: 6720,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 168,  REMAIN_CUM: 6552,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 99,   REMAIN_CUM: 6453,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 58,   REMAIN_CUM: 6395,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 140,  REMAIN_CUM: 6255,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 140,  REMAIN_CUM: 6115,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 221,  REMAIN_CUM: 5894,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 207,  REMAIN_CUM: 5687,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 167,  REMAIN_CUM: 5520,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 238,  REMAIN_CUM: 5282,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 15,   REMAIN_CUM: 5267,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 153,  REMAIN_CUM: 33668, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 66,   REMAIN_CUM: 33602, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 92,   REMAIN_CUM: 33510, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 66,   REMAIN_CUM: 33444, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 76,   REMAIN_CUM: 33368, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 205,  REMAIN_CUM: 33163, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 160,  REMAIN_CUM: 33003, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 448,  REMAIN_CUM: 32555, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 822,  REMAIN_CUM: 31733, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 328,  REMAIN_CUM: 31405, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 92,   REMAIN_CUM: 31313, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 47,   REMAIN_CUM: 31266, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 63,   REMAIN_CUM: 31203, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 109,  REMAIN_CUM: 31094, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 61,   REMAIN_CUM: 31033, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 100,  REMAIN_CUM: 30933, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 114,  REMAIN_CUM: 30819, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 113,  REMAIN_CUM: 30706, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 44,   REMAIN_CUM: 30662, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 82,   REMAIN_CUM: 30580, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 24,   REMAIN_CUM: 30556, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 25,   REMAIN_CUM: 30531, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 149,  REMAIN_CUM: 30382, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 59,   REMAIN_CUM: 30323, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 22,   REMAIN_CUM: 7500,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 20,   REMAIN_CUM: 7480,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 15,   REMAIN_CUM: 7465,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 12,   REMAIN_CUM: 7453,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 21,   REMAIN_CUM: 7432,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 51,   REMAIN_CUM: 7381,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 36,   REMAIN_CUM: 7345,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 61,   REMAIN_CUM: 7284,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 80,   REMAIN_CUM: 7204,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 337,  REMAIN_CUM: 6867,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 62,   REMAIN_CUM: 6805,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 10,   REMAIN_CUM: 6795,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 18,   REMAIN_CUM: 6777,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 25,   REMAIN_CUM: 6752,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 45,   REMAIN_CUM: 6707,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 21,   REMAIN_CUM: 6686,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 31,   REMAIN_CUM: 6655,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 34,   REMAIN_CUM: 6621,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 47,   REMAIN_CUM: 6574,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 9,    REMAIN_CUM: 6565,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 15,   REMAIN_CUM: 6550,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 6,    REMAIN_CUM: 6544,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 8,    REMAIN_CUM: 6536,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 16,   REMAIN_CUM: 6520,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 81,   REMAIN_CUM: 2817,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 3,    REMAIN_CUM: 2814,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 14,   REMAIN_CUM: 2800,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 9,    REMAIN_CUM: 2791,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 8,    REMAIN_CUM: 2783,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 29,   REMAIN_CUM: 2754,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 21,   REMAIN_CUM: 2733,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 42,   REMAIN_CUM: 2691,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 14,   REMAIN_CUM: 2677,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 56,   REMAIN_CUM: 2621,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 42,   REMAIN_CUM: 2579,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 29,   REMAIN_CUM: 2550,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 14,   REMAIN_CUM: 2536,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 17,   REMAIN_CUM: 2519,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 5,    REMAIN_CUM: 2514,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 6,    REMAIN_CUM: 2508,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 8,    REMAIN_CUM: 2500,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 33,   REMAIN_CUM: 2467,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 17,   REMAIN_CUM: 2450,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 41,   REMAIN_CUM: 2409,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 13,   REMAIN_CUM: 2396,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 32,   REMAIN_CUM: 2364,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 93,   REMAIN_CUM: 2271,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 20,   REMAIN_CUM: 2251,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 48,   REMAIN_CUM: 677,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 65,   REMAIN_CUM: 612,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 94,   REMAIN_CUM: 518,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 51,   REMAIN_CUM: 467,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 76,   REMAIN_CUM: 391,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 46,   REMAIN_CUM: 345,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 32,   REMAIN_CUM: 313,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 14,   REMAIN_CUM: 299,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 14,   REMAIN_CUM: 285,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 42,   REMAIN_CUM: 243,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 16,   REMAIN_CUM: 227,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 1,    REMAIN_CUM: 226,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 0,    REMAIN_CUM: 226,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 12,   REMAIN_CUM: 214,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 6,    REMAIN_CUM: 208,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 5,    REMAIN_CUM: 203,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 6,    REMAIN_CUM: 197,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 12,   REMAIN_CUM: 185,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 0,    REMAIN_CUM: 185,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 2,    REMAIN_CUM: 183,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 0,    REMAIN_CUM: 183,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 36,   REMAIN_CUM: 147,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 6,    REMAIN_CUM: 141,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 6,    REMAIN_CUM: 135,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 516,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 516,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 1,    REMAIN_CUM: 515,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 18,   REMAIN_CUM: 497,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 18,   REMAIN_CUM: 479,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 6,    REMAIN_CUM: 473,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 0,    REMAIN_CUM: 473,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 17,   REMAIN_CUM: 456,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 8,    REMAIN_CUM: 448,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 155,  REMAIN_CUM: 293,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 34,   REMAIN_CUM: 259,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 44,   REMAIN_CUM: 215,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 11,   REMAIN_CUM: 204,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 8,    REMAIN_CUM: 196,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 1,    REMAIN_CUM: 195,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 195,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 34,   REMAIN_CUM: 161,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 161,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 36,   REMAIN_CUM: 125,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 125,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 40,   REMAIN_CUM: 85,    TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 85,    TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 0,    REMAIN_CUM: 85,    TOTAL: 550, },
    { ITEM: 'A', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 85,    TOTAL: 550, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 5,    REMAIN_CUM: 672,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 672,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 7,    REMAIN_CUM: 665,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 665,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 3,    REMAIN_CUM: 662,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 6,    REMAIN_CUM: 656,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 0,    REMAIN_CUM: 656,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 11,   REMAIN_CUM: 645,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 0,    REMAIN_CUM: 645,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 0,    REMAIN_CUM: 645,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 0,    REMAIN_CUM: 645,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 9,    REMAIN_CUM: 636,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 1,    REMAIN_CUM: 635,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 2,    REMAIN_CUM: 633,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 4,    REMAIN_CUM: 629,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 629,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 1,    REMAIN_CUM: 628,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 2,    REMAIN_CUM: 626,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 2,    REMAIN_CUM: 624,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 624,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 0,    REMAIN_CUM: 624,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 2,    REMAIN_CUM: 622,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 0,    REMAIN_CUM: 622,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 2,    REMAIN_CUM: 620,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 17,   REMAIN_CUM: 4862,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 9,    REMAIN_CUM: 4853,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 18,   REMAIN_CUM: 4835,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 73,   REMAIN_CUM: 4762,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 63,   REMAIN_CUM: 4699,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 14,   REMAIN_CUM: 4685,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 72,   REMAIN_CUM: 4613,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 48,   REMAIN_CUM: 4565,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 10,   REMAIN_CUM: 4555,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 18,   REMAIN_CUM: 4537,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 33,   REMAIN_CUM: 4504,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 39,   REMAIN_CUM: 4465,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 34,   REMAIN_CUM: 4431,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 47,   REMAIN_CUM: 4384,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 172,  REMAIN_CUM: 4212,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 153,  REMAIN_CUM: 4059,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 107,  REMAIN_CUM: 3952,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 101,  REMAIN_CUM: 3851,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 72,   REMAIN_CUM: 3779,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 32,   REMAIN_CUM: 3747,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 12,   REMAIN_CUM: 3735,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 4,    REMAIN_CUM: 3731,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 12,   REMAIN_CUM: 3719,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 6,    REMAIN_CUM: 3713,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 6,    REMAIN_CUM: 1637,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 5,    REMAIN_CUM: 1632,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 3,    REMAIN_CUM: 1629,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 4,    REMAIN_CUM: 1625,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 6,    REMAIN_CUM: 1619,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 24,   REMAIN_CUM: 1595,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 21,   REMAIN_CUM: 1574,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 126,  REMAIN_CUM: 1448,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 100,  REMAIN_CUM: 1348,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 108,  REMAIN_CUM: 1240,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 135,  REMAIN_CUM: 1105,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 84,   REMAIN_CUM: 1021,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 145,  REMAIN_CUM: 876,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 52,   REMAIN_CUM: 824,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 1,    REMAIN_CUM: 823,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 7,    REMAIN_CUM: 816,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 2,    REMAIN_CUM: 814,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 1,    REMAIN_CUM: 813,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 1,    REMAIN_CUM: 812,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 18,   REMAIN_CUM: 794,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 0,    REMAIN_CUM: 794,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 2,    REMAIN_CUM: 792,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 3,    REMAIN_CUM: 789,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 8,    REMAIN_CUM: 781,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 7,    REMAIN_CUM: 44,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 5,    REMAIN_CUM: 39,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 1,    REMAIN_CUM: 38,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 0,    REMAIN_CUM: 38,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 5,    REMAIN_CUM: 33,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 5,    REMAIN_CUM: 28,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 0,    REMAIN_CUM: 28,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 0,    REMAIN_CUM: 28,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 0,    REMAIN_CUM: 28,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 2,    REMAIN_CUM: 26,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 26,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 0,    REMAIN_CUM: 26,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 26,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 0,    REMAIN_CUM: 26,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 10,   REMAIN_CUM: 16,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 1,    REMAIN_CUM: 15,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 15,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 0,    REMAIN_CUM: 15,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 15,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 96,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 96,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 12,   REMAIN_CUM: 84,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 84,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 0,    REMAIN_CUM: 84,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 0,    REMAIN_CUM: 84,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 0,    REMAIN_CUM: 84,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 0,    REMAIN_CUM: 84,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 0,    REMAIN_CUM: 84,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 0,    REMAIN_CUM: 84,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 5,    REMAIN_CUM: 79,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 1,    REMAIN_CUM: 78,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 0,    REMAIN_CUM: 78,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 0,    REMAIN_CUM: 78,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 2,    REMAIN_CUM: 76,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 2,    REMAIN_CUM: 74,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 0,    REMAIN_CUM: 74,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 10,   REMAIN_CUM: 64,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 0,    REMAIN_CUM: 64,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 64,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 5,    REMAIN_CUM: 59,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 4,    REMAIN_CUM: 55,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 0,    REMAIN_CUM: 55,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 55,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 1,    REMAIN_CUM: 4028,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 4028,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 11,   REMAIN_CUM: 4017,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 6,    REMAIN_CUM: 4011,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 1,    REMAIN_CUM: 4010,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 5,    REMAIN_CUM: 4005,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 18,   REMAIN_CUM: 3987,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 1,    REMAIN_CUM: 3986,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 9,    REMAIN_CUM: 3977,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 3,    REMAIN_CUM: 3974,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 1,    REMAIN_CUM: 3973,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 8,    REMAIN_CUM: 3965,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 10,   REMAIN_CUM: 3955,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 6,    REMAIN_CUM: 3949,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 5,    REMAIN_CUM: 3944,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 4,    REMAIN_CUM: 3940,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 16,   REMAIN_CUM: 3924,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 10,   REMAIN_CUM: 3914,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 11,   REMAIN_CUM: 3903,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 33,   REMAIN_CUM: 3870,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 15,   REMAIN_CUM: 3855,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 11,   REMAIN_CUM: 3844,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 6,    REMAIN_CUM: 3838,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 15,   REMAIN_CUM: 3823,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 1,    REMAIN_CUM: 2138,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 2138,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 1,    REMAIN_CUM: 2137,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 5,    REMAIN_CUM: 2132,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 2,    REMAIN_CUM: 2130,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 0,    REMAIN_CUM: 2130,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 13,   REMAIN_CUM: 2117,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 5,    REMAIN_CUM: 2112,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 4,    REMAIN_CUM: 2108,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 9,    REMAIN_CUM: 2099,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 6,    REMAIN_CUM: 2093,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 22,   REMAIN_CUM: 2071,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 3,    REMAIN_CUM: 2068,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 0,    REMAIN_CUM: 2068,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 5,    REMAIN_CUM: 2063,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 2063,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 2,    REMAIN_CUM: 2061,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 2061,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 3,    REMAIN_CUM: 2058,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 12,   REMAIN_CUM: 2046,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 1,    REMAIN_CUM: 2045,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 1,    REMAIN_CUM: 2044,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 6,    REMAIN_CUM: 2038,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 9,    REMAIN_CUM: 2029,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 589,  REMAIN_CUM: 97542, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 427,  REMAIN_CUM: 97115, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 618,  REMAIN_CUM: 96497, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 582,  REMAIN_CUM: 95915, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 544,  REMAIN_CUM: 95371, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 613,  REMAIN_CUM: 94758, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 595,  REMAIN_CUM: 94163, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 965,  REMAIN_CUM: 93198, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 1206, REMAIN_CUM: 91992, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 1529, REMAIN_CUM: 90463, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 927,  REMAIN_CUM: 89536, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 549,  REMAIN_CUM: 88987, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 536,  REMAIN_CUM: 88451, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 660,  REMAIN_CUM: 87791, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 605,  REMAIN_CUM: 87186, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 493,  REMAIN_CUM: 86693, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 497,  REMAIN_CUM: 86196, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 733,  REMAIN_CUM: 85463, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 735,  REMAIN_CUM: 84728, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 922,  REMAIN_CUM: 83806, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 614,  REMAIN_CUM: 83192, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 424,  REMAIN_CUM: 82768, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 782,  REMAIN_CUM: 81986, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 363,  REMAIN_CUM: 81623, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 12,   REMAIN_CUM: 1659,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 2,    REMAIN_CUM: 1657,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 2,    REMAIN_CUM: 1655,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 8,    REMAIN_CUM: 1647,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 6,    REMAIN_CUM: 1641,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 3,    REMAIN_CUM: 1638,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 4,    REMAIN_CUM: 1634,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 7,    REMAIN_CUM: 1627,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 4,    REMAIN_CUM: 1623,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 2,    REMAIN_CUM: 1621,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 9,    REMAIN_CUM: 1612,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 4,    REMAIN_CUM: 1608,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 1,    REMAIN_CUM: 1607,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 8,    REMAIN_CUM: 1599,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 18,   REMAIN_CUM: 1581,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 8,    REMAIN_CUM: 1573,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 20,   REMAIN_CUM: 1553,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 128,  REMAIN_CUM: 1425,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 165,  REMAIN_CUM: 1260,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 144,  REMAIN_CUM: 1116,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 4,    REMAIN_CUM: 1112,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 1112,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 0,    REMAIN_CUM: 1112,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 2,    REMAIN_CUM: 1110,  TOTAL: 1862, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 57,   REMAIN_CUM: 4302,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 62,   REMAIN_CUM: 4240,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 48,   REMAIN_CUM: 4192,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 6,    REMAIN_CUM: 4186,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 2,    REMAIN_CUM: 4184,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 6,    REMAIN_CUM: 4178,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 14,   REMAIN_CUM: 4164,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 4,    REMAIN_CUM: 4160,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 1,    REMAIN_CUM: 4159,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 1,    REMAIN_CUM: 4158,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 9,    REMAIN_CUM: 4149,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 39,   REMAIN_CUM: 4110,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 1,    REMAIN_CUM: 4109,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 38,   REMAIN_CUM: 4071,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 0,    REMAIN_CUM: 4071,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 8,    REMAIN_CUM: 4063,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 0,    REMAIN_CUM: 4063,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 4063,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 4,    REMAIN_CUM: 4059,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 1,    REMAIN_CUM: 4058,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 9,    REMAIN_CUM: 4049,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 23,   REMAIN_CUM: 4026,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 129,  REMAIN_CUM: 3897,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 101,  REMAIN_CUM: 3796,  TOTAL: 8018, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 31,   REMAIN_CUM: 970,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 23,   REMAIN_CUM: 947,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 99,   REMAIN_CUM: 848,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 31,   REMAIN_CUM: 817,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 28,   REMAIN_CUM: 789,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 18,   REMAIN_CUM: 771,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 4,    REMAIN_CUM: 767,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 4,    REMAIN_CUM: 763,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 3,    REMAIN_CUM: 760,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 0,    REMAIN_CUM: 760,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 7,    REMAIN_CUM: 753,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 0,    REMAIN_CUM: 753,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 1,    REMAIN_CUM: 752,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 3,    REMAIN_CUM: 749,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 1,    REMAIN_CUM: 748,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 748,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 0,    REMAIN_CUM: 748,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 4,    REMAIN_CUM: 744,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 11,   REMAIN_CUM: 733,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 21,   REMAIN_CUM: 712,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 3,    REMAIN_CUM: 709,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 2,    REMAIN_CUM: 707,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 5,    REMAIN_CUM: 702,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 5,    REMAIN_CUM: 697,   TOTAL: 1409, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 83,   REMAIN_CUM: 6354,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 60,   REMAIN_CUM: 6294,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 79,   REMAIN_CUM: 6215,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 82,   REMAIN_CUM: 6133,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 78,   REMAIN_CUM: 6055,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 51,   REMAIN_CUM: 6004,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 53,   REMAIN_CUM: 5951,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 72,   REMAIN_CUM: 5879,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 84,   REMAIN_CUM: 5795,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 75,   REMAIN_CUM: 5720,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 155,  REMAIN_CUM: 5565,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 126,  REMAIN_CUM: 5439,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 69,   REMAIN_CUM: 5370,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 137,  REMAIN_CUM: 5233,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 75,   REMAIN_CUM: 5158,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 49,   REMAIN_CUM: 5109,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 56,   REMAIN_CUM: 5053,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 83,   REMAIN_CUM: 4970,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 88,   REMAIN_CUM: 4882,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 71,   REMAIN_CUM: 4811,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 70,   REMAIN_CUM: 4741,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 56,   REMAIN_CUM: 4685,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 38,   REMAIN_CUM: 4647,  TOTAL: 9377, },
    { ITEM: 'A', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 35,   REMAIN_CUM: 4612,  TOTAL: 9377, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 12,   REMAIN_CUM: 884,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 14,   REMAIN_CUM: 870,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 28,   REMAIN_CUM: 842,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 49,   REMAIN_CUM: 793,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 72,   REMAIN_CUM: 721,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 1,    REMAIN_CUM: 720,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 7,    REMAIN_CUM: 713,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 1,    REMAIN_CUM: 712,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 15,   REMAIN_CUM: 697,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 3,    REMAIN_CUM: 694,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 1,    REMAIN_CUM: 693,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 3,    REMAIN_CUM: 690,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 0,    REMAIN_CUM: 690,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 0,    REMAIN_CUM: 690,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 2,    REMAIN_CUM: 688,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 3,    REMAIN_CUM: 685,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 0,    REMAIN_CUM: 685,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 685,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 0,    REMAIN_CUM: 685,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 685,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 6,    REMAIN_CUM: 679,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 679,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 1,    REMAIN_CUM: 678,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'ARU',         SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 1,    REMAIN_CUM: 677,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 3846,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 1,    REMAIN_CUM: 3845,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 3,    REMAIN_CUM: 3842,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 8,    REMAIN_CUM: 3834,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 2,    REMAIN_CUM: 3832,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 6,    REMAIN_CUM: 3826,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 1,    REMAIN_CUM: 3825,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 6,    REMAIN_CUM: 3819,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 4,    REMAIN_CUM: 3815,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 1,    REMAIN_CUM: 3814,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 7,    REMAIN_CUM: 3807,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 1,    REMAIN_CUM: 3806,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 4,    REMAIN_CUM: 3802,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 1,    REMAIN_CUM: 3801,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 4,    REMAIN_CUM: 3797,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 20,   REMAIN_CUM: 3777,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 1,    REMAIN_CUM: 3776,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 7,    REMAIN_CUM: 3769,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 34,   REMAIN_CUM: 3735,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 52,   REMAIN_CUM: 3683,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 80,   REMAIN_CUM: 3603,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 13,   REMAIN_CUM: 3590,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 15,   REMAIN_CUM: 3575,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU',        SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 11,   REMAIN_CUM: 3564,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 1,    REMAIN_CUM: 953,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 1,    REMAIN_CUM: 952,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 0,    REMAIN_CUM: 952,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 952,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 6,    REMAIN_CUM: 946,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 2,    REMAIN_CUM: 944,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 1,    REMAIN_CUM: 943,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CCFU SS',     SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 943,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 6,    REMAIN_CUM: 725,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 5,    REMAIN_CUM: 720,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 3,    REMAIN_CUM: 717,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 8,    REMAIN_CUM: 709,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 7,    REMAIN_CUM: 702,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 6,    REMAIN_CUM: 696,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 4,    REMAIN_CUM: 692,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 7,    REMAIN_CUM: 685,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 1,    REMAIN_CUM: 684,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 0,    REMAIN_CUM: 684,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 2,    REMAIN_CUM: 682,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 3,    REMAIN_CUM: 679,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 8,    REMAIN_CUM: 671,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 16,   REMAIN_CUM: 655,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 6,    REMAIN_CUM: 649,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 649,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 7,    REMAIN_CUM: 642,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 6,    REMAIN_CUM: 636,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 16,   REMAIN_CUM: 620,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 3,    REMAIN_CUM: 617,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 3,    REMAIN_CUM: 614,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 7,    REMAIN_CUM: 607,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 8,    REMAIN_CUM: 599,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'CWS',         SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 3,    REMAIN_CUM: 596,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 10,   REMAIN_CUM: 2473,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 13,   REMAIN_CUM: 2460,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 23,   REMAIN_CUM: 2437,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 46,   REMAIN_CUM: 2391,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 17,   REMAIN_CUM: 2374,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 9,    REMAIN_CUM: 2365,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 10,   REMAIN_CUM: 2355,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 13,   REMAIN_CUM: 2342,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 10,   REMAIN_CUM: 2332,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 12,   REMAIN_CUM: 2320,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 24,   REMAIN_CUM: 2296,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 32,   REMAIN_CUM: 2264,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 68,   REMAIN_CUM: 2196,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 55,   REMAIN_CUM: 2141,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 34,   REMAIN_CUM: 2107,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 28,   REMAIN_CUM: 2079,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 35,   REMAIN_CUM: 2044,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 40,   REMAIN_CUM: 2004,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 48,   REMAIN_CUM: 1956,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 66,   REMAIN_CUM: 1890,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 45,   REMAIN_CUM: 1845,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 15,   REMAIN_CUM: 1830,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 5,    REMAIN_CUM: 1825,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DAU',         SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 1,    REMAIN_CUM: 1824,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 55,   REMAIN_CUM: 8303,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 14,   REMAIN_CUM: 8289,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 16,   REMAIN_CUM: 8273,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 13,   REMAIN_CUM: 8260,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 21,   REMAIN_CUM: 8239,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 23,   REMAIN_CUM: 8216,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 30,   REMAIN_CUM: 8186,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 48,   REMAIN_CUM: 8138,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 13,   REMAIN_CUM: 8125,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 13,   REMAIN_CUM: 8112,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 14,   REMAIN_CUM: 8098,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 21,   REMAIN_CUM: 8077,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 24,   REMAIN_CUM: 8053,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 32,   REMAIN_CUM: 8021,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 32,   REMAIN_CUM: 7989,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 83,   REMAIN_CUM: 7906,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 45,   REMAIN_CUM: 7861,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 55,   REMAIN_CUM: 7806,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 25,   REMAIN_CUM: 7781,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 16,   REMAIN_CUM: 7765,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 21,   REMAIN_CUM: 7744,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 10,   REMAIN_CUM: 7734,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 35,   REMAIN_CUM: 7699,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S1',      SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 24,   REMAIN_CUM: 7675,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 5,    REMAIN_CUM: 2061,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 5,    REMAIN_CUM: 2056,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 8,    REMAIN_CUM: 2048,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 11,   REMAIN_CUM: 2037,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 9,    REMAIN_CUM: 2028,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 3,    REMAIN_CUM: 2025,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 9,    REMAIN_CUM: 2016,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 16,   REMAIN_CUM: 2000,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 21,   REMAIN_CUM: 1979,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 17,   REMAIN_CUM: 1962,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 4,    REMAIN_CUM: 1958,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 3,    REMAIN_CUM: 1955,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 8,    REMAIN_CUM: 1947,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 10,   REMAIN_CUM: 1937,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 11,   REMAIN_CUM: 1926,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 26,   REMAIN_CUM: 1900,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 12,   REMAIN_CUM: 1888,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 20,   REMAIN_CUM: 1868,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 10,   REMAIN_CUM: 1858,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 6,    REMAIN_CUM: 1852,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 1,    REMAIN_CUM: 1851,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 1,    REMAIN_CUM: 1850,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 9,    REMAIN_CUM: 1841,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DHU S2',      SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 10,   REMAIN_CUM: 1831,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 8,    REMAIN_CUM: 1254,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 1,    REMAIN_CUM: 1253,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 6,    REMAIN_CUM: 1247,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 3,    REMAIN_CUM: 1244,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 5,    REMAIN_CUM: 1239,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 3,    REMAIN_CUM: 1236,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 6,    REMAIN_CUM: 1230,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 7,    REMAIN_CUM: 1223,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 9,    REMAIN_CUM: 1214,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 9,    REMAIN_CUM: 1205,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 26,   REMAIN_CUM: 1179,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 18,   REMAIN_CUM: 1161,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 15,   REMAIN_CUM: 1146,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 6,    REMAIN_CUM: 1140,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 4,    REMAIN_CUM: 1136,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 5,    REMAIN_CUM: 1131,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 8,    REMAIN_CUM: 1123,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 13,   REMAIN_CUM: 1110,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 11,   REMAIN_CUM: 1099,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 10,   REMAIN_CUM: 1089,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 1,    REMAIN_CUM: 1088,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 1,    REMAIN_CUM: 1087,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 8,    REMAIN_CUM: 1079,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'DS',          SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 15,   REMAIN_CUM: 1064,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 154,   TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 154,   TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 10,   REMAIN_CUM: 144,   TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 3,    REMAIN_CUM: 141,   TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 2,    REMAIN_CUM: 139,   TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 6,    REMAIN_CUM: 133,   TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 16,   REMAIN_CUM: 117,   TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 4,    REMAIN_CUM: 113,   TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 9,    REMAIN_CUM: 104,   TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 22,   REMAIN_CUM: 82,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 2,    REMAIN_CUM: 80,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 1,    REMAIN_CUM: 79,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 0,    REMAIN_CUM: 79,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 0,    REMAIN_CUM: 79,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 12,   REMAIN_CUM: 67,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 10,   REMAIN_CUM: 57,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 2,    REMAIN_CUM: 55,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 55,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 12,   REMAIN_CUM: 43,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 43,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 0,    REMAIN_CUM: 43,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 43,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 2,    REMAIN_CUM: 41,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSV',     SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 41,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 115,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 115,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 0,    REMAIN_CUM: 115,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 115,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 2,    REMAIN_CUM: 113,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 0,    REMAIN_CUM: 113,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 1,    REMAIN_CUM: 112,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 0,    REMAIN_CUM: 112,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 0,    REMAIN_CUM: 112,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 5,    REMAIN_CUM: 107,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 0,    REMAIN_CUM: 107,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 15,   REMAIN_CUM: 92,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 30,   REMAIN_CUM: 62,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 0,    REMAIN_CUM: 62,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 0,    REMAIN_CUM: 62,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 62,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 0,    REMAIN_CUM: 62,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 62,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 11,   REMAIN_CUM: 51,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'ELS SSW',     SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 98,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 98,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 0,    REMAIN_CUM: 98,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 98,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 1,    REMAIN_CUM: 97,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 1,    REMAIN_CUM: 96,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 0,    REMAIN_CUM: 96,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 0,    REMAIN_CUM: 96,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 0,    REMAIN_CUM: 96,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 0,    REMAIN_CUM: 96,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 0,    REMAIN_CUM: 96,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 4,    REMAIN_CUM: 92,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 0,    REMAIN_CUM: 92,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 0,    REMAIN_CUM: 92,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 0,    REMAIN_CUM: 92,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 92,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 1,    REMAIN_CUM: 91,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 4,    REMAIN_CUM: 87,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 0,    REMAIN_CUM: 87,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 87,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 0,    REMAIN_CUM: 87,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 2,    REMAIN_CUM: 85,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 0,    REMAIN_CUM: 85,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'GENERAL',     SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 85,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 2,    REMAIN_CUM: 1416,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 1416,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 1,    REMAIN_CUM: 1415,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 1,    REMAIN_CUM: 1414,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 11,   REMAIN_CUM: 1403,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 4,    REMAIN_CUM: 1399,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 2,    REMAIN_CUM: 1397,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 5,    REMAIN_CUM: 1392,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 6,    REMAIN_CUM: 1386,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 5,    REMAIN_CUM: 1381,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 13,   REMAIN_CUM: 1368,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 16,   REMAIN_CUM: 1352,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 23,   REMAIN_CUM: 1329,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 16,   REMAIN_CUM: 1313,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 33,   REMAIN_CUM: 1280,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 50,   REMAIN_CUM: 1230,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 36,   REMAIN_CUM: 1194,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 59,   REMAIN_CUM: 1135,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 27,   REMAIN_CUM: 1108,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 9,    REMAIN_CUM: 1099,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 7,    REMAIN_CUM: 1092,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 1,    REMAIN_CUM: 1091,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 1,    REMAIN_CUM: 1090,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HOS',         SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 1,    REMAIN_CUM: 1089,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 537,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 537,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 0,    REMAIN_CUM: 537,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 537,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 0,    REMAIN_CUM: 537,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 0,    REMAIN_CUM: 537,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 6,    REMAIN_CUM: 531,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 25,   REMAIN_CUM: 506,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 10,   REMAIN_CUM: 496,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 20,   REMAIN_CUM: 476,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 32,   REMAIN_CUM: 444,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 44,   REMAIN_CUM: 400,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 75,   REMAIN_CUM: 325,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 4,    REMAIN_CUM: 321,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 0,    REMAIN_CUM: 321,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 1,    REMAIN_CUM: 320,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 1,    REMAIN_CUM: 319,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 319,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 0,    REMAIN_CUM: 319,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 319,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 0,    REMAIN_CUM: 319,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 319,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 0,    REMAIN_CUM: 319,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'HSFOC',       SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 4,    REMAIN_CUM: 315,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 78,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 78,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 0,    REMAIN_CUM: 78,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 78,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 0,    REMAIN_CUM: 78,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 5,    REMAIN_CUM: 73,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 5,    REMAIN_CUM: 68,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 0,    REMAIN_CUM: 68,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 0,    REMAIN_CUM: 68,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 9,    REMAIN_CUM: 59,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 8,    REMAIN_CUM: 51,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 10,   REMAIN_CUM: 41,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 0,    REMAIN_CUM: 41,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 1,    REMAIN_CUM: 40,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 0,    REMAIN_CUM: 40,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 40,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 0,    REMAIN_CUM: 40,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 40,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 1,    REMAIN_CUM: 39,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 39,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 3,    REMAIN_CUM: 36,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 01', SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 36,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 81,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 81,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 0,    REMAIN_CUM: 81,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 81,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 11,   REMAIN_CUM: 70,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 0,    REMAIN_CUM: 70,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 7,    REMAIN_CUM: 63,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 0,    REMAIN_CUM: 63,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 0,    REMAIN_CUM: 63,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 6,    REMAIN_CUM: 57,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 9,    REMAIN_CUM: 48,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 0,    REMAIN_CUM: 48,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 0,    REMAIN_CUM: 48,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 13,   REMAIN_CUM: 35,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 0,    REMAIN_CUM: 35,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 5,    REMAIN_CUM: 30,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 0,    REMAIN_CUM: 30,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 30,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 0,    REMAIN_CUM: 30,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 1,    REMAIN_CUM: 29,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 0,    REMAIN_CUM: 29,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 29,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 3,    REMAIN_CUM: 26,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'I&CS RIE 02', SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 26,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 1,    REMAIN_CUM: 945,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 1,    REMAIN_CUM: 944,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 0,    REMAIN_CUM: 944,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 944,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 0,    REMAIN_CUM: 944,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 0,    REMAIN_CUM: 944,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 1,    REMAIN_CUM: 943,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 0,    REMAIN_CUM: 943,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 1,    REMAIN_CUM: 942,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 2,    REMAIN_CUM: 940,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 2,    REMAIN_CUM: 938,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 0,    REMAIN_CUM: 938,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 5,    REMAIN_CUM: 933,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 1,    REMAIN_CUM: 932,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 0,    REMAIN_CUM: 932,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 932,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 0,    REMAIN_CUM: 932,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 6,    REMAIN_CUM: 926,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 5,    REMAIN_CUM: 921,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 9,    REMAIN_CUM: 912,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 2,    REMAIN_CUM: 910,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 910,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 0,    REMAIN_CUM: 910,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKN',    SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 910,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 1,    REMAIN_CUM: 245,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 1,    REMAIN_CUM: 244,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 0,    REMAIN_CUM: 244,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 244,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 0,    REMAIN_CUM: 244,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 0,    REMAIN_CUM: 244,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 0,    REMAIN_CUM: 244,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 0,    REMAIN_CUM: 244,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 1,    REMAIN_CUM: 243,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 0,    REMAIN_CUM: 243,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 0,    REMAIN_CUM: 243,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 1,    REMAIN_CUM: 242,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 1,    REMAIN_CUM: 241,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 0,    REMAIN_CUM: 241,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 1,    REMAIN_CUM: 240,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 1,    REMAIN_CUM: 239,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 1,    REMAIN_CUM: 238,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 238,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 2,    REMAIN_CUM: 236,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 3,    REMAIN_CUM: 233,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 0,    REMAIN_CUM: 233,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 233,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 0,    REMAIN_CUM: 233,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OM&S-TKS',    SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 233,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 188,  REMAIN_CUM: 28981, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 119,  REMAIN_CUM: 28862, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 167,  REMAIN_CUM: 28695, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 201,  REMAIN_CUM: 28494, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 245,  REMAIN_CUM: 28249, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 108,  REMAIN_CUM: 28141, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 140,  REMAIN_CUM: 28001, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 190,  REMAIN_CUM: 27811, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 142,  REMAIN_CUM: 27669, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 157,  REMAIN_CUM: 27512, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 196,  REMAIN_CUM: 27316, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 217,  REMAIN_CUM: 27099, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 307,  REMAIN_CUM: 26792, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 292,  REMAIN_CUM: 26500, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 182,  REMAIN_CUM: 26318, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 291,  REMAIN_CUM: 26027, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 203,  REMAIN_CUM: 25824, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 296,  REMAIN_CUM: 25528, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 293,  REMAIN_CUM: 25235, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 251,  REMAIN_CUM: 24984, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 262,  REMAIN_CUM: 24722, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 60,   REMAIN_CUM: 24662, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 106,  REMAIN_CUM: 24556, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'OV',          SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 85,   REMAIN_CUM: 24471, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 4,    REMAIN_CUM: 555,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 1,    REMAIN_CUM: 554,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 2,    REMAIN_CUM: 552,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 2,    REMAIN_CUM: 550,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 2,    REMAIN_CUM: 548,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 4,    REMAIN_CUM: 544,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 2,    REMAIN_CUM: 542,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 2,    REMAIN_CUM: 540,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 1,    REMAIN_CUM: 539,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 1,    REMAIN_CUM: 538,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 5,    REMAIN_CUM: 533,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 2,    REMAIN_CUM: 531,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 0,    REMAIN_CUM: 531,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 1,    REMAIN_CUM: 530,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 1,    REMAIN_CUM: 529,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 3,    REMAIN_CUM: 526,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 4,    REMAIN_CUM: 522,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 18,   REMAIN_CUM: 504,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 36,   REMAIN_CUM: 468,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 46,   REMAIN_CUM: 422,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 47,   REMAIN_CUM: 375,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 375,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 0,    REMAIN_CUM: 375,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'PFBS',        SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 375,   TOTAL: 599, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 24,   REMAIN_CUM: 1231,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 22,   REMAIN_CUM: 1209,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 16,   REMAIN_CUM: 1193,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 2,    REMAIN_CUM: 1191,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 0,    REMAIN_CUM: 1191,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 0,    REMAIN_CUM: 1191,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 3,    REMAIN_CUM: 1188,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 0,    REMAIN_CUM: 1188,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 0,    REMAIN_CUM: 1188,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 0,    REMAIN_CUM: 1188,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 1,    REMAIN_CUM: 1187,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 4,    REMAIN_CUM: 1183,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 0,    REMAIN_CUM: 1183,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 0,    REMAIN_CUM: 1183,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 0,    REMAIN_CUM: 1183,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 1183,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 0,    REMAIN_CUM: 1183,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 1183,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 2,    REMAIN_CUM: 1181,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 1181,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 0,    REMAIN_CUM: 1181,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 1181,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 3,    REMAIN_CUM: 1178,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SRU',         SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 1178,  TOTAL: 2225, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 9,    REMAIN_CUM: 384,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 6,    REMAIN_CUM: 378,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 20,   REMAIN_CUM: 358,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 19,   REMAIN_CUM: 339,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 20,   REMAIN_CUM: 319,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 6,    REMAIN_CUM: 313,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 0,    REMAIN_CUM: 313,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 1,    REMAIN_CUM: 312,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 3,    REMAIN_CUM: 309,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 1,    REMAIN_CUM: 308,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 2,    REMAIN_CUM: 306,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 1,    REMAIN_CUM: 305,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 2,    REMAIN_CUM: 303,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 1,    REMAIN_CUM: 302,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 0,    REMAIN_CUM: 302,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 302,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 1,    REMAIN_CUM: 301,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 301,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 0,    REMAIN_CUM: 301,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 301,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 2,    REMAIN_CUM: 299,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 299,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 2,    REMAIN_CUM: 297,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'SWS',         SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 297,   TOTAL: 603, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 51,   REMAIN_CUM: 2642,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 35,   REMAIN_CUM: 2607,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 31,   REMAIN_CUM: 2576,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 36,   REMAIN_CUM: 2540,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 63,   REMAIN_CUM: 2477,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 31,   REMAIN_CUM: 2446,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 30,   REMAIN_CUM: 2416,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 55,   REMAIN_CUM: 2361,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 38,   REMAIN_CUM: 2323,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 31,   REMAIN_CUM: 2292,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 44,   REMAIN_CUM: 2248,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 48,   REMAIN_CUM: 2200,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 44,   REMAIN_CUM: 2156,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 126,  REMAIN_CUM: 2030,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 42,   REMAIN_CUM: 1988,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 55,   REMAIN_CUM: 1933,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 48,   REMAIN_CUM: 1885,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 67,   REMAIN_CUM: 1818,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 54,   REMAIN_CUM: 1764,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 30,   REMAIN_CUM: 1734,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 40,   REMAIN_CUM: 1694,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 8,    REMAIN_CUM: 1686,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 10,   REMAIN_CUM: 1676,  TOTAL: 4429, },
    { ITEM: 'B', UNIT: 'U&O',         SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 15,   REMAIN_CUM: 1661,  TOTAL: 4429, },
    
    ],

  },
}