
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 1000,
    CanvasBorderWeight: 0.2,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  


  DataItems: [
    { MS_NO: 'WP-10', CUTOFF: '2023-10-31', FORECAST: '2023-09-29', STATUS: 'togo', },
    { MS_NO: 'WP-10', CUTOFF: '2023-11-31', FORECAST: '2023-09-29', STATUS: 'completed', },
    { MS_NO: 'WP-15', CUTOFF: '2023-10-31', FORECAST: '2024-02-13', STATUS: 'togo', },
    { MS_NO: 'WP-15', CUTOFF: '2023-11-31', FORECAST: '2024-02-13', STATUS: 'togo', },
    { MS_NO: 'WP-18', CUTOFF: '2023-10-31', FORECAST: '2024-06-14', STATUS: 'togo', },
    { MS_NO: 'WP-18', CUTOFF: '2023-11-31', FORECAST: '2024-06-14', STATUS: 'togo', },
    { MS_NO: 'WP-19', CUTOFF: '2023-10-31', FORECAST: '2024-09-07', STATUS: 'togo', },
    { MS_NO: 'WP-19', CUTOFF: '2023-11-31', FORECAST: '2024-09-07', STATUS: 'togo', },
    { MS_NO: 'WP-24', CUTOFF: '2023-10-31', FORECAST: '2024-09-26', STATUS: 'togo', },
    { MS_NO: 'WP-24', CUTOFF: '2023-11-31', FORECAST: '2024-09-26', STATUS: 'togo', },
    { MS_NO: 'WP-25', CUTOFF: '2023-10-31', FORECAST: '2024-10-11', STATUS: 'togo', },
    { MS_NO: 'WP-25', CUTOFF: '2023-11-31', FORECAST: '2024-10-11', STATUS: 'togo', },
    { MS_NO: 'WP-23', CUTOFF: '2023-10-31', FORECAST: '2024-10-30', STATUS: 'togo', },
    { MS_NO: 'WP-23', CUTOFF: '2023-11-31', FORECAST: '2024-10-30', STATUS: 'togo', },
    { MS_NO: 'WP-26', CUTOFF: '2023-10-31', FORECAST: '2024-10-30', STATUS: 'togo', },
    { MS_NO: 'WP-26', CUTOFF: '2023-11-31', FORECAST: '2024-10-30', STATUS: 'togo', },
    { MS_NO: 'WP-28', CUTOFF: '2023-10-31', FORECAST: '2024-12-10', STATUS: 'togo', },
    { MS_NO: 'WP-28', CUTOFF: '2023-11-31', FORECAST: '2024-12-10', STATUS: 'togo', },
    { MS_NO: 'WP-31', CUTOFF: '2023-10-31', FORECAST: '2025-01-13', STATUS: 'togo', },
    { MS_NO: 'WP-31', CUTOFF: '2023-11-31', FORECAST: '2025-01-13', STATUS: 'togo', },
    { MS_NO: 'WP-34', CUTOFF: '2023-10-31', FORECAST: '2025-04-01', STATUS: 'togo', },
    { MS_NO: 'WP-34', CUTOFF: '2023-11-31', FORECAST: '2025-04-01', STATUS: 'togo', },
    { MS_NO: 'MS-2',  CUTOFF: '2023-10-31', FORECAST: '2024-02-27', STATUS: 'togo', },
    { MS_NO: 'MS-2',  CUTOFF: '2023-11-31', FORECAST: '2023-12-07', STATUS: 'togo', },
    { MS_NO: 'MS-3',  CUTOFF: '2023-10-31', FORECAST: '2024-01-17', STATUS: 'togo', },
    { MS_NO: 'MS-3',  CUTOFF: '2023-11-31', FORECAST: '2023-12-07', STATUS: 'togo', },
    { MS_NO: 'MS-4',  CUTOFF: '2023-10-31', FORECAST: '2024-03-05', STATUS: 'togo', },
    { MS_NO: 'MS-4',  CUTOFF: '2023-11-31', FORECAST: '2024-03-08', STATUS: 'togo', },
    { MS_NO: 'MS-5',  CUTOFF: '2023-10-31', FORECAST: '2024-08-08', STATUS: 'togo', },
    { MS_NO: 'MS-5',  CUTOFF: '2023-11-31', FORECAST: '2024-06-27', STATUS: 'togo', },
    { MS_NO: 'MS-6',  CUTOFF: '2023-10-31', FORECAST: '2024-08-22', STATUS: 'togo', },
    { MS_NO: 'MS-6',  CUTOFF: '2023-11-31', FORECAST: '2024-08-17', STATUS: 'togo', },
    { MS_NO: 'MS-7',  CUTOFF: '2023-10-31', FORECAST: '2024-09-19', STATUS: 'togo', },
    { MS_NO: 'MS-7',  CUTOFF: '2023-11-31', FORECAST: '2024-06-16', STATUS: 'togo', },
    { MS_NO: 'MS-8',  CUTOFF: '2023-10-31', FORECAST: '2024-08-06', STATUS: 'togo', },
    { MS_NO: 'MS-8',  CUTOFF: '2023-11-31', FORECAST: '2024-08-24', STATUS: 'togo', },
    { MS_NO: 'MS-9',  CUTOFF: '2023-10-31', FORECAST: '2024-08-14', STATUS: 'togo', },
    { MS_NO: 'MS-9',  CUTOFF: '2023-11-31', FORECAST: '2024-09-07', STATUS: 'togo', },
    { MS_NO: 'MS-10', CUTOFF: '2023-10-31', FORECAST: '2024-08-17', STATUS: 'togo', },
    { MS_NO: 'MS-10', CUTOFF: '2023-11-31', FORECAST: '2024-08-26', STATUS: 'togo', },
    { MS_NO: 'MS-11', CUTOFF: '2023-10-31', FORECAST: '2024-09-18', STATUS: 'togo', },
    { MS_NO: 'MS-11', CUTOFF: '2023-11-31', FORECAST: '2024-09-24', STATUS: 'togo', },
    { MS_NO: 'MS-12', CUTOFF: '2023-10-31', FORECAST: '2024-06-06', STATUS: 'togo', },
    { MS_NO: 'MS-12', CUTOFF: '2023-11-31', FORECAST: '2024-06-10', STATUS: 'togo', },
    { MS_NO: 'MS-13', CUTOFF: '2023-10-31', FORECAST: '2024-08-26', STATUS: 'togo', },
    { MS_NO: 'MS-13', CUTOFF: '2023-11-31', FORECAST: '2024-08-19', STATUS: 'togo', },
    { MS_NO: 'MS-14', CUTOFF: '2023-10-31', FORECAST: '2024-08-24', STATUS: 'togo', },
    { MS_NO: 'MS-14', CUTOFF: '2023-11-31', FORECAST: '2024-09-06', STATUS: 'togo', },
    { MS_NO: 'MS-15', CUTOFF: '2023-10-31', FORECAST: '2024-07-18', STATUS: 'togo', },
    { MS_NO: 'MS-15', CUTOFF: '2023-11-31', FORECAST: '2024-06-14', STATUS: 'togo', },
    { MS_NO: 'MS-16', CUTOFF: '2023-10-31', FORECAST: '2024-09-26', STATUS: 'togo', },
    { MS_NO: 'MS-16', CUTOFF: '2023-11-31', FORECAST: '2024-09-28', STATUS: 'togo', },
    { MS_NO: 'MS-17', CUTOFF: '2023-10-31', FORECAST: '2024-09-24', STATUS: 'togo', },
    { MS_NO: 'MS-17', CUTOFF: '2023-11-31', FORECAST: '2024-10-24', STATUS: 'togo', },
    { MS_NO: 'MS-18', CUTOFF: '2023-10-31', FORECAST: '2024-10-09', STATUS: 'togo', },
    { MS_NO: 'MS-18', CUTOFF: '2023-11-31', FORECAST: '2024-10-06', STATUS: 'togo', },
    { MS_NO: 'MS-19', CUTOFF: '2023-10-31', FORECAST: '2024-11-28', STATUS: 'togo', },
    { MS_NO: 'MS-19', CUTOFF: '2023-11-31', FORECAST: '2024-11-23', STATUS: 'togo', },
    { MS_NO: 'MS-20', CUTOFF: '2023-10-31', FORECAST: '2024-12-02', STATUS: 'togo', },
    { MS_NO: 'MS-20', CUTOFF: '2023-11-31', FORECAST: '2024-11-28', STATUS: 'togo', },
    { MS_NO: 'MS-21', CUTOFF: '2023-10-31', FORECAST: '2024-10-13', STATUS: 'togo', },
    { MS_NO: 'MS-21', CUTOFF: '2023-11-31', FORECAST: '2024-10-07', STATUS: 'togo', },
    { MS_NO: 'MS-22', CUTOFF: '2023-10-31', FORECAST: '2024-10-26', STATUS: 'togo', },
    { MS_NO: 'MS-22', CUTOFF: '2023-11-31', FORECAST: '2024-10-26', STATUS: 'togo', },
    { MS_NO: 'MS-23', CUTOFF: '2023-10-31', FORECAST: '2024-12-01', STATUS: 'togo', },
    { MS_NO: 'MS-23', CUTOFF: '2023-11-31', FORECAST: '2024-10-05', STATUS: 'togo', },
    { MS_NO: 'MS-24', CUTOFF: '2023-10-31', FORECAST: '2024-11-05', STATUS: 'togo', },
    { MS_NO: 'MS-24', CUTOFF: '2023-11-31', FORECAST: '2024-10-29', STATUS: 'togo', },
    { MS_NO: 'MS-25', CUTOFF: '2023-10-31', FORECAST: '2025-01-09', STATUS: 'togo', },
    { MS_NO: 'MS-25', CUTOFF: '2023-11-31', FORECAST: '2024-12-28', STATUS: 'togo', },
    { MS_NO: 'MS-26', CUTOFF: '2023-10-31', FORECAST: '2024-12-10', STATUS: 'togo', },
    { MS_NO: 'MS-26', CUTOFF: '2023-11-31', FORECAST: '2024-11-15', STATUS: 'togo', },
    { MS_NO: 'MS-27', CUTOFF: '2023-10-31', FORECAST: '2024-12-15', STATUS: 'togo', },
    { MS_NO: 'MS-27', CUTOFF: '2023-11-31', FORECAST: '2024-12-02', STATUS: 'togo', },
    { MS_NO: 'MS-28', CUTOFF: '2023-10-31', FORECAST: '2024-12-20', STATUS: 'togo', },
    { MS_NO: 'MS-28', CUTOFF: '2023-11-31', FORECAST: '2024-11-24', STATUS: 'togo', },
    { MS_NO: 'MS-29', CUTOFF: '2023-10-31', FORECAST: '2025-01-25', STATUS: 'togo', },
    { MS_NO: 'MS-29', CUTOFF: '2023-11-31', FORECAST: '2025-01-19', STATUS: 'togo', },
    { MS_NO: 'MS-30', CUTOFF: '2023-10-31', FORECAST: '2025-02-08', STATUS: 'togo', },
    { MS_NO: 'MS-30', CUTOFF: '2023-11-31', FORECAST: '2025-02-05', STATUS: 'togo', },
    { MS_NO: 'MS-31', CUTOFF: '2023-10-31', FORECAST: '2025-02-08', STATUS: 'togo', },
    { MS_NO: 'MS-31', CUTOFF: '2023-11-31', FORECAST: '2025-02-08', STATUS: 'togo', },
    { MS_NO: 'MS-32', CUTOFF: '2023-10-31', FORECAST: '2025-01-12', STATUS: 'togo', },
    { MS_NO: 'MS-32', CUTOFF: '2023-11-31', FORECAST: '2025-01-12', STATUS: 'togo', },
    { MS_NO: 'MS-33', CUTOFF: '2023-10-31', FORECAST: '2024-10-24', STATUS: 'togo', },
    { MS_NO: 'MS-33', CUTOFF: '2023-11-31', FORECAST: '2024-12-31', STATUS: 'togo', },
    { MS_NO: 'MS-34', CUTOFF: '2023-10-31', FORECAST: '2025-02-03', STATUS: 'togo', },
    { MS_NO: 'MS-34', CUTOFF: '2023-11-31', FORECAST: '2025-01-21', STATUS: 'togo', },
    { MS_NO: 'MS-35', CUTOFF: '2023-10-31', FORECAST: '2025-01-27', STATUS: 'togo', },
    { MS_NO: 'MS-35', CUTOFF: '2023-11-31', FORECAST: '2024-12-06', STATUS: 'togo', },
    { MS_NO: 'MS-36', CUTOFF: '2023-10-31', FORECAST: '2024-11-18', STATUS: 'togo', },
    { MS_NO: 'MS-36', CUTOFF: '2023-11-31', FORECAST: '2024-10-08', STATUS: 'togo', },
    { MS_NO: 'MS-37', CUTOFF: '2023-10-31', FORECAST: '2025-02-07', STATUS: 'togo', },
    { MS_NO: 'MS-37', CUTOFF: '2023-11-31', FORECAST: '2025-01-12', STATUS: 'togo', },
  ],

  Queries: {
    
    SQL1: [
      { MS_TYPE: 'WAYPOINT', MS_NO: 'WP-25', MS_DESC: 'Make Up and Recycle Gas Compressors Ready for Commissioning',   DISC: 'NA', OWNER: 'IPT',  A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NONE',       AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NONE',       B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NONE',       BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NONE',       C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   CP_STATUS: 'NONE',       MS_PLAN: '2024-10-11',  MS_FORECAST: '2024-10-11', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'WAYPOINT', MS_NO: 'WP-31', MS_DESC: '1st Hydrogen on Pitch Feed Received',                           DISC: 'NA', OWNER: 'SUT',  A_TOT: 6601,  A_ACT: 2295,  A_REM: 4306,  A_PRO: 34, A_STATUS: 'INPROGRESS', AP_TOT: 7,   AP_ACT: 0,   AP_REM: 7,   AP_PRO: 0,   AP_STATUS: 'NOTSTARTED', B_TOT: 1414,  B_ACT: 582,  B_REM: 832,   B_PRO: 41,  B_STATUS: 'INPROGRESS', BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,  BP_STATUS: 'NOTSTARTED', C_TOT: 171,  C_ACT: 0, C_REM: 171,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 7,   CP_ACT: 0,   CP_REM: 7,  CP_PRO: 0,   CP_STATUS: 'NOTSTARTED', MS_PLAN: '2025-01-13',  MS_FORECAST: '2025-01-13', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'WAYPOINT', MS_NO: 'WP-10', MS_DESC: 'Substation V Energized',                                        DISC: 'NA', OWNER: 'ISCT', A_TOT: 744,   A_ACT: 583,   A_REM: 161,   A_PRO: 78, A_STATUS: 'INPROGRESS', AP_TOT: 308, AP_ACT: 281, AP_REM: 27,  AP_PRO: 91,  AP_STATUS: 'INPROGRESS', B_TOT: 152,   B_ACT: 103,  B_REM: 49,    B_PRO: 67,  B_STATUS: 'INPROGRESS', BP_TOT: 248, BP_ACT: 203, BP_REM: 45,  BP_PRO: 81, BP_STATUS: 'INPROGRESS', C_TOT: 34,   C_ACT: 0, C_REM: 34,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 353, CP_ACT: 279, CP_REM: 74, CP_PRO: 79,  CP_STATUS: 'INPROGRESS', MS_PLAN: '2023-09-29',  MS_FORECAST: '2023-09-29', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'WAYPOINT', MS_NO: 'WP-18', MS_DESC: '1st Reactor Loaded',                                            DISC: 'NA', OWNER: 'SUT',  A_TOT: 677,   A_ACT: 45,    A_REM: 632,   A_PRO: 6,  A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NONE',       B_TOT: 186,   B_ACT: 4,    B_REM: 182,   B_PRO: 2,   B_STATUS: 'INPROGRESS', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NONE',       C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   CP_STATUS: 'NONE',       MS_PLAN: '2024-06-14',  MS_FORECAST: '2024-06-14', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'WAYPOINT', MS_NO: 'WP-23', MS_DESC: 'Completion of all Existing Site Scope',                         DISC: 'NA', OWNER: 'IPT',  A_TOT: 45,    A_ACT: 30,    A_REM: 15,    A_PRO: 66, A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, AP_STATUS: 'COMPLETED',  B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NONE',       BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NONE',       C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 5,   CP_ACT: 5,   CP_REM: 0,  CP_PRO: 100, CP_STATUS: 'COMPLETED',  MS_PLAN: '2024-10-30',  MS_FORECAST: '2024-10-30', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'WAYPOINT', MS_NO: 'WP-24', MS_DESC: 'Flare System Live',                                             DISC: 'NA', OWNER: 'SUT',  A_TOT: 2178,  A_ACT: 655,   A_REM: 1523,  A_PRO: 30, A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,   AP_REM: 3,   AP_PRO: 0,   AP_STATUS: 'NOTSTARTED', B_TOT: 920,   B_ACT: 292,  B_REM: 628,   B_PRO: 31,  B_STATUS: 'INPROGRESS', BP_TOT: 3,   BP_ACT: 0,   BP_REM: 3,   BP_PRO: 0,  BP_STATUS: 'NOTSTARTED', C_TOT: 8,    C_ACT: 0, C_REM: 8,    C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,  CP_PRO: 0,   CP_STATUS: 'NOTSTARTED', MS_PLAN: '2024-09-26',  MS_FORECAST: '2024-09-26', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'WAYPOINT', MS_NO: 'WP-26', MS_DESC: 'All Grassroots Facilities Turned Over',                         DISC: 'NA', OWNER: 'IPT',  A_TOT: 3861,  A_ACT: 1430,  A_REM: 2431,  A_PRO: 37, A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,   AP_REM: 3,   AP_PRO: 0,   AP_STATUS: 'NOTSTARTED', B_TOT: 442,   B_ACT: 112,  B_REM: 330,   B_PRO: 25,  B_STATUS: 'INPROGRESS', BP_TOT: 10,  BP_ACT: 2,   BP_REM: 8,   BP_PRO: 20, BP_STATUS: 'INPROGRESS', C_TOT: 2,    C_ACT: 0, C_REM: 2,    C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 3,   CP_ACT: 0,   CP_REM: 3,  CP_PRO: 0,   CP_STATUS: 'NOTSTARTED', MS_PLAN: '2024-10-30',  MS_FORECAST: '2024-10-30', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'WAYPOINT', MS_NO: 'WP-28', MS_DESC: 'Product Tanks & Inter-connecting Lines Commissioned',           DISC: 'NA', OWNER: 'ISCT', A_TOT: 3805,  A_ACT: 632,   A_REM: 3173,  A_PRO: 16, A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,   AP_REM: 3,   AP_PRO: 0,   AP_STATUS: 'NOTSTARTED', B_TOT: 1422,  B_ACT: 347,  B_REM: 1075,  B_PRO: 24,  B_STATUS: 'INPROGRESS', BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,  BP_STATUS: 'NOTSTARTED', C_TOT: 25,   C_ACT: 0, C_REM: 25,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0,   CP_STATUS: 'NOTSTARTED', MS_PLAN: '2024-12-10',  MS_FORECAST: '2024-12-10', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'WAYPOINT', MS_NO: 'WP-15', MS_DESC: 'Controls System Ready for Loop Checks',                         DISC: 'NA', OWNER: 'ISCT', A_TOT: 673,   A_ACT: 397,   A_REM: 276,   A_PRO: 59, A_STATUS: 'INPROGRESS', AP_TOT: 151, AP_ACT: 103, AP_REM: 48,  AP_PRO: 68,  AP_STATUS: 'INPROGRESS', B_TOT: 278,   B_ACT: 147,  B_REM: 131,   B_PRO: 52,  B_STATUS: 'INPROGRESS', BP_TOT: 234, BP_ACT: 182, BP_REM: 52,  BP_PRO: 77, BP_STATUS: 'INPROGRESS', C_TOT: 51,   C_ACT: 0, C_REM: 51,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 170, CP_ACT: 81,  CP_REM: 89, CP_PRO: 47,  CP_STATUS: 'INPROGRESS', MS_PLAN: '2024-02-13',  MS_FORECAST: '2024-02-13', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'WAYPOINT', MS_NO: 'WP-19', MS_DESC: 'Essential Utilities in Service',                                DISC: 'NA', OWNER: 'ISCT', A_TOT: 8532,  A_ACT: 4095,  A_REM: 4437,  A_PRO: 48, A_STATUS: 'INPROGRESS', AP_TOT: 29,  AP_ACT: 3,   AP_REM: 26,  AP_PRO: 10,  AP_STATUS: 'INPROGRESS', B_TOT: 4648,  B_ACT: 2502, B_REM: 2146,  B_PRO: 53,  B_STATUS: 'INPROGRESS', BP_TOT: 68,  BP_ACT: 12,  BP_REM: 56,  BP_PRO: 17, BP_STATUS: 'INPROGRESS', C_TOT: 120,  C_ACT: 0, C_REM: 120,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 43,  CP_ACT: 26,  CP_REM: 17, CP_PRO: 60,  CP_STATUS: 'INPROGRESS', MS_PLAN: '2024-09-07',  MS_FORECAST: '2024-09-07', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'WAYPOINT', MS_NO: 'WP-34', MS_DESC: 'Units at Design Rates with All Products on Spec',               DISC: 'NA', OWNER: 'SUT',  A_TOT: 72379, A_ACT: 16489, A_REM: 55890, A_PRO: 22, A_STATUS: 'INPROGRESS', AP_TOT: 136, AP_ACT: 10,  AP_REM: 126, AP_PRO: 7,   AP_STATUS: 'INPROGRESS', B_TOT: 24007, B_ACT: 5525, B_REM: 18482, B_PRO: 23,  B_STATUS: 'INPROGRESS', BP_TOT: 310, BP_ACT: 79,  BP_REM: 231, BP_PRO: 25, BP_STATUS: 'INPROGRESS', C_TOT: 518,  C_ACT: 0, C_REM: 518,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 143, CP_ACT: 50,  CP_REM: 93, CP_PRO: 35,  CP_STATUS: 'INPROGRESS', MS_PLAN: '2025-04-01',  MS_FORECAST: '2025-04-01', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-15', MS_DESC: 'SRU Catalyst Loaded',                                           DISC: 'NA', OWNER: 'SUT',  A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NONE',       AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NONE',       B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NONE',       BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NONE',       C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   CP_STATUS: 'NONE',       MS_PLAN: '2024-07-18', MS_FORECAST: '2024-06-14', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-24', MS_DESC: 'Makeup & Recycle Gas Compressor N2 String Test Complete (DHU)', DISC: 'NA', OWNER: 'ISCT', A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NONE',       AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NONE',       B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NONE',       BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NONE',       C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   CP_STATUS: 'NONE',       MS_PLAN: '2024-11-05', MS_FORECAST: '2024-10-29', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-8',  MS_DESC: 'Cooling Water in Service',                                      DISC: 'NA', OWNER: 'ISCT', A_TOT: 819,   A_ACT: 249,   A_REM: 570,   A_PRO: 30, A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,   AP_REM: 1,   AP_PRO: 0,   AP_STATUS: 'NOTSTARTED', B_TOT: 147,   B_ACT: 38,   B_REM: 109,   B_PRO: 25,  B_STATUS: 'INPROGRESS', BP_TOT: 7,   BP_ACT: 1,   BP_REM: 6,   BP_PRO: 14, BP_STATUS: 'INPROGRESS', C_TOT: 2,    C_ACT: 0, C_REM: 2,    C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 14,  CP_ACT: 1,   CP_REM: 13, CP_PRO: 7,   CP_STATUS: 'INPROGRESS', MS_PLAN: '2024-08-06', MS_FORECAST: '2024-08-24', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-2',  MS_DESC: 'RIE1 Ready for Loop Check',                                     DISC: 'NA', OWNER: 'ISCT', A_TOT: 586,   A_ACT: 380,   A_REM: 206,   A_PRO: 64, A_STATUS: 'INPROGRESS', AP_TOT: 131, AP_ACT: 93,  AP_REM: 38,  AP_PRO: 71,  AP_STATUS: 'INPROGRESS', B_TOT: 198,   B_ACT: 96,   B_REM: 102,   B_PRO: 48,  B_STATUS: 'INPROGRESS', BP_TOT: 203, BP_ACT: 178, BP_REM: 25,  BP_PRO: 87, BP_STATUS: 'INPROGRESS', C_TOT: 41,   C_ACT: 0, C_REM: 41,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 160, CP_ACT: 81,  CP_REM: 79, CP_PRO: 50,  CP_STATUS: 'INPROGRESS', MS_PLAN: '2024-02-27', MS_FORECAST: '2023-12-07', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-20', MS_DESC: 'ARU Circulation',                                               DISC: 'NA', OWNER: 'ISCT', A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NONE',       AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NONE',       B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NONE',       BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NONE',       C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   CP_STATUS: 'NONE',       MS_PLAN: '2024-12-02', MS_FORECAST: '2024-11-28', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-23', MS_DESC: 'DAU Feed Tank in Service',                                      DISC: 'NA', OWNER: 'SUT',  A_TOT: 855,   A_ACT: 481,   A_REM: 374,   A_PRO: 56, A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 1,   AP_REM: 1,   AP_PRO: 50,  AP_STATUS: 'INPROGRESS', B_TOT: 209,   B_ACT: 125,  B_REM: 84,    B_PRO: 59,  B_STATUS: 'INPROGRESS', BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,  BP_STATUS: 'NOTSTARTED', C_TOT: 123,  C_ACT: 0, C_REM: 123,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 2,   CP_ACT: 1,   CP_REM: 1,  CP_PRO: 50,  CP_STATUS: 'INPROGRESS', MS_PLAN: '2024-12-01', MS_FORECAST: '2024-10-05', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-31', MS_DESC: 'GR EHC 340 Max Lubes System Cleaning Complete',                 DISC: 'NA', OWNER: 'SUT',  A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NONE',       AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NONE',       B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NONE',       BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NONE',       C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   CP_STATUS: 'NONE',       MS_PLAN: '2025-02-08', MS_FORECAST: '2025-02-08', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-4',  MS_DESC: 'Instrument Air Distribution Available',                         DISC: 'NA', OWNER: 'ISCT', A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NONE',       AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NONE',       B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NONE',       BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NONE',       C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   CP_STATUS: 'NONE',       MS_PLAN: '2024-03-05', MS_FORECAST: '2024-03-08', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-10', MS_DESC: 'Fire Water Available',                                          DISC: 'NA', OWNER: 'ISCT', A_TOT: 96698, A_ACT: 23945, A_REM: 72753, A_PRO: 24, A_STATUS: 'INPROGRESS', AP_TOT: 161, AP_ACT: 0,   AP_REM: 161, AP_PRO: 0,   AP_STATUS: 'NOTSTARTED', B_TOT: 31297, B_ACT: 8559, B_REM: 22738, B_PRO: 27,  B_STATUS: 'INPROGRESS', BP_TOT: 170, BP_ACT: 5,   BP_REM: 165, BP_PRO: 2,  BP_STATUS: 'INPROGRESS', C_TOT: 1172, C_ACT: 0, C_REM: 1172, C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 54,  CP_ACT: 3,   CP_REM: 51, CP_PRO: 5,   CP_STATUS: 'INPROGRESS', MS_PLAN: '2024-08-17', MS_FORECAST: '2024-08-26', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-13', MS_DESC: 'SLOP Commissioned',                                             DISC: 'NA', OWNER: 'ISCT', A_TOT: 47,    A_ACT: 24,    A_REM: 23,    A_PRO: 51, A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,   AP_STATUS: 'NOTSTARTED', B_TOT: 15,    B_ACT: 8,    B_REM: 7,     B_PRO: 53,  B_STATUS: 'INPROGRESS', BP_TOT: 2,   BP_ACT: 0,   BP_REM: 2,   BP_PRO: 0,  BP_STATUS: 'NOTSTARTED', C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   CP_STATUS: 'NONE',       MS_PLAN: '2024-08-26', MS_FORECAST: '2024-08-19', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-28', MS_DESC: 'PFBS S/U',                                                      DISC: 'NA', OWNER: 'SUT',  A_TOT: 205,   A_ACT: 59,    A_REM: 146,   A_PRO: 28, A_STATUS: 'INPROGRESS', AP_TOT: 7,   AP_ACT: 1,   AP_REM: 6,   AP_PRO: 14,  AP_STATUS: 'INPROGRESS', B_TOT: 76,    B_ACT: 19,   B_REM: 57,    B_PRO: 25,  B_STATUS: 'INPROGRESS', BP_TOT: 7,   BP_ACT: 1,   BP_REM: 6,   BP_PRO: 14, BP_STATUS: 'INPROGRESS', C_TOT: 32,   C_ACT: 0, C_REM: 32,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,  CP_PRO: 100, CP_STATUS: 'COMPLETED',  MS_PLAN: '2024-12-20', MS_FORECAST: '2024-11-24', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-35', MS_DESC: 'MCR Tank Pre-build Complete',                                   DISC: 'NA', OWNER: 'SUT',  A_TOT: 381,   A_ACT: 199,   A_REM: 182,   A_PRO: 52, A_STATUS: 'INPROGRESS', AP_TOT: 14,  AP_ACT: 4,   AP_REM: 10,  AP_PRO: 28,  AP_STATUS: 'INPROGRESS', B_TOT: 141,   B_ACT: 45,   B_REM: 96,    B_PRO: 31,  B_STATUS: 'INPROGRESS', BP_TOT: 72,  BP_ACT: 12,  BP_REM: 60,  BP_PRO: 16, BP_STATUS: 'INPROGRESS', C_TOT: 4,    C_ACT: 0, C_REM: 4,    C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 11,  CP_ACT: 9,   CP_REM: 2,  CP_PRO: 81,  CP_STATUS: 'INPROGRESS', MS_PLAN: '2025-01-27', MS_FORECAST: '2024-12-06', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-11', MS_DESC: 'Natural Gas Introduction',                                      DISC: 'NA', OWNER: 'SUT',  A_TOT: 29,    A_ACT: 15,    A_REM: 14,    A_PRO: 51, A_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, AP_STATUS: 'COMPLETED',  B_TOT: 9,     B_ACT: 5,    B_REM: 4,     B_PRO: 55,  B_STATUS: 'INPROGRESS', BP_TOT: 9,   BP_ACT: 8,   BP_REM: 1,   BP_PRO: 88, BP_STATUS: 'INPROGRESS', C_TOT: 2,    C_ACT: 0, C_REM: 2,    C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 5,   CP_ACT: 3,   CP_REM: 2,  CP_PRO: 60,  CP_STATUS: 'INPROGRESS', MS_PLAN: '2024-09-18', MS_FORECAST: '2024-09-24', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-18', MS_DESC: 'SRU on Hot Standby',                                            DISC: 'NA', OWNER: 'SUT',  A_TOT: 628,   A_ACT: 308,   A_REM: 320,   A_PRO: 49, A_STATUS: 'INPROGRESS', AP_TOT: 23,  AP_ACT: 15,  AP_REM: 8,   AP_PRO: 65,  AP_STATUS: 'INPROGRESS', B_TOT: 216,   B_ACT: 93,   B_REM: 123,   B_PRO: 43,  B_STATUS: 'INPROGRESS', BP_TOT: 96,  BP_ACT: 11,  BP_REM: 85,  BP_PRO: 11, BP_STATUS: 'INPROGRESS', C_TOT: 19,   C_ACT: 0, C_REM: 19,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 33,  CP_ACT: 8,   CP_REM: 25, CP_PRO: 24,  CP_STATUS: 'INPROGRESS', MS_PLAN: '2024-10-09', MS_FORECAST: '2024-10-06', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-19', MS_DESC: 'ARU Degreasing',                                                DISC: 'NA', OWNER: 'ISCT', A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NONE',       AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NONE',       B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NONE',       BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NONE',       C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   CP_STATUS: 'NONE',       MS_PLAN: '2024-11-28', MS_FORECAST: '2024-11-23', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-12', MS_DESC: 'N2 in Service',                                                 DISC: 'NA', OWNER: 'ISCT', A_TOT: 126,   A_ACT: 101,   A_REM: 25,    A_PRO: 80, A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 1,   AP_REM: 2,   AP_PRO: 33,  AP_STATUS: 'INPROGRESS', B_TOT: 57,    B_ACT: 49,   B_REM: 8,     B_PRO: 86,  B_STATUS: 'INPROGRESS', BP_TOT: 21,  BP_ACT: 10,  BP_REM: 11,  BP_PRO: 47, BP_STATUS: 'INPROGRESS', C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 23,  CP_ACT: 21,  CP_REM: 2,  CP_PRO: 91,  CP_STATUS: 'INPROGRESS', MS_PLAN: '2024-06-06', MS_FORECAST: '2024-06-10', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-26', MS_DESC: 'DAU S/U',                                                       DISC: 'NA', OWNER: 'SUT',  A_TOT: 7452,  A_ACT: 2442,  A_REM: 5010,  A_PRO: 32, A_STATUS: 'INPROGRESS', AP_TOT: 29,  AP_ACT: 19,  AP_REM: 10,  AP_PRO: 65,  AP_STATUS: 'INPROGRESS', B_TOT: 2633,  B_ACT: 759,  B_REM: 1874,  B_PRO: 28,  B_STATUS: 'INPROGRESS', BP_TOT: 100, BP_ACT: 68,  BP_REM: 32,  BP_PRO: 68, BP_STATUS: 'INPROGRESS', C_TOT: 59,   C_ACT: 3, C_REM: 56,   C_PRO: 5, C_STATUS: 'INPROGRESS', CP_TOT: 179, CP_ACT: 134, CP_REM: 45, CP_PRO: 74,  CP_STATUS: 'INPROGRESS', MS_PLAN: '2024-12-10', MS_FORECAST: '2024-11-15', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-32', MS_DESC: 'ES EHC 50/120 Lubes System Cleaning Complete',                  DISC: 'NA', OWNER: 'SUT',  A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NONE',       AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NONE',       B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NONE',       BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NONE',       C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   CP_STATUS: 'NONE',       MS_PLAN: '2025-01-12', MS_FORECAST: '2025-01-12', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-37', MS_DESC: 'CCFU & Solid Separation S/U',                                   DISC: 'NA', OWNER: 'SUT',  A_TOT: 488,   A_ACT: 140,   A_REM: 348,   A_PRO: 28, A_STATUS: 'INPROGRESS', AP_TOT: 30,  AP_ACT: 19,  AP_REM: 11,  AP_PRO: 63,  AP_STATUS: 'INPROGRESS', B_TOT: 168,   B_ACT: 40,   B_REM: 128,   B_PRO: 23,  B_STATUS: 'INPROGRESS', BP_TOT: 36,  BP_ACT: 3,   BP_REM: 33,  BP_PRO: 8,  BP_STATUS: 'INPROGRESS', C_TOT: 38,   C_ACT: 0, C_REM: 38,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 3,   CP_ACT: 2,   CP_REM: 1,  CP_PRO: 66,  CP_STATUS: 'INPROGRESS', MS_PLAN: '2025-02-07', MS_FORECAST: '2025-01-12', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-9',  MS_DESC: 'Permanent Instrument Air in Service',                           DISC: 'NA', OWNER: 'ISCT', A_TOT: 100,   A_ACT: 85,    A_REM: 15,    A_PRO: 85, A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, AP_STATUS: 'COMPLETED',  B_TOT: 37,    B_ACT: 37,   B_REM: 0,     B_PRO: 100, B_STATUS: 'COMPLETED',  BP_TOT: 4,   BP_ACT: 0,   BP_REM: 4,   BP_PRO: 0,  BP_STATUS: 'NOTSTARTED', C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 7,   CP_ACT: 6,   CP_REM: 1,  CP_PRO: 85,  CP_STATUS: 'INPROGRESS', MS_PLAN: '2024-08-14', MS_FORECAST: '2024-09-07', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-16', MS_DESC: 'Fuel Gas Available',                                            DISC: 'NA', OWNER: 'SUT',  A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NONE',       AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NONE',       B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NONE',       BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NONE',       C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   CP_STATUS: 'NONE',       MS_PLAN: '2024-09-26', MS_FORECAST: '2024-09-28', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-22', MS_DESC: 'Hot Oil System Circulation',                                    DISC: 'NA', OWNER: 'SUT',  A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NONE',       AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NONE',       B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NONE',       BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NONE',       C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   CP_STATUS: 'NONE',       MS_PLAN: '2024-10-26', MS_FORECAST: '2024-10-26', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-27', MS_DESC: 'DAU on Spec Min Rates',                                         DISC: 'NA', OWNER: 'SUT',  A_TOT: 63,    A_ACT: 58,    A_REM: 5,     A_PRO: 92, A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 1,   AP_REM: 1,   AP_PRO: 50,  AP_STATUS: 'INPROGRESS', B_TOT: 23,    B_ACT: 17,   B_REM: 6,     B_PRO: 73,  B_STATUS: 'INPROGRESS', BP_TOT: 15,  BP_ACT: 0,   BP_REM: 15,  BP_PRO: 0,  BP_STATUS: 'NOTSTARTED', C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 15,  CP_ACT: 7,   CP_REM: 8,  CP_PRO: 46,  CP_STATUS: 'INPROGRESS', MS_PLAN: '2024-12-15', MS_FORECAST: '2024-12-02', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-36', MS_DESC: 'CCFU Solid Separation SAT',                                     DISC: 'NA', OWNER: 'SUT',  A_TOT: 175,   A_ACT: 129,   A_REM: 46,    A_PRO: 73, A_STATUS: 'INPROGRESS', AP_TOT: 18,  AP_ACT: 9,   AP_REM: 9,   AP_PRO: 50,  AP_STATUS: 'INPROGRESS', B_TOT: 76,    B_ACT: 44,   B_REM: 32,    B_PRO: 57,  B_STATUS: 'INPROGRESS', BP_TOT: 64,  BP_ACT: 15,  BP_REM: 49,  BP_PRO: 23, BP_STATUS: 'INPROGRESS', C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 29,  CP_ACT: 14,  CP_REM: 15, CP_PRO: 48,  CP_STATUS: 'INPROGRESS', MS_PLAN: '2024-11-18', MS_FORECAST: '2024-10-08', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-6',  MS_DESC: 'Last DHU S2 Reactor Loaded',                                    DISC: 'NA', OWNER: 'SUT',  A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NONE',       AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NONE',       B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NONE',       BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NONE',       C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   CP_STATUS: 'NONE',       MS_PLAN: '2024-08-22', MS_FORECAST: '2024-08-17', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-17', MS_DESC: 'HSFOC Commissioned',                                            DISC: 'NA', OWNER: 'ISCT', A_TOT: 1541,  A_ACT: 971,   A_REM: 570,   A_PRO: 63, A_STATUS: 'INPROGRESS', AP_TOT: 12,  AP_ACT: 4,   AP_REM: 8,   AP_PRO: 33,  AP_STATUS: 'INPROGRESS', B_TOT: 494,   B_ACT: 237,  B_REM: 257,   B_PRO: 48,  B_STATUS: 'INPROGRESS', BP_TOT: 18,  BP_ACT: 2,   BP_REM: 16,  BP_PRO: 11, BP_STATUS: 'INPROGRESS', C_TOT: 48,   C_ACT: 0, C_REM: 48,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 16,  CP_ACT: 2,   CP_REM: 14, CP_PRO: 12,  CP_STATUS: 'INPROGRESS', MS_PLAN: '2024-09-24', MS_FORECAST: '2024-10-24', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-21', MS_DESC: 'SWS Circulation',                                               DISC: 'NA', OWNER: 'ISCT', A_TOT: 122,   A_ACT: 34,    A_REM: 88,    A_PRO: 27, A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,   AP_STATUS: 'NOTSTARTED', B_TOT: 58,    B_ACT: 10,   B_REM: 48,    B_PRO: 17,  B_STATUS: 'INPROGRESS', BP_TOT: 2,   BP_ACT: 0,   BP_REM: 2,   BP_PRO: 0,  BP_STATUS: 'NOTSTARTED', C_TOT: 3,    C_ACT: 0, C_REM: 3,    C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   CP_STATUS: 'NONE',       MS_PLAN: '2024-10-13', MS_FORECAST: '2024-10-07', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-29', MS_DESC: 'DHU S1 on Spec Min Rates',                                      DISC: 'NA', OWNER: 'SUT',  A_TOT: 1375,  A_ACT: 621,   A_REM: 754,   A_PRO: 45, A_STATUS: 'INPROGRESS', AP_TOT: 46,  AP_ACT: 20,  AP_REM: 26,  AP_PRO: 43,  AP_STATUS: 'INPROGRESS', B_TOT: 493,   B_ACT: 207,  B_REM: 286,   B_PRO: 42,  B_STATUS: 'INPROGRESS', BP_TOT: 251, BP_ACT: 99,  BP_REM: 152, BP_PRO: 39, BP_STATUS: 'INPROGRESS', C_TOT: 19,   C_ACT: 0, C_REM: 19,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 130, CP_ACT: 62,  CP_REM: 68, CP_PRO: 47,  CP_STATUS: 'INPROGRESS', MS_PLAN: '2025-01-25', MS_FORECAST: '2025-01-19', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-3',  MS_DESC: 'RIE2 Ready for Loop Check',                                     DISC: 'NA', OWNER: 'ISCT', A_TOT: 844,   A_ACT: 613,   A_REM: 231,   A_PRO: 72, A_STATUS: 'INPROGRESS', AP_TOT: 328, AP_ACT: 291, AP_REM: 37,  AP_PRO: 88,  AP_STATUS: 'INPROGRESS', B_TOT: 232,   B_ACT: 154,  B_REM: 78,    B_PRO: 66,  B_STATUS: 'INPROGRESS', BP_TOT: 282, BP_ACT: 209, BP_REM: 73,  BP_PRO: 74, BP_STATUS: 'INPROGRESS', C_TOT: 44,   C_ACT: 0, C_REM: 44,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 363, CP_ACT: 279, CP_REM: 84, CP_PRO: 76,  CP_STATUS: 'INPROGRESS', MS_PLAN: '2024-01-17', MS_FORECAST: '2023-12-07', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-30', MS_DESC: 'DHU S2 on Spec Min Rates',                                      DISC: 'NA', OWNER: 'SUT',  A_TOT: 407,   A_ACT: 34,    A_REM: 373,   A_PRO: 8,  A_STATUS: 'INPROGRESS', AP_TOT: 8,   AP_ACT: 0,   AP_REM: 8,   AP_PRO: 0,   AP_STATUS: 'NOTSTARTED', B_TOT: 153,   B_ACT: 8,    B_REM: 145,   B_PRO: 5,   B_STATUS: 'INPROGRESS', BP_TOT: 11,  BP_ACT: 0,   BP_REM: 11,  BP_PRO: 0,  BP_STATUS: 'NOTSTARTED', C_TOT: 11,   C_ACT: 0, C_REM: 11,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,  CP_PRO: 0,   CP_STATUS: 'NOTSTARTED', MS_PLAN: '2025-02-08', MS_FORECAST: '2025-02-05', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-33', MS_DESC: 'Recycle Gas Compressor N2 String Test Complete (CCFU)',         DISC: 'NA', OWNER: 'ISCT', A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NONE',       AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NONE',       B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NONE',       BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NONE',       C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   CP_STATUS: 'NONE',       MS_PLAN: '2024-10-24', MS_FORECAST: '2024-12-31', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-7',  MS_DESC: 'Last CCFU Reactor Loaded',                                      DISC: 'NA', OWNER: 'SUT',  A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NONE',       AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NONE',       B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NONE',       BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NONE',       C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   CP_STATUS: 'NONE',       MS_PLAN: '2024-09-19', MS_FORECAST: '2024-06-16', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-14', MS_DESC: 'HP Steam Available',                                            DISC: 'NA', OWNER: 'ISCT', A_TOT: 129,   A_ACT: 111,   A_REM: 18,    A_PRO: 86, A_STATUS: 'INPROGRESS', AP_TOT: 6,   AP_ACT: 3,   AP_REM: 3,   AP_PRO: 50,  AP_STATUS: 'INPROGRESS', B_TOT: 47,    B_ACT: 37,   B_REM: 10,    B_PRO: 78,  B_STATUS: 'INPROGRESS', BP_TOT: 48,  BP_ACT: 14,  BP_REM: 34,  BP_PRO: 29, BP_STATUS: 'INPROGRESS', C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 6,   CP_ACT: 4,   CP_REM: 2,  CP_PRO: 66,  CP_STATUS: 'INPROGRESS', MS_PLAN: '2024-08-24', MS_FORECAST: '2024-09-06', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-25', MS_DESC: 'Makeup & Recycle Gas Compressor S/U (DHU)',                     DISC: 'NA', OWNER: 'SUT',  A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NONE',       AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NONE',       B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NONE',       BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NONE',       C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   CP_STATUS: 'NONE',       MS_PLAN: '2025-01-09', MS_FORECAST: '2024-12-28', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-34', MS_DESC: 'Recycle Gas Compressor S/U (CCFU)',                             DISC: 'NA', OWNER: 'SUT',  A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NONE',       AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NONE',       B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NONE',       BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NONE',       C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   CP_STATUS: 'NONE',       MS_PLAN: '2025-02-03', MS_FORECAST: '2025-01-21', MS_ACTUAL: 'NULL', },
      { MS_TYPE: 'CSU_MS',   MS_NO: 'MS-5',  MS_DESC: 'Last DHU S1 Reactor Loaded',                                    DISC: 'NA', OWNER: 'SUT',  A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NONE',       AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NONE',       B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NONE',       BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NONE',       C_TOT: 0,    C_ACT: 0, C_REM: 0,    C_PRO: 0, C_STATUS: 'NONE',       CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,  CP_PRO: 0,   CP_STATUS: 'NONE',       MS_PLAN: '2024-08-08', MS_FORECAST: '2024-06-27', MS_ACTUAL: 'NULL', },
    ],

    SQL2:  [
      {
        CUTOFF:               '2023-11-31',

        START_DATE:           '2023-09-01',
        END_DATE:             '2025-04-30',
        HIS_START:            '2023-11-01',
        HIS_END:              '2024-12-31',

        ICICLE_X:             30,
        ICICLE_Y:             250,
        ICICLE_PADDING_LEFT:  40,
        ICICLE_PADDING_TOP:   25,

        TBL_X:                690,
        TBL_Y:                125.5, // N/A
        TBL_CHCKED:           'unselect', // select / unselect
        TBL_NAME:             'Code   / MS No   / Milestone / Plan   / Forecast / Delta  ',
        TBL_CODE:             'IDX    / MS_NO   / MS_DESC   / PLAN   / FORECAST / DELTA  ',
        TBL_VISIBLE:          'Y      / Y       / Y         / Y      / Y        / Y      ',
        TBL_WIDTH:            '33     / 42      / 300       / 60     / 60       / 25     ',
        TBL_ALIGN:            'middle  / middle / start     / middle / middle   / middle ',
      }
    ],

    SQL3: [ // Detail infomation Box
      {
        DETAIL_VISIBLE:           'Y',
        DETAIL_ONOFF:             'ON',
        DETAIL_X:                 32,
        DETAIL_Y:                 118,
        DETAIL_WIDTH:             635,
        DETAIL_TEXT_SIZE:         10,
        DETAIL_LINEHEIGHT:        22,
        
        DETAIL_CODE:              ' MS_DESC      / MS_PLAN / MS_FORECAST / MS_ACTUAL / A        / B        / C        / AP       / BP       / CP       ',
        DETAIL_NAME:              ' Description: / Plan:   / Forecast:   / Actual:   / A-Sheet: / B-Sheet: / C-Sheet: / Punch-A: / Punch-B: / Punch-C: ',
        DETAIL_TYPE:              ' TEXT         / TEXT    / TEXT        / TEXT      / BAR      / BAR      / BAR      / BAR      / BAR      / BAR      ',
        DETAIL_VALUE_COLOR:       ' #757575      / #757575 / #757575     / #757575   / #83D2F5  / #83D2F5  / #83D2F5  / #F7BACF  / #F7BACF  / #F7BACF  ',
        DETAIL_BAR_COLOR:         ' #44A9DF      / gray    / gray        / gray      / #83D2F5  / #83D2F5  / #83D2F5  / #F7BACF  / #F7BACF  / #F7BACF  ',
        DETAIL_COLUMN:            ' 0            / 0       / 0           / 0         / 200      / 200      / 200      / 400      /400       / 400      ',
        DETAIL_TITLE_WIDTH:       50,
        DETAIL_TITLE_COLOR:       'gray',
      }
    ],
  },
  
}