
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 300,
    CanvasBorderWeight: 0.2,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 230,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    { SCOPE: 'Grassroots', UNIT: 'GENERAL',     UNIT_NO: '20000', UNIT_DESC: 'GENERAL',                                       SSMCC_TOT: 12, SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 12, SSMCC_PRO: 0,    A_TOT: 799,   A_ACT: 176,  A_REM: 623,   A_PRO: 22,   A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    B_TOT: 98,   B_ACT: 11,   B_REM: 87,   B_PRO: 11.2, B_STATUS: 'INPROGRESS', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,    C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'DAU',         UNIT_NO: '20100', UNIT_DESC: 'Deasphalting Unit',                             SSMCC_TOT: 42, SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 42, SSMCC_PRO: 0,    A_TOT: 8651,  A_ACT: 2677, A_REM: 5974,  A_PRO: 30.9, A_STATUS: 'INPROGRESS', AP_TOT: 169, AP_ACT: 0,   AP_REM: 169, AP_PRO: 0,    B_TOT: 2838, B_ACT: 847,  B_REM: 1991, B_PRO: 29.8, B_STATUS: 'INPROGRESS', BP_TOT: 95,  BP_ACT: 0,   BP_REM: 95,  BP_PRO: 0,    C_TOT: 173, C_ACT: 0, C_REM: 173, C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 106, CP_ACT: 0,   CP_REM: 106, CP_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'PFBS',        UNIT_NO: '20101', UNIT_DESC: 'Prefractionator Bottoms Stripper',              SSMCC_TOT: 21, SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 21, SSMCC_PRO: 0,    A_TOT: 1754,  A_ACT: 370,  A_REM: 1384,  A_PRO: 21.1, A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    B_TOT: 594,  B_ACT: 90,   B_REM: 504,  B_PRO: 15.2, B_STATUS: 'INPROGRESS', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,    C_TOT: 17,  C_ACT: 0, C_REM: 17,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'DHU S1',      UNIT_NO: '20200', UNIT_DESC: 'Hydroprocessing Unit',                          SSMCC_TOT: 68, SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 68, SSMCC_PRO: 0,    A_TOT: 35459, A_ACT: 5403, A_REM: 30056, A_PRO: 15.2, A_STATUS: 'INPROGRESS', AP_TOT: 28,  AP_ACT: 0,   AP_REM: 28,  AP_PRO: 0,    B_TOT: 9369, B_ACT: 1433, B_REM: 7936, B_PRO: 15.3, B_STATUS: 'INPROGRESS', BP_TOT: 16,  BP_ACT: 5,   BP_REM: 11,  BP_PRO: 31.3, C_TOT: 231, C_ACT: 0, C_REM: 231, C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 4,   CP_ACT: 0,   CP_REM: 4,   CP_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'DHU S2',      UNIT_NO: '20300', UNIT_DESC: 'Hydroprocessing Unit',                          SSMCC_TOT: 30, SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 30, SSMCC_PRO: 0,    A_TOT: 8105,  A_ACT: 1725, A_REM: 6380,  A_PRO: 21.3, A_STATUS: 'INPROGRESS', AP_TOT: 22,  AP_ACT: 0,   AP_REM: 22,  AP_PRO: 0,    B_TOT: 2384, B_ACT: 525,  B_REM: 1859, B_PRO: 22,   B_STATUS: 'INPROGRESS', BP_TOT: 5,   BP_ACT: 0,   BP_REM: 5,   BP_PRO: 0,    C_TOT: 36,  C_ACT: 0, C_REM: 36,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'CCFU',        UNIT_NO: '20400', UNIT_DESC: 'Chemical Clean Fuel Unit',                      SSMCC_TOT: 78, SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 78, SSMCC_PRO: 0,    A_TOT: 16061, A_ACT: 3020, A_REM: 13041, A_PRO: 18.8, A_STATUS: 'INPROGRESS', AP_TOT: 10,  AP_ACT: 0,   AP_REM: 10,  AP_PRO: 0,    B_TOT: 5702, B_ACT: 952,  B_REM: 4750, B_PRO: 16.7, B_STATUS: 'INPROGRESS', BP_TOT: 10,  BP_ACT: 0,   BP_REM: 10,  BP_PRO: 0,    C_TOT: 124, C_ACT: 0, C_REM: 124, C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,   CP_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'SWS',         UNIT_NO: '20500', UNIT_DESC: 'Sour Water Stripper Unit',                      SSMCC_TOT: 24, SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 24, SSMCC_PRO: 0,    A_TOT: 1241,  A_ACT: 667,  A_REM: 574,   A_PRO: 53.7, A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,    B_TOT: 515,  B_ACT: 304,  B_REM: 211,  B_PRO: 59,   B_STATUS: 'INPROGRESS', BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,    C_TOT: 10,  C_ACT: 0, C_REM: 10,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,   CP_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'ARU',         UNIT_NO: '20600', UNIT_DESC: 'Amine Regeneration Unit',                       SSMCC_TOT: 29, SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 29, SSMCC_PRO: 0,    A_TOT: 2710,  A_ACT: 1070, A_REM: 1640,  A_PRO: 39.5, A_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,    B_TOT: 1045, B_ACT: 431,  B_REM: 614,  B_PRO: 41.2, B_STATUS: 'INPROGRESS', BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0,    C_TOT: 46,  C_ACT: 0, C_REM: 46,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,   CP_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'SRU',         UNIT_NO: '20700', UNIT_DESC: 'Sulfur Recovery Unit',                          SSMCC_TOT: 39, SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 39, SSMCC_PRO: 0,    A_TOT: 7604,  A_ACT: 3959, A_REM: 3645,  A_PRO: 52.1, A_STATUS: 'INPROGRESS', AP_TOT: 64,  AP_ACT: 0,   AP_REM: 64,  AP_PRO: 0,    B_TOT: 2044, B_ACT: 1046, B_REM: 998,  B_PRO: 51.2, B_STATUS: 'INPROGRESS', BP_TOT: 108, BP_ACT: 5,   BP_REM: 103, BP_PRO: 4.6,  C_TOT: 88,  C_ACT: 0, C_REM: 88,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 34,  CP_ACT: 2,   CP_REM: 32,  CP_PRO: 5.9, },
    { SCOPE: 'Grassroots', UNIT: 'HOS',         UNIT_NO: '20800', UNIT_DESC: 'Hot Oil System',                                SSMCC_TOT: 28, SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 28, SSMCC_PRO: 0,    A_TOT: 5095,  A_ACT: 1458, A_REM: 3637,  A_PRO: 28.6, A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,   AP_REM: 3,   AP_PRO: 0,    B_TOT: 1583, B_ACT: 441,  B_REM: 1142, B_PRO: 27.9, B_STATUS: 'INPROGRESS', BP_TOT: 7,   BP_ACT: 0,   BP_REM: 7,   BP_PRO: 0,    C_TOT: 52,  C_ACT: 0, C_REM: 52,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'U&O',         UNIT_NO: '21100', UNIT_DESC: 'Utilities & Offsites',                          SSMCC_TOT: 95, SSMCC_PLN: 1, SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 95, SSMCC_PRO: 0,    A_TOT: 8986,  A_ACT: 4351, A_REM: 4635,  A_PRO: 48.4, A_STATUS: 'INPROGRESS', AP_TOT: 113, AP_ACT: 0,   AP_REM: 113, AP_PRO: 0,    B_TOT: 4261, B_ACT: 2561, B_REM: 1700, B_PRO: 60.1, B_STATUS: 'INPROGRESS', BP_TOT: 66,  BP_ACT: 0,   BP_REM: 66,  BP_PRO: 0,    C_TOT: 157, C_ACT: 0, C_REM: 157, C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 25,  CP_ACT: 0,   CP_REM: 25,  CP_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'CWS',         UNIT_NO: '21200', UNIT_DESC: 'Cooling Water System',                          SSMCC_TOT: 32, SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 32, SSMCC_PRO: 0,    A_TOT: 1618,  A_ACT: 341,  A_REM: 1277,  A_PRO: 21.1, A_STATUS: 'INPROGRESS', AP_TOT: 4,   AP_ACT: 0,   AP_REM: 4,   AP_PRO: 0,    B_TOT: 916,  B_ACT: 264,  B_REM: 652,  B_PRO: 28.8, B_STATUS: 'INPROGRESS', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,    C_TOT: 16,  C_ACT: 0, C_REM: 16,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,   CP_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'DS',          UNIT_NO: '21300', UNIT_DESC: 'Disposal Systems',                              SSMCC_TOT: 29, SSMCC_PLN: 1, SSMCC_WD: 1,  SSMCC_ACT: 0,  SSMCC_REM: 29, SSMCC_PRO: 0,    A_TOT: 3132,  A_ACT: 995,  A_REM: 2137,  A_PRO: 31.8, A_STATUS: 'INPROGRESS', AP_TOT: 18,  AP_ACT: 0,   AP_REM: 18,  AP_PRO: 0,    B_TOT: 1383, B_ACT: 464,  B_REM: 919,  B_PRO: 33.6, B_STATUS: 'INPROGRESS', BP_TOT: 22,  BP_ACT: 0,   BP_REM: 22,  BP_PRO: 0,    C_TOT: 23,  C_ACT: 0, C_REM: 23,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 5,   CP_ACT: 0,   CP_REM: 5,   CP_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    UNIT_NO: '21401', UNIT_DESC: 'Tank Farm North',                               SSMCC_TOT: 24, SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 24, SSMCC_PRO: 0,    A_TOT: 4169,  A_ACT: 490,  A_REM: 3679,  A_PRO: 11.8, A_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,   AP_REM: 3,   AP_PRO: 0,    B_TOT: 1194, B_ACT: 170,  B_REM: 1024, B_PRO: 14.2, B_STATUS: 'INPROGRESS', BP_TOT: 2,   BP_ACT: 0,   BP_REM: 2,   BP_PRO: 0,    C_TOT: 138, C_ACT: 0, C_REM: 138, C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 3,   CP_ACT: 0,   CP_REM: 3,   CP_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    UNIT_NO: '21402', UNIT_DESC: 'Tank Farm South',                               SSMCC_TOT: 24, SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 24, SSMCC_PRO: 0,    A_TOT: 2511,  A_ACT: 586,  A_REM: 1925,  A_PRO: 23.3, A_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    B_TOT: 413,  B_ACT: 77,   B_REM: 336,  B_PRO: 18.6, B_STATUS: 'INPROGRESS', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,    C_TOT: 234, C_ACT: 0, C_REM: 234, C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 4,   CP_ACT: 1,   CP_REM: 3,   CP_PRO: 25, },
    { SCOPE: 'Grassroots', UNIT: 'HSFOC',       UNIT_NO: '21403', UNIT_DESC: 'High Sulphur Fuel Oil Cooler',                  SSMCC_TOT: 31, SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0,  SSMCC_REM: 31, SSMCC_PRO: 0,    A_TOT: 1864,  A_ACT: 1147, A_REM: 717,   A_PRO: 61.5, A_STATUS: 'INPROGRESS', AP_TOT: 49,  AP_ACT: 0,   AP_REM: 49,  AP_PRO: 0,    B_TOT: 631,  B_ACT: 312,  B_REM: 319,  B_PRO: 49.4, B_STATUS: 'INPROGRESS', BP_TOT: 107, BP_ACT: 0,   BP_REM: 107, BP_PRO: 0,    C_TOT: 51,  C_ACT: 0, C_REM: 51,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 47,  CP_ACT: 0,   CP_REM: 47,  CP_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', UNIT_NO: '21501', UNIT_DESC: 'Instrument & Control System-RIE 01 - PAB 1603', SSMCC_TOT: 15, SSMCC_PLN: 0, SSMCC_WD: 2,  SSMCC_ACT: 1,  SSMCC_REM: 14, SSMCC_PRO: 6.7,  A_TOT: 40,    A_ACT: 25,   A_REM: 15,    A_PRO: 62.5, A_STATUS: 'INPROGRESS', AP_TOT: 4,   AP_ACT: 0,   AP_REM: 4,   AP_PRO: 0,    B_TOT: 78,   B_ACT: 38,   B_REM: 40,   B_PRO: 48.7, B_STATUS: 'INPROGRESS', BP_TOT: 15,  BP_ACT: 3,   BP_REM: 12,  BP_PRO: 20,   C_TOT: 10,  C_ACT: 0, C_REM: 10,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 4,   CP_ACT: 0,   CP_REM: 4,   CP_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', UNIT_NO: '21502', UNIT_DESC: 'Instrument & Control System-RIE 02 - PAB 1603', SSMCC_TOT: 15, SSMCC_PLN: 1, SSMCC_WD: 2,  SSMCC_ACT: 1,  SSMCC_REM: 14, SSMCC_PRO: 6.7,  A_TOT: 100,   A_ACT: 40,   A_REM: 60,    A_PRO: 40,   A_STATUS: 'INPROGRESS', AP_TOT: 20,  AP_ACT: 10,  AP_REM: 10,  AP_PRO: 50,   B_TOT: 80,   B_ACT: 51,   B_REM: 29,   B_PRO: 63.7, B_STATUS: 'INPROGRESS', BP_TOT: 34,  BP_ACT: 6,   BP_REM: 28,  BP_PRO: 17.6, C_TOT: 10,  C_ACT: 0, C_REM: 10,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 10,  CP_ACT: 0,   CP_REM: 10,  CP_PRO: 0, },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     UNIT_NO: '21601', UNIT_DESC: 'Electrical System-Substation SSV',              SSMCC_TOT: 30, SSMCC_PLN: 4, SSMCC_WD: 18, SSMCC_ACT: 14, SSMCC_REM: 16, SSMCC_PRO: 46.7, A_TOT: 772,   A_ACT: 593,  A_REM: 179,   A_PRO: 76.8, A_STATUS: 'INPROGRESS', AP_TOT: 308, AP_ACT: 282, AP_REM: 26,  AP_PRO: 91.6, B_TOT: 157,  B_ACT: 103,  B_REM: 54,   B_PRO: 65.6, B_STATUS: 'INPROGRESS', BP_TOT: 243, BP_ACT: 206, BP_REM: 37,  BP_PRO: 84.8, C_TOT: 35,  C_ACT: 0, C_REM: 35,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 351, CP_ACT: 291, CP_REM: 60,  CP_PRO: 82.9, },
    { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     UNIT_NO: '21605', UNIT_DESC: 'Electrical System-Substation SSW',              SSMCC_TOT: 29, SSMCC_PLN: 2, SSMCC_WD: 17, SSMCC_ACT: 14, SSMCC_REM: 15, SSMCC_PRO: 48.3, A_TOT: 554,   A_ACT: 389,  A_REM: 165,   A_PRO: 70.2, A_STATUS: 'INPROGRESS', AP_TOT: 127, AP_ACT: 95,  AP_REM: 32,  AP_PRO: 74.8, B_TOT: 119,  B_ACT: 58,   B_REM: 61,   B_PRO: 48.7, B_STATUS: 'INPROGRESS', BP_TOT: 188, BP_ACT: 175, BP_REM: 13,  BP_PRO: 93.1, C_TOT: 31,  C_ACT: 0, C_REM: 31,  C_PRO: 0, C_STATUS: 'NOTSTARTED', CP_TOT: 156, CP_ACT: 81,  CP_REM: 75,  CP_PRO: 51.9, },

  ],

  Queries: {
    SQL1:  [
      {
        TABLE_NAME    :'Grassroots',
        TABLE_PAGE_NO :7,
        TABLE_COLOR   :'#83D2F5 / #83D2F5 / #83D2F5 / #FEED57 / #FEED57',
        TABLE_OPACITY :'.9      / .6      / .3      / .6      / .3     ',

        SHEET_NAME    :'Turnover  / Walkdown  / A Sheet / Punch A / B Sheet / Punch B ',
        SHEET_CODE    :'SSMCC     / WD        / A       / AP      / B       / BP      ',

      },
    ],

    // Filter
    SQL2: [
        { 
          TOT: " [CATEGORY] = ''A''",
          ACT: " [CATEGORY] = ''A'' AND [STATUS_CODE] = ''C''",
          REM: " [CATEGORY] = ''A'' AND [STATUS_CODE] != ''C''",
        }      
    ],

  },
  
}