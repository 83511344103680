
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 900,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 900,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-07-07', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 8,    CLOSED_CUM: 8,    REMAIN_CUM: 0,    TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-07-14', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 8,    CLOSED_CUM: 8,    REMAIN_CUM: 0,    TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-07-21', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 8,    CLOSED_CUM: 8,    REMAIN_CUM: 0,    TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-07-28', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 8,    CLOSED_CUM: 8,    REMAIN_CUM: 0,    TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 8,    CLOSED_CUM: 8,    REMAIN_CUM: 0,    TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 8,    CLOSED_CUM: 8,    REMAIN_CUM: 0,    TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 8,    CLOSED_CUM: 8,    REMAIN_CUM: 0,    TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-08-25', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 8,    CLOSED_CUM: 8,    REMAIN_CUM: 0,    TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-09-01', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 8,    CLOSED_CUM: 8,    REMAIN_CUM: 0,    TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-09-08', CREATED_WK: 1,    CLOSED_WK: 0,   CREATED_CUM: 9,    CLOSED_CUM: 8,    REMAIN_CUM: 1,    TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-09-15', CREATED_WK: 5,    CLOSED_WK: 0,   CREATED_CUM: 14,   CLOSED_CUM: 8,    REMAIN_CUM: 6,    TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-09-22', CREATED_WK: 6,    CLOSED_WK: 4,   CREATED_CUM: 20,   CLOSED_CUM: 12,   REMAIN_CUM: 8,    TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-09-29', CREATED_WK: 3,    CLOSED_WK: 2,   CREATED_CUM: 23,   CLOSED_CUM: 14,   REMAIN_CUM: 9,    TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 1,   CREATED_CUM: 23,   CLOSED_CUM: 15,   REMAIN_CUM: 8,    TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-10-13', CREATED_WK: 4,    CLOSED_WK: 0,   CREATED_CUM: 27,   CLOSED_CUM: 15,   REMAIN_CUM: 12,   TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-10-20', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 27,   CLOSED_CUM: 15,   REMAIN_CUM: 12,   TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-10-27', CREATED_WK: 3,    CLOSED_WK: 2,   CREATED_CUM: 30,   CLOSED_CUM: 17,   REMAIN_CUM: 13,   TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-11-03', CREATED_WK: 4,    CLOSED_WK: 5,   CREATED_CUM: 34,   CLOSED_CUM: 22,   REMAIN_CUM: 12,   TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-11-10', CREATED_WK: 2,    CLOSED_WK: 2,   CREATED_CUM: 36,   CLOSED_CUM: 24,   REMAIN_CUM: 12,   TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 1,   CREATED_CUM: 36,   CLOSED_CUM: 25,   REMAIN_CUM: 11,   TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-11-24', CREATED_WK: 2,    CLOSED_WK: 1,   CREATED_CUM: 38,   CLOSED_CUM: 26,   REMAIN_CUM: 12,   TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-12-01', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 38,   CLOSED_CUM: 26,   REMAIN_CUM: 12,   TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 1,   CREATED_CUM: 38,   CLOSED_CUM: 27,   REMAIN_CUM: 11,   TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-12-15', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 38,   CLOSED_CUM: 27,   REMAIN_CUM: 11,   TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-12-22', CREATED_WK: 0,    CLOSED_WK: 3,   CREATED_CUM: 38,   CLOSED_CUM: 30,   REMAIN_CUM: 8,    TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-12-29', CREATED_WK: 1,    CLOSED_WK: 0,   CREATED_CUM: 39,   CLOSED_CUM: 30,   REMAIN_CUM: 9,    TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2024-01-05', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 39,   CLOSED_CUM: 30,   REMAIN_CUM: 9,    TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2024-01-12', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 39,   CLOSED_CUM: 30,   REMAIN_CUM: 9,    TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2024-01-19', CREATED_WK: 3,    CLOSED_WK: 2,   CREATED_CUM: 42,   CLOSED_CUM: 32,   REMAIN_CUM: 10,   TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 42,   CLOSED_CUM: 32,   REMAIN_CUM: 10,   TOTAL: 42, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-07-07', CREATED_WK: 0,    CLOSED_WK: 3,   CREATED_CUM: 40,   CLOSED_CUM: 35,   REMAIN_CUM: 5,    TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-07-14', CREATED_WK: 13,   CLOSED_WK: 0,   CREATED_CUM: 53,   CLOSED_CUM: 35,   REMAIN_CUM: 18,   TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-07-21', CREATED_WK: 6,    CLOSED_WK: 13,  CREATED_CUM: 59,   CLOSED_CUM: 48,   REMAIN_CUM: 11,   TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-07-28', CREATED_WK: 0,    CLOSED_WK: 1,   CREATED_CUM: 59,   CLOSED_CUM: 49,   REMAIN_CUM: 10,   TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-08-04', CREATED_WK: 1,    CLOSED_WK: 5,   CREATED_CUM: 60,   CLOSED_CUM: 54,   REMAIN_CUM: 6,    TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 60,   CLOSED_CUM: 54,   REMAIN_CUM: 6,    TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 60,   CLOSED_CUM: 54,   REMAIN_CUM: 6,    TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-08-25', CREATED_WK: 0,    CLOSED_WK: 1,   CREATED_CUM: 60,   CLOSED_CUM: 55,   REMAIN_CUM: 5,    TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-09-01', CREATED_WK: 14,   CLOSED_WK: 0,   CREATED_CUM: 74,   CLOSED_CUM: 55,   REMAIN_CUM: 19,   TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-09-08', CREATED_WK: 18,   CLOSED_WK: 8,   CREATED_CUM: 92,   CLOSED_CUM: 63,   REMAIN_CUM: 29,   TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-09-15', CREATED_WK: 85,   CLOSED_WK: 37,  CREATED_CUM: 177,  CLOSED_CUM: 100,  REMAIN_CUM: 77,   TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-09-22', CREATED_WK: 25,   CLOSED_WK: 28,  CREATED_CUM: 202,  CLOSED_CUM: 128,  REMAIN_CUM: 74,   TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-09-29', CREATED_WK: 101,  CLOSED_WK: 26,  CREATED_CUM: 303,  CLOSED_CUM: 154,  REMAIN_CUM: 149,  TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-10-06', CREATED_WK: 5,    CLOSED_WK: 9,   CREATED_CUM: 308,  CLOSED_CUM: 163,  REMAIN_CUM: 145,  TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-10-13', CREATED_WK: 19,   CLOSED_WK: 1,   CREATED_CUM: 327,  CLOSED_CUM: 164,  REMAIN_CUM: 163,  TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-10-20', CREATED_WK: 90,   CLOSED_WK: 42,  CREATED_CUM: 417,  CLOSED_CUM: 206,  REMAIN_CUM: 211,  TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-10-27', CREATED_WK: 68,   CLOSED_WK: 53,  CREATED_CUM: 485,  CLOSED_CUM: 259,  REMAIN_CUM: 226,  TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-11-03', CREATED_WK: 3,    CLOSED_WK: 57,  CREATED_CUM: 488,  CLOSED_CUM: 316,  REMAIN_CUM: 172,  TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-11-10', CREATED_WK: 42,   CLOSED_WK: 61,  CREATED_CUM: 530,  CLOSED_CUM: 377,  REMAIN_CUM: 153,  TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-11-17', CREATED_WK: 50,   CLOSED_WK: 58,  CREATED_CUM: 580,  CLOSED_CUM: 435,  REMAIN_CUM: 145,  TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-11-24', CREATED_WK: 17,   CLOSED_WK: 34,  CREATED_CUM: 597,  CLOSED_CUM: 469,  REMAIN_CUM: 128,  TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-12-01', CREATED_WK: 5,    CLOSED_WK: 15,  CREATED_CUM: 602,  CLOSED_CUM: 484,  REMAIN_CUM: 118,  TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-12-08', CREATED_WK: 255,  CLOSED_WK: 4,   CREATED_CUM: 857,  CLOSED_CUM: 488,  REMAIN_CUM: 369,  TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-12-15', CREATED_WK: 7,    CLOSED_WK: 39,  CREATED_CUM: 864,  CLOSED_CUM: 527,  REMAIN_CUM: 337,  TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-12-22', CREATED_WK: 523,  CLOSED_WK: 1,   CREATED_CUM: 1387, CLOSED_CUM: 528,  REMAIN_CUM: 859,  TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-12-29', CREATED_WK: 14,   CLOSED_WK: 0,   CREATED_CUM: 1401, CLOSED_CUM: 528,  REMAIN_CUM: 873,  TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2024-01-05', CREATED_WK: 0,    CLOSED_WK: 99,  CREATED_CUM: 1401, CLOSED_CUM: 627,  REMAIN_CUM: 774,  TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2024-01-12', CREATED_WK: 17,   CLOSED_WK: 2,   CREATED_CUM: 1418, CLOSED_CUM: 629,  REMAIN_CUM: 789,  TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2024-01-19', CREATED_WK: 11,   CLOSED_WK: 0,   CREATED_CUM: 1429, CLOSED_CUM: 629,  REMAIN_CUM: 800,  TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 1429, CLOSED_CUM: 629,  REMAIN_CUM: 800,  TOTAL: 1429, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-07-07', CREATED_WK: 14,   CLOSED_WK: 0,   CREATED_CUM: 40,   CLOSED_CUM: 26,   REMAIN_CUM: 14,   TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-07-14', CREATED_WK: 19,   CLOSED_WK: 20,  CREATED_CUM: 59,   CLOSED_CUM: 46,   REMAIN_CUM: 13,   TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-07-21', CREATED_WK: 4,    CLOSED_WK: 10,  CREATED_CUM: 63,   CLOSED_CUM: 56,   REMAIN_CUM: 7,    TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-07-28', CREATED_WK: 3,    CLOSED_WK: 5,   CREATED_CUM: 66,   CLOSED_CUM: 61,   REMAIN_CUM: 5,    TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 4,   CREATED_CUM: 66,   CLOSED_CUM: 65,   REMAIN_CUM: 1,    TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 66,   CLOSED_CUM: 65,   REMAIN_CUM: 1,    TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 66,   CLOSED_CUM: 65,   REMAIN_CUM: 1,    TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-08-25', CREATED_WK: 8,    CLOSED_WK: 0,   CREATED_CUM: 74,   CLOSED_CUM: 65,   REMAIN_CUM: 9,    TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-09-01', CREATED_WK: 1,    CLOSED_WK: 8,   CREATED_CUM: 75,   CLOSED_CUM: 73,   REMAIN_CUM: 2,    TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-09-08', CREATED_WK: 2,    CLOSED_WK: 2,   CREATED_CUM: 77,   CLOSED_CUM: 75,   REMAIN_CUM: 2,    TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-09-15', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 77,   CLOSED_CUM: 75,   REMAIN_CUM: 2,    TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-09-22', CREATED_WK: 19,   CLOSED_WK: 0,   CREATED_CUM: 96,   CLOSED_CUM: 75,   REMAIN_CUM: 21,   TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-09-29', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 96,   CLOSED_CUM: 75,   REMAIN_CUM: 21,   TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-10-06', CREATED_WK: 18,   CLOSED_WK: 1,   CREATED_CUM: 114,  CLOSED_CUM: 76,   REMAIN_CUM: 38,   TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-10-13', CREATED_WK: 1,    CLOSED_WK: 7,   CREATED_CUM: 115,  CLOSED_CUM: 83,   REMAIN_CUM: 32,   TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-10-20', CREATED_WK: 18,   CLOSED_WK: 21,  CREATED_CUM: 133,  CLOSED_CUM: 104,  REMAIN_CUM: 29,   TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-10-27', CREATED_WK: 1,    CLOSED_WK: 0,   CREATED_CUM: 134,  CLOSED_CUM: 104,  REMAIN_CUM: 30,   TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-11-03', CREATED_WK: 0,    CLOSED_WK: 9,   CREATED_CUM: 134,  CLOSED_CUM: 113,  REMAIN_CUM: 21,   TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-11-10', CREATED_WK: 41,   CLOSED_WK: 26,  CREATED_CUM: 175,  CLOSED_CUM: 139,  REMAIN_CUM: 36,   TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-11-17', CREATED_WK: 25,   CLOSED_WK: 30,  CREATED_CUM: 200,  CLOSED_CUM: 169,  REMAIN_CUM: 31,   TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-11-24', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 200,  CLOSED_CUM: 169,  REMAIN_CUM: 31,   TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-12-01', CREATED_WK: 24,   CLOSED_WK: 5,   CREATED_CUM: 224,  CLOSED_CUM: 174,  REMAIN_CUM: 50,   TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-12-08', CREATED_WK: 268,  CLOSED_WK: 17,  CREATED_CUM: 492,  CLOSED_CUM: 191,  REMAIN_CUM: 301,  TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-12-15', CREATED_WK: 35,   CLOSED_WK: 6,   CREATED_CUM: 527,  CLOSED_CUM: 197,  REMAIN_CUM: 330,  TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-12-22', CREATED_WK: 466,  CLOSED_WK: 12,  CREATED_CUM: 993,  CLOSED_CUM: 209,  REMAIN_CUM: 784,  TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 10,  CREATED_CUM: 993,  CLOSED_CUM: 219,  REMAIN_CUM: 774,  TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2024-01-05', CREATED_WK: 4,    CLOSED_WK: 23,  CREATED_CUM: 997,  CLOSED_CUM: 242,  REMAIN_CUM: 755,  TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2024-01-12', CREATED_WK: 12,   CLOSED_WK: 1,   CREATED_CUM: 1009, CLOSED_CUM: 243,  REMAIN_CUM: 766,  TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2024-01-19', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 1009, CLOSED_CUM: 243,  REMAIN_CUM: 766,  TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 1009, CLOSED_CUM: 243,  REMAIN_CUM: 766,  TOTAL: 1009, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-07-07', CREATED_WK: 0,    CLOSED_WK: 2,   CREATED_CUM: 61,   CLOSED_CUM: 55,   REMAIN_CUM: 6,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-07-14', CREATED_WK: 2,    CLOSED_WK: 1,   CREATED_CUM: 63,   CLOSED_CUM: 56,   REMAIN_CUM: 7,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-07-21', CREATED_WK: 4,    CLOSED_WK: 3,   CREATED_CUM: 67,   CLOSED_CUM: 59,   REMAIN_CUM: 8,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-07-28', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 67,   CLOSED_CUM: 59,   REMAIN_CUM: 8,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 2,   CREATED_CUM: 67,   CLOSED_CUM: 61,   REMAIN_CUM: 6,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 67,   CLOSED_CUM: 61,   REMAIN_CUM: 6,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 67,   CLOSED_CUM: 61,   REMAIN_CUM: 6,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-08-25', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 67,   CLOSED_CUM: 61,   REMAIN_CUM: 6,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-09-01', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 67,   CLOSED_CUM: 61,   REMAIN_CUM: 6,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-09-08', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 67,   CLOSED_CUM: 61,   REMAIN_CUM: 6,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-09-15', CREATED_WK: 2,    CLOSED_WK: 0,   CREATED_CUM: 69,   CLOSED_CUM: 61,   REMAIN_CUM: 8,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-09-22', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 69,   CLOSED_CUM: 61,   REMAIN_CUM: 8,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-09-29', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 69,   CLOSED_CUM: 61,   REMAIN_CUM: 8,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 2,   CREATED_CUM: 69,   CLOSED_CUM: 63,   REMAIN_CUM: 6,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-10-13', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 69,   CLOSED_CUM: 63,   REMAIN_CUM: 6,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-10-20', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 69,   CLOSED_CUM: 63,   REMAIN_CUM: 6,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-10-27', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 69,   CLOSED_CUM: 63,   REMAIN_CUM: 6,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-11-03', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 69,   CLOSED_CUM: 63,   REMAIN_CUM: 6,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-11-10', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 69,   CLOSED_CUM: 63,   REMAIN_CUM: 6,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 69,   CLOSED_CUM: 63,   REMAIN_CUM: 6,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-11-24', CREATED_WK: 1,    CLOSED_WK: 0,   CREATED_CUM: 70,   CLOSED_CUM: 63,   REMAIN_CUM: 7,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-12-01', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 70,   CLOSED_CUM: 63,   REMAIN_CUM: 7,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 1,   CREATED_CUM: 70,   CLOSED_CUM: 64,   REMAIN_CUM: 6,    TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-12-15', CREATED_WK: 166,  CLOSED_WK: 0,   CREATED_CUM: 236,  CLOSED_CUM: 64,   REMAIN_CUM: 172,  TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-12-22', CREATED_WK: 1,    CLOSED_WK: 28,  CREATED_CUM: 237,  CLOSED_CUM: 92,   REMAIN_CUM: 145,  TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 237,  CLOSED_CUM: 92,   REMAIN_CUM: 145,  TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2024-01-05', CREATED_WK: 0,    CLOSED_WK: 46,  CREATED_CUM: 237,  CLOSED_CUM: 138,  REMAIN_CUM: 99,   TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2024-01-12', CREATED_WK: 0,    CLOSED_WK: 13,  CREATED_CUM: 237,  CLOSED_CUM: 151,  REMAIN_CUM: 86,   TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2024-01-19', CREATED_WK: 21,   CLOSED_WK: 0,   CREATED_CUM: 258,  CLOSED_CUM: 151,  REMAIN_CUM: 107,  TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 258,  CLOSED_CUM: 151,  REMAIN_CUM: 107,  TOTAL: 258, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-07-07', CREATED_WK: 68,   CLOSED_WK: 19,  CREATED_CUM: 1415, CLOSED_CUM: 1212, REMAIN_CUM: 203,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-07-14', CREATED_WK: 75,   CLOSED_WK: 110, CREATED_CUM: 1490, CLOSED_CUM: 1322, REMAIN_CUM: 168,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-07-21', CREATED_WK: 83,   CLOSED_WK: 99,  CREATED_CUM: 1573, CLOSED_CUM: 1421, REMAIN_CUM: 152,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-07-28', CREATED_WK: 31,   CLOSED_WK: 38,  CREATED_CUM: 1604, CLOSED_CUM: 1459, REMAIN_CUM: 145,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-08-04', CREATED_WK: 71,   CLOSED_WK: 18,  CREATED_CUM: 1675, CLOSED_CUM: 1477, REMAIN_CUM: 198,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-08-11', CREATED_WK: 14,   CLOSED_WK: 58,  CREATED_CUM: 1689, CLOSED_CUM: 1535, REMAIN_CUM: 154,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-08-18', CREATED_WK: 24,   CLOSED_WK: 34,  CREATED_CUM: 1713, CLOSED_CUM: 1569, REMAIN_CUM: 144,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-08-25', CREATED_WK: 18,   CLOSED_WK: 16,  CREATED_CUM: 1731, CLOSED_CUM: 1585, REMAIN_CUM: 146,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-09-01', CREATED_WK: 58,   CLOSED_WK: 19,  CREATED_CUM: 1789, CLOSED_CUM: 1604, REMAIN_CUM: 185,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-09-08', CREATED_WK: 214,  CLOSED_WK: 119, CREATED_CUM: 2003, CLOSED_CUM: 1723, REMAIN_CUM: 280,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-09-15', CREATED_WK: 103,  CLOSED_WK: 123, CREATED_CUM: 2106, CLOSED_CUM: 1846, REMAIN_CUM: 260,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-09-22', CREATED_WK: 59,   CLOSED_WK: 42,  CREATED_CUM: 2165, CLOSED_CUM: 1888, REMAIN_CUM: 277,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-09-29', CREATED_WK: 149,  CLOSED_WK: 43,  CREATED_CUM: 2314, CLOSED_CUM: 1931, REMAIN_CUM: 383,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-10-06', CREATED_WK: 59,   CLOSED_WK: 29,  CREATED_CUM: 2373, CLOSED_CUM: 1960, REMAIN_CUM: 413,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-10-13', CREATED_WK: 90,   CLOSED_WK: 60,  CREATED_CUM: 2463, CLOSED_CUM: 2020, REMAIN_CUM: 443,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-10-20', CREATED_WK: 171,  CLOSED_WK: 99,  CREATED_CUM: 2634, CLOSED_CUM: 2119, REMAIN_CUM: 515,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-10-27', CREATED_WK: 202,  CLOSED_WK: 83,  CREATED_CUM: 2836, CLOSED_CUM: 2202, REMAIN_CUM: 634,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-11-03', CREATED_WK: 82,   CLOSED_WK: 136, CREATED_CUM: 2918, CLOSED_CUM: 2338, REMAIN_CUM: 580,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-11-10', CREATED_WK: 284,  CLOSED_WK: 191, CREATED_CUM: 3202, CLOSED_CUM: 2529, REMAIN_CUM: 673,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-11-17', CREATED_WK: 205,  CLOSED_WK: 236, CREATED_CUM: 3407, CLOSED_CUM: 2765, REMAIN_CUM: 642,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-11-24', CREATED_WK: 107,  CLOSED_WK: 61,  CREATED_CUM: 3514, CLOSED_CUM: 2826, REMAIN_CUM: 688,  TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-12-01', CREATED_WK: 716,  CLOSED_WK: 48,  CREATED_CUM: 4230, CLOSED_CUM: 2874, REMAIN_CUM: 1356, TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-12-08', CREATED_WK: 942,  CLOSED_WK: 153, CREATED_CUM: 5172, CLOSED_CUM: 3027, REMAIN_CUM: 2145, TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-12-15', CREATED_WK: 634,  CLOSED_WK: 253, CREATED_CUM: 5806, CLOSED_CUM: 3280, REMAIN_CUM: 2526, TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-12-22', CREATED_WK: 1406, CLOSED_WK: 177, CREATED_CUM: 7212, CLOSED_CUM: 3457, REMAIN_CUM: 3755, TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-12-29', CREATED_WK: 174,  CLOSED_WK: 99,  CREATED_CUM: 7386, CLOSED_CUM: 3556, REMAIN_CUM: 3830, TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2024-01-05', CREATED_WK: 64,   CLOSED_WK: 407, CREATED_CUM: 7450, CLOSED_CUM: 3963, REMAIN_CUM: 3487, TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2024-01-12', CREATED_WK: 67,   CLOSED_WK: 263, CREATED_CUM: 7517, CLOSED_CUM: 4226, REMAIN_CUM: 3291, TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2024-01-19', CREATED_WK: 36,   CLOSED_WK: 20,  CREATED_CUM: 7553, CLOSED_CUM: 4246, REMAIN_CUM: 3307, TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 7553, CLOSED_CUM: 4246, REMAIN_CUM: 3307, TOTAL: 7553, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-07-07', CREATED_WK: 44,   CLOSED_WK: 12,  CREATED_CUM: 1135, CLOSED_CUM: 967,  REMAIN_CUM: 168,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-07-14', CREATED_WK: 40,   CLOSED_WK: 88,  CREATED_CUM: 1175, CLOSED_CUM: 1055, REMAIN_CUM: 120,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-07-21', CREATED_WK: 65,   CLOSED_WK: 72,  CREATED_CUM: 1240, CLOSED_CUM: 1127, REMAIN_CUM: 113,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-07-28', CREATED_WK: 28,   CLOSED_WK: 28,  CREATED_CUM: 1268, CLOSED_CUM: 1155, REMAIN_CUM: 113,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-08-04', CREATED_WK: 69,   CLOSED_WK: 3,   CREATED_CUM: 1337, CLOSED_CUM: 1158, REMAIN_CUM: 179,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-08-11', CREATED_WK: 11,   CLOSED_WK: 57,  CREATED_CUM: 1348, CLOSED_CUM: 1215, REMAIN_CUM: 133,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-08-18', CREATED_WK: 21,   CLOSED_WK: 32,  CREATED_CUM: 1369, CLOSED_CUM: 1247, REMAIN_CUM: 122,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-08-25', CREATED_WK: 8,    CLOSED_WK: 13,  CREATED_CUM: 1377, CLOSED_CUM: 1260, REMAIN_CUM: 117,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-09-01', CREATED_WK: 30,   CLOSED_WK: 6,   CREATED_CUM: 1407, CLOSED_CUM: 1266, REMAIN_CUM: 141,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-09-08', CREATED_WK: 131,  CLOSED_WK: 100, CREATED_CUM: 1538, CLOSED_CUM: 1366, REMAIN_CUM: 172,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-09-15', CREATED_WK: 8,    CLOSED_WK: 51,  CREATED_CUM: 1546, CLOSED_CUM: 1417, REMAIN_CUM: 129,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-09-22', CREATED_WK: 7,    CLOSED_WK: 1,   CREATED_CUM: 1553, CLOSED_CUM: 1418, REMAIN_CUM: 135,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-09-29', CREATED_WK: 41,   CLOSED_WK: 11,  CREATED_CUM: 1594, CLOSED_CUM: 1429, REMAIN_CUM: 165,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-10-06', CREATED_WK: 31,   CLOSED_WK: 11,  CREATED_CUM: 1625, CLOSED_CUM: 1440, REMAIN_CUM: 185,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-10-13', CREATED_WK: 64,   CLOSED_WK: 46,  CREATED_CUM: 1689, CLOSED_CUM: 1486, REMAIN_CUM: 203,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-10-20', CREATED_WK: 53,   CLOSED_WK: 30,  CREATED_CUM: 1742, CLOSED_CUM: 1516, REMAIN_CUM: 226,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-10-27', CREATED_WK: 111,  CLOSED_WK: 17,  CREATED_CUM: 1853, CLOSED_CUM: 1533, REMAIN_CUM: 320,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-11-03', CREATED_WK: 70,   CLOSED_WK: 55,  CREATED_CUM: 1923, CLOSED_CUM: 1588, REMAIN_CUM: 335,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-11-10', CREATED_WK: 185,  CLOSED_WK: 92,  CREATED_CUM: 2108, CLOSED_CUM: 1680, REMAIN_CUM: 428,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-11-17', CREATED_WK: 91,   CLOSED_WK: 140, CREATED_CUM: 2199, CLOSED_CUM: 1820, REMAIN_CUM: 379,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-11-24', CREATED_WK: 27,   CLOSED_WK: 21,  CREATED_CUM: 2226, CLOSED_CUM: 1841, REMAIN_CUM: 385,  TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-12-01', CREATED_WK: 659,  CLOSED_WK: 24,  CREATED_CUM: 2885, CLOSED_CUM: 1865, REMAIN_CUM: 1020, TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-12-08', CREATED_WK: 417,  CLOSED_WK: 122, CREATED_CUM: 3302, CLOSED_CUM: 1987, REMAIN_CUM: 1315, TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-12-15', CREATED_WK: 374,  CLOSED_WK: 196, CREATED_CUM: 3676, CLOSED_CUM: 2183, REMAIN_CUM: 1493, TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-12-22', CREATED_WK: 328,  CLOSED_WK: 129, CREATED_CUM: 4004, CLOSED_CUM: 2312, REMAIN_CUM: 1692, TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-12-29', CREATED_WK: 123,  CLOSED_WK: 87,  CREATED_CUM: 4127, CLOSED_CUM: 2399, REMAIN_CUM: 1728, TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2024-01-05', CREATED_WK: 59,   CLOSED_WK: 224, CREATED_CUM: 4186, CLOSED_CUM: 2623, REMAIN_CUM: 1563, TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2024-01-12', CREATED_WK: 15,   CLOSED_WK: 245, CREATED_CUM: 4201, CLOSED_CUM: 2868, REMAIN_CUM: 1333, TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2024-01-19', CREATED_WK: 1,    CLOSED_WK: 17,  CREATED_CUM: 4202, CLOSED_CUM: 2885, REMAIN_CUM: 1317, TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 4202, CLOSED_CUM: 2885, REMAIN_CUM: 1317, TOTAL: 4202, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-07-07', CREATED_WK: 1,    CLOSED_WK: 2,   CREATED_CUM: 62,   CLOSED_CUM: 61,   REMAIN_CUM: 1,    TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-07-14', CREATED_WK: 1,    CLOSED_WK: 0,   CREATED_CUM: 63,   CLOSED_CUM: 61,   REMAIN_CUM: 2,    TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-07-21', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 63,   CLOSED_CUM: 61,   REMAIN_CUM: 2,    TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-07-28', CREATED_WK: 0,    CLOSED_WK: 1,   CREATED_CUM: 63,   CLOSED_CUM: 62,   REMAIN_CUM: 1,    TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 63,   CLOSED_CUM: 62,   REMAIN_CUM: 1,    TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 63,   CLOSED_CUM: 62,   REMAIN_CUM: 1,    TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 63,   CLOSED_CUM: 62,   REMAIN_CUM: 1,    TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-08-25', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 63,   CLOSED_CUM: 62,   REMAIN_CUM: 1,    TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-09-01', CREATED_WK: 13,   CLOSED_WK: 0,   CREATED_CUM: 76,   CLOSED_CUM: 62,   REMAIN_CUM: 14,   TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-09-08', CREATED_WK: 28,   CLOSED_WK: 6,   CREATED_CUM: 104,  CLOSED_CUM: 68,   REMAIN_CUM: 36,   TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-09-15', CREATED_WK: 0,    CLOSED_WK: 30,  CREATED_CUM: 104,  CLOSED_CUM: 98,   REMAIN_CUM: 6,    TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-09-22', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 104,  CLOSED_CUM: 98,   REMAIN_CUM: 6,    TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-09-29', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 104,  CLOSED_CUM: 98,   REMAIN_CUM: 6,    TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 104,  CLOSED_CUM: 98,   REMAIN_CUM: 6,    TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-10-13', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 104,  CLOSED_CUM: 98,   REMAIN_CUM: 6,    TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-10-20', CREATED_WK: 2,    CLOSED_WK: 0,   CREATED_CUM: 106,  CLOSED_CUM: 98,   REMAIN_CUM: 8,    TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-10-27', CREATED_WK: 13,   CLOSED_WK: 0,   CREATED_CUM: 119,  CLOSED_CUM: 98,   REMAIN_CUM: 21,   TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-11-03', CREATED_WK: 2,    CLOSED_WK: 2,   CREATED_CUM: 121,  CLOSED_CUM: 100,  REMAIN_CUM: 21,   TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-11-10', CREATED_WK: 11,   CLOSED_WK: 8,   CREATED_CUM: 132,  CLOSED_CUM: 108,  REMAIN_CUM: 24,   TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-11-17', CREATED_WK: 34,   CLOSED_WK: 4,   CREATED_CUM: 166,  CLOSED_CUM: 112,  REMAIN_CUM: 54,   TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-11-24', CREATED_WK: 1,    CLOSED_WK: 0,   CREATED_CUM: 167,  CLOSED_CUM: 112,  REMAIN_CUM: 55,   TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-12-01', CREATED_WK: 1,    CLOSED_WK: 1,   CREATED_CUM: 168,  CLOSED_CUM: 113,  REMAIN_CUM: 55,   TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-12-08', CREATED_WK: 2,    CLOSED_WK: 1,   CREATED_CUM: 170,  CLOSED_CUM: 114,  REMAIN_CUM: 56,   TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-12-15', CREATED_WK: 4,    CLOSED_WK: 0,   CREATED_CUM: 174,  CLOSED_CUM: 114,  REMAIN_CUM: 60,   TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-12-22', CREATED_WK: 27,   CLOSED_WK: 0,   CREATED_CUM: 201,  CLOSED_CUM: 114,  REMAIN_CUM: 87,   TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-12-29', CREATED_WK: 33,   CLOSED_WK: 0,   CREATED_CUM: 234,  CLOSED_CUM: 114,  REMAIN_CUM: 120,  TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2024-01-05', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 234,  CLOSED_CUM: 114,  REMAIN_CUM: 120,  TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2024-01-12', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 234,  CLOSED_CUM: 114,  REMAIN_CUM: 120,  TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2024-01-19', CREATED_WK: 0,    CLOSED_WK: 1,   CREATED_CUM: 234,  CLOSED_CUM: 115,  REMAIN_CUM: 119,  TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 234,  CLOSED_CUM: 115,  REMAIN_CUM: 119,  TOTAL: 234, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-07-07', CREATED_WK: 9,    CLOSED_WK: 0,   CREATED_CUM: 50,   CLOSED_CUM: 41,   REMAIN_CUM: 9,    TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-07-14', CREATED_WK: 0,    CLOSED_WK: 1,   CREATED_CUM: 50,   CLOSED_CUM: 42,   REMAIN_CUM: 8,    TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-07-21', CREATED_WK: 4,    CLOSED_WK: 1,   CREATED_CUM: 54,   CLOSED_CUM: 43,   REMAIN_CUM: 11,   TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-07-28', CREATED_WK: 0,    CLOSED_WK: 3,   CREATED_CUM: 54,   CLOSED_CUM: 46,   REMAIN_CUM: 8,    TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-08-04', CREATED_WK: 1,    CLOSED_WK: 4,   CREATED_CUM: 55,   CLOSED_CUM: 50,   REMAIN_CUM: 5,    TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-08-11', CREATED_WK: 3,    CLOSED_WK: 1,   CREATED_CUM: 58,   CLOSED_CUM: 51,   REMAIN_CUM: 7,    TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-08-18', CREATED_WK: 3,    CLOSED_WK: 2,   CREATED_CUM: 61,   CLOSED_CUM: 53,   REMAIN_CUM: 8,    TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-08-25', CREATED_WK: 2,    CLOSED_WK: 2,   CREATED_CUM: 63,   CLOSED_CUM: 55,   REMAIN_CUM: 8,    TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-09-01', CREATED_WK: 0,    CLOSED_WK: 5,   CREATED_CUM: 63,   CLOSED_CUM: 60,   REMAIN_CUM: 3,    TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-09-08', CREATED_WK: 34,   CLOSED_WK: 3,   CREATED_CUM: 97,   CLOSED_CUM: 63,   REMAIN_CUM: 34,   TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-09-15', CREATED_WK: 3,    CLOSED_WK: 5,   CREATED_CUM: 100,  CLOSED_CUM: 68,   REMAIN_CUM: 32,   TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-09-22', CREATED_WK: 2,    CLOSED_WK: 9,   CREATED_CUM: 102,  CLOSED_CUM: 77,   REMAIN_CUM: 25,   TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-09-29', CREATED_WK: 4,    CLOSED_WK: 4,   CREATED_CUM: 106,  CLOSED_CUM: 81,   REMAIN_CUM: 25,   TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-10-06', CREATED_WK: 4,    CLOSED_WK: 5,   CREATED_CUM: 110,  CLOSED_CUM: 86,   REMAIN_CUM: 24,   TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-10-13', CREATED_WK: 2,    CLOSED_WK: 6,   CREATED_CUM: 112,  CLOSED_CUM: 92,   REMAIN_CUM: 20,   TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-10-20', CREATED_WK: 8,    CLOSED_WK: 5,   CREATED_CUM: 120,  CLOSED_CUM: 97,   REMAIN_CUM: 23,   TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-10-27', CREATED_WK: 2,    CLOSED_WK: 8,   CREATED_CUM: 122,  CLOSED_CUM: 105,  REMAIN_CUM: 17,   TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-11-03', CREATED_WK: 3,    CLOSED_WK: 7,   CREATED_CUM: 125,  CLOSED_CUM: 112,  REMAIN_CUM: 13,   TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-11-10', CREATED_WK: 3,    CLOSED_WK: 2,   CREATED_CUM: 128,  CLOSED_CUM: 114,  REMAIN_CUM: 14,   TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 3,   CREATED_CUM: 128,  CLOSED_CUM: 117,  REMAIN_CUM: 11,   TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-11-24', CREATED_WK: 58,   CLOSED_WK: 0,   CREATED_CUM: 186,  CLOSED_CUM: 117,  REMAIN_CUM: 69,   TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-12-01', CREATED_WK: 27,   CLOSED_WK: 2,   CREATED_CUM: 213,  CLOSED_CUM: 119,  REMAIN_CUM: 94,   TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 7,   CREATED_CUM: 213,  CLOSED_CUM: 126,  REMAIN_CUM: 87,   TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-12-15', CREATED_WK: 48,   CLOSED_WK: 12,  CREATED_CUM: 261,  CLOSED_CUM: 138,  REMAIN_CUM: 123,  TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-12-22', CREATED_WK: 25,   CLOSED_WK: 4,   CREATED_CUM: 286,  CLOSED_CUM: 142,  REMAIN_CUM: 144,  TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-12-29', CREATED_WK: 1,    CLOSED_WK: 2,   CREATED_CUM: 287,  CLOSED_CUM: 144,  REMAIN_CUM: 143,  TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2024-01-05', CREATED_WK: 1,    CLOSED_WK: 13,  CREATED_CUM: 288,  CLOSED_CUM: 157,  REMAIN_CUM: 131,  TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2024-01-12', CREATED_WK: 23,   CLOSED_WK: 2,   CREATED_CUM: 311,  CLOSED_CUM: 159,  REMAIN_CUM: 152,  TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2024-01-19', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 311,  CLOSED_CUM: 159,  REMAIN_CUM: 152,  TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 311,  CLOSED_CUM: 159,  REMAIN_CUM: 152,  TOTAL: 311, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-07-07', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 19,   CLOSED_CUM: 19,   REMAIN_CUM: 0,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-07-14', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 19,   CLOSED_CUM: 19,   REMAIN_CUM: 0,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-07-21', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 19,   CLOSED_CUM: 19,   REMAIN_CUM: 0,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-07-28', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 19,   CLOSED_CUM: 19,   REMAIN_CUM: 0,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 19,   CLOSED_CUM: 19,   REMAIN_CUM: 0,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 19,   CLOSED_CUM: 19,   REMAIN_CUM: 0,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 19,   CLOSED_CUM: 19,   REMAIN_CUM: 0,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-08-25', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 19,   CLOSED_CUM: 19,   REMAIN_CUM: 0,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-09-01', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 19,   CLOSED_CUM: 19,   REMAIN_CUM: 0,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-09-08', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 19,   CLOSED_CUM: 19,   REMAIN_CUM: 0,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-09-15', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 19,   CLOSED_CUM: 19,   REMAIN_CUM: 0,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-09-22', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 19,   CLOSED_CUM: 19,   REMAIN_CUM: 0,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-09-29', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 19,   CLOSED_CUM: 19,   REMAIN_CUM: 0,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-10-06', CREATED_WK: 1,    CLOSED_WK: 0,   CREATED_CUM: 20,   CLOSED_CUM: 19,   REMAIN_CUM: 1,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-10-13', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 20,   CLOSED_CUM: 19,   REMAIN_CUM: 1,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-10-20', CREATED_WK: 0,    CLOSED_WK: 1,   CREATED_CUM: 20,   CLOSED_CUM: 20,   REMAIN_CUM: 0,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-10-27', CREATED_WK: 4,    CLOSED_WK: 3,   CREATED_CUM: 24,   CLOSED_CUM: 23,   REMAIN_CUM: 1,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-11-03', CREATED_WK: 0,    CLOSED_WK: 1,   CREATED_CUM: 24,   CLOSED_CUM: 24,   REMAIN_CUM: 0,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-11-10', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 24,   CLOSED_CUM: 24,   REMAIN_CUM: 0,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-11-17', CREATED_WK: 5,    CLOSED_WK: 0,   CREATED_CUM: 29,   CLOSED_CUM: 24,   REMAIN_CUM: 5,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-11-24', CREATED_WK: 1,    CLOSED_WK: 5,   CREATED_CUM: 30,   CLOSED_CUM: 29,   REMAIN_CUM: 1,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-12-01', CREATED_WK: 0,    CLOSED_WK: 1,   CREATED_CUM: 30,   CLOSED_CUM: 30,   REMAIN_CUM: 0,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 30,   CLOSED_CUM: 30,   REMAIN_CUM: 0,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-12-15', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 30,   CLOSED_CUM: 30,   REMAIN_CUM: 0,    TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-12-22', CREATED_WK: 36,   CLOSED_WK: 0,   CREATED_CUM: 66,   CLOSED_CUM: 30,   REMAIN_CUM: 36,   TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-12-29', CREATED_WK: 2,    CLOSED_WK: 0,   CREATED_CUM: 68,   CLOSED_CUM: 30,   REMAIN_CUM: 38,   TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2024-01-05', CREATED_WK: 0,    CLOSED_WK: 2,   CREATED_CUM: 68,   CLOSED_CUM: 32,   REMAIN_CUM: 36,   TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2024-01-12', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 68,   CLOSED_CUM: 32,   REMAIN_CUM: 36,   TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2024-01-19', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 68,   CLOSED_CUM: 32,   REMAIN_CUM: 36,   TOTAL: 68, },
    { ITEM: 'AP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 68,   CLOSED_CUM: 32,   REMAIN_CUM: 36,   TOTAL: 68, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-07-07', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 20,   CLOSED_CUM: 20,   REMAIN_CUM: 0,    TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-07-14', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 20,   CLOSED_CUM: 20,   REMAIN_CUM: 0,    TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-07-21', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 20,   CLOSED_CUM: 20,   REMAIN_CUM: 0,    TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-07-28', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 20,   CLOSED_CUM: 20,   REMAIN_CUM: 0,    TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 20,   CLOSED_CUM: 20,   REMAIN_CUM: 0,    TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 20,   CLOSED_CUM: 20,   REMAIN_CUM: 0,    TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 20,   CLOSED_CUM: 20,   REMAIN_CUM: 0,    TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-08-25', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 20,   CLOSED_CUM: 20,   REMAIN_CUM: 0,    TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-09-01', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 20,   CLOSED_CUM: 20,   REMAIN_CUM: 0,    TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-09-08', CREATED_WK: 4,    CLOSED_WK: 0,   CREATED_CUM: 24,   CLOSED_CUM: 20,   REMAIN_CUM: 4,    TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-09-15', CREATED_WK: 32,   CLOSED_WK: 1,   CREATED_CUM: 56,   CLOSED_CUM: 21,   REMAIN_CUM: 35,   TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-09-22', CREATED_WK: 0,    CLOSED_WK: 22,  CREATED_CUM: 56,   CLOSED_CUM: 43,   REMAIN_CUM: 13,   TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-09-29', CREATED_WK: 19,   CLOSED_WK: 5,   CREATED_CUM: 75,   CLOSED_CUM: 48,   REMAIN_CUM: 27,   TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-10-06', CREATED_WK: 4,    CLOSED_WK: 14,  CREATED_CUM: 79,   CLOSED_CUM: 62,   REMAIN_CUM: 17,   TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-10-13', CREATED_WK: 89,   CLOSED_WK: 4,   CREATED_CUM: 168,  CLOSED_CUM: 66,   REMAIN_CUM: 102,  TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-10-20', CREATED_WK: 4,    CLOSED_WK: 24,  CREATED_CUM: 172,  CLOSED_CUM: 90,   REMAIN_CUM: 82,   TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-10-27', CREATED_WK: 4,    CLOSED_WK: 8,   CREATED_CUM: 176,  CLOSED_CUM: 98,   REMAIN_CUM: 78,   TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-11-03', CREATED_WK: 13,   CLOSED_WK: 33,  CREATED_CUM: 189,  CLOSED_CUM: 131,  REMAIN_CUM: 58,   TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-11-10', CREATED_WK: 2,    CLOSED_WK: 31,  CREATED_CUM: 191,  CLOSED_CUM: 162,  REMAIN_CUM: 29,   TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-11-17', CREATED_WK: 3,    CLOSED_WK: 12,  CREATED_CUM: 194,  CLOSED_CUM: 174,  REMAIN_CUM: 20,   TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-11-24', CREATED_WK: 0,    CLOSED_WK: 1,   CREATED_CUM: 194,  CLOSED_CUM: 175,  REMAIN_CUM: 19,   TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-12-01', CREATED_WK: 0,    CLOSED_WK: 1,   CREATED_CUM: 194,  CLOSED_CUM: 176,  REMAIN_CUM: 18,   TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 3,   CREATED_CUM: 194,  CLOSED_CUM: 179,  REMAIN_CUM: 15,   TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-12-15', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 194,  CLOSED_CUM: 179,  REMAIN_CUM: 15,   TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-12-22', CREATED_WK: 4,    CLOSED_WK: 2,   CREATED_CUM: 198,  CLOSED_CUM: 181,  REMAIN_CUM: 17,   TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 198,  CLOSED_CUM: 181,  REMAIN_CUM: 17,   TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2024-01-05', CREATED_WK: 28,   CLOSED_WK: 0,   CREATED_CUM: 226,  CLOSED_CUM: 181,  REMAIN_CUM: 45,   TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2024-01-12', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 226,  CLOSED_CUM: 181,  REMAIN_CUM: 45,   TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2024-01-19', CREATED_WK: 40,   CLOSED_WK: 0,   CREATED_CUM: 266,  CLOSED_CUM: 181,  REMAIN_CUM: 85,   TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_LONG: 'C-Civil',           CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 266,  CLOSED_CUM: 181,  REMAIN_CUM: 85,   TOTAL: 266, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-07-07', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 26,   CLOSED_CUM: 26,   REMAIN_CUM: 0,    TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-07-14', CREATED_WK: 5,    CLOSED_WK: 2,   CREATED_CUM: 31,   CLOSED_CUM: 28,   REMAIN_CUM: 3,    TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-07-21', CREATED_WK: 3,    CLOSED_WK: 4,   CREATED_CUM: 34,   CLOSED_CUM: 32,   REMAIN_CUM: 2,    TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-07-28', CREATED_WK: 1,    CLOSED_WK: 1,   CREATED_CUM: 35,   CLOSED_CUM: 33,   REMAIN_CUM: 2,    TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-08-04', CREATED_WK: 26,   CLOSED_WK: 0,   CREATED_CUM: 61,   CLOSED_CUM: 33,   REMAIN_CUM: 28,   TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 2,   CREATED_CUM: 61,   CLOSED_CUM: 35,   REMAIN_CUM: 26,   TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 61,   CLOSED_CUM: 35,   REMAIN_CUM: 26,   TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-08-25', CREATED_WK: 11,   CLOSED_WK: 19,  CREATED_CUM: 72,   CLOSED_CUM: 54,   REMAIN_CUM: 18,   TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-09-01', CREATED_WK: 18,   CLOSED_WK: 18,  CREATED_CUM: 90,   CLOSED_CUM: 72,   REMAIN_CUM: 18,   TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-09-08', CREATED_WK: 20,   CLOSED_WK: 5,   CREATED_CUM: 110,  CLOSED_CUM: 77,   REMAIN_CUM: 33,   TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-09-15', CREATED_WK: 29,   CLOSED_WK: 0,   CREATED_CUM: 139,  CLOSED_CUM: 77,   REMAIN_CUM: 62,   TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-09-22', CREATED_WK: 18,   CLOSED_WK: 23,  CREATED_CUM: 157,  CLOSED_CUM: 100,  REMAIN_CUM: 57,   TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-09-29', CREATED_WK: 76,   CLOSED_WK: 20,  CREATED_CUM: 233,  CLOSED_CUM: 120,  REMAIN_CUM: 113,  TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-10-06', CREATED_WK: 6,    CLOSED_WK: 11,  CREATED_CUM: 239,  CLOSED_CUM: 131,  REMAIN_CUM: 108,  TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-10-13', CREATED_WK: 4,    CLOSED_WK: 1,   CREATED_CUM: 243,  CLOSED_CUM: 132,  REMAIN_CUM: 111,  TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-10-20', CREATED_WK: 61,   CLOSED_WK: 46,  CREATED_CUM: 304,  CLOSED_CUM: 178,  REMAIN_CUM: 126,  TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-10-27', CREATED_WK: 51,   CLOSED_WK: 17,  CREATED_CUM: 355,  CLOSED_CUM: 195,  REMAIN_CUM: 160,  TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-11-03', CREATED_WK: 8,    CLOSED_WK: 28,  CREATED_CUM: 363,  CLOSED_CUM: 223,  REMAIN_CUM: 140,  TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-11-10', CREATED_WK: 18,   CLOSED_WK: 63,  CREATED_CUM: 381,  CLOSED_CUM: 286,  REMAIN_CUM: 95,   TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-11-17', CREATED_WK: 22,   CLOSED_WK: 47,  CREATED_CUM: 403,  CLOSED_CUM: 333,  REMAIN_CUM: 70,   TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-11-24', CREATED_WK: 42,   CLOSED_WK: 25,  CREATED_CUM: 445,  CLOSED_CUM: 358,  REMAIN_CUM: 87,   TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-12-01', CREATED_WK: 21,   CLOSED_WK: 36,  CREATED_CUM: 466,  CLOSED_CUM: 394,  REMAIN_CUM: 72,   TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-12-08', CREATED_WK: 12,   CLOSED_WK: 18,  CREATED_CUM: 478,  CLOSED_CUM: 412,  REMAIN_CUM: 66,   TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-12-15', CREATED_WK: 21,   CLOSED_WK: 20,  CREATED_CUM: 499,  CLOSED_CUM: 432,  REMAIN_CUM: 67,   TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-12-22', CREATED_WK: 20,   CLOSED_WK: 6,   CREATED_CUM: 519,  CLOSED_CUM: 438,  REMAIN_CUM: 81,   TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2023-12-29', CREATED_WK: 2,    CLOSED_WK: 5,   CREATED_CUM: 521,  CLOSED_CUM: 443,  REMAIN_CUM: 78,   TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2024-01-05', CREATED_WK: 3,    CLOSED_WK: 0,   CREATED_CUM: 524,  CLOSED_CUM: 443,  REMAIN_CUM: 81,   TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2024-01-12', CREATED_WK: 23,   CLOSED_WK: 0,   CREATED_CUM: 547,  CLOSED_CUM: 443,  REMAIN_CUM: 104,  TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2024-01-19', CREATED_WK: 7,    CLOSED_WK: 2,   CREATED_CUM: 554,  CLOSED_CUM: 445,  REMAIN_CUM: 109,  TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_LONG: 'E-Electrical',      CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 554,  CLOSED_CUM: 445,  REMAIN_CUM: 109,  TOTAL: 554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-07-07', CREATED_WK: 12,   CLOSED_WK: 0,   CREATED_CUM: 38,   CLOSED_CUM: 24,   REMAIN_CUM: 14,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-07-14', CREATED_WK: 25,   CLOSED_WK: 15,  CREATED_CUM: 63,   CLOSED_CUM: 39,   REMAIN_CUM: 24,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-07-21', CREATED_WK: 7,    CLOSED_WK: 14,  CREATED_CUM: 70,   CLOSED_CUM: 53,   REMAIN_CUM: 17,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-07-28', CREATED_WK: 3,    CLOSED_WK: 12,  CREATED_CUM: 73,   CLOSED_CUM: 65,   REMAIN_CUM: 8,    TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 4,   CREATED_CUM: 73,   CLOSED_CUM: 69,   REMAIN_CUM: 4,    TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 1,   CREATED_CUM: 73,   CLOSED_CUM: 70,   REMAIN_CUM: 3,    TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 73,   CLOSED_CUM: 70,   REMAIN_CUM: 3,    TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-08-25', CREATED_WK: 13,   CLOSED_WK: 2,   CREATED_CUM: 86,   CLOSED_CUM: 72,   REMAIN_CUM: 14,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-09-01', CREATED_WK: 1,    CLOSED_WK: 10,  CREATED_CUM: 87,   CLOSED_CUM: 82,   REMAIN_CUM: 5,    TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-09-08', CREATED_WK: 8,    CLOSED_WK: 3,   CREATED_CUM: 95,   CLOSED_CUM: 85,   REMAIN_CUM: 10,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-09-15', CREATED_WK: 4,    CLOSED_WK: 1,   CREATED_CUM: 99,   CLOSED_CUM: 86,   REMAIN_CUM: 13,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-09-22', CREATED_WK: 6,    CLOSED_WK: 0,   CREATED_CUM: 105,  CLOSED_CUM: 86,   REMAIN_CUM: 19,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-09-29', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 105,  CLOSED_CUM: 86,   REMAIN_CUM: 19,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-10-06', CREATED_WK: 9,    CLOSED_WK: 0,   CREATED_CUM: 114,  CLOSED_CUM: 86,   REMAIN_CUM: 28,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-10-13', CREATED_WK: 1,    CLOSED_WK: 3,   CREATED_CUM: 115,  CLOSED_CUM: 89,   REMAIN_CUM: 26,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-10-20', CREATED_WK: 23,   CLOSED_WK: 19,  CREATED_CUM: 138,  CLOSED_CUM: 108,  REMAIN_CUM: 30,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-10-27', CREATED_WK: 6,    CLOSED_WK: 0,   CREATED_CUM: 144,  CLOSED_CUM: 108,  REMAIN_CUM: 36,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-11-03', CREATED_WK: 15,   CLOSED_WK: 7,   CREATED_CUM: 159,  CLOSED_CUM: 115,  REMAIN_CUM: 44,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-11-10', CREATED_WK: 9,    CLOSED_WK: 5,   CREATED_CUM: 168,  CLOSED_CUM: 120,  REMAIN_CUM: 48,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-11-17', CREATED_WK: 21,   CLOSED_WK: 18,  CREATED_CUM: 189,  CLOSED_CUM: 138,  REMAIN_CUM: 51,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-11-24', CREATED_WK: 9,    CLOSED_WK: 0,   CREATED_CUM: 198,  CLOSED_CUM: 138,  REMAIN_CUM: 60,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-12-01', CREATED_WK: 13,   CLOSED_WK: 4,   CREATED_CUM: 211,  CLOSED_CUM: 142,  REMAIN_CUM: 69,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-12-08', CREATED_WK: 8,    CLOSED_WK: 12,  CREATED_CUM: 219,  CLOSED_CUM: 154,  REMAIN_CUM: 65,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-12-15', CREATED_WK: 11,   CLOSED_WK: 7,   CREATED_CUM: 230,  CLOSED_CUM: 161,  REMAIN_CUM: 69,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-12-22', CREATED_WK: 7,    CLOSED_WK: 1,   CREATED_CUM: 237,  CLOSED_CUM: 162,  REMAIN_CUM: 75,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 2,   CREATED_CUM: 237,  CLOSED_CUM: 164,  REMAIN_CUM: 73,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2024-01-05', CREATED_WK: 1,    CLOSED_WK: 0,   CREATED_CUM: 238,  CLOSED_CUM: 164,  REMAIN_CUM: 74,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2024-01-12', CREATED_WK: 2,    CLOSED_WK: 1,   CREATED_CUM: 240,  CLOSED_CUM: 165,  REMAIN_CUM: 75,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2024-01-19', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 240,  CLOSED_CUM: 165,  REMAIN_CUM: 75,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_LONG: 'I-Instrument',      CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 240,  CLOSED_CUM: 165,  REMAIN_CUM: 75,   TOTAL: 240, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-07-07', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 69,   CLOSED_CUM: 58,   REMAIN_CUM: 11,   TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-07-14', CREATED_WK: 1,    CLOSED_WK: 0,   CREATED_CUM: 70,   CLOSED_CUM: 58,   REMAIN_CUM: 12,   TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-07-21', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 70,   CLOSED_CUM: 58,   REMAIN_CUM: 12,   TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-07-28', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 70,   CLOSED_CUM: 58,   REMAIN_CUM: 12,   TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-08-04', CREATED_WK: 1,    CLOSED_WK: 0,   CREATED_CUM: 71,   CLOSED_CUM: 58,   REMAIN_CUM: 13,   TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-08-11', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 71,   CLOSED_CUM: 58,   REMAIN_CUM: 13,   TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 7,   CREATED_CUM: 71,   CLOSED_CUM: 65,   REMAIN_CUM: 6,    TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-08-25', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 71,   CLOSED_CUM: 65,   REMAIN_CUM: 6,    TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-09-01', CREATED_WK: 3,    CLOSED_WK: 1,   CREATED_CUM: 74,   CLOSED_CUM: 66,   REMAIN_CUM: 8,    TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-09-08', CREATED_WK: 0,    CLOSED_WK: 1,   CREATED_CUM: 74,   CLOSED_CUM: 67,   REMAIN_CUM: 7,    TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-09-15', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 74,   CLOSED_CUM: 67,   REMAIN_CUM: 7,    TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-09-22', CREATED_WK: 0,    CLOSED_WK: 4,   CREATED_CUM: 74,   CLOSED_CUM: 71,   REMAIN_CUM: 3,    TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-09-29', CREATED_WK: 4,    CLOSED_WK: 0,   CREATED_CUM: 78,   CLOSED_CUM: 71,   REMAIN_CUM: 7,    TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 2,   CREATED_CUM: 78,   CLOSED_CUM: 73,   REMAIN_CUM: 5,    TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-10-13', CREATED_WK: 0,    CLOSED_WK: 4,   CREATED_CUM: 78,   CLOSED_CUM: 77,   REMAIN_CUM: 1,    TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-10-20', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 78,   CLOSED_CUM: 77,   REMAIN_CUM: 1,    TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-10-27', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 78,   CLOSED_CUM: 77,   REMAIN_CUM: 1,    TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-11-03', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 78,   CLOSED_CUM: 77,   REMAIN_CUM: 1,    TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-11-10', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 78,   CLOSED_CUM: 77,   REMAIN_CUM: 1,    TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 78,   CLOSED_CUM: 77,   REMAIN_CUM: 1,    TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-11-24', CREATED_WK: 24,   CLOSED_WK: 0,   CREATED_CUM: 102,  CLOSED_CUM: 77,   REMAIN_CUM: 25,   TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-12-01', CREATED_WK: 0,    CLOSED_WK: 2,   CREATED_CUM: 102,  CLOSED_CUM: 79,   REMAIN_CUM: 23,   TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-12-08', CREATED_WK: 3,    CLOSED_WK: 8,   CREATED_CUM: 105,  CLOSED_CUM: 87,   REMAIN_CUM: 18,   TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-12-15', CREATED_WK: 1,    CLOSED_WK: 7,   CREATED_CUM: 106,  CLOSED_CUM: 94,   REMAIN_CUM: 12,   TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-12-22', CREATED_WK: 0,    CLOSED_WK: 9,   CREATED_CUM: 106,  CLOSED_CUM: 103,  REMAIN_CUM: 3,    TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 106,  CLOSED_CUM: 103,  REMAIN_CUM: 3,    TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2024-01-05', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 106,  CLOSED_CUM: 103,  REMAIN_CUM: 3,    TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2024-01-12', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 106,  CLOSED_CUM: 103,  REMAIN_CUM: 3,    TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2024-01-19', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 106,  CLOSED_CUM: 103,  REMAIN_CUM: 3,    TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_LONG: 'M-Mechanical',      CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 106,  CLOSED_CUM: 103,  REMAIN_CUM: 3,    TOTAL: 106, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-07-07', CREATED_WK: 15,   CLOSED_WK: 5,   CREATED_CUM: 728,  CLOSED_CUM: 641,  REMAIN_CUM: 87,   TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-07-14', CREATED_WK: 171,  CLOSED_WK: 35,  CREATED_CUM: 899,  CLOSED_CUM: 676,  REMAIN_CUM: 223,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-07-21', CREATED_WK: 80,   CLOSED_WK: 88,  CREATED_CUM: 979,  CLOSED_CUM: 764,  REMAIN_CUM: 215,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-07-28', CREATED_WK: 40,   CLOSED_WK: 47,  CREATED_CUM: 1019, CLOSED_CUM: 811,  REMAIN_CUM: 208,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-08-04', CREATED_WK: 126,  CLOSED_WK: 22,  CREATED_CUM: 1145, CLOSED_CUM: 833,  REMAIN_CUM: 312,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-08-11', CREATED_WK: 19,   CLOSED_WK: 36,  CREATED_CUM: 1164, CLOSED_CUM: 869,  REMAIN_CUM: 295,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-08-18', CREATED_WK: 34,   CLOSED_WK: 85,  CREATED_CUM: 1198, CLOSED_CUM: 954,  REMAIN_CUM: 244,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-08-25', CREATED_WK: 45,   CLOSED_WK: 58,  CREATED_CUM: 1243, CLOSED_CUM: 1012, REMAIN_CUM: 231,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-09-01', CREATED_WK: 58,   CLOSED_WK: 69,  CREATED_CUM: 1301, CLOSED_CUM: 1081, REMAIN_CUM: 220,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-09-08', CREATED_WK: 153,  CLOSED_WK: 69,  CREATED_CUM: 1454, CLOSED_CUM: 1150, REMAIN_CUM: 304,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-09-15', CREATED_WK: 80,   CLOSED_WK: 89,  CREATED_CUM: 1534, CLOSED_CUM: 1239, REMAIN_CUM: 295,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-09-22', CREATED_WK: 52,   CLOSED_WK: 63,  CREATED_CUM: 1586, CLOSED_CUM: 1302, REMAIN_CUM: 284,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-09-29', CREATED_WK: 167,  CLOSED_WK: 29,  CREATED_CUM: 1753, CLOSED_CUM: 1331, REMAIN_CUM: 422,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-10-06', CREATED_WK: 141,  CLOSED_WK: 73,  CREATED_CUM: 1894, CLOSED_CUM: 1404, REMAIN_CUM: 490,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-10-13', CREATED_WK: 152,  CLOSED_WK: 96,  CREATED_CUM: 2046, CLOSED_CUM: 1500, REMAIN_CUM: 546,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-10-20', CREATED_WK: 134,  CLOSED_WK: 218, CREATED_CUM: 2180, CLOSED_CUM: 1718, REMAIN_CUM: 462,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-10-27', CREATED_WK: 233,  CLOSED_WK: 35,  CREATED_CUM: 2413, CLOSED_CUM: 1753, REMAIN_CUM: 660,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-11-03', CREATED_WK: 102,  CLOSED_WK: 143, CREATED_CUM: 2515, CLOSED_CUM: 1896, REMAIN_CUM: 619,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-11-10', CREATED_WK: 230,  CLOSED_WK: 204, CREATED_CUM: 2745, CLOSED_CUM: 2100, REMAIN_CUM: 645,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-11-17', CREATED_WK: 102,  CLOSED_WK: 176, CREATED_CUM: 2847, CLOSED_CUM: 2276, REMAIN_CUM: 571,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-11-24', CREATED_WK: 136,  CLOSED_WK: 40,  CREATED_CUM: 2983, CLOSED_CUM: 2316, REMAIN_CUM: 667,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-12-01', CREATED_WK: 134,  CLOSED_WK: 97,  CREATED_CUM: 3117, CLOSED_CUM: 2413, REMAIN_CUM: 704,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-12-08', CREATED_WK: 177,  CLOSED_WK: 111, CREATED_CUM: 3294, CLOSED_CUM: 2524, REMAIN_CUM: 770,  TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-12-15', CREATED_WK: 323,  CLOSED_WK: 91,  CREATED_CUM: 3617, CLOSED_CUM: 2615, REMAIN_CUM: 1002, TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-12-22', CREATED_WK: 409,  CLOSED_WK: 59,  CREATED_CUM: 4026, CLOSED_CUM: 2674, REMAIN_CUM: 1352, TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2023-12-29', CREATED_WK: 123,  CLOSED_WK: 43,  CREATED_CUM: 4149, CLOSED_CUM: 2717, REMAIN_CUM: 1432, TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2024-01-05', CREATED_WK: 81,   CLOSED_WK: 36,  CREATED_CUM: 4230, CLOSED_CUM: 2753, REMAIN_CUM: 1477, TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2024-01-12', CREATED_WK: 45,   CLOSED_WK: 58,  CREATED_CUM: 4275, CLOSED_CUM: 2811, REMAIN_CUM: 1464, TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2024-01-19', CREATED_WK: 55,   CLOSED_WK: 22,  CREATED_CUM: 4330, CLOSED_CUM: 2833, REMAIN_CUM: 1497, TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_LONG: 'OV',                CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 4330, CLOSED_CUM: 2833, REMAIN_CUM: 1497, TOTAL: 4330, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-07-07', CREATED_WK: 1,    CLOSED_WK: 4,   CREATED_CUM: 144,  CLOSED_CUM: 128,  REMAIN_CUM: 16,   TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-07-14', CREATED_WK: 29,   CLOSED_WK: 8,   CREATED_CUM: 173,  CLOSED_CUM: 136,  REMAIN_CUM: 37,   TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-07-21', CREATED_WK: 22,   CLOSED_WK: 22,  CREATED_CUM: 195,  CLOSED_CUM: 158,  REMAIN_CUM: 37,   TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-07-28', CREATED_WK: 7,    CLOSED_WK: 13,  CREATED_CUM: 202,  CLOSED_CUM: 171,  REMAIN_CUM: 31,   TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-08-04', CREATED_WK: 2,    CLOSED_WK: 5,   CREATED_CUM: 204,  CLOSED_CUM: 176,  REMAIN_CUM: 28,   TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-08-11', CREATED_WK: 10,   CLOSED_WK: 6,   CREATED_CUM: 214,  CLOSED_CUM: 182,  REMAIN_CUM: 32,   TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-08-18', CREATED_WK: 15,   CLOSED_WK: 9,   CREATED_CUM: 229,  CLOSED_CUM: 191,  REMAIN_CUM: 38,   TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-08-25', CREATED_WK: 5,    CLOSED_WK: 11,  CREATED_CUM: 234,  CLOSED_CUM: 202,  REMAIN_CUM: 32,   TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-09-01', CREATED_WK: 11,   CLOSED_WK: 6,   CREATED_CUM: 245,  CLOSED_CUM: 208,  REMAIN_CUM: 37,   TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-09-08', CREATED_WK: 22,   CLOSED_WK: 19,  CREATED_CUM: 267,  CLOSED_CUM: 227,  REMAIN_CUM: 40,   TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-09-15', CREATED_WK: 15,   CLOSED_WK: 10,  CREATED_CUM: 282,  CLOSED_CUM: 237,  REMAIN_CUM: 45,   TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-09-22', CREATED_WK: 9,    CLOSED_WK: 14,  CREATED_CUM: 291,  CLOSED_CUM: 251,  REMAIN_CUM: 40,   TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-09-29', CREATED_WK: 24,   CLOSED_WK: 4,   CREATED_CUM: 315,  CLOSED_CUM: 255,  REMAIN_CUM: 60,   TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-10-06', CREATED_WK: 35,   CLOSED_WK: 18,  CREATED_CUM: 350,  CLOSED_CUM: 273,  REMAIN_CUM: 77,   TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-10-13', CREATED_WK: 43,   CLOSED_WK: 60,  CREATED_CUM: 393,  CLOSED_CUM: 333,  REMAIN_CUM: 60,   TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-10-20', CREATED_WK: 13,   CLOSED_WK: 27,  CREATED_CUM: 406,  CLOSED_CUM: 360,  REMAIN_CUM: 46,   TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-10-27', CREATED_WK: 46,   CLOSED_WK: 1,   CREATED_CUM: 452,  CLOSED_CUM: 361,  REMAIN_CUM: 91,   TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-11-03', CREATED_WK: 31,   CLOSED_WK: 34,  CREATED_CUM: 483,  CLOSED_CUM: 395,  REMAIN_CUM: 88,   TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-11-10', CREATED_WK: 98,   CLOSED_WK: 11,  CREATED_CUM: 581,  CLOSED_CUM: 406,  REMAIN_CUM: 175,  TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-11-17', CREATED_WK: 31,   CLOSED_WK: 42,  CREATED_CUM: 612,  CLOSED_CUM: 448,  REMAIN_CUM: 164,  TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-11-24', CREATED_WK: 43,   CLOSED_WK: 14,  CREATED_CUM: 655,  CLOSED_CUM: 462,  REMAIN_CUM: 193,  TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-12-01', CREATED_WK: 67,   CLOSED_WK: 31,  CREATED_CUM: 722,  CLOSED_CUM: 493,  REMAIN_CUM: 229,  TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-12-08', CREATED_WK: 82,   CLOSED_WK: 49,  CREATED_CUM: 804,  CLOSED_CUM: 542,  REMAIN_CUM: 262,  TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-12-15', CREATED_WK: 140,  CLOSED_WK: 48,  CREATED_CUM: 944,  CLOSED_CUM: 590,  REMAIN_CUM: 354,  TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-12-22', CREATED_WK: 169,  CLOSED_WK: 35,  CREATED_CUM: 1113, CLOSED_CUM: 625,  REMAIN_CUM: 488,  TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2023-12-29', CREATED_WK: 61,   CLOSED_WK: 33,  CREATED_CUM: 1174, CLOSED_CUM: 658,  REMAIN_CUM: 516,  TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2024-01-05', CREATED_WK: 42,   CLOSED_WK: 33,  CREATED_CUM: 1216, CLOSED_CUM: 691,  REMAIN_CUM: 525,  TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2024-01-12', CREATED_WK: 18,   CLOSED_WK: 35,  CREATED_CUM: 1234, CLOSED_CUM: 726,  REMAIN_CUM: 508,  TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2024-01-19', CREATED_WK: 1,    CLOSED_WK: 7,   CREATED_CUM: 1235, CLOSED_CUM: 733,  REMAIN_CUM: 502,  TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_LONG: 'P-Piping',          CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 1235, CLOSED_CUM: 733,  REMAIN_CUM: 502,  TOTAL: 1235, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-07-07', CREATED_WK: 2,    CLOSED_WK: 0,   CREATED_CUM: 329,  CLOSED_CUM: 317,  REMAIN_CUM: 12,   TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-07-14', CREATED_WK: 93,   CLOSED_WK: 3,   CREATED_CUM: 422,  CLOSED_CUM: 320,  REMAIN_CUM: 102,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-07-21', CREATED_WK: 36,   CLOSED_WK: 33,  CREATED_CUM: 458,  CLOSED_CUM: 353,  REMAIN_CUM: 105,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-07-28', CREATED_WK: 27,   CLOSED_WK: 13,  CREATED_CUM: 485,  CLOSED_CUM: 366,  REMAIN_CUM: 119,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-08-04', CREATED_WK: 97,   CLOSED_WK: 1,   CREATED_CUM: 582,  CLOSED_CUM: 367,  REMAIN_CUM: 215,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-08-11', CREATED_WK: 3,    CLOSED_WK: 26,  CREATED_CUM: 585,  CLOSED_CUM: 393,  REMAIN_CUM: 192,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-08-18', CREATED_WK: 15,   CLOSED_WK: 68,  CREATED_CUM: 600,  CLOSED_CUM: 461,  REMAIN_CUM: 139,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-08-25', CREATED_WK: 11,   CLOSED_WK: 22,  CREATED_CUM: 611,  CLOSED_CUM: 483,  REMAIN_CUM: 128,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-09-01', CREATED_WK: 18,   CLOSED_WK: 26,  CREATED_CUM: 629,  CLOSED_CUM: 509,  REMAIN_CUM: 120,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-09-08', CREATED_WK: 80,   CLOSED_WK: 30,  CREATED_CUM: 709,  CLOSED_CUM: 539,  REMAIN_CUM: 170,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-09-15', CREATED_WK: 0,    CLOSED_WK: 62,  CREATED_CUM: 709,  CLOSED_CUM: 601,  REMAIN_CUM: 108,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-09-22', CREATED_WK: 18,   CLOSED_WK: 0,   CREATED_CUM: 727,  CLOSED_CUM: 601,  REMAIN_CUM: 126,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-09-29', CREATED_WK: 40,   CLOSED_WK: 0,   CREATED_CUM: 767,  CLOSED_CUM: 601,  REMAIN_CUM: 166,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-10-06', CREATED_WK: 83,   CLOSED_WK: 21,  CREATED_CUM: 850,  CLOSED_CUM: 622,  REMAIN_CUM: 228,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-10-13', CREATED_WK: 9,    CLOSED_WK: 23,  CREATED_CUM: 859,  CLOSED_CUM: 645,  REMAIN_CUM: 214,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-10-20', CREATED_WK: 24,   CLOSED_WK: 98,  CREATED_CUM: 883,  CLOSED_CUM: 743,  REMAIN_CUM: 140,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-10-27', CREATED_WK: 104,  CLOSED_WK: 3,   CREATED_CUM: 987,  CLOSED_CUM: 746,  REMAIN_CUM: 241,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-11-03', CREATED_WK: 13,   CLOSED_WK: 31,  CREATED_CUM: 1000, CLOSED_CUM: 777,  REMAIN_CUM: 223,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-11-10', CREATED_WK: 42,   CLOSED_WK: 71,  CREATED_CUM: 1042, CLOSED_CUM: 848,  REMAIN_CUM: 194,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-11-17', CREATED_WK: 24,   CLOSED_WK: 30,  CREATED_CUM: 1066, CLOSED_CUM: 878,  REMAIN_CUM: 188,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-11-24', CREATED_WK: 4,    CLOSED_WK: 0,   CREATED_CUM: 1070, CLOSED_CUM: 878,  REMAIN_CUM: 192,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-12-01', CREATED_WK: 26,   CLOSED_WK: 13,  CREATED_CUM: 1096, CLOSED_CUM: 891,  REMAIN_CUM: 205,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-12-08', CREATED_WK: 70,   CLOSED_WK: 16,  CREATED_CUM: 1166, CLOSED_CUM: 907,  REMAIN_CUM: 259,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-12-15', CREATED_WK: 142,  CLOSED_WK: 8,   CREATED_CUM: 1308, CLOSED_CUM: 915,  REMAIN_CUM: 393,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-12-22', CREATED_WK: 192,  CLOSED_WK: 5,   CREATED_CUM: 1500, CLOSED_CUM: 920,  REMAIN_CUM: 580,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2023-12-29', CREATED_WK: 41,   CLOSED_WK: 3,   CREATED_CUM: 1541, CLOSED_CUM: 923,  REMAIN_CUM: 618,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2024-01-05', CREATED_WK: 5,    CLOSED_WK: 2,   CREATED_CUM: 1546, CLOSED_CUM: 925,  REMAIN_CUM: 621,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2024-01-12', CREATED_WK: 1,    CLOSED_WK: 0,   CREATED_CUM: 1547, CLOSED_CUM: 925,  REMAIN_CUM: 622,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2024-01-19', CREATED_WK: 7,    CLOSED_WK: 4,   CREATED_CUM: 1554, CLOSED_CUM: 929,  REMAIN_CUM: 625,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_LONG: 'Q-Insulation',      CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 1554, CLOSED_CUM: 929,  REMAIN_CUM: 625,  TOTAL: 1554, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-07-07', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 31,   CLOSED_CUM: 28,   REMAIN_CUM: 3,    TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-07-14', CREATED_WK: 15,   CLOSED_WK: 2,   CREATED_CUM: 46,   CLOSED_CUM: 30,   REMAIN_CUM: 16,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-07-21', CREATED_WK: 11,   CLOSED_WK: 4,   CREATED_CUM: 57,   CLOSED_CUM: 34,   REMAIN_CUM: 23,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-07-28', CREATED_WK: 2,    CLOSED_WK: 7,   CREATED_CUM: 59,   CLOSED_CUM: 41,   REMAIN_CUM: 18,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 12,  CREATED_CUM: 59,   CLOSED_CUM: 53,   REMAIN_CUM: 6,    TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-08-11', CREATED_WK: 5,    CLOSED_WK: 1,   CREATED_CUM: 64,   CLOSED_CUM: 54,   REMAIN_CUM: 10,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-08-18', CREATED_WK: 4,    CLOSED_WK: 1,   CREATED_CUM: 68,   CLOSED_CUM: 55,   REMAIN_CUM: 13,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-08-25', CREATED_WK: 5,    CLOSED_WK: 3,   CREATED_CUM: 73,   CLOSED_CUM: 58,   REMAIN_CUM: 15,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-09-01', CREATED_WK: 4,    CLOSED_WK: 8,   CREATED_CUM: 77,   CLOSED_CUM: 66,   REMAIN_CUM: 11,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-09-08', CREATED_WK: 14,   CLOSED_WK: 6,   CREATED_CUM: 91,   CLOSED_CUM: 72,   REMAIN_CUM: 19,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-09-15', CREATED_WK: 0,    CLOSED_WK: 12,  CREATED_CUM: 91,   CLOSED_CUM: 84,   REMAIN_CUM: 7,    TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-09-22', CREATED_WK: 1,    CLOSED_WK: 0,   CREATED_CUM: 92,   CLOSED_CUM: 84,   REMAIN_CUM: 8,    TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-09-29', CREATED_WK: 4,    CLOSED_WK: 0,   CREATED_CUM: 96,   CLOSED_CUM: 84,   REMAIN_CUM: 12,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-10-06', CREATED_WK: 4,    CLOSED_WK: 7,   CREATED_CUM: 100,  CLOSED_CUM: 91,   REMAIN_CUM: 9,    TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-10-13', CREATED_WK: 6,    CLOSED_WK: 1,   CREATED_CUM: 106,  CLOSED_CUM: 92,   REMAIN_CUM: 14,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-10-20', CREATED_WK: 6,    CLOSED_WK: 3,   CREATED_CUM: 112,  CLOSED_CUM: 95,   REMAIN_CUM: 17,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-10-27', CREATED_WK: 17,   CLOSED_WK: 6,   CREATED_CUM: 129,  CLOSED_CUM: 101,  REMAIN_CUM: 28,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-11-03', CREATED_WK: 2,    CLOSED_WK: 4,   CREATED_CUM: 131,  CLOSED_CUM: 105,  REMAIN_CUM: 26,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-11-10', CREATED_WK: 35,   CLOSED_WK: 2,   CREATED_CUM: 166,  CLOSED_CUM: 107,  REMAIN_CUM: 59,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-11-17', CREATED_WK: 1,    CLOSED_WK: 2,   CREATED_CUM: 167,  CLOSED_CUM: 109,  REMAIN_CUM: 58,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-11-24', CREATED_WK: 8,    CLOSED_WK: 0,   CREATED_CUM: 175,  CLOSED_CUM: 109,  REMAIN_CUM: 66,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-12-01', CREATED_WK: 6,    CLOSED_WK: 4,   CREATED_CUM: 181,  CLOSED_CUM: 113,  REMAIN_CUM: 68,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 5,   CREATED_CUM: 181,  CLOSED_CUM: 118,  REMAIN_CUM: 63,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-12-15', CREATED_WK: 5,    CLOSED_WK: 1,   CREATED_CUM: 186,  CLOSED_CUM: 119,  REMAIN_CUM: 67,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-12-22', CREATED_WK: 1,    CLOSED_WK: 1,   CREATED_CUM: 187,  CLOSED_CUM: 120,  REMAIN_CUM: 67,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2023-12-29', CREATED_WK: 2,    CLOSED_WK: 0,   CREATED_CUM: 189,  CLOSED_CUM: 120,  REMAIN_CUM: 69,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2024-01-05', CREATED_WK: 1,    CLOSED_WK: 1,   CREATED_CUM: 190,  CLOSED_CUM: 121,  REMAIN_CUM: 69,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2024-01-12', CREATED_WK: 1,    CLOSED_WK: 0,   CREATED_CUM: 191,  CLOSED_CUM: 121,  REMAIN_CUM: 70,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2024-01-19', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 191,  CLOSED_CUM: 121,  REMAIN_CUM: 70,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_LONG: 'S-Steel Structure', CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 191,  CLOSED_CUM: 121,  REMAIN_CUM: 70,   TOTAL: 191, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-07-07', CREATED_WK: 0,    CLOSED_WK: 1,   CREATED_CUM: 71,   CLOSED_CUM: 40,   REMAIN_CUM: 31,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-07-14', CREATED_WK: 3,    CLOSED_WK: 5,   CREATED_CUM: 74,   CLOSED_CUM: 45,   REMAIN_CUM: 29,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-07-21', CREATED_WK: 1,    CLOSED_WK: 11,  CREATED_CUM: 75,   CLOSED_CUM: 56,   REMAIN_CUM: 19,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-07-28', CREATED_WK: 0,    CLOSED_WK: 1,   CREATED_CUM: 75,   CLOSED_CUM: 57,   REMAIN_CUM: 18,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-08-04', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 75,   CLOSED_CUM: 57,   REMAIN_CUM: 18,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-08-11', CREATED_WK: 1,    CLOSED_WK: 0,   CREATED_CUM: 76,   CLOSED_CUM: 57,   REMAIN_CUM: 19,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-08-18', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 76,   CLOSED_CUM: 57,   REMAIN_CUM: 19,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-08-25', CREATED_WK: 0,    CLOSED_WK: 1,   CREATED_CUM: 76,   CLOSED_CUM: 58,   REMAIN_CUM: 18,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-09-01', CREATED_WK: 3,    CLOSED_WK: 0,   CREATED_CUM: 79,   CLOSED_CUM: 58,   REMAIN_CUM: 21,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-09-08', CREATED_WK: 5,    CLOSED_WK: 5,   CREATED_CUM: 84,   CLOSED_CUM: 63,   REMAIN_CUM: 21,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-09-15', CREATED_WK: 0,    CLOSED_WK: 3,   CREATED_CUM: 84,   CLOSED_CUM: 66,   REMAIN_CUM: 18,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-09-22', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 84,   CLOSED_CUM: 66,   REMAIN_CUM: 18,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-09-29', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 84,   CLOSED_CUM: 66,   REMAIN_CUM: 18,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-10-06', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 84,   CLOSED_CUM: 66,   REMAIN_CUM: 18,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-10-13', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 84,   CLOSED_CUM: 66,   REMAIN_CUM: 18,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-10-20', CREATED_WK: 3,    CLOSED_WK: 1,   CREATED_CUM: 87,   CLOSED_CUM: 67,   REMAIN_CUM: 20,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-10-27', CREATED_WK: 5,    CLOSED_WK: 0,   CREATED_CUM: 92,   CLOSED_CUM: 67,   REMAIN_CUM: 25,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-11-03', CREATED_WK: 20,   CLOSED_WK: 6,   CREATED_CUM: 112,  CLOSED_CUM: 73,   REMAIN_CUM: 39,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-11-10', CREATED_WK: 26,   CLOSED_WK: 21,  CREATED_CUM: 138,  CLOSED_CUM: 94,   REMAIN_CUM: 44,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 25,  CREATED_CUM: 138,  CLOSED_CUM: 119,  REMAIN_CUM: 19,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-11-24', CREATED_WK: 6,    CLOSED_WK: 0,   CREATED_CUM: 144,  CLOSED_CUM: 119,  REMAIN_CUM: 25,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-12-01', CREATED_WK: 1,    CLOSED_WK: 6,   CREATED_CUM: 145,  CLOSED_CUM: 125,  REMAIN_CUM: 20,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-12-08', CREATED_WK: 2,    CLOSED_WK: 0,   CREATED_CUM: 147,  CLOSED_CUM: 125,  REMAIN_CUM: 22,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-12-15', CREATED_WK: 3,    CLOSED_WK: 0,   CREATED_CUM: 150,  CLOSED_CUM: 125,  REMAIN_CUM: 25,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-12-22', CREATED_WK: 16,   CLOSED_WK: 0,   CREATED_CUM: 166,  CLOSED_CUM: 125,  REMAIN_CUM: 41,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2023-12-29', CREATED_WK: 17,   CLOSED_WK: 0,   CREATED_CUM: 183,  CLOSED_CUM: 125,  REMAIN_CUM: 58,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2024-01-05', CREATED_WK: 1,    CLOSED_WK: 0,   CREATED_CUM: 184,  CLOSED_CUM: 125,  REMAIN_CUM: 59,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2024-01-12', CREATED_WK: 0,    CLOSED_WK: 22,  CREATED_CUM: 184,  CLOSED_CUM: 147,  REMAIN_CUM: 37,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2024-01-19', CREATED_WK: 0,    CLOSED_WK: 9,   CREATED_CUM: 184,  CLOSED_CUM: 156,  REMAIN_CUM: 28,   TOTAL: 184, },
    { ITEM: 'BP', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_LONG: 'X-Painting',        CDATE: '2024-01-26', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 184,  CLOSED_CUM: 156,  REMAIN_CUM: 28,   TOTAL: 184, },
],

  Queries: {
    // Overall
    SQL1: [
      { 
        SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV',       UNIT_DESC: 'OV', 
        AP_TOT: 7553, AP_ACT: 4246, AP_REM: 3307, AP_PRO: 56.2, 
        BP_TOT: 4330, BP_ACT: 2833, BP_REM: 1497, BP_PRO: 65.4,
      },

    ], 

    // A & B Checksheet
    SQL2: [
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_SHORT: 'NULL',   DISC_LONG: 'NULL',              TOT: 7553, ACT: 4246, REM: 3307, PRO: 56.2, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_SHORT: 'C.CIVL', DISC_LONG: 'C-Civil',           TOT: 42,   ACT: 32,   REM: 10,   PRO: 76.2, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_SHORT: 'E.ELEC', DISC_LONG: 'E-Electrical',      TOT: 1429, ACT: 629,  REM: 800,  PRO: 44, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_SHORT: 'I.INST', DISC_LONG: 'I-Instrument',      TOT: 1009, ACT: 243,  REM: 766,  PRO: 24.1, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_SHORT: 'M.MECH', DISC_LONG: 'M-Mechanical',      TOT: 258,  ACT: 151,  REM: 107,  PRO: 58.5, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_SHORT: 'P.PIPE', DISC_LONG: 'P-Piping',          TOT: 4202, ACT: 2885, REM: 1317, PRO: 68.7, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_SHORT: 'Q.INSL', DISC_LONG: 'Q-Insulation',      TOT: 234,  ACT: 115,  REM: 119,  PRO: 49.1, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_SHORT: 'S.STLX', DISC_LONG: 'S-Steel Structure', TOT: 311,  ACT: 159,  REM: 152,  PRO: 51.1, },
      { ITEM: 'A', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_SHORT: 'X.PANT', DISC_LONG: 'X-Painting',        TOT: 68,   ACT: 32,   REM: 36,   PRO: 47.1, },
      { ITEM: 'B', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'OV', DISC_SHORT: 'NULL',   DISC_LONG: 'NULL',              TOT: 4330, ACT: 2833, REM: 1497, PRO: 65.4, },
      { ITEM: 'B', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'C',  DISC_SHORT: 'C.CIVL', DISC_LONG: 'C-Civil',           TOT: 266,  ACT: 181,  REM: 85,   PRO: 68, },
      { ITEM: 'B', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'E',  DISC_SHORT: 'E.ELEC', DISC_LONG: 'E-Electrical',      TOT: 554,  ACT: 445,  REM: 109,  PRO: 80.3, },
      { ITEM: 'B', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'I',  DISC_SHORT: 'I.INST', DISC_LONG: 'I-Instrument',      TOT: 240,  ACT: 165,  REM: 75,   PRO: 68.8, },
      { ITEM: 'B', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'M',  DISC_SHORT: 'M.MECH', DISC_LONG: 'M-Mechanical',      TOT: 106,  ACT: 103,  REM: 3,    PRO: 97.2, },
      { ITEM: 'B', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'P',  DISC_SHORT: 'P.PIPE', DISC_LONG: 'P-Piping',          TOT: 1235, ACT: 733,  REM: 502,  PRO: 59.4, },
      { ITEM: 'B', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'Q',  DISC_SHORT: 'Q.INSL', DISC_LONG: 'Q-Insulation',      TOT: 1554, ACT: 929,  REM: 625,  PRO: 59.8, },
      { ITEM: 'B', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'S',  DISC_SHORT: 'S.STLX', DISC_LONG: 'S-Steel Structure', TOT: 191,  ACT: 121,  REM: 70,   PRO: 63.4, },
      { ITEM: 'B', SCOPE: 'Grassroots', UNIT: 'OV', UNIT_NO: 'OV', DISC: 'X',  DISC_SHORT: 'X.PANT', DISC_LONG: 'X-Painting',        TOT: 184,  ACT: 156,  REM: 28,   PRO: 84.8, },
  ], 

    
    // Last Turnover Date
    SQL3: [ 
      { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     UNIT_NO: '21601',    PLAN_MAX: '2024-03-28', },
      { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     UNIT_NO: '21605',    PLAN_MAX: '2024-04-11', },
      { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', UNIT_NO: '21502',    PLAN_MAX: '2024-04-26', },
      { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', UNIT_NO: '21501',    PLAN_MAX: '2024-05-10', },
      { SCOPE: 'Grassroots', UNIT: 'CWS',         UNIT_NO: '21200',    PLAN_MAX: '2024-07-12', },
      { SCOPE: 'Grassroots', UNIT: 'GENERAL',     UNIT_NO: '20000',    PLAN_MAX: '2024-07-15', },
      { SCOPE: 'Grassroots', UNIT: 'SRU',         UNIT_NO: '20700',    PLAN_MAX: '2024-07-19', },
      { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    UNIT_NO: '21402',    PLAN_MAX: '2024-07-26', },
      { SCOPE: 'Grassroots', UNIT: 'HSFOC',       UNIT_NO: '21403',    PLAN_MAX: '2024-08-02', },
      { SCOPE: 'Grassroots', UNIT: 'DS',          UNIT_NO: '21300',    PLAN_MAX: '2024-08-02', },
      { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    UNIT_NO: '21401',    PLAN_MAX: '2024-08-08', },
      { SCOPE: 'Grassroots', UNIT: 'ARU',         UNIT_NO: '20600',    PLAN_MAX: '2024-08-08', },
      { SCOPE: 'Grassroots', UNIT: 'SWS',         UNIT_NO: '20500',    PLAN_MAX: '2024-08-17', },
      { SCOPE: 'Grassroots', UNIT: 'PFBS',        UNIT_NO: '20101',    PLAN_MAX: '2024-08-28', },
      { SCOPE: 'Grassroots', UNIT: 'U&O',         UNIT_NO: '21100',    PLAN_MAX: '2024-09-25', },
      { SCOPE: 'Grassroots', UNIT: 'DAU',         UNIT_NO: '20100',    PLAN_MAX: '2024-10-11', },
      { SCOPE: 'Grassroots', UNIT: 'DHU S2',      UNIT_NO: '20300',    PLAN_MAX: '2024-10-23', },
      { SCOPE: 'Grassroots', UNIT: 'DHU S1',      UNIT_NO: '20200',    PLAN_MAX: '2024-10-25', },
      { SCOPE: 'Grassroots', UNIT: 'HOS',         UNIT_NO: '20800',    PLAN_MAX: '2024-10-29', },
      { SCOPE: 'Grassroots', UNIT: 'CCFU',        UNIT_NO: '20400',    PLAN_MAX: '2024-10-30', },
      { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     UNIT_NO: '20401',    PLAN_MAX: '2024-10-30', },
      
    ],

    // Canvas & Chart forms
    SQL4: [ 
      {
        CANVAS_WIDTH:           1300,
        CANVAS_HEIGHT:          850,
        
        PADDING_TOP:            290,
        PADDING_RIGHT:          90,
        PADDING_BOTTOM:         30,
        PADDING_LEFT:           90,

        START_DATE:             '2023-07-07', 
        END_DATE:               '2024-12-27',
        WEEKEND:                'friday',

        CUTOFF_DATE:            '2024-01-26'
      }
    ],
  }
}