
// SYSTEM COMPLETION -----------------------------------------------------------------------------------------------------

import CR_Home_Data                   from '../10_Crisp/SystemCompletion/Dashboard/Homepage/includes/primitives/data'

// Planning
import CR_PLAN_Generator_Data          from '../10_Crisp/SystemCompletion/Planning/Plan_Generator/includes/primitives/data'
import CR_PLAN_Forecast_Data           from '../10_Crisp/SystemCompletion/Planning/FC_Analysis/includes/primitives/data'


// DashBoard Elements
import CR_DBE_Header_Data              from '../10_Crisp/SystemCompletion/Dashboard/Header/includes/primitives/data'
import CR_DBE_UnitSlide_Data           from '../10_Crisp/SystemCompletion/Dashboard/Unit_Slide/includes/primitives/data'


// MC & PreCommissioning
import CR_Skyline_Subsys_Month_Data    from '../10_Crisp/SystemCompletion/Completion/Skyline_Subsys_Month/includes/primitives/data'
import CR_Skyline_Subsys_GR_Data       from '../10_Crisp/SystemCompletion/Completion/Skyline_Subsys_GR/includes/primitives/data'
import CR_Skyline_Subsys_GR_FC_Data       from '../10_Crisp/SystemCompletion/Completion/Skyline_Subsys_GR_FC/includes/primitives/data'
import CR_Skyline_Subsys_GR_Mini_Data  from '../10_Crisp/SystemCompletion/Completion/Skyline_Subsys_GR_Mini/includes/primitives/data'
import CR_Skyline_Subsys_ES_Data       from '../10_Crisp/SystemCompletion/Completion/Skyline_Subsys_ES/includes/primitives/data'
import CR_Skyline_Subsys_ES_FC_Data       from '../10_Crisp/SystemCompletion/Completion/Skyline_Subsys_ES/includes/primitives/data'
import CR_SCurve_Turnover_Data         from '../10_Crisp/SystemCompletion/Completion/SCurve_Turnover/includes/primitives/data'
import CR_SCurve_ITRs_Data             from '../10_Crisp/SystemCompletion/Completion/SCurve_ITRs/includes/primitives/data'
import CR_Rundown_ITRs_Data            from '../10_Crisp/SystemCompletion/Completion/Rundown_ITRs/includes/primitives/data'
import CR_Rundown_Punch_Data           from '../10_Crisp/SystemCompletion/Completion/Rundown_Punch/includes/primitives/data'


// UNIT
import CR_Unit_Plotplan_Data           from '../10_Crisp/SystemCompletion/Unit/Unit_Plotplan/includes/primitives/data'
import CR_Unit_Modal_Header_Data       from '../10_Crisp/SystemCompletion/Unit/Unit_Modal_Header/includes/primitives/data'
import CR_Unit_Radial_Data             from '../10_Crisp/SystemCompletion/Unit/Unit_Radial/includes/primitives/data'
import CR_Unit_Overall_Status_Data     from '../10_Crisp/SystemCompletion/Unit/Unit_Overall_Status/includes/primitives/data'
import CR_Unit_Overall_Gantt_Data      from '../10_Crisp/SystemCompletion/Unit/Unit_Overall_Gantt/includes/primitives/data'
import CR_Unit_Rundown_B_ITRs_Data     from '../10_Crisp/SystemCompletion/Unit/Rundown_B_ITRs//includes/primitives/data'
import CR_Unit_Rundown_A_ITRs_Data     from '../10_Crisp/SystemCompletion/Unit/Rundown_A_ITRs//includes/primitives/data'


// Milestone
import CR_MS_CSU_Icicle_Data           from '../10_Crisp/SystemCompletion/Milestones/CSU_Icicle/includes/primitives/data'
import CR_MS_CSU_Skyline_Data          from '../10_Crisp/SystemCompletion/Milestones/CSU_Skyline/includes/primitives/data'
import CR_MS_Contractor_Skyline_Data   from '../10_Crisp/SystemCompletion/Milestones/Contractor_Skyline/includes/primitives/data'
import CR_MS_Contractor_Handover_Data  from '../10_Crisp/SystemCompletion/Milestones/Contractor_Handover/includes/primitives/data'
import CR_MS_CSU_Handover_Data         from '../10_Crisp/SystemCompletion/Milestones/CSU_Handover/includes/primitives/data'



export default {
  CR_Home_Data,

  // Planning
  CR_PLAN_Generator_Data,
  CR_PLAN_Forecast_Data,

  // DashBoard Elements
  CR_DBE_Header_Data,
  CR_DBE_UnitSlide_Data,

  // Completion
  CR_Skyline_Subsys_Month_Data,
  CR_Skyline_Subsys_GR_Data,
  CR_Skyline_Subsys_GR_FC_Data,
  CR_Skyline_Subsys_GR_Mini_Data,
  CR_Skyline_Subsys_ES_Data,
  CR_Skyline_Subsys_ES_FC_Data,
  CR_SCurve_Turnover_Data,
  CR_SCurve_ITRs_Data,
  CR_Rundown_ITRs_Data,
  CR_Rundown_Punch_Data,
  
  // UNIT
  CR_Unit_Plotplan_Data,
  CR_Unit_Modal_Header_Data,
  CR_Unit_Radial_Data,
  CR_Unit_Overall_Status_Data,
  CR_Unit_Overall_Gantt_Data,
  CR_Unit_Rundown_B_ITRs_Data,
  CR_Unit_Rundown_A_ITRs_Data,

  // Milestone
  CR_MS_CSU_Icicle_Data,
  CR_MS_CSU_Skyline_Data,
  CR_MS_Contractor_Skyline_Data,
  CR_MS_Contractor_Handover_Data,
  CR_MS_CSU_Handover_Data,
  
}