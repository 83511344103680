
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 900,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 900,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    { CDATE: '2023-07-07', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 28,  PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1154,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-07-07', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 123, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 11511, PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-07-07', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 41,  PL_REMAIN_CUM: 40678,  REMAIN_CUM: 40303, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-07-07', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 70,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 6477,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-07-07', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 7,   PL_REMAIN_CUM: 3318,   REMAIN_CUM: 3032,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-07-07', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 75,  PL_REMAIN_CUM: 29577,  REMAIN_CUM: 28171, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-07-07', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 0,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 316,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-07-07', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 103, PL_REMAIN_CUM: 9258,   REMAIN_CUM: 7700,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-07-07', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 447, PL_REMAIN_CUM: 102913, REMAIN_CUM: 98664, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-07-14', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 29,  PL_REMAIN_CUM: 3318,   REMAIN_CUM: 3003,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-07-14', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 103, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 11408, PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-07-14', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 110, PL_REMAIN_CUM: 29577,  REMAIN_CUM: 28111, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-07-14', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 2,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 314,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-07-14', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 19,  PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1141,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-07-14', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 78,  PL_REMAIN_CUM: 40678,  REMAIN_CUM: 40225, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-07-14', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 70,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 6407,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-07-14', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 108, PL_REMAIN_CUM: 9258,   REMAIN_CUM: 7598,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-07-14', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 519, PL_REMAIN_CUM: 102913, REMAIN_CUM: 98207, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-07-21', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 14,  PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2989,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-07-21', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 11,  PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1130,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-07-21', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 215, PL_REMAIN_CUM: 9258,   REMAIN_CUM: 7385,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-07-21', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 27,  PL_REMAIN_CUM: 29577,  REMAIN_CUM: 28084, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-07-21', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 121, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 11299, PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-07-21', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 63,  PL_REMAIN_CUM: 40678,  REMAIN_CUM: 40162, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-07-21', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 2,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 312,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-07-21', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 92,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 6317,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-07-21', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 545, PL_REMAIN_CUM: 102913, REMAIN_CUM: 97678, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-07-28', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 9,   PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1122,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-07-28', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 2,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 310,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-07-28', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 67,  PL_REMAIN_CUM: 29577,  REMAIN_CUM: 28017, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-07-28', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 29,  PL_REMAIN_CUM: 9258,   REMAIN_CUM: 7356,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-07-28', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 23,  PL_REMAIN_CUM: 40678,  REMAIN_CUM: 40139, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-07-28', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 23,  PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2966,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-07-28', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 19,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 6299,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-07-28', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 112, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 11188, PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-07-28', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 284, PL_REMAIN_CUM: 102913, REMAIN_CUM: 97397, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-08-04', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 48,  PL_REMAIN_CUM: 29577,  REMAIN_CUM: 27991, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-08-04', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 5,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 309,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-08-04', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 133, PL_REMAIN_CUM: 40678,  REMAIN_CUM: 40010, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-08-04', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 90,  PL_REMAIN_CUM: 9258,   REMAIN_CUM: 7271,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-08-04', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 35,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 6265,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-08-04', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 4,   PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2962,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-08-04', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 0,   PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1122,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-08-04', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 97,  PL_REMAIN_CUM: 11806,  REMAIN_CUM: 11092, PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-08-04', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 412, PL_REMAIN_CUM: 102913, REMAIN_CUM: 97022, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-08-11', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 93,  PL_REMAIN_CUM: 40678,  REMAIN_CUM: 39976, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-08-11', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 138, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 10954, PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-08-11', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 83,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 6188,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-08-11', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 129, PL_REMAIN_CUM: 29577,  REMAIN_CUM: 27931, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-08-11', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 15,  PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2953,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-08-11', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 101, PL_REMAIN_CUM: 9258,   REMAIN_CUM: 7172,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-08-11', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 7,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 303,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-08-11', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 16,  PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1111,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-08-11', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 582, PL_REMAIN_CUM: 102913, REMAIN_CUM: 96588, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-08-18', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 98,  PL_REMAIN_CUM: 29577,  REMAIN_CUM: 27857, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-08-18', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 11,  PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1100,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-08-18', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 26,  PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2931,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-08-18', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 121, PL_REMAIN_CUM: 40678,  REMAIN_CUM: 39883, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-08-18', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 78,  PL_REMAIN_CUM: 11806,  REMAIN_CUM: 10877, PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-08-18', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 10,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 6178,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-08-18', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 0,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 303,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-08-18', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 83,  PL_REMAIN_CUM: 9258,   REMAIN_CUM: 7092,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-08-18', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 427, PL_REMAIN_CUM: 102913, REMAIN_CUM: 96221, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-08-25', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 27,  PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2904,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-08-25', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 107, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 10771, PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-08-25', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 142, PL_REMAIN_CUM: 9258,   REMAIN_CUM: 6950,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-08-25', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 13,  PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1088,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-08-25', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 2,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 301,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-08-25', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 127, PL_REMAIN_CUM: 40678,  REMAIN_CUM: 39765, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-08-25', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 158, PL_REMAIN_CUM: 29577,  REMAIN_CUM: 27761, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-08-25', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 42,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 6137,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-08-25', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 618, PL_REMAIN_CUM: 102913, REMAIN_CUM: 95677, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-09-01', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 2,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 299,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-09-01', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 45,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 6093,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-09-01', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 119, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 10655, PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-09-01', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 173, PL_REMAIN_CUM: 40678,  REMAIN_CUM: 39612, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-09-01', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 6,   PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2898,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-09-01', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 101, PL_REMAIN_CUM: 29577,  REMAIN_CUM: 27696, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-09-01', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 6,   PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1082,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-09-01', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 130, PL_REMAIN_CUM: 9258,   REMAIN_CUM: 6820,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-09-01', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 582, PL_REMAIN_CUM: 102913, REMAIN_CUM: 95155, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-09-08', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 62,  PL_REMAIN_CUM: 9258,   REMAIN_CUM: 6759,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-09-08', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 162, PL_REMAIN_CUM: 40678,  REMAIN_CUM: 39487, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-09-08', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 2,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 297,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-09-08', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 3,   PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1079,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-09-08', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 21,  PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2877,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-09-08', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 94,  PL_REMAIN_CUM: 29577,  REMAIN_CUM: 27607, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-09-08', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 126, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 10554, PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-09-08', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 74,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 6022,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-09-08', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 544, PL_REMAIN_CUM: 102913, REMAIN_CUM: 94682, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-09-15', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 132, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 10422, PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-09-15', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 264, PL_REMAIN_CUM: 40678,  REMAIN_CUM: 39226, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-09-15', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 60,  PL_REMAIN_CUM: 29577,  REMAIN_CUM: 27560, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-09-15', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 17,  PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1068,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-09-15', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 34,  PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2843,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-09-15', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 48,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5976,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-09-15', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 55,  PL_REMAIN_CUM: 9258,   REMAIN_CUM: 6706,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-09-15', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 1,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 296,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-09-15', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 611, PL_REMAIN_CUM: 102913, REMAIN_CUM: 94097, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-09-22', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 24,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5955,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-09-22', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 61,  PL_REMAIN_CUM: 29577,  REMAIN_CUM: 27502, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-09-22', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 19,  PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1059,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-09-22', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 13,  PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2833,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-09-22', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 282, PL_REMAIN_CUM: 40678,  REMAIN_CUM: 38952, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-09-22', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 139, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 10286, PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-09-22', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 1,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 295,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-09-22', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 55,  PL_REMAIN_CUM: 9258,   REMAIN_CUM: 6655,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-09-22', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 594, PL_REMAIN_CUM: 102913, REMAIN_CUM: 93537, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-09-29', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 4,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 293,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-09-29', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 150, PL_REMAIN_CUM: 29577,  REMAIN_CUM: 27411, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-09-29', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 149, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 10147, PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-09-29', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 489, PL_REMAIN_CUM: 40678,  REMAIN_CUM: 38730, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-09-29', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 55,  PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2779,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-09-29', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 1,   PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1058,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-09-29', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 64,  PL_REMAIN_CUM: 9258,   REMAIN_CUM: 6591,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-09-29', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 53,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5906,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-09-29', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 965, PL_REMAIN_CUM: 102913, REMAIN_CUM: 92915, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-10-06', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 3,   PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1056,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-10-06', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 13,  PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2766,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-10-06', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 854, PL_REMAIN_CUM: 40678,  REMAIN_CUM: 37877, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-10-06', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 116, PL_REMAIN_CUM: 29577,  REMAIN_CUM: 27302, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-10-06', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 37,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5872,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-10-06', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 115, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 10037, PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-10-06', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 62,  PL_REMAIN_CUM: 9258,   REMAIN_CUM: 6538,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-10-06', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 0,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 293,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-10-06', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 1200, PL_REMAIN_CUM: 102913, REMAIN_CUM: 91741, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-10-13', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 1010, PL_REMAIN_CUM: 40678,  REMAIN_CUM: 37216, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-10-13', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 17,  PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2754,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-10-13', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 235, PL_REMAIN_CUM: 29577,  REMAIN_CUM: 27194, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-10-13', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 107, PL_REMAIN_CUM: 9258,   REMAIN_CUM: 6436,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-10-13', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 49,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5829,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-10-13', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 1,   PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1055,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-10-13', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 2,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 291,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-10-13', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 92,  PL_REMAIN_CUM: 11806,  REMAIN_CUM: 9946,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-10-13', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 1513, PL_REMAIN_CUM: 102913, REMAIN_CUM: 90721, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-10-20', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 5,   PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2750,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-10-20', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 342, PL_REMAIN_CUM: 40678,  REMAIN_CUM: 36880, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-10-20', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 117, PL_REMAIN_CUM: 9258,   REMAIN_CUM: 6319,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-10-20', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 15,  PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1042,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-10-20', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 106, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 9841,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-10-20', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 261, PL_REMAIN_CUM: 29577,  REMAIN_CUM: 26992, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-10-20', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 7,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 287,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-10-20', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 74,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5765,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-10-20', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 927, PL_REMAIN_CUM: 102913, REMAIN_CUM: 89876, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-10-27', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 55,  PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2695,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-10-27', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 25,  PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1029,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-10-27', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 84,  PL_REMAIN_CUM: 9258,   REMAIN_CUM: 6238,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-10-27', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 130, PL_REMAIN_CUM: 29577,  REMAIN_CUM: 26873, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-10-27', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 5,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 284,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-10-27', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 54,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5712,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-10-27', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 122, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 9719,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-10-27', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 73,  PL_REMAIN_CUM: 40678,  REMAIN_CUM: 36807, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-10-27', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 548, PL_REMAIN_CUM: 102913, REMAIN_CUM: 89357, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-11-03', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 112, PL_REMAIN_CUM: 9258,   REMAIN_CUM: 6128,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-11-03', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 101, PL_REMAIN_CUM: 29577,  REMAIN_CUM: 26773, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-11-03', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 24,  PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2671,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-11-03', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 10,  PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1020,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-11-03', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 143, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 9578,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-11-03', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 122, PL_REMAIN_CUM: 40678,  REMAIN_CUM: 36693, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-11-03', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 1,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 283,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-11-03', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 23,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5689,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-11-03', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 536, PL_REMAIN_CUM: 102913, REMAIN_CUM: 88835, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-11-10', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 46,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5647,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-11-10', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 2,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 281,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-11-10', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 151, PL_REMAIN_CUM: 40678,  REMAIN_CUM: 36547, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-11-10', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 169, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 9409,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-11-10', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 54,  PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2617,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-11-10', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 122, PL_REMAIN_CUM: 29577,  REMAIN_CUM: 26660, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-11-10', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 14,  PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1009,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-11-10', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 99,  PL_REMAIN_CUM: 9258,   REMAIN_CUM: 6032,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-11-10', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 657, PL_REMAIN_CUM: 102913, REMAIN_CUM: 88202, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-11-17', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 96,  PL_REMAIN_CUM: 29577,  REMAIN_CUM: 26565, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-11-17', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 185, PL_REMAIN_CUM: 40678,  REMAIN_CUM: 36387, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-11-17', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 157, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 9266,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-11-17', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 98,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5550,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-11-17', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 2,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 280,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-11-17', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 54,  PL_REMAIN_CUM: 9258,   REMAIN_CUM: 5983,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-11-17', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 9,   PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2608,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-11-17', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 3,   PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1008,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-11-17', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 604, PL_REMAIN_CUM: 102913, REMAIN_CUM: 87647, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-11-24', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 6,   PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1005,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-11-24', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 4,   PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2605,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-11-24', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 177, PL_REMAIN_CUM: 40678,  REMAIN_CUM: 36213, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-11-24', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 65,  PL_REMAIN_CUM: 29577,  REMAIN_CUM: 26500, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-11-24', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 163, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 9112,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-11-24', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 44,  PL_REMAIN_CUM: 9258,   REMAIN_CUM: 5939,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-11-24', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 33,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5517,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-11-24', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 0,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 280,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-11-24', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 492, PL_REMAIN_CUM: 102913, REMAIN_CUM: 87171, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-12-01', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 55,  PL_REMAIN_CUM: 9258,   REMAIN_CUM: 5885,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-12-01', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 153, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 8959,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-12-01', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 26,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5494,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-12-01', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 89,  PL_REMAIN_CUM: 29577,  REMAIN_CUM: 26472, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-12-01', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 152, PL_REMAIN_CUM: 40678,  REMAIN_CUM: 36062, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-12-01', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 12,  PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2593,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-12-01', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 8,   PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1001,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-12-01', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 1,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 279,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-12-01', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 496, PL_REMAIN_CUM: 102913, REMAIN_CUM: 86745, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-12-08', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: 224, PL_REMAIN_CUM: 40678,  REMAIN_CUM: 35842, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-12-08', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: 210, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 8757,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-12-08', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: 6,   PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2587,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-12-08', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 2,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 277,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-12-08', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: 113, PL_REMAIN_CUM: 29577,  REMAIN_CUM: 26385, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-12-08', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 0,   PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1001,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-12-08', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 34,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5461,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-12-08', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 142, PL_REMAIN_CUM: 9258,   REMAIN_CUM: 5753,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-12-08', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: 731, PL_REMAIN_CUM: 102913, REMAIN_CUM: 86063, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-12-15', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 28, CLOSED_WK: 160, PL_REMAIN_CUM: 11806,  REMAIN_CUM: 8602,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-12-15', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 28, CLOSED_WK: 11,  PL_REMAIN_CUM: 3318,   REMAIN_CUM: 2576,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-12-15', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 1,   PL_REMAIN_CUM: 1331,   REMAIN_CUM: 1000,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-12-15', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 80, CLOSED_WK: 178, PL_REMAIN_CUM: 29577,  REMAIN_CUM: 26223, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-12-15', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 91, CLOSED_WK: 214, PL_REMAIN_CUM: 40678,  REMAIN_CUM: 35628, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-12-15', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 99,  PL_REMAIN_CUM: 9258,   REMAIN_CUM: 5655,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-12-15', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 63,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5403,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-12-15', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 6,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 271,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-12-15', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 227, CLOSED_WK: 732, PL_REMAIN_CUM: 102913, REMAIN_CUM: 85358, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-12-22', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 144, CLOSED_WK: 237, PL_REMAIN_CUM: 40541,  REMAIN_CUM: 35391, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-12-22', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 43, CLOSED_WK: 46,  PL_REMAIN_CUM: 3276,   REMAIN_CUM: 2551,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-12-22', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 11,  PL_REMAIN_CUM: 1331,   REMAIN_CUM: 993,   PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-12-22', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 137, PL_REMAIN_CUM: 9258,   REMAIN_CUM: 5521,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-12-22', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 135, CLOSED_WK: 237, PL_REMAIN_CUM: 29452,  REMAIN_CUM: 25988, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-12-22', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 45, CLOSED_WK: 225, PL_REMAIN_CUM: 11763,  REMAIN_CUM: 8403,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-12-22', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 24,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5384,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-12-22', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 4,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 267,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-12-22', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 367, CLOSED_WK: 921, PL_REMAIN_CUM: 102566, REMAIN_CUM: 84498, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2023-12-29', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 35, CLOSED_WK: 15,  PL_REMAIN_CUM: 3241,   REMAIN_CUM: 2536,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2023-12-29', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 47,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5339,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2023-12-29', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 90,  PL_REMAIN_CUM: 9258,   REMAIN_CUM: 5431,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2023-12-29', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 140, CLOSED_WK: 173, PL_REMAIN_CUM: 29337,  REMAIN_CUM: 25818, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2023-12-29', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 9,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 259,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2023-12-29', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 14,  PL_REMAIN_CUM: 1331,   REMAIN_CUM: 979,   PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2023-12-29', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 45, CLOSED_WK: 152, PL_REMAIN_CUM: 11722,  REMAIN_CUM: 8251,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2023-12-29', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 125, CLOSED_WK: 112, PL_REMAIN_CUM: 40421,  REMAIN_CUM: 35286, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2023-12-29', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 345, CLOSED_WK: 612, PL_REMAIN_CUM: 102255, REMAIN_CUM: 83899, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-01-05', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 153, CLOSED_WK: 111, PL_REMAIN_CUM: 40270,  REMAIN_CUM: 35200, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-01-05', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 31,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5308,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-01-05', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 0,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 259,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-01-05', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 169, CLOSED_WK: 123, PL_REMAIN_CUM: 29169,  REMAIN_CUM: 25728, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-01-05', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 61, CLOSED_WK: 111, PL_REMAIN_CUM: 11669,  REMAIN_CUM: 8143,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-01-05', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 43, CLOSED_WK: 4,   PL_REMAIN_CUM: 3198,   REMAIN_CUM: 2532,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-01-05', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 30,  PL_REMAIN_CUM: 9258,   REMAIN_CUM: 5401,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-01-05', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 13,  PL_REMAIN_CUM: 1331,   REMAIN_CUM: 967,   PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-01-05', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 426, CLOSED_WK: 423, PL_REMAIN_CUM: 101840, REMAIN_CUM: 83538, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-01-12', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 75, CLOSED_WK: 172, PL_REMAIN_CUM: 11597,  REMAIN_CUM: 7981,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-01-12', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 199, CLOSED_WK: 212, PL_REMAIN_CUM: 40087,  REMAIN_CUM: 34993, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-01-12', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 21,  PL_REMAIN_CUM: 1331,   REMAIN_CUM: 948,   PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-01-12', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 340, CLOSED_WK: 232, PL_REMAIN_CUM: 28972,  REMAIN_CUM: 25610, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-01-12', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 55, CLOSED_WK: 16,  PL_REMAIN_CUM: 3148,   REMAIN_CUM: 2516,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-01-12', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 87,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5225,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-01-12', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 1,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 258,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-01-12', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 40,  PL_REMAIN_CUM: 9258,   REMAIN_CUM: 5364,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-01-12', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 669, CLOSED_WK: 781, PL_REMAIN_CUM: 101338, REMAIN_CUM: 82895, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-01-19', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 21,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5206,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-01-19', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 76, CLOSED_WK: 106, PL_REMAIN_CUM: 11522,  REMAIN_CUM: 7879,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-01-19', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 619, CLOSED_WK: 85,  PL_REMAIN_CUM: 28490,  REMAIN_CUM: 25525, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-01-19', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 72, CLOSED_WK: 1,   PL_REMAIN_CUM: 3076,   REMAIN_CUM: 2515,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-01-19', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 373, CLOSED_WK: 99,  PL_REMAIN_CUM: 39867,  REMAIN_CUM: 34894, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-01-19', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 2,   PL_REMAIN_CUM: 1331,   REMAIN_CUM: 946,   PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-01-19', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: 46,  PL_REMAIN_CUM: 9258,   REMAIN_CUM: 5327,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-01-19', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 1,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 257,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-01-19', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 1140, CLOSED_WK: 361, PL_REMAIN_CUM: 100489, REMAIN_CUM: 82549, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-01-26', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 891, CLOSED_WK: 130, PL_REMAIN_CUM: 39428,  REMAIN_CUM: 34772, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-01-26', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 72, CLOSED_WK: 6,   PL_REMAIN_CUM: 3004,   REMAIN_CUM: 2509,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-01-26', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: 2,   PL_REMAIN_CUM: 1331,   REMAIN_CUM: 944,   PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-01-26', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 11,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5195,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-01-26', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 87, CLOSED_WK: 121, PL_REMAIN_CUM: 11443,  REMAIN_CUM: 7760,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-01-26', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 734, CLOSED_WK: 309, PL_REMAIN_CUM: 27843,  REMAIN_CUM: 25245, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-01-26', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 4, CLOSED_WK: 72,  PL_REMAIN_CUM: 9258,   REMAIN_CUM: 5255,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-01-26', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 0,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 257,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-01-26', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 1788, CLOSED_WK: 651, PL_REMAIN_CUM: 99252,  REMAIN_CUM: 81937, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-02-02', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 38,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5159,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-02-02', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 0,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 257,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-02-02', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 788, CLOSED_WK: 180, PL_REMAIN_CUM: 27087,  REMAIN_CUM: 25105, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-02-02', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 72, CLOSED_WK: 12,  PL_REMAIN_CUM: 2932,   REMAIN_CUM: 2497,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-02-02', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 5, CLOSED_WK: 0,   PL_REMAIN_CUM: 1331,   REMAIN_CUM: 944,   PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-02-02', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 13, CLOSED_WK: 54,  PL_REMAIN_CUM: 9247,   REMAIN_CUM: 5201,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-02-02', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 1086, CLOSED_WK: 306, PL_REMAIN_CUM: 38373,  REMAIN_CUM: 34478, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-02-02', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 105, CLOSED_WK: 128, PL_REMAIN_CUM: 11354,  REMAIN_CUM: 7632,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-02-02', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 2069, CLOSED_WK: 718, PL_REMAIN_CUM: 97269,  REMAIN_CUM: 81273, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-02-09', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 20,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5140,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-02-09', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 645, CLOSED_WK: 422, PL_REMAIN_CUM: 26467,  REMAIN_CUM: 24733, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-02-09', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 94, CLOSED_WK: 71,  PL_REMAIN_CUM: 11263,  REMAIN_CUM: 7561,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-02-09', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 4, CLOSED_WK: 10,  PL_REMAIN_CUM: 1327,   REMAIN_CUM: 934,   PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-02-09', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 889, CLOSED_WK: 306, PL_REMAIN_CUM: 37500,  REMAIN_CUM: 34186, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-02-09', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 49, CLOSED_WK: 1,   PL_REMAIN_CUM: 2883,   REMAIN_CUM: 2496,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-02-09', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 15, CLOSED_WK: 71,  PL_REMAIN_CUM: 9236,   REMAIN_CUM: 5130,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-02-09', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 1,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 256,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-02-09', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 1696, CLOSED_WK: 902, PL_REMAIN_CUM: 95621,  REMAIN_CUM: 80436, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-02-16', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 19, CLOSED_WK: 13,  PL_REMAIN_CUM: 2864,   REMAIN_CUM: 2483,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-02-16', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 5, CLOSED_WK: 7,   PL_REMAIN_CUM: 1323,   REMAIN_CUM: 932,   PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-02-16', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 77, CLOSED_WK: 67,  PL_REMAIN_CUM: 11201,  REMAIN_CUM: 7495,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-02-16', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 608, CLOSED_WK: 170, PL_REMAIN_CUM: 25907,  REMAIN_CUM: 24568, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-02-16', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 3,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 253,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-02-16', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 25,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5116,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-02-16', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 16, CLOSED_WK: 49,  PL_REMAIN_CUM: 9221,   REMAIN_CUM: 5089,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-02-16', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 813, CLOSED_WK: 212, PL_REMAIN_CUM: 36706,  REMAIN_CUM: 33976, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-02-16', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 1538, CLOSED_WK: 546, PL_REMAIN_CUM: 94167,  REMAIN_CUM: 79912, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-02-23', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 2553, CLOSED_WK: 253, PL_REMAIN_CUM: 35718,  REMAIN_CUM: 33753, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-02-23', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: 9,   PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5108,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-02-23', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 9, CLOSED_WK: 4,   PL_REMAIN_CUM: 1315,   REMAIN_CUM: 928,   PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-02-23', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 106, CLOSED_WK: 87,  PL_REMAIN_CUM: 11116,  REMAIN_CUM: 7408,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-02-23', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 1068, CLOSED_WK: 280, PL_REMAIN_CUM: 25158,  REMAIN_CUM: 24360, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-02-23', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 25, CLOSED_WK: 20,  PL_REMAIN_CUM: 2842,   REMAIN_CUM: 2480,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-02-23', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 22, CLOSED_WK: 43,  PL_REMAIN_CUM: 9201,   REMAIN_CUM: 5055,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-02-23', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 3,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 250,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-02-23', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 3783, CLOSED_WK: 699, PL_REMAIN_CUM: 92295,  REMAIN_CUM: 79342, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-03-01', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 3,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 247,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-03-01', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 2904, CLOSED_WK: 804, PL_REMAIN_CUM: 32889,  REMAIN_CUM: 32973, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-03-01', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 146, CLOSED_WK: 96,  PL_REMAIN_CUM: 11004,  REMAIN_CUM: 7312,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-03-01', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 13, CLOSED_WK: 3,   PL_REMAIN_CUM: 1302,   REMAIN_CUM: 926,   PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-03-01', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 37, CLOSED_WK: 77,  PL_REMAIN_CUM: 2812,   REMAIN_CUM: 2411,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-03-01', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 2, CLOSED_WK: 53,  PL_REMAIN_CUM: 6945,   REMAIN_CUM: 5055,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-03-01', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 1164, CLOSED_WK: 213, PL_REMAIN_CUM: 24021,  REMAIN_CUM: 24168, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-03-01', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 30, CLOSED_WK: 40,  PL_REMAIN_CUM: 9179,   REMAIN_CUM: 5015,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-03-01', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 4296, CLOSED_WK: 1289, PL_REMAIN_CUM: 88152,  REMAIN_CUM: 78107, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-03-08', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 13, CLOSED_WK: 7,   PL_REMAIN_CUM: 1289,   REMAIN_CUM: 919,   PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-03-08', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 35, CLOSED_WK: 48,  PL_REMAIN_CUM: 2782,   REMAIN_CUM: 2366,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-03-08', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 1062, CLOSED_WK: 560, PL_REMAIN_CUM: 23019,  REMAIN_CUM: 23850, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-03-08', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 41, CLOSED_WK: 50,  PL_REMAIN_CUM: 9145,   REMAIN_CUM: 4965,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-03-08', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 3, CLOSED_WK: 24,  PL_REMAIN_CUM: 6943,   REMAIN_CUM: 5031,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-03-08', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 225, CLOSED_WK: 80,  PL_REMAIN_CUM: 10856,  REMAIN_CUM: 7232,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-03-08', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 2924, CLOSED_WK: 1182, PL_REMAIN_CUM: 29968,  REMAIN_CUM: 31803, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-03-08', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 1,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 246,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-03-08', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 4303, CLOSED_WK: 1952, PL_REMAIN_CUM: 84002,  REMAIN_CUM: 76412, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-03-15', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 19, CLOSED_WK: 5,   PL_REMAIN_CUM: 1276,   REMAIN_CUM: 916,   PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-03-15', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 280, CLOSED_WK: 66,  PL_REMAIN_CUM: 10598,  REMAIN_CUM: 7166,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-03-15', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 820, CLOSED_WK: 299, PL_REMAIN_CUM: 22210,  REMAIN_CUM: 23564, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-03-15', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 72, CLOSED_WK: 6,   PL_REMAIN_CUM: 2758,   REMAIN_CUM: 2360,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-03-15', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 30, CLOSED_WK: 30,  PL_REMAIN_CUM: 6940,   REMAIN_CUM: 5001,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-03-15', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 5,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 241,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-03-15', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 2779, CLOSED_WK: 567, PL_REMAIN_CUM: 27275,  REMAIN_CUM: 31269, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-03-15', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 81, CLOSED_WK: 23,  PL_REMAIN_CUM: 9103,   REMAIN_CUM: 4942,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-03-15', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 4081, CLOSED_WK: 1001, PL_REMAIN_CUM: 80160,  REMAIN_CUM: 75459, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-03-22', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 905, CLOSED_WK: 551, PL_REMAIN_CUM: 21471,  REMAIN_CUM: 23052, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-03-22', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 55, CLOSED_WK: 10,  PL_REMAIN_CUM: 6899,   REMAIN_CUM: 4991,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-03-22', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 2320, CLOSED_WK: 719, PL_REMAIN_CUM: 24968,  REMAIN_CUM: 30550, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-03-22', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 106, CLOSED_WK: 1,   PL_REMAIN_CUM: 9014,   REMAIN_CUM: 4941,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-03-22', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 0,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 241,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-03-22', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 91, CLOSED_WK: 72,  PL_REMAIN_CUM: 2675,   REMAIN_CUM: 2294,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-03-22', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 24, CLOSED_WK: 3,   PL_REMAIN_CUM: 1258,   REMAIN_CUM: 913,   PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-03-22', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 329, CLOSED_WK: 54,  PL_REMAIN_CUM: 10322,  REMAIN_CUM: 7112,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-03-22', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 3830, CLOSED_WK: 1410, PL_REMAIN_CUM: 76607,  REMAIN_CUM: 74094, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-03-29', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 67, CLOSED_WK: 7,   PL_REMAIN_CUM: 6846,   REMAIN_CUM: 4984,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-03-29', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 96, CLOSED_WK: 86,  PL_REMAIN_CUM: 2594,   REMAIN_CUM: 2213,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-03-29', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 18, CLOSED_WK: 4,   PL_REMAIN_CUM: 1242,   REMAIN_CUM: 909,   PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-03-29', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 343, CLOSED_WK: 72,  PL_REMAIN_CUM: 10034,  REMAIN_CUM: 7042,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-03-29', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 767, CLOSED_WK: 458, PL_REMAIN_CUM: 20724,  REMAIN_CUM: 22604, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-03-29', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 0,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 241,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-03-29', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 1850, CLOSED_WK: 1344, PL_REMAIN_CUM: 23128,  REMAIN_CUM: 29232, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-03-29', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 120, CLOSED_WK: 49,  PL_REMAIN_CUM: 8927,   REMAIN_CUM: 4892,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-03-29', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 3261, CLOSED_WK: 2020, PL_REMAIN_CUM: 73495,  REMAIN_CUM: 72117, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-04-05', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 140, CLOSED_WK: 48,  PL_REMAIN_CUM: 2480,   REMAIN_CUM: 2167,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-04-05', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 35, CLOSED_WK: 29,  PL_REMAIN_CUM: 1220,   REMAIN_CUM: 884,   PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-04-05', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 985, CLOSED_WK: 778, PL_REMAIN_CUM: 19800,  REMAIN_CUM: 21914, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-04-05', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 2235, CLOSED_WK: 2114, PL_REMAIN_CUM: 20932,  REMAIN_CUM: 27118, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-04-05', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 491, CLOSED_WK: 52,  PL_REMAIN_CUM: 9611,   REMAIN_CUM: 6992,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-04-05', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 83, CLOSED_WK: 3,   PL_REMAIN_CUM: 6770,   REMAIN_CUM: 4981,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-04-05', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 161, CLOSED_WK: 0,   PL_REMAIN_CUM: 8779,   REMAIN_CUM: 4892,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-04-05', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 1,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 241,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-04-05', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 4130, CLOSED_WK: 3025, PL_REMAIN_CUM: 69592,  REMAIN_CUM: 69189, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-04-12', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 1849, CLOSED_WK: 1175, PL_REMAIN_CUM: 19104,  REMAIN_CUM: 25950, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-04-12', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 83, CLOSED_WK: 0,   PL_REMAIN_CUM: 6698,   REMAIN_CUM: 4981,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-04-12', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 0,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 241,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-04-12', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 30, CLOSED_WK: 3,   PL_REMAIN_CUM: 1191,   REMAIN_CUM: 881,   PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-04-12', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 948, CLOSED_WK: 515, PL_REMAIN_CUM: 19018,  REMAIN_CUM: 21404, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-04-12', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 490, CLOSED_WK: 27,  PL_REMAIN_CUM: 9196,   REMAIN_CUM: 6966,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-04-12', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 125, CLOSED_WK: 104, PL_REMAIN_CUM: 2360,   REMAIN_CUM: 2063,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-04-12', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 153, CLOSED_WK: 2,   PL_REMAIN_CUM: 8644,   REMAIN_CUM: 4891,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-04-12', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 3678, CLOSED_WK: 1826, PL_REMAIN_CUM: 66211,  REMAIN_CUM: 67377, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-04-19', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 52, CLOSED_WK: 0,   PL_REMAIN_CUM: 1155,   REMAIN_CUM: 881,   PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-04-19', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 229, CLOSED_WK: 16,  PL_REMAIN_CUM: 2213,   REMAIN_CUM: 2047,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-04-19', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 887, CLOSED_WK: 34,  PL_REMAIN_CUM: 18219,  REMAIN_CUM: 21373, PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-04-19', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 246, CLOSED_WK: 2,   PL_REMAIN_CUM: 8445,   REMAIN_CUM: 4889,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-04-19', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 138, CLOSED_WK: 0,   PL_REMAIN_CUM: 6584,   REMAIN_CUM: 4981,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-04-19', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 649, CLOSED_WK: 19,  PL_REMAIN_CUM: 8610,   REMAIN_CUM: 6947,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-04-19', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 727, CLOSED_WK: 170, PL_REMAIN_CUM: 18684,  REMAIN_CUM: 25780, PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-04-19', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: 0,   PL_REMAIN_CUM: undefined,   REMAIN_CUM: 241,   PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-04-19', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 2928, CLOSED_WK: 241, PL_REMAIN_CUM: 63910,  REMAIN_CUM: 67139, PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-04-26', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-04-26', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 968, CLOSED_WK: undefined, PL_REMAIN_CUM: 17358,  REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-04-26', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 734, CLOSED_WK: undefined, PL_REMAIN_CUM: 7965,   REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-04-26', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 721, CLOSED_WK: undefined, PL_REMAIN_CUM: 18022,  REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-04-26', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 230, CLOSED_WK: undefined, PL_REMAIN_CUM: 1987,   REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-04-26', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 77, CLOSED_WK: undefined, PL_REMAIN_CUM: 1099,   REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-04-26', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 319, CLOSED_WK: undefined, PL_REMAIN_CUM: 8167,   REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-04-26', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 190, CLOSED_WK: undefined, PL_REMAIN_CUM: 6430,   REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-04-26', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 3239, CLOSED_WK: undefined, PL_REMAIN_CUM: 61028,  REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-05-03', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 677, CLOSED_WK: undefined, PL_REMAIN_CUM: 17426,  REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-05-03', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 320, CLOSED_WK: undefined, PL_REMAIN_CUM: 7887,   REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-05-03', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 200, CLOSED_WK: undefined, PL_REMAIN_CUM: 6252,   REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-05-03', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 196, CLOSED_WK: undefined, PL_REMAIN_CUM: 1797,   REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-05-03', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 930, CLOSED_WK: undefined, PL_REMAIN_CUM: 16493,  REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-05-03', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 613, CLOSED_WK: undefined, PL_REMAIN_CUM: 7397,   REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-05-03', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 71, CLOSED_WK: undefined, PL_REMAIN_CUM: 1036,   REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-05-03', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-05-03', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 3007, CLOSED_WK: undefined, PL_REMAIN_CUM: 58288,  REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-05-10', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 241, CLOSED_WK: undefined, PL_REMAIN_CUM: 6043,   REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-05-10', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 216, CLOSED_WK: undefined, PL_REMAIN_CUM: 1604,   REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-05-10', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 1184, CLOSED_WK: undefined, PL_REMAIN_CUM: 15387,  REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-05-10', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 876, CLOSED_WK: undefined, PL_REMAIN_CUM: 16686,  REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-05-10', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 95, CLOSED_WK: undefined, PL_REMAIN_CUM: 954,    REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-05-10', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 791, CLOSED_WK: undefined, PL_REMAIN_CUM: 6690,   REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-05-10', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-05-10', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 388, CLOSED_WK: undefined, PL_REMAIN_CUM: 7531,   REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-05-10', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 3791, CLOSED_WK: undefined, PL_REMAIN_CUM: 54895,  REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-05-17', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 1066, CLOSED_WK: undefined, PL_REMAIN_CUM: 14379,  REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-05-17', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 205, CLOSED_WK: undefined, PL_REMAIN_CUM: 1399,   REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-05-17', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 788, CLOSED_WK: undefined, PL_REMAIN_CUM: 5943,   REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-05-17', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 1352, CLOSED_WK: undefined, PL_REMAIN_CUM: 15760,  REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-05-17', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 298, CLOSED_WK: undefined, PL_REMAIN_CUM: 5818,   REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-05-17', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 93, CLOSED_WK: undefined, PL_REMAIN_CUM: 867,    REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-05-17', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 460, CLOSED_WK: undefined, PL_REMAIN_CUM: 7163,   REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-05-17', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-05-17', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 4262, CLOSED_WK: undefined, PL_REMAIN_CUM: 51329,  REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-05-24', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 632, CLOSED_WK: undefined, PL_REMAIN_CUM: 5360,   REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-05-24', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 916, CLOSED_WK: undefined, PL_REMAIN_CUM: 13509,  REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-05-24', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 335, CLOSED_WK: undefined, PL_REMAIN_CUM: 5529,   REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-05-24', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-05-24', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 1167, CLOSED_WK: undefined, PL_REMAIN_CUM: 14612,  REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-05-24', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 472, CLOSED_WK: undefined, PL_REMAIN_CUM: 6739,   REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-05-24', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 80, CLOSED_WK: undefined, PL_REMAIN_CUM: 789,    REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-05-24', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 154, CLOSED_WK: undefined, PL_REMAIN_CUM: 1245,   REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-05-24', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 3756, CLOSED_WK: undefined, PL_REMAIN_CUM: 47783,  REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-05-31', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 156, CLOSED_WK: undefined, PL_REMAIN_CUM: 1090,   REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-05-31', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 91, CLOSED_WK: undefined, PL_REMAIN_CUM: 707,    REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-05-31', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-05-31', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 1445, CLOSED_WK: undefined, PL_REMAIN_CUM: 13218,  REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-05-31', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 809, CLOSED_WK: undefined, PL_REMAIN_CUM: 4659,   REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-05-31', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 451, CLOSED_WK: undefined, PL_REMAIN_CUM: 5107,   REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-05-31', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 611, CLOSED_WK: undefined, PL_REMAIN_CUM: 6165,   REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-05-31', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 1098, CLOSED_WK: undefined, PL_REMAIN_CUM: 12471,  REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-05-31', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 4661, CLOSED_WK: undefined, PL_REMAIN_CUM: 43417,  REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-06-07', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 1418, CLOSED_WK: undefined, PL_REMAIN_CUM: 11869,  REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-06-07', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-06-07', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 468, CLOSED_WK: undefined, PL_REMAIN_CUM: 4669,   REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-06-07', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 751, CLOSED_WK: undefined, PL_REMAIN_CUM: 3951,   REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-06-07', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 1094, CLOSED_WK: undefined, PL_REMAIN_CUM: 11582,  REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-06-07', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 150, CLOSED_WK: undefined, PL_REMAIN_CUM: 950,    REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-06-07', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 98, CLOSED_WK: undefined, PL_REMAIN_CUM: 621,    REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-06-07', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 627, CLOSED_WK: undefined, PL_REMAIN_CUM: 5571,   REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-06-07', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 4606, CLOSED_WK: undefined, PL_REMAIN_CUM: 39213,  REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-06-14', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 78, CLOSED_WK: undefined, PL_REMAIN_CUM: 876,    REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-06-14', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 770, CLOSED_WK: undefined, PL_REMAIN_CUM: 3292,   REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-06-14', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 1214, CLOSED_WK: undefined, PL_REMAIN_CUM: 10433,  REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-06-14', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-06-14', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 457, CLOSED_WK: undefined, PL_REMAIN_CUM: 4240,   REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-06-14', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 592, CLOSED_WK: undefined, PL_REMAIN_CUM: 5015,   REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-06-14', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 1395, CLOSED_WK: undefined, PL_REMAIN_CUM: 10712,  REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-06-14', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 95, CLOSED_WK: undefined, PL_REMAIN_CUM: 535,    REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-06-14', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 4601, CLOSED_WK: undefined, PL_REMAIN_CUM: 35103,  REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-06-21', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 1040, CLOSED_WK: undefined, PL_REMAIN_CUM: 9495,   REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-06-21', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 70, CLOSED_WK: undefined, PL_REMAIN_CUM: 478,    REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-06-21', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 124, CLOSED_WK: undefined, PL_REMAIN_CUM: 809,    REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-06-21', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 1258, CLOSED_WK: undefined, PL_REMAIN_CUM: 9534,   REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-06-21', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 486, CLOSED_WK: undefined, PL_REMAIN_CUM: 4552,   REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-06-21', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 381, CLOSED_WK: undefined, PL_REMAIN_CUM: 3878,   REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-06-21', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-06-21', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 571, CLOSED_WK: undefined, PL_REMAIN_CUM: 2742,   REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-06-21', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 3930, CLOSED_WK: undefined, PL_REMAIN_CUM: 31488,  REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-06-28', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 609, CLOSED_WK: undefined, PL_REMAIN_CUM: 2153,   REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-06-28', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 1161, CLOSED_WK: undefined, PL_REMAIN_CUM: 8446,   REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-06-28', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 576, CLOSED_WK: undefined, PL_REMAIN_CUM: 4015,   REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-06-28', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 80, CLOSED_WK: undefined, PL_REMAIN_CUM: 406,    REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-06-28', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 148, CLOSED_WK: undefined, PL_REMAIN_CUM: 665,    REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-06-28', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 1439, CLOSED_WK: undefined, PL_REMAIN_CUM: 8133,   REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-06-28', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-06-28', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 476, CLOSED_WK: undefined, PL_REMAIN_CUM: 3441,   REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-06-28', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 4489, CLOSED_WK: undefined, PL_REMAIN_CUM: 27259,  REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-07-05', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 484, CLOSED_WK: undefined, PL_REMAIN_CUM: 2974,   REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-07-05', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 537, CLOSED_WK: undefined, PL_REMAIN_CUM: 1628,   REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-07-05', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 1429, CLOSED_WK: undefined, PL_REMAIN_CUM: 7277,   REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-07-05', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 113, CLOSED_WK: undefined, PL_REMAIN_CUM: 552,    REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-07-05', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 1330, CLOSED_WK: undefined, PL_REMAIN_CUM: 6881,   REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-07-05', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 568, CLOSED_WK: undefined, PL_REMAIN_CUM: 3461,   REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-07-05', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 79, CLOSED_WK: undefined, PL_REMAIN_CUM: 333,    REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-07-05', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-07-05', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 4540, CLOSED_WK: undefined, PL_REMAIN_CUM: 23106,  REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-07-12', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-07-12', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 498, CLOSED_WK: undefined, PL_REMAIN_CUM: 1147,   REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-07-12', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 508, CLOSED_WK: undefined, PL_REMAIN_CUM: 3013,   REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-07-12', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 74, CLOSED_WK: undefined, PL_REMAIN_CUM: 263,    REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-07-12', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 109, CLOSED_WK: undefined, PL_REMAIN_CUM: 443,    REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-07-12', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 1515, CLOSED_WK: undefined, PL_REMAIN_CUM: 5814,   REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-07-12', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 980, CLOSED_WK: undefined, PL_REMAIN_CUM: 5974,   REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-07-12', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 445, CLOSED_WK: undefined, PL_REMAIN_CUM: 2589,   REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-07-12', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 4129, CLOSED_WK: undefined, PL_REMAIN_CUM: 19243,  REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-07-19', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 365, CLOSED_WK: undefined, PL_REMAIN_CUM: 2247,   REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-07-19', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 109, CLOSED_WK: undefined, PL_REMAIN_CUM: 334,    REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-07-19', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 390, CLOSED_WK: undefined, PL_REMAIN_CUM: 758,    REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-07-19', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 990, CLOSED_WK: undefined, PL_REMAIN_CUM: 5035,   REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-07-19', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-07-19', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 1449, CLOSED_WK: undefined, PL_REMAIN_CUM: 4369,   REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-07-19', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 73, CLOSED_WK: undefined, PL_REMAIN_CUM: 191,    REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-07-19', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 416, CLOSED_WK: undefined, PL_REMAIN_CUM: 2626,   REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-07-19', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 3792, CLOSED_WK: undefined, PL_REMAIN_CUM: 15560,  REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-07-26', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 1339, CLOSED_WK: undefined, PL_REMAIN_CUM: 3041,   REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-07-26', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-07-26', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 63, CLOSED_WK: undefined, PL_REMAIN_CUM: 129,    REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-07-26', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 107, CLOSED_WK: undefined, PL_REMAIN_CUM: 228,    REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-07-26', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 952, CLOSED_WK: undefined, PL_REMAIN_CUM: 4104,   REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-07-26', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 382, CLOSED_WK: undefined, PL_REMAIN_CUM: 2251,   REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-07-26', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 337, CLOSED_WK: undefined, PL_REMAIN_CUM: 1915,   REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-07-26', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 283, CLOSED_WK: undefined, PL_REMAIN_CUM: 475,    REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-07-26', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 3463, CLOSED_WK: undefined, PL_REMAIN_CUM: 12143,  REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-08-02', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 1020, CLOSED_WK: undefined, PL_REMAIN_CUM: 2031,   REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-08-02', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-08-02', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 889, CLOSED_WK: undefined, PL_REMAIN_CUM: 3227,   REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-08-02', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 397, CLOSED_WK: undefined, PL_REMAIN_CUM: 1908,   REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-08-02', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 346, CLOSED_WK: undefined, PL_REMAIN_CUM: 1613,   REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-08-02', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 92, CLOSED_WK: undefined, PL_REMAIN_CUM: 136,    REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-08-02', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 50, CLOSED_WK: undefined, PL_REMAIN_CUM: 80,     REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-08-02', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 193, CLOSED_WK: undefined, PL_REMAIN_CUM: 285,    REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-08-02', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 2987, CLOSED_WK: undefined, PL_REMAIN_CUM: 9280,   REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-08-09', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 523, CLOSED_WK: undefined, PL_REMAIN_CUM: 2727,   REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-08-09', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 678, CLOSED_WK: undefined, PL_REMAIN_CUM: 1355,   REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-08-09', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 64, CLOSED_WK: undefined, PL_REMAIN_CUM: 75,     REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-08-09', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 291, CLOSED_WK: undefined, PL_REMAIN_CUM: 1345,   REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-08-09', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 92, CLOSED_WK: undefined, PL_REMAIN_CUM: 194,    REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-08-09', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 30, CLOSED_WK: undefined, PL_REMAIN_CUM: 51,     REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-08-09', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 350, CLOSED_WK: undefined, PL_REMAIN_CUM: 1587,   REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-08-09', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-08-09', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 2028, CLOSED_WK: undefined, PL_REMAIN_CUM: 7334,   REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-08-16', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 24, CLOSED_WK: undefined, PL_REMAIN_CUM: 27,     REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-08-16', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 662, CLOSED_WK: undefined, PL_REMAIN_CUM: 696,    REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-08-16', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 570, CLOSED_WK: undefined, PL_REMAIN_CUM: 2205,   REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-08-16', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 16, CLOSED_WK: undefined, PL_REMAIN_CUM: 59,     REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-08-16', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 79, CLOSED_WK: undefined, PL_REMAIN_CUM: 115,    REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-08-16', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 377, CLOSED_WK: undefined, PL_REMAIN_CUM: 1232,   REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-08-16', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 326, CLOSED_WK: undefined, PL_REMAIN_CUM: 1038,   REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-08-16', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-08-16', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 2054, CLOSED_WK: undefined, PL_REMAIN_CUM: 5372,   REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-08-23', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 587, CLOSED_WK: undefined, PL_REMAIN_CUM: 1618,   REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-08-23', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 274, CLOSED_WK: undefined, PL_REMAIN_CUM: 764,    REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-08-23', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 13, CLOSED_WK: undefined, PL_REMAIN_CUM: 14,     REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-08-23', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 505, CLOSED_WK: undefined, PL_REMAIN_CUM: 191,    REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-08-23', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 57, CLOSED_WK: undefined, PL_REMAIN_CUM: 58,     REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-08-23', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 10, CLOSED_WK: undefined, PL_REMAIN_CUM: 49,     REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-08-23', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 321, CLOSED_WK: undefined, PL_REMAIN_CUM: 911,    REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-08-23', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-08-23', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 1767, CLOSED_WK: undefined, PL_REMAIN_CUM: 3605,   REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-08-30', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 238, CLOSED_WK: undefined, PL_REMAIN_CUM: 526,    REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-08-30', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-08-30', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 110, CLOSED_WK: undefined, PL_REMAIN_CUM: 81,     REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-08-30', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 36, CLOSED_WK: undefined, PL_REMAIN_CUM: 22,     REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-08-30', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 7, CLOSED_WK: undefined, PL_REMAIN_CUM: 7,      REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-08-30', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 10, CLOSED_WK: undefined, PL_REMAIN_CUM: 39,     REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-08-30', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 442, CLOSED_WK: undefined, PL_REMAIN_CUM: 1176,   REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-08-30', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 282, CLOSED_WK: undefined, PL_REMAIN_CUM: 629,    REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-08-30', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 1125, CLOSED_WK: undefined, PL_REMAIN_CUM: 2480,   REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-09-06', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 10, CLOSED_WK: undefined, PL_REMAIN_CUM: 29,     REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-09-06', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 6, CLOSED_WK: undefined, PL_REMAIN_CUM: 16,     REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-09-06', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 207, CLOSED_WK: undefined, PL_REMAIN_CUM: 422,    REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-09-06', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 45, CLOSED_WK: undefined, PL_REMAIN_CUM: 36,     REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-09-06', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 169, CLOSED_WK: undefined, PL_REMAIN_CUM: 357,    REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-09-06', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-09-06', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 342, CLOSED_WK: undefined, PL_REMAIN_CUM: 834,    REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-09-06', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 3, CLOSED_WK: undefined, PL_REMAIN_CUM: 4,      REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-09-06', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 782, CLOSED_WK: undefined, PL_REMAIN_CUM: 1698,   REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-09-13', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 6, CLOSED_WK: undefined, PL_REMAIN_CUM: 10,     REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-09-13', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 2, CLOSED_WK: undefined, PL_REMAIN_CUM: 2,      REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-09-13', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 10, CLOSED_WK: undefined, PL_REMAIN_CUM: 19,     REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-09-13', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 273, CLOSED_WK: undefined, PL_REMAIN_CUM: 561,    REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-09-13', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 22, CLOSED_WK: undefined, PL_REMAIN_CUM: 14,     REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-09-13', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 142, CLOSED_WK: undefined, PL_REMAIN_CUM: 215,    REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-09-13', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-09-13', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 173, CLOSED_WK: undefined, PL_REMAIN_CUM: 249,    REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-09-13', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 628, CLOSED_WK: undefined, PL_REMAIN_CUM: 1070,   REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-09-20', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 6, CLOSED_WK: undefined, PL_REMAIN_CUM: 4,      REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-09-20', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 1, CLOSED_WK: undefined, PL_REMAIN_CUM: 1,      REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-09-20', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 127, CLOSED_WK: undefined, PL_REMAIN_CUM: 88,     REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-09-20', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 10, CLOSED_WK: undefined, PL_REMAIN_CUM: 4,      REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-09-20', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 9, CLOSED_WK: undefined, PL_REMAIN_CUM: 10,     REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-09-20', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 215, CLOSED_WK: undefined, PL_REMAIN_CUM: 346,    REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-09-20', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-09-20', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 150, CLOSED_WK: undefined, PL_REMAIN_CUM: 99,     REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-09-20', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 518, CLOSED_WK: undefined, PL_REMAIN_CUM: 552,    REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-09-27', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 3, CLOSED_WK: undefined, PL_REMAIN_CUM: 1,      REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-09-27', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-09-27', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 177, CLOSED_WK: undefined, PL_REMAIN_CUM: 169,    REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-09-27', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 3, CLOSED_WK: undefined, PL_REMAIN_CUM: 1,      REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-09-27', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 1, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-09-27', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 8, CLOSED_WK: undefined, PL_REMAIN_CUM: 2,      REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-09-27', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 70, CLOSED_WK: undefined, PL_REMAIN_CUM: 18,     REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-09-27', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 78, CLOSED_WK: undefined, PL_REMAIN_CUM: 21,     REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-09-27', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 340, CLOSED_WK: undefined, PL_REMAIN_CUM: 212,    REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-10-04', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-10-04', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-10-04', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 124, CLOSED_WK: undefined, PL_REMAIN_CUM: 45,     REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-10-04', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 1, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-10-04', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 17, CLOSED_WK: undefined, PL_REMAIN_CUM: 1,      REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-10-04', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 2, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-10-04', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 1, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-10-04', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 20, CLOSED_WK: undefined, PL_REMAIN_CUM: 1,      REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-10-04', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 165, CLOSED_WK: undefined, PL_REMAIN_CUM: 47,     REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-10-11', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-10-11', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 1, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-10-11', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-10-11', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-10-11', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 45, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-10-11', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-10-11', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 1, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-10-11', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-10-11', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 47, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-10-18', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-10-18', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-10-18', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-10-18', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-10-18', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-10-18', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-10-18', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-10-18', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-10-18', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-10-25', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-10-25', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-10-25', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-10-25', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-10-25', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-10-25', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-10-25', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-10-25', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-10-25', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-11-01', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-11-01', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-11-01', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-11-01', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-11-01', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-11-01', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-11-01', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-11-01', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-11-01', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },
    { CDATE: '2024-11-08', ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: undefined,   REMAIN_CUM: undefined,  PL_TOTAL: undefined,   TOTAL: 389, },
    { CDATE: '2024-11-08', ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 45092,  TOTAL: 45624, },
    { CDATE: '2024-11-08', ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 13007,  TOTAL: 15280, },
    { CDATE: '2024-11-08', ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 3664,   TOTAL: 3701, },
    { CDATE: '2024-11-08', ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 32323,  TOTAL: 32401, },
    { CDATE: '2024-11-08', ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 7590,   TOTAL: 7720, },
    { CDATE: '2024-11-08', ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 10089,  TOTAL: 10221, },
    { CDATE: '2024-11-08', ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 1497,   TOTAL: 1560, },
    { CDATE: '2024-11-08', ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     PL_CLOSED_WK: 0, CLOSED_WK: undefined, PL_REMAIN_CUM: 0,      REMAIN_CUM: undefined,  PL_TOTAL: 113262, TOTAL: 116896, },

],


  Queries: {

    // Overall
    SQL1: [
      { PLAN_MAX: '2024-10-28', A_TOT: 116896, A_ACT: 49757, A_REM: 67139, A_PRO: 42.6, },
    ], 


    // Discipline & Checksheet
    SQL2: [
      { ITEM: 'A',  DISC: 'S', DISC_SHORT: 'S.STLX', TOT: 389,    ACT: 148,   REM: 241, },
      { ITEM: 'A',  DISC: 'P', DISC_SHORT: 'P.PIPE', TOT: 15280,  ACT: 8333,  REM: 6947, },
      { ITEM: 'A',  DISC: 'M', DISC_SHORT: 'M.MECH', TOT: 1560,   ACT: 679,   REM: 881, },
      { ITEM: 'A',  DISC: 'I', DISC_SHORT: 'I.INST', TOT: 45624,  ACT: 19844, REM: 25780, },
      { ITEM: 'A',  DISC: 'X', DISC_SHORT: 'X.PANT', TOT: 10221,  ACT: 5332,  REM: 4889, },
      { ITEM: 'A',  DISC: 'C', DISC_SHORT: 'C.CIVL', TOT: 3701,   ACT: 1654,  REM: 2047, },
      { ITEM: 'A',  DISC: 'E', DISC_SHORT: 'E.ELEC', TOT: 32401,  ACT: 11028, REM: 21373, },
      { ITEM: 'A',  DISC: 'Q', DISC_SHORT: 'Q.INSL', TOT: 7720,   ACT: 2739,  REM: 4981, },
      { ITEM: 'OV', DISC: 'OV',     DISC_SHORT: 'OV',     TOT: 116896, ACT: 49757, REM: 67139, },      
    ], 


    // Canvas & Chart forms
    SQL3: [ 
      {
        CANVAS_WIDTH:           1300,
        CANVAS_HEIGHT:          850,
        
        PADDING_TOP:            290,
        PADDING_RIGHT:          90,
        PADDING_BOTTOM:         30,
        PADDING_LEFT:           90,

        START_DATE:             '2023-07-07', 
        END_DATE:               '2024-12-27',
        WEEKEND:                'friday',
        CUTOFF:                 '2024-01-26'
      }
    ],

  }
}