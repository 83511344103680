
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 170,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  


  DataItems: [
    {
      SSMCC_PLN: 665,  SSMCC_WD: 40,
      SSMCC_TOT: 695, SSMCC_ACT: 30,  SSMCC_REM: 665,  SSMCC_PRO: 4.3,  
      A_TOT: 111225,  A_ACT: 29482,   A_REM: 81743,   A_PRO: 26.5, 
      AP_TOT: 946,    AP_ACT: 387,    AP_REM: 559,    AP_PRO: 40.9, 
      B_TOT: 35404,   B_ACT: 10178,   B_REM: 25226,   B_PRO: 28.7, 
      BP_TOT: 920,    BP_ACT: 400,    BP_REM: 520,    BP_PRO: 43.5, 
      C_TOT: 1484,    C_ACT: 0,       C_REM: 1484,    C_PRO: 0,    
      CP_TOT: 755,    CP_ACT: 375,    CP_REM: 380,    CP_PRO: 49.7, 
    },
  ],

  Queries: {

    SQL1: [
      {
        HEADER_X:             0,
        HEADER_COLUMN:        '350 / 115 / 115 / 115 / 115 / 215 / 215',
        HEADER_HEIGHT:        170,
        
        // Overall
        OVERALL_LENGTH:        350,
        OVERALL_NAME:         ' Turnover / Walkdown / A Checksheet / B Checksheet ',
        OVERALL_CODE:         ' SSMCC    / WD       / A            / B            ',
        OVERALL_ALIGN:        ' end      / end      / start        / start        ',
        OVERALL_COL_WIDTH:    ' 40       / 60       / 100          / 50           ',

        // Bar Chart
        SCOPE_NAME:           'Grassroots',
        SCOPE_CODE:           'GR',
        SCOPE_TITLE:          ' Mech.Completion       / Pre-Comm. / Walkdown / Turnover ',
        SCOPE_COLOR:          ' #88C8EB   / #FEED57   / #F7BACF  / #F7BACF  ',
        SCOPE_BAR_COLOR:      ' LightBlue / Yellow    / Pink     / Pink     ',
        SCOPE_OPACITY:        ' .3        / .3        /.6        / .9       ',

        // Sheet Summary
        SHEET_LENGTH:         215,
        SHEET_TITLE:          ' Mechanical Completion     / Pre-Commissiong',
        SHEET_SUB_TITLE:      ' Checksheet & Punch Status / Checksheet & Punch Status ',
      }
    ],
  },
  
}