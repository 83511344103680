
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 800,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 900,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    { MS_TYPE: 'WAYPOINT', MS_NO: 'WP-25', OWNER: 'IPT',      MS_DESC: 'Make Up and Recycle Gas Compressors Ready for Commissioning',   PLAN: '2024-10-11', ACTUAL: null,        A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NOTREGISTERED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NOTREGISTERED', B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NOTREGISTERED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0,   CP_STATUS: 'NOTREGISTERED', C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'WAYPOINT', MS_NO: 'WP-31', OWNER: 'SUT',      MS_DESC: '1st Hydrogen on Pitch Feed Received',                           PLAN: '2025-01-13', ACTUAL: null,        A_TOT: 7347,  A_ACT: 2347,  A_REM: 5000,  A_PRO: 31, A_STATUS: 'INPROGRESS',    AP_TOT: 27,  AP_ACT: 0,   AP_REM: 27,  AP_PRO: 0,   AP_STATUS: 'NOTSTARTED',    BP_TOT: 63,  BP_ACT: 0,   BP_REM: 63,  BP_PRO: 0,  BP_STATUS: 'NOTSTARTED',    B_TOT: 1422,  B_ACT: 589,  B_REM: 833,   B_PRO: 41,  B_STATUS: 'INPROGRESS',    CP_TOT: 29,  CP_ACT: 0,   CP_REM: 29,  CP_PRO: 0,   CP_STATUS: 'NOTSTARTED',    C_TOT: 171,  C_ACT: 0, C_REM: 171, },
{ MS_TYPE: 'WAYPOINT', MS_NO: 'WP-10', OWNER: 'ISCT',     MS_DESC: 'Substation V Energized',                                        PLAN: '2023-09-29', ACTUAL: null,        A_TOT: 726,   A_ACT: 593,   A_REM: 133,   A_PRO: 81, A_STATUS: 'INPROGRESS',    AP_TOT: 308, AP_ACT: 282, AP_REM: 26,  AP_PRO: 91,  AP_STATUS: 'INPROGRESS',    BP_TOT: 248, BP_ACT: 206, BP_REM: 42,  BP_PRO: 83, BP_STATUS: 'INPROGRESS',    B_TOT: 151,   B_ACT: 103,  B_REM: 48,    B_PRO: 68,  B_STATUS: 'INPROGRESS',    CP_TOT: 353, CP_ACT: 291, CP_REM: 62,  CP_PRO: 82,  CP_STATUS: 'INPROGRESS',    C_TOT: 34,   C_ACT: 0, C_REM: 34, },
{ MS_TYPE: 'WAYPOINT', MS_NO: 'WP-34', OWNER: 'SUT',      MS_DESC: 'Units at Design Rates with All Products on Spec',               PLAN: '2025-04-01', ACTUAL: null,        A_TOT: 73595, A_ACT: 16846, A_REM: 56749, A_PRO: 22, A_STATUS: 'INPROGRESS',    AP_TOT: 312, AP_ACT: 10,  AP_REM: 302, AP_PRO: 3,   AP_STATUS: 'INPROGRESS',    BP_TOT: 410, BP_ACT: 84,  BP_REM: 326, BP_PRO: 20, BP_STATUS: 'INPROGRESS',    B_TOT: 24126, B_ACT: 5668, B_REM: 18458, B_PRO: 23,  B_STATUS: 'INPROGRESS',    CP_TOT: 238, CP_ACT: 50,  CP_REM: 188, CP_PRO: 21,  CP_STATUS: 'INPROGRESS',    C_TOT: 518,  C_ACT: 0, C_REM: 518, },
{ MS_TYPE: 'WAYPOINT', MS_NO: 'WP-18', OWNER: 'SUT',      MS_DESC: '1st Reactor Loaded',                                            PLAN: '2024-06-14', ACTUAL: null,        A_TOT: 677,   A_ACT: 45,    A_REM: 632,   A_PRO: 6,  A_STATUS: 'INPROGRESS',    AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NOTREGISTERED', B_TOT: 186,   B_ACT: 4,    B_REM: 182,   B_PRO: 2,   B_STATUS: 'INPROGRESS',    CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0,   CP_STATUS: 'NOTREGISTERED', C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'WAYPOINT', MS_NO: 'WP-23', OWNER: 'IPT',      MS_DESC: 'Completion of all Existing Site Scope',                         PLAN: '2024-10-30', ACTUAL: null,        A_TOT: 45,    A_ACT: 30,    A_REM: 15,    A_PRO: 66, A_STATUS: 'INPROGRESS',    AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100, AP_STATUS: 'COMPLETED',     BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NOTREGISTERED', B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NOTREGISTERED', CP_TOT: 5,   CP_ACT: 5,   CP_REM: 0,   CP_PRO: 100, CP_STATUS: 'COMPLETED',     C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'WAYPOINT', MS_NO: 'WP-24', OWNER: 'SUT',      MS_DESC: 'Flare System Live',                                             PLAN: '2024-09-26', ACTUAL: null,        A_TOT: 2187,  A_ACT: 686,   A_REM: 1501,  A_PRO: 31, A_STATUS: 'INPROGRESS',    AP_TOT: 11,  AP_ACT: 0,   AP_REM: 11,  AP_PRO: 0,   AP_STATUS: 'NOTSTARTED',    BP_TOT: 13,  BP_ACT: 0,   BP_REM: 13,  BP_PRO: 0,  BP_STATUS: 'NOTSTARTED',    B_TOT: 926,   B_ACT: 301,  B_REM: 625,   B_PRO: 32,  B_STATUS: 'INPROGRESS',    CP_TOT: 5,   CP_ACT: 0,   CP_REM: 5,   CP_PRO: 0,   CP_STATUS: 'NOTSTARTED',    C_TOT: 8,    C_ACT: 0, C_REM: 8, },
{ MS_TYPE: 'WAYPOINT', MS_NO: 'WP-26', OWNER: 'IPT',      MS_DESC: 'All Grassroots Facilities Turned Over',                         PLAN: '2024-10-30', ACTUAL: null,        A_TOT: 3873,  A_ACT: 1440,  A_REM: 2433,  A_PRO: 37, A_STATUS: 'INPROGRESS',    AP_TOT: 13,  AP_ACT: 0,   AP_REM: 13,  AP_PRO: 0,   AP_STATUS: 'NOTSTARTED',    BP_TOT: 15,  BP_ACT: 2,   BP_REM: 13,  BP_PRO: 13, BP_STATUS: 'INPROGRESS',    B_TOT: 448,   B_ACT: 118,  B_REM: 330,   B_PRO: 26,  B_STATUS: 'INPROGRESS',    CP_TOT: 3,   CP_ACT: 0,   CP_REM: 3,   CP_PRO: 0,   CP_STATUS: 'NOTSTARTED',    C_TOT: 2,    C_ACT: 0, C_REM: 2, },
{ MS_TYPE: 'WAYPOINT', MS_NO: 'WP-28', OWNER: 'ISCT',     MS_DESC: 'Product Tanks & Inter-connecting Lines Commissioned',           PLAN: '2024-12-10', ACTUAL: null,        A_TOT: 3811,  A_ACT: 656,   A_REM: 3155,  A_PRO: 17, A_STATUS: 'INPROGRESS',    AP_TOT: 8,   AP_ACT: 0,   AP_REM: 8,   AP_PRO: 0,   AP_STATUS: 'NOTSTARTED',    BP_TOT: 8,   BP_ACT: 0,   BP_REM: 8,   BP_PRO: 0,  BP_STATUS: 'NOTSTARTED',    B_TOT: 1426,  B_ACT: 350,  B_REM: 1076,  B_PRO: 24,  B_STATUS: 'INPROGRESS',    CP_TOT: 7,   CP_ACT: 0,   CP_REM: 7,   CP_PRO: 0,   CP_STATUS: 'NOTSTARTED',    C_TOT: 25,   C_ACT: 0, C_REM: 25, },
{ MS_TYPE: 'WAYPOINT', MS_NO: 'WP-15', OWNER: 'ISCT',     MS_DESC: 'Controls System Ready for Loop Checks',                         PLAN: '2024-02-13', ACTUAL: null,        A_TOT: 681,   A_ACT: 441,   A_REM: 240,   A_PRO: 64, A_STATUS: 'INPROGRESS',    AP_TOT: 151, AP_ACT: 105, AP_REM: 46,  AP_PRO: 69,  AP_STATUS: 'INPROGRESS',    BP_TOT: 234, BP_ACT: 182, BP_REM: 52,  BP_PRO: 77, BP_STATUS: 'INPROGRESS',    B_TOT: 277,   B_ACT: 147,  B_REM: 130,   B_PRO: 53,  B_STATUS: 'INPROGRESS',    CP_TOT: 170, CP_ACT: 81,  CP_REM: 89,  CP_PRO: 47,  CP_STATUS: 'INPROGRESS',    C_TOT: 51,   C_ACT: 0, C_REM: 51, },
{ MS_TYPE: 'WAYPOINT', MS_NO: 'WP-19', OWNER: 'ISCT',     MS_DESC: 'Essential Utilities in Service',                                PLAN: '2024-09-07', ACTUAL: null,        A_TOT: 8960,  A_ACT: 4179,  A_REM: 4781,  A_PRO: 46, A_STATUS: 'INPROGRESS',    AP_TOT: 83,  AP_ACT: 3,   AP_REM: 80,  AP_PRO: 3,   AP_STATUS: 'INPROGRESS',    BP_TOT: 101, BP_ACT: 12,  BP_REM: 89,  BP_PRO: 11, BP_STATUS: 'INPROGRESS',    B_TOT: 4648,  B_ACT: 2558, B_REM: 2090,  B_PRO: 55,  B_STATUS: 'INPROGRESS',    CP_TOT: 62,  CP_ACT: 26,  CP_REM: 36,  CP_PRO: 41,  CP_STATUS: 'INPROGRESS',    C_TOT: 120,  C_ACT: 0, C_REM: 120, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-10', OWNER: 'ISCT',     MS_DESC: 'Fire Water Available',                                          PLAN: '2024-08-26', ACTUAL: null,        A_TOT: 98525, A_ACT: 24441, A_REM: 74084, A_PRO: 24, A_STATUS: 'INPROGRESS',    AP_TOT: 269, AP_ACT: 0,   AP_REM: 269, AP_PRO: 0,   AP_STATUS: 'NOTSTARTED',    BP_TOT: 238, BP_ACT: 10,  BP_REM: 228, BP_PRO: 4,  BP_STATUS: 'INPROGRESS',    B_TOT: 31432, B_ACT: 8758, B_REM: 22674, B_PRO: 27,  B_STATUS: 'INPROGRESS',    CP_TOT: 81,  CP_ACT: 3,   CP_REM: 78,  CP_PRO: 3,   CP_STATUS: 'INPROGRESS',    C_TOT: 1172, C_ACT: 0, C_REM: 1172, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-13', OWNER: 'ISCT',     MS_DESC: 'SLOP Commissioned',                                             PLAN: '2024-08-19', ACTUAL: null,        A_TOT: 47,    A_ACT: 24,    A_REM: 23,    A_PRO: 51, A_STATUS: 'INPROGRESS',    AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,   AP_STATUS: 'NOTSTARTED',    BP_TOT: 2,   BP_ACT: 0,   BP_REM: 2,   BP_PRO: 0,  BP_STATUS: 'NOTSTARTED',    B_TOT: 15,    B_ACT: 8,    B_REM: 7,     B_PRO: 53,  B_STATUS: 'INPROGRESS',    CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0,   CP_STATUS: 'NOTREGISTERED', C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-28', OWNER: 'SUT',      MS_DESC: 'PFBS S/U',                                                      PLAN: '2024-11-24', ACTUAL: null,        A_TOT: 205,   A_ACT: 59,    A_REM: 146,   A_PRO: 28, A_STATUS: 'INPROGRESS',    AP_TOT: 7,   AP_ACT: 1,   AP_REM: 6,   AP_PRO: 14,  AP_STATUS: 'INPROGRESS',    BP_TOT: 7,   BP_ACT: 1,   BP_REM: 6,   BP_PRO: 14, BP_STATUS: 'INPROGRESS',    B_TOT: 76,    B_ACT: 19,   B_REM: 57,    B_PRO: 25,  B_STATUS: 'INPROGRESS',    CP_TOT: 1,   CP_ACT: 1,   CP_REM: 0,   CP_PRO: 100, CP_STATUS: 'COMPLETED',     C_TOT: 32,   C_ACT: 0, C_REM: 32, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-35', OWNER: 'SUT',      MS_DESC: 'MCR Tank Pre-build Complete',                                   PLAN: '2024-12-06', ACTUAL: null,        A_TOT: 387,   A_ACT: 201,   A_REM: 186,   A_PRO: 51, A_STATUS: 'INPROGRESS',    AP_TOT: 17,  AP_ACT: 4,   AP_REM: 13,  AP_PRO: 23,  AP_STATUS: 'INPROGRESS',    BP_TOT: 78,  BP_ACT: 12,  BP_REM: 66,  BP_PRO: 15, BP_STATUS: 'INPROGRESS',    B_TOT: 145,   B_ACT: 45,   B_REM: 100,   B_PRO: 31,  B_STATUS: 'INPROGRESS',    CP_TOT: 15,  CP_ACT: 9,   CP_REM: 6,   CP_PRO: 60,  CP_STATUS: 'INPROGRESS',    C_TOT: 4,    C_ACT: 0, C_REM: 4, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-16', OWNER: 'SUT',      MS_DESC: 'Fuel Gas Available',                                            PLAN: '2024-09-28', ACTUAL: null,        A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NOTREGISTERED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NOTREGISTERED', B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NOTREGISTERED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0,   CP_STATUS: 'NOTREGISTERED', C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-22', OWNER: 'SUT',      MS_DESC: 'Hot Oil System Circulation',                                    PLAN: '2024-10-26', ACTUAL: null,        A_TOT: 8,     A_ACT: 0,     A_REM: 8,     A_PRO: 0,  A_STATUS: 'NOTSTARTED',    AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NOTREGISTERED', B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NOTREGISTERED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0,   CP_STATUS: 'NOTREGISTERED', C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-27', OWNER: 'SUT',      MS_DESC: 'DAU on Spec Min Rates',                                         PLAN: '2024-12-02', ACTUAL: null,        A_TOT: 63,    A_ACT: 58,    A_REM: 5,     A_PRO: 92, A_STATUS: 'INPROGRESS',    AP_TOT: 5,   AP_ACT: 1,   AP_REM: 4,   AP_PRO: 20,  AP_STATUS: 'INPROGRESS',    BP_TOT: 19,  BP_ACT: 0,   BP_REM: 19,  BP_PRO: 0,  BP_STATUS: 'NOTSTARTED',    B_TOT: 23,    B_ACT: 17,   B_REM: 6,     B_PRO: 73,  B_STATUS: 'INPROGRESS',    CP_TOT: 20,  CP_ACT: 7,   CP_REM: 13,  CP_PRO: 35,  CP_STATUS: 'INPROGRESS',    C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-36', OWNER: 'SUT',      MS_DESC: 'CCFU Solid Separation SAT',                                     PLAN: '2024-10-08', ACTUAL: null,        A_TOT: 175,   A_ACT: 129,   A_REM: 46,    A_PRO: 73, A_STATUS: 'INPROGRESS',    AP_TOT: 18,  AP_ACT: 9,   AP_REM: 9,   AP_PRO: 50,  AP_STATUS: 'INPROGRESS',    BP_TOT: 64,  BP_ACT: 16,  BP_REM: 48,  BP_PRO: 25, BP_STATUS: 'INPROGRESS',    B_TOT: 76,    B_ACT: 44,   B_REM: 32,    B_PRO: 57,  B_STATUS: 'INPROGRESS',    CP_TOT: 29,  CP_ACT: 15,  CP_REM: 14,  CP_PRO: 51,  CP_STATUS: 'INPROGRESS',    C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-6',  OWNER: 'SUT',      MS_DESC: 'Last DHU S2 Reactor Loaded',                                    PLAN: '2024-08-17', ACTUAL: null,        A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NOTREGISTERED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NOTREGISTERED', B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NOTREGISTERED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0,   CP_STATUS: 'NOTREGISTERED', C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-2',  OWNER: 'ISCT',     MS_DESC: 'RIE1 Ready for Loop Check',                                     PLAN: '2023-12-07', ACTUAL: null,        A_TOT: 594,   A_ACT: 414,   A_REM: 180,   A_PRO: 69, A_STATUS: 'INPROGRESS',    AP_TOT: 131, AP_ACT: 95,  AP_REM: 36,  AP_PRO: 72,  AP_STATUS: 'INPROGRESS',    BP_TOT: 203, BP_ACT: 178, BP_REM: 25,  BP_PRO: 87, BP_STATUS: 'INPROGRESS',    B_TOT: 197,   B_ACT: 96,   B_REM: 101,   B_PRO: 48,  B_STATUS: 'INPROGRESS',    CP_TOT: 160, CP_ACT: 81,  CP_REM: 79,  CP_PRO: 50,  CP_STATUS: 'INPROGRESS',    C_TOT: 41,   C_ACT: 0, C_REM: 41, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-20', OWNER: 'ISCT',     MS_DESC: 'ARU Circulation',                                               PLAN: '2024-11-28', ACTUAL: null,        A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NOTREGISTERED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NOTREGISTERED', B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NOTREGISTERED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0,   CP_STATUS: 'NOTREGISTERED', C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-23', OWNER: 'SUT',      MS_DESC: 'DAU Feed Tank in Service',                                      PLAN: '2024-10-05', ACTUAL: null,        A_TOT: 1391,  A_ACT: 491,   A_REM: 900,   A_PRO: 35, A_STATUS: 'INPROGRESS',    AP_TOT: 8,   AP_ACT: 1,   AP_REM: 7,   AP_PRO: 12,  AP_STATUS: 'INPROGRESS',    BP_TOT: 17,  BP_ACT: 0,   BP_REM: 17,  BP_PRO: 0,  BP_STATUS: 'NOTSTARTED',    B_TOT: 209,   B_ACT: 125,  B_REM: 84,    B_PRO: 59,  B_STATUS: 'INPROGRESS',    CP_TOT: 12,  CP_ACT: 1,   CP_REM: 11,  CP_PRO: 8,   CP_STATUS: 'INPROGRESS',    C_TOT: 123,  C_ACT: 0, C_REM: 123, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-31', OWNER: 'SUT',      MS_DESC: 'GR EHC 340 Max Lubes System Cleaning Complete',                 PLAN: '2025-02-08', ACTUAL: null,        A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NOTREGISTERED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NOTREGISTERED', B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NOTREGISTERED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0,   CP_STATUS: 'NOTREGISTERED', C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-4',  OWNER: 'ISCT',     MS_DESC: 'Instrument Air Distribution Available',                         PLAN: '2024-03-08', ACTUAL: null,        A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NOTREGISTERED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NOTREGISTERED', B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NOTREGISTERED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0,   CP_STATUS: 'NOTREGISTERED', C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-15', OWNER: 'SUT',      MS_DESC: 'SRU Catalyst Loaded',                                           PLAN: '2024-06-14', ACTUAL: null,        A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NOTREGISTERED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NOTREGISTERED', B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NOTREGISTERED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0,   CP_STATUS: 'NOTREGISTERED', C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-24', OWNER: 'ISCT',     MS_DESC: 'Makeup & Recycle Gas Compressor N2 String Test Complete (DHU)', PLAN: '2024-10-29', ACTUAL: null,        A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NOTREGISTERED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NOTREGISTERED', B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NOTREGISTERED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0,   CP_STATUS: 'NOTREGISTERED', C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-8',  OWNER: 'ISCT',     MS_DESC: 'Cooling Water in Service',                                      PLAN: '2024-08-24', ACTUAL: null,        A_TOT: 817,   A_ACT: 251,   A_REM: 566,   A_PRO: 30, A_STATUS: 'INPROGRESS',    AP_TOT: 1,   AP_ACT: 0,   AP_REM: 1,   AP_PRO: 0,   AP_STATUS: 'NOTSTARTED',    BP_TOT: 7,   BP_ACT: 1,   BP_REM: 6,   BP_PRO: 14, BP_STATUS: 'INPROGRESS',    B_TOT: 147,   B_ACT: 42,   B_REM: 105,   B_PRO: 28,  B_STATUS: 'INPROGRESS',    CP_TOT: 14,  CP_ACT: 4,   CP_REM: 10,  CP_PRO: 28,  CP_STATUS: 'INPROGRESS',    C_TOT: 2,    C_ACT: 0, C_REM: 2, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-17', OWNER: 'ISCT',     MS_DESC: 'HSFOC Commissioned',                                            PLAN: '2024-10-24', ACTUAL: null,        A_TOT: 1554,  A_ACT: 972,   A_REM: 582,   A_PRO: 62, A_STATUS: 'INPROGRESS',    AP_TOT: 53,  AP_ACT: 4,   AP_REM: 49,  AP_PRO: 7,   AP_STATUS: 'INPROGRESS',    BP_TOT: 115, BP_ACT: 2,   BP_REM: 113, BP_PRO: 1,  BP_STATUS: 'INPROGRESS',    B_TOT: 496,   B_ACT: 237,  B_REM: 259,   B_PRO: 47,  B_STATUS: 'INPROGRESS',    CP_TOT: 47,  CP_ACT: 2,   CP_REM: 45,  CP_PRO: 4,   CP_STATUS: 'INPROGRESS',    C_TOT: 48,   C_ACT: 0, C_REM: 48, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-21', OWNER: 'ISCT',     MS_DESC: 'SWS Circulation',                                               PLAN: '2024-10-07', ACTUAL: null,        A_TOT: 122,   A_ACT: 34,    A_REM: 88,    A_PRO: 27, A_STATUS: 'INPROGRESS',    AP_TOT: 5,   AP_ACT: 0,   AP_REM: 5,   AP_PRO: 0,   AP_STATUS: 'NOTSTARTED',    BP_TOT: 3,   BP_ACT: 0,   BP_REM: 3,   BP_PRO: 0,  BP_STATUS: 'NOTSTARTED',    B_TOT: 58,    B_ACT: 10,   B_REM: 48,    B_PRO: 17,  B_STATUS: 'INPROGRESS',    CP_TOT: 1,   CP_ACT: 0,   CP_REM: 1,   CP_PRO: 0,   CP_STATUS: 'NOTSTARTED',    C_TOT: 3,    C_ACT: 0, C_REM: 3, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-29', OWNER: 'SUT',      MS_DESC: 'DHU S1 on Spec Min Rates',                                      PLAN: '2025-01-19', ACTUAL: null,        A_TOT: 1375,  A_ACT: 621,   A_REM: 754,   A_PRO: 45, A_STATUS: 'INPROGRESS',    AP_TOT: 46,  AP_ACT: 20,  AP_REM: 26,  AP_PRO: 43,  AP_STATUS: 'INPROGRESS',    BP_TOT: 251, BP_ACT: 99,  BP_REM: 152, BP_PRO: 39, BP_STATUS: 'INPROGRESS',    B_TOT: 493,   B_ACT: 209,  B_REM: 284,   B_PRO: 42,  B_STATUS: 'INPROGRESS',    CP_TOT: 130, CP_ACT: 62,  CP_REM: 68,  CP_PRO: 47,  CP_STATUS: 'INPROGRESS',    C_TOT: 19,   C_ACT: 0, C_REM: 19, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-3',  OWNER: 'ISCT',     MS_DESC: 'RIE2 Ready for Loop Check',                                     PLAN: '2023-12-07', ACTUAL: null,        A_TOT: 826,   A_ACT: 633,   A_REM: 193,   A_PRO: 76, A_STATUS: 'INPROGRESS',    AP_TOT: 328, AP_ACT: 292, AP_REM: 36,  AP_PRO: 89,  AP_STATUS: 'INPROGRESS',    BP_TOT: 282, BP_ACT: 212, BP_REM: 70,  BP_PRO: 75, BP_STATUS: 'INPROGRESS',    B_TOT: 231,   B_ACT: 154,  B_REM: 77,    B_PRO: 66,  B_STATUS: 'INPROGRESS',    CP_TOT: 363, CP_ACT: 291, CP_REM: 72,  CP_PRO: 80,  CP_STATUS: 'INPROGRESS',    C_TOT: 44,   C_ACT: 0, C_REM: 44, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-30', OWNER: 'SUT',      MS_DESC: 'DHU S2 on Spec Min Rates',                                      PLAN: '2025-02-05', ACTUAL: null,        A_TOT: 407,   A_ACT: 34,    A_REM: 373,   A_PRO: 8,  A_STATUS: 'INPROGRESS',    AP_TOT: 8,   AP_ACT: 0,   AP_REM: 8,   AP_PRO: 0,   AP_STATUS: 'NOTSTARTED',    BP_TOT: 11,  BP_ACT: 0,   BP_REM: 11,  BP_PRO: 0,  BP_STATUS: 'NOTSTARTED',    B_TOT: 153,   B_ACT: 8,    B_REM: 145,   B_PRO: 5,   B_STATUS: 'INPROGRESS',    CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,   CP_PRO: 0,   CP_STATUS: 'NOTSTARTED',    C_TOT: 11,   C_ACT: 0, C_REM: 11, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-33', OWNER: 'ISCT',     MS_DESC: 'Recycle Gas Compressor N2 String Test Complete (CCFU)',         PLAN: '2024-12-31', ACTUAL: null,        A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NOTREGISTERED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NOTREGISTERED', B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NOTREGISTERED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0,   CP_STATUS: 'NOTREGISTERED', C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-7',  OWNER: 'SUT',      MS_DESC: 'Last CCFU Reactor Loaded',                                      PLAN: '2024-06-16', ACTUAL: null,        A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NOTREGISTERED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NOTREGISTERED', B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NOTREGISTERED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0,   CP_STATUS: 'NOTREGISTERED', C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-14', OWNER: 'ISCT',     MS_DESC: 'HP Steam Available',                                            PLAN: '2024-09-06', ACTUAL: null,        A_TOT: 129,   A_ACT: 111,   A_REM: 18,    A_PRO: 86, A_STATUS: 'INPROGRESS',    AP_TOT: 7,   AP_ACT: 3,   AP_REM: 4,   AP_PRO: 42,  AP_STATUS: 'INPROGRESS',    BP_TOT: 53,  BP_ACT: 14,  BP_REM: 39,  BP_PRO: 26, BP_STATUS: 'INPROGRESS',    B_TOT: 47,    B_ACT: 37,   B_REM: 10,    B_PRO: 78,  B_STATUS: 'INPROGRESS',    CP_TOT: 11,  CP_ACT: 4,   CP_REM: 7,   CP_PRO: 36,  CP_STATUS: 'INPROGRESS',    C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-25', OWNER: 'SUT',      MS_DESC: 'Makeup & Recycle Gas Compressor S/U (DHU)',                     PLAN: '2024-12-28', ACTUAL: null,        A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NOTREGISTERED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NOTREGISTERED', B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NOTREGISTERED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0,   CP_STATUS: 'NOTREGISTERED', C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-34', OWNER: 'SUT',      MS_DESC: 'Recycle Gas Compressor S/U (CCFU)',                             PLAN: '2025-01-21', ACTUAL: null,        A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NOTREGISTERED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NOTREGISTERED', B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NOTREGISTERED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0,   CP_STATUS: 'NOTREGISTERED', C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-5',  OWNER: 'SUT',      MS_DESC: 'Last DHU S1 Reactor Loaded',                                    PLAN: '2024-06-27', ACTUAL: null,        A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NOTREGISTERED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NOTREGISTERED', B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NOTREGISTERED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0,   CP_STATUS: 'NOTREGISTERED', C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-12', OWNER: 'ISCT',     MS_DESC: 'N2 in Service',                                                 PLAN: '2024-06-10', ACTUAL: null,        A_TOT: 126,   A_ACT: 101,   A_REM: 25,    A_PRO: 80, A_STATUS: 'INPROGRESS',    AP_TOT: 3,   AP_ACT: 1,   AP_REM: 2,   AP_PRO: 33,  AP_STATUS: 'INPROGRESS',    BP_TOT: 21,  BP_ACT: 10,  BP_REM: 11,  BP_PRO: 47, BP_STATUS: 'INPROGRESS',    B_TOT: 57,    B_ACT: 49,   B_REM: 8,     B_PRO: 86,  B_STATUS: 'INPROGRESS',    CP_TOT: 23,  CP_ACT: 21,  CP_REM: 2,   CP_PRO: 91,  CP_STATUS: 'INPROGRESS',    C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-26', OWNER: 'SUT',      MS_DESC: 'DAU S/U',                                                       PLAN: '2024-11-15', ACTUAL: null,        A_TOT: 7536,  A_ACT: 2506,  A_REM: 5030,  A_PRO: 33, A_STATUS: 'INPROGRESS',    AP_TOT: 181, AP_ACT: 19,  AP_REM: 162, AP_PRO: 10,  AP_STATUS: 'INPROGRESS',    BP_TOT: 165, BP_ACT: 68,  BP_REM: 97,  BP_PRO: 41, BP_STATUS: 'INPROGRESS',    B_TOT: 2635,  B_ACT: 790,  B_REM: 1845,  B_PRO: 30,  B_STATUS: 'INPROGRESS',    CP_TOT: 257, CP_ACT: 134, CP_REM: 123, CP_PRO: 52,  CP_STATUS: 'INPROGRESS',    C_TOT: 59,   C_ACT: 3, C_REM: 56, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-32', OWNER: 'SUT',      MS_DESC: 'ES EHC 50/120 Lubes System Cleaning Complete',                  PLAN: '2025-01-12', ACTUAL: null,        A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NOTREGISTERED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NOTREGISTERED', B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NOTREGISTERED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0,   CP_STATUS: 'NOTREGISTERED', C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-37', OWNER: 'SUT',      MS_DESC: 'CCFU & Solid Separation S/U',                                   PLAN: '2025-01-12', ACTUAL: null,        A_TOT: 488,   A_ACT: 140,   A_REM: 348,   A_PRO: 28, A_STATUS: 'INPROGRESS',    AP_TOT: 30,  AP_ACT: 19,  AP_REM: 11,  AP_PRO: 63,  AP_STATUS: 'INPROGRESS',    BP_TOT: 36,  BP_ACT: 3,   BP_REM: 33,  BP_PRO: 8,  BP_STATUS: 'INPROGRESS',    B_TOT: 168,   B_ACT: 40,   B_REM: 128,   B_PRO: 23,  B_STATUS: 'INPROGRESS',    CP_TOT: 3,   CP_ACT: 2,   CP_REM: 1,   CP_PRO: 66,  CP_STATUS: 'INPROGRESS',    C_TOT: 38,   C_ACT: 0, C_REM: 38, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-9',  OWNER: 'ISCT',     MS_DESC: 'Permanent Instrument Air in Service',                           PLAN: '2024-09-07', ACTUAL: null,        A_TOT: 100,   A_ACT: 85,    A_REM: 15,    A_PRO: 85, A_STATUS: 'INPROGRESS',    AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100, AP_STATUS: 'COMPLETED',     BP_TOT: 6,   BP_ACT: 0,   BP_REM: 6,   BP_PRO: 0,  BP_STATUS: 'NOTSTARTED',    B_TOT: 37,    B_ACT: 37,   B_REM: 0,     B_PRO: 100, B_STATUS: 'COMPLETED',     CP_TOT: 8,   CP_ACT: 6,   CP_REM: 2,   CP_PRO: 75,  CP_STATUS: 'INPROGRESS',    C_TOT: 0,    C_ACT: 0, C_REM: 0, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-11', OWNER: 'SUT',      MS_DESC: 'Natural Gas Introduction',                                      PLAN: '2024-09-24', ACTUAL: null,        A_TOT: 29,    A_ACT: 15,    A_REM: 14,    A_PRO: 51, A_STATUS: 'INPROGRESS',    AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100, AP_STATUS: 'COMPLETED',     BP_TOT: 9,   BP_ACT: 8,   BP_REM: 1,   BP_PRO: 88, BP_STATUS: 'INPROGRESS',    B_TOT: 9,     B_ACT: 5,    B_REM: 4,     B_PRO: 55,  B_STATUS: 'INPROGRESS',    CP_TOT: 5,   CP_ACT: 3,   CP_REM: 2,   CP_PRO: 60,  CP_STATUS: 'INPROGRESS',    C_TOT: 2,    C_ACT: 0, C_REM: 2, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-18', OWNER: 'SUT',      MS_DESC: 'SRU on Hot Standby',                                            PLAN: '2024-10-06', ACTUAL: null,        A_TOT: 628,   A_ACT: 308,   A_REM: 320,   A_PRO: 49, A_STATUS: 'INPROGRESS',    AP_TOT: 23,  AP_ACT: 15,  AP_REM: 8,   AP_PRO: 65,  AP_STATUS: 'INPROGRESS',    BP_TOT: 110, BP_ACT: 11,  BP_REM: 99,  BP_PRO: 10, BP_STATUS: 'INPROGRESS',    B_TOT: 216,   B_ACT: 93,   B_REM: 123,   B_PRO: 43,  B_STATUS: 'INPROGRESS',    CP_TOT: 39,  CP_ACT: 8,   CP_REM: 31,  CP_PRO: 20,  CP_STATUS: 'INPROGRESS',    C_TOT: 19,   C_ACT: 0, C_REM: 19, },
{ MS_TYPE: 'CSU_MS',   MS_NO: 'MS-19', OWNER: 'ISCT',     MS_DESC: 'ARU Degreasing',                                                PLAN: '2024-11-23', ACTUAL: null,        A_TOT: 0,     A_ACT: 0,     A_REM: 0,     A_PRO: 0,  A_STATUS: 'NOTREGISTERED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,   AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,  BP_STATUS: 'NOTREGISTERED', B_TOT: 0,     B_ACT: 0,    B_REM: 0,     B_PRO: 0,   B_STATUS: 'NOTREGISTERED', CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0,   CP_STATUS: 'NOTREGISTERED', C_TOT: 0,    C_ACT: 0, C_REM: 0, },

  ],

  Queries: {

    SQL1: [ // Timeline
      {
        X:                      40,
        Y:                      700,
        LENGTH:                 1200,
        CUTOFF:                 '2023-11-25',
        START:                  '2023-06-15', 
        END:                    '2025-05-15',
        REF_DATE:               'PLAN', // <---
        FONT:                   'roboto',

        MONTH_HEIGHT:           20,
        MONTH_LINE:             15,
        MONTH_STROKE_WIDTH:     0.5,
        MONTH_STROKE_COLOR:     '#BCBCBC',
        MONTH_TEXT_SIZE:        10,
        MONTH_TEXT_COLOR:       '#333',

        YEAR_HEIGHT:            16,
        YEAR_LINE:              20,
        YEAR_STROKE_WIDTH:      0.5,
        YEAR_STROKE_COLOR:      '#BCBCBC',
        YEAR_TEXT_SIZE:         11,
        YEAR_TEXT_COLOR:        '#000',
      }
    ],


    // Skyline
    SQL2: [ 
      {
        TITLE_MAIN:             'Skyline Chart',
        TITLE_SUB:              'Milestone (Waypoint & CSU)',
        REF_COLUMN:             'MS_NO', // <---

        BOX_FONT:               'roboto',
        BOX_HEIGHT:             20,
        BOX_GAP:                2, 
        BOX_RADIUS:             2,
        BOX_TEXT_SIZE:          10,
        BOX_HOVER_SCALE:        1.4,

        // Legends 
        LEGEND_VISIBLE:         'Y',
        LEGEND_X:               45,
        LEGEND_Y:               95,  

        // Search
        SEARCH_X:               45,
        SEARCH_Y:               240.5,
        SEARCH_VISIBLE:         'Y',
        SEARCH_NOTE:            'Enter the Milestone number',
      }
    ],


    // Checksheet
    SQL3: [ 
      {
        SHEET_PREFIX:             'A', // <---
        PUNCH_PREFIX:             'AP', // <---

        // Package
        PKG_VISIBLE:              'Y',
        PKG_REF_COLUMN:           'MS_TYPE', // <---
        PKG_NAME:                 ' All / Waypoint / CSU    ',
        PKG_STATUS:               ' ALL / WAYPOINT / CSU_MS ',

        // CHeck Sheet
        SHEET_TITLE:              ' Total   / Not Registered / Not Started / In Progress / Turn-Over   / Completed ',
        SHEET_STATUS:             ' TOTAL   / NOTREGISTERED  / NOTSTARTED  / INPROGRESS  / ACTUAL      / COMPLETED ',
        SHEET_TYPE:               '         /                /             / PRO         / LAYER       /           ',
        SHEET_COLOR:              ' #fff    / #fff           / #FFEEF3     / #FEED57     / transparent / #82B22E   ',
        SHEET_FONT_WEIGHT:        ' regular / regular        / regular     / regular     / regular     / regular   ',
        SHEET_FONT_COLOR:         ' #000    / #000           / #000        / #000        / #000        / #000      ',
        SHEET_STROKE_WIDTH:       ' 0.5     / 0.5            / 0.5         / 0.5         / 1.5         / 0.5       ',
        SHEET_STROKE_COLOR:       ' #757575 / #757575        / #757575     / #757575     / #F35E90     / #757575   ',
        SHEET_OPACITY:            ' 1       / 1              / 1           / 1           / 1           / 1         ',

        // Punch
        PUNCH_NAME:               ' None / Created / Not Started / Opened / Opened < 10 / Cleared 100% ',
        PUNCH_STATUS:             ' NONE / CREATE  / NOTSTARTED  / OPENED / REM-10      / CLEARED      ',
      }
    ],

    
    SQL4: [ // Detail infomation Box
      {
        DETAIL_VISIBLE:           'Y',
        DETAIL_ONOFF:             'ON',
        DETAIL_X:                 45,
        DETAIL_Y:                 330,
        DETAIL_WIDTH:             300,
        DETAIL_TEXT_SIZE:         10,
        DETAIL_LINEHEIGHT:        18,
        
        DETAIL_CODE:              ' MS_TYPE    / PLAN         / MS_DESC      / A         / B         / AP        / BP       ',
        DETAIL_NAME:              ' Milestone: / Plan:        / Description: / A-Sheet:  / B-Sheet:  / Punch-A:  / Punch-B: ',
        DETAIL_TYPE:              ' TEXT       / TEXT         / TRIM         / BAR       / BAR       / BAR       / BAR      ',
        DETAIL_VALUE_COLOR:       ' #757575    / #757575      / #757575      / #83D2F5   / #83D2F5   / #F7BACF   / #F7BACF  ',
        DETAIL_BAR_COLOR:         ' #44A9DF    / gray         / gray         / #83D2F5   / #83D2F5   / #F7BACF   / #F7BACF  ',
        DETAIL_TITLE_WIDTH:       50,
        DETAIL_TITLE_COLOR:       'gray',
      }
    ],




    
  }
}