
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 1030,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  


  DataItems: [
    { SCOPE: 'Existing Site', SSMCC_TOT: 1276, SSMCC_PLN: 576, SSMCC_WD: 596, SSMCC_ACT: 618, SSMCC_REM: 658, SSMCC_PRO: 48.4, A_TOT: 9943,   A_ACT: 6653,  A_REM: 3290,  A_PRO: 66.9, AP_TOT: 481,  AP_ACT: 362,  AP_REM: 119,  AP_PRO: 75.3, B_TOT: 4289,  B_ACT: 1979,  B_REM: 2310,  B_PRO: 46.1, BP_TOT: 2357, BP_ACT: 1379, BP_REM: 978,  BP_PRO: 58.5, C_TOT: 599,  C_ACT: 18, C_REM: 581,  C_PRO: 3, CP_TOT: 2451, CP_ACT: 2057, CP_REM: 394,  CP_PRO: 83.9, },
    { SCOPE: 'Grassroots',    SSMCC_TOT: 696,  SSMCC_PLN: 19,  SSMCC_WD: 41,  SSMCC_ACT: 31,  SSMCC_REM: 665, SSMCC_PRO: 4.5,  A_TOT: 115146, A_ACT: 33074, A_REM: 82072, A_PRO: 28.7, AP_TOT: 7463, AP_ACT: 4149, AP_REM: 3314, AP_PRO: 55.6, B_TOT: 36041, B_ACT: 11207, B_REM: 24834, B_PRO: 31.1, BP_TOT: 4250, BP_ACT: 2778, BP_REM: 1472, BP_PRO: 65.4, C_TOT: 1483, C_ACT: 0,  C_REM: 1483, C_PRO: 0, CP_TOT: 3094, CP_ACT: 1977, CP_REM: 1117, CP_PRO: 63.9, },
  ],

  Queries: {
    SQL1: [
      { SCOPE: 'Existing Site', ITEM: 'A',  UNIT: 'OV', CDATE: '2023-10-27', CREATED_WK: 137,  CLOSED_WK: 263, CREATED_CUM: 8600,   CLOSED_CUM: 4627,  REMAIN_CUM: 5316,  TOTAL: 9943,   TO_PLAN_WK: 3,  TO_ACT_WK: 13, TO_PLAN_CUM: 467, TO_ACT_CUM: 498, TO_REM_CUM: 424, },
      { SCOPE: 'Existing Site', ITEM: 'A',  UNIT: 'OV', CDATE: '2023-11-03', CREATED_WK: 86,   CLOSED_WK: 153, CREATED_CUM: 8686,   CLOSED_CUM: 4780,  REMAIN_CUM: 5163,  TOTAL: 9943,   TO_PLAN_WK: 7,  TO_ACT_WK: 9,  TO_PLAN_CUM: 474, TO_ACT_CUM: 507, TO_REM_CUM: 415, },
      { SCOPE: 'Existing Site', ITEM: 'A',  UNIT: 'OV', CDATE: '2023-11-10', CREATED_WK: 85,   CLOSED_WK: 190, CREATED_CUM: 8771,   CLOSED_CUM: 4970,  REMAIN_CUM: 4973,  TOTAL: 9943,   TO_PLAN_WK: 4,  TO_ACT_WK: 1,  TO_PLAN_CUM: 478, TO_ACT_CUM: 508, TO_REM_CUM: 414, },
      { SCOPE: 'Existing Site', ITEM: 'A',  UNIT: 'OV', CDATE: '2023-11-17', CREATED_WK: 90,   CLOSED_WK: 180, CREATED_CUM: 8861,   CLOSED_CUM: 5150,  REMAIN_CUM: 4793,  TOTAL: 9943,   TO_PLAN_WK: 5,  TO_ACT_WK: 7,  TO_PLAN_CUM: 483, TO_ACT_CUM: 515, TO_REM_CUM: 407, },
      { SCOPE: 'Existing Site', ITEM: 'A',  UNIT: 'OV', CDATE: '2023-11-24', CREATED_WK: 118,  CLOSED_WK: 286, CREATED_CUM: 8979,   CLOSED_CUM: 5436,  REMAIN_CUM: 4507,  TOTAL: 9943,   TO_PLAN_WK: 2,  TO_ACT_WK: 12, TO_PLAN_CUM: 485, TO_ACT_CUM: 527, TO_REM_CUM: 395, },
      { SCOPE: 'Existing Site', ITEM: 'A',  UNIT: 'OV', CDATE: '2023-12-01', CREATED_WK: 493,  CLOSED_WK: 336, CREATED_CUM: 9472,   CLOSED_CUM: 5772,  REMAIN_CUM: 4171,  TOTAL: 9943,   TO_PLAN_WK: 9,  TO_ACT_WK: 8,  TO_PLAN_CUM: 494, TO_ACT_CUM: 535, TO_REM_CUM: 387, },
      { SCOPE: 'Existing Site', ITEM: 'A',  UNIT: 'OV', CDATE: '2023-12-08', CREATED_WK: 143,  CLOSED_WK: 98,  CREATED_CUM: 9615,   CLOSED_CUM: 5870,  REMAIN_CUM: 4073,  TOTAL: 9943,   TO_PLAN_WK: 16, TO_ACT_WK: 19, TO_PLAN_CUM: 510, TO_ACT_CUM: 554, TO_REM_CUM: 368, },
      { SCOPE: 'Existing Site', ITEM: 'A',  UNIT: 'OV', CDATE: '2023-12-15', CREATED_WK: 123,  CLOSED_WK: 273, CREATED_CUM: 9738,   CLOSED_CUM: 6143,  REMAIN_CUM: 3800,  TOTAL: 9943,   TO_PLAN_WK: 27, TO_ACT_WK: 30, TO_PLAN_CUM: 537, TO_ACT_CUM: 584, TO_REM_CUM: 338, },
      { SCOPE: 'Existing Site', ITEM: 'A',  UNIT: 'OV', CDATE: '2023-12-22', CREATED_WK: 44,   CLOSED_WK: 150, CREATED_CUM: 9782,   CLOSED_CUM: 6293,  REMAIN_CUM: 3650,  TOTAL: 9943,   TO_PLAN_WK: 4,  TO_ACT_WK: 29, TO_PLAN_CUM: 541, TO_ACT_CUM: 613, TO_REM_CUM: 309, },
      { SCOPE: 'Existing Site', ITEM: 'A',  UNIT: 'OV', CDATE: '2023-12-29', CREATED_WK: 2,    CLOSED_WK: 40,  CREATED_CUM: 9784,   CLOSED_CUM: 6333,  REMAIN_CUM: 3610,  TOTAL: 9943,   TO_PLAN_WK: 21, TO_ACT_WK: 0,  TO_PLAN_CUM: 562, TO_ACT_CUM: 613, TO_REM_CUM: 309, },
      { SCOPE: 'Existing Site', ITEM: 'A',  UNIT: 'OV', CDATE: '2024-01-05', CREATED_WK: 144,  CLOSED_WK: 204, CREATED_CUM: 9928,   CLOSED_CUM: 6537,  REMAIN_CUM: 3406,  TOTAL: 9943,   TO_PLAN_WK: 5,  TO_ACT_WK: 1,  TO_PLAN_CUM: 567, TO_ACT_CUM: 614, TO_REM_CUM: 308, },
      { SCOPE: 'Existing Site', ITEM: 'A',  UNIT: 'OV', CDATE: '2024-01-12', CREATED_WK: 15,   CLOSED_WK: 116, CREATED_CUM: 9943,   CLOSED_CUM: 6653,  REMAIN_CUM: 3290,  TOTAL: 9943,   TO_PLAN_WK: 11, TO_ACT_WK: 4,  TO_PLAN_CUM: 578, TO_ACT_CUM: 618, TO_REM_CUM: 304, },
      { SCOPE: 'Existing Site', ITEM: 'AP', UNIT: 'OV', CDATE: '2023-10-27', CREATED_WK: 178,  CLOSED_WK: 10,  CREATED_CUM: 439,    CLOSED_CUM: 253,   REMAIN_CUM: 228,   TOTAL: 481,    TO_PLAN_WK: 3,  TO_ACT_WK: 13, TO_PLAN_CUM: 467, TO_ACT_CUM: 498, TO_REM_CUM: 424, },
      { SCOPE: 'Existing Site', ITEM: 'AP', UNIT: 'OV', CDATE: '2023-11-03', CREATED_WK: 1,    CLOSED_WK: 10,  CREATED_CUM: 440,    CLOSED_CUM: 263,   REMAIN_CUM: 218,   TOTAL: 481,    TO_PLAN_WK: 7,  TO_ACT_WK: 9,  TO_PLAN_CUM: 474, TO_ACT_CUM: 507, TO_REM_CUM: 415, },
      { SCOPE: 'Existing Site', ITEM: 'AP', UNIT: 'OV', CDATE: '2023-11-10', CREATED_WK: 3,    CLOSED_WK: 5,   CREATED_CUM: 443,    CLOSED_CUM: 268,   REMAIN_CUM: 213,   TOTAL: 481,    TO_PLAN_WK: 4,  TO_ACT_WK: 1,  TO_PLAN_CUM: 478, TO_ACT_CUM: 508, TO_REM_CUM: 414, },
      { SCOPE: 'Existing Site', ITEM: 'AP', UNIT: 'OV', CDATE: '2023-11-17', CREATED_WK: 7,    CLOSED_WK: 12,  CREATED_CUM: 450,    CLOSED_CUM: 280,   REMAIN_CUM: 201,   TOTAL: 481,    TO_PLAN_WK: 5,  TO_ACT_WK: 7,  TO_PLAN_CUM: 483, TO_ACT_CUM: 515, TO_REM_CUM: 407, },
      { SCOPE: 'Existing Site', ITEM: 'AP', UNIT: 'OV', CDATE: '2023-11-24', CREATED_WK: 2,    CLOSED_WK: 14,  CREATED_CUM: 452,    CLOSED_CUM: 294,   REMAIN_CUM: 187,   TOTAL: 481,    TO_PLAN_WK: 2,  TO_ACT_WK: 12, TO_PLAN_CUM: 485, TO_ACT_CUM: 527, TO_REM_CUM: 395, },
      { SCOPE: 'Existing Site', ITEM: 'AP', UNIT: 'OV', CDATE: '2023-12-01', CREATED_WK: 6,    CLOSED_WK: 9,   CREATED_CUM: 458,    CLOSED_CUM: 303,   REMAIN_CUM: 178,   TOTAL: 481,    TO_PLAN_WK: 9,  TO_ACT_WK: 8,  TO_PLAN_CUM: 494, TO_ACT_CUM: 535, TO_REM_CUM: 387, },
      { SCOPE: 'Existing Site', ITEM: 'AP', UNIT: 'OV', CDATE: '2023-12-08', CREATED_WK: 9,    CLOSED_WK: 6,   CREATED_CUM: 467,    CLOSED_CUM: 309,   REMAIN_CUM: 172,   TOTAL: 481,    TO_PLAN_WK: 16, TO_ACT_WK: 19, TO_PLAN_CUM: 510, TO_ACT_CUM: 554, TO_REM_CUM: 368, },
      { SCOPE: 'Existing Site', ITEM: 'AP', UNIT: 'OV', CDATE: '2023-12-15', CREATED_WK: 7,    CLOSED_WK: 23,  CREATED_CUM: 474,    CLOSED_CUM: 332,   REMAIN_CUM: 149,   TOTAL: 481,    TO_PLAN_WK: 27, TO_ACT_WK: 30, TO_PLAN_CUM: 537, TO_ACT_CUM: 584, TO_REM_CUM: 338, },
      { SCOPE: 'Existing Site', ITEM: 'AP', UNIT: 'OV', CDATE: '2023-12-22', CREATED_WK: 1,    CLOSED_WK: 7,   CREATED_CUM: 475,    CLOSED_CUM: 339,   REMAIN_CUM: 142,   TOTAL: 481,    TO_PLAN_WK: 4,  TO_ACT_WK: 29, TO_PLAN_CUM: 541, TO_ACT_CUM: 613, TO_REM_CUM: 309, },
      { SCOPE: 'Existing Site', ITEM: 'AP', UNIT: 'OV', CDATE: '2023-12-29', CREATED_WK: 1,    CLOSED_WK: 6,   CREATED_CUM: 476,    CLOSED_CUM: 345,   REMAIN_CUM: 136,   TOTAL: 481,    TO_PLAN_WK: 21, TO_ACT_WK: 0,  TO_PLAN_CUM: 562, TO_ACT_CUM: 613, TO_REM_CUM: 309, },
      { SCOPE: 'Existing Site', ITEM: 'AP', UNIT: 'OV', CDATE: '2024-01-05', CREATED_WK: 1,    CLOSED_WK: 4,   CREATED_CUM: 477,    CLOSED_CUM: 349,   REMAIN_CUM: 132,   TOTAL: 481,    TO_PLAN_WK: 5,  TO_ACT_WK: 1,  TO_PLAN_CUM: 567, TO_ACT_CUM: 614, TO_REM_CUM: 308, },
      { SCOPE: 'Existing Site', ITEM: 'AP', UNIT: 'OV', CDATE: '2024-01-12', CREATED_WK: 4,    CLOSED_WK: 13,  CREATED_CUM: 481,    CLOSED_CUM: 362,   REMAIN_CUM: 119,   TOTAL: 481,    TO_PLAN_WK: 11, TO_ACT_WK: 4,  TO_PLAN_CUM: 578, TO_ACT_CUM: 618, TO_REM_CUM: 304, },
      { SCOPE: 'Existing Site', ITEM: 'B',  UNIT: 'OV', CDATE: '2023-10-27', CREATED_WK: 6,    CLOSED_WK: 55,  CREATED_CUM: 3084,   CLOSED_CUM: 1395,  REMAIN_CUM: 2894,  TOTAL: 4289,   TO_PLAN_WK: 3,  TO_ACT_WK: 13, TO_PLAN_CUM: 467, TO_ACT_CUM: 498, TO_REM_CUM: 424, },
      { SCOPE: 'Existing Site', ITEM: 'B',  UNIT: 'OV', CDATE: '2023-11-03', CREATED_WK: 0,    CLOSED_WK: 43,  CREATED_CUM: 3084,   CLOSED_CUM: 1438,  REMAIN_CUM: 2851,  TOTAL: 4289,   TO_PLAN_WK: 7,  TO_ACT_WK: 9,  TO_PLAN_CUM: 474, TO_ACT_CUM: 507, TO_REM_CUM: 415, },
      { SCOPE: 'Existing Site', ITEM: 'B',  UNIT: 'OV', CDATE: '2023-11-10', CREATED_WK: 98,   CLOSED_WK: 63,  CREATED_CUM: 3182,   CLOSED_CUM: 1501,  REMAIN_CUM: 2788,  TOTAL: 4289,   TO_PLAN_WK: 4,  TO_ACT_WK: 1,  TO_PLAN_CUM: 478, TO_ACT_CUM: 508, TO_REM_CUM: 414, },
      { SCOPE: 'Existing Site', ITEM: 'B',  UNIT: 'OV', CDATE: '2023-11-17', CREATED_WK: 2,    CLOSED_WK: 54,  CREATED_CUM: 3184,   CLOSED_CUM: 1555,  REMAIN_CUM: 2734,  TOTAL: 4289,   TO_PLAN_WK: 5,  TO_ACT_WK: 7,  TO_PLAN_CUM: 483, TO_ACT_CUM: 515, TO_REM_CUM: 407, },
      { SCOPE: 'Existing Site', ITEM: 'B',  UNIT: 'OV', CDATE: '2023-11-24', CREATED_WK: 17,   CLOSED_WK: 60,  CREATED_CUM: 3201,   CLOSED_CUM: 1615,  REMAIN_CUM: 2674,  TOTAL: 4289,   TO_PLAN_WK: 2,  TO_ACT_WK: 12, TO_PLAN_CUM: 485, TO_ACT_CUM: 527, TO_REM_CUM: 395, },
      { SCOPE: 'Existing Site', ITEM: 'B',  UNIT: 'OV', CDATE: '2023-12-01', CREATED_WK: 48,   CLOSED_WK: 103, CREATED_CUM: 3249,   CLOSED_CUM: 1718,  REMAIN_CUM: 2571,  TOTAL: 4289,   TO_PLAN_WK: 9,  TO_ACT_WK: 8,  TO_PLAN_CUM: 494, TO_ACT_CUM: 535, TO_REM_CUM: 387, },
      { SCOPE: 'Existing Site', ITEM: 'B',  UNIT: 'OV', CDATE: '2023-12-08', CREATED_WK: 3,    CLOSED_WK: 27,  CREATED_CUM: 3252,   CLOSED_CUM: 1745,  REMAIN_CUM: 2544,  TOTAL: 4289,   TO_PLAN_WK: 16, TO_ACT_WK: 19, TO_PLAN_CUM: 510, TO_ACT_CUM: 554, TO_REM_CUM: 368, },
      { SCOPE: 'Existing Site', ITEM: 'B',  UNIT: 'OV', CDATE: '2023-12-15', CREATED_WK: 137,  CLOSED_WK: 76,  CREATED_CUM: 3389,   CLOSED_CUM: 1821,  REMAIN_CUM: 2468,  TOTAL: 4289,   TO_PLAN_WK: 27, TO_ACT_WK: 30, TO_PLAN_CUM: 537, TO_ACT_CUM: 584, TO_REM_CUM: 338, },
      { SCOPE: 'Existing Site', ITEM: 'B',  UNIT: 'OV', CDATE: '2023-12-22', CREATED_WK: 1,    CLOSED_WK: 48,  CREATED_CUM: 3390,   CLOSED_CUM: 1869,  REMAIN_CUM: 2420,  TOTAL: 4289,   TO_PLAN_WK: 4,  TO_ACT_WK: 29, TO_PLAN_CUM: 541, TO_ACT_CUM: 613, TO_REM_CUM: 309, },
      { SCOPE: 'Existing Site', ITEM: 'B',  UNIT: 'OV', CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 10,  CREATED_CUM: 3390,   CLOSED_CUM: 1879,  REMAIN_CUM: 2410,  TOTAL: 4289,   TO_PLAN_WK: 21, TO_ACT_WK: 0,  TO_PLAN_CUM: 562, TO_ACT_CUM: 613, TO_REM_CUM: 309, },
      { SCOPE: 'Existing Site', ITEM: 'B',  UNIT: 'OV', CDATE: '2024-01-05', CREATED_WK: 38,   CLOSED_WK: 62,  CREATED_CUM: 3428,   CLOSED_CUM: 1941,  REMAIN_CUM: 2348,  TOTAL: 4289,   TO_PLAN_WK: 5,  TO_ACT_WK: 1,  TO_PLAN_CUM: 567, TO_ACT_CUM: 614, TO_REM_CUM: 308, },
      { SCOPE: 'Existing Site', ITEM: 'B',  UNIT: 'OV', CDATE: '2024-01-12', CREATED_WK: 861,  CLOSED_WK: 38,  CREATED_CUM: 4289,   CLOSED_CUM: 1979,  REMAIN_CUM: 2310,  TOTAL: 4289,   TO_PLAN_WK: 11, TO_ACT_WK: 4,  TO_PLAN_CUM: 578, TO_ACT_CUM: 618, TO_REM_CUM: 304, },
      { SCOPE: 'Existing Site', ITEM: 'BP', UNIT: 'OV', CDATE: '2023-10-27', CREATED_WK: 303,  CLOSED_WK: 50,  CREATED_CUM: 1253,   CLOSED_CUM: 720,   REMAIN_CUM: 1637,  TOTAL: 2357,   TO_PLAN_WK: 3,  TO_ACT_WK: 13, TO_PLAN_CUM: 467, TO_ACT_CUM: 498, TO_REM_CUM: 424, },
      { SCOPE: 'Existing Site', ITEM: 'BP', UNIT: 'OV', CDATE: '2023-11-03', CREATED_WK: 45,   CLOSED_WK: 89,  CREATED_CUM: 1298,   CLOSED_CUM: 809,   REMAIN_CUM: 1548,  TOTAL: 2357,   TO_PLAN_WK: 7,  TO_ACT_WK: 9,  TO_PLAN_CUM: 474, TO_ACT_CUM: 507, TO_REM_CUM: 415, },
      { SCOPE: 'Existing Site', ITEM: 'BP', UNIT: 'OV', CDATE: '2023-11-10', CREATED_WK: 100,  CLOSED_WK: 34,  CREATED_CUM: 1398,   CLOSED_CUM: 843,   REMAIN_CUM: 1514,  TOTAL: 2357,   TO_PLAN_WK: 4,  TO_ACT_WK: 1,  TO_PLAN_CUM: 478, TO_ACT_CUM: 508, TO_REM_CUM: 414, },
      { SCOPE: 'Existing Site', ITEM: 'BP', UNIT: 'OV', CDATE: '2023-11-17', CREATED_WK: 91,   CLOSED_WK: 36,  CREATED_CUM: 1489,   CLOSED_CUM: 879,   REMAIN_CUM: 1478,  TOTAL: 2357,   TO_PLAN_WK: 5,  TO_ACT_WK: 7,  TO_PLAN_CUM: 483, TO_ACT_CUM: 515, TO_REM_CUM: 407, },
      { SCOPE: 'Existing Site', ITEM: 'BP', UNIT: 'OV', CDATE: '2023-11-24', CREATED_WK: 67,   CLOSED_WK: 39,  CREATED_CUM: 1556,   CLOSED_CUM: 918,   REMAIN_CUM: 1439,  TOTAL: 2357,   TO_PLAN_WK: 2,  TO_ACT_WK: 12, TO_PLAN_CUM: 485, TO_ACT_CUM: 527, TO_REM_CUM: 395, },
      { SCOPE: 'Existing Site', ITEM: 'BP', UNIT: 'OV', CDATE: '2023-12-01', CREATED_WK: 106,  CLOSED_WK: 21,  CREATED_CUM: 1662,   CLOSED_CUM: 939,   REMAIN_CUM: 1418,  TOTAL: 2357,   TO_PLAN_WK: 9,  TO_ACT_WK: 8,  TO_PLAN_CUM: 494, TO_ACT_CUM: 535, TO_REM_CUM: 387, },
      { SCOPE: 'Existing Site', ITEM: 'BP', UNIT: 'OV', CDATE: '2023-12-08', CREATED_WK: 131,  CLOSED_WK: 21,  CREATED_CUM: 1793,   CLOSED_CUM: 960,   REMAIN_CUM: 1397,  TOTAL: 2357,   TO_PLAN_WK: 16, TO_ACT_WK: 19, TO_PLAN_CUM: 510, TO_ACT_CUM: 554, TO_REM_CUM: 368, },
      { SCOPE: 'Existing Site', ITEM: 'BP', UNIT: 'OV', CDATE: '2023-12-15', CREATED_WK: 67,   CLOSED_WK: 44,  CREATED_CUM: 1860,   CLOSED_CUM: 1004,  REMAIN_CUM: 1353,  TOTAL: 2357,   TO_PLAN_WK: 27, TO_ACT_WK: 30, TO_PLAN_CUM: 537, TO_ACT_CUM: 584, TO_REM_CUM: 338, },
      { SCOPE: 'Existing Site', ITEM: 'BP', UNIT: 'OV', CDATE: '2023-12-22', CREATED_WK: 177,  CLOSED_WK: 141, CREATED_CUM: 2037,   CLOSED_CUM: 1145,  REMAIN_CUM: 1212,  TOTAL: 2357,   TO_PLAN_WK: 4,  TO_ACT_WK: 29, TO_PLAN_CUM: 541, TO_ACT_CUM: 613, TO_REM_CUM: 309, },
      { SCOPE: 'Existing Site', ITEM: 'BP', UNIT: 'OV', CDATE: '2023-12-29', CREATED_WK: 79,   CLOSED_WK: 84,  CREATED_CUM: 2116,   CLOSED_CUM: 1229,  REMAIN_CUM: 1128,  TOTAL: 2357,   TO_PLAN_WK: 21, TO_ACT_WK: 0,  TO_PLAN_CUM: 562, TO_ACT_CUM: 613, TO_REM_CUM: 309, },
      { SCOPE: 'Existing Site', ITEM: 'BP', UNIT: 'OV', CDATE: '2024-01-05', CREATED_WK: 90,   CLOSED_WK: 61,  CREATED_CUM: 2206,   CLOSED_CUM: 1290,  REMAIN_CUM: 1067,  TOTAL: 2357,   TO_PLAN_WK: 5,  TO_ACT_WK: 1,  TO_PLAN_CUM: 567, TO_ACT_CUM: 614, TO_REM_CUM: 308, },
      { SCOPE: 'Existing Site', ITEM: 'BP', UNIT: 'OV', CDATE: '2024-01-12', CREATED_WK: 151,  CLOSED_WK: 89,  CREATED_CUM: 2357,   CLOSED_CUM: 1379,  REMAIN_CUM: 978,   TOTAL: 2357,   TO_PLAN_WK: 11, TO_ACT_WK: 4,  TO_PLAN_CUM: 578, TO_ACT_CUM: 618, TO_REM_CUM: 304, },
      { SCOPE: 'Existing Site', ITEM: 'C',  UNIT: 'OV', CDATE: '2023-10-27', CREATED_WK: 2,    CLOSED_WK: 0,   CREATED_CUM: 509,    CLOSED_CUM: 12,    REMAIN_CUM: 587,   TOTAL: 599,    TO_PLAN_WK: 3,  TO_ACT_WK: 13, TO_PLAN_CUM: 467, TO_ACT_CUM: 498, TO_REM_CUM: 424, },
      { SCOPE: 'Existing Site', ITEM: 'C',  UNIT: 'OV', CDATE: '2023-11-03', CREATED_WK: 55,   CLOSED_WK: 0,   CREATED_CUM: 564,    CLOSED_CUM: 12,    REMAIN_CUM: 587,   TOTAL: 599,    TO_PLAN_WK: 7,  TO_ACT_WK: 9,  TO_PLAN_CUM: 474, TO_ACT_CUM: 507, TO_REM_CUM: 415, },
      { SCOPE: 'Existing Site', ITEM: 'C',  UNIT: 'OV', CDATE: '2023-11-10', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 564,    CLOSED_CUM: 12,    REMAIN_CUM: 587,   TOTAL: 599,    TO_PLAN_WK: 4,  TO_ACT_WK: 1,  TO_PLAN_CUM: 478, TO_ACT_CUM: 508, TO_REM_CUM: 414, },
      { SCOPE: 'Existing Site', ITEM: 'C',  UNIT: 'OV', CDATE: '2023-11-17', CREATED_WK: 4,    CLOSED_WK: 0,   CREATED_CUM: 568,    CLOSED_CUM: 12,    REMAIN_CUM: 587,   TOTAL: 599,    TO_PLAN_WK: 5,  TO_ACT_WK: 7,  TO_PLAN_CUM: 483, TO_ACT_CUM: 515, TO_REM_CUM: 407, },
      { SCOPE: 'Existing Site', ITEM: 'C',  UNIT: 'OV', CDATE: '2023-11-24', CREATED_WK: 0,    CLOSED_WK: 4,   CREATED_CUM: 568,    CLOSED_CUM: 16,    REMAIN_CUM: 583,   TOTAL: 599,    TO_PLAN_WK: 2,  TO_ACT_WK: 12, TO_PLAN_CUM: 485, TO_ACT_CUM: 527, TO_REM_CUM: 395, },
      { SCOPE: 'Existing Site', ITEM: 'C',  UNIT: 'OV', CDATE: '2023-12-01', CREATED_WK: 8,    CLOSED_WK: 0,   CREATED_CUM: 576,    CLOSED_CUM: 16,    REMAIN_CUM: 583,   TOTAL: 599,    TO_PLAN_WK: 9,  TO_ACT_WK: 8,  TO_PLAN_CUM: 494, TO_ACT_CUM: 535, TO_REM_CUM: 387, },
      { SCOPE: 'Existing Site', ITEM: 'C',  UNIT: 'OV', CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 2,   CREATED_CUM: 576,    CLOSED_CUM: 18,    REMAIN_CUM: 581,   TOTAL: 599,    TO_PLAN_WK: 16, TO_ACT_WK: 19, TO_PLAN_CUM: 510, TO_ACT_CUM: 554, TO_REM_CUM: 368, },
      { SCOPE: 'Existing Site', ITEM: 'C',  UNIT: 'OV', CDATE: '2023-12-15', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 576,    CLOSED_CUM: 18,    REMAIN_CUM: 581,   TOTAL: 599,    TO_PLAN_WK: 27, TO_ACT_WK: 30, TO_PLAN_CUM: 537, TO_ACT_CUM: 584, TO_REM_CUM: 338, },
      { SCOPE: 'Existing Site', ITEM: 'C',  UNIT: 'OV', CDATE: '2023-12-22', CREATED_WK: 22,   CLOSED_WK: 0,   CREATED_CUM: 598,    CLOSED_CUM: 18,    REMAIN_CUM: 581,   TOTAL: 599,    TO_PLAN_WK: 4,  TO_ACT_WK: 29, TO_PLAN_CUM: 541, TO_ACT_CUM: 613, TO_REM_CUM: 309, },
      { SCOPE: 'Existing Site', ITEM: 'C',  UNIT: 'OV', CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 598,    CLOSED_CUM: 18,    REMAIN_CUM: 581,   TOTAL: 599,    TO_PLAN_WK: 21, TO_ACT_WK: 0,  TO_PLAN_CUM: 562, TO_ACT_CUM: 613, TO_REM_CUM: 309, },
      { SCOPE: 'Existing Site', ITEM: 'C',  UNIT: 'OV', CDATE: '2024-01-05', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 598,    CLOSED_CUM: 18,    REMAIN_CUM: 581,   TOTAL: 599,    TO_PLAN_WK: 5,  TO_ACT_WK: 1,  TO_PLAN_CUM: 567, TO_ACT_CUM: 614, TO_REM_CUM: 308, },
      { SCOPE: 'Existing Site', ITEM: 'C',  UNIT: 'OV', CDATE: '2024-01-12', CREATED_WK: 1,    CLOSED_WK: 0,   CREATED_CUM: 599,    CLOSED_CUM: 18,    REMAIN_CUM: 581,   TOTAL: 599,    TO_PLAN_WK: 11, TO_ACT_WK: 4,  TO_PLAN_CUM: 578, TO_ACT_CUM: 618, TO_REM_CUM: 304, },
      { SCOPE: 'Existing Site', ITEM: 'CP', UNIT: 'OV', CDATE: '2023-10-27', CREATED_WK: 93,   CLOSED_WK: 46,  CREATED_CUM: 2013,   CLOSED_CUM: 1596,  REMAIN_CUM: 855,   TOTAL: 2451,   TO_PLAN_WK: 3,  TO_ACT_WK: 13, TO_PLAN_CUM: 467, TO_ACT_CUM: 498, TO_REM_CUM: 424, },
      { SCOPE: 'Existing Site', ITEM: 'CP', UNIT: 'OV', CDATE: '2023-11-03', CREATED_WK: 36,   CLOSED_WK: 78,  CREATED_CUM: 2049,   CLOSED_CUM: 1674,  REMAIN_CUM: 777,   TOTAL: 2451,   TO_PLAN_WK: 7,  TO_ACT_WK: 9,  TO_PLAN_CUM: 474, TO_ACT_CUM: 507, TO_REM_CUM: 415, },
      { SCOPE: 'Existing Site', ITEM: 'CP', UNIT: 'OV', CDATE: '2023-11-10', CREATED_WK: 43,   CLOSED_WK: 44,  CREATED_CUM: 2092,   CLOSED_CUM: 1718,  REMAIN_CUM: 733,   TOTAL: 2451,   TO_PLAN_WK: 4,  TO_ACT_WK: 1,  TO_PLAN_CUM: 478, TO_ACT_CUM: 508, TO_REM_CUM: 414, },
      { SCOPE: 'Existing Site', ITEM: 'CP', UNIT: 'OV', CDATE: '2023-11-17', CREATED_WK: 33,   CLOSED_WK: 28,  CREATED_CUM: 2125,   CLOSED_CUM: 1746,  REMAIN_CUM: 705,   TOTAL: 2451,   TO_PLAN_WK: 5,  TO_ACT_WK: 7,  TO_PLAN_CUM: 483, TO_ACT_CUM: 515, TO_REM_CUM: 407, },
      { SCOPE: 'Existing Site', ITEM: 'CP', UNIT: 'OV', CDATE: '2023-11-24', CREATED_WK: 27,   CLOSED_WK: 62,  CREATED_CUM: 2152,   CLOSED_CUM: 1808,  REMAIN_CUM: 643,   TOTAL: 2451,   TO_PLAN_WK: 2,  TO_ACT_WK: 12, TO_PLAN_CUM: 485, TO_ACT_CUM: 527, TO_REM_CUM: 395, },
      { SCOPE: 'Existing Site', ITEM: 'CP', UNIT: 'OV', CDATE: '2023-12-01', CREATED_WK: 65,   CLOSED_WK: 41,  CREATED_CUM: 2217,   CLOSED_CUM: 1849,  REMAIN_CUM: 602,   TOTAL: 2451,   TO_PLAN_WK: 9,  TO_ACT_WK: 8,  TO_PLAN_CUM: 494, TO_ACT_CUM: 535, TO_REM_CUM: 387, },
      { SCOPE: 'Existing Site', ITEM: 'CP', UNIT: 'OV', CDATE: '2023-12-08', CREATED_WK: 46,   CLOSED_WK: 20,  CREATED_CUM: 2263,   CLOSED_CUM: 1869,  REMAIN_CUM: 582,   TOTAL: 2451,   TO_PLAN_WK: 16, TO_ACT_WK: 19, TO_PLAN_CUM: 510, TO_ACT_CUM: 554, TO_REM_CUM: 368, },
      { SCOPE: 'Existing Site', ITEM: 'CP', UNIT: 'OV', CDATE: '2023-12-15', CREATED_WK: 35,   CLOSED_WK: 44,  CREATED_CUM: 2298,   CLOSED_CUM: 1913,  REMAIN_CUM: 538,   TOTAL: 2451,   TO_PLAN_WK: 27, TO_ACT_WK: 30, TO_PLAN_CUM: 537, TO_ACT_CUM: 584, TO_REM_CUM: 338, },
      { SCOPE: 'Existing Site', ITEM: 'CP', UNIT: 'OV', CDATE: '2023-12-22', CREATED_WK: 44,   CLOSED_WK: 51,  CREATED_CUM: 2342,   CLOSED_CUM: 1964,  REMAIN_CUM: 487,   TOTAL: 2451,   TO_PLAN_WK: 4,  TO_ACT_WK: 29, TO_PLAN_CUM: 541, TO_ACT_CUM: 613, TO_REM_CUM: 309, },
      { SCOPE: 'Existing Site', ITEM: 'CP', UNIT: 'OV', CDATE: '2023-12-29', CREATED_WK: 9,    CLOSED_WK: 27,  CREATED_CUM: 2351,   CLOSED_CUM: 1991,  REMAIN_CUM: 460,   TOTAL: 2451,   TO_PLAN_WK: 21, TO_ACT_WK: 0,  TO_PLAN_CUM: 562, TO_ACT_CUM: 613, TO_REM_CUM: 309, },
      { SCOPE: 'Existing Site', ITEM: 'CP', UNIT: 'OV', CDATE: '2024-01-05', CREATED_WK: 12,   CLOSED_WK: 22,  CREATED_CUM: 2363,   CLOSED_CUM: 2013,  REMAIN_CUM: 438,   TOTAL: 2451,   TO_PLAN_WK: 5,  TO_ACT_WK: 1,  TO_PLAN_CUM: 567, TO_ACT_CUM: 614, TO_REM_CUM: 308, },
      { SCOPE: 'Existing Site', ITEM: 'CP', UNIT: 'OV', CDATE: '2024-01-12', CREATED_WK: 88,   CLOSED_WK: 44,  CREATED_CUM: 2451,   CLOSED_CUM: 2057,  REMAIN_CUM: 394,   TOTAL: 2451,   TO_PLAN_WK: 11, TO_ACT_WK: 4,  TO_PLAN_CUM: 578, TO_ACT_CUM: 618, TO_REM_CUM: 304, },
      { SCOPE: 'Grassroots',    ITEM: 'A',  UNIT: 'OV', CDATE: '2023-10-27', CREATED_WK: 555,  CLOSED_WK: 549, CREATED_CUM: 105412, CLOSED_CUM: 26199, REMAIN_CUM: 88947, TOTAL: 115146, TO_PLAN_WK: 0,  TO_ACT_WK: 0,  TO_PLAN_CUM: 3,   TO_ACT_CUM: 7,   TO_REM_CUM: 662, },
      { SCOPE: 'Grassroots',    ITEM: 'A',  UNIT: 'OV', CDATE: '2023-11-03', CREATED_WK: 311,  CLOSED_WK: 536, CREATED_CUM: 105723, CLOSED_CUM: 26735, REMAIN_CUM: 88411, TOTAL: 115146, TO_PLAN_WK: 2,  TO_ACT_WK: 5,  TO_PLAN_CUM: 5,   TO_ACT_CUM: 12,  TO_REM_CUM: 657, },
      { SCOPE: 'Grassroots',    ITEM: 'A',  UNIT: 'OV', CDATE: '2023-11-10', CREATED_WK: 524,  CLOSED_WK: 659, CREATED_CUM: 106247, CLOSED_CUM: 27394, REMAIN_CUM: 87752, TOTAL: 115146, TO_PLAN_WK: 0,  TO_ACT_WK: 7,  TO_PLAN_CUM: 5,   TO_ACT_CUM: 19,  TO_REM_CUM: 650, },
      { SCOPE: 'Grassroots',    ITEM: 'A',  UNIT: 'OV', CDATE: '2023-11-17', CREATED_WK: 373,  CLOSED_WK: 605, CREATED_CUM: 106620, CLOSED_CUM: 27999, REMAIN_CUM: 87147, TOTAL: 115146, TO_PLAN_WK: 0,  TO_ACT_WK: 0,  TO_PLAN_CUM: 5,   TO_ACT_CUM: 19,  TO_REM_CUM: 650, },
      { SCOPE: 'Grassroots',    ITEM: 'A',  UNIT: 'OV', CDATE: '2023-11-24', CREATED_WK: 382,  CLOSED_WK: 493, CREATED_CUM: 107002, CLOSED_CUM: 28492, REMAIN_CUM: 86654, TOTAL: 115146, TO_PLAN_WK: 1,  TO_ACT_WK: 3,  TO_PLAN_CUM: 6,   TO_ACT_CUM: 22,  TO_REM_CUM: 647, },
      { SCOPE: 'Grassroots',    ITEM: 'A',  UNIT: 'OV', CDATE: '2023-12-01', CREATED_WK: 157,  CLOSED_WK: 497, CREATED_CUM: 107159, CLOSED_CUM: 28989, REMAIN_CUM: 86157, TOTAL: 115146, TO_PLAN_WK: 0,  TO_ACT_WK: 8,  TO_PLAN_CUM: 6,   TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'A',  UNIT: 'OV', CDATE: '2023-12-08', CREATED_WK: 2536, CLOSED_WK: 734, CREATED_CUM: 109695, CLOSED_CUM: 29723, REMAIN_CUM: 85423, TOTAL: 115146, TO_PLAN_WK: 1,  TO_ACT_WK: 0,  TO_PLAN_CUM: 7,   TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'A',  UNIT: 'OV', CDATE: '2023-12-15', CREATED_WK: 3329, CLOSED_WK: 735, CREATED_CUM: 113024, CLOSED_CUM: 30458, REMAIN_CUM: 84688, TOTAL: 115146, TO_PLAN_WK: 4,  TO_ACT_WK: 0,  TO_PLAN_CUM: 11,  TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'A',  UNIT: 'OV', CDATE: '2023-12-22', CREATED_WK: 574,  CLOSED_WK: 922, CREATED_CUM: 113598, CLOSED_CUM: 31380, REMAIN_CUM: 83766, TOTAL: 115146, TO_PLAN_WK: 1,  TO_ACT_WK: 0,  TO_PLAN_CUM: 12,  TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'A',  UNIT: 'OV', CDATE: '2023-12-29', CREATED_WK: 1223, CLOSED_WK: 614, CREATED_CUM: 114821, CLOSED_CUM: 31994, REMAIN_CUM: 83152, TOTAL: 115146, TO_PLAN_WK: 1,  TO_ACT_WK: 0,  TO_PLAN_CUM: 13,  TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'A',  UNIT: 'OV', CDATE: '2024-01-05', CREATED_WK: 131,  CLOSED_WK: 424, CREATED_CUM: 114952, CLOSED_CUM: 32418, REMAIN_CUM: 82728, TOTAL: 115146, TO_PLAN_WK: 4,  TO_ACT_WK: 1,  TO_PLAN_CUM: 17,  TO_ACT_CUM: 31,  TO_REM_CUM: 638, },
      { SCOPE: 'Grassroots',    ITEM: 'A',  UNIT: 'OV', CDATE: '2024-01-12', CREATED_WK: 194,  CLOSED_WK: 656, CREATED_CUM: 115146, CLOSED_CUM: 33074, REMAIN_CUM: 82072, TOTAL: 115146, TO_PLAN_WK: 2,  TO_ACT_WK: 0,  TO_PLAN_CUM: 19,  TO_ACT_CUM: 31,  TO_REM_CUM: 638, },
      { SCOPE: 'Grassroots',    ITEM: 'AP', UNIT: 'OV', CDATE: '2023-10-27', CREATED_WK: 202,  CLOSED_WK: 83,  CREATED_CUM: 2836,   CLOSED_CUM: 2202,  REMAIN_CUM: 5261,  TOTAL: 7463,   TO_PLAN_WK: 0,  TO_ACT_WK: 0,  TO_PLAN_CUM: 3,   TO_ACT_CUM: 7,   TO_REM_CUM: 662, },
      { SCOPE: 'Grassroots',    ITEM: 'AP', UNIT: 'OV', CDATE: '2023-11-03', CREATED_WK: 82,   CLOSED_WK: 136, CREATED_CUM: 2918,   CLOSED_CUM: 2338,  REMAIN_CUM: 5125,  TOTAL: 7463,   TO_PLAN_WK: 2,  TO_ACT_WK: 5,  TO_PLAN_CUM: 5,   TO_ACT_CUM: 12,  TO_REM_CUM: 657, },
      { SCOPE: 'Grassroots',    ITEM: 'AP', UNIT: 'OV', CDATE: '2023-11-10', CREATED_WK: 284,  CLOSED_WK: 191, CREATED_CUM: 3202,   CLOSED_CUM: 2529,  REMAIN_CUM: 4934,  TOTAL: 7463,   TO_PLAN_WK: 0,  TO_ACT_WK: 7,  TO_PLAN_CUM: 5,   TO_ACT_CUM: 19,  TO_REM_CUM: 650, },
      { SCOPE: 'Grassroots',    ITEM: 'AP', UNIT: 'OV', CDATE: '2023-11-17', CREATED_WK: 205,  CLOSED_WK: 236, CREATED_CUM: 3407,   CLOSED_CUM: 2765,  REMAIN_CUM: 4698,  TOTAL: 7463,   TO_PLAN_WK: 0,  TO_ACT_WK: 0,  TO_PLAN_CUM: 5,   TO_ACT_CUM: 19,  TO_REM_CUM: 650, },
      { SCOPE: 'Grassroots',    ITEM: 'AP', UNIT: 'OV', CDATE: '2023-11-24', CREATED_WK: 107,  CLOSED_WK: 61,  CREATED_CUM: 3514,   CLOSED_CUM: 2826,  REMAIN_CUM: 4637,  TOTAL: 7463,   TO_PLAN_WK: 1,  TO_ACT_WK: 3,  TO_PLAN_CUM: 6,   TO_ACT_CUM: 22,  TO_REM_CUM: 647, },
      { SCOPE: 'Grassroots',    ITEM: 'AP', UNIT: 'OV', CDATE: '2023-12-01', CREATED_WK: 716,  CLOSED_WK: 48,  CREATED_CUM: 4230,   CLOSED_CUM: 2874,  REMAIN_CUM: 4589,  TOTAL: 7463,   TO_PLAN_WK: 0,  TO_ACT_WK: 8,  TO_PLAN_CUM: 6,   TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'AP', UNIT: 'OV', CDATE: '2023-12-08', CREATED_WK: 942,  CLOSED_WK: 153, CREATED_CUM: 5172,   CLOSED_CUM: 3027,  REMAIN_CUM: 4436,  TOTAL: 7463,   TO_PLAN_WK: 1,  TO_ACT_WK: 0,  TO_PLAN_CUM: 7,   TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'AP', UNIT: 'OV', CDATE: '2023-12-15', CREATED_WK: 634,  CLOSED_WK: 253, CREATED_CUM: 5806,   CLOSED_CUM: 3280,  REMAIN_CUM: 4183,  TOTAL: 7463,   TO_PLAN_WK: 4,  TO_ACT_WK: 0,  TO_PLAN_CUM: 11,  TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'AP', UNIT: 'OV', CDATE: '2023-12-22', CREATED_WK: 1406, CLOSED_WK: 177, CREATED_CUM: 7212,   CLOSED_CUM: 3457,  REMAIN_CUM: 4006,  TOTAL: 7463,   TO_PLAN_WK: 1,  TO_ACT_WK: 0,  TO_PLAN_CUM: 12,  TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'AP', UNIT: 'OV', CDATE: '2023-12-29', CREATED_WK: 174,  CLOSED_WK: 99,  CREATED_CUM: 7386,   CLOSED_CUM: 3556,  REMAIN_CUM: 3907,  TOTAL: 7463,   TO_PLAN_WK: 1,  TO_ACT_WK: 0,  TO_PLAN_CUM: 13,  TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'AP', UNIT: 'OV', CDATE: '2024-01-05', CREATED_WK: 64,   CLOSED_WK: 407, CREATED_CUM: 7450,   CLOSED_CUM: 3963,  REMAIN_CUM: 3500,  TOTAL: 7463,   TO_PLAN_WK: 4,  TO_ACT_WK: 1,  TO_PLAN_CUM: 17,  TO_ACT_CUM: 31,  TO_REM_CUM: 638, },
      { SCOPE: 'Grassroots',    ITEM: 'AP', UNIT: 'OV', CDATE: '2024-01-12', CREATED_WK: 13,   CLOSED_WK: 186, CREATED_CUM: 7463,   CLOSED_CUM: 4149,  REMAIN_CUM: 3314,  TOTAL: 7463,   TO_PLAN_WK: 2,  TO_ACT_WK: 0,  TO_PLAN_CUM: 19,  TO_ACT_CUM: 31,  TO_REM_CUM: 638, },
      { SCOPE: 'Grassroots',    ITEM: 'B',  UNIT: 'OV', CDATE: '2023-10-27', CREATED_WK: 344,  CLOSED_WK: 217, CREATED_CUM: 33379,  CLOSED_CUM: 8685,  REMAIN_CUM: 27356, TOTAL: 36041,  TO_PLAN_WK: 0,  TO_ACT_WK: 0,  TO_PLAN_CUM: 3,   TO_ACT_CUM: 7,   TO_REM_CUM: 662, },
      { SCOPE: 'Grassroots',    ITEM: 'B',  UNIT: 'OV', CDATE: '2023-11-03', CREATED_WK: 183,  CLOSED_WK: 307, CREATED_CUM: 33562,  CLOSED_CUM: 8992,  REMAIN_CUM: 27049, TOTAL: 36041,  TO_PLAN_WK: 2,  TO_ACT_WK: 5,  TO_PLAN_CUM: 5,   TO_ACT_CUM: 12,  TO_REM_CUM: 657, },
      { SCOPE: 'Grassroots',    ITEM: 'B',  UNIT: 'OV', CDATE: '2023-11-10', CREATED_WK: 279,  CLOSED_WK: 292, CREATED_CUM: 33841,  CLOSED_CUM: 9284,  REMAIN_CUM: 26757, TOTAL: 36041,  TO_PLAN_WK: 0,  TO_ACT_WK: 7,  TO_PLAN_CUM: 5,   TO_ACT_CUM: 19,  TO_REM_CUM: 650, },
      { SCOPE: 'Grassroots',    ITEM: 'B',  UNIT: 'OV', CDATE: '2023-11-17', CREATED_WK: 162,  CLOSED_WK: 182, CREATED_CUM: 34003,  CLOSED_CUM: 9466,  REMAIN_CUM: 26575, TOTAL: 36041,  TO_PLAN_WK: 0,  TO_ACT_WK: 0,  TO_PLAN_CUM: 5,   TO_ACT_CUM: 19,  TO_REM_CUM: 650, },
      { SCOPE: 'Grassroots',    ITEM: 'B',  UNIT: 'OV', CDATE: '2023-11-24', CREATED_WK: 257,  CLOSED_WK: 291, CREATED_CUM: 34260,  CLOSED_CUM: 9757,  REMAIN_CUM: 26284, TOTAL: 36041,  TO_PLAN_WK: 1,  TO_ACT_WK: 3,  TO_PLAN_CUM: 6,   TO_ACT_CUM: 22,  TO_REM_CUM: 647, },
      { SCOPE: 'Grassroots',    ITEM: 'B',  UNIT: 'OV', CDATE: '2023-12-01', CREATED_WK: 41,   CLOSED_WK: 203, CREATED_CUM: 34301,  CLOSED_CUM: 9960,  REMAIN_CUM: 26081, TOTAL: 36041,  TO_PLAN_WK: 0,  TO_ACT_WK: 8,  TO_PLAN_CUM: 6,   TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'B',  UNIT: 'OV', CDATE: '2023-12-08', CREATED_WK: 193,  CLOSED_WK: 296, CREATED_CUM: 34494,  CLOSED_CUM: 10256, REMAIN_CUM: 25785, TOTAL: 36041,  TO_PLAN_WK: 1,  TO_ACT_WK: 0,  TO_PLAN_CUM: 7,   TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'B',  UNIT: 'OV', CDATE: '2023-12-15', CREATED_WK: 431,  CLOSED_WK: 293, CREATED_CUM: 34925,  CLOSED_CUM: 10549, REMAIN_CUM: 25492, TOTAL: 36041,  TO_PLAN_WK: 4,  TO_ACT_WK: 0,  TO_PLAN_CUM: 11,  TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'B',  UNIT: 'OV', CDATE: '2023-12-22', CREATED_WK: 232,  CLOSED_WK: 251, CREATED_CUM: 35157,  CLOSED_CUM: 10800, REMAIN_CUM: 25241, TOTAL: 36041,  TO_PLAN_WK: 1,  TO_ACT_WK: 0,  TO_PLAN_CUM: 12,  TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'B',  UNIT: 'OV', CDATE: '2023-12-29', CREATED_WK: 840,  CLOSED_WK: 262, CREATED_CUM: 35997,  CLOSED_CUM: 11062, REMAIN_CUM: 24979, TOTAL: 36041,  TO_PLAN_WK: 1,  TO_ACT_WK: 0,  TO_PLAN_CUM: 13,  TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'B',  UNIT: 'OV', CDATE: '2024-01-05', CREATED_WK: 7,    CLOSED_WK: 60,  CREATED_CUM: 36004,  CLOSED_CUM: 11122, REMAIN_CUM: 24919, TOTAL: 36041,  TO_PLAN_WK: 4,  TO_ACT_WK: 1,  TO_PLAN_CUM: 17,  TO_ACT_CUM: 31,  TO_REM_CUM: 638, },
      { SCOPE: 'Grassroots',    ITEM: 'B',  UNIT: 'OV', CDATE: '2024-01-12', CREATED_WK: 37,   CLOSED_WK: 85,  CREATED_CUM: 36041,  CLOSED_CUM: 11207, REMAIN_CUM: 24834, TOTAL: 36041,  TO_PLAN_WK: 2,  TO_ACT_WK: 0,  TO_PLAN_CUM: 19,  TO_ACT_CUM: 31,  TO_REM_CUM: 638, },
      { SCOPE: 'Grassroots',    ITEM: 'BP', UNIT: 'OV', CDATE: '2023-10-27', CREATED_WK: 233,  CLOSED_WK: 35,  CREATED_CUM: 2413,   CLOSED_CUM: 1753,  REMAIN_CUM: 2497,  TOTAL: 4250,   TO_PLAN_WK: 0,  TO_ACT_WK: 0,  TO_PLAN_CUM: 3,   TO_ACT_CUM: 7,   TO_REM_CUM: 662, },
      { SCOPE: 'Grassroots',    ITEM: 'BP', UNIT: 'OV', CDATE: '2023-11-03', CREATED_WK: 102,  CLOSED_WK: 143, CREATED_CUM: 2515,   CLOSED_CUM: 1896,  REMAIN_CUM: 2354,  TOTAL: 4250,   TO_PLAN_WK: 2,  TO_ACT_WK: 5,  TO_PLAN_CUM: 5,   TO_ACT_CUM: 12,  TO_REM_CUM: 657, },
      { SCOPE: 'Grassroots',    ITEM: 'BP', UNIT: 'OV', CDATE: '2023-11-10', CREATED_WK: 230,  CLOSED_WK: 204, CREATED_CUM: 2745,   CLOSED_CUM: 2100,  REMAIN_CUM: 2150,  TOTAL: 4250,   TO_PLAN_WK: 0,  TO_ACT_WK: 7,  TO_PLAN_CUM: 5,   TO_ACT_CUM: 19,  TO_REM_CUM: 650, },
      { SCOPE: 'Grassroots',    ITEM: 'BP', UNIT: 'OV', CDATE: '2023-11-17', CREATED_WK: 102,  CLOSED_WK: 176, CREATED_CUM: 2847,   CLOSED_CUM: 2276,  REMAIN_CUM: 1974,  TOTAL: 4250,   TO_PLAN_WK: 0,  TO_ACT_WK: 0,  TO_PLAN_CUM: 5,   TO_ACT_CUM: 19,  TO_REM_CUM: 650, },
      { SCOPE: 'Grassroots',    ITEM: 'BP', UNIT: 'OV', CDATE: '2023-11-24', CREATED_WK: 136,  CLOSED_WK: 40,  CREATED_CUM: 2983,   CLOSED_CUM: 2316,  REMAIN_CUM: 1934,  TOTAL: 4250,   TO_PLAN_WK: 1,  TO_ACT_WK: 3,  TO_PLAN_CUM: 6,   TO_ACT_CUM: 22,  TO_REM_CUM: 647, },
      { SCOPE: 'Grassroots',    ITEM: 'BP', UNIT: 'OV', CDATE: '2023-12-01', CREATED_WK: 134,  CLOSED_WK: 97,  CREATED_CUM: 3117,   CLOSED_CUM: 2413,  REMAIN_CUM: 1837,  TOTAL: 4250,   TO_PLAN_WK: 0,  TO_ACT_WK: 8,  TO_PLAN_CUM: 6,   TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'BP', UNIT: 'OV', CDATE: '2023-12-08', CREATED_WK: 177,  CLOSED_WK: 111, CREATED_CUM: 3294,   CLOSED_CUM: 2524,  REMAIN_CUM: 1726,  TOTAL: 4250,   TO_PLAN_WK: 1,  TO_ACT_WK: 0,  TO_PLAN_CUM: 7,   TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'BP', UNIT: 'OV', CDATE: '2023-12-15', CREATED_WK: 323,  CLOSED_WK: 91,  CREATED_CUM: 3617,   CLOSED_CUM: 2615,  REMAIN_CUM: 1635,  TOTAL: 4250,   TO_PLAN_WK: 4,  TO_ACT_WK: 0,  TO_PLAN_CUM: 11,  TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'BP', UNIT: 'OV', CDATE: '2023-12-22', CREATED_WK: 409,  CLOSED_WK: 59,  CREATED_CUM: 4026,   CLOSED_CUM: 2674,  REMAIN_CUM: 1576,  TOTAL: 4250,   TO_PLAN_WK: 1,  TO_ACT_WK: 0,  TO_PLAN_CUM: 12,  TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'BP', UNIT: 'OV', CDATE: '2023-12-29', CREATED_WK: 123,  CLOSED_WK: 43,  CREATED_CUM: 4149,   CLOSED_CUM: 2717,  REMAIN_CUM: 1533,  TOTAL: 4250,   TO_PLAN_WK: 1,  TO_ACT_WK: 0,  TO_PLAN_CUM: 13,  TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'BP', UNIT: 'OV', CDATE: '2024-01-05', CREATED_WK: 81,   CLOSED_WK: 36,  CREATED_CUM: 4230,   CLOSED_CUM: 2753,  REMAIN_CUM: 1497,  TOTAL: 4250,   TO_PLAN_WK: 4,  TO_ACT_WK: 1,  TO_PLAN_CUM: 17,  TO_ACT_CUM: 31,  TO_REM_CUM: 638, },
      { SCOPE: 'Grassroots',    ITEM: 'BP', UNIT: 'OV', CDATE: '2024-01-12', CREATED_WK: 20,   CLOSED_WK: 25,  CREATED_CUM: 4250,   CLOSED_CUM: 2778,  REMAIN_CUM: 1472,  TOTAL: 4250,   TO_PLAN_WK: 2,  TO_ACT_WK: 0,  TO_PLAN_CUM: 19,  TO_ACT_CUM: 31,  TO_REM_CUM: 638, },
      { SCOPE: 'Grassroots',    ITEM: 'C',  UNIT: 'OV', CDATE: '2023-10-27', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 1483,   CLOSED_CUM: 0,     REMAIN_CUM: 1483,  TOTAL: 1483,   TO_PLAN_WK: 0,  TO_ACT_WK: 0,  TO_PLAN_CUM: 3,   TO_ACT_CUM: 7,   TO_REM_CUM: 662, },
      { SCOPE: 'Grassroots',    ITEM: 'C',  UNIT: 'OV', CDATE: '2023-11-03', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 1483,   CLOSED_CUM: 0,     REMAIN_CUM: 1483,  TOTAL: 1483,   TO_PLAN_WK: 2,  TO_ACT_WK: 5,  TO_PLAN_CUM: 5,   TO_ACT_CUM: 12,  TO_REM_CUM: 657, },
      { SCOPE: 'Grassroots',    ITEM: 'C',  UNIT: 'OV', CDATE: '2023-11-10', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 1483,   CLOSED_CUM: 0,     REMAIN_CUM: 1483,  TOTAL: 1483,   TO_PLAN_WK: 0,  TO_ACT_WK: 7,  TO_PLAN_CUM: 5,   TO_ACT_CUM: 19,  TO_REM_CUM: 650, },
      { SCOPE: 'Grassroots',    ITEM: 'C',  UNIT: 'OV', CDATE: '2023-11-17', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 1483,   CLOSED_CUM: 0,     REMAIN_CUM: 1483,  TOTAL: 1483,   TO_PLAN_WK: 0,  TO_ACT_WK: 0,  TO_PLAN_CUM: 5,   TO_ACT_CUM: 19,  TO_REM_CUM: 650, },
      { SCOPE: 'Grassroots',    ITEM: 'C',  UNIT: 'OV', CDATE: '2023-11-24', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 1483,   CLOSED_CUM: 0,     REMAIN_CUM: 1483,  TOTAL: 1483,   TO_PLAN_WK: 1,  TO_ACT_WK: 3,  TO_PLAN_CUM: 6,   TO_ACT_CUM: 22,  TO_REM_CUM: 647, },
      { SCOPE: 'Grassroots',    ITEM: 'C',  UNIT: 'OV', CDATE: '2023-12-01', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 1483,   CLOSED_CUM: 0,     REMAIN_CUM: 1483,  TOTAL: 1483,   TO_PLAN_WK: 0,  TO_ACT_WK: 8,  TO_PLAN_CUM: 6,   TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'C',  UNIT: 'OV', CDATE: '2023-12-08', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 1483,   CLOSED_CUM: 0,     REMAIN_CUM: 1483,  TOTAL: 1483,   TO_PLAN_WK: 1,  TO_ACT_WK: 0,  TO_PLAN_CUM: 7,   TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'C',  UNIT: 'OV', CDATE: '2023-12-15', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 1483,   CLOSED_CUM: 0,     REMAIN_CUM: 1483,  TOTAL: 1483,   TO_PLAN_WK: 4,  TO_ACT_WK: 0,  TO_PLAN_CUM: 11,  TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'C',  UNIT: 'OV', CDATE: '2023-12-22', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 1483,   CLOSED_CUM: 0,     REMAIN_CUM: 1483,  TOTAL: 1483,   TO_PLAN_WK: 1,  TO_ACT_WK: 0,  TO_PLAN_CUM: 12,  TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'C',  UNIT: 'OV', CDATE: '2023-12-29', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 1483,   CLOSED_CUM: 0,     REMAIN_CUM: 1483,  TOTAL: 1483,   TO_PLAN_WK: 1,  TO_ACT_WK: 0,  TO_PLAN_CUM: 13,  TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'C',  UNIT: 'OV', CDATE: '2024-01-05', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 1483,   CLOSED_CUM: 0,     REMAIN_CUM: 1483,  TOTAL: 1483,   TO_PLAN_WK: 4,  TO_ACT_WK: 1,  TO_PLAN_CUM: 17,  TO_ACT_CUM: 31,  TO_REM_CUM: 638, },
      { SCOPE: 'Grassroots',    ITEM: 'C',  UNIT: 'OV', CDATE: '2024-01-12', CREATED_WK: 0,    CLOSED_WK: 0,   CREATED_CUM: 1483,   CLOSED_CUM: 0,     REMAIN_CUM: 1483,  TOTAL: 1483,   TO_PLAN_WK: 2,  TO_ACT_WK: 0,  TO_PLAN_CUM: 19,  TO_ACT_CUM: 31,  TO_REM_CUM: 638, },
      { SCOPE: 'Grassroots',    ITEM: 'CP', UNIT: 'OV', CDATE: '2023-10-27', CREATED_WK: 187,  CLOSED_WK: 63,  CREATED_CUM: 1680,   CLOSED_CUM: 1221,  REMAIN_CUM: 1873,  TOTAL: 3094,   TO_PLAN_WK: 0,  TO_ACT_WK: 0,  TO_PLAN_CUM: 3,   TO_ACT_CUM: 7,   TO_REM_CUM: 662, },
      { SCOPE: 'Grassroots',    ITEM: 'CP', UNIT: 'OV', CDATE: '2023-11-03', CREATED_WK: 79,   CLOSED_WK: 88,  CREATED_CUM: 1759,   CLOSED_CUM: 1309,  REMAIN_CUM: 1785,  TOTAL: 3094,   TO_PLAN_WK: 2,  TO_ACT_WK: 5,  TO_PLAN_CUM: 5,   TO_ACT_CUM: 12,  TO_REM_CUM: 657, },
      { SCOPE: 'Grassroots',    ITEM: 'CP', UNIT: 'OV', CDATE: '2023-11-10', CREATED_WK: 141,  CLOSED_WK: 130, CREATED_CUM: 1900,   CLOSED_CUM: 1439,  REMAIN_CUM: 1655,  TOTAL: 3094,   TO_PLAN_WK: 0,  TO_ACT_WK: 7,  TO_PLAN_CUM: 5,   TO_ACT_CUM: 19,  TO_REM_CUM: 650, },
      { SCOPE: 'Grassroots',    ITEM: 'CP', UNIT: 'OV', CDATE: '2023-11-17', CREATED_WK: 138,  CLOSED_WK: 134, CREATED_CUM: 2038,   CLOSED_CUM: 1573,  REMAIN_CUM: 1521,  TOTAL: 3094,   TO_PLAN_WK: 0,  TO_ACT_WK: 0,  TO_PLAN_CUM: 5,   TO_ACT_CUM: 19,  TO_REM_CUM: 650, },
      { SCOPE: 'Grassroots',    ITEM: 'CP', UNIT: 'OV', CDATE: '2023-11-24', CREATED_WK: 145,  CLOSED_WK: 11,  CREATED_CUM: 2183,   CLOSED_CUM: 1584,  REMAIN_CUM: 1510,  TOTAL: 3094,   TO_PLAN_WK: 1,  TO_ACT_WK: 3,  TO_PLAN_CUM: 6,   TO_ACT_CUM: 22,  TO_REM_CUM: 647, },
      { SCOPE: 'Grassroots',    ITEM: 'CP', UNIT: 'OV', CDATE: '2023-12-01', CREATED_WK: 182,  CLOSED_WK: 96,  CREATED_CUM: 2365,   CLOSED_CUM: 1680,  REMAIN_CUM: 1414,  TOTAL: 3094,   TO_PLAN_WK: 0,  TO_ACT_WK: 8,  TO_PLAN_CUM: 6,   TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'CP', UNIT: 'OV', CDATE: '2023-12-08', CREATED_WK: 123,  CLOSED_WK: 142, CREATED_CUM: 2488,   CLOSED_CUM: 1822,  REMAIN_CUM: 1272,  TOTAL: 3094,   TO_PLAN_WK: 1,  TO_ACT_WK: 0,  TO_PLAN_CUM: 7,   TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'CP', UNIT: 'OV', CDATE: '2023-12-15', CREATED_WK: 211,  CLOSED_WK: 119, CREATED_CUM: 2699,   CLOSED_CUM: 1941,  REMAIN_CUM: 1153,  TOTAL: 3094,   TO_PLAN_WK: 4,  TO_ACT_WK: 0,  TO_PLAN_CUM: 11,  TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'CP', UNIT: 'OV', CDATE: '2023-12-22', CREATED_WK: 116,  CLOSED_WK: 20,  CREATED_CUM: 2815,   CLOSED_CUM: 1961,  REMAIN_CUM: 1133,  TOTAL: 3094,   TO_PLAN_WK: 1,  TO_ACT_WK: 0,  TO_PLAN_CUM: 12,  TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'CP', UNIT: 'OV', CDATE: '2023-12-29', CREATED_WK: 84,   CLOSED_WK: 10,  CREATED_CUM: 2899,   CLOSED_CUM: 1971,  REMAIN_CUM: 1123,  TOTAL: 3094,   TO_PLAN_WK: 1,  TO_ACT_WK: 0,  TO_PLAN_CUM: 13,  TO_ACT_CUM: 30,  TO_REM_CUM: 639, },
      { SCOPE: 'Grassroots',    ITEM: 'CP', UNIT: 'OV', CDATE: '2024-01-05', CREATED_WK: 85,   CLOSED_WK: 2,   CREATED_CUM: 2984,   CLOSED_CUM: 1973,  REMAIN_CUM: 1121,  TOTAL: 3094,   TO_PLAN_WK: 4,  TO_ACT_WK: 1,  TO_PLAN_CUM: 17,  TO_ACT_CUM: 31,  TO_REM_CUM: 638, },
      { SCOPE: 'Grassroots',    ITEM: 'CP', UNIT: 'OV', CDATE: '2024-01-12', CREATED_WK: 110,  CLOSED_WK: 4,   CREATED_CUM: 3094,   CLOSED_CUM: 1977,  REMAIN_CUM: 1117,  TOTAL: 3094,   TO_PLAN_WK: 2,  TO_ACT_WK: 0,  TO_PLAN_CUM: 19,  TO_ACT_CUM: 31,  TO_REM_CUM: 638, },
    ],
    
    SQL2: [
      { SCOPE: 'Existing Site', ITEM: 'A',     UNIT: 'OV', DATE: '2024-01-05', WEEK: 'LAST', VALUE: 204, },
      { SCOPE: 'Existing Site', ITEM: 'A',     UNIT: 'OV', DATE: '2024-01-06', WEEK: 'SAT',  VALUE: 3, },
      { SCOPE: 'Existing Site', ITEM: 'A',     UNIT: 'OV', DATE: '2024-01-07', WEEK: 'SUN',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'A',     UNIT: 'OV', DATE: '2024-01-08', WEEK: 'MON',  VALUE: 47, },
      { SCOPE: 'Existing Site', ITEM: 'A',     UNIT: 'OV', DATE: '2024-01-09', WEEK: 'TUE',  VALUE: 14, },
      { SCOPE: 'Existing Site', ITEM: 'A',     UNIT: 'OV', DATE: '2024-01-10', WEEK: 'WED',  VALUE: 24, },
      { SCOPE: 'Existing Site', ITEM: 'A',     UNIT: 'OV', DATE: '2024-01-11', WEEK: 'THU',  VALUE: 28, },
      { SCOPE: 'Existing Site', ITEM: 'A',     UNIT: 'OV', DATE: '2024-01-12', WEEK: 'FRI',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'A',     UNIT: 'OV', DATE: '2024-01-12', WEEK: 'THIS', VALUE: 116, },
      { SCOPE: 'Existing Site', ITEM: 'AP',    UNIT: 'OV', DATE: '2024-01-05', WEEK: 'LAST', VALUE: 4, },
      { SCOPE: 'Existing Site', ITEM: 'AP',    UNIT: 'OV', DATE: '2024-01-06', WEEK: 'SAT',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'AP',    UNIT: 'OV', DATE: '2024-01-07', WEEK: 'SUN',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'AP',    UNIT: 'OV', DATE: '2024-01-08', WEEK: 'MON',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'AP',    UNIT: 'OV', DATE: '2024-01-09', WEEK: 'TUE',  VALUE: 7, },
      { SCOPE: 'Existing Site', ITEM: 'AP',    UNIT: 'OV', DATE: '2024-01-10', WEEK: 'WED',  VALUE: 3, },
      { SCOPE: 'Existing Site', ITEM: 'AP',    UNIT: 'OV', DATE: '2024-01-11', WEEK: 'THU',  VALUE: 3, },
      { SCOPE: 'Existing Site', ITEM: 'AP',    UNIT: 'OV', DATE: '2024-01-12', WEEK: 'FRI',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'AP',    UNIT: 'OV', DATE: '2024-01-12', WEEK: 'THIS', VALUE: 13, },
      { SCOPE: 'Existing Site', ITEM: 'B',     UNIT: 'OV', DATE: '2024-01-05', WEEK: 'LAST', VALUE: 62, },
      { SCOPE: 'Existing Site', ITEM: 'B',     UNIT: 'OV', DATE: '2024-01-06', WEEK: 'SAT',  VALUE: 1, },
      { SCOPE: 'Existing Site', ITEM: 'B',     UNIT: 'OV', DATE: '2024-01-07', WEEK: 'SUN',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'B',     UNIT: 'OV', DATE: '2024-01-08', WEEK: 'MON',  VALUE: 15, },
      { SCOPE: 'Existing Site', ITEM: 'B',     UNIT: 'OV', DATE: '2024-01-09', WEEK: 'TUE',  VALUE: 5, },
      { SCOPE: 'Existing Site', ITEM: 'B',     UNIT: 'OV', DATE: '2024-01-10', WEEK: 'WED',  VALUE: 8, },
      { SCOPE: 'Existing Site', ITEM: 'B',     UNIT: 'OV', DATE: '2024-01-11', WEEK: 'THU',  VALUE: 9, },
      { SCOPE: 'Existing Site', ITEM: 'B',     UNIT: 'OV', DATE: '2024-01-12', WEEK: 'FRI',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'B',     UNIT: 'OV', DATE: '2024-01-12', WEEK: 'THIS', VALUE: 38, },
      { SCOPE: 'Existing Site', ITEM: 'BP',    UNIT: 'OV', DATE: '2024-01-05', WEEK: 'LAST', VALUE: 61, },
      { SCOPE: 'Existing Site', ITEM: 'BP',    UNIT: 'OV', DATE: '2024-01-06', WEEK: 'SAT',  VALUE: 10, },
      { SCOPE: 'Existing Site', ITEM: 'BP',    UNIT: 'OV', DATE: '2024-01-07', WEEK: 'SUN',  VALUE: 8, },
      { SCOPE: 'Existing Site', ITEM: 'BP',    UNIT: 'OV', DATE: '2024-01-08', WEEK: 'MON',  VALUE: 7, },
      { SCOPE: 'Existing Site', ITEM: 'BP',    UNIT: 'OV', DATE: '2024-01-09', WEEK: 'TUE',  VALUE: 21, },
      { SCOPE: 'Existing Site', ITEM: 'BP',    UNIT: 'OV', DATE: '2024-01-10', WEEK: 'WED',  VALUE: 13, },
      { SCOPE: 'Existing Site', ITEM: 'BP',    UNIT: 'OV', DATE: '2024-01-11', WEEK: 'THU',  VALUE: 30, },
      { SCOPE: 'Existing Site', ITEM: 'BP',    UNIT: 'OV', DATE: '2024-01-12', WEEK: 'FRI',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'BP',    UNIT: 'OV', DATE: '2024-01-12', WEEK: 'THIS', VALUE: 89, },
      { SCOPE: 'Existing Site', ITEM: 'C',     UNIT: 'OV', DATE: '2024-01-05', WEEK: 'LAST', VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'C',     UNIT: 'OV', DATE: '2024-01-06', WEEK: 'SAT',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'C',     UNIT: 'OV', DATE: '2024-01-07', WEEK: 'SUN',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'C',     UNIT: 'OV', DATE: '2024-01-08', WEEK: 'MON',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'C',     UNIT: 'OV', DATE: '2024-01-09', WEEK: 'TUE',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'C',     UNIT: 'OV', DATE: '2024-01-10', WEEK: 'WED',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'C',     UNIT: 'OV', DATE: '2024-01-11', WEEK: 'THU',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'C',     UNIT: 'OV', DATE: '2024-01-12', WEEK: 'FRI',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'C',     UNIT: 'OV', DATE: '2024-01-12', WEEK: 'THIS', VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'CP',    UNIT: 'OV', DATE: '2024-01-05', WEEK: 'LAST', VALUE: 22, },
      { SCOPE: 'Existing Site', ITEM: 'CP',    UNIT: 'OV', DATE: '2024-01-06', WEEK: 'SAT',  VALUE: 9, },
      { SCOPE: 'Existing Site', ITEM: 'CP',    UNIT: 'OV', DATE: '2024-01-07', WEEK: 'SUN',  VALUE: 1, },
      { SCOPE: 'Existing Site', ITEM: 'CP',    UNIT: 'OV', DATE: '2024-01-08', WEEK: 'MON',  VALUE: 11, },
      { SCOPE: 'Existing Site', ITEM: 'CP',    UNIT: 'OV', DATE: '2024-01-09', WEEK: 'TUE',  VALUE: 11, },
      { SCOPE: 'Existing Site', ITEM: 'CP',    UNIT: 'OV', DATE: '2024-01-10', WEEK: 'WED',  VALUE: 9, },
      { SCOPE: 'Existing Site', ITEM: 'CP',    UNIT: 'OV', DATE: '2024-01-11', WEEK: 'THU',  VALUE: 3, },
      { SCOPE: 'Existing Site', ITEM: 'CP',    UNIT: 'OV', DATE: '2024-01-12', WEEK: 'FRI',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'CP',    UNIT: 'OV', DATE: '2024-01-12', WEEK: 'THIS', VALUE: 44, },
      { SCOPE: 'Existing Site', ITEM: 'SSMCC', UNIT: 'OV', DATE: '2024-01-05', WEEK: 'LAST', VALUE: 1, },
      { SCOPE: 'Existing Site', ITEM: 'SSMCC', UNIT: 'OV', DATE: '2024-01-06', WEEK: 'SAT',  VALUE: 1, },
      { SCOPE: 'Existing Site', ITEM: 'SSMCC', UNIT: 'OV', DATE: '2024-01-07', WEEK: 'SUN',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'SSMCC', UNIT: 'OV', DATE: '2024-01-08', WEEK: 'MON',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'SSMCC', UNIT: 'OV', DATE: '2024-01-09', WEEK: 'TUE',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'SSMCC', UNIT: 'OV', DATE: '2024-01-10', WEEK: 'WED',  VALUE: 2, },
      { SCOPE: 'Existing Site', ITEM: 'SSMCC', UNIT: 'OV', DATE: '2024-01-11', WEEK: 'THU',  VALUE: 1, },
      { SCOPE: 'Existing Site', ITEM: 'SSMCC', UNIT: 'OV', DATE: '2024-01-12', WEEK: 'FRI',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'SSMCC', UNIT: 'OV', DATE: '2024-01-12', WEEK: 'THIS', VALUE: 4, },
      { SCOPE: 'Existing Site', ITEM: 'WD',    UNIT: 'OV', DATE: '2024-01-05', WEEK: 'LAST', VALUE: 2, },
      { SCOPE: 'Existing Site', ITEM: 'WD',    UNIT: 'OV', DATE: '2024-01-06', WEEK: 'SAT',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'WD',    UNIT: 'OV', DATE: '2024-01-07', WEEK: 'SUN',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'WD',    UNIT: 'OV', DATE: '2024-01-08', WEEK: 'MON',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'WD',    UNIT: 'OV', DATE: '2024-01-09', WEEK: 'TUE',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'WD',    UNIT: 'OV', DATE: '2024-01-10', WEEK: 'WED',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'WD',    UNIT: 'OV', DATE: '2024-01-11', WEEK: 'THU',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'WD',    UNIT: 'OV', DATE: '2024-01-12', WEEK: 'FRI',  VALUE: 0, },
      { SCOPE: 'Existing Site', ITEM: 'WD',    UNIT: 'OV', DATE: '2024-01-12', WEEK: 'THIS', VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'A',     UNIT: 'OV', DATE: '2024-01-05', WEEK: 'LAST', VALUE: 424, },
      { SCOPE: 'Grassroots',    ITEM: 'A',     UNIT: 'OV', DATE: '2024-01-06', WEEK: 'SAT',  VALUE: 41, },
      { SCOPE: 'Grassroots',    ITEM: 'A',     UNIT: 'OV', DATE: '2024-01-07', WEEK: 'SUN',  VALUE: 29, },
      { SCOPE: 'Grassroots',    ITEM: 'A',     UNIT: 'OV', DATE: '2024-01-08', WEEK: 'MON',  VALUE: 261, },
      { SCOPE: 'Grassroots',    ITEM: 'A',     UNIT: 'OV', DATE: '2024-01-09', WEEK: 'TUE',  VALUE: 125, },
      { SCOPE: 'Grassroots',    ITEM: 'A',     UNIT: 'OV', DATE: '2024-01-10', WEEK: 'WED',  VALUE: 163, },
      { SCOPE: 'Grassroots',    ITEM: 'A',     UNIT: 'OV', DATE: '2024-01-11', WEEK: 'THU',  VALUE: 37, },
      { SCOPE: 'Grassroots',    ITEM: 'A',     UNIT: 'OV', DATE: '2024-01-12', WEEK: 'FRI',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'A',     UNIT: 'OV', DATE: '2024-01-12', WEEK: 'THIS', VALUE: 656, },
      { SCOPE: 'Grassroots',    ITEM: 'AP',    UNIT: 'OV', DATE: '2024-01-05', WEEK: 'LAST', VALUE: 407, },
      { SCOPE: 'Grassroots',    ITEM: 'AP',    UNIT: 'OV', DATE: '2024-01-06', WEEK: 'SAT',  VALUE: 164, },
      { SCOPE: 'Grassroots',    ITEM: 'AP',    UNIT: 'OV', DATE: '2024-01-07', WEEK: 'SUN',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'AP',    UNIT: 'OV', DATE: '2024-01-08', WEEK: 'MON',  VALUE: 16, },
      { SCOPE: 'Grassroots',    ITEM: 'AP',    UNIT: 'OV', DATE: '2024-01-09', WEEK: 'TUE',  VALUE: 4, },
      { SCOPE: 'Grassroots',    ITEM: 'AP',    UNIT: 'OV', DATE: '2024-01-10', WEEK: 'WED',  VALUE: 2, },
      { SCOPE: 'Grassroots',    ITEM: 'AP',    UNIT: 'OV', DATE: '2024-01-11', WEEK: 'THU',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'AP',    UNIT: 'OV', DATE: '2024-01-12', WEEK: 'FRI',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'AP',    UNIT: 'OV', DATE: '2024-01-12', WEEK: 'THIS', VALUE: 186, },
      { SCOPE: 'Grassroots',    ITEM: 'B',     UNIT: 'OV', DATE: '2024-01-05', WEEK: 'LAST', VALUE: 60, },
      { SCOPE: 'Grassroots',    ITEM: 'B',     UNIT: 'OV', DATE: '2024-01-06', WEEK: 'SAT',  VALUE: 17, },
      { SCOPE: 'Grassroots',    ITEM: 'B',     UNIT: 'OV', DATE: '2024-01-07', WEEK: 'SUN',  VALUE: 2, },
      { SCOPE: 'Grassroots',    ITEM: 'B',     UNIT: 'OV', DATE: '2024-01-08', WEEK: 'MON',  VALUE: 29, },
      { SCOPE: 'Grassroots',    ITEM: 'B',     UNIT: 'OV', DATE: '2024-01-09', WEEK: 'TUE',  VALUE: 14, },
      { SCOPE: 'Grassroots',    ITEM: 'B',     UNIT: 'OV', DATE: '2024-01-10', WEEK: 'WED',  VALUE: 19, },
      { SCOPE: 'Grassroots',    ITEM: 'B',     UNIT: 'OV', DATE: '2024-01-11', WEEK: 'THU',  VALUE: 4, },
      { SCOPE: 'Grassroots',    ITEM: 'B',     UNIT: 'OV', DATE: '2024-01-12', WEEK: 'FRI',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'B',     UNIT: 'OV', DATE: '2024-01-12', WEEK: 'THIS', VALUE: 85, },
      { SCOPE: 'Grassroots',    ITEM: 'BP',    UNIT: 'OV', DATE: '2024-01-05', WEEK: 'LAST', VALUE: 36, },
      { SCOPE: 'Grassroots',    ITEM: 'BP',    UNIT: 'OV', DATE: '2024-01-06', WEEK: 'SAT',  VALUE: 7, },
      { SCOPE: 'Grassroots',    ITEM: 'BP',    UNIT: 'OV', DATE: '2024-01-07', WEEK: 'SUN',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'BP',    UNIT: 'OV', DATE: '2024-01-08', WEEK: 'MON',  VALUE: 8, },
      { SCOPE: 'Grassroots',    ITEM: 'BP',    UNIT: 'OV', DATE: '2024-01-09', WEEK: 'TUE',  VALUE: 5, },
      { SCOPE: 'Grassroots',    ITEM: 'BP',    UNIT: 'OV', DATE: '2024-01-10', WEEK: 'WED',  VALUE: 5, },
      { SCOPE: 'Grassroots',    ITEM: 'BP',    UNIT: 'OV', DATE: '2024-01-11', WEEK: 'THU',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'BP',    UNIT: 'OV', DATE: '2024-01-12', WEEK: 'FRI',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'BP',    UNIT: 'OV', DATE: '2024-01-12', WEEK: 'THIS', VALUE: 25, },
      { SCOPE: 'Grassroots',    ITEM: 'C',     UNIT: 'OV', DATE: '2024-01-05', WEEK: 'LAST', VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'C',     UNIT: 'OV', DATE: '2024-01-06', WEEK: 'SAT',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'C',     UNIT: 'OV', DATE: '2024-01-07', WEEK: 'SUN',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'C',     UNIT: 'OV', DATE: '2024-01-08', WEEK: 'MON',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'C',     UNIT: 'OV', DATE: '2024-01-09', WEEK: 'TUE',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'C',     UNIT: 'OV', DATE: '2024-01-10', WEEK: 'WED',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'C',     UNIT: 'OV', DATE: '2024-01-11', WEEK: 'THU',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'C',     UNIT: 'OV', DATE: '2024-01-12', WEEK: 'FRI',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'C',     UNIT: 'OV', DATE: '2024-01-12', WEEK: 'THIS', VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'CP',    UNIT: 'OV', DATE: '2024-01-05', WEEK: 'LAST', VALUE: 2, },
      { SCOPE: 'Grassroots',    ITEM: 'CP',    UNIT: 'OV', DATE: '2024-01-06', WEEK: 'SAT',  VALUE: 4, },
      { SCOPE: 'Grassroots',    ITEM: 'CP',    UNIT: 'OV', DATE: '2024-01-07', WEEK: 'SUN',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'CP',    UNIT: 'OV', DATE: '2024-01-08', WEEK: 'MON',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'CP',    UNIT: 'OV', DATE: '2024-01-09', WEEK: 'TUE',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'CP',    UNIT: 'OV', DATE: '2024-01-10', WEEK: 'WED',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'CP',    UNIT: 'OV', DATE: '2024-01-11', WEEK: 'THU',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'CP',    UNIT: 'OV', DATE: '2024-01-12', WEEK: 'FRI',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'CP',    UNIT: 'OV', DATE: '2024-01-12', WEEK: 'THIS', VALUE: 4, },
      { SCOPE: 'Grassroots',    ITEM: 'SSMCC', UNIT: 'OV', DATE: '2024-01-05', WEEK: 'LAST', VALUE: 1, },
      { SCOPE: 'Grassroots',    ITEM: 'SSMCC', UNIT: 'OV', DATE: '2024-01-06', WEEK: 'SAT',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'SSMCC', UNIT: 'OV', DATE: '2024-01-07', WEEK: 'SUN',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'SSMCC', UNIT: 'OV', DATE: '2024-01-08', WEEK: 'MON',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'SSMCC', UNIT: 'OV', DATE: '2024-01-09', WEEK: 'TUE',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'SSMCC', UNIT: 'OV', DATE: '2024-01-10', WEEK: 'WED',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'SSMCC', UNIT: 'OV', DATE: '2024-01-11', WEEK: 'THU',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'SSMCC', UNIT: 'OV', DATE: '2024-01-12', WEEK: 'FRI',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'SSMCC', UNIT: 'OV', DATE: '2024-01-12', WEEK: 'THIS', VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'WD',    UNIT: 'OV', DATE: '2024-01-05', WEEK: 'LAST', VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'WD',    UNIT: 'OV', DATE: '2024-01-06', WEEK: 'SAT',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'WD',    UNIT: 'OV', DATE: '2024-01-07', WEEK: 'SUN',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'WD',    UNIT: 'OV', DATE: '2024-01-08', WEEK: 'MON',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'WD',    UNIT: 'OV', DATE: '2024-01-09', WEEK: 'TUE',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'WD',    UNIT: 'OV', DATE: '2024-01-10', WEEK: 'WED',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'WD',    UNIT: 'OV', DATE: '2024-01-11', WEEK: 'THU',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'WD',    UNIT: 'OV', DATE: '2024-01-12', WEEK: 'FRI',  VALUE: 0, },
      { SCOPE: 'Grassroots',    ITEM: 'WD',    UNIT: 'OV', DATE: '2024-01-12', WEEK: 'THIS', VALUE: 0, },
    ],

    SQL3: [
      { SCOPE: 'Grassroots', UNIT: 'GENERAL',     UNIT_NO: '20000', UNIT_DESC: 'GENERAL',                                       SSMCC_TOT: 12, SSMCC_ACT: 0,  SSMCC_REM: 12, A_TOT: 797,   A_ACT: 178,  A_REM: 619,   A_PRO: 22.3, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    B_TOT: 98,   B_ACT: 11,   B_REM: 87,   B_PRO: 11.2, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0, },
      { SCOPE: 'Grassroots', UNIT: 'DAU',         UNIT_NO: '20100', UNIT_DESC: 'Deasphalting Unit',                             SSMCC_TOT: 42, SSMCC_ACT: 0,  SSMCC_REM: 42, A_TOT: 8981,  A_ACT: 3359, A_REM: 5622,  A_PRO: 37.4, AP_TOT: 169, AP_ACT: 1,   AP_REM: 168, AP_PRO: 0.6,  B_TOT: 2861, B_ACT: 1020, B_REM: 1841, B_PRO: 35.7, BP_TOT: 95,  BP_ACT: 0,   BP_REM: 95,  BP_PRO: 0, },
      { SCOPE: 'Grassroots', UNIT: 'PFBS',        UNIT_NO: '20101', UNIT_DESC: 'Prefractionator Bottoms Stripper',              SSMCC_TOT: 21, SSMCC_ACT: 0,  SSMCC_REM: 21, A_TOT: 1863,  A_ACT: 751,  A_REM: 1112,  A_PRO: 40.3, AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    B_TOT: 596,  B_ACT: 224,  B_REM: 372,  B_PRO: 37.6, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0, },
      { SCOPE: 'Grassroots', UNIT: 'DHU S1',      UNIT_NO: '20200', UNIT_DESC: 'Hydroprocessing Unit',                          SSMCC_TOT: 68, SSMCC_ACT: 0,  SSMCC_REM: 68, A_TOT: 36059, A_ACT: 5591, A_REM: 30468, A_PRO: 15.5, AP_TOT: 28,  AP_ACT: 0,   AP_REM: 28,  AP_PRO: 0,    B_TOT: 9225, B_ACT: 1511, B_REM: 7714, B_PRO: 16.4, BP_TOT: 16,  BP_ACT: 5,   BP_REM: 11,  BP_PRO: 31.3, },
      { SCOPE: 'Grassroots', UNIT: 'DHU S2',      UNIT_NO: '20300', UNIT_DESC: 'Hydroprocessing Unit',                          SSMCC_TOT: 30, SSMCC_ACT: 0,  SSMCC_REM: 30, A_TOT: 8342,  A_ACT: 1808, A_REM: 6534,  A_PRO: 21.7, AP_TOT: 22,  AP_ACT: 0,   AP_REM: 22,  AP_PRO: 0,    B_TOT: 2392, B_ACT: 546,  B_REM: 1846, B_PRO: 22.8, BP_TOT: 5,   BP_ACT: 0,   BP_REM: 5,   BP_PRO: 0, },
      { SCOPE: 'Grassroots', UNIT: 'CCFU',        UNIT_NO: '20400', UNIT_DESC: 'Chemical Clean Fuel Unit',                      SSMCC_TOT: 49, SSMCC_ACT: 0,  SSMCC_REM: 49, A_TOT: 13983, A_ACT: 2771, A_REM: 11212, A_PRO: 19.8, AP_TOT: 6,   AP_ACT: 0,   AP_REM: 6,   AP_PRO: 0,    B_TOT: 4442, B_ACT: 861,  B_REM: 3581, B_PRO: 19.4, BP_TOT: 17,  BP_ACT: 0,   BP_REM: 17,  BP_PRO: 0, },
      { SCOPE: 'Grassroots', UNIT: 'CCFU SS',     UNIT_NO: '20401', UNIT_DESC: 'Chemical Clean Fuel Unit Solid Separation',     SSMCC_TOT: 29, SSMCC_ACT: 0,  SSMCC_REM: 29, A_TOT: 2399,  A_ACT: 750,  A_REM: 1649,  A_PRO: 31.3, AP_TOT: 5,   AP_ACT: 0,   AP_REM: 5,   AP_PRO: 0,    B_TOT: 1224, B_ACT: 280,  B_REM: 944,  B_PRO: 22.9, BP_TOT: 5,   BP_ACT: 0,   BP_REM: 5,   BP_PRO: 0, },
      { SCOPE: 'Grassroots', UNIT: 'SWS',         UNIT_NO: '20500', UNIT_DESC: 'Sour Water Stripper Unit',                      SSMCC_TOT: 24, SSMCC_ACT: 0,  SSMCC_REM: 24, A_TOT: 1406,  A_ACT: 704,  A_REM: 702,   A_PRO: 50.1, AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,    B_TOT: 603,  B_ACT: 306,  B_REM: 297,  B_PRO: 50.7, BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0, },
      { SCOPE: 'Grassroots', UNIT: 'ARU',         UNIT_NO: '20600', UNIT_DESC: 'Amine Regeneration Unit',                       SSMCC_TOT: 29, SSMCC_ACT: 0,  SSMCC_REM: 29, A_TOT: 2900,  A_ACT: 1089, A_REM: 1811,  A_PRO: 37.6, AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,    B_TOT: 1113, B_ACT: 437,  B_REM: 676,  B_PRO: 39.3, BP_TOT: 1,   BP_ACT: 0,   BP_REM: 1,   BP_PRO: 0, },
      { SCOPE: 'Grassroots', UNIT: 'SRU',         UNIT_NO: '20700', UNIT_DESC: 'Sulfur Recovery Unit',                          SSMCC_TOT: 39, SSMCC_ACT: 0,  SSMCC_REM: 39, A_TOT: 7972,  A_ACT: 3980, A_REM: 3992,  A_PRO: 49.9, AP_TOT: 64,  AP_ACT: 0,   AP_REM: 64,  AP_PRO: 0,    B_TOT: 2201, B_ACT: 1046, B_REM: 1155, B_PRO: 47.5, BP_TOT: 108, BP_ACT: 5,   BP_REM: 103, BP_PRO: 4.6, },
      { SCOPE: 'Grassroots', UNIT: 'HOS',         UNIT_NO: '20800', UNIT_DESC: 'Hot Oil System',                                SSMCC_TOT: 28, SSMCC_ACT: 0,  SSMCC_REM: 28, A_TOT: 5296,  A_ACT: 1580, A_REM: 3716,  A_PRO: 29.8, AP_TOT: 4,   AP_ACT: 0,   AP_REM: 4,   AP_PRO: 0,    B_TOT: 1604, B_ACT: 494,  B_REM: 1110, B_PRO: 30.8, BP_TOT: 10,  BP_ACT: 0,   BP_REM: 10,  BP_PRO: 0, },
      { SCOPE: 'Grassroots', UNIT: 'U&O',         UNIT_NO: '21100', UNIT_DESC: 'Utilities & Offsites',                          SSMCC_TOT: 95, SSMCC_ACT: 0,  SSMCC_REM: 95, A_TOT: 9328,  A_ACT: 4624, A_REM: 4704,  A_PRO: 49.6, AP_TOT: 113, AP_ACT: 6,   AP_REM: 107, AP_PRO: 5.3,  B_TOT: 4419, B_ACT: 2710, B_REM: 1709, B_PRO: 61.3, BP_TOT: 66,  BP_ACT: 0,   BP_REM: 66,  BP_PRO: 0, },
      { SCOPE: 'Grassroots', UNIT: 'CWS',         UNIT_NO: '21200', UNIT_DESC: 'Cooling Water System',                          SSMCC_TOT: 32, SSMCC_ACT: 0,  SSMCC_REM: 32, A_TOT: 1716,  A_ACT: 370,  A_REM: 1346,  A_PRO: 21.6, AP_TOT: 4,   AP_ACT: 0,   AP_REM: 4,   AP_PRO: 0,    B_TOT: 938,  B_ACT: 291,  B_REM: 647,  B_PRO: 31,   BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0, },
      { SCOPE: 'Grassroots', UNIT: 'DS',          UNIT_NO: '21300', UNIT_DESC: 'Disposal Systems',                              SSMCC_TOT: 29, SSMCC_ACT: 0,  SSMCC_REM: 29, A_TOT: 3456,  A_ACT: 1062, A_REM: 2394,  A_PRO: 30.7, AP_TOT: 18,  AP_ACT: 3,   AP_REM: 15,  AP_PRO: 16.7, B_TOT: 1578, B_ACT: 489,  B_REM: 1089, B_PRO: 31,   BP_TOT: 22,  BP_ACT: 2,   BP_REM: 20,  BP_PRO: 9.1, },
      { SCOPE: 'Grassroots', UNIT: 'OM&S-TKN',    UNIT_NO: '21401', UNIT_DESC: 'Tank Farm North',                               SSMCC_TOT: 24, SSMCC_ACT: 0,  SSMCC_REM: 24, A_TOT: 4426,  A_ACT: 558,  A_REM: 3868,  A_PRO: 12.6, AP_TOT: 3,   AP_ACT: 0,   AP_REM: 3,   AP_PRO: 0,    B_TOT: 1205, B_ACT: 190,  B_REM: 1015, B_PRO: 15.8, BP_TOT: 2,   BP_ACT: 0,   BP_REM: 2,   BP_PRO: 0, },
      { SCOPE: 'Grassroots', UNIT: 'OM&S-TKS',    UNIT_NO: '21402', UNIT_DESC: 'Tank Farm South',                               SSMCC_TOT: 24, SSMCC_ACT: 0,  SSMCC_REM: 24, A_TOT: 2647,  A_ACT: 603,  A_REM: 2044,  A_PRO: 22.8, AP_TOT: 1,   AP_ACT: 0,   AP_REM: 1,   AP_PRO: 0,    B_TOT: 425,  B_ACT: 82,   B_REM: 343,  B_PRO: 19.3, BP_TOT: 0,   BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0, },
      { SCOPE: 'Grassroots', UNIT: 'HSFOC',       UNIT_NO: '21403', UNIT_DESC: 'High Sulphur Fuel Oil Cooler',                  SSMCC_TOT: 31, SSMCC_ACT: 0,  SSMCC_REM: 31, A_TOT: 1927,  A_ACT: 1167, A_REM: 760,   A_PRO: 60.6, AP_TOT: 49,  AP_ACT: 0,   AP_REM: 49,  AP_PRO: 0,    B_TOT: 635,  B_ACT: 312,  B_REM: 323,  B_PRO: 49.1, BP_TOT: 107, BP_ACT: 0,   BP_REM: 107, BP_PRO: 0, },
      { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 01', UNIT_NO: '21501', UNIT_DESC: 'Instrument & Control System-RIE 01 - PAB 1603', SSMCC_TOT: 15, SSMCC_ACT: 1,  SSMCC_REM: 14, A_TOT: 52,    A_ACT: 36,   A_REM: 16,    A_PRO: 69.2, AP_TOT: 4,   AP_ACT: 0,   AP_REM: 4,   AP_PRO: 0,    B_TOT: 78,   B_ACT: 39,   B_REM: 39,   B_PRO: 50,   BP_TOT: 15,  BP_ACT: 5,   BP_REM: 10,  BP_PRO: 33.3, },
      { SCOPE: 'Grassroots', UNIT: 'I&CS RIE 02', UNIT_NO: '21502', UNIT_DESC: 'Instrument & Control System-RIE 02 - PAB 1603', SSMCC_TOT: 15, SSMCC_ACT: 1,  SSMCC_REM: 14, A_TOT: 105,   A_ACT: 45,   A_REM: 60,    A_PRO: 42.9, AP_TOT: 20,  AP_ACT: 10,  AP_REM: 10,  AP_PRO: 50,   B_TOT: 81,   B_ACT: 52,   B_REM: 29,   B_PRO: 64.2, BP_TOT: 34,  BP_ACT: 6,   BP_REM: 28,  BP_PRO: 17.6, },
      { SCOPE: 'Grassroots', UNIT: 'ELS SSV',     UNIT_NO: '21601', UNIT_DESC: 'Electrical System-Substation SSV',              SSMCC_TOT: 31, SSMCC_ACT: 14, SSMCC_REM: 17, A_TOT: 793,   A_ACT: 643,  A_REM: 150,   A_PRO: 81.1, AP_TOT: 316, AP_ACT: 286, AP_REM: 30,  AP_PRO: 90.5, B_TOT: 160,  B_ACT: 117,  B_REM: 43,   B_PRO: 73.1, BP_TOT: 248, BP_ACT: 208, BP_REM: 40,  BP_PRO: 83.9, },
      { SCOPE: 'Grassroots', UNIT: 'ELS SSW',     UNIT_NO: '21605', UNIT_DESC: 'Electrical System-Substation SSW',              SSMCC_TOT: 29, SSMCC_ACT: 14, SSMCC_REM: 15, A_TOT: 550,   A_ACT: 465,  A_REM: 85,    A_PRO: 84.5, AP_TOT: 143, AP_ACT: 97,  AP_REM: 46,  AP_PRO: 67.8, B_TOT: 121,  B_ACT: 70,   B_REM: 51,   B_PRO: 57.9, BP_TOT: 190, BP_ACT: 175, BP_REM: 15,  BP_PRO: 92.1, },
    ],

    SQL4: [ // Do not use SQL,  
      {UNIT: 'ELS SSV',     },
      {UNIT: 'ELS SSW',     },
      {UNIT: 'I&CS RIE 02', },
      {UNIT: 'I&CS RIE 01', },
      {UNIT: 'GENERAL',     },
      {UNIT: 'CWS',         },
      {UNIT: 'HSFOC',       },
      {UNIT: 'SRU',         },
      {UNIT: 'SWS',         },
      {UNIT: 'OM&S-TKS',    },
      {UNIT: 'OM&S-TKN',    },
      {UNIT: 'ARU',         },
      {UNIT: 'DS',          },
      {UNIT: 'PFBS',        },
      {UNIT: 'U&O',         },
      {UNIT: 'DAU',         },
      {UNIT: 'HOS',         },
      {UNIT: 'DHU S2',      },
      {UNIT: 'DHU S1',      },
      {UNIT: 'CCFU',        },
      {UNIT: 'CCFU SS',     },
    ],

  },
}