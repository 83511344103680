
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 1500,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 900,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: { 
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    { CODE: '1010', MAP_ID: 'M00GECP21000001010', GROUP_SORT: 1, GROUP: 'Utility / HSFO / SRU Area',               ASSIGN: 'NULL', AREA: 'SSW',              DESC: 'Substation W',                                                      MS_DATE: '2024-04-11', MIN_PLAN: '2023-10-04', MAX_PLAN: '2024-04-09', SSMCC_PLN: 11, SSMCC_ACT: 13, SSMCC_WD: 16, SSMCC_TOT: 26,  SSMCC_REM: 13,  SSMCC_PRO: 50,   A_TOT: 509,   A_ACT: 385,  A_REM: 124,   A_PRO: 75.6, B_TOT: 104,  B_ACT: 61,   B_REM: 43,   B_PRO: 58.7, C_TOT: 28,  C_ACT: 0, C_REM: 28,  C_PRO: 0, AP_TOT: 135,  AP_ACT: 93,   AP_REM: 42,   AP_PRO: 68.9, BP_TOT: 189,  BP_ACT: 173, BP_REM: 16,  BP_PRO: 91.5, CP_TOT: 163, CP_ACT: 86,  CP_REM: 77,  CP_PRO: 52.8, },
    { CODE: '1000', MAP_ID: 'M00GECP21000001000', GROUP_SORT: 1, GROUP: 'Utility / HSFO / SRU Area',               ASSIGN: 'NULL', AREA: 'SSV',              DESC: 'Substation V',                                                      MS_DATE: '2024-03-31', MIN_PLAN: '2023-09-22', MAX_PLAN: '2024-04-11', SSMCC_PLN: 12, SSMCC_ACT: 13, SSMCC_WD: 19, SSMCC_TOT: 29,  SSMCC_REM: 16,  SSMCC_PRO: 44.8, A_TOT: 738,   A_ACT: 637,  A_REM: 101,   A_PRO: 86.3, B_TOT: 155,  B_ACT: 115,  B_REM: 40,   B_PRO: 74.2, C_TOT: 36,  C_ACT: 0, C_REM: 36,  C_PRO: 0, AP_TOT: 311,  AP_ACT: 282,  AP_REM: 29,   AP_PRO: 90.7, BP_TOT: 252,  BP_ACT: 206, BP_REM: 46,  BP_PRO: 81.7, CP_TOT: 345, CP_ACT: 286, CP_REM: 59,  CP_PRO: 82.9, },
    { CODE: '1020', MAP_ID: 'M00GECP21000001020', GROUP_SORT: 1, GROUP: 'Utility / HSFO / SRU Area',               ASSIGN: 'NULL', AREA: 'RIE2',             DESC: 'RIE2',                                                              MS_DATE: '2024-04-25', MIN_PLAN: '2023-11-03', MAX_PLAN: '2024-04-24', SSMCC_PLN: 1,  SSMCC_ACT: 1,  SSMCC_WD: 2,  SSMCC_TOT: 15,  SSMCC_REM: 14,  SSMCC_PRO: 6.7,  A_TOT: 105,   A_ACT: 49,   A_REM: 56,    A_PRO: 46.7, B_TOT: 81,   B_ACT: 55,   B_REM: 26,   B_PRO: 67.9, C_TOT: 10,  C_ACT: 0, C_REM: 10,  C_PRO: 0, AP_TOT: 20,   AP_ACT: 10,   AP_REM: 10,   AP_PRO: 50,   BP_TOT: 34,   BP_ACT: 6,   BP_REM: 28,  BP_PRO: 17.6, CP_TOT: 10,  CP_ACT: 0,   CP_REM: 10,  CP_PRO: 0, },
    { CODE: '1030', MAP_ID: 'M00GECP21000001030', GROUP_SORT: 1, GROUP: 'Utility / HSFO / SRU Area',               ASSIGN: 'NULL', AREA: 'RIE1',             DESC: 'RIE1',                                                              MS_DATE: '2024-05-09', MIN_PLAN: '2023-12-20', MAX_PLAN: '2024-05-10', SSMCC_PLN: 1,  SSMCC_ACT: 1,  SSMCC_WD: 2,  SSMCC_TOT: 15,  SSMCC_REM: 14,  SSMCC_PRO: 6.7,  A_TOT: 51,    A_ACT: 36,   A_REM: 15,    A_PRO: 70.6, B_TOT: 78,   B_ACT: 42,   B_REM: 36,   B_PRO: 53.8, C_TOT: 10,  C_ACT: 0, C_REM: 10,  C_PRO: 0, AP_TOT: 4,    AP_ACT: 0,    AP_REM: 4,    AP_PRO: 0,    BP_TOT: 15,   BP_ACT: 5,   BP_REM: 10,  BP_PRO: 33.3, CP_TOT: 4,   CP_ACT: 0,   CP_REM: 4,   CP_PRO: 0, },
    { CODE: '1040', MAP_ID: 'M00GECP21000001040', GROUP_SORT: 1, GROUP: 'Utility / HSFO / SRU Area',               ASSIGN: 'NULL', AREA: 'IA Dist',          DESC: 'Instrument Air / Plant Air Distribution',                           MS_DATE: '2024-02-29', MIN_PLAN: '2024-03-11', MAX_PLAN: '2024-06-13', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 27,  SSMCC_REM: 27,  SSMCC_PRO: 0,    A_TOT: 1452,  A_ACT: 689,  A_REM: 763,   A_PRO: 47.5, B_TOT: 1042, B_ACT: 592,  B_REM: 450,  B_PRO: 56.8, C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, AP_TOT: 170,  AP_ACT: 147,  AP_REM: 23,   AP_PRO: 86.5, BP_TOT: 63,   BP_ACT: 46,  BP_REM: 17,  BP_PRO: 73,   CP_TOT: 62,  CP_ACT: 50,  CP_REM: 12,  CP_PRO: 80.6, },
    { CODE: '1050', MAP_ID: 'M00GECP21000001050', GROUP_SORT: 1, GROUP: 'Utility / HSFO / SRU Area',               ASSIGN: 'NULL', AREA: 'N2',               DESC: 'Nitrogen',                                                          MS_DATE: '2024-05-30', MIN_PLAN: '2024-05-04', MAX_PLAN: '2024-06-20', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 16,  SSMCC_REM: 16,  SSMCC_PRO: 0,    A_TOT: 897,   A_ACT: 490,  A_REM: 407,   A_PRO: 54.6, B_TOT: 630,  B_ACT: 388,  B_REM: 242,  B_PRO: 61.6, C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, AP_TOT: 176,  AP_ACT: 154,  AP_REM: 22,   AP_PRO: 87.5, BP_TOT: 53,   BP_ACT: 38,  BP_REM: 15,  BP_PRO: 71.7, CP_TOT: 92,  CP_ACT: 81,  CP_REM: 11,  CP_PRO: 88, },
    { CODE: '1400', MAP_ID: 'M00GECP21000001400', GROUP_SORT: 1, GROUP: 'Utility / HSFO / SRU Area',               ASSIGN: 'NULL', AREA: 'CWS',              DESC: 'Cooling Water System',                                              MS_DATE: '2024-07-11', MIN_PLAN: '2024-03-04', MAX_PLAN: '2024-07-01', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 12,  SSMCC_REM: 12,  SSMCC_PRO: 0,    A_TOT: 156,   A_ACT: 1,    A_REM: 155,   A_PRO: 0.6,  B_TOT: 10,   B_ACT: 0,    B_REM: 10,   B_PRO: 0,    C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, AP_TOT: 0,    AP_ACT: 0,    AP_REM: 0,    AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0, },
    { CODE: '1060', MAP_ID: 'M00GECP21000001060', GROUP_SORT: 1, GROUP: 'Utility / HSFO / SRU Area',               ASSIGN: 'NULL', AREA: 'FW Main',          DESC: 'Firewater main',                                                    MS_DATE: '2024-07-04', MIN_PLAN: '2024-05-08', MAX_PLAN: '2024-07-05', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 14,  SSMCC_REM: 14,  SSMCC_PRO: 0,    A_TOT: 393,   A_ACT: 94,   A_REM: 299,   A_PRO: 23.9, B_TOT: 227,  B_ACT: 0,    B_REM: 227,  B_PRO: 0,    C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, AP_TOT: 5,    AP_ACT: 0,    AP_REM: 5,    AP_PRO: 0,    BP_TOT: 8,    BP_ACT: 5,   BP_REM: 3,   BP_PRO: 62.5, CP_TOT: 2,   CP_ACT: 0,   CP_REM: 2,   CP_PRO: 0, },
    { CODE: '1100', MAP_ID: 'M00GECP21000001100', GROUP_SORT: 1, GROUP: 'Utility / HSFO / SRU Area',               ASSIGN: 'NULL', AREA: 'Chem Inj',         DESC: 'Chemical Injection Packages',                                       MS_DATE: '2024-07-11', MIN_PLAN: '2024-06-10', MAX_PLAN: '2024-07-11', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 5,   SSMCC_REM: 5,   SSMCC_PRO: 0,    A_TOT: 288,   A_ACT: 17,   A_REM: 271,   A_PRO: 5.9,  B_TOT: 139,  B_ACT: 33,   B_REM: 106,  B_PRO: 23.7, C_TOT: 17,  C_ACT: 0, C_REM: 17,  C_PRO: 0, AP_TOT: 19,   AP_ACT: 16,   AP_REM: 3,    AP_PRO: 84.2, BP_TOT: 0,    BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0, },
    { CODE: '1080', MAP_ID: 'M00GECP21000001080', GROUP_SORT: 1, GROUP: 'Utility / HSFO / SRU Area',               ASSIGN: 'NULL', AREA: 'Potable',          DESC: 'Potable Water',                                                     MS_DATE: '2024-07-11', MIN_PLAN: '2024-05-04', MAX_PLAN: '2024-07-11', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 11,  SSMCC_REM: 11,  SSMCC_PRO: 0,    A_TOT: 243,   A_ACT: 98,   A_REM: 145,   A_PRO: 40.3, B_TOT: 169,  B_ACT: 92,   B_REM: 77,   B_PRO: 54.4, C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, AP_TOT: 34,   AP_ACT: 29,   AP_REM: 5,    AP_PRO: 85.3, BP_TOT: 4,    BP_ACT: 3,   BP_REM: 1,   BP_PRO: 75,   CP_TOT: 10,  CP_ACT: 9,   CP_REM: 1,   CP_PRO: 90, },
    { CODE: '1090', MAP_ID: 'M00GECP21000001090', GROUP_SORT: 1, GROUP: 'Utility / HSFO / SRU Area',               ASSIGN: 'NULL', AREA: 'CT',               DESC: 'Cooling Tower',                                                     MS_DATE: '2024-07-11', MIN_PLAN: '2024-05-18', MAX_PLAN: '2024-07-12', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 12,  SSMCC_REM: 12,  SSMCC_PRO: 0,    A_TOT: 1436,  A_ACT: 344,  A_REM: 1092,  A_PRO: 24,   B_TOT: 886,  B_ACT: 276,  B_REM: 610,  B_PRO: 31.2, C_TOT: 16,  C_ACT: 0, C_REM: 16,  C_PRO: 0, AP_TOT: 175,  AP_ACT: 119,  AP_REM: 56,   AP_PRO: 68,   BP_TOT: 22,   BP_ACT: 16,  BP_REM: 6,   BP_PRO: 72.7, CP_TOT: 36,  CP_ACT: 24,  CP_REM: 12,  CP_PRO: 66.7, },
    { CODE: '1070', MAP_ID: 'M00GECP21000001070', GROUP_SORT: 1, GROUP: 'Utility / HSFO / SRU Area',               ASSIGN: 'NULL', AREA: 'Newater',          DESC: 'NeWater',                                                           MS_DATE: '2024-07-11', MIN_PLAN: '2024-05-15', MAX_PLAN: '2024-07-12', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 16,  SSMCC_REM: 16,  SSMCC_PRO: 0,    A_TOT: 633,   A_ACT: 392,  A_REM: 241,   A_PRO: 61.9, B_TOT: 541,  B_ACT: 383,  B_REM: 158,  B_PRO: 70.8, C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, AP_TOT: 122,  AP_ACT: 101,  AP_REM: 21,   AP_PRO: 82.8, BP_TOT: 41,   BP_ACT: 30,  BP_REM: 11,  BP_PRO: 73.2, CP_TOT: 50,  CP_ACT: 37,  CP_REM: 13,  CP_PRO: 74, },
    { CODE: '1110', MAP_ID: 'M00GECP21000001110', GROUP_SORT: 1, GROUP: 'Utility / HSFO / SRU Area',               ASSIGN: 'NULL', AREA: 'SRU',              DESC: 'SRU',                                                               MS_DATE: '2024-07-18', MIN_PLAN: '2024-03-11', MAX_PLAN: '2024-07-19', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 1,  SSMCC_TOT: 27,  SSMCC_REM: 27,  SSMCC_PRO: 0,    A_TOT: 6653,  A_ACT: 3287, A_REM: 3366,  A_PRO: 49.4, B_TOT: 1746, B_ACT: 638,  B_REM: 1108, B_PRO: 36.5, C_TOT: 34,  C_ACT: 0, C_REM: 34,  C_PRO: 0, AP_TOT: 273,  AP_ACT: 217,  AP_REM: 56,   AP_PRO: 79.5, BP_TOT: 346,  BP_ACT: 232, BP_REM: 114, BP_PRO: 67.1, CP_TOT: 144, CP_ACT: 120, CP_REM: 24,  CP_PRO: 83.3, },
    { CODE: '1160', MAP_ID: 'M00GECP21000001160', GROUP_SORT: 1, GROUP: 'Utility / HSFO / SRU Area',               ASSIGN: 'NULL', AREA: 'FW Deluge',        DESC: 'Firewater Deluge',                                                  MS_DATE: '2024-08-08', MIN_PLAN: '2024-06-07', MAX_PLAN: '2024-07-26', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 2,   SSMCC_REM: 2,   SSMCC_PRO: 0,    A_TOT: 25,    A_ACT: 9,    A_REM: 16,    A_PRO: 36,   B_TOT: 20,   B_ACT: 0,    B_REM: 20,   B_PRO: 0,    C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, AP_TOT: 2,    AP_ACT: 2,    AP_REM: 0,    AP_PRO: 100,  BP_TOT: 0,    BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0, },
    { CODE: '1120', MAP_ID: 'M00GECP21000001120', GROUP_SORT: 1, GROUP: 'Utility / HSFO / SRU Area',               ASSIGN: 'NULL', AREA: 'Closed Drain',     DESC: 'HC closed drain  (HSFO / SRU / Flare area)',                        MS_DATE: '2024-08-01', MIN_PLAN: '2024-06-10', MAX_PLAN: '2024-08-02', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 6,   SSMCC_REM: 6,   SSMCC_PRO: 0,    A_TOT: 2065,  A_ACT: 584,  A_REM: 1481,  A_PRO: 28.3, B_TOT: 1325, B_ACT: 420,  B_REM: 905,  B_PRO: 31.7, C_TOT: 6,   C_ACT: 0, C_REM: 6,   C_PRO: 0, AP_TOT: 143,  AP_ACT: 84,   AP_REM: 59,   AP_PRO: 58.7, BP_TOT: 78,   BP_ACT: 27,  BP_REM: 51,  BP_PRO: 34.6, CP_TOT: 42,  CP_ACT: 24,  CP_REM: 18,  CP_PRO: 57.1, },
    { CODE: '1170', MAP_ID: 'M00GECP21000001170', GROUP_SORT: 1, GROUP: 'Utility / HSFO / SRU Area',               ASSIGN: 'NULL', AREA: 'OWS/SW',           DESC: 'Oily Water Sewer / Storm Water',                                    MS_DATE: '2024-08-08', MIN_PLAN: '2024-03-11', MAX_PLAN: '2024-08-07', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 19,  SSMCC_REM: 19,  SSMCC_PRO: 0,    A_TOT: 1229,  A_ACT: 348,  A_REM: 881,   A_PRO: 28.3, B_TOT: 216,  B_ACT: 87,   B_REM: 129,  B_PRO: 40.3, C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, AP_TOT: 7,    AP_ACT: 4,    AP_REM: 3,    AP_PRO: 57.1, BP_TOT: 8,    BP_ACT: 6,   BP_REM: 2,   BP_PRO: 75,   CP_TOT: 16,  CP_ACT: 13,  CP_REM: 3,   CP_PRO: 81.3, },
    { CODE: '1140', MAP_ID: 'M00GECP21000001140', GROUP_SORT: 1, GROUP: 'Utility / HSFO / SRU Area',               ASSIGN: 'NULL', AREA: 'Flare',            DESC: 'Flare (stack and associated equip) (HSFO / SRU / Flare area)',      MS_DATE: '2024-08-01', MIN_PLAN: '2024-03-06', MAX_PLAN: '2024-08-16', SSMCC_PLN: 0,  SSMCC_ACT: 1,  SSMCC_WD: 1,  SSMCC_TOT: 46,  SSMCC_REM: 45,  SSMCC_PRO: 2.2,  A_TOT: 6023,  A_ACT: 2332, A_REM: 3691,  A_PRO: 38.7, B_TOT: 1519, B_ACT: 544,  B_REM: 975,  B_PRO: 35.8, C_TOT: 267, C_ACT: 0, C_REM: 267, C_PRO: 0, AP_TOT: 464,  AP_ACT: 313,  AP_REM: 151,  AP_PRO: 67.5, BP_TOT: 333,  BP_ACT: 187, BP_REM: 146, BP_PRO: 56.2, CP_TOT: 345, CP_ACT: 218, CP_REM: 127, CP_PRO: 63.2, },
    { CODE: '1150', MAP_ID: 'M00GECP21000001150', GROUP_SORT: 1, GROUP: 'Utility / HSFO / SRU Area',               ASSIGN: 'NULL', AREA: 'IA Comp',          DESC: 'Instrument Air Compressor',                                         MS_DATE: '2024-08-22', MIN_PLAN: '2024-08-23', MAX_PLAN: '2024-08-23', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 1,   SSMCC_REM: 1,   SSMCC_PRO: 0,    A_TOT: 146,   A_ACT: 20,   A_REM: 126,   A_PRO: 13.7, B_TOT: 91,   B_ACT: 0,    B_REM: 91,   B_PRO: 0,    C_TOT: 4,   C_ACT: 0, C_REM: 4,   C_PRO: 0, AP_TOT: 0,    AP_ACT: 0,    AP_REM: 0,    AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0, },
    { CODE: '1130', MAP_ID: 'M00GECP21000001130', GROUP_SORT: 1, GROUP: 'Utility / HSFO / SRU Area',               ASSIGN: 'NULL', AREA: 'NG/FG',            DESC: 'Natural Gas / Fuel Gas (HSFO / SRU / Flare area)',                  MS_DATE: '2024-08-01', MIN_PLAN: '2024-05-30', MAX_PLAN: '2024-09-05', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 18,  SSMCC_REM: 18,  SSMCC_PRO: 0,    A_TOT: 2694,  A_ACT: 844,  A_REM: 1850,  A_PRO: 31.3, B_TOT: 942,  B_ACT: 434,  B_REM: 508,  B_PRO: 46.1, C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, AP_TOT: 300,  AP_ACT: 124,  AP_REM: 176,  AP_PRO: 41.3, BP_TOT: 86,   BP_ACT: 45,  BP_REM: 41,  BP_PRO: 52.3, CP_TOT: 67,  CP_ACT: 59,  CP_REM: 8,   CP_PRO: 88.1, },
    { CODE: '1180', MAP_ID: 'M00GECP21000001180', GROUP_SORT: 2, GROUP: 'CCFU Solids Handling / South Tanks Area', ASSIGN: 'NULL', AREA: 'South Tanks',      DESC: 'S Tank Farm',                                                       MS_DATE: '2024-07-25', MIN_PLAN: '2024-03-25', MAX_PLAN: '2024-07-20', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 15,  SSMCC_REM: 15,  SSMCC_PRO: 0,    A_TOT: 2685,  A_ACT: 239,  A_REM: 2446,  A_PRO: 8.9,  B_TOT: 864,  B_ACT: 118,  B_REM: 746,  B_PRO: 13.7, C_TOT: 38,  C_ACT: 0, C_REM: 38,  C_PRO: 0, AP_TOT: 43,   AP_ACT: 39,   AP_REM: 4,    AP_PRO: 90.7, BP_TOT: 23,   BP_ACT: 18,  BP_REM: 5,   BP_PRO: 78.3, CP_TOT: 25,  CP_ACT: 23,  CP_REM: 2,   CP_PRO: 92, },
    { CODE: '1190', MAP_ID: 'M00GECP21000001190', GROUP_SORT: 2, GROUP: 'CCFU Solids Handling / South Tanks Area', ASSIGN: 'NULL', AREA: 'Solvent Tank',     DESC: 'Midcut Solvent Tank',                                               MS_DATE: '2024-07-25', MIN_PLAN: '2024-07-20', MAX_PLAN: '2024-08-17', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 3,   SSMCC_REM: 3,   SSMCC_PRO: 0,    A_TOT: 304,   A_ACT: 100,  A_REM: 204,   A_PRO: 32.9, B_TOT: 156,  B_ACT: 83,   B_REM: 73,   B_PRO: 53.2, C_TOT: 1,   C_ACT: 0, C_REM: 1,   C_PRO: 0, AP_TOT: 23,   AP_ACT: 17,   AP_REM: 6,    AP_PRO: 73.9, BP_TOT: 8,    BP_ACT: 7,   BP_REM: 1,   BP_PRO: 87.5, CP_TOT: 12,  CP_ACT: 12,  CP_REM: 0,   CP_PRO: 100, },
    { CODE: '1200', MAP_ID: 'M00GECP21000001200', GROUP_SORT: 2, GROUP: 'CCFU Solids Handling / South Tanks Area', ASSIGN: 'NULL', AREA: 'CCFU Solids',      DESC: 'CCFU Solids Handling',                                              MS_DATE: '2024-08-15', MIN_PLAN: '2024-06-28', MAX_PLAN: '2024-09-20', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 4,   SSMCC_REM: 4,   SSMCC_PRO: 0,    A_TOT: 1438,  A_ACT: 340,  A_REM: 1098,  A_PRO: 23.6, B_TOT: 794,  B_ACT: 81,   B_REM: 713,  B_PRO: 10.2, C_TOT: 21,  C_ACT: 0, C_REM: 21,  C_PRO: 0, AP_TOT: 35,   AP_ACT: 34,   AP_REM: 1,    AP_PRO: 97.1, BP_TOT: 10,   BP_ACT: 7,   BP_REM: 3,   BP_PRO: 70,   CP_TOT: 7,   CP_ACT: 6,   CP_REM: 1,   CP_PRO: 85.7, },
    { CODE: '1390', MAP_ID: 'M00GECP21000001390', GROUP_SORT: 3, GROUP: 'HO / PFBS / SWS / North Tanks Area',      ASSIGN: 'NULL', AREA: 'HOS(Finish)',      DESC: 'HOS(Finish)',                                                       MS_DATE: '2024-07-25', MIN_PLAN: '2024-03-11', MAX_PLAN: '2024-07-20', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 13,  SSMCC_REM: 13,  SSMCC_PRO: 0,    A_TOT: 806,   A_ACT: 190,  A_REM: 616,   A_PRO: 23.6, B_TOT: 43,   B_ACT: 0,    B_REM: 43,   B_PRO: 0,    C_TOT: 26,  C_ACT: 0, C_REM: 26,  C_PRO: 0, AP_TOT: 43,   AP_ACT: 25,   AP_REM: 18,   AP_PRO: 58.1, BP_TOT: 74,   BP_ACT: 69,  BP_REM: 5,   BP_PRO: 93.2, CP_TOT: 34,  CP_ACT: 23,  CP_REM: 11,  CP_PRO: 67.6, },
    { CODE: '1220', MAP_ID: 'M00GECP21000001220', GROUP_SORT: 3, GROUP: 'HO / PFBS / SWS / North Tanks Area',      ASSIGN: 'NULL', AREA: 'Steam',            DESC: 'Steam',                                                             MS_DATE: '2024-07-18', MIN_PLAN: '2024-04-20', MAX_PLAN: '2024-08-01', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 58,  SSMCC_REM: 58,  SSMCC_PRO: 0,    A_TOT: 7748,  A_ACT: 4034, A_REM: 3714,  A_PRO: 52.1, B_TOT: 2493, B_ACT: 1760, B_REM: 733,  B_PRO: 70.6, C_TOT: 310, C_ACT: 0, C_REM: 310, C_PRO: 0, AP_TOT: 857,  AP_ACT: 599,  AP_REM: 258,  AP_PRO: 69.9, BP_TOT: 673,  BP_ACT: 460, BP_REM: 213, BP_PRO: 68.4, CP_TOT: 317, CP_ACT: 192, CP_REM: 125, CP_PRO: 60.6, },
    { CODE: '1260', MAP_ID: 'M00GECP21000001260', GROUP_SORT: 3, GROUP: 'HO / PFBS / SWS / North Tanks Area',      ASSIGN: 'NULL', AREA: 'North Tanks',      DESC: 'N Tank Farm',                                                       MS_DATE: '2024-08-08', MIN_PLAN: '2024-03-25', MAX_PLAN: '2024-08-03', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 8,   SSMCC_REM: 8,   SSMCC_PRO: 0,    A_TOT: 1446,  A_ACT: 226,  A_REM: 1220,  A_PRO: 15.6, B_TOT: 207,  B_ACT: 42,   B_REM: 165,  B_PRO: 20.3, C_TOT: 120, C_ACT: 0, C_REM: 120, C_PRO: 0, AP_TOT: 11,   AP_ACT: 11,   AP_REM: 0,    AP_PRO: 100,  BP_TOT: 17,   BP_ACT: 16,  BP_REM: 1,   BP_PRO: 94.1, CP_TOT: 2,   CP_ACT: 1,   CP_REM: 1,   CP_PRO: 50, },
    { CODE: '1250', MAP_ID: 'M00GECP21000001250', GROUP_SORT: 3, GROUP: 'HO / PFBS / SWS / North Tanks Area',      ASSIGN: 'NULL', AREA: 'DAU Feed Tank',    DESC: 'DAU Feed Tank',                                                     MS_DATE: '2024-08-08', MIN_PLAN: '2024-08-08', MAX_PLAN: '2024-08-08', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 1,   SSMCC_REM: 1,   SSMCC_PRO: 0,    A_TOT: 456,   A_ACT: 91,   A_REM: 365,   A_PRO: 20,   B_TOT: 146,  B_ACT: 32,   B_REM: 114,  B_PRO: 21.9, C_TOT: 2,   C_ACT: 0, C_REM: 2,   C_PRO: 0, AP_TOT: 9,    AP_ACT: 9,    AP_REM: 0,    AP_PRO: 100,  BP_TOT: 8,    BP_ACT: 8,   BP_REM: 0,   BP_PRO: 100,  CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0, },
    { CODE: '1230', MAP_ID: 'M00GECP21000001230', GROUP_SORT: 3, GROUP: 'HO / PFBS / SWS / North Tanks Area',      ASSIGN: 'NULL', AREA: 'SWS',              DESC: 'SWS',                                                               MS_DATE: '2024-08-29', MIN_PLAN: '2024-04-13', MAX_PLAN: '2024-08-29', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 22,  SSMCC_REM: 22,  SSMCC_PRO: 0,    A_TOT: 1883,  A_ACT: 794,  A_REM: 1089,  A_PRO: 42.2, B_TOT: 842,  B_ACT: 348,  B_REM: 494,  B_PRO: 41.3, C_TOT: 16,  C_ACT: 0, C_REM: 16,  C_PRO: 0, AP_TOT: 132,  AP_ACT: 73,   AP_REM: 59,   AP_PRO: 55.3, BP_TOT: 86,   BP_ACT: 67,  BP_REM: 19,  BP_PRO: 77.9, CP_TOT: 40,  CP_ACT: 31,  CP_REM: 9,   CP_PRO: 77.5, },
    { CODE: '1240', MAP_ID: 'M00GECP21000001240', GROUP_SORT: 3, GROUP: 'HO / PFBS / SWS / North Tanks Area',      ASSIGN: 'NULL', AREA: 'PFB',              DESC: 'PFB',                                                               MS_DATE: '2024-08-29', MIN_PLAN: '2024-04-03', MAX_PLAN: '2024-08-29', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 12,  SSMCC_REM: 12,  SSMCC_PRO: 0,    A_TOT: 1518,  A_ACT: 643,  A_REM: 875,   A_PRO: 42.4, B_TOT: 506,  B_ACT: 195,  B_REM: 311,  B_PRO: 38.5, C_TOT: 8,   C_ACT: 0, C_REM: 8,   C_PRO: 0, AP_TOT: 318,  AP_ACT: 78,   AP_REM: 240,  AP_PRO: 24.5, BP_TOT: 263,  BP_ACT: 55,  BP_REM: 208, BP_PRO: 20.9, CP_TOT: 132, CP_ACT: 15,  CP_REM: 117, CP_PRO: 11.4, },
    { CODE: '1210', MAP_ID: 'M00GECP21000001210', GROUP_SORT: 3, GROUP: 'HO / PFBS / SWS / North Tanks Area',      ASSIGN: 'NULL', AREA: 'HO System',        DESC: 'HO System',                                                         MS_DATE: '2024-07-25', MIN_PLAN: '2024-06-12', MAX_PLAN: '2024-10-29', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 16,  SSMCC_REM: 16,  SSMCC_PRO: 0,    A_TOT: 4669,  A_ACT: 1574, A_REM: 3095,  A_PRO: 33.7, B_TOT: 1592, B_ACT: 491,  B_REM: 1101, B_PRO: 30.8, C_TOT: 13,  C_ACT: 0, C_REM: 13,  C_PRO: 0, AP_TOT: 227,  AP_ACT: 170,  AP_REM: 57,   AP_PRO: 74.9, BP_TOT: 288,  BP_ACT: 201, BP_REM: 87,  BP_PRO: 69.8, CP_TOT: 106, CP_ACT: 58,  CP_REM: 48,  CP_PRO: 54.7, },
    { CODE: '1306', MAP_ID: 'TBA-01',             GROUP_SORT: 4, GROUP: 'DAU / DHU / ARU Area',                    ASSIGN: 'N',    AREA: 'DHU Cat Load(L)',  DESC: 'DHU Reactor Catalyst Loading (Last)',                               MS_DATE: '2024-07-18', MIN_PLAN: undefined,    MAX_PLAN: undefined,    SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 0,   SSMCC_REM: 0,   SSMCC_PRO: 0,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,    B_TOT: 0,    B_ACT: 0,    B_REM: 0,    B_PRO: 0,    C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, AP_TOT: 0,    AP_ACT: 0,    AP_REM: 0,    AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0, },
    { CODE: '1309', MAP_ID: 'TBA-02',             GROUP_SORT: 4, GROUP: 'DAU / DHU / ARU Area',                    ASSIGN: 'N',    AREA: 'DAU, DHU(F)',      DESC: 'DAU / DHU systems (First)',                                         MS_DATE: '2024-08-29', MIN_PLAN: undefined,    MAX_PLAN: undefined,    SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 0,   SSMCC_REM: 0,   SSMCC_PRO: 0,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,    B_TOT: 0,    B_ACT: 0,    B_REM: 0,    B_PRO: 0,    C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, AP_TOT: 0,    AP_ACT: 0,    AP_REM: 0,    AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0, },
    { CODE: '1305', MAP_ID: 'M00GECP21000001305', GROUP_SORT: 4, GROUP: 'DAU / DHU / ARU Area',                    ASSIGN: 'NULL', AREA: 'DHU Cat Load(F)',  DESC: 'DHU Reactor Catalyst Loading (First - Ready for Catayst Loading)',  MS_DATE: '2024-05-23', MIN_PLAN: '2024-05-23', MAX_PLAN: '2024-05-23', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 1,   SSMCC_REM: 1,   SSMCC_PRO: 0,    A_TOT: 12624, A_ACT: 1294, A_REM: 11330, A_PRO: 10.3, B_TOT: 2394, B_ACT: 7,    B_REM: 2387, B_PRO: 0.3,  C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, AP_TOT: 0,    AP_ACT: 0,    AP_REM: 0,    AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0, },
    { CODE: '1290', MAP_ID: 'M00GECP21000001290', GROUP_SORT: 4, GROUP: 'DAU / DHU / ARU Area',                    ASSIGN: 'NULL', AREA: 'Amine (CCFU)',     DESC: 'Amine circuit within CCFU',                                         MS_DATE: '2024-08-29', MIN_PLAN: '2024-07-18', MAX_PLAN: '2024-08-08', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 5,   SSMCC_REM: 5,   SSMCC_PRO: 0,    A_TOT: 1813,  A_ACT: 560,  A_REM: 1253,  A_PRO: 30.9, B_TOT: 877,  B_ACT: 267,  B_REM: 610,  B_PRO: 30.4, C_TOT: 6,   C_ACT: 0, C_REM: 6,   C_PRO: 0, AP_TOT: 86,   AP_ACT: 84,   AP_REM: 2,    AP_PRO: 97.7, BP_TOT: 33,   BP_ACT: 30,  BP_REM: 3,   BP_PRO: 90.9, CP_TOT: 57,  CP_ACT: 55,  CP_REM: 2,   CP_PRO: 96.5, },
    { CODE: '1320', MAP_ID: 'M00GECP21000001320', GROUP_SORT: 4, GROUP: 'DAU / DHU / ARU Area',                    ASSIGN: 'NULL', AREA: 'DHU H2 Comp',      DESC: 'DHU H2 Make Up Compressors',                                        MS_DATE: '2024-08-29', MIN_PLAN: '2024-08-28', MAX_PLAN: '2024-08-29', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 6,   SSMCC_REM: 6,   SSMCC_PRO: 0,    A_TOT: 3376,  A_ACT: 274,  A_REM: 3102,  A_PRO: 8.1,  B_TOT: 1103, B_ACT: 34,   B_REM: 1069, B_PRO: 3.1,  C_TOT: 6,   C_ACT: 0, C_REM: 6,   C_PRO: 0, AP_TOT: 0,    AP_ACT: 0,    AP_REM: 0,    AP_PRO: 0,    BP_TOT: 4,    BP_ACT: 4,   BP_REM: 0,   BP_PRO: 100,  CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0, },
    { CODE: '1330', MAP_ID: 'M00GECP21000001330', GROUP_SORT: 4, GROUP: 'DAU / DHU / ARU Area',                    ASSIGN: 'NULL', AREA: 'FG Comp',          DESC: 'Fuel Gas Compressor',                                               MS_DATE: '2024-09-26', MIN_PLAN: '2024-09-25', MAX_PLAN: '2024-09-25', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 1,   SSMCC_REM: 1,   SSMCC_PRO: 0,    A_TOT: 830,   A_ACT: 102,  A_REM: 728,   A_PRO: 12.3, B_TOT: 239,  B_ACT: 51,   B_REM: 188,  B_PRO: 21.3, C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, AP_TOT: 82,   AP_ACT: 7,    AP_REM: 75,   AP_PRO: 8.5,  BP_TOT: 9,    BP_ACT: 2,   BP_REM: 7,   BP_PRO: 22.2, CP_TOT: 5,   CP_ACT: 1,   CP_REM: 4,   CP_PRO: 20, },
    { CODE: '1270', MAP_ID: 'M00GECP21000001270', GROUP_SORT: 4, GROUP: 'DAU / DHU / ARU Area',                    ASSIGN: 'NULL', AREA: 'ARU',              DESC: 'ARU',                                                               MS_DATE: '2024-08-08', MIN_PLAN: '2024-03-11', MAX_PLAN: '2024-10-05', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 19,  SSMCC_REM: 19,  SSMCC_PRO: 0,    A_TOT: 3308,  A_ACT: 930,  A_REM: 2378,  A_PRO: 28.1, B_TOT: 1213, B_ACT: 323,  B_REM: 890,  B_PRO: 26.6, C_TOT: 52,  C_ACT: 0, C_REM: 52,  C_PRO: 0, AP_TOT: 129,  AP_ACT: 103,  AP_REM: 26,   AP_PRO: 79.8, BP_TOT: 83,   BP_ACT: 79,  BP_REM: 4,   BP_PRO: 95.2, CP_TOT: 98,  CP_ACT: 92,  CP_REM: 6,   CP_PRO: 93.9, },
    { CODE: '1280', MAP_ID: 'M00GECP21000001280', GROUP_SORT: 4, GROUP: 'DAU / DHU / ARU Area',                    ASSIGN: 'NULL', AREA: 'Amine (DHU)',      DESC: 'Amine circuit within DHU',                                          MS_DATE: '2024-08-22', MIN_PLAN: '2024-10-05', MAX_PLAN: '2024-10-05', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 1,   SSMCC_REM: 1,   SSMCC_PRO: 0,    A_TOT: 653,   A_ACT: 63,   A_REM: 590,   A_PRO: 9.6,  B_TOT: 264,  B_ACT: 50,   B_REM: 214,  B_PRO: 18.9, C_TOT: 6,   C_ACT: 0, C_REM: 6,   C_PRO: 0, AP_TOT: 37,   AP_ACT: 13,   AP_REM: 24,   AP_PRO: 35.1, BP_TOT: 3,    BP_ACT: 3,   BP_REM: 0,   BP_PRO: 100,  CP_TOT: 11,  CP_ACT: 7,   CP_REM: 4,   CP_PRO: 63.6, },
    { CODE: '1310', MAP_ID: 'M00GECP21000001310', GROUP_SORT: 4, GROUP: 'DAU / DHU / ARU Area',                    ASSIGN: 'NULL', AREA: 'DAU, DHU(L)',      DESC: 'DAU / DHU systems (Last)',                                          MS_DATE: '2024-10-24', MIN_PLAN: '2024-03-04', MAX_PLAN: '2024-10-25', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 1,  SSMCC_TOT: 142, SSMCC_REM: 142, SSMCC_PRO: 0,    A_TOT: 34285, A_ACT: 9455, A_REM: 24830, A_PRO: 27.6, B_TOT: 9746, B_ACT: 2958, B_REM: 6788, B_PRO: 30.4, C_TOT: 383, C_ACT: 0, C_REM: 383, C_PRO: 0, AP_TOT: 2617, AP_ACT: 1095, AP_REM: 1522, AP_PRO: 41.8, BP_TOT: 1035, BP_ACT: 692, BP_REM: 343, BP_PRO: 66.9, CP_TOT: 938, CP_ACT: 511, CP_REM: 427, CP_PRO: 54.5, },
    { CODE: '1349', MAP_ID: 'TBA-03',             GROUP_SORT: 5, GROUP: 'CCFU Area',                               ASSIGN: 'N',    AREA: 'CCFU(F)',          DESC: 'CCFU systems (First)',                                              MS_DATE: '2024-09-12', MIN_PLAN: undefined,    MAX_PLAN: undefined,    SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 0,   SSMCC_REM: 0,   SSMCC_PRO: 0,    A_TOT: 0,     A_ACT: 0,    A_REM: 0,     A_PRO: 0,    B_TOT: 0,    B_ACT: 0,    B_REM: 0,    B_PRO: 0,    C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, AP_TOT: 0,    AP_ACT: 0,    AP_REM: 0,    AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0, },
    { CODE: '1345', MAP_ID: 'M00GECP21000001345', GROUP_SORT: 5, GROUP: 'CCFU Area',                               ASSIGN: 'NULL', AREA: 'CCFU Cat Load(F)', DESC: 'CCFU Reactor Catalyst Loading (First - Ready for Catayst Loading)', MS_DATE: '2024-08-22', MIN_PLAN: '2024-08-23', MAX_PLAN: '2024-08-23', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 1,   SSMCC_REM: 1,   SSMCC_PRO: 0,    A_TOT: 1172,  A_ACT: 170,  A_REM: 1002,  A_PRO: 14.5, B_TOT: 266,  B_ACT: 0,    B_REM: 266,  B_PRO: 0,    C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, AP_TOT: 0,    AP_ACT: 0,    AP_REM: 0,    AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0, },
    { CODE: '1340', MAP_ID: 'M00GECP21000001340', GROUP_SORT: 5, GROUP: 'CCFU Area',                               ASSIGN: 'NULL', AREA: 'CCFU Cat Load(L)', DESC: 'CCFU Reactor Catalyst Loading (Last - Ready for Catayst Loading)',  MS_DATE: '2024-09-12', MIN_PLAN: '2024-09-13', MAX_PLAN: '2024-09-13', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 1,   SSMCC_REM: 1,   SSMCC_PRO: 0,    A_TOT: 3011,  A_ACT: 348,  A_REM: 2663,  A_PRO: 11.6, B_TOT: 645,  B_ACT: 0,    B_REM: 645,  B_PRO: 0,    C_TOT: 0,   C_ACT: 0, C_REM: 0,   C_PRO: 0, AP_TOT: 0,    AP_ACT: 0,    AP_REM: 0,    AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0, },
    { CODE: '1360', MAP_ID: 'M00GECP21000001360', GROUP_SORT: 5, GROUP: 'CCFU Area',                               ASSIGN: 'NULL', AREA: 'CCFU RG Comp',     DESC: 'CCFU Recycle Gas Compressors',                                      MS_DATE: '2024-10-10', MIN_PLAN: '2024-10-11', MAX_PLAN: '2024-10-11', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 1,   SSMCC_REM: 1,   SSMCC_PRO: 0,    A_TOT: 339,   A_ACT: 10,   A_REM: 329,   A_PRO: 2.9,  B_TOT: 181,  B_ACT: 20,   B_REM: 161,  B_PRO: 11,   C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, AP_TOT: 0,    AP_ACT: 0,    AP_REM: 0,    AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0, },
    { CODE: '1370', MAP_ID: 'M00GECP21000001370', GROUP_SORT: 5, GROUP: 'CCFU Area',                               ASSIGN: 'NULL', AREA: 'CCFU OG Comp',     DESC: 'CCFU Offgas Compressors',                                           MS_DATE: '2024-10-17', MIN_PLAN: '2024-10-10', MAX_PLAN: '2024-10-11', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 2,   SSMCC_REM: 2,   SSMCC_PRO: 0,    A_TOT: 859,   A_ACT: 54,   A_REM: 805,   A_PRO: 6.3,  B_TOT: 303,  B_ACT: 12,   B_REM: 291,  B_PRO: 4,    C_TOT: 3,   C_ACT: 0, C_REM: 3,   C_PRO: 0, AP_TOT: 0,    AP_ACT: 0,    AP_REM: 0,    AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,   BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,   CP_ACT: 0,   CP_REM: 0,   CP_PRO: 0, },
    { CODE: '1350', MAP_ID: 'M00GECP21000001350', GROUP_SORT: 5, GROUP: 'CCFU Area',                               ASSIGN: 'NULL', AREA: 'CCFU(L)',          DESC: 'CCFU systems (Last)',                                               MS_DATE: '2024-10-31', MIN_PLAN: '2024-03-19', MAX_PLAN: '2024-10-30', SSMCC_PLN: 0,  SSMCC_ACT: 0,  SSMCC_WD: 0,  SSMCC_TOT: 39,  SSMCC_REM: 39,  SSMCC_PRO: 0,    A_TOT: 6427,  A_ACT: 1763, A_REM: 4664,  A_PRO: 27.4, B_TOT: 2036, B_ACT: 528,  B_REM: 1508, B_PRO: 25.9, C_TOT: 44,  C_ACT: 0, C_REM: 44,  C_PRO: 0, AP_TOT: 624,  AP_ACT: 283,  AP_REM: 341,  AP_PRO: 45.4, BP_TOT: 210,  BP_ACT: 119, BP_REM: 91,  BP_PRO: 56.7, CP_TOT: 79,  CP_ACT: 29,  CP_REM: 50,  CP_PRO: 36.7, },
    
  ],

  Queries: {

    // Timeline
    SQL1: [ 
      {
        CUTOFF:               '2024-01-12',

        X:                    30,
        Y:                    100,
        LENGTH:               800,
        HEIGHT:               35,
        COLUMN_WIDTH:         450,

        START_DATE:           '2023-08-01', 
        END_DATE:             '2024-12-15',
        WEEKEND:              'friday',
      }
    ],

  }
}